const DataFeatureName = {
  COMMON_ACCOUNT_NUMBER_HASH_ORIGINATOR: 'common-account-number-hash-originator',
  COMMON_ACCOUNT_NUMBER_HASH_BENEFICIARY: 'common-account-number-hash-beneficiary',
  COMMON_HOLDER_HASH_ORIGINATOR: 'common-holder-hash-originator',
  COMMON_HOLDER_HASH_BENEFICIARY: 'common-holder-hash-beneficiary',
  COMMON_SETTLED_FUNDS_AMOUNT: 'common-settled-funds-amount',
  COMMON_SETTLED_FUNDS_CURRENCY: 'common-settled-funds-currency',
  COUNTRY_IBAN_ORIGINATOR: 'country-iban-originator',
  COUNTRY_IBAN_BENEFICIARY: 'country-iban-beneficiary',
  SIMILARITY_HOLDER_NAME_ORIGINATOR_BENEFICIARY: 'similarity-holder-name-originator-beneficiary',
  TRANSACTION_INITIATING_PARTY: 'transaction-initiating-party',
  ACCOUNT_HOLDING_PARTY: 'account-holding-party',
  ACCOUNT_HOLDING_PARTY_COUNTRY_OF_RESIDENCE: 'account-holding-party-country-of-residence',
  ACCOUNT_HOLDING_PARTY_COUNTRY_OF_RESIDENCE_RISK:
    'account-holding-party-country-of-residence-risk',
  ACCOUNT_HOLDING_PARTY_COUNTRY_OF_BIRTH: 'account-holding-party-country-of-birth',
  ACCOUNT_HOLDING_PARTY_COUNTRY_OF_BIRTH_RISK: 'account-holding-party-country-of-birth-risk',
  ACCOUNT_HOLDING_PARTY_NATIONALITY: 'account-holding-party-nationality',
  ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK: 'account-holding-party-nationality-risk',
  ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL: 'account-holding-party-bank-risk-level',
  ACCOUNT_HOLDING_PARTY_GENDER: 'account-holding-party-gender',
  ACCOUNT_HOLDING_PARTY_LAST_NAME_INCLUDED_IN_COUNTER_PARTY_HOLDER_NAME:
    'account-holding-party-last-name-included-in-counter-party-holder-name',
  ACCOUNT_HOLDING_PARTY_SUM_INCOMING_SETTLED_FUNDS_LAST_MONTH:
    'account-holding-party-sum-incoming-settled-funds-last-month',
  ACCOUNT_HOLDING_PARTY_SUM_INCOMING_SETTLED_FUNDS_LAST_MONTH_VS_SETTLED_FUNDS:
    'account-holding-party-sum-incoming-settled-funds-last-month-vs-settled-funds',
  COUNTRY_RISK_IBAN_ORIGINATOR: 'country-risk-iban-originator',
  COUNTRY_RISK_IBAN_BENEFICIARY: 'country-risk-iban-beneficiary',
  COUNTRY_RISK_HISTORY_ACCOUNT_HOLDING_PARTY: 'country-risk-history-account-holding-party',
  COUNTRY_RISK_CONSECUTIVE_OK_THRESHOLD: 'country-risk-consecutive-ok-threshold',
  COUNTRY_RISK_CONSECUTIVE_OK_VALUE: 'country-risk-consecutive-ok-value',
  COUNTRY_RISK_DECISION_CASE_STATUS: 'country-risk-decision-case-status',
  PEPSANCTION_LOOKUP_MATCH_OTHER_HOLDER_ORIGINATOR:
    'pepsanction-lookup-match-other-holder-originator',
  PEPSANCTION_LOOKUP_MATCH_OTHER_HOLDER_BENEFICIARY:
    'pepsanction-lookup-match-other-holder-beneficiary',
  PEPSANCTION_LOOKUP_MATCH_SANCTION_HOLDER_ORIGINATOR:
    'pepsanction-lookup-match-sanction-holder-originator',
  PEPSANCTION_LOOKUP_MATCH_SANCTION_HOLDER_BENEFICIARY:
    'pepsanction-lookup-match-sanction-holder-beneficiary',
  PEPSANCTION_LOOKUP_MATCH_VALIDATED_HOLDER_ORIGINATOR:
    'pepsanction-lookup-match-validated-holder-originator',
  PEPSANCTION_LOOKUP_MATCH_VALIDATED_HOLDER_BENEFICIARY:
    'pepsanction-lookup-match-validated-holder-beneficiary',
  PEPSANCTION_LOOKUP_MATCH_GEOGRAPHICAL_OVERLAP_HOLDER_ORIGINATOR:
    'pepsanction-lookup-match-geographical-overlap-holder-originator',
  PEPSANCTION_LOOKUP_MATCH_GEOGRAPHICAL_OVERLAP_HOLDER_BENEFICIARY:
    'pepsanction-lookup-match-geographical-overlap-holder-beneficiary',
  PEPSANCTION_LOOKUP_MATCH_GENDER_MATCH_HOLDER_ORIGINATOR:
    'pepsanction-lookup-match-gender-match-holder-originator',
  PEPSANCTION_LOOKUP_MATCH_GENDER_MATCH_HOLDER_BENEFICIARY:
    'pepsanction-lookup-match-gender-match-holder-beneficiary',
  PEPSANCTION_LOOKUP_MATCH_DATE_OF_BIRTH_MATCH_HOLDER_ORIGINATOR:
    'pepsanction-lookup-match-date-of-birth-match-holder-originator',
  PEPSANCTION_LOOKUP_MATCH_DATE_OF_BIRTH_MATCH_HOLDER_BENEFICIARY:
    'pepsanction-lookup-match-date-of-birth-match-holder-beneficiary',
  PEPSANCTION_RISK_DECISION_CASE_STATUS: 'pepsanction-risk-decision-case-status',
  VOLUME_RISK_ONE_MONTH_CONFIGURED_THRESHOLD: 'volume-risk-one-month-configured-threshold',
  VOLUME_RISK_ONE_MONTH_SETTLED_FUNDS_AGGREGATED_AMOUNT:
    'volume-risk-one-month-settled-funds-aggregated-amount',
  VOLUME_RISK_ONE_MONTH_AGGREGATED_TX_COUNT: 'volume-risk-one-month-aggregated-tx-count',
  VOLUME_RISK_ONE_MONTH_AMOUNT_STANDARD_DEVIATION:
    'volume-risk-one-month-amount-standard-deviation',
  VOLUME_RISK_ONE_MONTH_AMOUNT_STANDARD_DEVIATION_CONFIGURED_THRESHOLD:
    'volume-risk-one-month-amount-standard-deviation-configured-threshold',
  VOLUME_RISK_ONE_MONTH_VOLUME_ACCELERATOR_THRESHOLD_MULTIPLIER:
    'volume-risk-one-month-volume-accelerator-threshold-multiplier',
  VOLUME_RISK_ONE_MONTH_AMOUNT_AVERAGE_DISTANCE_PERCENTAGE_THRESHOLD:
    'volume-risk-one-month-amount-average-distance-percentage-threshold',
  VOLUME_RISK_ONE_MONTH_AMOUNT_AVERAGE_DISTANCE_PERCENTAGE_VALUE:
    'volume-risk-one-month-amount-average-distance-percentage-value',
  VOLUME_RISK_DECISION_CASE_STATUS: 'volume-risk-decision-case-status',
  USAGE_RISK_SUSPICIOUS_WORDS: 'usage-risk-suspicious-words',
  USAGE_RISK_DECISION_CASE_STATUS: 'usage-risk-decision-case-status',
  REFERENCE_ACCOUNT_DECISION_CASE_STATUS: 'reference-account-decision-case-status',
  ORIGINATOR_ACCOUNT_NUMBER_EQUALS_REFERENCE_ACCOUNT_NUMBER:
    'originator-account-number-equals-reference-account-number',
  ORIGINATOR_ACCOUNT_NUMBER_COUNTRY_EQUALS_REFERENCE_ACCOUNT_NUMBER_COUNTRY:
    'originator-account-number-country-equals-reference-account-number-country',
  ORIGINATOR_ACCOUNT_HOLDER_NAME_EQUALS_REFERENCE_ACCOUNT_HOLDER_NAME:
    'originator-account-holder-name-equals-reference-account-holder-name',
  TRANSACTION_EXPECTED_BASED_ON_TIME_AND_AMOUNT: 'transaction-expected-based-on-time-and-amount',
  ORIGINATOR_ACCOUNT_BLACKLISTED: 'originator-account-blacklisted',
  BENEFICIARY_ACCOUNT_BLACKLISTED: 'beneficiary-account-blacklisted',
  ACCOUNT_BLACKLISTED_DECISION_CASE_STATUS: 'account-blacklisted-decision-case-status',
  ROLLING_MONTH_INCOME: 'rolling-month-income',
  ROLLING_MONTH_OUTCOME: 'rolling-month-outcome',
  FUNDS_PASSED_THROUGH_QUICKLY: 'funds-passed-through-quickly',
  SAME_FUNDS_SOURCE_COUNT_ROLLING_YEAR: 'same-funds-source-count-rolling-year',
  SAME_FUNDS_SOURCE_COUNT_EVER: 'same-funds-source-count-ever',
  AVERAGE_MONTH_INCOME: 'average-month-income',
  ROLLING_MONTH_INCOME_DIVIDED_BY_AVERAGE_MONTH_INCOME:
    'rolling-month-income-divided-by-average-month-income',
  ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_ROLLING_YEAR:
    'account-holding-party-transaction-count-rolling-year',
  ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_EVER: 'account-holding-party-transaction-count-ever',
  ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_AVG_PER_MONTH_ROLLING_YEAR:
    'account-holding-party-transaction-count-avg-per-month-rolling-year',
  ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_AVG_PER_MONTH_ROLLING_THREE_YEARS:
    'account-holding-party-transaction-count-avg-per-month-rolling-three-years',
  ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_AVG_PER_MONTH_EVER:
    'account-holding-party-transaction-count-avg-per-month-ever',
  ACCOUNT_HOLDING_PARTY_ACCOUNT_AGE_IN_DAYS: 'account-holding-party-account-age-in-days',
  ACCOUNT_HOLDING_PARTY_NATIONALITY_EQUAL_ACCOUNT_COUNTRY:
    'account-holding-party-nationality-equal-account-country',
  ACCOUNT_HOLDING_PARTY_AGE_IN_YEARS: 'account-holding-party-age-in-years',
  ORIGINATOR_DISTANCE_TO_NEXT_LABELED_MONEY_MULE: 'originator-distance-to-next-labeled-money-mule',
  BENEFICIARY_DISTANCE_TO_NEXT_LABELED_MONEY_MULE:
    'beneficiary-distance-to-next-labeled-money-mule',
  MONEY_MULE_DECISION_CASE_STATUS: 'money-mule-decision-case-status',
  ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_HIGH: 'account-holding-party-bank-risk-level-high',
  ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_LOW: 'account-holding-party-bank-risk-level-low',
  ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_MEDIUM: 'account-holding-party-bank-risk-level-medium',
  ACCOUNT_HOLDING_PARTY_FUNDS_BENEFICIARY: 'account-holding-party-funds-beneficiary',
  ACCOUNT_HOLDING_PARTY_FUNDS_ORIGINATOR: 'account-holding-party-funds-originator',
  ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK_MEDIUM: 'account-holding-party-nationality-risk-medium',
  ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK_HIGH: 'account-holding-party-nationality-risk-high',
  TRANSACTION_INITIATING_PARTY_FUNDS_ORIGINATOR: 'transaction-initiating-party-funds-originator',
  VOLUME_RISK_ONE_MONTH_VELOCITY_ACCELERATOR_THRESHOLD_MULTIPLIER:
    'volume-risk-one-month-velocity-accelerator-threshold-multiplier',
  ACCOUNT_HOLDING_PARTY_COUNTRY_OF_BIRTH_EQUALS_COUNTER_PARTY_COUNTRY_OF_IBAN:
    'account-holding-party-country-of-birth-equals-counter-party-country-of-iban',

  SEND_MONEY_HOME_TO_FAMILY: 'send-money-home-to-family',
  REFERENCE_ACCOUNT: 'reference-account',
  REFERENCE_ACCOUNT_MISMATCH: 'reference-account-mismatch',
  MONEY_MULE: 'money-mule',

  COUNTER_PARTY_COUNTRY_RISK_LEVEL: 'counter-party-country-risk-level',

  PASSTHROUGH_AMOUNT_THRESHOLD: 'passthrough-amount-threshold',
  AVERAGE_SPENDING_SIX_MONTHS: 'average-spending-six-months',
  RATIO_SPENDING_VS_AVERAGE_SPENDING_SIX_MONTHS: 'ratio-spending-vs-average-spending-six-months',
  PASSTHROUGH_INCREASE_AMOUNT_SPENDING_THRESHOLD: 'passthrough-increase-amount-spending-threshold',
  PASSTHROUGH_POTENTIAL_SOURCES: 'passthrough-potential-sources',
  PASSTHROUGH_MAX_NUM_OF_DAYS_THRESHOLD: 'passthrough-max-num-of-days-threshold',
  PASSTHROUGH_VARIANCE_INOUT_AMOUNT_THRESHOLD: 'passthrough-variance-inout-amount-threshold',
  PASSTHROUGH_FUTURE_AMOUNT_VARIANCE_THRESHOLD: 'passthrough-future-amount-variance-threshold',
  PASSTHROUGH_SCORE: 'passthrough-score',

  ANOMALY_SCORE: 'anomaly-score',
  FAN_IN_SCORE: 'fan-in-score',
  FAN_IN_SCORE_DEFAULT: 'fan-in-score-default',
  PHISHING_SCORE: 'phishing-score',

  SMURFING_TIMEFRAME: 'smurfing-timeframe',
  SMURFING_AMOUNT_THRESHOLD: 'smurfing-amount-threshold',
  SMURFING_AMOUNT_VARIANCE_THRESHOLD: 'smurfing-amount-variance-threshold',
  SMURFING_NUM_OF_TRANSACTIONS_TO_TRIGGER_HIT: 'smurfing-num-of-transactions-to-trigger-hit',
  SMURFING_TRANSACTION_TYPE: 'smurfing-transaction-type',
  SMURFING_POTENTIAL_TRANSACTIONS: 'smurfing-potential-transactions',
  SMURFING_POTENTIAL_TRANSACTIONS_COUNT: 'smurfing-potential-transactions-count',
  SMURFING_SCORE: 'smurfing-score',
  SMURFING_DECISION_CASE_STATUS: 'smurfing-decision-case-status',
};

// For account based
DataFeatureName.ACCOUNT_BASED = {
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_1_DAY: 'volume-account-holding-party-credit-1-day',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_2_DAYS: 'volume-account-holding-party-credit-2-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_3_DAYS: 'volume-account-holding-party-credit-3-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_4_DAYS: 'volume-account-holding-party-credit-4-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_5_DAYS: 'volume-account-holding-party-credit-5-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_6_DAYS: 'volume-account-holding-party-credit-6-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_1_WEEK: 'volume-account-holding-party-credit-1-week',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_2_WEEKS: 'volume-account-holding-party-credit-2-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_3_WEEKS: 'volume-account-holding-party-credit-3-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_1_MONTH: 'volume-account-holding-party-credit-1-month',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_2_MONTHS: 'volume-account-holding-party-credit-2-months',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_3_MONTHS: 'volume-account-holding-party-credit-3-months',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_1_DAY: 'volume-account-holding-party-debit-1-day',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_2_DAYS: 'volume-account-holding-party-debit-2-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_3_DAYS: 'volume-account-holding-party-debit-3-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_4_DAYS: 'volume-account-holding-party-debit-4-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_5_DAYS: 'volume-account-holding-party-debit-5-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_6_DAYS: 'volume-account-holding-party-debit-6-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_1_WEEK: 'volume-account-holding-party-debit-1-week',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_2_WEEKS: 'volume-account-holding-party-debit-2-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_3_WEEKS: 'volume-account-holding-party-debit-3-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_1_MONTH: 'volume-account-holding-party-debit-1-month',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_2_MONTHS: 'volume-account-holding-party-debit-2-months',
  VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_3_MONTHS: 'volume-account-holding-party-debit-3-months',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_1_DAY:
    'volume-account-holding-party-credit-or-debit-1-day',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_2_DAYS:
    'volume-account-holding-party-credit-or-debit-2-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_3_DAYS:
    'volume-account-holding-party-credit-or-debit-3-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_4_DAYS:
    'volume-account-holding-party-credit-or-debit-4-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_5_DAYS:
    'volume-account-holding-party-credit-or-debit-5-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_6_DAYS:
    'volume-account-holding-party-credit-or-debit-6-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_1_WEEK:
    'volume-account-holding-party-credit-or-debit-1-week',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_2_WEEKS:
    'volume-account-holding-party-credit-or-debit-2-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_3_WEEKS:
    'volume-account-holding-party-credit-or-debit-3-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_1_MONTH:
    'volume-account-holding-party-credit-or-debit-1-month',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_2_MONTHS:
    'volume-account-holding-party-credit-or-debit-2-months',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_3_MONTHS:
    'volume-account-holding-party-credit-or-debit-3-months',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_1_DAY:
    'volume-account-holding-party-credit-minus-debit-1-day',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_2_DAYS:
    'volume-account-holding-party-credit-minus-debit-2-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_3_DAYS:
    'volume-account-holding-party-credit-minus-debit-3-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_4_DAYS:
    'volume-account-holding-party-credit-minus-debit-4-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_5_DAYS:
    'volume-account-holding-party-credit-minus-debit-5-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_6_DAYS:
    'volume-account-holding-party-credit-minus-debit-6-days',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_1_WEEK:
    'volume-account-holding-party-credit-minus-debit-1-week',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_2_WEEKS:
    'volume-account-holding-party-credit-minus-debit-2-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_3_WEEKS:
    'volume-account-holding-party-credit-minus-debit-3-weeks',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_1_MONTH:
    'volume-account-holding-party-credit-minus-debit-1-month',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_2_MONTHS:
    'volume-account-holding-party-credit-minus-debit-2-months',
  VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_3_MONTHS:
    'volume-account-holding-party-credit-minus-debit-3-months',
};

// For transfer based
DataFeatureName.TRANSFER_BASED = {
  VOLUME_TRANSFER_CREDIT_1_DAY: 'volume-transfer-credit-1-day',
  VOLUME_TRANSFER_CREDIT_2_DAYS: 'volume-transfer-credit-2-days',
  VOLUME_TRANSFER_CREDIT_3_DAYS: 'volume-transfer-credit-3-days',
  VOLUME_TRANSFER_CREDIT_4_DAYS: 'volume-transfer-credit-4-days',
  VOLUME_TRANSFER_CREDIT_5_DAYS: 'volume-transfer-credit-5-days',
  VOLUME_TRANSFER_CREDIT_6_DAYS: 'volume-transfer-credit-6-days',
  VOLUME_TRANSFER_CREDIT_1_WEEK: 'volume-transfer-credit-1-week',
  VOLUME_TRANSFER_CREDIT_2_WEEKS: 'volume-transfer-credit-2-weeks',
  VOLUME_TRANSFER_CREDIT_3_WEEKS: 'volume-transfer-credit-3-weeks',
  VOLUME_TRANSFER_CREDIT_1_MONTH: 'volume-transfer-credit-1-month',
  VOLUME_TRANSFER_CREDIT_2_MONTHS: 'volume-transfer-credit-2-months',
  VOLUME_TRANSFER_CREDIT_3_MONTHS: 'volume-transfer-credit-3-months',
  VOLUME_TRANSFER_DEBIT_1_DAY: 'volume-transfer-debit-1-day',
  VOLUME_TRANSFER_DEBIT_2_DAYS: 'volume-transfer-debit-2-days',
  VOLUME_TRANSFER_DEBIT_3_DAYS: 'volume-transfer-debit-3-days',
  VOLUME_TRANSFER_DEBIT_4_DAYS: 'volume-transfer-debit-4-days',
  VOLUME_TRANSFER_DEBIT_5_DAYS: 'volume-transfer-debit-5-days',
  VOLUME_TRANSFER_DEBIT_6_DAYS: 'volume-transfer-debit-6-days',
  VOLUME_TRANSFER_DEBIT_1_WEEK: 'volume-transfer-debit-1-week',
  VOLUME_TRANSFER_DEBIT_2_WEEKS: 'volume-transfer-debit-2-weeks',
  VOLUME_TRANSFER_DEBIT_3_WEEKS: 'volume-transfer-debit-3-weeks',
  VOLUME_TRANSFER_DEBIT_1_MONTH: 'volume-transfer-debit-1-month',
  VOLUME_TRANSFER_DEBIT_2_MONTHS: 'volume-transfer-debit-2-months',
  VOLUME_TRANSFER_DEBIT_3_MONTHS: 'volume-transfer-debit-3-months',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_1_DAY: 'volume-transfer-credit-or-debit-1-day',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_2_DAYS: 'volume-transfer-credit-or-debit-2-days',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_3_DAYS: 'volume-transfer-credit-or-debit-3-days',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_4_DAYS: 'volume-transfer-credit-or-debit-4-days',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_5_DAYS: 'volume-transfer-credit-or-debit-5-days',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_6_DAYS: 'volume-transfer-credit-or-debit-6-days',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_1_WEEK: 'volume-transfer-credit-or-debit-1-week',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_2_WEEKS: 'volume-transfer-credit-or-debit-2-weeks',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_3_WEEKS: 'volume-transfer-credit-or-debit-3-weeks',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_1_MONTH: 'volume-transfer-credit-or-debit-1-month',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_2_MONTHS: 'volume-transfer-credit-or-debit-2-months',
  VOLUME_TRANSFER_CREDIT_OR_DEBIT_3_MONTHS: 'volume-transfer-credit-or-debit-3-months',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_1_DAY: 'volume-transfer-credit-minus-debit-1-day',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_2_DAYS: 'volume-transfer-credit-minus-debit-2-days',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_3_DAYS: 'volume-transfer-credit-minus-debit-3-days',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_4_DAYS: 'volume-transfer-credit-minus-debit-4-days',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_5_DAYS: 'volume-transfer-credit-minus-debit-5-days',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_6_DAYS: 'volume-transfer-credit-minus-debit-6-days',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_1_WEEK: 'volume-transfer-credit-minus-debit-1-week',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_2_WEEKS: 'volume-transfer-credit-minus-debit-2-weeks',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_3_WEEKS: 'volume-transfer-credit-minus-debit-3-weeks',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_1_MONTH: 'volume-transfer-credit-minus-debit-1-month',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_2_MONTHS: 'volume-transfer-credit-minus-debit-2-months',
  VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_3_MONTHS: 'volume-transfer-credit-minus-debit-3-months',
};

// For customer based
DataFeatureName.CUSTOMER_BASED = {
  VOLUME_CUSTOMER_ID_CREDIT_1_DAY: 'volume-customer-id-credit-1-day',
  VOLUME_CUSTOMER_ID_CREDIT_2_DAYS: 'volume-customer-id-credit-2-days',
  VOLUME_CUSTOMER_ID_CREDIT_3_DAYS: 'volume-customer-id-credit-3-days',
  VOLUME_CUSTOMER_ID_CREDIT_4_DAYS: 'volume-customer-id-credit-4-days',
  VOLUME_CUSTOMER_ID_CREDIT_5_DAYS: 'volume-customer-id-credit-5-days',
  VOLUME_CUSTOMER_ID_CREDIT_6_DAYS: 'volume-customer-id-credit-6-days',
  VOLUME_CUSTOMER_ID_CREDIT_1_WEEK: 'volume-customer-id-credit-1-week',
  VOLUME_CUSTOMER_ID_CREDIT_2_WEEKS: 'volume-customer-id-credit-2-weeks',
  VOLUME_CUSTOMER_ID_CREDIT_3_WEEKS: 'volume-customer-id-credit-3-weeks',
  VOLUME_CUSTOMER_ID_CREDIT_1_MONTH: 'volume-customer-id-credit-1-month',
  VOLUME_CUSTOMER_ID_CREDIT_2_MONTHS: 'volume-customer-id-credit-2-months',
  VOLUME_CUSTOMER_ID_CREDIT_3_MONTHS: 'volume-customer-id-credit-3-months',
  VOLUME_CUSTOMER_ID_DEBIT_1_DAY: 'volume-customer-id-debit-1-day',
  VOLUME_CUSTOMER_ID_DEBIT_2_DAYS: 'volume-customer-id-debit-2-days',
  VOLUME_CUSTOMER_ID_DEBIT_3_DAYS: 'volume-customer-id-debit-3-days',
  VOLUME_CUSTOMER_ID_DEBIT_4_DAYS: 'volume-customer-id-debit-4-days',
  VOLUME_CUSTOMER_ID_DEBIT_5_DAYS: 'volume-customer-id-debit-5-days',
  VOLUME_CUSTOMER_ID_DEBIT_6_DAYS: 'volume-customer-id-debit-6-days',
  VOLUME_CUSTOMER_ID_DEBIT_1_WEEK: 'volume-customer-id-debit-1-week',
  VOLUME_CUSTOMER_ID_DEBIT_2_WEEKS: 'volume-customer-id-debit-2-weeks',
  VOLUME_CUSTOMER_ID_DEBIT_3_WEEKS: 'volume-customer-id-debit-3-weeks',
  VOLUME_CUSTOMER_ID_DEBIT_1_MONTH: 'volume-customer-id-debit-1-month',
  VOLUME_CUSTOMER_ID_DEBIT_2_MONTHS: 'volume-customer-id-debit-2-months',
  VOLUME_CUSTOMER_ID_DEBIT_3_MONTHS: 'volume-customer-id-debit-3-months',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_1_DAY: 'volume-customer-id-credit-or-debit-1-day',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_2_DAYS: 'volume-customer-id-credit-or-debit-2-days',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_3_DAYS: 'volume-customer-id-credit-or-debit-3-days',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_4_DAYS: 'volume-customer-id-credit-or-debit-4-days',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_5_DAYS: 'volume-customer-id-credit-or-debit-5-days',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_6_DAYS: 'volume-customer-id-credit-or-debit-6-days',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_1_WEEK: 'volume-customer-id-credit-or-debit-1-week',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_2_WEEKS: 'volume-customer-id-credit-or-debit-2-weeks',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_3_WEEKS: 'volume-customer-id-credit-or-debit-3-weeks',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_1_MONTH: 'volume-customer-id-credit-or-debit-1-month',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_2_MONTHS: 'volume-customer-id-credit-or-debit-2-months',
  VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_3_MONTHS: 'volume-customer-id-credit-or-debit-3-months',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_1_DAY: 'volume-customer-id-credit-minus-debit-1-day',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_2_DAYS: 'volume-customer-id-credit-minus-debit-2-days',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_3_DAYS: 'volume-customer-id-credit-minus-debit-3-days',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_4_DAYS: 'volume-customer-id-credit-minus-debit-4-days',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_5_DAYS: 'volume-customer-id-credit-minus-debit-5-days',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_6_DAYS: 'volume-customer-id-credit-minus-debit-6-days',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_1_WEEK: 'volume-customer-id-credit-minus-debit-1-week',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_2_WEEKS: 'volume-customer-id-credit-minus-debit-2-weeks',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_3_WEEKS: 'volume-customer-id-credit-minus-debit-3-weeks',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_1_MONTH: 'volume-customer-id-credit-minus-debit-1-month',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_2_MONTHS: 'volume-customer-id-credit-minus-debit-2-months',
  VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_3_MONTHS: 'volume-customer-id-credit-minus-debit-3-months',
};

// For customer group based
DataFeatureName.CUSTOMER_GROUP_BASED = {
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_1_DAY: 'volume-customer-group-id-credit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_2_DAYS: 'volume-customer-group-id-credit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_3_DAYS: 'volume-customer-group-id-credit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_4_DAYS: 'volume-customer-group-id-credit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_5_DAYS: 'volume-customer-group-id-credit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_6_DAYS: 'volume-customer-group-id-credit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_1_WEEK: 'volume-customer-group-id-credit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_2_WEEKS: 'volume-customer-group-id-credit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_3_WEEKS: 'volume-customer-group-id-credit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_1_MONTH: 'volume-customer-group-id-credit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_2_MONTHS: 'volume-customer-group-id-credit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_3_MONTHS: 'volume-customer-group-id-credit-3-months',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_1_DAY: 'volume-customer-group-id-debit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_2_DAYS: 'volume-customer-group-id-debit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_3_DAYS: 'volume-customer-group-id-debit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_4_DAYS: 'volume-customer-group-id-debit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_5_DAYS: 'volume-customer-group-id-debit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_6_DAYS: 'volume-customer-group-id-debit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_1_WEEK: 'volume-customer-group-id-debit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_2_WEEKS: 'volume-customer-group-id-debit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_3_WEEKS: 'volume-customer-group-id-debit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_1_MONTH: 'volume-customer-group-id-debit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_2_MONTHS: 'volume-customer-group-id-debit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_DEBIT_3_MONTHS: 'volume-customer-group-id-debit-3-months',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_1_DAY: 'volume-customer-group-id-credit-or-debit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_2_DAYS:
    'volume-customer-group-id-credit-or-debit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_3_DAYS:
    'volume-customer-group-id-credit-or-debit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_4_DAYS:
    'volume-customer-group-id-credit-or-debit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_5_DAYS:
    'volume-customer-group-id-credit-or-debit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_6_DAYS:
    'volume-customer-group-id-credit-or-debit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_1_WEEK:
    'volume-customer-group-id-credit-or-debit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_2_WEEKS:
    'volume-customer-group-id-credit-or-debit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_3_WEEKS:
    'volume-customer-group-id-credit-or-debit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_1_MONTH:
    'volume-customer-group-id-credit-or-debit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_2_MONTHS:
    'volume-customer-group-id-credit-or-debit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_3_MONTHS:
    'volume-customer-group-id-credit-or-debit-3-months',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_1_DAY:
    'volume-customer-group-id-credit-minus-debit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_2_DAYS:
    'volume-customer-group-id-credit-minus-debit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_3_DAYS:
    'volume-customer-group-id-credit-minus-debit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_4_DAYS:
    'volume-customer-group-id-credit-minus-debit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_5_DAYS:
    'volume-customer-group-id-credit-minus-debit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_6_DAYS:
    'volume-customer-group-id-credit-minus-debit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_1_WEEK:
    'volume-customer-group-id-credit-minus-debit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_2_WEEKS:
    'volume-customer-group-id-credit-minus-debit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_3_WEEKS:
    'volume-customer-group-id-credit-minus-debit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_1_MONTH:
    'volume-customer-group-id-credit-minus-debit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_2_MONTHS:
    'volume-customer-group-id-credit-minus-debit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_3_MONTHS:
    'volume-customer-group-id-credit-minus-debit-3-months',
};

// FOR ACOUNTER PARTY VOLUME RULES
DataFeatureName.COUNTRY_RISK_TRANSFER_BASED = {
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY:
    'volume-transfer-counter-party-country-risk-low-credit-1-day',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS:
    'volume-transfer-counter-party-country-risk-low-credit-2-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS:
    'volume-transfer-counter-party-country-risk-low-credit-3-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS:
    'volume-transfer-counter-party-country-risk-low-credit-4-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS:
    'volume-transfer-counter-party-country-risk-low-credit-5-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS:
    'volume-transfer-counter-party-country-risk-low-credit-6-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK:
    'volume-transfer-counter-party-country-risk-low-credit-1-week',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS:
    'volume-transfer-counter-party-country-risk-low-credit-2-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS:
    'volume-transfer-counter-party-country-risk-low-credit-3-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH:
    'volume-transfer-counter-party-country-risk-low-credit-1-month',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS:
    'volume-transfer-counter-party-country-risk-low-credit-2-months',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS:
    'volume-transfer-counter-party-country-risk-low-credit-3-months',

  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY:
    'volume-transfer-counter-party-country-risk-low-debit-1-day',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS:
    'volume-transfer-counter-party-country-risk-low-debit-2-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS:
    'volume-transfer-counter-party-country-risk-low-debit-3-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS:
    'volume-transfer-counter-party-country-risk-low-debit-4-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS:
    'volume-transfer-counter-party-country-risk-low-debit-5-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS:
    'volume-transfer-counter-party-country-risk-low-debit-6-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK:
    'volume-transfer-counter-party-country-risk-low-debit-1-week',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS:
    'volume-transfer-counter-party-country-risk-low-debit-2-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS:
    'volume-transfer-counter-party-country-risk-low-debit-3-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH:
    'volume-transfer-counter-party-country-risk-low-debit-1-month',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS:
    'volume-transfer-counter-party-country-risk-low-debit-2-months',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS:
    'volume-transfer-counter-party-country-risk-low-debit-3-months',

  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY:
    'volume-transfer-counter-party-country-risk-medium-credit-1-day',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS:
    'volume-transfer-counter-party-country-risk-medium-credit-2-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS:
    'volume-transfer-counter-party-country-risk-medium-credit-3-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS:
    'volume-transfer-counter-party-country-risk-medium-credit-4-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS:
    'volume-transfer-counter-party-country-risk-medium-credit-5-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS:
    'volume-transfer-counter-party-country-risk-medium-credit-6-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK:
    'volume-transfer-counter-party-country-risk-medium-credit-1-week',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS:
    'volume-transfer-counter-party-country-risk-medium-credit-2-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS:
    'volume-transfer-counter-party-country-risk-medium-credit-3-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH:
    'volume-transfer-counter-party-country-risk-medium-credit-1-month',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS:
    'volume-transfer-counter-party-country-risk-medium-credit-2-months',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS:
    'volume-transfer-counter-party-country-risk-medium-credit-3-months',

  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY:
    'volume-transfer-counter-party-country-risk-medium-debit-1-day',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS:
    'volume-transfer-counter-party-country-risk-medium-debit-2-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS:
    'volume-transfer-counter-party-country-risk-medium-debit-3-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS:
    'volume-transfer-counter-party-country-risk-medium-debit-4-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS:
    'volume-transfer-counter-party-country-risk-medium-debit-5-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS:
    'volume-transfer-counter-party-country-risk-medium-debit-6-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK:
    'volume-transfer-counter-party-country-risk-medium-debit-1-week',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS:
    'volume-transfer-counter-party-country-risk-medium-debit-2-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS:
    'volume-transfer-counter-party-country-risk-medium-debit-3-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH:
    'volume-transfer-counter-party-country-risk-medium-debit-1-month',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS:
    'volume-transfer-counter-party-country-risk-medium-debit-2-months',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS:
    'volume-transfer-counter-party-country-risk-medium-debit-3-months',

  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY:
    'volume-transfer-counter-party-country-risk-high-credit-1-day',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS:
    'volume-transfer-counter-party-country-risk-high-credit-2-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS:
    'volume-transfer-counter-party-country-risk-high-credit-3-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS:
    'volume-transfer-counter-party-country-risk-high-credit-4-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS:
    'volume-transfer-counter-party-country-risk-high-credit-5-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS:
    'volume-transfer-counter-party-country-risk-high-credit-6-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK:
    'volume-transfer-counter-party-country-risk-high-credit-1-week',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS:
    'volume-transfer-counter-party-country-risk-high-credit-2-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS:
    'volume-transfer-counter-party-country-risk-high-credit-3-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH:
    'volume-transfer-counter-party-country-risk-high-credit-1-month',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS:
    'volume-transfer-counter-party-country-risk-high-credit-2-months',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS:
    'volume-transfer-counter-party-country-risk-high-credit-3-months',

  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY:
    'volume-transfer-counter-party-country-risk-high-debit-1-day',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS:
    'volume-transfer-counter-party-country-risk-high-debit-2-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS:
    'volume-transfer-counter-party-country-risk-high-debit-3-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS:
    'volume-transfer-counter-party-country-risk-high-debit-4-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS:
    'volume-transfer-counter-party-country-risk-high-debit-5-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS:
    'volume-transfer-counter-party-country-risk-high-debit-6-days',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK:
    'volume-transfer-counter-party-country-risk-high-debit-1-week',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS:
    'volume-transfer-counter-party-country-risk-high-debit-2-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS:
    'volume-transfer-counter-party-country-risk-high-debit-3-weeks',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH:
    'volume-transfer-counter-party-country-risk-high-debit-1-month',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS:
    'volume-transfer-counter-party-country-risk-high-debit-2-months',
  VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS:
    'volume-transfer-counter-party-country-risk-high-debit-3-months',
};

DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED = {
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY:
    'volume-counter-party-country-risk-low-credit-1-day',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS:
    'volume-counter-party-country-risk-low-credit-2-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS:
    'volume-counter-party-country-risk-low-credit-3-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS:
    'volume-counter-party-country-risk-low-credit-4-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS:
    'volume-counter-party-country-risk-low-credit-5-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS:
    'volume-counter-party-country-risk-low-credit-6-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK:
    'volume-counter-party-country-risk-low-credit-1-week',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS:
    'volume-counter-party-country-risk-low-credit-2-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS:
    'volume-counter-party-country-risk-low-credit-3-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH:
    'volume-counter-party-country-risk-low-credit-1-month',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS:
    'volume-counter-party-country-risk-low-credit-2-months',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS:
    'volume-counter-party-country-risk-low-credit-3-months',

  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY:
    'volume-counter-party-country-risk-low-debit-1-day',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS:
    'volume-counter-party-country-risk-low-debit-2-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS:
    'volume-counter-party-country-risk-low-debit-3-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS:
    'volume-counter-party-country-risk-low-debit-4-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS:
    'volume-counter-party-country-risk-low-debit-5-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS:
    'volume-counter-party-country-risk-low-debit-6-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK:
    'volume-counter-party-country-risk-low-debit-1-week',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS:
    'volume-counter-party-country-risk-low-debit-2-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS:
    'volume-counter-party-country-risk-low-debit-3-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH:
    'volume-counter-party-country-risk-low-debit-1-month',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS:
    'volume-counter-party-country-risk-low-debit-2-months',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS:
    'volume-counter-party-country-risk-low-debit-3-months',

  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY:
    'volume-counter-party-country-risk-medium-credit-1-day',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS:
    'volume-counter-party-country-risk-medium-credit-2-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS:
    'volume-counter-party-country-risk-medium-credit-3-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS:
    'volume-counter-party-country-risk-medium-credit-4-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS:
    'volume-counter-party-country-risk-medium-credit-5-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS:
    'volume-counter-party-country-risk-medium-credit-6-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK:
    'volume-counter-party-country-risk-medium-credit-1-week',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS:
    'volume-counter-party-country-risk-medium-credit-2-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS:
    'volume-counter-party-country-risk-medium-credit-3-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH:
    'volume-counter-party-country-risk-medium-credit-1-month',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS:
    'volume-counter-party-country-risk-medium-credit-2-months',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS:
    'volume-counter-party-country-risk-medium-credit-3-months',

  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY:
    'volume-counter-party-country-risk-medium-debit-1-day',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS:
    'volume-counter-party-country-risk-medium-debit-2-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS:
    'volume-counter-party-country-risk-medium-debit-3-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS:
    'volume-counter-party-country-risk-medium-debit-4-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS:
    'volume-counter-party-country-risk-medium-debit-5-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS:
    'volume-counter-party-country-risk-medium-debit-6-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK:
    'volume-counter-party-country-risk-medium-debit-1-week',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS:
    'volume-counter-party-country-risk-medium-debit-2-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS:
    'volume-counter-party-country-risk-medium-debit-3-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH:
    'volume-counter-party-country-risk-medium-debit-1-month',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS:
    'volume-counter-party-country-risk-medium-debit-2-months',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS:
    'volume-counter-party-country-risk-medium-debit-3-months',

  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY:
    'volume-counter-party-country-risk-high-credit-1-day',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS:
    'volume-counter-party-country-risk-high-credit-2-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS:
    'volume-counter-party-country-risk-high-credit-3-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS:
    'volume-counter-party-country-risk-high-credit-4-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS:
    'volume-counter-party-country-risk-high-credit-5-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS:
    'volume-counter-party-country-risk-high-credit-6-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK:
    'volume-counter-party-country-risk-high-credit-1-week',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS:
    'volume-counter-party-country-risk-high-credit-2-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS:
    'volume-counter-party-country-risk-high-credit-3-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH:
    'volume-counter-party-country-risk-high-credit-1-month',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS:
    'volume-counter-party-country-risk-high-credit-2-months',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS:
    'volume-counter-party-country-risk-high-credit-3-months',

  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY:
    'volume-counter-party-country-risk-high-debit-1-day',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS:
    'volume-counter-party-country-risk-high-debit-2-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS:
    'volume-counter-party-country-risk-high-debit-3-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS:
    'volume-counter-party-country-risk-high-debit-4-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS:
    'volume-counter-party-country-risk-high-debit-5-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS:
    'volume-counter-party-country-risk-high-debit-6-days',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK:
    'volume-counter-party-country-risk-high-debit-1-week',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS:
    'volume-counter-party-country-risk-high-debit-2-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS:
    'volume-counter-party-country-risk-high-debit-3-weeks',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH:
    'volume-counter-party-country-risk-high-debit-1-month',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS:
    'volume-counter-party-country-risk-high-debit-2-months',
  VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS:
    'volume-counter-party-country-risk-high-debit-3-months',
};

DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED = {
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY:
    'volume-customer-id-counter-party-country-risk-low-credit-1-day',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS:
    'volume-customer-id-counter-party-country-risk-low-credit-2-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS:
    'volume-customer-id-counter-party-country-risk-low-credit-3-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS:
    'volume-customer-id-counter-party-country-risk-low-credit-4-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS:
    'volume-customer-id-counter-party-country-risk-low-credit-5-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS:
    'volume-customer-id-counter-party-country-risk-low-credit-6-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK:
    'volume-customer-id-counter-party-country-risk-low-credit-1-week',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS:
    'volume-customer-id-counter-party-country-risk-low-credit-2-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS:
    'volume-customer-id-counter-party-country-risk-low-credit-3-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH:
    'volume-customer-id-counter-party-country-risk-low-credit-1-month',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS:
    'volume-customer-id-counter-party-country-risk-low-credit-2-months',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS:
    'volume-customer-id-counter-party-country-risk-low-credit-3-months',

  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY:
    'volume-customer-id-counter-party-country-risk-low-debit-1-day',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS:
    'volume-customer-id-counter-party-country-risk-low-debit-2-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS:
    'volume-customer-id-counter-party-country-risk-low-debit-3-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS:
    'volume-customer-id-counter-party-country-risk-low-debit-4-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS:
    'volume-customer-id-counter-party-country-risk-low-debit-5-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS:
    'volume-customer-id-counter-party-country-risk-low-debit-6-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK:
    'volume-customer-id-counter-party-country-risk-low-debit-1-week',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS:
    'volume-customer-id-counter-party-country-risk-low-debit-2-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS:
    'volume-customer-id-counter-party-country-risk-low-debit-3-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH:
    'volume-customer-id-counter-party-country-risk-low-debit-1-month',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS:
    'volume-customer-id-counter-party-country-risk-low-debit-2-months',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS:
    'volume-customer-id-counter-party-country-risk-low-debit-3-months',

  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY:
    'volume-customer-id-counter-party-country-risk-medium-credit-1-day',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-credit-2-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-credit-3-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-credit-4-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-credit-5-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-credit-6-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK:
    'volume-customer-id-counter-party-country-risk-medium-credit-1-week',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS:
    'volume-customer-id-counter-party-country-risk-medium-credit-2-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS:
    'volume-customer-id-counter-party-country-risk-medium-credit-3-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH:
    'volume-customer-id-counter-party-country-risk-medium-credit-1-month',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS:
    'volume-customer-id-counter-party-country-risk-medium-credit-2-months',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS:
    'volume-customer-id-counter-party-country-risk-medium-credit-3-months',

  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY:
    'volume-customer-id-counter-party-country-risk-medium-debit-1-day',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-debit-2-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-debit-3-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-debit-4-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-debit-5-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS:
    'volume-customer-id-counter-party-country-risk-medium-debit-6-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK:
    'volume-customer-id-counter-party-country-risk-medium-debit-1-week',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS:
    'volume-customer-id-counter-party-country-risk-medium-debit-2-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS:
    'volume-customer-id-counter-party-country-risk-medium-debit-3-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH:
    'volume-customer-id-counter-party-country-risk-medium-debit-1-month',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS:
    'volume-customer-id-counter-party-country-risk-medium-debit-2-months',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS:
    'volume-customer-id-counter-party-country-risk-medium-debit-3-months',

  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY:
    'volume-customer-id-counter-party-country-risk-high-credit-1-day',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS:
    'volume-customer-id-counter-party-country-risk-high-credit-2-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS:
    'volume-customer-id-counter-party-country-risk-high-credit-3-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS:
    'volume-customer-id-counter-party-country-risk-high-credit-4-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS:
    'volume-customer-id-counter-party-country-risk-high-credit-5-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS:
    'volume-customer-id-counter-party-country-risk-high-credit-6-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK:
    'volume-customer-id-counter-party-country-risk-high-credit-1-week',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS:
    'volume-customer-id-counter-party-country-risk-high-credit-2-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS:
    'volume-customer-id-counter-party-country-risk-high-credit-3-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH:
    'volume-customer-id-counter-party-country-risk-high-credit-1-month',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS:
    'volume-customer-id-counter-party-country-risk-high-credit-2-months',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS:
    'volume-customer-id-counter-party-country-risk-high-credit-3-months',

  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY:
    'volume-customer-id-counter-party-country-risk-high-debit-1-day',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS:
    'volume-customer-id-counter-party-country-risk-high-debit-2-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS:
    'volume-customer-id-counter-party-country-risk-high-debit-3-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS:
    'volume-customer-id-counter-party-country-risk-high-debit-4-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS:
    'volume-customer-id-counter-party-country-risk-high-debit-5-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS:
    'volume-customer-id-counter-party-country-risk-high-debit-6-days',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK:
    'volume-customer-id-counter-party-country-risk-high-debit-1-week',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS:
    'volume-customer-id-counter-party-country-risk-high-debit-2-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS:
    'volume-customer-id-counter-party-country-risk-high-debit-3-weeks',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH:
    'volume-customer-id-counter-party-country-risk-high-debit-1-month',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS:
    'volume-customer-id-counter-party-country-risk-high-debit-2-months',
  VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS:
    'volume-customer-id-counter-party-country-risk-high-debit-3-months',
};

DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED = {
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY:
    'volume-customer-group-id-counter-party-country-risk-low-credit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK:
    'volume-customer-group-id-counter-party-country-risk-low-credit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH:
    'volume-customer-group-id-counter-party-country-risk-low-credit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-low-credit-3-months',

  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY:
    'volume-customer-group-id-counter-party-country-risk-low-debit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK:
    'volume-customer-group-id-counter-party-country-risk-low-debit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH:
    'volume-customer-group-id-counter-party-country-risk-low-debit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-low-debit-3-months',

  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-medium-credit-3-months',

  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-medium-debit-3-months',

  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY:
    'volume-customer-group-id-counter-party-country-risk-high-credit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK:
    'volume-customer-group-id-counter-party-country-risk-high-credit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH:
    'volume-customer-group-id-counter-party-country-risk-high-credit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-high-credit-3-months',

  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY:
    'volume-customer-group-id-counter-party-country-risk-high-debit-1-day',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-2-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-3-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-4-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-5-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-6-days',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK:
    'volume-customer-group-id-counter-party-country-risk-high-debit-1-week',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-2-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-3-weeks',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH:
    'volume-customer-group-id-counter-party-country-risk-high-debit-1-month',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-2-months',
  VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS:
    'volume-customer-group-id-counter-party-country-risk-high-debit-3-months',
};

export default DataFeatureName;
