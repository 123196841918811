export const CounterPartyFrequencyConfigActions = {
  GET: 'COUNTER_PARTY_FREQUENCY_CONFIG_GET',
  GET_SUCCESS: 'COUNTER_PARTY_FREQUENCY_CONFIG_GET_SUCCESS',
  GET_ERROR: 'COUNTER_PARTY_FREQUENCY_CONFIG_GET_ERROR',
  UPDATE: 'COUNTER_PARTY_FREQUENCY_CONFIG_UPDATE',
  UPDATE_SUCCESS: 'COUNTER_PARTY_FREQUENCY_CONFIG_UPDATE_SUCCESS',
  UPDATE_ERROR: 'COUNTER_PARTY_FREQUENCY_CONFIG_UPDATE_ERROR',
  DELETE: 'COUNTER_PARTY_FREQUENCY_CONFIG_DELETE',
  DELETE_SUCCESS: 'COUNTER_PARTY_FREQUENCY_CONFIG_DELETE_SUCCESS',
  DELETE_ERROR: 'COUNTER_PARTY_FREQUENCY_CONFIG_DELETE_ERROR',
};
