import { CASE_ACTIONS } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import { updateMasks } from 'middleware/actions/mask';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

import {
  getAllCasesError,
  getAllCasesSuccess,
} from 'modules/trx-cases/case-list/middleware/actions/case-list';
import CaseListAPICommunicator from 'modules/trx-cases/case-list/services/case-list/case-list-api-communicator';
import CaseListManager from 'modules/trx-cases/case-list/services/case-list/case-list-manager';
import { put, takeLatest } from 'redux-saga/effects';
import { getMaskedsFromCases } from 'utils/helpers/cases/case-utility';
import { GroupSearchRequest } from 'models/case/search/group-search-request';

const caseCommunicator = new CaseListAPICommunicator();
const caseManager = new CaseListManager(caseCommunicator);

/** getting all cases */
function* getAllCasesBegin(action: { payload: GroupSearchRequest }): Generator {
  try {
    const { payload } = action;
    yield put(startProcessing(CASE_ACTIONS.allCases.fetch.data));

    const json = yield caseManager.queryAllCases(
      payload.paging,
      payload.sorting,
      payload.filtering,
      payload.allMaskedDataShown,
    );

    yield put(getAllCasesSuccess(json));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(updateMasks(getMaskedsFromCases(json?.cases)));
    yield put(stopProcessing(CASE_ACTIONS.allCases.fetch.data));
  } catch (error: any) {
    yield put(getAllCasesError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CASE_ACTIONS.allCases.fetch.data));
  }
}

function* getAllCasesWatcher() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  yield takeLatest(CASE_ACTIONS.allCases.fetch.data, getAllCasesBegin);
}

export { getAllCasesWatcher };
