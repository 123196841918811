import { Box, Checkbox, Fade, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { DIRECTION } from 'utils/constants/sort-directions';
import { HeadCell } from 'modules/customer-data/types';
import React from 'react';

type DirectionType = keyof typeof DIRECTION;

interface SortingOption {
  field: string;
  direction: 'asc' | 'ASC' | 'desc' | 'DESC';
}

interface EnhancedTableHeadProps {
  headCells: HeadCell[];
  sortingOptions?: SortingOption[];
  onRequestSort?: (event: unknown, headCell: HeadCell, direction?: string) => void;
  selectable?: boolean;
  onToggleSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  expandable?: boolean;
  selectedLength?: number;
  totalListLength?: number;
}

const EnhancedTableHead = ({
  headCells,
  sortingOptions,
  onRequestSort,
  selectable = false,
  onToggleSelectAll,
  expandable = false,
  selectedLength,
  totalListLength,
}: EnhancedTableHeadProps) => {
  const toggleSort = (direction: string): DirectionType =>
    direction.toLowerCase() === DIRECTION.asc ? DIRECTION.desc : DIRECTION.asc;

  const createSortHandler = (property: HeadCell, direction?: string) => (event: unknown) => {
    if (onRequestSort) {
      onRequestSort(event, property, direction);
    }
  };

  const determineSortDirection = (
    headCell: HeadCell,
    sortProperty: SortingOption,
  ): DirectionType => {
    if (headCell.sortConfig.inverseSortIcon) {
      return toggleSort(sortProperty.direction);
    }
    return sortProperty.direction.toLowerCase() as DirectionType;
  };

  return (
    <TableHead>
      <TableRow>
        {expandable && <TableCell padding="checkbox" />}
        {selectable && (
          <TableCell padding="none">
            <Checkbox
              size="small"
              checked={selectedLength === totalListLength}
              indeterminate={selectedLength !== 0 && selectedLength !== totalListLength}
              onChange={onToggleSelectAll}
            />
          </TableCell>
        )}
        {headCells.map((headCell, index) => {
          const sortProperty = sortingOptions?.find(
            (sortItem) => sortItem.field === headCell.sortConfig?.name,
          );
          return (
            <Fade
              key={headCell.id ?? index}
              in={headCell.isVisible}
              className={!headCell.isVisible ? 'd-none' : ''}
            >
              <TableCell
                size="medium"
                sx={selectable && index === 0 ? { paddingLeft: 0 } : {}}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={
                  sortProperty ? determineSortDirection(headCell, sortProperty) : false
                }
              >
                {headCell.sortConfig?.isEnabled ? (
                  <TableSortLabel
                    active={Boolean(sortProperty)}
                    direction={
                      sortProperty ? determineSortDirection(headCell, sortProperty) : DIRECTION.asc
                    }
                    onClick={createSortHandler(headCell, sortProperty?.direction)}
                  >
                    <Box sx={{ fontWeight: sortProperty ? 'bold' : 'regular' }}>
                      {headCell.label}
                    </Box>
                  </TableSortLabel>
                ) : (
                  <Box sx={{ fontWeight: 'regular' }}>{headCell.label}</Box>
                )}
              </TableCell>
            </Fade>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export { EnhancedTableHead };
