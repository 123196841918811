import i18next from 'i18next';

export interface DateRange {
  from?: Date;
  to?: Date;
}

export interface ValidationErrors {
  [key: string]: string;
}

export const validateDateRange = (fieldValues: DateRange, values: DateRange): ValidationErrors => {
  const errors: ValidationErrors = {};
  if ('from' in fieldValues && !fieldValues.from) {
    errors.from = i18next.t('required.heading');
  }
  if ('to' in fieldValues && !fieldValues.to) {
    errors.to = i18next.t('required.heading');
  }
  const currentValues: DateRange = { ...values, ...fieldValues };
  if (currentValues.from && currentValues.to && currentValues.from > currentValues.to) {
    errors.to = i18next.t('date.error.invalidRange.heading');
  }
  return errors;
};
