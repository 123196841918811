import { Grid, Typography } from '@mui/material';
import LoginButton from 'modules/authentication/login/components/login-button';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LoginViewV2Props {
  onClick: CallableFunction;
  applicationLogo: string | undefined;
}

const LoginViewV2: FC<LoginViewV2Props> = ({ onClick, applicationLogo }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      className="bg-background"
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        item
        className="bg-white login-window"
        sx={{ width: { sm: '398px', xs: '100%' }, height: { sm: '540px', xs: '100%' } }}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        flexDirection="column"
        gap={3}
      >
        <>
          {applicationLogo && <img className="logo" src={applicationLogo} alt="logo" />}
          <Grid
            item
            container
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <Typography variant="h3">{t('appTitle.heading')}</Typography>
            <Typography variant="body1">{t('appTitle.subheading')}</Typography>
          </Grid>
          <LoginButton onClick={() => onClick()} />
        </>
      </Grid>
    </Grid>
  );
};

export default LoginViewV2;
