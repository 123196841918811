export const SftpConfigActions = {
  SFTP_GET: 'SFTP_GET',
  SFTP_GET_SUCCESS: 'SFTP_GET_SUCCESS',
  SFTP_GET_ERROR: 'SFTP_GET_ERROR',
  SFTP_UPDATE: 'SFTP_UPDATE',
  SFTP_UPDATE_SUCCESS: 'SFTP_UPDATE_SUCCESS',
  SFTP_UPDATE_ERROR: 'SFTP_UPDATE_ERROR',
  SFTP_DELETE: 'SFTP_DELETE',
  SFTP_DELETE_SUCCESS: 'SFTP_DELETE_SUCCESS',
  SFTP_DELETE_ERROR: 'SFTP_DELETE_ERROR',
  SFTP_GET_UNMASKED: 'SFTP_GET_UNMASKED',
  SFTP_GET_UNMASKED_SUCCESS: 'SFTP_GET_UNMASKED_SUCCESS',
  SFTP_GET_UNMASKED_ERROR: 'SFTP_GET_UNMASKED_ERROR',
};
