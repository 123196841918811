import { Grid, ListItemText, Typography } from '@mui/material';
import { RiskChip } from 'components/chips/risk-chip';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCountryName } from 'utils/constants/country-list';
import { getCountryRiskLevel, getSettingsFromState } from 'utils/helpers/config-utils/config-util';

const CountryRiskDetails = ({ maxPoints, scoreData, popoverMode, title, caseConfig }) => {
  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);

    let countryRisk = settings?.countryRisk;

    const countryListId = scoreData?.RISK_FACTOR_INSTANCE?.settings?.countryListId;
    if (countryListId !== 'default') {
      countryRisk = settings?.instanceableCountryRisks?.countries?.find(
        (it) => it.uniqueId === countryListId,
      );
    }

    return countryRisk;
  });
  return (
    <Translation>
      {(t) => (
        <ScoreDetailsAccordion
          summaryComponent={
            <WheelPointsWithHeadline
              size={popoverMode ? 'small' : 'medium'}
              points={scoreData.CALCULATED_POINTS ?? 0}
              maxPoints={maxPoints}
              headline={title}
            />
          }
          highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
          detailsComponent={
            <Grid container justifyContent="space-between" className="pb-1">
              {scoreData.ORIGINAL_DATA &&
                scoreData.ORIGINAL_DATA.map((it, k) => (
                  <Grid container item key={`cr-label-${k}`} direction="row">
                    <ListItemText
                      primary={
                        <Typography variant="body2">
                          {t(
                            'configuration:customerRiskRating.riskFactor.factors.countryRisk.role.heading',
                          )}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {it.role ? it.role : '-'}
                        </Typography>
                      }
                    />
                    <RiskChip
                      size="small"
                      label={getCountryName(it.countryCode)}
                      risk={getCountryRiskLevel(it.countryCode, countryRisk)}
                      dataTestIdPrefix={`country-risk-${scoreData?.RISK_FACTOR_INSTANCE?.uniqueId}`}
                    />
                  </Grid>
                ))}
            </Grid>
          }
        />
      )}
    </Translation>
  );
};

export { CountryRiskDetails };
