import i18next from 'i18next';
import { showError } from 'middleware/actions/error';
import { showNotification } from 'middleware/actions/notification';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { TENANT_CONFIG_ACTIONS } from 'modules/configuration/workflows-and-users/middleware/action-types';
import {
  addMasterTenantError,
  addMasterTenantSuccess,
  addTenantError,
  addTenantSuccess,
  addTenantToUsers,
  addTenantToUsersSuccess,
  getAllTenantsError,
  getAllTenantsSuccess,
  getMasterTenantCreationStatus,
  getMasterTenantsError,
  getMasterTenantsSuccess,
  getTenantCallbackConfigSecretError,
  getTenantCallbackConfigSecretSuccess,
  getTenantScorechainConfigSecretSuccess,
  getUsers,
  getUsersError,
  getUsersNotInTenant,
  getUsersNotInTenantError,
  getUsersNotInTenantSuccess,
  getUsersSuccess,
  putTenantCallbackConfigError,
  putTenantCallbackConfigSuccess,
  putTenantScorechainConfigError,
  putTenantScorechainConfigSuccess,
  getTenantScorechainConfigSecretError,
  updateTenantStructureError,
  updateTenantStructureSuccess,
  updateUserError,
  updateUserSuccess,
} from 'modules/configuration/workflows-and-users/middleware/actions/tenant-config';
import RuleConfigAPICommunicator from 'modules/configuration/workflows-and-users/services/tenant-config-api-communicator';
import TenantConfigManager from 'modules/configuration/workflows-and-users/services/tenant-config-manager';
import { delay, put, call, takeLatest } from 'redux-saga/effects';

const communicator = new RuleConfigAPICommunicator('tenant');
export const manager = new TenantConfigManager(communicator);

function* getAllTenantsBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.ALL_TENANTS_GET));
  try {
    const allTenantsResponse = yield manager.getAllTenants();
    const allTenants = Object.values(allTenantsResponse).flatMap(
      (masterTenant) => masterTenant.tenants,
    );
    yield put(getAllTenantsSuccess(allTenants));
  } catch (error) {
    yield put(getAllTenantsError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.ALL_TENANTS_GET));
}

export function* getAllTenantsWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.ALL_TENANTS_GET, getAllTenantsBegin);
}

export function* addTenantBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.TENANT_ADD));
  try {
    const { payload } = action;
    const subTenantResponse = yield manager.addTenant(payload);
    yield put(addTenantSuccess(subTenantResponse));

    if (!subTenantResponse || subTenantResponse?.errorMessage) {
      return;
    }

    const newTenant = {
      id: subTenantResponse.tenantId,
      name: subTenantResponse.tenantName,
    };
    if (payload.copyUsersFromActiveTenant) {
      yield put(addTenantToUsers({ newTenant }));
    } else if (payload.experimentationTenant) {
      yield put(addTenantToUsers({ newTenant, userId: payload.userId }));
    }

    const message = i18next.t(
      'translation:tenantSelection.subTenant.createdSuccessMessage.subheading',
      { tenantName: subTenantResponse.tenantName },
    );

    yield put(showNotification({ message }));
  } catch (error) {
    yield put(addTenantError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.TENANT_ADD));
}

export function* addTenantWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.TENANT_ADD, addTenantBegin);
}

function* addTenantToUsersBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.ADD_TENANT_TO_USERS));
  try {
    const json = yield manager.addTenantToUsers(action.payload);
    yield put(addTenantToUsersSuccess(json));
  } catch (error) {
    yield put(addTenantError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.ADD_TENANT_TO_USERS));
}

export function* addTenantToUsersWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.ADD_TENANT_TO_USERS, addTenantToUsersBegin);
}

function* getUsersBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.USERS_GET));
  try {
    const json = yield manager.getUsers(action.payload.tenantId);
    yield put(getUsersSuccess(json));
  } catch (error) {
    yield put(getUsersError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.USERS_GET));
}

export function* getUsersWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.USERS_GET, getUsersBegin);
}

function* getUsersNotInTenantBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.USERS_NOT_IN_TENANT_GET));
  try {
    const { tenantId, otherTenantIds } = action.payload;
    const json = yield manager.getUsersNotInTenant(tenantId, otherTenantIds);
    yield put(getUsersNotInTenantSuccess(json));
  } catch (error) {
    yield put(getUsersNotInTenantError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.USERS_NOT_IN_TENANT_GET));
}

export function* getUsersNotInTenantWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.USERS_NOT_IN_TENANT_GET, getUsersNotInTenantBegin);
}

function* updateUserBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.USER_UPDATE));
  try {
    const { tenantId, otherTenantIds } = action.payload;
    const json = yield manager.updateUserInfo(action.payload);
    yield put(getUsers({ tenantId }));
    yield put(
      getUsersNotInTenant({
        tenantId,
        otherTenantIds,
      }),
    );
    yield put(updateUserSuccess(json));
  } catch (error) {
    yield put(updateUserError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.USER_UPDATE));
}

export function* updateUserWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.USER_UPDATE, updateUserBegin);
}

/** Start "get master tenant" action */
function* getMasterTenantsBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.masterTenants.get));
  try {
    const json = yield manager.getMasterTenants();
    yield put(getMasterTenantsSuccess(json));
  } catch (error) {
    yield put(getMasterTenantsError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.masterTenants.get));
}

/**
 * Connect action GET to method to get master tenant
 */
export function* getMasterTenantsWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.masterTenants.get, getMasterTenantsBegin);
}

/** Start "update tenant structure" action */
function* updateTenantStructureBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.tenantStructure.update));
  try {
    const json = yield manager.updateTenantStructure(action.payload);
    yield put(updateTenantStructureSuccess(json));
  } catch (error) {
    yield put(updateTenantStructureError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.tenantStructure.update));
}

/**
 * Connect action UPDATE to method to update tenant structure
 */
export function* updateTenantStructureWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.tenantStructure.update, updateTenantStructureBegin);
}

/**
 * Start "update tenant callback config" action
 */
function* putTenantCallbackConfigBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.callbackConfig.put));
  try {
    const json = yield manager.putTenantCallbackConfig(action.payload);
    yield put(putTenantCallbackConfigSuccess(json));
  } catch (error) {
    yield put(putTenantCallbackConfigError());
    yield showError({ ...error, action });
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.callbackConfig.put));
}

/**
 * Connect action "TenantConfigActions.callbackConfig.put" to method
 */
export function* putTenantCallbackConfigWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.callbackConfig.put, putTenantCallbackConfigBegin);
}

function* getTenantCallbackConfigSecretBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.callbackConfigSecret.get));

  try {
    const json = yield manager.getTenantCallbackConfigSecret();
    yield put(getTenantCallbackConfigSecretSuccess(json));

    const noteString = i18next.t('configuration:workflowsAndUsers.callbacks.secret.unmask.heading');
    yield put(showNotification({ message: noteString }));
  } catch (error) {
    yield put(getTenantCallbackConfigSecretError());
    yield showError({ ...error, action });
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.callbackConfigSecret.get));
}

export function* getTenantCallbackConfigSecretWatcher() {
  yield takeLatest(
    TENANT_CONFIG_ACTIONS.callbackConfigSecret.get,
    getTenantCallbackConfigSecretBegin,
  );
}
/**
 * Start "update tenant callback config" action
 */
function* putTenantScorechainConfigBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.scorechainConfig.put));
  try {
    const json = yield manager.putTenantScorechainConfig(action.payload);
    yield put(putTenantScorechainConfigSuccess(json));
  } catch (error) {
    yield put(putTenantScorechainConfigError());
    yield showError({ ...error, action });
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.scorechainConfig.put));
}

/**
 * Connect action "TenantConfigActions.scorechainConfig.put" to method
 */
export function* putTenantScorechainConfigWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.scorechainConfig.put, putTenantScorechainConfigBegin);
}

function* getTenantScorechainConfigSecretBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.scorechainConfigSecret.get));

  try {
    const json = yield manager.getTenantScorechainConfigSecret();
    yield put(getTenantScorechainConfigSecretSuccess(json));

    const noteString = i18next.t(
      'configuration:workflowsAndUsers.scorechain.secret.unmask.heading',
    );
    yield put(showNotification({ message: noteString }));
  } catch (error) {
    yield put(getTenantScorechainConfigSecretError());
    yield showError({ ...error, action });
  }
  yield put(stopProcessing(TENANT_CONFIG_ACTIONS.scorechainConfigSecret.get));
}

export function* getTenantScorechainConfigSecretWatcher() {
  yield takeLatest(
    TENANT_CONFIG_ACTIONS.scorechainConfigSecret.get,
    getTenantScorechainConfigSecretBegin,
  );
}

export function* addMasterTenantBegin(action) {
  yield put(startProcessing(TENANT_CONFIG_ACTIONS.createMasterTenant.add));
  try {
    const creationStatusResponse = yield manager.addMasterTenant(action.payload);
    yield put(
      getMasterTenantCreationStatus({
        requestId: creationStatusResponse.requestId,
        dryRun: action.payload.dryRun,
        copyUsersFromActiveTenant: action.payload.copyUsersFromActiveTenant,
      }),
    );
  } catch (error) {
    yield put(addMasterTenantError());
    yield put(showError({ ...error, action }));
  }
}

export function* pollMasterTenantCreationStatus(action) {
  try {
    const { requestId, dryRun, copyUsersFromActiveTenant } = action.payload;
    const response = yield manager.getMasterTenantCreationStatus(requestId);
    switch (response.status) {
      case 'PROCESSING': {
        yield delay(500);
        yield put(getMasterTenantCreationStatus(action.payload));
        break;
      }
      case 'SUCCESS': {
        const masterTenant = response.createdMasterTenant;
        yield put(addMasterTenantSuccess(masterTenant));
        if (!dryRun && masterTenant.tenantId && copyUsersFromActiveTenant) {
          yield put(
            addTenantToUsers({
              newTenant: { id: masterTenant.tenantId, name: masterTenant.tenantName },
            }),
          );
        }
        const successMessage = {
          message: i18next.t(
            'translation:tenantSelection.masterTenant.createdSuccessMessage.subheading',
            { tenantName: masterTenant.tenantName },
          ),
        };
        yield put(showNotification(successMessage));
        break;
      }
      case 'FAILURE': {
        yield put(addMasterTenantError());
        yield put(showError({ message: response.errorMessage }));
        break;
      }
      default: {
        yield put(addMasterTenantError());
        yield put(
          showError({
            message: i18next.t(
              'translation:tenantSelection.masterTenant.unknownCreationStatusMessage.subheading',
              { status: response.status },
            ),
          }),
        );
        break;
      }
    }
    if (response.status !== 'PROCESSING') {
      yield put(stopProcessing(TENANT_CONFIG_ACTIONS.createMasterTenant.add));
      yield call([manager, 'finalizeMasterTenantCreation'], requestId);
    }
  } catch (error) {
    yield put(addMasterTenantError());
    yield put(showError({ ...error }));
    yield put(stopProcessing(TENANT_CONFIG_ACTIONS.createMasterTenant.add));
  }
}

export function* addMasterTenantWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.createMasterTenant.add, addMasterTenantBegin);
}

export function* getMasterTenantCreationStatusWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.createMasterTenant.status, pollMasterTenantCreationStatus);
}
