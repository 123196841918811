import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE } from 'utils/constants/languages';
import LocalStorageService from 'services/local-storage-service';

type LanguageConfigReducerState = {
  language: string;
};

const getInitialLanguage = () => {
  const localStorage = new LocalStorageService();
  const storedLanguage = localStorage.getLanguage().language;
  return storedLanguage ?? DEFAULT_LANGUAGE;
};

const initialState: LanguageConfigReducerState = {
  language: getInitialLanguage(),
};

const languageConfigReducer = createSlice({
  name: 'languageConfig',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => ({
      ...state,
      language: action.payload,
    }),
  },
});

export const { setLanguage } = languageConfigReducer.actions;

export default languageConfigReducer.reducer;
