import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HighestPointsApplied: FC = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="textSecondary">
      {t('case:userPopover.riskRating.highestPointsApplied.heading')}
    </Typography>
  );
};

export default HighestPointsApplied;
