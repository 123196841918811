import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer, CustomerListRequest, CustomerListResponse } from 'modules/customer-data/types';

interface CustomerListState {
  processing: boolean;
  customers: Customer[];
  totalCustomers: number;
  paging: {
    scrollId: string;
    lastPage: boolean;
  };
}

const initialState: CustomerListState = {
  customers: [],
  processing: false,
  totalCustomers: 0,
  paging: {
    scrollId: '',
    lastPage: true,
  },
};
export const customerListSlice = createSlice({
  name: 'customerList',
  initialState,
  reducers: {
    getCustomers: (state, action: PayloadAction<CustomerListRequest>) => {
      const newState = { ...state };
      if (!action.payload.paging.scrollId) {
        newState.customers = [];
      }
      return newState;
    },
    getCustomersSuccess: (state, action: PayloadAction<CustomerListResponse>) =>
      // state?.customerDetails[uuid]
      ({
        ...state,
        customers: [...state.customers, ...action.payload.data.customers],
        totalCustomers: action.payload.data.totalHits,
        paging: {
          scrollId: action.payload.data.scrollId,
          lastPage: action.payload.data.customers.length === 0,
        },
      }),
  },
});

export const { getCustomers, getCustomersSuccess } = customerListSlice.actions;

export default customerListSlice.reducer;
