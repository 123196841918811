export const SuspiciousAvgTicketSizeConfigActions = {
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET: 'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET_SUCCESS: 'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET_SUCCESS',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET_ERROR: 'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET_ERROR',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE: 'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE_SUCCESS:
    'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE_SUCCESS',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE_ERROR: 'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE_ERROR',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE: 'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE_SUCCESS:
    'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE_SUCCESS',
  SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE_ERROR: 'SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE_ERROR',
};
