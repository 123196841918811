import { Box, Grid, Paper, Typography } from '@mui/material';
import { PersonChip } from 'components/chips/person-chip';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { Translation, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCountryName } from 'utils/constants/country-list';
import {
  decryptWebLinks,
  formatMaskedArray,
  formatMaskedValue,
} from 'utils/helpers/cases/case-utility';
import { getCountryRiskLevel, getSettingsFromState } from 'utils/helpers/config-utils/config-util';
import { formatPercentage } from 'utils/helpers/math-util';

const ShareholderCard = ({ data, caseConfig }) => {
  const { t } = useTranslation();
  const { role, liability, share } = data;
  const formattedShare = `${formatPercentage(share)}%`;

  return (
    <Paper className="p-3 w-100" variant="outlined">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <PersonChip personDetails={{ data }} caseConfig={caseConfig} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            {t('case:userPopover.personal.shareholdersInfo.role.heading')}
          </Typography>
          {role?.length ? (
            <Typography variant="body2" className="wb-break-all">
              {role}
            </Typography>
          ) : (
            '-'
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            {t('case:userPopover.personal.shareholdersInfo.liability.heading')}
          </Typography>
          {liability?.length ? <Typography variant="body2">{liability}</Typography> : '-'}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            {t('case:userPopover.personal.shareholdersInfo.share.heading')}
          </Typography>
          {share ? <Typography variant="body2">{formattedShare}</Typography> : '-'}
        </Grid>
      </Grid>
    </Paper>
  );
};
const ShareholdersDataView = (props) => {
  const { personDetails, handleChange, backTabLink, caseConfig, showUnmaskedData } = props;
  const allMasks = useSelector((state) => state.masks?.all);
  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);
    return settings?.countryRisk;
  });
  const formatMaskedValueInternal = (fieldObject) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);
  const formatMaskedArrayInternal = (dataArray) =>
    formatMaskedArray(showUnmaskedData, allMasks, dataArray);
  const decryptWebLinksInternal = (webLinks) =>
    decryptWebLinks(showUnmaskedData, allMasks, webLinks);

  const contactData = {
    name: formatMaskedValueInternal(personDetails.contactDetails?.contactName),
    extendedName: formatMaskedValueInternal(personDetails.contactDetails?.contactNameExtended),
    contactId: personDetails.contactDetails?.contactId,
    emailAddress: formatMaskedArrayInternal(personDetails.contactDetails?.emailAddresses),
    phoneNumber: formatMaskedArrayInternal(personDetails.contactDetails?.phoneNumbers),
    webLinksV2: decryptWebLinksInternal(personDetails.contactDetails?.webLinksV2),
    addressValidSince: personDetails.contactDetails?.addressValidSince,
    relatedCustomerId: formatMaskedArrayInternal(
      personDetails.contactDetails?.allAssignedCustomerIds,
    ),
    address: {
      street: formatMaskedValueInternal(personDetails.contactDetails?.street),
      streetExtended: formatMaskedValueInternal(personDetails.contactDetails?.streetExtended),
      town: formatMaskedValueInternal(personDetails.contactDetails?.town),
      region: formatMaskedValueInternal(personDetails.contactDetails?.region),
      zip: formatMaskedValueInternal(personDetails.contactDetails?.zip),
      country: {
        value: formatMaskedValueInternal(personDetails.contactDetails?.country),
      },
      unstructured: formatMaskedValueInternal(personDetails.contactDetails?.unstructuredAddress),
    },
  };

  contactData.address.country.name = getCountryName(contactData.address.country.value);
  contactData.address.country.riskLevel = getCountryRiskLevel(
    contactData.address.country.value,
    countryRisk,
  );

  const shareholdersArray = personDetails?.companyDetails?.shareholders?.map((item) => ({
    data: item,
  }));

  return (
    <>
      <Translation>
        {(t) => (
          <>
            <Grid container spacing={1}>
              <Grid container item alignItems="center">
                <Grid item>
                  <IconButtons.Back edge="start" onClick={() => handleChange(backTabLink)} />
                </Grid>

                <Grid item>
                  <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                    {t('case:userPopover.personal.shareholdersInfo.heading')}
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {shareholdersArray?.map((item, i) => (
                  <Grid item xs={12} key={i}>
                    <ShareholderCard data={item.data} caseConfig={caseConfig} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Translation>
    </>
  );
};

export { ShareholdersDataView };
