import { useState } from 'react';
import { Chip, Tooltip } from '@mui/material';
import { PersonPopover } from 'components/popover/person-popover';
import { Translation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getPersonName, getPersonLabel } from 'utils/helpers/cases/case-utility';
import { getPersonData } from 'utils/helpers/cases/case-party-utils';
import { getAccountIcon } from 'components/icons/icons';

import { updateCaseDetailDataShown } from 'middleware/actions/mask';
import LocalStorageService from 'services/local-storage-service';
import { prepareCustomerRiskRatingData } from 'utils/helpers/cases/case-crr-data-util';

const localStorageService = new LocalStorageService();

/**
 * chip to open person details
 * @param {*} personDetails
 * @param {*} chipSize
 * @param {*} tenantConfig
 */
const PersonChip = ({
  personDetails,
  chipSize,
  tenantConfig,
  caseDetail,
  readOnly,
  isCompany,
  caseConfig,
  customerRiskRatingDataParam,
  skipHolderName = false,
}) => {
  const riskLevel = personDetails?.data?.riskLevel?.replace(':default', '');
  const calculatedRiskLevel = personDetails.isCustomer
    ? personDetails?.data?.calculatedRiskLevel?.replace(':default', '')
    : riskLevel;

  const [anchorEl, setAnchorEl] = useState(() => null);
  const caseDetailDataShown = useSelector((state) => state.masks?.caseDetailDataShown);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    const anchorResult = anchorEl ? null : event.currentTarget;
    event.stopPropagation();
    setAnchorEl(anchorResult);

    if (!caseDetailDataShown) {
      dispatch(updateCaseDetailDataShown(localStorageService.updateCaseDetailDataShown()));
    }
  };

  const customerRiskRatingData =
    customerRiskRatingDataParam ??
    prepareCustomerRiskRatingData(caseDetail, calculatedRiskLevel?.toUpperCase());

  const allMasks = useSelector((state) => state.masks?.all);
  const personData = getPersonData(
    allMasks,
    personDetails?.data,
    caseDetailDataShown,
    skipHolderName,
  );
  const openDetails = Boolean(anchorEl);
  const personName = getPersonName(personData, skipHolderName);
  const isStaff = Boolean(personDetails?.data?.role);
  return (
    <Translation>
      {(t) => (
        <>
          <span>
            <Tooltip
              disableHoverListener={isStaff}
              title={personDetails.isCustomer ? t('customer.heading') : t('counterParty.heading')}
            >
              <Chip
                className={`${calculatedRiskLevel ?? 'none'} max-w-100 limit-max-size`}
                onClick={readOnly ? null : handleClick}
                size={chipSize}
                label={
                  personName ?? (
                    <span className="font-italic color-on-surface-secondary">
                      {getPersonLabel(personDetails.isCustomer, isStaff)}
                    </span>
                  )
                }
                icon={
                  isStaff
                    ? null
                    : getAccountIcon(personDetails.isCustomer, isCompany, calculatedRiskLevel)
                }
              />
            </Tooltip>
          </span>
          <PersonPopover
            anchorEl={anchorEl}
            openDetails={openDetails}
            handleClick={handleClick}
            personName={personName}
            personDetails={personDetails.data}
            isCustomer={personDetails.isCustomer}
            tenantConfig={tenantConfig}
            customerRiskRatingData={customerRiskRatingData}
            isCompanyTransaction={isCompany}
            caseConfig={caseConfig}
          />
        </>
      )}
    </Translation>
  );
};

export { PersonChip, getPersonName };
