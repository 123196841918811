import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { Tenant } from 'models/tenant/tenant';
import { CopyReport } from 'modules/configuration/config-propagation/model';
import { ErrorCodeMap } from 'services/api/error-code-map';

class ConfigPropagationAPICommunicator extends APICommunicator {
  fetchAllowedTenants(): Promise<Tenant[]> {
    return this.getJSONForTenant(
      API_URLS.tenantService.allowedTenants,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  copyInstance(targetTenants: string[], ruleName: string, instanceId: string): Promise<CopyReport> {
    const errorCodeMap: ErrorCodeMap = {
      412: ErrorCode.LIST_PROVIDERS_MISMATCH,
      default: ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    };

    return this.postJSONForTenant(
      API_URLS.configPropagation.copyInstance,
      {
        newTenantIds: targetTenants,
        ruleName,
        instanceId,
      },
      errorCodeMap,
    );
  }
}

export default ConfigPropagationAPICommunicator;
