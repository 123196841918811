const TransactionType = {
  DEBIT: 'debit',
  CREDIT: 'credit',
  ANY: 'any',
};

const OrderedTransactionTypes = [
  TransactionType.ANY,
  TransactionType.DEBIT,
  TransactionType.CREDIT,
];

export default TransactionType;

export { OrderedTransactionTypes };
