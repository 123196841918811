import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Paper, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

/**
 * showing skeleton loader
 * @param {*} param0
 * @returns
 */

const SkeletonLoader = ({ variant, ...otherParam }) => {
  switch (variant) {
    case 'list':
      return <SkeletonListView listSize={otherParam.listSize} cardMode={otherParam.cardMode} />;
    case 'row':
      return <SkeletonRowView elementsPerRow={otherParam.elementsPerRow} />;
    case 'analysis':
      return <SkeletonAnalysisView />;
    default:
      return <Skeleton variant="rectangular" height={otherParam.height} />;
  }
};

const SkeletonRowView = ({ elementsPerRow }) => {
  const getSkeletonContent = (elementsPerRow) => {
    const content = [];
    for (let i = 0; i < elementsPerRow; i++) {
      content.push(
        <Grid key={i} item xs={12 / elementsPerRow}>
          <SkeletonLoader />
        </Grid>,
      );
    }
    return content;
  };

  return <>{getSkeletonContent(elementsPerRow)}</>;
};

const SkeletonListView = ({ listSize, cardMode }) => {
  const getSkeletonContent = (listSize) => {
    const content = [];
    for (let i = 0; i < listSize; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  return (
    <>
      <TableContainer className={`${cardMode ? 'bg-white' : ''}`}>
        <Table>
          <TableBody>{getSkeletonContent(listSize)}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const SkeletonAnalysisView = () => (
  <>
    <Paper className="p-3">
      <Grid container spacing={1} direction="column">
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>
    </Paper>
  </>
);

export { SkeletonLoader };
