import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';
import { put, take, takeLatest } from 'redux-saga/effects';
import {
  manualRiskLevelChangeError,
  manualRiskLevelChangeSuccess,
} from 'modules/customer-cases/case-detail/middleware/actions/manual-risk-level-change';
import { showError } from 'middleware/actions/error';
import ManualRiskLevelChangeCCApi from 'modules/customer-cases/case-detail/services/manual-risk-level-change/manual-risk-level-change-api-communicator';
import ManualRiskLevelChangeManager from 'modules/customer-cases/case-detail/services/manual-risk-level-change/manual-risk-level-change-manager';
import { addCustomerCaseNote } from 'modules/customer-cases/case-detail/middleware/actions/case-note';

const communicator = new ManualRiskLevelChangeCCApi();
const manager = new ManualRiskLevelChangeManager(communicator);

function* requestManualRiskLevelChangeBegin(action) {
  yield put(startProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.manualRiskLevelChange.data));

  try {
    const { createdCaseId } = yield manager.requestManualRiskLevelChange(
      action.payload.manualChangeData,
    );

    yield put(
      addCustomerCaseNote({
        ...action.payload.caseNoteData,
        caseId: createdCaseId,
      }),
    );

    yield take(CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.success);

    yield put(
      manualRiskLevelChangeSuccess({
        type: action.type,
        data: { createdCaseId },
      }),
    );
  } catch (error) {
    yield put(manualRiskLevelChangeError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.manualRiskLevelChange.data));
}

function* requestManualRiskLevelChangeWatcher() {
  yield takeLatest(
    CUSTOMER_CASE_DETAIL_ACTIONS.manualRiskLevelChange.data,
    requestManualRiskLevelChangeBegin,
  );
}

export { requestManualRiskLevelChangeWatcher };
