import { Badge, Avatar } from '@mui/material';
import { ConditionalTooltip } from 'components/tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import { Cancel, CheckCircle, SwapHoriz } from '@mui/icons-material';
import { isGroupingActive } from 'modules/trx-cases/case-detail/utils/helpers/case-grouping';

const CaseGrouping = ({
  stopGroupingInfo,
  assignedName,
  isStopManualEnabled,
  caseStatus,
  dataTestId,
}) => {
  const { t } = useTranslation();

  return (
    <ConditionalTooltip
      title={
        isGroupingActive(stopGroupingInfo, isStopManualEnabled, assignedName, caseStatus)
          ? t('case:caseDetail.caseDrawer.grouping.on.heading')
          : t('case:caseDetail.caseDrawer.grouping.off.heading')
      }
    >
      <span className="my-1">
        <Badge
          data-testid={dataTestId}
          className="small"
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <Avatar className="auto bg-background">
              {isGroupingActive(stopGroupingInfo, isStopManualEnabled, assignedName, caseStatus) ? (
                <CheckCircle color="success" className="icon-16" />
              ) : (
                <Cancel className="color-on-surface-disabled icon-16" />
              )}
            </Avatar>
          }
        >
          <SwapHoriz fontSize="small" />
        </Badge>
      </span>
    </ConditionalTooltip>
  );
};

export default CaseGrouping;
