import { hideError, showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { TRX_CASE_DETAIL_ACTIONS } from 'modules/trx-cases/case-detail/middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import {
  stopGroupingError,
  stopGroupingSuccess,
} from 'modules/trx-cases/case-detail/middleware/actions/case-grouping';
import TrxCaseDetailGroupingManager from 'modules/trx-cases/case-detail/services/case-grouping/case-grouping-manager';
import TrxCaseDetailGroupingApi from 'modules/trx-cases/case-detail/services/case-grouping/case-grouping-api-communicator';

const caseGroupingCommunicator = new TrxCaseDetailGroupingApi();
const caseGroupingManager = new TrxCaseDetailGroupingManager(caseGroupingCommunicator);

function* stopGroupingBegin(action) {
  const caseId = action.payload || {};

  try {
    yield put(startProcessing(TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.data));
    const json = yield caseGroupingManager.postStopGrouping(caseId);

    yield put(stopGroupingSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.data));
  } catch (error) {
    yield put(stopGroupingError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.data));
  }
}

function* stopGroupingWatcher() {
  yield takeLatest(TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.data, stopGroupingBegin);
}

export { stopGroupingWatcher };
