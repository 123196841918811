import TenantManagementService from 'services/tenant/tenant-management-service';
import { generatePath } from 'react-router';
import { customViewCasesRoute } from 'utils/constants/routes';

export const getCustomViewsPath = () => {
  const activeTenantId = TenantManagementService.getActiveTenantId() || '';
  return generatePath(customViewCasesRoute.path, {
    tenantId: activeTenantId,
  });
};
