export const EXPECTED_CUSTOMER_VALUE_ACTIONS = {
  fetch: {
    data: 'EXPECTED_CUSTOMER_VALUE_CONFIG_GET',
    success: 'EXPECTED_CUSTOMER_VALUE_CONFIG_GET_SUCCESS',
    error: 'EXPECTED_CUSTOMER_VALUE_CONFIG_GET_ERROR',
  },
  update: {
    send: 'EXPECTED_CUSTOMER_VALUE_CONFIG_UPDATE',
    success: 'EXPECTED_CUSTOMER_VALUE_CONFIG_UPDATE_SUCCESS',
    error: 'EXPECTED_CUSTOMER_VALUE_CONFIG_UPDATE_ERROR',
  },
  delete: {
    send: 'EXPECTED_CUSTOMER_VALUE_CONFIG_DELETE',
    success: 'EXPECTED_CUSTOMER_VALUE_CONFIG_DELETE_SUCCESS',
    error: 'EXPECTED_CUSTOMER_VALUE_CONFIG_DELETE_ERROR',
  },
};
