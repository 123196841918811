import { Box } from '@mui/material';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

const buildIsEscalatedValuesFromUrl = (filter) => ({
  condition: filter.condition,
  field: filter.field,
});

const IsEscalatedForm = ({ onSubmit }) => {
  onSubmit({
    condition: i18next.t('mapping:operator.text.NOT_EMPTY.key'),
    field: i18next.t('mapping:filters.filtering.escalatedAt.key'),
  });

  return null;
};

const IsEscalatedChip = () => (
  <Translation>
    {(t) => (
      <Box fontSize="body2.fontSize">
        {t('mapping:filters.filtering.escalatedAt.value.heading')}
      </Box>
    )}
  </Translation>
);

export { IsEscalatedForm, IsEscalatedChip, buildIsEscalatedValuesFromUrl };
