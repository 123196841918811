/* eslint-disable no-param-reassign */
import { Box, ClickAwayListener, Grid, Paper, Popper } from '@mui/material';
import {
  getAccountHolderPartyFromTransaction,
  getCustomerFromTransaction,
  getPersonData,
} from 'utils/helpers/cases/case-utility';
import { useSelector } from 'react-redux';
import { getCustomerDisplayName } from 'components/chips/customer-chip';
import { PersonDetailsHeader } from 'components/popover/person-details/person-details-header';
import { PersonDetailsTab } from 'components/popover/person-details/person-details-tab/person-details-tab';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import { prepareCustomerRiskRatingData } from 'utils/helpers/cases/case-crr-data-util';
import { useAuthorization } from 'hooks/use-authorization';

/**
 * popover for person details
 * @param {*} anchorEl
 * @param {*} openDetails
 * @param {*} handleClick
 * @param {*} personName
 * @param {*} personDetails
 * @param {*} isCustomer
 * @param tenantConfig
 * @param customerRiskRatingData
 * @param defaultTab
 * @param getDetailsCardMode
 * @param isPageContent
 * @param item
 * @param transaction
 * @param isCompanyTransaction
 * @param caseConfig
 * @param isCustomerCase
 * @param showCustomerDetailsLink
 */
const PersonPopover = ({
  anchorEl,
  openDetails, // todo - maybe we should remove this parameter, it always takes the value of !!anchorEl
  handleClick,
  personName,
  personDetails,
  isCustomer,
  tenantConfig,
  customerRiskRatingData,
  defaultTab,
  getDetailsCardMode,
  isPageContent,
  item = {},
  transaction,
  isCompanyTransaction,
  caseConfig,
  isCustomerCase = false,
  showCustomerDetailsLink = true,
}) => {
  const masks = useSelector((state) => state.masks);

  const { userPermissions, detachmentConfig } = useAuthorization();

  const isIndividual = !isCompanyTransaction;

  if (!personDetails) {
    const customer = getCustomerFromTransaction(transaction);
    const { masked, token } = { ...customer };
    const calculatedRiskLevel = transaction?.riskLevel ?? item?.riskLevel;
    const masterData = transaction?.masterData ?? item?.masterData;
    const { accountHoldingPartyData, underwriterData } = { ...masterData };

    const {
      companyDetails,
      personalDetails,
      customerType,
      contactDetails,
      riskLevel,
      customerId,
      customerGroupId,
      customerStatus,
      customerSince,
      signedProducts,
      referenceAccount,
      characteristics,
      customerRelationshipDetails,
    } = { ...accountHoldingPartyData };

    const accountHolderParty = getAccountHolderPartyFromTransaction(transaction);

    const personData = getPersonData(
      masks?.all,
      personalDetails,
      companyDetails,
      accountHolderParty,
      masks?.casesDataShown || anchorEl,
      isCompanyTransaction,
    );

    personName =
      personName && personName !== '-'
        ? personName
        : getCustomerDisplayName(item?.customer, personData);

    const crrDataFromMetadatas = prepareCustomerRiskRatingData(item);

    customerRiskRatingData =
      isFeatureAccessible(
        DETACHMENT_CONFIG.customerRiskRating.individual,
        detachmentConfig,
        userPermissions,
      ) ||
      isFeatureAccessible(
        DETACHMENT_CONFIG.customerRiskRating.organization,
        detachmentConfig,
        userPermissions,
      )
        ? customerRiskRatingData || crrDataFromMetadatas
        : null;

    personDetails = {
      masked,
      token,
      riskLevel,
      calculatedRiskLevel,
      customerId,
      customerGroupId,
      customerStatus,
      customerSince,
      customerType,
      signedProducts,
      underwriterData,
      referenceAccount,
      characteristics,
      personalDetails,
      companyDetails,
      contactDetails,
      customerRelationshipDetails,
      customerReviewSchedule: {
        ...item?.customerReviewSchedule,
        nextScheduledReviewDate: item?.nextScheduledReviewDate,
      },
    };
  }

  if (getDetailsCardMode) {
    return (
      <PersonDetailsTab
        showUnmaskedData
        personDetails={personDetails}
        isCustomer={isCustomer}
        tenantConfig={tenantConfig}
        customerRiskRatingData={customerRiskRatingData}
        defaultTab={defaultTab}
        getDetailsCardMode={getDetailsCardMode}
        isIndividual={isIndividual}
        caseConfig={caseConfig}
        isCustomerCase={isCustomerCase}
      />
    );
  }

  if (isPageContent) {
    return (
      <PersonDetailsHeader
        personName={personName}
        personDetails={personDetails}
        isCustomer={isCustomer}
        isCompany={isCompanyTransaction}
        isPageContent={isPageContent}
        customerRiskRatingData={customerRiskRatingData}
        isIndividual={isIndividual}
        caseConfig={caseConfig}
        showCustomerDetailsLink={showCustomerDetailsLink}
      />
    );
  }

  return (
    <Popper
      open={openDetails}
      anchorEl={anchorEl}
      placement="bottom-start"
      className="z-1000"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <ClickAwayListener onClickAway={handleClick}>
        <Paper elevation={5}>
          <Box className="max-w-400">
            <Grid container>
              <Grid item container className="px-3 pt-3 pb-1">
                <PersonDetailsHeader
                  personName={personName}
                  personDetails={personDetails}
                  isCustomer={isCustomer}
                  isCompany={isCompanyTransaction}
                  onClose={handleClick}
                  isPageContent={isPageContent}
                  customerRiskRatingData={customerRiskRatingData}
                  isIndividual={isIndividual}
                  caseConfig={caseConfig}
                  showCustomerDetailsLink={showCustomerDetailsLink}
                />
              </Grid>
              <Grid container item>
                <PersonDetailsTab
                  showUnmaskedData
                  personDetails={personDetails}
                  isCustomer={isCustomer}
                  tenantConfig={tenantConfig}
                  customerRiskRatingData={customerRiskRatingData}
                  defaultTab={defaultTab}
                  isIndividual={isIndividual}
                  caseConfig={caseConfig}
                  isCustomerCase={isCustomerCase}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export { PersonPopover };
