import i18next from 'i18next';
import { isEmptyObject } from 'utils/helpers/examination-util';
import Strings from 'utils/helpers/locales/strings.en';

export const getAction = (caseStateChange) =>
  caseStateChange?.caseAction?.name || caseStateChange?.action;

export const getActionLowerCase = (caseStateChange) => getAction(caseStateChange)?.toLowerCase();

const buildSingleStatusChangeNote = (label, fromStatus, toStatus) => {
  if (fromStatus === toStatus) {
    return `${label} ${i18next.t(
      'translation:caseStateChangeNote.is.heading',
    )} <strong>${fromStatus}</strong>.`;
  }
  return `${label} ${i18next.t('translation:caseStateChangeNote.changes.heading')} ${i18next.t(
    'translation:caseStateChangeNote.from.heading',
  )} <strong>${fromStatus}</strong> ${i18next.t(
    'translation:caseStateChangeNote.to.heading',
  )} <strong>${toStatus}</strong>.`;
};

const getProcessingStatusText = (processingStatus) =>
  Strings.ProcessingStatus[processingStatus] ??
  i18next.t([
    `mapping:processingStatusV2.${processingStatus}.value.heading`,
    `mapping:processingStatus.${processingStatus}.value.heading`,
    '',
  ]);

const buildProcessingStatusChangeNote = ({ fromCaseState, toCaseState }) =>
  buildSingleStatusChangeNote(
    i18next.t('case:caseDetail.summary.status.processing.heading'),
    getProcessingStatusText(fromCaseState?.processingStatusV2),
    getProcessingStatusText(toCaseState?.processingStatusV2),
  );

const getCaseStatusText = (caseStatus) =>
  Strings.CaseStatus[caseStatus] ??
  i18next.t([
    `mapping:caseStatusV2.${caseStatus}.value.heading`,
    `mapping:caseStatus.${caseStatus}.value.heading`,
    '',
  ]);

const buildCaseStatusChangeNote = ({ fromCaseState, toCaseState }) =>
  buildSingleStatusChangeNote(
    i18next.t('case:caseDetail.summary.status.case.heading'),
    getCaseStatusText(fromCaseState?.caseStatus),
    getCaseStatusText(toCaseState?.caseStatus),
  );

const getTransactionStatusText = (transactionStatus) =>
  Strings.TransactionStatus[transactionStatus] ??
  i18next.t([
    `mapping:transactionStatusV2.${transactionStatus}.value.heading`,
    `mapping:transactionStatus.${transactionStatus}.value.heading`,
    '',
  ]);

const buildTransactionStatusChangeNote = ({ fromCaseState, toCaseState }) =>
  buildSingleStatusChangeNote(
    i18next.t('case:caseDetail.summary.status.transaction.heading'),
    getTransactionStatusText(fromCaseState?.transactionStatusV2),
    getTransactionStatusText(toCaseState?.transactionStatusV2),
  );

const getCustomerStatusText = (customerStatus) =>
  i18next.t([`mapping:transactionStatusV2.${customerStatus}.value.heading`, '']);

const buildCustomerStatusChangeNote = ({ fromCaseState, toCaseState }) =>
  buildSingleStatusChangeNote(
    i18next.t('case:caseDetail.summary.status.customer.heading'),
    getCustomerStatusText(fromCaseState?.customerStatus),
    getCustomerStatusText(toCaseState?.customerStatus),
  );

const getInvestigationStatusText = (investigationStatus) =>
  i18next.t([`mapping:investigationStatus.${investigationStatus?.name}.value.heading`, '']);

const buildInvestigationStatusChangeNote = ({ fromCaseState, toCaseState }) =>
  buildSingleStatusChangeNote(
    i18next.t('case:caseDetail.summary.status.investigation.heading'),
    getInvestigationStatusText(fromCaseState?.investigationStatus),
    getInvestigationStatusText(toCaseState?.investigationStatus),
  );

const NOTE_SEPARATOR = '<br />';

const buildCaseStateChangeNote = (caseStateChange, builders) => {
  const currentCaseState = caseStateChange?.fromCaseState || {};
  const newCaseState = caseStateChange?.toCaseState || {};

  if (isEmptyObject(currentCaseState) || isEmptyObject(newCaseState)) {
    return '';
  }

  return builders.map((b) => b(caseStateChange)).join(NOTE_SEPARATOR);
};

export const buildTransactionCaseStateChangeNote = ({
  caseStateChange,
  operatorNote,
  operatorFeedback,
  isInvestigationStatusAttached,
  isFeedbackRequired,
}) => {
  let caseStateChangeNote = buildCaseStateChangeNote(caseStateChange, [
    isInvestigationStatusAttached
      ? buildInvestigationStatusChangeNote
      : buildProcessingStatusChangeNote,
    buildCaseStatusChangeNote,
    buildTransactionStatusChangeNote,
  ]);

  if (operatorNote && operatorNote.length > 0) {
    caseStateChangeNote += `<br /><br /><strong>${i18next.t(
      'case:confirmationDialog.note.heading',
    )}</strong><br />${operatorNote}`;
  }

  if (isFeedbackRequired && operatorFeedback && operatorFeedback.length > 0) {
    caseStateChangeNote += `<br /><br /><strong>${i18next.t(
      'case:confirmationDialog.feedback.question',
    )}</strong><br />${operatorFeedback}`;
  }

  return caseStateChangeNote;
};

export const buildCustomerCaseStateChangeNote = ({ caseStateChange }) =>
  buildCaseStateChangeNote(caseStateChange, [
    buildProcessingStatusChangeNote,
    buildCaseStatusChangeNote,
    buildCustomerStatusChangeNote,
  ]);

export const groupAllCaseStateChangesWithTheSameActionIntoOne = (caseStateChanges) => {
  const actionMap = {};
  caseStateChanges.forEach((caseStateChange) => {
    const actionName = caseStateChange.caseAction.name;
    if (!actionMap[actionName]) {
      actionMap[actionName] = { ...caseStateChange, possibleToCaseStates: {} };
    }
    const key = caseStateChange.toCaseState.transactionStatusV2;
    actionMap[actionName].possibleToCaseStates[key] = caseStateChange.toCaseState;
  });
  return Object.values(actionMap);
};
