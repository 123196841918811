const isObject = (obj) => !!obj && typeof obj === 'object';

const isMask = (mask) => isObject(mask) && !!mask.token;

const equalsMasks = (mask1, mask2) => mask1.token === mask2.token;

const existsMask = (masks, mask) => masks.find((m) => equalsMasks(m, mask)) !== undefined;

const addMask = (masks, mask) => {
  if (!masks || !isMask(mask) || existsMask(masks, mask)) {
    return;
  }

  masks.push(mask);
};

const addMasksFromObject = (masks, obj) => {
  if (!isObject(obj)) {
    return;
  }

  // if obj itself is a mask, then just add it to list
  if (isMask(obj)) {
    addMask(masks, obj);

    return;
  }

  // otherwise, loop for all obj properties
  for (const key in obj) {
    addMasksFromObject(masks, obj[key]);
  }
};

const getMasksFromObject = (obj) => {
  if (!obj) {
    return [];
  }

  const masks = [];

  addMasksFromObject(masks, obj);

  return masks;
};

export const isUnmasked = (item) => {
  if (Array.isArray(item)) {
    const arr = item.filter((m) => m.unmasked === undefined);
    return arr.length > 0;
  }

  return !!item?.unmasked;
};

export const stillNeedUnmask = (allMasks) => {
  if (!Array.isArray(allMasks)) {
    return false;
  }

  const arr = allMasks.filter((m) => m.unmasked === undefined);
  return arr.length > 0;
};

export default getMasksFromObject;
