export function addToList(str, list) {
  if (!!str && Array.isArray(list) && !list.includes(str)) {
    list.push(str);
  }
}

export function addListToList(sourceList, list) {
  if (Array.isArray(sourceList) && Array.isArray(list)) {
    sourceList.forEach((item) => addToList(item, list));
  }
}

export function removeFromList(str, list) {
  if (!!str && Array.isArray(list) && list.includes(str)) {
    list.splice(list.indexOf(str), 1);
  }
}

export function removeListFromList(sourceList, list) {
  if (Array.isArray(sourceList) && Array.isArray(list)) {
    sourceList.forEach((item) => removeFromList(item, list));
  }
}

export function isEmpty(list) {
  if (!list) {
    return true;
  }
  if (!Array.isArray(list)) {
    throw new Error('Param list must be an array');
  }
  return list.length === 0;
}

export function equals(list1, list2) {
  if (isEmpty(list1) && isEmpty(list2)) {
    return true;
  }

  if (isEmpty(list1) || isEmpty(list2)) {
    return false;
  }

  if (list1.length !== list2.length) {
    return false;
  }

  for (let i = 0; i < list1.length; i++) {
    if (!list2.includes(list1[i])) {
      return false;
    }
  }

  for (let i = 0; i < list2.length; i++) {
    if (!list1.includes(list2[i])) {
      return false;
    }
  }

  return true;
}
