import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getAmlRiskScreeningConfigSuccess,
  saveAmlRiskScreeningConfigSuccess,
  deleteAmlRiskScreeningConfigSuccess,
  getAmlRiskScreeningImportStatusSuccess,
} from 'middleware/reducers/aml-risk-screening-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import AmlRiskScreeningAPI from 'services/aml-risk-screening/aml-risk-screening-api';

const communicator = new AmlRiskScreeningAPI();

function* getAmlRiskScreeningConfigBegin(action) {
  yield put(startProcessing('amlRiskScreening/getAmlRiskScreeningConfig'));
  try {
    const json = yield communicator.fetchConfig();
    yield put(getAmlRiskScreeningConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('amlRiskScreening/getAmlRiskScreeningConfig'));
}

export function* getAmlRiskScreeningConfigWatcher() {
  yield takeLatest('amlRiskScreening/getAmlRiskScreeningConfig', getAmlRiskScreeningConfigBegin);
}

function* getAmlRiskScreeningImportStatusBegin(action) {
  yield put(startProcessing('amlRiskScreening/getAmlRiskScreeningImportStatus'));
  try {
    const json = yield communicator.getImportStatus(action.payload);
    yield put(getAmlRiskScreeningImportStatusSuccess({ importStatus: json }));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('amlRiskScreening/getAmlRiskScreeningImportStatus'));
}

export function* getAmlRiskScreeningImportStatusWatcher() {
  yield takeLatest(
    'amlRiskScreening/getAmlRiskScreeningImportStatus',
    getAmlRiskScreeningImportStatusBegin,
  );
}

function* saveAmlRiskScreeningConfigBegin(action) {
  yield put(startProcessing('amlRiskScreening/saveAmlRiskScreeningConfig'));
  try {
    const json = yield communicator.updateConfig(action.payload);
    yield put(saveAmlRiskScreeningConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('amlRiskScreening/saveAmlRiskScreeningConfig'));
}

export function* saveAmlRiskScreeningConfigWatcher() {
  yield takeLatest('amlRiskScreening/saveAmlRiskScreeningConfig', saveAmlRiskScreeningConfigBegin);
}

function* deleteAmlRiskScreeningConfigBegin(action) {
  yield put(startProcessing('amlRiskScreening/deleteAmlRiskScreeningConfig'));
  try {
    const json = yield communicator.deleteConfig(action.payload);
    yield put(deleteAmlRiskScreeningConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('amlRiskScreening/deleteAmlRiskScreeningConfig'));
}

export function* deleteAmlRiskScreeningConfigWatcher() {
  yield takeLatest(
    'amlRiskScreening/deleteAmlRiskScreeningConfig',
    deleteAmlRiskScreeningConfigBegin,
  );
}
