/**
 * material ui theme
 */

import { purple } from '@mui/material/colors';
import * as CSS from 'csstype';

/**
 * material-ui specific language
 */
const enUS = {
  props: {
    MuiBreadcrumbs: {
      expandText: 'Show path',
    },
    MuiTablePagination: {
      backIconButtonText: 'Previous page',
      labelRowsPerPage: 'Per page',
      labelDisplayedRows: ({ from, to, count }: { from: string; to: string; count: number }) =>
        `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`,
      nextIconButtonText: 'Next page',
    },
    MuiRating: {
      getLabelText: (value: number) => `${value} Star${value !== 1 ? 's' : ''}`,
      emptyLabelText: 'Empty',
    },
    MuiAutocomplete: {
      clearText: 'Clear',
      closeText: 'Close',
      loadingText: 'Loading…',
      noOptionsText: 'No options',
      openText: 'Open',
    },
    MuiAlert: {
      closeText: 'Close',
    },
    MuiPagination: {
      'aria-label': 'Pagination navigation',
      getItemAriaLabel: (type: string, page: number, selected: boolean) => {
        if (type === 'page') {
          return `${selected ? '' : 'Go to '}page ${page}`;
        }
        if (type === 'first') {
          return 'Go to first page';
        }
        if (type === 'last') {
          return 'Go to last page';
        }
        if (type === 'next') {
          return 'Go to next page';
        }
        if (type === 'previous') {
          return 'Go to previous page';
        }
        return undefined;
      },
    },
  },
};

/**
 * typography for material components
 */
const typography = {
  fontSize: 14,
  button: {
    textTransform: 'none' as CSS.Property.TextTransform,
  },
};

/**
 * palette for material ui
 */ const palette = {
  type: 'light',

  primary: {
    main: '#2532E5',
  },
  secondary: {
    main: '#368cff',
  },
  complementary: {
    main: '#ff802f',
  },
  success: {
    lightBg: '#e7f3ed',
    main: '#09814a',
  },
  warning: {
    lightBg: '#fff8e9',
    main: '#ffb31f',
  },
  default: {
    main: '#212121',
    white: '#ffffff',
    outline: '#b2b2b2',
  },
  error: {
    lightBg: '#fde6e6',
    main: '#cf0000',
    dark: '#8b0000',
  },
  critical: {
    main: purple[700],
    lightBg: '#f2e9f6',
  },
};

/**
 * overriding css for material components through app
 */
const components = {
  MuiTypography: {
    styleOverrides: {
      h3: {
        fontSize: '24px',
      },
      h4: {
        fontSize: '20px',
      },
      h5: {
        fontSize: '18px',
      },
      h6: {
        fontSize: '16px',
      },
      body1: {
        fontSize: '14px',
      },
      subtitle2: {
        fontWeight: 'bold',
      },
      overline: {
        fontSize: '10px',
        textTransform: 'none' as CSS.Property.TextTransform,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      title: {
        fontSize: '16px',
      },
      subheader: {
        fontSize: '14px',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: '36px',
        height: '36px',
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: '14px',
      },
      secondary: {
        fontSize: '12px',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 46,
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        fontSize: '14px',
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 3,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: '12px',
        lineHeight: 1.5,
        color: 'var(--on-surface-secondary)',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '14px',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: '14px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: '14px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '14px',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        fontSize: '14px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      textTransform: 'none',
    },
  },
};

/**
 * custom components created using material ui
 */
const customComponents = {
  riskChipComponent: {
    low: {
      color: palette.success.main,
      backgroundColor: palette.success.lightBg,
    },
    medium: {
      color: palette.warning.main,
      backgroundColor: palette.warning.lightBg,
    },
    high: {
      color: palette.error.main,
      backgroundColor: palette.error.lightBg,
    },
    prohibited: {
      color: palette.critical.main,
      backgroundColor: palette.critical.lightBg,
    },
    critical: {
      color: palette.critical.main,
      backgroundColor: palette.critical.lightBg,
    },
    neutral: {
      color: 'rgba(0, 0, 0, 0.54)',
      backgroundColor: 'rgba(0, 0, 0, 0.14)',
    },
    default: {
      backgroundColor: '#EBEBEB',
    },
    darkDefault: {
      color: '#fff',
      backgroundColor: '#757575',
    },
  },
  categoryChipComponent: {
    default: {
      color: palette.default.white,
      backgroundColor: palette.primary.main,
    },
    light: {
      color: palette.primary.main,
      backgroundColor: 'rgba(var(--rgb-primary), 0.06)',
    },
  },
  wheelPointComponent: {
    large: {
      number: {
        fontSize: '26px',
      },
      text: {
        fontSize: '12px',
      },
    },
    medium: {
      number: {
        fontSize: '20px',
      },
      text: {
        fontSize: '12px',
      },
    },
    small: {
      number: {
        fontSize: '16px',
      },
      text: {
        fontSize: '10px',
      },
    },
  },
  taskStatusChipComponent: {
    pending: {
      color: palette.warning.main,
      backgroundColor: palette.warning.lightBg,
    },
    approved: {
      color: palette.success.main,
      backgroundColor: palette.success.lightBg,
    },
    denied: {
      color: palette.error.main,
      backgroundColor: palette.error.lightBg,
    },
  },
  executionStatusChipComponent: {
    running: {
      color: palette.primary.main,
      backgroundColor: '#E5F3FA',
    },
    complete: {
      color: palette.success.main,
      backgroundColor: palette.success.lightBg,
    },
  },
};

export { customComponents, components, palette, typography, enUS };
