import { Typography, Grid, CardActionArea } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const SectionLink = ({ label, changeTabViewOnClick }) => (
  <CardActionArea>
    <Grid
      container
      item
      className="clickable border-top pt-2 pb-2"
      justifyContent="space-between"
      alignItems="center"
      onClick={changeTabViewOnClick}
    >
      <Typography variant="body2" color="textPrimary" className="ml-1">
        {label}
      </Typography>
      <ArrowForward color="primary" className="mr-1" />
    </Grid>
  </CardActionArea>
);

export { SectionLink };
