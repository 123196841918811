import {
  EntityResolutionRiskTemplates,
  ER_RISK_SECTION,
} from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/entity-resolution-risk-details';
import i18next from 'i18next';
import { CRR_METADATA_KEYS } from 'utils/constants/crr.constants';

export const getConfigKeys = () => {
  const crrSections = i18next.t('mapping:customerRiskRating.sections');
  const industrySection = crrSections.INDUSTRY.value.editors;
  const customerSection = crrSections.CUSTOMER.value.editors;
  const geographySection = crrSections.GEOGRAPHY.value.editors;
  return {
    mccRiskLevel: industrySection.MCC_RISK_LEVEL.key,
    pepScore: customerSection.PEP_SCORE.key,
    adverseMediaScore: customerSection.ADVERSE_MEDIA_SCORE.key,
    organizationType: industrySection.ORGANIZATION_TYPE.key,
    hidta: geographySection.HIDTA.key,
    hifca: geographySection.HIFCA.key,
    durationInBusinessScore: customerSection.DURATION_IN_BUSINESS.key,
    durationCustomerAgeScore: customerSection.DURATION_CUSTOMER_AGE.key,
    countryOfRegistration: geographySection.COUNTRY_OF_REGISTRATION.key,
    countryOfResidence: geographySection.COUNTRY_OF_RESIDENCE.key,
    expectedIncomeScore: customerSection.EXPECTED_INCOME.key,
    occupationRiskLevel: customerSection.OCCUPATION_RISK_LEVEL.key,
    productTypeRiskLevel: customerSection.PRODUCT_TYPE_RISK_LEVEL.key,
    contractValueScore: customerSection.CONTRACT_VALUE.key,
    durationCustomerSinceScore: customerSection.DURATION_CUSTOMER_SINCE.key,

    // TODO: add here if more risk factor
  };
};

export const buildIndividualPointConfigs = (pointsConfigs, activeMap, checkIfActive) => {
  const configKeys = getConfigKeys();
  return {
    [configKeys.mccRiskLevel]: pointsConfigs?.filter(
      (c) =>
        c?.group === configKeys.mccRiskLevel && (checkIfActive ? activeMap?.MCC === true : true),
    ),
    [configKeys.occupationRiskLevel]: pointsConfigs?.filter(
      (c) =>
        c?.group === configKeys.occupationRiskLevel &&
        (checkIfActive ? activeMap?.OCCUPATION === true : true),
    ),
    [configKeys.productTypeRiskLevel]: pointsConfigs?.filter(
      (c) =>
        c?.group === configKeys.productTypeRiskLevel &&
        (checkIfActive ? activeMap?.PRODUCT_TYPE === true : true),
    ),
    [configKeys.pepScore]: pointsConfigs?.filter(
      (c) =>
        c?.name === configKeys.pepScore &&
        (checkIfActive ? activeMap?.ASSESSMENT_PEP === true : true),
    ),
    [configKeys.adverseMediaScore]: pointsConfigs?.filter(
      (c) =>
        c?.name === configKeys.adverseMediaScore &&
        (checkIfActive ? activeMap?.ASSESSMENT_ADVERSE_MEDIA === true : true),
    ),
    [configKeys.organizationType]: pointsConfigs?.filter(
      (c) =>
        c?.group === configKeys.organizationType &&
        (checkIfActive ? activeMap?.ORGANIZATION_TYPE === true : true),
    ),
    [configKeys.hidta]: pointsConfigs?.filter(
      (c) => c?.name === configKeys.hidta && (checkIfActive ? activeMap?.HIDTA === true : true),
    ),
    [configKeys.hifca]: pointsConfigs?.filter(
      (c) => c?.name === configKeys.hifca && (checkIfActive ? activeMap?.HIFCA === true : true),
    ),
    [configKeys.durationInBusinessScore]: pointsConfigs?.filter(
      (c) =>
        c?.name === configKeys.durationInBusinessScore &&
        (checkIfActive ? activeMap?.DURATION_IN_BUSINESS === true : true),
    ),
    [configKeys.durationCustomerAgeScore]: pointsConfigs?.filter(
      (c) =>
        c?.name === configKeys.durationCustomerAgeScore &&
        (checkIfActive ? activeMap?.DURATION_CUSTOMER_AGE === true : true),
    ),
    [configKeys.countryOfRegistration]: pointsConfigs?.filter(
      (c) =>
        c?.group === configKeys.countryOfRegistration &&
        (checkIfActive ? activeMap?.COUNTRY_OF_REGISTRATION === true : true),
    ),
    [configKeys.countryOfResidence]: pointsConfigs?.filter(
      (c) =>
        c?.group === configKeys.countryOfResidence &&
        (checkIfActive ? activeMap?.COUNTRY_OF_RESIDENCE === true : true),
    ),
    [configKeys.expectedIncomeScore]: pointsConfigs?.filter(
      (c) =>
        c?.name === configKeys.expectedIncomeScore &&
        (checkIfActive ? activeMap?.EXPECTED_INCOME === true : true),
    ),
    [configKeys.contractValueScore]: pointsConfigs?.filter(
      (c) =>
        c?.name === configKeys.contractValueScore &&
        (checkIfActive ? activeMap?.CONTRACT_VALUE === true : true),
    ),
    [configKeys.durationCustomerSinceScore]: pointsConfigs?.filter(
      (c) =>
        c?.name === configKeys.durationCustomerSinceScore &&
        (checkIfActive ? activeMap?.DURATION_CUSTOMER_SINCE === true : true),
    ),

    // TODO: add here if more risk factor
  };
};

export const getScoreNames = () => {
  const crrSections = i18next.t('mapping:customerRiskRating.sections');
  const industrySection = crrSections.INDUSTRY.value.editors;
  const customerSection = crrSections.CUSTOMER.value.editors;
  const geographySection = crrSections.GEOGRAPHY.value.editors;
  return {
    mccScore: industrySection.MCC_RISK_LEVEL.scoreName.key,
    pepScore: customerSection.PEP_SCORE.scoreName.key,
    adverseMediaScore: customerSection.ADVERSE_MEDIA_SCORE.scoreName.key,
    organizationTypeScore: industrySection.ORGANIZATION_TYPE.scoreName.key,
    hidtaScore: geographySection.HIDTA.scoreName.key,
    hifcaScore: geographySection.HIFCA.scoreName.key,
    durationInBusinessScore: customerSection.DURATION_IN_BUSINESS.scoreName.key,
    durationCustomerAgeScore: customerSection.DURATION_CUSTOMER_AGE.scoreName.key,
    countryOfRegistration: geographySection.COUNTRY_OF_REGISTRATION.scoreName.key,
    countryOfResidence: geographySection.COUNTRY_OF_RESIDENCE.scoreName.key,
    expectedIncomeScore: customerSection.EXPECTED_INCOME.scoreName.key,
    occupationScore: customerSection.OCCUPATION_RISK_LEVEL.scoreName.key,
    productTypeScore: customerSection.PRODUCT_TYPE_RISK_LEVEL.scoreName.key,
    contractValueScore: customerSection.CONTRACT_VALUE.scoreName.key,
    durationCustomerSinceScore: customerSection.DURATION_CUSTOMER_SINCE.scoreName.key,

    // TODO: add here if more risk factor
  };
};

/**
 * Return an array such as:
 * [
 *   {
 *     key: 'geography',
 *     values: ['HIDTA_SCORE', 'HIFCA_SCORE'],
 *   },
 *   {
 *     key: 'customer',
 *     values: ['PEP_SCORE', 'ADVERSE_MEDIA_SCORE'],
 *   },
 *   {
 *     key: 'industry',
 *     values: ['MCC_SCORE', 'ORGANIZATION_TYPE_SCORE'],
 *   },
 * ]
 */
export const getRiskScoreCategory = () => {
  const crrSections = i18next.t('mapping:customerRiskRating.sections');
  return Object.keys(crrSections)
    .map((sectionKey) => ({
      key: crrSections[sectionKey].key.toLowerCase(),
      values: crrSections[sectionKey].value.editors,
    }))
    .map((item) => ({
      key: item.key,
      values: Object.keys(item.values).map((k) => item.values[k].scoreName?.key),
    }))
    .map((item) => ({
      key: item.key,
      values: item.values.filter((v) => !!v),
    }));
};

/**
 * Return an object such as:
 * {
 *   HIDTA: 'HIDTA_SCORE',
 *   HIFCA: 'HIFCA_SCORE',
 *   PEP_SCORE: 'PEP_SCORE',
 *   ADVERSE_MEDIA_SCORE: 'ADVERSE_MEDIA_SCORE',
 *   MCC_RISK_LEVEL: 'MCC_SCORE',
 *   ORGANIZATION_TYPE: 'ORGANIZATION_TYPE_SCORE',
 * }
 */
export const getConfigKeyScoreNameMap = () => {
  const crrSections = i18next.t('mapping:customerRiskRating.sections');
  return Object.keys(crrSections)
    .map((sectionKey) => crrSections[sectionKey].value.editors)
    .reduce((prev, val) => [...prev, ...Object.values(val)], [])
    .reduce((prev, val) => ({ ...prev, [val.key]: val.scoreName?.key }), {});
};

/**
 * Return an object such as:
 * {
    summary: 'SUMMARY',
    detail: 'DETAIL',
    originalData: 'ORIGINAL_DATA',
    calculatedPoints: 'CALCULATED_POINTS',
    totalPoints: 'TOTAL_POINTS',
    finalRiskLevel: 'FINAL_RISK_LEVEL',
  }
 */
// to replace: use constants instead of mapping.json
export const getMetaDataKeys = () => CRR_METADATA_KEYS;

export const activeGenericRiskFactors = (riskRatingConfig) =>
  riskRatingConfig?.riskFactorsConfig?.instances?.filter((instance) => instance?.active);

export const buildMaxPointsByRiskFactor = (riskRatingConfig, genericRiskFactors) => {
  const individualPointsConfig = buildIndividualPointConfigs(
    riskRatingConfig?.pointsConfigs,
    riskRatingConfig?.activeMap,
    true,
  );
  const configKeyScoreNameMap = getConfigKeyScoreNameMap();
  genericRiskFactors?.forEach((instance) => {
    configKeyScoreNameMap[instance.uniqueId] = instance.title;
    individualPointsConfig[instance.uniqueId] = instance.pointsConfigs;
  });

  const maxPointsByRiskFactor = {};
  Object.keys(individualPointsConfig).forEach((k) => {
    if (individualPointsConfig[k]?.length > 0) {
      maxPointsByRiskFactor[configKeyScoreNameMap[k]] = Math.max(
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...individualPointsConfig[k]?.map((item) => item.points ?? 0),
      );
    } else {
      maxPointsByRiskFactor[configKeyScoreNameMap[k]] = 0;
    }
  });
  return maxPointsByRiskFactor;
};

const buildCatScoreItems = (category, detail) => {
  const values = getRiskScoreCategory().find((item) => item.key === category)?.values ?? [];
  const catScoreItems = {};
  values.forEach((v) => {
    if (detail[v]) {
      catScoreItems[v] = detail[v];
    }
  });
  const riskFactorInstancesFromThisCase = Object.keys(detail).filter(
    (detailKey) =>
      detail[detailKey]?.RISK_FACTOR_INSTANCE?.section?.toUpperCase() === category.toUpperCase(),
  );

  riskFactorInstancesFromThisCase.forEach((instanceKey) => {
    catScoreItems[instanceKey] = detail[instanceKey];
  });

  // ER showcase - once we have RISK_FACTOR_INSTANCE in metadata, this can be removed
  if (ER_RISK_SECTION === category.toUpperCase()) {
    const entityResRiskFactorsFromThisCase = Object.keys(detail).filter((detailKey) =>
      Object.values(EntityResolutionRiskTemplates).includes(detail[detailKey]?.TEMPLATE),
    );
    entityResRiskFactorsFromThisCase.forEach((instanceKey) => {
      catScoreItems[instanceKey] = detail[instanceKey];
    });
  }
  return catScoreItems;
};

const sumCatTotalPoints = (catScoreItems) => {
  const metaDataKeys = getMetaDataKeys();
  return Object.values(catScoreItems).reduce(
    (prev, val) => prev + (val?.[metaDataKeys.calculatedPoints] || 0),
    0,
  );
};

export const buildRiskScoreCategoryDerivedData = (detail) => {
  const riskScoreCategoryDerivedData = {};
  getRiskScoreCategory().forEach((cat) => {
    const catScoreItems = buildCatScoreItems(cat.key, detail);
    riskScoreCategoryDerivedData[cat.key] = {
      catScoreItems,
      catScoreItemsAvailable: Object.keys(catScoreItems).length > 0,
      catTotalPoints: sumCatTotalPoints(catScoreItems),
    };
  });
  return riskScoreCategoryDerivedData;
};

const getCategoryOfRiskFactor = (scoreName, genericRiskFactors) => {
  const customCategory = genericRiskFactors
    ?.find((instance) => instance?.title?.toUpperCase() === scoreName.toUpperCase())
    ?.section?.toUpperCase();
  const originalCategory = getRiskScoreCategory().find((item) =>
    item?.values?.includes(scoreName),
  )?.key;
  return originalCategory || customCategory;
};

export const buildCategoryMaxPoints = (riskRatingConfig) => {
  const categoryMaxPoints = {};
  const genericRiskFactors = activeGenericRiskFactors(riskRatingConfig);
  const maxPointsByRiskFactor = buildMaxPointsByRiskFactor(riskRatingConfig, genericRiskFactors);
  Object.keys(maxPointsByRiskFactor).forEach((pointKey) => {
    const category = getCategoryOfRiskFactor(pointKey, genericRiskFactors)?.toLowerCase();
    categoryMaxPoints[category] =
      (categoryMaxPoints[category] ?? 0) + maxPointsByRiskFactor[pointKey];
  });
  return categoryMaxPoints;
};

export const getApiReferenceMappings = (scoreGroupMapping, customerType, riskFactorInstance) =>
  Object.values(scoreGroupMapping?.apiReference?.[customerType] || {})?.find(
    (apiReference) => apiReference?.key === riskFactorInstance?.apiReference,
  );
