import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';
import {
  getFirstTransactionFromCase,
  transformScreeningHitRecordEntityToJson,
} from 'utils/helpers/cases/case-utility';
import { groupAllCaseStateChangesWithTheSameActionIntoOne } from 'modules/trx-cases/case-detail/components/case-actions/utils/case-state-change-util';

class CaseManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Case API Communicator instance is required.');
    }
  }

  static validateCasesJson(json) {
    const valid = json && json.data && Array.isArray(json.data.content);
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  static validateForwardingToCasesJson(json) {
    const cases = json?.data?.content;
    if (Array.isArray(cases)) {
      const forwardingCases = cases.filter((c) => c.forwardingCasesCount === 0);

      // TODO (ERS) we don't need this when we stop saving "record" and "entity" as string
      forwardingCases.forEach((caseDetail) =>
        caseDetail?.screeningHitEvents?.forEach((she) =>
          transformScreeningHitRecordEntityToJson(she?.hits),
        ),
      );
      return Promise.resolve(forwardingCases);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  queryCasesByForwardingToCaseId(forwardingToCaseId, caseTenantId, page, pageSize) {
    return this.communicator
      .fetchCasesByForwardingToCaseId(forwardingToCaseId, caseTenantId, page, pageSize)
      .then((json) => CaseManager.validateForwardingToCasesJson(json));
  }

  updateCaseStatus(payload) {
    return this.communicator
      .updateCaseStatus(
        payload.caseId,
        payload.caseStatus,
        payload.transactionStatus,
        payload.processingStatus,
        payload.note,
        payload.createdAt,
      )
      .then((json) => Promise.resolve(json));
  }

  queryAuditTracks(
    size,
    page,
    sortParams,
    filterColumn,
    filterValue,
    filterOperator,
    filterStartDate,
    filterEndDate,
    excludeSystemUser,
  ) {
    return this.communicator
      .fetchAuditTracks(
        size,
        page,
        sortParams,
        filterColumn,
        filterValue,
        filterOperator,
        filterStartDate,
        filterEndDate,
        excludeSystemUser,
      )
      .then((json) => CaseManager.validateCasesJson(json));
  }

  queryDistinctCurrencyValuesByCaseStatus(caseStatus) {
    return this.communicator
      .fetchDistinctCurrencyValuesByCaseStatus(caseStatus)
      .then((json) => Promise.resolve(json));
  }

  queryDistinctUserIdsFromAuditTracks() {
    return this.communicator
      .fetchDistinctUserIdsFromAuditTracks()
      .then((json) => Promise.resolve(json));
  }

  static validateCaseDetailJson(json) {
    if (json) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASE_DETAIL_DATA_INVALID);
  }

  unveilSanctionHitNamesForCase(caseDetail, unveilOriginator, unveilBeneficiary) {
    const transaction = getFirstTransactionFromCase(caseDetail);

    const holderNames = [];
    if (unveilOriginator) {
      holderNames.push(transaction.fundsOriginator.holderName);
    }
    if (unveilBeneficiary) {
      holderNames.push(transaction.fundsBeneficiary.holderName);
    }

    const tasks = holderNames.map((holderName) => {
      if (!!holderName && !!holderName.token) {
        return this.unmaskOneToken(caseDetail.uuid, holderName);
      }
      return Promise.resolve({});
    });

    return Promise.all(tasks);
  }

  unveilCounterpartyData(uuid, counterpartyData) {
    const contactDetails = counterpartyData.contactDetails || {};
    const zipCode = contactDetails.zip || {};
    const town = contactDetails.town || {};
    const street = contactDetails.street || {};
    const country = contactDetails.country || {};
    const maskedData = [];

    if (zipCode.token !== undefined) {
      maskedData.push(zipCode);
    }

    if (town.token !== undefined) {
      maskedData.push(town);
    }

    if (street.token !== undefined) {
      maskedData.push(street);
    }

    if (country.token !== undefined) {
      maskedData.push(country);
    }

    const tasks = maskedData.map((d) => this.unmaskOneToken(uuid, d));

    return Promise.all(tasks);
  }

  queryCaseDetail(uuid, createdAt) {
    return this.communicator
      .fetchCaseByUUID(uuid, createdAt)
      .then((caseDetail) => CaseManager.validateCaseDetailJson(caseDetail));
  }

  static validateDecryptedJson(json) {
    if (json && json.token && json.decrypted) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.SECURE_STORAGE_DATA_INVALID);
  }

  unmaskOneToken(uuid, mask) {
    return this.communicator
      .unmaskOneToken(uuid, mask)
      .then((json) => CaseManager.validateDecryptedJson(json));
  }

  queryCaseStateChanges(uuid, caseTenantId) {
    return this.communicator
      .fetchCaseStateChanges(uuid, caseTenantId)
      .then((possibleCaseStateChanges) =>
        groupAllCaseStateChangesWithTheSameActionIntoOne(possibleCaseStateChanges),
      );
  }

  updateCaseState(params) {
    return this.communicator
      .updateCaseState(params)
      .then((possibleCaseStateChanges) =>
        groupAllCaseStateChangesWithTheSameActionIntoOne(possibleCaseStateChanges),
      );
  }

  fetchReferenceNames(orderField, orientation) {
    return this.communicator.fetchReferenceNames(orderField, orientation);
  }

  assessSanctionHit(uuid, payload) {
    return this.communicator.assessSanctionHit(uuid, payload);
  }

  addAuditTrack(uuid, userId, message, event, caseStateChange) {
    if (uuid != null && userId != null && message != null && message.length > 0 && event != null) {
      return this.communicator.addAuditTrack(uuid, userId, message, event, caseStateChange);
    }
    return raiseErrorByCode(ErrorCode.AUDIT_TRACK_DATA_INVALID);
  }

  addStandaloneAuditTrack(userId, message, event, sectionId) {
    if (
      userId != null &&
      message != null &&
      message.length > 0 &&
      event != null &&
      sectionId != null
    ) {
      return this.communicator.addStandaloneAuditTrack(userId, message, event, sectionId);
    }
    return raiseErrorByCode(ErrorCode.STAND_ALONE_AUDIT_TRACK_DATA_INVALID);
  }

  assessVolumeRisk(caseId, payload) {
    return this.communicator.assessVolumeRisk(caseId, payload);
  }

  assessCountryRisk(caseId, payload) {
    return this.communicator.postCountryRiskAssessment(caseId, payload);
  }

  assessLabel(caseId, payload) {
    return this.communicator.postLabelEngineAssessment(caseId, payload);
  }

  assess(caseId, isGroupCase, payload) {
    return this.communicator.postAssessment(caseId, isGroupCase, payload);
  }

  static validateTransactionsJson(json) {
    if (json && Array.isArray(json.data)) {
      return Promise.resolve({
        transactions: json.data,
        scrollId: json.scrollId,
      });
    }
    return Promise.resolve({ transactions: [] });
  }

  queryTransactions(payload) {
    return this.communicator
      .fetchTransactions(payload)
      .then((json) => CaseManager.validateTransactionsJson(json));
  }

  queryTransactionsV2(filtering, paging, sorting) {
    return this.communicator.fetchTransactionsV2(filtering, paging, sorting).then((json) =>
      CaseManager.validateTransactionsJson({
        data: json.cases,
        scrollId: json.scrollId,
      }),
    );
  }

  getConfigurationLastUpdateInfo(sectionIds) {
    return this.communicator.getConfigurationLastUpdateInfo(sectionIds);
  }

  assignCaseToAnotherOperator(payload) {
    return this.communicator.assignCaseToAnotherOperator(payload);
  }

  unmaskOneCase(params) {
    return this.communicator.unmaskOneCase(params);
  }

  unmaskMasterData(uuid) {
    return this.communicator.unmaskMasterData(uuid);
  }

  async unmaskManyCases(payload) {
    const { displayUuid, uuids, isCustomerCase, caseTenantId, unmaskedCaseIds, isCustomerData } =
      payload;
    const chunkSize = 3;
    const result = new Set();

    if (!Array.isArray(uuids)) {
      return Promise.resolve([]);
    }

    const unresolvedUuids = uuids.filter((uuid) => !unmaskedCaseIds?.includes(uuid));

    for (let i = 0; i < unresolvedUuids.length; i += chunkSize) {
      const chunk = unresolvedUuids.slice(i, i + chunkSize);

      const tasks = chunk.map((uuid) =>
        this.unmaskOneCase({
          uuid,
          currentCaseId: displayUuid,
          isCustomerCase,
          isCustomerData,
          caseTenantId,
        }),
      );

      // eslint-disable-next-line no-await-in-loop
      const decrypted = await this.processBulkRequests(tasks);
      decrypted.forEach(result.add, result);
    }

    return Array.from(result);
  }

  // eslint-disable-next-line class-methods-use-this
  processBulkRequests(tasks) {
    return Promise.all(tasks).then((bulkPromiseResults) => {
      const result = new Set();
      bulkPromiseResults.forEach((taskResult) => {
        taskResult.forEach((item) => result.add(item));
      });

      return Promise.resolve(Array.from(result));
    });
  }

  getCustomerPreviousAlerts(customerAccountLookupIdToken, caseId, createdAt, page, pageSize) {
    return this.communicator.getCustomerPreviousAlerts(
      customerAccountLookupIdToken,
      caseId,
      createdAt,
      page,
      pageSize,
    );
  }

  getCustomerProfileBalances(params) {
    return this.communicator.getCustomerProfileBalances(params);
  }

  getCaseLast4MonthsTxMetadata(tenantId, caseId) {
    return this.communicator.getCaseLast4MonthsTxMetadata(tenantId, caseId);
  }

  getCaseLast4MonthsTxHeatmapImage(tenantId, caseId) {
    return this.communicator.getCaseLast4MonthsTxHeatmapImage(tenantId, caseId);
  }

  breakOutCaseFromGroup(groupCaseId, breakingOutCaseId, operatorNote) {
    return this.communicator.breakOutCaseFromGroup({
      groupCaseId,
      breakingOutCaseId,
      operatorNote,
    });
  }

  addCaseToGroup(caseId, addingCaseId, operatorNote) {
    return this.communicator.addCaseToGroup({
      caseId,
      addingCaseId,
      operatorNote,
    });
  }

  getCaseDetailConfig(tenantId, configVersion) {
    return this.communicator.getCaseDetailConfig(tenantId, configVersion);
  }

  getCaseCollection(ids) {
    return this.communicator.getCaseCollection(ids);
  }
}

export default CaseManager;
