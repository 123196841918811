import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import querystring from 'querystring';
import config from 'utils/helpers/config-utils/config';
import ErrorCode from 'utils/constants/error-code';
import APICommunicator from 'services/api-communicator';
import { getTimezoneNumber } from 'modules/configuration/rule-manager/common/helpers/util';
import Utility from 'utils/helpers/utility';

class CaseStatisticsAPICommunicator extends APICommunicator {
  // eslint-disable-next-line class-methods-use-this
  prepareQuery(params) {
    const { startDate, endDate } = params;
    const zoneId = getTimezoneNumber();
    const queryParams = {
      startDate: Utility.formatDateForApi(startDate),
      endDate: Utility.formatDateForApi(endDate),
      zoneId,
    };
    return querystring.stringify(queryParams);
  }

  // eslint-disable-next-line class-methods-use-this
  prepareQueryV2(params) {
    const { startDate, endDate } = params;
    const queryParams = {
      startDate: Utility.formatDateForApiAsZonedDateTime(startDate),
      endDate: Utility.formatDateForApiAsZonedDateTime(endDate),
    };
    return querystring.stringify(queryParams);
  }

  // eslint-disable-next-line class-methods-use-this
  prepareQueryZoneIdOnly() {
    const queryParams = {
      zoneId: getTimezoneNumber(),
    };
    return querystring.stringify(queryParams);
  }

  getCaseHealthMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/case-health/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getCaseTypeCountMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/case-type-counts/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getCaseCountMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/case-counts/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getTransactionCountMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/transactions-count/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getGroupsCountMetric(params) {
    const queryString = this.prepareQueryV2(params);
    const apiUrl = API_URLS.dashboard.caseStatisticsAndHealth.groupsCount.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getGroupsTypeCountMetric(params) {
    const queryString = this.prepareQueryV2(params);
    const apiUrl = API_URLS.dashboard.caseStatisticsAndHealth.typesCount.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getGroupsHealthMetric() {
    const queryString = this.prepareQueryZoneIdOnly();
    const apiUrl = API_URLS.dashboard.caseStatisticsAndHealth.groupsHealth.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default CaseStatisticsAPICommunicator;
