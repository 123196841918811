import { RISKY_WORDS_V2_ACTIONS } from 'modules/configuration/rule-manager/risky-words-v2/middleware/action-types';

export const getRiskyWordsV2Config = () => ({
  type: RISKY_WORDS_V2_ACTIONS.fetch.data,
});

export const getRiskyWordsV2ConfigSuccess = (payload) => ({
  type: RISKY_WORDS_V2_ACTIONS.fetch.success,
  payload,
});

export const getRiskyWordsV2ConfigError = () => ({
  type: RISKY_WORDS_V2_ACTIONS.fetch.error,
});

export const updateRiskyWordsV2Config = (payload) => ({
  type: RISKY_WORDS_V2_ACTIONS.update.data,
  payload,
});

export const updateRiskyWordsV2ConfigSuccess = (payload) => ({
  type: RISKY_WORDS_V2_ACTIONS.update.success,
  payload,
});

export const updateRiskyWordsV2ConfigError = () => ({
  type: RISKY_WORDS_V2_ACTIONS.update.error,
});

export const deleteRiskyWordsV2Config = (payload) => ({
  type: RISKY_WORDS_V2_ACTIONS.delete.data,
  payload,
});

export const deleteRiskyWordsV2ConfigSuccess = (payload) => ({
  type: RISKY_WORDS_V2_ACTIONS.delete.success,
  payload,
});

export const deleteRiskyWordsV2ConfigError = () => ({
  type: RISKY_WORDS_V2_ACTIONS.delete.error,
});
