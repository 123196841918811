import { TRANSACTION_FIELDS_ACTIONS } from 'middleware/action-types';

export const getTransactionFields = (payload) => ({
  type: TRANSACTION_FIELDS_ACTIONS.fetch.data,
  payload,
});

export const getTransactionFieldsSuccess = (payload) => ({
  type: TRANSACTION_FIELDS_ACTIONS.fetch.success,
  payload,
});

export const getTransactionFieldsError = () => ({
  type: TRANSACTION_FIELDS_ACTIONS.fetch.error,
});
