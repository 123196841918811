import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';

export const updateCaseState = (uuid, tenantId, body) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.stateUpdate.data,
  payload: {
    uuid,
    tenantId,
    body,
  },
});

export const updateCaseStateSuccess = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.stateUpdate.success,
  payload,
});

export const updateCaseStateError = () => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.stateUpdate.error,
});
