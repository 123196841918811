import { put, takeLatest } from 'redux-saga/effects';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { showError } from 'middleware/actions/error';

import { ReferenceAccountV2ConfigApiCommunicator } from 'modules/configuration/rule-manager/reference-account-v2/services/reference-account-v2-config-api-communicator';
import { ReferenceAccountV2ConfigManager } from 'modules/configuration/rule-manager/reference-account-v2/services/reference-account-v2-config-manager';
import { REFERENCE_ACCOUNT_V2_ACTIONS } from 'modules/configuration/rule-manager/reference-account-v2/middleware/action-types';
import {
  deleteReferenceAccountV2ConfigError,
  deleteReferenceAccountV2ConfigSuccess,
  getReferenceAccountV2ConfigError,
  getReferenceAccountV2ConfigSuccess,
  updateReferenceAccountV2ConfigError,
  updateReferenceAccountV2ConfigSuccess,
} from 'modules/configuration/rule-manager/reference-account-v2/middleware/actions/reference-account-v2';

const configAPICommunicator = new ReferenceAccountV2ConfigApiCommunicator();
const configManager = new ReferenceAccountV2ConfigManager(configAPICommunicator);

function* getReferenceAccountV2ConfigBegin(action) {
  try {
    yield put(startProcessing(REFERENCE_ACCOUNT_V2_ACTIONS.fetch.data));

    const json = yield configManager.getReferenceAccountV2Config();
    yield put(getReferenceAccountV2ConfigSuccess(json));

    yield put(stopProcessing(REFERENCE_ACCOUNT_V2_ACTIONS.fetch.data));
  } catch (error) {
    yield put(getReferenceAccountV2ConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(REFERENCE_ACCOUNT_V2_ACTIONS.fetch.error));
  }
}

function* getReferenceAccountV2ConfigWatcher() {
  yield takeLatest(REFERENCE_ACCOUNT_V2_ACTIONS.fetch.data, getReferenceAccountV2ConfigBegin);
}

function* updateReferenceAccountV2ConfigBegin(action) {
  yield put(startProcessing(REFERENCE_ACCOUNT_V2_ACTIONS.update.data));

  try {
    const json = yield configManager.updateReferenceAccountV2Config(action.payload);
    yield put(updateReferenceAccountV2ConfigSuccess(json));
  } catch (error) {
    yield put(updateReferenceAccountV2ConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(REFERENCE_ACCOUNT_V2_ACTIONS.update.data));
}

function* updateReferenceAccountV2ConfigWatcher() {
  yield takeLatest(REFERENCE_ACCOUNT_V2_ACTIONS.update.data, updateReferenceAccountV2ConfigBegin);
}

function* deleteReferenceAccountV2ConfigBegin(action) {
  yield put(startProcessing(REFERENCE_ACCOUNT_V2_ACTIONS.delete.data));

  try {
    const json = yield configManager.deleteReferenceAccountV2Config(action.payload);
    yield put(deleteReferenceAccountV2ConfigSuccess(json));
  } catch (error) {
    yield put(deleteReferenceAccountV2ConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(REFERENCE_ACCOUNT_V2_ACTIONS.delete.data));
}

function* deleteReferenceAccountV2ConfigWatcher() {
  yield takeLatest(REFERENCE_ACCOUNT_V2_ACTIONS.delete.data, deleteReferenceAccountV2ConfigBegin);
}

export {
  getReferenceAccountV2ConfigWatcher,
  updateReferenceAccountV2ConfigWatcher,
  deleteReferenceAccountV2ConfigWatcher,
};
