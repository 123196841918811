import { Component } from 'react';
import PropTypes from 'prop-types';
import HoverComponent from 'components/hover-component';
import { getCountryName } from 'utils/constants/country-list';

class HoverCountryName extends Component {
  render() {
    const code = this.props.code || '';
    const country = getCountryName(code);

    return <HoverComponent id={`country_code_${code}`}>{country}</HoverComponent>;
  }
}

HoverCountryName.propTypes = {
  code: PropTypes.string,
};

export default HoverCountryName;
