import { AuthActions } from 'middleware/action-types';

const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case AuthActions.REQUEST_RESET_PASSWORD:
    case AuthActions.REQUEST_RESET_PASSWORD_ERROR: {
      return {
        ...state,
      };
    }

    case AuthActions.REQUEST_RESET_PASSWORD_SUCCESS: {
      const payload = action.payload || {};
      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default resetPasswordReducer;
