import { Translation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';

const DurationInBusinessDetails = ({ maxPoints, scoreData, popoverMode }) => {
  const metaDataKeys = getMetaDataKeys();
  const originalData = scoreData[metaDataKeys.originalData];
  const calculatedDurationInBusiness = scoreData[metaDataKeys.calculatedDurationInBusiness];

  return (
    <Translation>
      {(t) => (
        <ScoreDetailsAccordion
          summaryComponent={
            <WheelPointsWithHeadline
              size={popoverMode ? 'small' : 'medium'}
              points={scoreData[metaDataKeys.calculatedPoints] ?? 0}
              maxPoints={maxPoints}
              headline={t('case:userPopover.riskRating.durationInBusiness.heading')}
            />
          }
          highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
          detailsComponent={
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {originalData || t('case:userPopover.riskRating.missingData.heading')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {!!calculatedDurationInBusiness && (
                    <>
                      {t('case:userPopover.riskRating.durationInBusiness.months.heading', {
                        monthsInBusiness: calculatedDurationInBusiness,
                      })}
                    </>
                  )}
                  {!calculatedDurationInBusiness && originalData && (
                    <>{t('translation:unknown.heading')}</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )}
    </Translation>
  );
};

export { DurationInBusinessDetails };
