import { getTimezoneNumber } from 'modules/configuration/rule-manager/common/helpers/util';
import querystring from 'querystring';
import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';
import Utility from 'utils/helpers/utility';

class EfficiencyAPICommunicator extends APICommunicator {
  // eslint-disable-next-line class-methods-use-this
  prepareQuery(params) {
    const { startDate, endDate } = params;
    const zoneId = getTimezoneNumber();
    const queryParams = {
      startDate: Utility.formatDateForApi(startDate),
      endDate: Utility.formatDateForApi(endDate),
      zoneId,
    };
    return querystring.stringify(queryParams);
  }

  // eslint-disable-next-line class-methods-use-this
  prepareQueryV2(params) {
    const { startDate, endDate } = params;
    const queryParams = {
      startDate: Utility.formatDateForApiAsZonedDateTime(startDate),
      endDate: Utility.formatDateForApiAsZonedDateTime(endDate),
    };
    return querystring.stringify(queryParams);
  }

  // eslint-disable-next-line class-methods-use-this
  prepareQueryZoneIdOnly() {
    const queryParams = {
      zoneId: getTimezoneNumber(),
    };
    return querystring.stringify(queryParams);
  }

  getCaseHealthMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/case-health/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getCaseTypeCountMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/case-type-counts/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getCaseCountMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/case-counts/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getTransactionCountMetric(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/transactions-count/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getFalsePositiveRate({ startDate, endDate, key }) {
    const queryString = this.prepareQuery({ startDate, endDate });
    const apiUrl = `${config.baseUrl}/report/false-positive-rate/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE).then(
      (json) => {
        if (json) {
          json.key = key;
        }
        return Promise.resolve(json);
      },
    );
  }

  getFalsePositivePattern(params) {
    const queryString = this.prepareQuery(params);
    const apiUrl = `${config.baseUrl}/report/false-positive-pattern-v2/?${queryString}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getGroupsFalsePositiveRate({ startDate, endDate, key }) {
    const queryString = this.prepareQueryV2({ startDate, endDate });
    const apiUrl = API_URLS.dashboard.efficiency.falsePositiveRate.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE).then(
      (json) => {
        if (json) {
          json.key = key;
        }
        return Promise.resolve(json);
      },
    );
  }

  getGroupsFalsePositivePattern(params) {
    const queryString = this.prepareQueryV2(params);
    const apiUrl = API_URLS.dashboard.efficiency.falsePositivePattern.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getGroupsTruePositivePattern({ startDate, endDate }) {
    const queryString = this.prepareQueryV2({ startDate, endDate });
    const apiUrl = API_URLS.dashboard.efficiency.truePositivePattern.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getGroupsCasesPerformance({ startDate, endDate }) {
    const queryString = this.prepareQueryV2({ startDate, endDate });
    const apiUrl = API_URLS.dashboard.efficiency.casesPerformance.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getRiskyWordsPattern({ startDate, endDate }) {
    const queryString = this.prepareQueryV2({ startDate, endDate });
    const apiUrl = API_URLS.dashboard.efficiency.riskyWordsPattern.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getGroupsTruePositivePatternByInstance({ startDate, endDate }) {
    const queryString = this.prepareQueryV2({ startDate, endDate });
    const apiUrl = API_URLS.dashboard.efficiency.truePositivePatternByInstance.replace(
      API_PARAMS.queryString,
      queryString,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default EfficiencyAPICommunicator;
