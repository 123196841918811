import { isEmptyNestedChecks } from 'utils/helpers/object-util';

interface Filter {
  field: string;
  rangeStart: Date;
  rangeEnd: Date;
}

interface FilterConfig {
  filters: Filter[];
}

export const handleInvalidUpdatedAtFilter = (
  configFromUrl: FilterConfig,
  defaultLastUpdatedAtFilter: Filter,
) => {
  const updatedAtField = configFromUrl.filters.find(
    (filter) => filter.field === defaultLastUpdatedAtFilter.field,
  );

  if (isEmptyNestedChecks(updatedAtField?.rangeEnd)) {
    return;
  }
  if (!updatedAtField) {
    configFromUrl.filters = [defaultLastUpdatedAtFilter, ...configFromUrl.filters];
  } else if (updatedAtField.rangeStart > updatedAtField.rangeEnd) {
    const originalRangeEnd = updatedAtField.rangeEnd;
    updatedAtField.rangeEnd = updatedAtField.rangeStart;
    updatedAtField.rangeStart = originalRangeEnd;
  }
};
