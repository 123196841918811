const Constants = {
  API_Error_Code: 1001,
  Data_Invalid_Error_Code: 2001,
};

const HTTP_METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

const DATE_FORMAT = {
  medium: 'MMM dd, yyyy',
  large: 'MMMM dd, yyyy',
};

const DATE_TIME_FORMAT = {
  medium: 'MMM dd, yyyy, HH:mm',
  mediumMoment: 'MMM DD, YYYY, HH:mm',
  large: 'MMMM dd, yyyy, HH:mm',
};

const TIME_FORMAT = {
  fullHour: 'HH:mm',
};

const TIMEOUTS = {
  keyboardHints: 4000,
};

const RISK_CATEGORY = {
  low: 'low',
  medium: 'medium',
  high: 'high',
  prohibited: 'prohibited',
  critical: 'critical',
  default: ':default',
};

const API_TYPE = {
  CustomerChecks: 'CustomerChecks',
  AMLChecks: 'AMLChecks',
};

const ALERT_STATUS = {
  open: 'open',
  notOpened: 'not-opened',
  autoClosed: 'auto-closed',
};

const FEATURE_STATUS = {
  attached: 'ATTACHED',
  detached: 'DETACHED',
  config: {
    active: 'active',
  },
};

const TRANSACTION_PARTY = {
  originator: 'FUNDS_ORIGINATOR',
  beneficiary: 'FUNDS_BENEFICIARY',
};

const LOCAL_STORAGE = {
  trxCaseList: {
    columnConfig: 'trx-case-list-column-config',
  },
  customerCaseList: {
    columnConfig: 'customer-case-list-column-config',
  },
  currentCase: {
    tenantId: 'current_case_tenant_id',
  },
  caseList: {
    companyDataShownState: 'company-data-shown-state',
  },
  caseDetails: {
    maskedDataShowState: 'case-detail-data-shown-state',
    unmaskCount: 'unmask-count',
  },
  betaFeature: {
    groupSearch: 'use-beta-group-search',
    customerTransactionHistory: 'use-beta-customer-transaction-history',
    auditTrail: 'use-beta-audit-trail',
    caseNotesFromEs: 'case-notes-es-header',
  },
  platformVersion: {
    current: 'current_platform_version',
    timestamp: 'timestamp_when_current_version_was_displayed_first',
  },
  language: {
    selectedLanguage: 'selected-language',
  },
  customViews: {
    trxCustomViews: 'trx-custom-views',
  },
};

const SESSION_STORAGE = {
  caseDetails: {
    selectedCaseIndex: 'selected-case-index',
  },
};

const REGEX = {
  url: /https?:\/\/[^\s<]+/g,
  digits: /^\d/,
  uuid: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi,
  htmlTag: /<[^>]+>/g,
  digitThreeCount: /\B(?=(\d{3})+(?!\d))/g,
  endOfLine: /(?:\r\n|\r|\n)/g,
  // finds characters on which we should break line (used now in Profile Notes)
  // Includes 'space' following colon or semicolon
  // Excludes 'colon' if part of URL e.g. http://
  specialCharacterLineBreak: /([:;] ?)(?!\/)|([¶-]{2,})/g,
  generatedApiKeyOrAtleast40Alphanumeric: /^([a-fA-F0-9]{8}-[a-zA-Z0-9]{31,}|[a-zA-Z0-9]{40,})$/,
  isoUtcDateTime: /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z/g,
  fileDownloadPlaceholder: /{download\|.*?}/g,
  linkPlaceholder: /{link\|.*?}/g,
  domain: /^[a-zA-Z0-9-]+\.[a-zA-Z0-9]{2,}$/,
};

const BIN_PAYMENT = ['VISA', 'MASTER', 'AMEX', 'DINERS', 'UNIONPAY', 'JCB', 'MAESTRO'];

const CHARTS = {
  seriesColors: [
    '#2532e5',
    '#368cff',
    '#b2c4ff',
    '#000078',
    '#0000BE',
    '#FF802F',
    '#91cc75',
    '#fac858',
    '#ee6666',
    '#73c0de',
    '#3ba272',
    '#f2a280',
    '#7c93ff',
    '#9a60b4',
    '#ea7ccc',
  ],
  riskSeriesColors: ['#09814a', '#ffb31f', '#cf0000', '#7b1fa2'],
};

const FILE_SIZE = {
  max: 15728640,
};

// Similar list is hardcoded in backed. Ensure if change will be reflected.
// TODO add GH link
const ACCEPTED_FILE_EXTENSIONS = [
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.pdf',
  '.odt',
  '.odp',
  '.ods',
  '.txt',
  '.csv',
  '.png',
  '.jpg',
  '.jpeg',
  '.avif',
  '.jp2',
  '.jpx',
  '.jpm',
];

const CONTENT_TYPES = {
  textCsv: 'text/csv',
  excel: 'application/vnd.ms-excel',
  documents: ACCEPTED_FILE_EXTENSIONS.join(', '),
  image: ['image/png', 'image/jpeg'],
  favicon: ['image/vnd.microsoft.icon', 'image/x-icon', 'image/png', 'image/jpeg'],
};
const HTML_BREAK_LINE = '<br />';

export {
  Constants,
  FEATURE_STATUS,
  RISK_CATEGORY,
  TRANSACTION_PARTY,
  LOCAL_STORAGE,
  SESSION_STORAGE,
  REGEX,
  CHARTS,
  BIN_PAYMENT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  FILE_SIZE,
  CONTENT_TYPES,
  TIMEOUTS,
  HTML_BREAK_LINE,
  HTTP_METHODS,
  ACCEPTED_FILE_EXTENSIONS,
  API_TYPE,
  ALERT_STATUS,
};
