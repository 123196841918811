import { DetachmentUtilActions } from 'middleware/action-types';

const detachmentConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET_SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT_SUCCESS:
      return {
        ...state,
        ...action.payload.detachmentConfig,
      };
    case DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload.detachmentConfig,
      };
    case DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
      };
    default:
      return state;
  }
};

export default detachmentConfigReducer;
