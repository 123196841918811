import { EXPECTED_CUSTOMER_VALUE_ACTIONS } from 'modules/configuration/rule-manager/expected-customer-value/middleware/action-types';

export const getExpectedCustomerValueConfig = () => ({
  type: EXPECTED_CUSTOMER_VALUE_ACTIONS.fetch.data,
});

export const getExpectedCustomerValueConfigSuccess = (payload) => ({
  type: EXPECTED_CUSTOMER_VALUE_ACTIONS.fetch.success,
  payload,
});

export const updateExpectedCustomerValueConfig = (payload) => ({
  type: EXPECTED_CUSTOMER_VALUE_ACTIONS.update.send,
  payload,
});

export const updateExpectedCustomerValueConfigSuccess = (payload) => ({
  type: EXPECTED_CUSTOMER_VALUE_ACTIONS.update.success,
  payload,
});

export const deleteExpectedCustomerValueConfig = (payload) => ({
  type: EXPECTED_CUSTOMER_VALUE_ACTIONS.delete.send,
  payload,
});

export const deleteExpectedCustomerValueConfigSuccess = (payload) => ({
  type: EXPECTED_CUSTOMER_VALUE_ACTIONS.delete.success,
  payload,
});
