import { put, takeLatest } from 'redux-saga/effects';
import { COUNTRY_RISK_V2_ACTIONS } from 'modules/configuration/rule-manager/country-config-v2/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteCountryRiskV2ConfigError,
  deleteCountryRiskV2ConfigSuccess,
  getCountryRiskV2ConfigError,
  getCountryRiskV2ConfigSuccess,
  updateCountryRiskV2ConfigError,
  updateCountryRiskV2ConfigSuccess,
} from 'modules/configuration/rule-manager/country-config-v2/middleware/actions/country-risk-v2-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';
import CountryRiskDtoMapper from 'modules/configuration/rule-manager/country-config-v2/middleware/sagas/dto-mapper';

const communicator = new RuleConfigAPICommunicator('country-risk-v2');
const manager = new RuleConfigManager(communicator);

function* getCountryRiskV2ConfigBegin(action) {
  yield put(startProcessing(COUNTRY_RISK_V2_ACTIONS.fetch.data));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getCountryRiskV2ConfigSuccess(CountryRiskDtoMapper.mapFromDto(json)));
  } catch (error) {
    yield put(getCountryRiskV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(COUNTRY_RISK_V2_ACTIONS.fetch.data));
}

export function* getCountryRiskV2ConfigBeginWatcher() {
  yield takeLatest(COUNTRY_RISK_V2_ACTIONS.fetch.data, getCountryRiskV2ConfigBegin);
}

function* updateCountryRiskV2ConfigBegin(action) {
  yield put(startProcessing(COUNTRY_RISK_V2_ACTIONS.update.data));
  try {
    const json = yield manager.updateConfig(CountryRiskDtoMapper.mapToDto(action.payload));
    yield put(updateCountryRiskV2ConfigSuccess(CountryRiskDtoMapper.mapFromDto(json)));
  } catch (error) {
    yield put(updateCountryRiskV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(COUNTRY_RISK_V2_ACTIONS.update.data));
}

export function* updateCountryRiskV2ConfigWatcher() {
  yield takeLatest(COUNTRY_RISK_V2_ACTIONS.update.data, updateCountryRiskV2ConfigBegin);
}

function* deleteCountryRiskV2ConfigBegin(action) {
  yield put(startProcessing(COUNTRY_RISK_V2_ACTIONS.delete.data));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteCountryRiskV2ConfigSuccess(json));
  } catch (error) {
    yield put(deleteCountryRiskV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(COUNTRY_RISK_V2_ACTIONS.delete.data));
}

export function* deleteCountryRiskV2ConfigWatcher() {
  yield takeLatest(COUNTRY_RISK_V2_ACTIONS.delete.data, deleteCountryRiskV2ConfigBegin);
}
