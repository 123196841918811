import { FormControlLabel, Grid, Switch } from '@mui/material';
import TextSearch from 'components/text-search';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FilterColumns = ({ columnList, handleChangeColumnConfig, localStorageKey }) => {
  const { t } = useTranslation();

  const [filteredColumns, setFilteredColumns] = useState(columnList?.map((column) => column.id));

  const [columnConfig, setColumnConfig] = useState(columnList);

  const handleFilterChange = (newColumns) => {
    const filteredIdColumns = newColumns.map((column) => column.id);

    setFilteredColumns(filteredIdColumns);
  };

  const handleSelectChange = (event, index, singleColumn) => {
    singleColumn.isVisible = !singleColumn.isVisible;

    const tempAllConfig = [...columnConfig];
    tempAllConfig[index] = singleColumn;

    setColumnConfig(tempAllConfig);
    handleChangeColumnConfig(tempAllConfig);

    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(tempAllConfig));
    }
  };

  return (
    <>
      <Grid item xs={12} className="px-2 py-3">
        <TextSearch
          optionsArray={columnList.map((column) => ({
            label: column.label,
            ...column,
          }))}
          onInputChange={(allColumns) => handleFilterChange(allColumns)}
          placeholder={t('column.search.heading')}
          hideClearCross
        />
      </Grid>

      <Grid item xs={12} className="max-h-vh-50 overflow-auto pr-2 pl-3">
        {columnConfig.map(
          (item, index) =>
            filteredColumns.includes(item.id) && (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  label={item.label}
                  disabled={item.isRequired}
                  control={
                    <Switch
                      checked={item.isVisible}
                      onChange={(event) => handleSelectChange(event, index, item)}
                      size="small"
                    />
                  }
                />
              </Grid>
            ),
        )}
      </Grid>
    </>
  );
};

export default FilterColumns;
