import { ErrorActions } from 'middleware/action-types';

export const showError = (error) => ({
  type: ErrorActions.ERRORS_SHOW,
  payload: error,
});

export const retryError = (error) => ({
  type: ErrorActions.ERRORS_RETRY,
  payload: error,
});

export const retryErrorBegan = (error) => ({
  type: ErrorActions.ERRORS_RETRY_BEGAN,
  payload: error,
});

export const hideError = (error) => ({
  type: ErrorActions.ERRORS_HIDE,
  payload: error,
});
