import { useState } from 'react';
import { Tooltip, IconButton, Popover } from '@mui/material';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import FilterColumns from 'components/table/column-management/filter-columns';
import { useTranslation } from 'react-i18next';
/**
 * Showing tabs for popover
 * @param {*} props
 */
const ColumnManagement = ({ columnList = [], handleChangeColumnConfig, localStorageKey }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={t('column.settings.heading')}>
        <IconButton onClick={handleClick}>
          <ViewWeekOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FilterColumns
          columnList={columnList}
          handleChangeColumnConfig={handleChangeColumnConfig}
          localStorageKey={localStorageKey}
        />
      </Popover>
    </>
  );
};

export default ColumnManagement;
