import { put, takeLatest } from 'redux-saga/effects';
import { ACCOUNT_BASED_FREQUENCY_ACTIONS } from 'modules/configuration/rule-manager/account-based-frequency-increase-decrease/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteAccountBasedFrequencyIncreaseDecreaseConfigError,
  deleteAccountBasedFrequencyIncreaseDecreaseConfigSuccess,
  getAccountBasedFrequencyIncreaseDecreaseConfigError,
  getAccountBasedFrequencyIncreaseDecreaseConfigSuccess,
  updateAccountBasedFrequencyIncreaseDecreaseConfigError,
  updateAccountBasedFrequencyIncreaseDecreaseConfigSuccess,
} from 'modules/configuration/rule-manager/account-based-frequency-increase-decrease/middleware/actions/account-based-frequency-increase-decrease-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';

const communicator = new RuleConfigAPICommunicator('account-based-frequency-increase-decrease');
const manager = new RuleConfigManager(communicator);

function* getAccountBasedFrequencyIncreaseDecreaseConfigBegin(action) {
  yield put(startProcessing(ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.fetch.data));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getAccountBasedFrequencyIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(getAccountBasedFrequencyIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.fetch.data));
}

export function* getAccountBasedFrequencyIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.fetch.data,
    getAccountBasedFrequencyIncreaseDecreaseConfigBegin,
  );
}

function* updateAccountBasedFrequencyIncreaseDecreaseConfigBegin(action) {
  yield put(startProcessing(ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.update.data));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateAccountBasedFrequencyIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(updateAccountBasedFrequencyIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.update.data));
}

export function* updateAccountBasedFrequencyIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.update.data,
    updateAccountBasedFrequencyIncreaseDecreaseConfigBegin,
  );
}

function* deleteAccountBasedFrequencyIncreaseDecreaseConfigBegin(action) {
  yield put(startProcessing(ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.delete.data));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteAccountBasedFrequencyIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(deleteAccountBasedFrequencyIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.delete.data));
}

export function* deleteAccountBasedFrequencyIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.delete.data,
    deleteAccountBasedFrequencyIncreaseDecreaseConfigBegin,
  );
}
