import { put, takeLatest } from 'redux-saga/effects';
import { SuspiciousAvgTicketSizeConfigActions } from 'modules/configuration/rule-manager/suspicious-avg-ticket-size/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteSuspiciousAvgTicketSizeConfigError,
  deleteSuspiciousAvgTicketSizeConfigSuccess,
  getSuspiciousAvgTicketSizeConfigError,
  getSuspiciousAvgTicketSizeConfigSuccess,
  updateSuspiciousAvgTicketSizeConfigError,
  updateSuspiciousAvgTicketSizeConfigSuccess,
} from 'modules/configuration/rule-manager/suspicious-avg-ticket-size/middleware/actions/suspicious-avg-ticket-size-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';

const communicator = new RuleConfigAPICommunicator('suspicious-avg-ticket-size');
const manager = new RuleConfigManager(communicator);

function* getSuspiciousAvgTicketSizeConfigBegin(action) {
  yield put(
    startProcessing(SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET),
  );
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getSuspiciousAvgTicketSizeConfigSuccess(json));
  } catch (error) {
    yield put(getSuspiciousAvgTicketSizeConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(
    stopProcessing(SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET),
  );
}

export function* getSuspiciousAvgTicketSizeConfigWatcher() {
  yield takeLatest(
    SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET,
    getSuspiciousAvgTicketSizeConfigBegin,
  );
}

function* updateSuspiciousAvgTicketSizeConfigBegin(action) {
  yield put(
    startProcessing(SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE),
  );
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateSuspiciousAvgTicketSizeConfigSuccess(json));
  } catch (error) {
    yield put(updateSuspiciousAvgTicketSizeConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(
    stopProcessing(SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE),
  );
}

export function* updateSuspiciousAvgTicketSizeConfigWatcher() {
  yield takeLatest(
    SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE,
    updateSuspiciousAvgTicketSizeConfigBegin,
  );
}

function* deleteSuspiciousAvgTicketSizeConfigBegin(action) {
  yield put(
    startProcessing(SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE),
  );
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteSuspiciousAvgTicketSizeConfigSuccess(json));
  } catch (error) {
    yield put(deleteSuspiciousAvgTicketSizeConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(
    stopProcessing(SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE),
  );
}

export function* deleteSuspiciousAvgTicketSizeConfigWatcher() {
  yield takeLatest(
    SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE,
    deleteSuspiciousAvgTicketSizeConfigBegin,
  );
}
