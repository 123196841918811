import { AssessmentScoreDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/assessment-score-details';
import { CountingScoreDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/counting-score-details';
import { CountryRiskDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/country-risk-details';
import { FreeTextRiskDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/free-text-risk-details';
import {
  CRR_GENERIC_TEMPLATE_VALUES,
  CRR_GENERIC_GROUP_TO_TEMPLATE_MAP,
} from 'utils/constants/crr.constants';
import { PercentDeviationScoreDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/percent-deviation-score-details';
import { TxnVolumeAndCountDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/txn-volume-and-count-details';
import { IncomeMonetaryValueRangeDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/income-monetary-value-range-details';
import { hasMonetaryValueRangeRiskFactor } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/generic-details-util';
import { DurationCustomerAgeDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/duration-customer-age-details';
import ListUploadDetails from 'components/popover/person-details/person-details-tab/risk-rating/score-details/list-upload-details';
import { EntityResolutionRiskTemplates } from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/entity-resolution-risk-details';
import MultipleAttributeDetails from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/multiple-attribute-details';
import SharedAttributeDetails from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/shared-attribute-details';

const GenericDetails = ({
  scoreData = {},
  maxPoints,
  configKey,
  popoverMode,
  headlineKey,
  isIndividual,
  caseConfig,
}) => {
  const key = scoreData.RISK_FACTOR_INSTANCE?.title || '';

  if (scoreData.RISK_FACTOR_INSTANCE?.template === CRR_GENERIC_GROUP_TO_TEMPLATE_MAP.ASSESSMENT) {
    return (
      <AssessmentScoreDetails
        scoreData={scoreData}
        maxPoints={maxPoints}
        configKey={configKey}
        popoverMode={popoverMode}
        isIndividual={isIndividual}
        headlineKey={headlineKey}
        headlineKeyWithoutTranslation={key}
      />
    );
  }

  if (scoreData.RISK_FACTOR_INSTANCE?.template === CRR_GENERIC_TEMPLATE_VALUES.TIME_RANGE) {
    return (
      <DurationCustomerAgeDetails
        maxPoints={maxPoints}
        scoreData={scoreData}
        popoverMode={popoverMode}
        title={key}
      />
    );
  }

  if (
    [CRR_GENERIC_TEMPLATE_VALUES.RANGE, CRR_GENERIC_TEMPLATE_VALUES.RISK_PATTERN].includes(
      scoreData.RISK_FACTOR_INSTANCE?.template || '',
    )
  ) {
    if (hasMonetaryValueRangeRiskFactor(scoreData)) {
      return (
        <IncomeMonetaryValueRangeDetails
          maxPoints={maxPoints}
          scoreData={scoreData}
          popoverMode={popoverMode}
          title={key}
        />
      );
    }

    return (
      <CountingScoreDetails
        maxPoints={maxPoints}
        scoreData={scoreData}
        popoverMode={popoverMode}
        title={key}
      />
    );
  }

  if (
    CRR_GENERIC_GROUP_TO_TEMPLATE_MAP.DEVIATION.includes(scoreData.RISK_FACTOR_INSTANCE?.template)
  ) {
    return (
      <PercentDeviationScoreDetails
        maxPoints={maxPoints}
        scoreData={scoreData}
        popoverMode={popoverMode}
        title={key}
      />
    );
  }

  if (
    scoreData.RISK_FACTOR_INSTANCE?.template ===
    CRR_GENERIC_GROUP_TO_TEMPLATE_MAP.COUNTRY_RISK_LEVEL
  ) {
    return (
      <CountryRiskDetails
        maxPoints={maxPoints}
        scoreData={scoreData}
        popoverMode={popoverMode}
        title={key}
        caseConfig={caseConfig}
      />
    );
  }

  if (scoreData.RISK_FACTOR_INSTANCE?.template === CRR_GENERIC_GROUP_TO_TEMPLATE_MAP.FREE_TEXT) {
    return (
      <FreeTextRiskDetails
        maxPoints={maxPoints}
        scoreData={scoreData}
        popoverMode={popoverMode}
        title={key}
        caseConfig={caseConfig}
      />
    );
  }

  if (
    scoreData.RISK_FACTOR_INSTANCE?.template ===
    CRR_GENERIC_GROUP_TO_TEMPLATE_MAP.TXN_VOLUME_AND_COUNT
  ) {
    return (
      <TxnVolumeAndCountDetails
        maxPoints={maxPoints}
        scoreData={scoreData}
        popoverMode={popoverMode}
        title={key}
        isIndividual={isIndividual}
      />
    );
  }

  if (
    scoreData.RISK_FACTOR_INSTANCE?.template === CRR_GENERIC_GROUP_TO_TEMPLATE_MAP.LIST_RISK_LEVEL
  ) {
    return (
      <ListUploadDetails
        maxPoints={maxPoints}
        scoreData={scoreData}
        popoverMode={popoverMode}
        title={key}
      />
    );
  }
  if (scoreData?.TEMPLATE === EntityResolutionRiskTemplates.multipleAttribute) {
    return <MultipleAttributeDetails scoreData={scoreData} popoverMode={popoverMode} />;
  }
  if (scoreData?.TEMPLATE === EntityResolutionRiskTemplates.sharedAttribute) {
    return <SharedAttributeDetails scoreData={scoreData} popoverMode={popoverMode} />;
  }

  return null;
};

export { GenericDetails };
