import { connect } from 'react-redux';
import MDSpinner from 'react-md-spinner';

export const Loader = (props) => (
  <div className="overlay-loader d-flex justify-content-center align-items-center">
    <MDSpinner />
  </div>
);

const mapStateToProps = (state) => ({
  processing: state.processing || {},
});

export default connect(mapStateToProps)(Loader);
