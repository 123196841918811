import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

const Checkbox = ({
  name,
  label,
  value,
  onChange,
  id,
  fullWidth,
  size,
  disabled = false,
  ...other
}) => {
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <FormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            id={id}
            color="primary"
            checked={value}
            size={size}
            disabled={disabled}
            onChange={(e) => onChange(convertToDefEventPara(name, e.target.checked))}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
          />
        }
        label={label}
      />
      {other.error && <FormHelperText error={true}>{other.error}</FormHelperText>}
    </FormControl>
  );
};

export default Checkbox;
