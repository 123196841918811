import { RISKY_WORDS_V2_ACTIONS } from 'modules/configuration/rule-manager/risky-words-v2/middleware/action-types';

const RiskyWordsV2ConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case RISKY_WORDS_V2_ACTIONS.fetch.success:
    case RISKY_WORDS_V2_ACTIONS.update.success:
    case RISKY_WORDS_V2_ACTIONS.delete.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default RiskyWordsV2ConfigReducer;
