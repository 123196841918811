import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';

const NoSubtenantSupportAlert = () => {
  const { t } = useTranslation();

  return <Alert severity="info">{t('case:caseList.header.noSubtenantSupportHint.heading')}</Alert>;
};

export default NoSubtenantSupportAlert;
