import { Box, Grid, Chip, Divider } from '@mui/material';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import Controls from 'components/controls/controls';
import { useForm, Form } from 'hooks/use-form';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'middleware/store';

interface ScreeningListTypeProps {
  editForm: any;
  onSubmit: CallableFunction;
}

const ScreeningListTypeForm: FC<ScreeningListTypeProps> = ({ editForm, onSubmit }) => {
  const initialFValues = {
    listType: editForm?.value || '',
  };

  // this is a placeholder, we have no validation rule for now
  const validate = () => true;

  const { values, handleInputChange } = useForm(initialFValues, true, validate);
  const listTypeCategories = useSelector(
    (state: RootState) => state.listManager?.dataProviderList?.listTypeCategories || [],
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        field: i18next.t('mapping:filters.filtering.screeningListType.key'),
        value: values.listType,
      });
    }
  };
  return (
    <Grid container>
      <Form className="w-100" onSubmit={handleSubmit}>
        <Grid container className="px-3 py-3">
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.Select2
                name="listType"
                label={i18next.t('mapping:operator.text.TEXT_EQUALS.value.heading')}
                value={values.listType}
                onChange={handleInputChange}
                options={listTypeCategories}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            type="submit"
            color="primary"
            text={i18next.t('filter.heading')}
            size="medium"
            variant="text"
            onClick={() => {
              console.log('clicked');
            }}
            disableElevation
            disabled={false}
          />
        </Grid>
      </Form>
    </Grid>
  );
};

interface ScreeningListTypeChipProps {
  form: any;
}

const ScreeningListTypeChip: FC<ScreeningListTypeChipProps> = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.screeningListType.value.heading')}
        </Box>
        <Chip
          key={form.field}
          variant="outlined"
          size="small"
          className="mr-1"
          label={form.value}
        />
      </>
    )}
  </Translation>
);

export { ScreeningListTypeForm, ScreeningListTypeChip };
