import { useState } from 'react';
import { Box, Chip, Divider, FormHelperText, Grid } from '@mui/material';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import _ from 'lodash';
import { useAuthorization } from 'hooks/use-authorization';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import { TypeCell } from 'components/table/table-cell/case-table-cell';
import { isFeatureAttached } from 'utils/helpers/feature-visibility';

const buildCaseTypeValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
  field: i18next.t('mapping:filters.filtering.types.key'),
  values: filter?.values,
});

const ruleTypesExcludedFromFilter = [
  'total-cases',
  'REG_314A',
  'COUNTRY_RISK_HIGH',
  'COUNTRY_RISK_MEDIUM',
  'PHISHING',
];

const isAtLeastOneFeatureAttached = (ruleDetachmentKey, detachmentConfig) =>
  detachmentConfig &&
  ruleDetachmentKey.split(';').find((rule) => isFeatureAttached(detachmentConfig[rule])) !==
    undefined;

const includeThisRuleInFilter = (rule, detachmentConfig) => {
  const excluded = ruleTypesExcludedFromFilter.includes(rule.key);
  const attached =
    rule.detachmentKey === undefined ||
    isAtLeastOneFeatureAttached(rule.detachmentKey, detachmentConfig) ||
    isAtLeastOneFeatureAttached(rule.detachmentKey, detachmentConfig.genericRuleDetachmentMap);

  return !excluded && attached;
};

const CaseTypeForm = ({ editForm, onSubmit }) => {
  const { detachmentConfig } = useAuthorization();

  const patterns = _.sortBy(
    _.filter(Object.values(i18next.t('mapping:patternType')), (item) =>
      includeThisRuleInFilter(item, detachmentConfig),
    ),
    (item) => item.value.heading,
  );
  const initialFValues = {};

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`caseTypes[${item}]`] = true;
    });
  }

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);

    const newTypes = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];

    setCheckedValues(newTypes);
    // eslint-disable-next-line no-use-before-define
    validate(newTypes);
  };

  /**
   * validating field form
   * @param newCheckedTypes
   * @returns
   */
  const validate = (newCheckedTypes = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };

    temp.caseTypes = newCheckedTypes.length ? '' : i18next.t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
        field: i18next.t('mapping:filters.filtering.types.key'),
        values: checkedValues,
      });
    }
  };

  return (
    <Translation>
      {(t) => (
        <Grid container item>
          <Form onSubmit={handleSubmit}>
            <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
              {patterns.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Controls.Checkbox
                    name={`caseTypes[${item.key}]`}
                    label={
                      <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                        <Grid item>
                          <TypeCell singleTypeKey={item.key} />
                        </Grid>
                        <Grid item>{item.value.heading}</Grid>
                      </Grid>
                    }
                    value={values[`caseTypes[${item.key}]`] ?? false}
                    onChange={(event) => handleSelectChange(event, item.key)}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            {errors.caseTypes && (
              <FormHelperText className="pl-3" error>
                {errors.caseTypes}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const CaseTypeChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.types.value.heading')}
        </Box>
        {form.values.map((item, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            className="mr-1"
            label={t(`mapping:patternType.${item}.value.heading`)}
            icon={<TypeCell singleTypeKey={item} customClass="ml-1 icon-14" />}
          />
        ))}
      </>
    )}
  </Translation>
);

export { CaseTypeForm, CaseTypeChip, buildCaseTypeValuesFromUrl };
