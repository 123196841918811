import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { WebLink as WebLinkModel } from 'modules/trx-cases/case-detail/components/weblinks/model/weblink';
import { WebLink } from 'modules/trx-cases/case-detail/components/weblinks/weblink';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'middleware/store';

type WebLinkListProps = {
  webLinks: WebLinkModel[];
  emptyView?: React.ReactNode;
};

export const WebLinkList: FC<WebLinkListProps> = ({ webLinks = [], emptyView }) => {
  const { t } = useTranslation();
  const caseDetailDataShown = useSelector((state: RootState) => state.masks?.caseDetailDataShown);

  return (
    <>
      {(emptyView !== undefined || webLinks.length > 0) && (
        <Grid item xs={12} className="mb-2">
          <Typography variant="body2" color="textSecondary">
            {t('case:caseDetail.tabContainer.transaction.webLinks.heading')}
          </Typography>
          <Box>
            {webLinks?.length
              ? webLinks.map((link, index) => (
                  <WebLink weblink={link} key={index} hidden={!caseDetailDataShown} />
                ))
              : emptyView}
          </Box>
        </Grid>
      )}
    </>
  );
};
