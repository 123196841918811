import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteDormantAccountConfigError,
  deleteDormantAccountConfigSuccess,
  getDormantAccountConfigError,
  getDormantAccountConfigSuccess,
  updateDormantAccountConfigError,
  updateDormantAccountConfigSuccess,
} from 'modules/configuration/rule-manager/dormant-account/middleware/actions/dormant-account-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';
import { DormantAccountConfigActions } from 'modules/configuration/rule-manager/dormant-account/middleware/action-types';

const communicator = new RuleConfigAPICommunicator('dormant-account');
const manager = new RuleConfigManager(communicator);

function* getDormantAccountConfigBegin(action) {
  yield put(startProcessing(DormantAccountConfigActions.GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getDormantAccountConfigSuccess(json));
  } catch (error) {
    yield put(getDormantAccountConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(DormantAccountConfigActions.GET));
}

export function* getDormantAccountConfigWatcher() {
  yield takeLatest(DormantAccountConfigActions.GET, getDormantAccountConfigBegin);
}

function* updateDormantAccountConfigBegin(action) {
  yield put(startProcessing(DormantAccountConfigActions.UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateDormantAccountConfigSuccess(json));
  } catch (error) {
    yield put(updateDormantAccountConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(DormantAccountConfigActions.UPDATE));
}

export function* updateDormantAccountConfigWatcher() {
  yield takeLatest(DormantAccountConfigActions.UPDATE, updateDormantAccountConfigBegin);
}

function* deleteDormantAccountConfigBegin(action) {
  yield put(startProcessing(DormantAccountConfigActions.DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteDormantAccountConfigSuccess(json));
  } catch (error) {
    yield put(deleteDormantAccountConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(DormantAccountConfigActions.DELETE));
}

export function* deleteDormantAccountConfigWatcher() {
  yield takeLatest(DormantAccountConfigActions.DELETE, deleteDormantAccountConfigBegin);
}
