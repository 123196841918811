import { put, takeLatest } from 'redux-saga/effects';
import { VolumeRiskConfigActions } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getVolumeRiskConfigError,
  getVolumeRiskConfigSuccess,
} from 'middleware/actions/volume-risk-config';
import VolumeRiskConfigAPICommunicator from 'services/volume-risk-config-api-communicator';
import VolumeRiskConfigManager from 'services/volume-risk-config-manager';

const volumeRiskConfigAPICommunicator = new VolumeRiskConfigAPICommunicator();
const volumeRiskConfigManager = new VolumeRiskConfigManager(volumeRiskConfigAPICommunicator);

function* getVolumeRiskConfigBegin(action) {
  yield put(startProcessing(VolumeRiskConfigActions.VOLUME_RISK_CONFIG_GET));
  try {
    const json = yield volumeRiskConfigManager.getVolumeRiskConfig();
    yield put(getVolumeRiskConfigSuccess(json));
  } catch (error) {
    yield put(getVolumeRiskConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(VolumeRiskConfigActions.VOLUME_RISK_CONFIG_GET));
}

export function* getVolumeRiskConfigWatcher() {
  yield takeLatest(VolumeRiskConfigActions.VOLUME_RISK_CONFIG_GET, getVolumeRiskConfigBegin);
}
