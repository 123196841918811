import { SmurfingConfigActions } from 'modules/configuration/rule-manager/smurfing/middleware/action-types';

const smurfingConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case SmurfingConfigActions.SMURFING_V2_CONFIG_GET_SUCCESS:
    case SmurfingConfigActions.SMURFING_V2_CONFIG_UPDATE_SUCCESS:
    case SmurfingConfigActions.SMURFING_V2_CONFIG_DELETE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default smurfingConfigReducer;
