import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UnmaskedIdentityDocument } from 'components/popover/person-details/person-details-tab/documents/identity-document';

export const DocumentDataHeader = ({ document }: { document: UnmaskedIdentityDocument }) => (
  <Grid container>
    <Grid item>
      <Typography variant="body2" component="div">
        {document.kind ?? '-'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {document.id ?? '-'}
      </Typography>
    </Grid>
  </Grid>
);
