import i18next from 'i18next';
import { addHours, subDays } from 'date-fns';
import { startOfMinuteAsString } from 'utils/helpers/date-time-util';
import { CaseStatuses } from 'models/case/case-state/case-status';

const MAX_TIMEFRAME_IN_DAYS = 28;

export const isTimeframeValid = (fromDate: Date, toDate: Date) => {
  const diffInTime = new Date(toDate).getTime() - new Date(fromDate).getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);
  return diffInDays >= 0 && diffInDays <= MAX_TIMEFRAME_IN_DAYS;
};

export const isSamplingRateValid = (samplingRate: number) =>
  samplingRate > 0 && samplingRate <= 100;

export const getInitialFromDate = () => {
  let fromDate = new Date();
  fromDate = subDays(fromDate, MAX_TIMEFRAME_IN_DAYS);
  // buffer for winter time change
  fromDate = addHours(fromDate, 1);
  return fromDate;
};

export const getInitialToDate = () => new Date();

export const getInitialRequest = () => ({
  fromDate: startOfMinuteAsString(getInitialFromDate()),
  toDate: startOfMinuteAsString(getInitialToDate()),
  sourceTenantId: '',
  caseStatuses: [CaseStatuses.OPEN],
  samplingRate: 100,
});

export const toHHMMSS = (durationInSecond: number) => {
  const hours = Math.floor(durationInSecond / 3600);
  const minutes = Math.floor((durationInSecond - hours * 3600) / 60);
  const seconds = durationInSecond - hours * 3600 - minutes * 60;
  return { hours, minutes, seconds };
};

export const toHumanReadableHHMMSS = (durationInSecond: number) => {
  const { hours, minutes, seconds } = toHHMMSS(durationInSecond);

  const res = [];

  if (hours > 1) {
    res.push(`${hours} ${i18next.t('timeUnit.hours.heading')}`);
  } else if (hours === 1) {
    res.push(`1 ${i18next.t('timeUnit.hour.heading')}`);
  }

  if (minutes > 1) {
    res.push(`${minutes} ${i18next.t('timeUnit.minutes.heading')}`);
  } else if (minutes === 1) {
    res.push(`1 ${i18next.t('timeUnit.minute.heading')}`);
  }

  if (seconds > 1) {
    res.push(`${seconds} ${i18next.t('timeUnit.seconds.heading')}`);
  } else if (seconds === 1) {
    res.push(`1 ${i18next.t('timeUnit.second.heading')}`);
  }

  return res.join(' ');
};
