import { PRODUCT_TYPE_LIST_ACTIONS } from 'middleware/action-types';

const productTypeListReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_TYPE_LIST_ACTIONS.fileList.individualError:
    case PRODUCT_TYPE_LIST_ACTIONS.fileList.organizationError:
    case PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchIndividual:
    case PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchOrganization: {
      return state;
    }

    case PRODUCT_TYPE_LIST_ACTIONS.fileList.individualSuccess: {
      return {
        ...state,
        individual: { ...action.payload },
        timestamp: new Date().getTime(),
      };
    }

    case PRODUCT_TYPE_LIST_ACTIONS.fileList.organizationSuccess: {
      return {
        ...state,
        organization: { ...action.payload },
        timestamp: new Date().getTime(),
      };
    }

    case PRODUCT_TYPE_LIST_ACTIONS.activateVersion.individualError:
    case PRODUCT_TYPE_LIST_ACTIONS.activateVersion.organizationError: {
      return state;
    }

    default:
      return state;
  }
};

export default productTypeListReducer;
