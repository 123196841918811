const AssessmentAnswer = {
  YES: 'yes',
  NO: 'no',
  OK: 'ok',
  NOK: 'nok',
  INCONCLUSIVE: 'inconclusive',
  VOLUME_RISK_CUSTOMER_TRANSACTION_ANSWER_POSITIVE:
    'volume-risk-customer-transaction-answer-positive',
  VOLUME_RISK_CUSTOMER_TRANSACTION_ANSWER_NEGATIVE:
    'volume-risk-customer-transaction-answer-negative',
  VOLUME_RISK_CUSTOMER_TRANSACTION_ANSWER_UNCERTAIN:
    'volume-risk-customer-transaction-answer-uncertain',
  MATCH: 'match',
  NO_MATCH: 'no-match',
} as const;

export default AssessmentAnswer;
