import { USER_MANAGEMENT_ACTIONS } from 'modules/configuration/workflows-and-users/components/user-management/middleware/action-types';
import { UserManagementConfig } from 'models/configuration/user-management-config';

export const saveTenantUserManagementConfig = (payload: UserManagementConfig) => ({
  type: USER_MANAGEMENT_ACTIONS.userManagementConfig.data,
  payload,
});

export const saveTenantUserManagementConfigSuccess = (payload: UserManagementConfig) => ({
  type: USER_MANAGEMENT_ACTIONS.userManagementConfig.success,
  payload,
});

export const saveTenantUserManagementConfigError = () => ({
  type: USER_MANAGEMENT_ACTIONS.userManagementConfig.error,
});
