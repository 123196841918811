import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class RiskyWordsV2ListApiCommunicator extends APICommunicator {
  fetchRiskyWordsV2FilesList(tenantId) {
    const apiUrl = API_URLS.config.riskyWordsV2.getMetadata.replaceAll(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.RISKY_WORDS_LIST_DATA_NOT_FOUND, tenantId);
  }

  updateActiveRiskyWordsV2ListVersion(tenantId, version) {
    const apiUrl = API_URLS.config.riskyWordsV2.updateActiveVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default RiskyWordsV2ListApiCommunicator;
