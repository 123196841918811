import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type JSONValue = string | number | boolean | { [key: string]: JSONValue } | Array<JSONValue>;

interface BehavioralAnalysisConfigState {
  instances: Array<JSONValue>;
  updatedAt: string;
  userId: string;
}

const initialState: BehavioralAnalysisConfigState = {
  instances: [],
  updatedAt: '',
  userId: '',
};

export const behavioralAnalysisConfigReadingSlice = createSlice({
  name: 'behavioralAnalysis',
  initialState,
  reducers: {
    getBehavioralAnalysisConfig: (state) => state,
    getBehavioralAnalysisConfigSuccess: (
      state,
      action: PayloadAction<BehavioralAnalysisConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    saveBehavioralAnalysisConfig: (state) => state,
    saveBehavioralAnalysisConfigSuccess: (
      state,
      action: PayloadAction<BehavioralAnalysisConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    deleteBehavioralAnalysisConfig: (state) => state,
    deleteBehavioralAnalysisConfigSuccess: (
      state,
      action: PayloadAction<BehavioralAnalysisConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
  },
});

export const {
  getBehavioralAnalysisConfig,
  getBehavioralAnalysisConfigSuccess,
  saveBehavioralAnalysisConfig,
  saveBehavioralAnalysisConfigSuccess,
  deleteBehavioralAnalysisConfig,
} = behavioralAnalysisConfigReadingSlice.actions;

export default behavioralAnalysisConfigReadingSlice.reducer;
