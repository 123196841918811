import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideError } from 'middleware/actions/error';

import { Alert } from '@mui/material';
import ErrorActions from 'components/notifications/errors/error-actions';
import { ErrorDetails, ErrorSummary } from 'components/notifications/errors/error-details';

const SingleError = ({ error }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const dispatch = useDispatch();

  const handleToggleDetailsVisible = () => {
    setDetailsVisible(!detailsVisible);
  };

  return (
    <Alert
      className="mb-2"
      severity={error.custom.alertType}
      variant="filled"
      onClose={() => dispatch(hideError(error))}
      action={
        <ErrorActions
          error={error}
          detailsVisible={detailsVisible}
          toggleDetails={handleToggleDetailsVisible}
        />
      }
    >
      {!detailsVisible && <ErrorSummary text={error.message} />}
      {detailsVisible && (
        <ErrorDetails
          caseUrl={error.details.caseUrl}
          apiUrl={error.details.apiUrl}
          sessionId={error.details.sessionId}
        />
      )}
    </Alert>
  );
};

export default SingleError;
