import { SETTINGS_ACTIONS } from 'middleware/action-types';

const settingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_ACTIONS.get.data:
    case SETTINGS_ACTIONS.get.error:
    case SETTINGS_ACTIONS.update.data:
    case SETTINGS_ACTIONS.update.error: {
      return state;
    }

    case SETTINGS_ACTIONS.get.success:
    case SETTINGS_ACTIONS.update.success:
    case SETTINGS_ACTIONS.countryRisks.update.success:
    case SETTINGS_ACTIONS.countryRisks.delete.success: {
      const { tenantId, configVersionKey, json } = action?.payload ?? {};
      return {
        ...state,
        [tenantId]: {
          ...state[tenantId],
          [configVersionKey]: json,
        },
      };
    }

    default:
      return state;
  }
};

export default settingsReducer;
