import { FlaggingConfigActions } from 'modules/configuration/rule-manager/flagging/middleware/action-types';

export const getFlaggingConfig = () => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_GET,
});

export const getFlaggingConfigSuccess = (payload) => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_GET_SUCCESS,
  payload,
});

export const getFlaggingConfigError = () => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_GET_ERROR,
});

export const updateFlaggingConfig = (payload) => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_UPDATE,
  payload,
});

export const updateFlaggingConfigSuccess = (payload) => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateFlaggingConfigError = () => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_UPDATE_ERROR,
});

export const deleteFlaggingConfig = (payload) => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_DELETE,
  payload,
});

export const deleteFlaggingConfigSuccess = (payload) => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_DELETE_SUCCESS,
  payload,
});

export const deleteFlaggingConfigError = () => ({
  type: FlaggingConfigActions.FLAGGING_CONFIG_DELETE_ERROR,
});
