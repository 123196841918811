import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Divider, Grid } from '@mui/material';
import i18next from 'i18next';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import {
  Filter,
  FilterFormProps,
  FilterChipProps,
  UseFormReturnType,
} from 'components/filters/models/filter';

const ExecutionScopesChip: FC<FilterChipProps> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.executionScopes.value.heading')}
      </Box>
      {(form as Filter).values.map((item, index) => (
        <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
      ))}
    </>
  );
};

const buildExecutionScopesValuesFromUrl = (filter: Filter) => ({
  field: i18next.t('mapping:filters.filtering.executionScopes.key'),
  condition: i18next.t(`mapping:operator.text.${filter.condition}.key`),
  values: filter.values,
});

const ExecutionScopesForm: FC<FilterFormProps> = ({ editForm, onSubmit }) => {
  const { t } = useTranslation();
  const initialFValues = {
    executionScopes: editForm?.values || [],
  };

  const validate = (fieldValues: { executionScopes: string[] }) => {
    // eslint-disable-next-line no-use-before-define
    const newErrors: Record<string, string | null> = { ...errors };

    if (Object.keys(fieldValues).includes('executionScopes')) {
      newErrors.executionScopes = fieldValues.executionScopes?.length
        ? ''
        : i18next.t('required.heading');
    }

    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...newErrors,
    });

    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) {
      return Object.values(newErrors).every((error) => error === '');
    }
    return false;
  };

  const { values, errors, setErrors, handleInputChange }: UseFormReturnType = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();

    if (validate(values)) {
      onSubmit({
        condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
        field: i18next.t('mapping:filters.filtering.executionScopes.key'),
        values: values.executionScopes,
      });
    }
  };
  return (
    <Grid container>
      <Form className="w-100">
        <Grid container item className="px-3 py-2" spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                dataTestIdPrefix="filter-by-execution-scopes"
                name="executionScopes"
                label={t('condition.equal.heading')}
                value={values.executionScopes}
                onChange={handleInputChange}
                error={errors.executionScopes}
                shrink={undefined}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            dataTestIdPrefix="filter-by-execution-scopes"
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};

export { ExecutionScopesChip, buildExecutionScopesValuesFromUrl, ExecutionScopesForm };
