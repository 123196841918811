import { Table } from 'reactstrap';
import { Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const colorBlackStyle = {
  color: 'black',
};

const TooltipTable = ({ header, rows }) => (
  <Grid container spacing={1} className="pt-1">
    <Grid container item alignContent="center" wrap="nowrap" spacing={1}>
      <Grid item>
        <InfoOutlinedIcon fontSize="small" color="info" />
      </Grid>
      <Grid item>
        <Typography variant="body2" component="span">
          {header}
        </Typography>
      </Grid>
    </Grid>

    <Grid item>
      <Table bordered striped>
        <tbody>
          {rows.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {rowData.map((colData, colIndex) => {
                if (colIndex === 0) {
                  return (
                    <td style={colorBlackStyle} key={colIndex}>
                      {colData}
                    </td>
                  );
                }
                return <th key={colIndex}>{colData}</th>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </Grid>
  </Grid>
);

export default TooltipTable;
