import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class TenantConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Tenant Config API Communicator instance is required.');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  validateConfigJson(json) {
    if (json) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  fetchTenantConfig() {
    return this.communicator.fetchTenantConfig().then((json) => this.validateConfigJson(json));
  }

  fetchTenantConfigWithTenant(tenantId) {
    return this.communicator
      .fetchTenantConfigWithTenant(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  saveTenantConfig(params) {
    return this.communicator.saveTenantConfig(params).then((json) => this.validateConfigJson(json));
  }

  addTenant(params) {
    return this.communicator.addTenant(params).then((json) => this.validateConfigJson(json));
  }

  getAllTenants() {
    return this.communicator.getAllTenants().then((json) => this.validateConfigJson(json));
  }

  addMasterTenant(params) {
    return this.communicator.addMasterTenant(params).then((json) => this.validateConfigJson(json));
  }

  getMasterTenantCreationStatus(requestId) {
    return this.communicator
      .getMasterTenantCreationStatus(requestId)
      .then((json) => this.validateConfigJson(json));
  }

  finalizeMasterTenantCreation(requestId) {
    return this.communicator
      .finalizeMasterTenantCreation(requestId)
      .then((json) => this.validateConfigJson(json));
  }

  addTenantToUsers(params) {
    return this.communicator.addTenantToUsers(params).then((json) => this.validateConfigJson(json));
  }

  getUsers(tenantId) {
    return this.communicator.getUsers(tenantId).then((json) => this.validateConfigJson(json));
  }

  getUsersNotInTenant(tenantId, otherTenantIds) {
    return this.communicator
      .getUsersNotInTenant(tenantId, otherTenantIds)
      .then((json) => this.validateConfigJson(json));
  }

  updateUserInfo(params) {
    return this.communicator.updateUserInfo(params).then((json) => this.validateConfigJson(json));
  }

  getMasterTenants() {
    return this.communicator.getMasterTenants().then((json) => this.validateConfigJson(json));
  }

  updateTenantStructure(params) {
    return this.communicator
      .updateTenantStructure(params)
      .then((json) => this.validateConfigJson(json));
  }

  /**
   * Call communicator to update callback config and validate response
   */
  putTenantCallbackConfig(params) {
    return this.communicator
      .putTenantCallbackConfig(params)
      .then((json) => this.validateConfigJson(json));
  }

  getTenantCallbackConfigSecret() {
    return this.communicator
      .getTenantCallbackConfigSecret()
      .then((json) => this.validateConfigJson(json));
  }

  putTenantScorechainConfig(params) {
    return this.communicator
      .putTenantScorechainConfig(params)
      .then((json) => this.validateConfigJson(json));
  }

  getTenantScorechainConfigSecret() {
    return this.communicator
      .getTenantScorechainConfigSecret()
      .then((json) => this.validateConfigJson(json));
  }
}

export default TenantConfigManager;
