import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import TenantManagementService from 'services/tenant/tenant-management-service';
import ErrorCode from 'utils/constants/error-code';

class RiskLevelMapApi extends APICommunicator {
  fetchMetadata(params) {
    const apiUrl = API_URLS.crrRiskFactors.riskLevelMaps.getMetadata
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.riskFactorId, params.riskFactorInstanceId);

    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default RiskLevelMapApi;
