import React, { FC, MouseEvent, useState } from 'react';
import { Chip } from '@mui/material';
import { unmask } from 'middleware/actions/mask';
import { OverridableStringUnion } from '@mui/types';
import { useDispatch, useSelector } from 'react-redux';

interface MaskedChipProps {
  token: string;
  maskedValue: string;
  uuid: string;
  size: OverridableStringUnion<'small' | 'medium'>;
  isCustomerCase: boolean;
}

const MaskedChip: FC<MaskedChipProps> = ({
  token,
  maskedValue = '***',
  uuid,
  size = 'medium',
  isCustomerCase = false,
}) => {
  // eslint-disable-next-line
  const maskedItems = useSelector((state: any) => state.masks?.all || []);
  // eslint-disable-next-line
  const userId = useSelector((state: any) => state.authentication.user?.displayName);
  const [individualClicked, setIndividualClicked] = useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const getData = () => maskedItems?.find((item: any) => item.token === token) || {};
  // eslint-disable-next-line
  const shouldUnmask = (data: any) =>
    !!individualClicked && data.unmasked && data.unmasked.length > 0;

  const handleUnmask = (event: MouseEvent) => {
    event.stopPropagation();

    setIndividualClicked(true);

    dispatch(
      unmask({
        masterUuid: uuid,
        displayUuid: uuid,
        uuids: [uuid],
        userId,
        isCustomerCase,
      }),
    );
  };

  const data = getData();

  if (!token) {
    return null;
  }
  if (shouldUnmask(data)) {
    return <Chip size={size} label={data?.unmasked} />;
  }
  return <Chip size={size} onClick={handleUnmask} label={maskedValue} />;
};
export { MaskedChip };
