// https://www.web-payment-software.com/online-merchant-accounts/mcc-codes/
const BusinessPurposeHints = [
  { code: '4814', hint: 'Fax services' },
  {
    code: '4814',
    hint: 'Telecommunication service including local and long distance calls, credit card calls, calls through use of magneticstrip reading telephones and fax services',
  },
  { code: '4815', hint: 'VisaPhone' },
  { code: '4821', hint: 'Telegraph services' },
  { code: '4829', hint: 'Money Orders - Wire Transfer' },
  {
    code: '4899',
    hint: 'Cable and other pay television (previously Cable Services)',
  },
  { code: '4900', hint: 'Electric, Gas, Sanitary and Water Utilities' },
  { code: '5013', hint: 'Motor vehicle supplies and new parts' },
  { code: '5021', hint: 'Office and Commercial Furniture' },
  { code: '5039', hint: 'Construction Materials, Not Elsewhere Classified' },
  {
    code: '5044',
    hint: 'Office, Photographic, Photocopy, and Microfilm Equipment',
  },
  { code: '5045', hint: 'Computers, Computer Peripheral Equipment, Software' },
  { code: '5046', hint: 'Commercial Equipment, Not Elsewhere Classified' },
  {
    code: '5047',
    hint: 'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
  },
  { code: '5051', hint: 'Metal Service Centers and Offices' },
  { code: '5065', hint: 'Electrical Parts and Equipment' },
  { code: '5072', hint: 'Hardware Equipment and Supplies' },
  { code: '5074', hint: 'Plumbing and Heating Equipment and Supplies' },
  { code: '5085', hint: 'Industrial Supplies, Not Elsewhere Classified' },
  { code: '5094', hint: 'Precious Stones and Metals, Watches and Jewelry' },
  { code: '5099', hint: 'Durable Goods, Not Elsewhere Classified' },
  {
    code: '5111',
    hint: 'Stationery, Office Supplies, Printing, and Writing Paper',
  },
  { code: '5122', hint: 'Drugs, Drug Proprietors, and Druggist’s Sundries' },
  { code: '5131', hint: 'Piece Goods, Notions, and Other Dry Goods' },
  {
    code: '5137',
    hint: 'Men’s Women’s and Children’s Uniforms and Commercial Clothing',
  },
  { code: '5139', hint: 'Commercial Footwear' },
  {
    code: '5169',
    hint: 'Chemicals and Allied Products, Not Elsewhere Classified',
  },
  { code: '5172', hint: 'Petroleum and Petroleum Products' },
  { code: '5192', hint: 'Books, Periodicals, and Newspapers' },
  { code: '5193', hint: 'Florists’ Supplies, Nursery Stock and Flowers' },
  { code: '5198', hint: 'Paints, Varnishes, and Supplies' },
  { code: '5199', hint: 'Non-durable Goods, Not Elsewhere Classified' },
  { code: '5200', hint: 'Home Supply Warehouse Stores' },
  { code: '5211', hint: 'Lumber and Building Materials Stores' },
  { code: '5231', hint: 'Glass Stores' },
  { code: '5231', hint: 'Paint and Wallpaper Stores' },
  { code: '5231', hint: 'Wallpaper Stores' },
  { code: '5251', hint: 'Hardware Stores' },
  { code: '5261', hint: 'Nurseries – Lawn and Garden Supply Store' },
  { code: '5271', hint: 'Mobile Home Dealers' },
  { code: '5300', hint: 'Wholesale Clubs' },
  { code: '5309', hint: 'Duty Free Store' },
  { code: '5310', hint: 'Discount Stores' },
  { code: '5311', hint: 'Department Stores' },
  { code: '5331', hint: 'Variety Stores' },
  { code: '5399', hint: 'Misc. General Merchandise' },
  { code: '5411', hint: 'Grocery Stores' },
  { code: '5411', hint: 'Supermarkets' },
  { code: '5422', hint: 'Freezer and Locker Meat Provisioners' },
  { code: '5422', hint: 'Meat Provisioners – Freezer and Locker' },
  { code: '5441', hint: 'Candy Stores' },
  { code: '5441', hint: 'Confectionery Stores' },
  { code: '5441', hint: 'Nut Stores' },
  { code: '5451', hint: 'Dairy Products Stores' },
  { code: '5462', hint: 'Bakeries' },
  {
    code: '5499',
    hint: 'Misc. Food Stores – Convenience Stores and Specialty Markets',
  },
  {
    code: '5511',
    hint: 'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
  },
  { code: '5521', hint: 'Automobile and Truck Dealers (Used Only)' },
  { code: '5531', hint: 'Automobile Supply Stores' },
  { code: '5532', hint: 'Automotive Tire Stores' },
  { code: '5533', hint: 'Automotive Parts, Accessories Stores' },
  {
    code: '5541',
    hint: 'Service Stations ( with or without ancillary services)',
  },
  { code: '5542', hint: 'Automated Fuel Dispensers' },
  { code: '5551', hint: 'Boat Dealers' },
  { code: '5561', hint: 'Recreational and Utility Trailers, Camp Dealers' },
  { code: '5571', hint: 'Motorcycle Dealers' },
  { code: '5592', hint: 'Motor Home Dealers' },
  { code: '5598', hint: 'Snowmobile Dealers' },
  { code: '5611', hint: 'Men’s and Boy’s Clothing and Accessories Stores' },
  { code: '5621', hint: 'Women’s Ready - to - Wear Stores' },
  { code: '5631', hint: 'Women’s Accessory and Specialty Shops' },
  { code: '5641', hint: 'Children’s and Infant’s Wear Stores' },
  { code: '5651', hint: 'Family Clothing Stores' },
  { code: '5655', hint: 'Sports Apparel, Riding Apparel Stores' },
  { code: '5661', hint: 'Shoe Stores' },
  { code: '5681', hint: 'Furriers and Fur Shops' },
  { code: '5691', hint: 'Men’s and Women’s Clothing Stores' },
  { code: '5697', hint: 'Tailors, Seamstress, Mending, and Alterations' },
  { code: '5698', hint: 'Wig and Toupee Stores' },
  { code: '5699', hint: 'Miscellaneous Apparel and Accessory Shops' },
  {
    code: '5712',
    hint: 'Furniture, Home Furnishings, and Equipment Stores, Except Appliances',
  },
  { code: '5713', hint: 'Floor Covering Stores' },
  { code: '5714', hint: 'Drapery, Window Covering and Upholstery Stores' },
  {
    code: '5718',
    hint: 'Fireplace, Fireplace Screens, and Accessories Stores',
  },
  { code: '5719', hint: 'Miscellaneous Home Furnishing Specialty Stores' },
  { code: '5722', hint: 'Household Appliance Stores' },
  { code: '5732', hint: 'Electronic Sales' },
  {
    code: '5733',
    hint: 'Music Stores, Musical Instruments, Piano Sheet Music',
  },
  { code: '5734', hint: 'Computer Software Stores' },
  { code: '5735', hint: 'Record Shops' },
  { code: '5811', hint: 'Caterers' },
  { code: '5812', hint: 'Eating places and Restaurants' },
  {
    code: '5813',
    hint: 'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
  },
  { code: '5814', hint: 'Fast Food Restaurants' },
  { code: '5912', hint: 'Drug Stores and Pharmacies' },
  { code: '5921', hint: 'Package Stores - Beer, Wine, and Liquor' },
  { code: '5931', hint: 'Used Merchandise and Secondhand Stores' },
  {
    code: '5832',
    hint: 'Antique Shops - Sales, Repairs, and Restoration Services',
  },
  { code: '5933', hint: 'Pawn Shops and Salvage Yards' },
  { code: '5935', hint: 'Wrecking and Salvage Yards' },
  { code: '5937', hint: 'Antique Reproductions' },
  { code: '5940', hint: 'Bicycle Shops - Sales and Service' },
  { code: '5941', hint: 'Sporting Goods Stores' },
  { code: '5942', hint: 'Book Stores' },
  { code: '5943', hint: 'Stationery Stores, Office and School Supply Stores' },
  { code: '5944', hint: 'Watch, Clock, Jewelry, and Silverware Stores' },
  { code: '5945', hint: 'Hobby, Toy, and Game Shops' },
  { code: '5946', hint: 'Camera and Photographic Supply Stores' },
  { code: '5947', hint: 'Card Shops, Gift, Novelty, and Souvenir Shops' },
  { code: '5948', hint: 'Leather Foods Stores' },
  { code: '5949', hint: 'Sewing, Needle, Fabric, and Price Goods Stores' },
  { code: '5950', hint: 'Glassware/Crystal Stores' },
  { code: '5960', hint: 'Direct Marketing - Insurance Service' },
  {
    code: '5961',
    hint: 'Mail Order Houses Including Catalog Order Stores, Book/Record Clubs (No longer permitted for .S. original presentments)',
  },
  {
    code: '5962',
    hint: 'Direct Marketing - Travel Related Arrangements Services',
  },
  { code: '5963', hint: 'Door - to - Door Sales' },
  { code: '5964', hint: 'Direct Marketing - Catalog Merchant' },
  {
    code: '5965',
    hint: 'Direct Marketing - Catalog and Catalog and Retail Merchant Direct Marketing - Outbound Telemarketing Merchant',
  },
  { code: '5967', hint: 'Direct Marketing -Inbound Teleservices Merchant' },
  { code: '5968', hint: 'Direct Marketing - Continuity/Subscription Merchant' },
  { code: '5969', hint: 'Direct Marketing - Not Elsewhere Classified' },
  { code: '5970', hint: 'Artist’s Supply and Craft Shops' },
  { code: '5971', hint: 'Art Dealers and Galleries' },
  {
    code: '5972',
    hint: 'Stamp and Coin Stores - Philatelic and Numismatic Supplies',
  },
  { code: '5973', hint: 'Religious Goods Stores' },
  { code: '5975', hint: 'Hearing Aids - Sales, Service, and Supply Stores' },
  { code: '5976', hint: 'Orthopedic Goods Prosthetic Devices' },
  { code: '5977', hint: 'Cosmetic Stores' },
  { code: '5978', hint: 'Typewriter Stores - Sales, Rental, Service' },
  { code: '5983', hint: 'Fuel - Fuel Oil, Wood, Coal, Liquefied Petroleum' },
  { code: '5992', hint: 'Florists' },
  { code: '5993', hint: 'Cigar Stores and Stands' },
  { code: '5994', hint: 'News Dealers and Newsstands' },
  { code: '5995', hint: 'Pet Shops, Pet Foods, and Supplies Stores' },
  { code: '5996', hint: 'Swimming Pools - Sales, Service, and Supplies' },
  { code: '5997', hint: 'Electric Razor Stores - Sales and Service' },
  { code: '5998', hint: 'Tent and Awning Shops' },
  { code: '5999', hint: 'Miscellaneous and Specialty Retail Stores' },
  { code: '6010', hint: 'Financial Institutions - Manual Cash Disbursements' },
  { code: '6011', hint: 'Financial Institutions - Manual Cash Disbursements' },
  { code: '6012', hint: 'Financial Institutions - Merchandise and Services' },
  {
    code: '6051',
    hint: 'Non - Financial Institutions - Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
  },
  { code: '6211', hint: 'Security Brokers/Dealers' },
  { code: '6300', hint: 'Insurance Sales, Underwriting, and Premiums' },
  {
    code: '6381',
    hint: 'Insurance Premiums, (no longer valid for first presentment work)',
  },
  {
    code: '6399',
    hint: 'Insurance, Not Elsewhere Classified ( no longer valid for first presentment work)',
  },
  {
    code: '7011',
    hint: 'Lodging - Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
  },
  { code: '7012', hint: 'Timeshares' },
  { code: '7032', hint: 'Sporting and Recreational Camps' },
  { code: '7033', hint: 'Trailer Parks and Camp Grounds' },
  { code: '7210', hint: 'Laundry, Cleaning, and Garment Services' },
  { code: '7211', hint: 'Laundry - Family and Commercial' },
  { code: '7216', hint: 'Dry Cleaners' },
  { code: '7217', hint: 'Carpet and Upholstery Cleaning' },
  { code: '7221', hint: 'Photographic Studios' },
  { code: '7230', hint: 'Barber and Beauty Shops' },
  {
    code: '7251',
    hint: 'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
  },
  { code: '7261', hint: 'Funeral Service and Crematories' },
  { code: '7273', hint: 'Dating and Escort Services' },
  { code: '7276', hint: 'Tax Preparation Service' },
  { code: '7277', hint: 'Counseling Service - Debt, Marriage, Personal' },
  { code: '7278', hint: 'Buying/Shopping Services, Clubs' },
  { code: '7296', hint: 'Clothing Rental - Costumes, Formal Wear, Uniforms' },
  { code: '7297', hint: 'Massage Parlors' },
  { code: '7298', hint: 'Health and Beauty Shops' },
  {
    code: '7299',
    hint: 'Miscellaneous Personal Services ( not elsewhere classifies)',
  },
  { code: '7311', hint: 'Advertising Services' },
  { code: '7321', hint: 'Consumer Credit Reporting Agencies' },
  { code: '7332', hint: 'Blueprinting and Photocopying Services' },
  { code: '7333', hint: 'Commercial Photography, Art and Graphics' },
  { code: '7338', hint: 'Quick Copy, Reproduction and Blueprinting Services' },
  { code: '7339', hint: 'Stenographic and Secretarial Support Services' },
  { code: '7342', hint: 'Disinfecting Services' },
  { code: '7342', hint: 'Exterminating and Disinfecting Services' },
  { code: '7349', hint: 'Cleaning and Maintenance, Janitorial Services' },
  { code: '7361', hint: 'Employment Agencies, Temporary Help Services' },
  {
    code: '7372',
    hint: 'Computer Programming, Integrated Systems Design and Data Processing Services',
  },
  { code: '7375', hint: 'Information Retrieval Services' },
  {
    code: '7379',
    hint: 'Computer Maintenance and Repair Services, Not Elsewhere Classified',
  },
  {
    code: '7392',
    hint: 'Management, Consulting, and Public Relations Services',
  },
  {
    code: '7393',
    hint: 'Protective and Security Services - Including Armored Cars and Guard Dogs',
  },
  {
    code: '7394',
    hint: 'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
  },
  { code: '7395', hint: 'Photofinishing Laboratories, Photo Developing' },
  { code: '7399', hint: 'Business Services, Not Elsewhere Classified' },
  { code: '7512', hint: 'Car Rental Companies ( Not Listed Below)' },
  { code: '7513', hint: 'Truck and Utility Trailer Rentals' },
  { code: '7519', hint: 'Motor Home and Recreational Vehicle Rentals' },
  { code: '7523', hint: 'Automobile Parking Lots and Garages' },
  { code: '7531', hint: 'Automotive Body Repair Shops' },
  { code: '7534', hint: 'Tire Re - treading and Repair Shops' },
  { code: '7535', hint: 'Paint Shops - Automotive' },
  { code: '7538', hint: 'Automotive Service Shops' },
  { code: '7542', hint: 'Car Washes' },
  { code: '7549', hint: 'Towing Services' },
  { code: '7622', hint: 'Radio Repair Shops' },
  { code: '7623', hint: 'Air Conditioning and Refrigeration Repair Shops' },
  { code: '7629', hint: 'Electrical And Small Appliance Repair Shops' },
  { code: '7631', hint: 'Watch, Clock, and Jewelry Repair' },
  {
    code: '7641',
    hint: 'Furniture, Furniture Repair, and Furniture Refinishing',
  },
  { code: '7692', hint: 'Welding Repair' },
  { code: '7699', hint: 'Repair Shops and Related Services - Miscellaneous' },
  {
    code: '7829',
    hint: 'Motion Pictures and Video Tape Production and Distribution',
  },
  { code: '7832', hint: 'Motion Picture Theaters' },
  { code: '7841', hint: 'Video Tape Rental Stores' },
  { code: '7911', hint: 'Dance Halls, Studios and Schools' },
  {
    code: '7922',
    hint: 'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
  },
  {
    code: '7929',
    hint: 'Bands. Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
  },
  { code: '7932', hint: 'Billiard and Pool Establishments' },
  { code: '7933', hint: 'Bowling Alleys' },
  {
    code: '7941',
    hint: 'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
  },
  { code: '7991', hint: 'Tourist Attractions and Exhibits' },
  { code: '7992', hint: 'Golf Courses - Public' },
  { code: '7993', hint: 'Video Amusement Game Supplies' },
  { code: '7994', hint: 'Video Game Arcades/Establishments' },
  {
    code: '7995',
    hint: 'Betting (including Lottery Tickets, Casino Gaming Chips, Off - track Betting and Wagers)',
  },
  {
    code: '7996',
    hint: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
  },
  {
    code: '7997',
    hint: 'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
  },
  { code: '7998', hint: 'Aquariums, Sea - aquariums, Dolphinariums' },
  { code: '7999', hint: 'Recreation Services (Not Elsewhere Classified)' },
  { code: '8011', hint: 'Doctors and Physicians (Not Elsewhere Classified)' },
  { code: '8021', hint: 'Dentists and Orthodontists' },
  { code: '8031', hint: 'Osteopaths' },
  { code: '8041', hint: 'Chiropractors' },
  { code: '8042', hint: 'Optometrists and Ophthalmologists' },
  { code: '8043', hint: 'Opticians, Opticians Goods and Eyeglasses' },
  {
    code: '8044',
    hint: 'Opticians, Optical Goods, and Eyeglasses (no longer valid for first presentments)',
  },
  { code: '8049', hint: 'Podiatrists and Chiropodists' },
  { code: '8050', hint: 'Nursing and Personal Care Facilities' },
  { code: '8062', hint: 'Hospitals' },
  { code: '8071', hint: 'Medical and Dental Laboratories' },
  {
    code: '8099',
    hint: 'Medical Services and Health Practitioners (Not Elsewhere Classified)',
  },
  { code: '8111', hint: 'Legal Services and Attorneys' },
  { code: '8211', hint: 'Elementary and Secondary Schools' },
  {
    code: '8220',
    hint: 'Colleges, Junior Colleges, Universities, and Professional Schools',
  },
  { code: '8241', hint: 'Correspondence Schools' },
  { code: '8244', hint: 'Business and Secretarial Schools' },
  { code: '8249', hint: 'Vocational Schools and Trade Schools' },
  {
    code: '8299',
    hint: 'Schools and Educational Services ( Not Elsewhere Classified)',
  },
  { code: '8351', hint: 'Child Care Services' },
  { code: '8398', hint: 'Charitable and Social Service Organizations' },
  { code: '8641', hint: 'Civic, Fraternal, and Social Associations' },
  { code: '8651', hint: 'Political Organizations' },
  { code: '8661', hint: 'Religious Organizations' },
  { code: '8675', hint: 'Automobile Associations' },
  {
    code: '8699',
    hint: 'Membership Organizations ( Not Elsewhere Classified)',
  },
  { code: '8734', hint: 'Testing Laboratories ( non - medical)' },
  { code: '8911', hint: 'Architectural - Engineering and Surveying Services' },
  { code: '8931', hint: 'Accounting, Auditing, and Bookkeeping Services' },
  { code: '8999', hint: 'Professional Services ( Not Elsewhere Defined)' },
  { code: '9211', hint: 'Court Costs, including Alimony and Child Support' },
  { code: '9222', hint: 'Fines' },
  { code: '9223', hint: 'Bail and Bond Payments' },
  { code: '9311', hint: 'Tax Payments' },
  { code: '9399', hint: 'Government Services ( Not Elsewhere Classified)' },
  { code: '9402', hint: 'Postal Services - Government Only' },
  { code: '9405', hint: 'Intra - Government Transactions' },
  { code: '9700', hint: 'Automated Referral Service (For Visa Only)' },
  { code: '9701', hint: 'Visa Credential Service ( For Visa Only)' },
  { code: '9702', hint: 'GCAS Emergency Services ( For Visa Only)' },
  { code: '9950', hint: 'Intra - Company Purchases ( For Visa Only)' },
];

export function filterBusinessPurposeHintsByCode(code) {
  return BusinessPurposeHints.filter((p) => p.code === `${code}`);
}
