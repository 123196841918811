import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from 'middleware/store';
import {
  loginWithSsoError,
  loginWithSsoStart,
  logoutWithSsoSuccess,
} from 'modules/authentication/login/middleware/actions/auth-actions';
import SessionService from 'modules/authentication/login/services/session-service';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TenantManagementService from 'services/tenant/tenant-management-service';

export function useAuthentication() {
  const dispatch = useDispatch();
  const sessionService = SessionService.getInstance();

  const isAuthenticatedDatabase = useSelector(
    (state: RootState) => state.authentication?.isAuthenticated,
  );

  const {
    isAuthenticated: isAuthenticatedSSO,
    getAccessTokenSilently,
    user,
    isLoading,
    loginWithRedirect,
    logout,
    loginWithPopup,
  } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: window.REACT_APP_AUTH0_MANAGEMENT_API_AUDIENCE,
        });
        sessionService.setUserDisplayName(`${user?.name}`);
        sessionService.setUserEmail(`${user?.email}`);
        sessionService.setUserPicture(`${user?.picture}`);

        dispatch(
          loginWithSsoStart(
            user?.email,
            user?.picture,
            `${user?.name}`,
            token,
            TenantManagementService.getLastTenantId(),
          ),
        );
      } catch (e) {
        dispatch(loginWithSsoError(e));
      }
    };
    if (isAuthenticatedSSO && !isLoading) {
      getAccessToken().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutWithRedirect = () => {
    logout({ returnTo: window.location.origin });

    sessionService.logout();

    dispatch(logoutWithSsoSuccess());
  };

  const isAuthenticated = isAuthenticatedSSO || isAuthenticatedDatabase;

  return {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    logoutWithRedirect,
    loginWithPopup,
  };
}
