import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import ConfigPropagationAPICommunicator from 'services/config-propagation-api-communicator';
import ConfigPropagationManager from 'services/config-propagation-manager';
import { Tenant } from 'models/tenant/tenant';
import {
  copyInstance,
  copyInstanceComplete,
  getEligibleTenants,
  getEligibleTenantsSuccess,
} from 'modules/configuration/config-propagation/middleware/reducer/config-propagation-reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { CopyInstance, CopyReport } from 'modules/configuration/config-propagation/model';

const communicator = new ConfigPropagationAPICommunicator();
const manager = new ConfigPropagationManager(communicator);

export function* getEligibleTenantsBegin() {
  yield put(startProcessing(getEligibleTenants.type));
  try {
    const eligibleTenants: Tenant[] = yield manager.fetchAllowedTenants();
    yield put(getEligibleTenantsSuccess(eligibleTenants));
  } catch (error) {
    yield put(showError(error));
  }

  yield put(stopProcessing(getEligibleTenants.type));
}

export function* copyInstanceBegin(action: PayloadAction<CopyInstance>) {
  yield put(startProcessing(copyInstance.type));
  try {
    const { targetTenants, instanceId, ruleName } = action.payload;
    const report: CopyReport = yield manager.copyInstance(targetTenants, ruleName, instanceId);

    yield put(copyInstanceComplete(report));
  } catch (error) {
    yield put(showError(error));
  }

  yield put(stopProcessing(copyInstance.type));
}

export function* getEligibleTenantsWatcher() {
  yield takeLatest(getEligibleTenants.type, getEligibleTenantsBegin);
}

export function* copyInstanceWatcher() {
  yield takeLatest(copyInstance.type, copyInstanceBegin);
}
