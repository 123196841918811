import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tenant } from 'models/tenant/tenant';
import { CopyInstance, CopyReport } from 'modules/configuration/config-propagation/model';

type EligibleTenants = {
  data: Tenant[];
  lastRequestedAt: number;
};

export type ConfigPropagationReducerState = {
  eligibleTenants: EligibleTenants;
  latestResults: CopyReport;
};

export const initialState: ConfigPropagationReducerState = {
  eligibleTenants: {
    data: [],
    lastRequestedAt: 0,
  },
  latestResults: {},
};

const configPropagationSlice = createSlice({
  name: 'configPropagation',
  initialState,
  reducers: {
    getEligibleTenants: (state) => ({
      ...state,
      eligibleTenants: {
        ...state.eligibleTenants,
        lastRequestedAt: Date.now(),
      },
    }),
    getEligibleTenantsSuccess: (state, action: PayloadAction<Tenant[]>) => ({
      ...state,
      eligibleTenants: {
        ...state.eligibleTenants,
        data: action.payload,
        isLoading: false,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    copyInstance: (state, action: PayloadAction<CopyInstance>) => state,
    copyInstanceComplete: (state, action: PayloadAction<CopyReport>) => ({
      ...state,
      latestResults: action.payload,
    }),
  },
});

export const { getEligibleTenants, getEligibleTenantsSuccess, copyInstance, copyInstanceComplete } =
  configPropagationSlice.actions;

export default configPropagationSlice;
