import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class AmlRiskScreeningAPI extends APICommunicator {
  fetchConfig() {
    const apiUrl = API_URLS.config.amlRiskScreening.fetch.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );

    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getImportStatus(params) {
    const apiUrl = API_URLS.config.screeningManager.fetchImportStatus;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateConfig(params) {
    const apiUrl = API_URLS.config.amlRiskScreening.upsert.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.postJSONForTenant(
      apiUrl,
      {
        instances: [params],
      },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteConfig(id) {
    const apiUrl = API_URLS.config.amlRiskScreening.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.id, id);

    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default AmlRiskScreeningAPI;
