import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';

export const requestManualRiskLevelChange = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.manualRiskLevelChange.data,
  payload,
});

export const manualRiskLevelChangeSuccess = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.manualRiskLevelChange.success,
  payload,
});

export const manualRiskLevelChangeError = () => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.manualRiskLevelChange.error,
});
