import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { appendManyFilesAsBase64ToFormData } from 'utils/helpers/file.helpers';

const getCaseTenantId = () => {
  let caseTenantId = TenantManagementService.getTenantIdOfCurrentCase();
  caseTenantId = caseTenantId || TenantManagementService.getActiveTenantId();
  return caseTenantId;
};

class TrxCaseDetailNoteApi extends APICommunicator {
  fetchDistinctUserIdsFromNotes() {
    const apiUrl = API_URLS.caseService.notes.userIds;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  addCaseNote(uuid, userId, note, type, attachments) {
    const apiUrl = API_URLS.caseService.singleCase.notes.addNote.replaceAll(
      API_PARAMS.caseId,
      uuid,
    );
    const body = { userId, note, type };

    const data = new FormData();
    data.append('request', new Blob([JSON.stringify(body)], { type: 'application/json' }));

    const caseTenantId = getCaseTenantId();
    return appendManyFilesAsBase64ToFormData(attachments, data, 'attachments').then(() =>
      this.postForCustomTenant(
        apiUrl,
        data,
        caseTenantId,
        ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      ),
    );
  }

  downloadAttachment(noteId, attachmentId) {
    let apiUrl = API_URLS.filesService.notes.getFile.replaceAll(API_PARAMS.noteId, noteId);

    apiUrl = apiUrl.replaceAll(API_PARAMS.id, attachmentId);
    const tenantId = getCaseTenantId();
    return this.getByteArrayFromCaseFiles(
      apiUrl,
      tenantId,
      ErrorCode.TENANT_CASE_FILES_NOT_AVAILABLE,
    );
  }
}

export default TrxCaseDetailNoteApi;
