import { put, takeLatest } from 'redux-saga/effects';
import { AssessmentEngineConfigActions } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getAssessmentEngineConfig,
  getAssessmentEngineConfigError,
  getAssessmentEngineConfigSuccess,
  updateAssessmentEngineConfigError,
  updateAssessmentEngineConfigSuccess,
} from 'middleware/actions/assessment-engine-config';
import AssessmentEngineConfigAPICommunicator from 'services/assessment-engine-config-api-communicator';
import AssessmentEngineConfigManager from 'services/assessment-engine-config-manager';

const assessmentEngineConfigAPICommunicator = new AssessmentEngineConfigAPICommunicator();
const assessmentEngineConfigManager = new AssessmentEngineConfigManager(
  assessmentEngineConfigAPICommunicator,
);

function* getAssessmentEngineConfigBegin(action) {
  try {
    yield put(startProcessing(AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET));

    const json = yield assessmentEngineConfigManager.getAssessmentEngineConfig();
    yield put(getAssessmentEngineConfigSuccess(json));

    yield put(stopProcessing(AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET));
  } catch (error) {
    yield put(getAssessmentEngineConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET));
  }
}

export function* getAssessmentEngineConfigWatcher() {
  yield takeLatest(
    AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET,
    getAssessmentEngineConfigBegin,
  );
}

function* updateAssessmentEngineConfigBegin(action) {
  try {
    yield put(startProcessing(AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_UPDATE));

    const json = yield assessmentEngineConfigManager.updateAssessmentEngineConfig(action.payload);
    yield put(updateAssessmentEngineConfigSuccess(json));

    yield put(getAssessmentEngineConfig());

    yield put(stopProcessing(AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_UPDATE));
  } catch (error) {
    yield put(updateAssessmentEngineConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_UPDATE));
  }
}

export function* updateAssessmentEngineConfigWatcher() {
  yield takeLatest(
    AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_UPDATE,
    updateAssessmentEngineConfigBegin,
  );
}
