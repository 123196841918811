import { Box, Card, CardContent, CardHeader, Grid, Tab, Tabs, Typography } from '@mui/material';
import { AccountData } from 'components/popover/person-details/person-details-tab/account-data';
import { CompanyData } from 'components/popover/person-details/person-details-tab/company-data';
import { CustomerData } from 'components/popover/person-details/person-details-tab/customer-data';
import { PartyAccountTab } from 'components/popover/person-details/person-details-tab/party-account-tab';
import { DbaData } from 'components/popover/person-details/person-details-tab/dba/dba-data';
import { GeneralData } from 'components/popover/person-details/person-details-tab/general-data';
import { PersonalData } from 'components/popover/person-details/person-details-tab/personal-data';
import { RiskRatingData } from 'components/popover/person-details/person-details-tab/risk-rating-data';
import { TabPanel } from 'components/tabs/tab';
import { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { ContactInfoView } from 'components/popover/person-details/person-details-tab/contact-data';
import { isEmptyNestedChecks } from 'utils/helpers/object-util';

const TAB_KEYS = {
  partyAccount: 'partyAccount',
  customer: 'customer',
  general: 'general',
  personal: 'personal',
  company: 'company',
  dba: 'dba',
  account: 'account',
  riskRating: 'riskRating',
  contact: 'contact',
};

/**
 * Showing tabs for popover
 * @param {*} props
 */
const PersonDetailsTab = ({
  personDetails,
  isCustomer,
  tenantConfig,
  customerRiskRatingData,
  defaultTab,
  getDetailsCardMode,
  isIndividual,
  caseConfig,
  showUnmaskedData,
  isCustomerCase = false,
}) => {
  const detachmentConfig = useSelector((state) => state.authentication?.detachmentConfig);

  const userPermissions = useSelector((state) => state.authentication?.userPermissions || []);

  const isRiskRatingVisible =
    isCustomer &&
    (isIndividual
      ? isFeatureAccessible(
          DETACHMENT_CONFIG.customerRiskRating.individual,
          detachmentConfig,
          userPermissions,
        )
      : isFeatureAccessible(
          DETACHMENT_CONFIG.customerRiskRating.organization,
          detachmentConfig,
          userPermissions,
        )) &&
    !!customerRiskRatingData;

  const parties = personDetails?.parties;

  // we show contact tab and contact card only if contact details are present . Contact details are not dependant on company data or personal data
  if (
    !personDetails?.companyDetails &&
    !personDetails?.personalDetails &&
    personDetails?.contactDetails &&
    !isCustomer
  ) {
    personDetails.personalDetails = {};
  }

  const isPersonalTabVisible =
    (!parties && !isEmptyNestedChecks(personDetails?.personalDetails)) ||
    (parties?.length === 1 && !isEmptyNestedChecks(parties[0]?.personalDetails));

  const isCompanyTabVisible =
    (!parties && !isEmptyNestedChecks(personDetails?.companyDetails)) ||
    (parties?.length === 1 && !isEmptyNestedChecks(parties[0]?.companyDetails));

  const isContactTabVisible =
    (!parties && !isEmptyNestedChecks(personDetails?.contactDetails)) ||
    (parties?.length === 1 && !isEmptyNestedChecks(parties[0]?.contactDetails));

  const buildTabKeys = () => ({
    partyAccount: {
      key: TAB_KEYS.partyAccount,
      isVisible: parties?.length > 1 && !isEmptyNestedChecks(parties),
    },
    customer: {
      key: TAB_KEYS.customer,
      isVisible: isCustomer && (!parties || parties?.length < 2),
    },
    general: {
      key: TAB_KEYS.general,
      isVisible: !isCustomer && (!parties || parties?.length < 2),
    },
    personal: {
      key: TAB_KEYS.personal,
      isVisible: isPersonalTabVisible,
    },
    company: {
      key: TAB_KEYS.company,
      isVisible: isCompanyTabVisible,
    },
    dba: {
      key: TAB_KEYS.dba,
      isVisible: false,
    },
    account: {
      key: TAB_KEYS.account,
      isVisible: false,
    },
    riskRating: {
      key: TAB_KEYS.riskRating,
      isVisible: isRiskRatingVisible,
    },
    contact: {
      key: TAB_KEYS.contact,
      isVisible: isContactTabVisible,
    },
  });

  const [tabKeys, setTabKeys] = useState(buildTabKeys());

  useEffect(() => {
    const tabKeys = buildTabKeys();
    setTabKeys(tabKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomer, personDetails, customerRiskRatingData]);

  const isTabVisible = (tabKey) => tabKeys[tabKey]?.isVisible;

  /**
   * get key of first visible tab
   */
  const getFirstVisibleTab = () => {
    let firstVisibleTabKey;

    Object.keys(tabKeys).forEach((key) => {
      if (isTabVisible(key)) {
        firstVisibleTabKey = firstVisibleTabKey || key;
      }
    });
    return firstVisibleTabKey;
  };

  const [selectedTabKey, setSelectedTab] = useState(getFirstVisibleTab());

  useEffect(() => {
    if (defaultTab && isTabVisible(defaultTab)) {
      setSelectedTab(defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKeys, defaultTab]);

  const handleChange = (event, newTabKey) => {
    setSelectedTab(newTabKey);
  };

  const TabLayout = () => (
    <Translation>
      {(t) => (
        <>
          {selectedTabKey && (
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="w-100">
              <Tabs
                value={selectedTabKey}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="p-0 m-0"
              >
                {tabKeys.partyAccount.isVisible && (
                  <Tab
                    value={tabKeys.partyAccount.key}
                    label={t('case:userPopover.partyAccount.heading')}
                    data-testid="party-account-tab"
                  />
                )}
                {tabKeys.customer.isVisible && (
                  <Tab
                    value={tabKeys.customer.key}
                    label={t('case:userPopover.customer.heading')}
                  />
                )}
                {tabKeys.general.isVisible && (
                  <Tab
                    value={tabKeys.general.key}
                    label={t('case:userPopover.general.heading')}
                    data-testid="general-tab"
                  />
                )}
                {tabKeys.personal.isVisible && (
                  <Tab
                    value={tabKeys.personal.key}
                    label={t('case:userPopover.personal.heading')}
                    data-testid="personal-tab"
                  />
                )}
                {tabKeys.company.isVisible && (
                  <Tab
                    value={tabKeys.company.key}
                    label={t('case:userPopover.company.heading')}
                    data-testid="company-tab"
                  />
                )}
                {tabKeys.dba.isVisible && (
                  <Tab value={tabKeys.dba.key} label={t('case:userPopover.dba.heading')} />
                )}
                {tabKeys.account.isVisible && (
                  <Tab value={tabKeys.account.key} label={t('case:userPopover.account.heading')} />
                )}
                {tabKeys.riskRating.isVisible && (
                  <Tab
                    value={tabKeys.riskRating.key}
                    label={t('case:userPopover.riskRating.heading')}
                  />
                )}
                {tabKeys.contact.isVisible && (
                  <Tab
                    value={tabKeys.contact.key}
                    label={t('case:userPopover.personal.contactInfo.heading')}
                  />
                )}
              </Tabs>
            </Box>
          )}
          <Box className="w-100 max-h-vh-33 overflow-y-auto overflow-x-hidden py-2 px-3">
            {tabKeys.partyAccount.isVisible && (
              <TabPanel
                index={tabKeys.partyAccount.key}
                value={selectedTabKey}
                data-testid="party-account-tab-panel"
              >
                <PartyAccountTab
                  showUnmaskedData={showUnmaskedData}
                  personDetails={personDetails}
                  tenantConfig={tenantConfig}
                  caseConfig={caseConfig}
                  isCustomerCase={isCustomerCase}
                  isCustomer={isCustomer}
                  customerRiskRatingData={customerRiskRatingData}
                  isIndividual={isIndividual}
                />
              </TabPanel>
            )}
            {tabKeys.customer.isVisible && (
              <TabPanel index={tabKeys.customer.key} value={selectedTabKey}>
                <CustomerData
                  showUnmaskedData={showUnmaskedData}
                  personDetails={personDetails}
                  tenantConfig={tenantConfig}
                  caseConfig={caseConfig}
                  isCustomerCase={isCustomerCase}
                  isCustomer={isCustomer}
                />
              </TabPanel>
            )}

            {tabKeys.general.isVisible && (
              <TabPanel
                index={tabKeys.general.key}
                value={selectedTabKey}
                data-testid="general-tab-panel"
              >
                <GeneralData personDetails={personDetails} showUnmaskedData={showUnmaskedData} />
              </TabPanel>
            )}

            {tabKeys.personal.isVisible && (
              <TabPanel
                index={tabKeys.personal.key}
                value={selectedTabKey}
                data-testid="personal-tab-panel"
              >
                <PersonalData
                  showUnmaskedData={showUnmaskedData}
                  personDetails={personDetails}
                  caseConfig={caseConfig}
                />
              </TabPanel>
            )}

            {tabKeys.company.isVisible && (
              <TabPanel
                index={tabKeys.company.key}
                value={selectedTabKey}
                data-testid="company-tab-panel"
              >
                <CompanyData
                  showUnmaskedData={showUnmaskedData}
                  personDetails={personDetails}
                  caseConfig={caseConfig}
                />
              </TabPanel>
            )}
            {tabKeys.contact.isVisible && (
              <TabPanel index={tabKeys.contact.key} value={selectedTabKey}>
                <ContactInfoView
                  showUnmaskedData={showUnmaskedData}
                  personDetails={personDetails}
                  caseConfig={caseConfig}
                />
              </TabPanel>
            )}
            {tabKeys.dba.isVisible && (
              <TabPanel index={tabKeys.dba.key} value={selectedTabKey}>
                <DbaData personDetails={personDetails} />
              </TabPanel>
            )}

            {tabKeys.account.isVisible && (
              <TabPanel index={tabKeys.account.key} value={selectedTabKey}>
                <AccountData personDetails={personDetails} />
              </TabPanel>
            )}

            {tabKeys.riskRating.isVisible && (
              <TabPanel index={tabKeys.riskRating.key} value={selectedTabKey}>
                <RiskRatingData
                  isIndividual={isIndividual}
                  popoverMode
                  caseConfig={caseConfig}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...customerRiskRatingData}
                />
              </TabPanel>
            )}
          </Box>

          {!selectedTabKey && (
            <Typography className="px-3 pb-2" variant="caption" color="textSecondary">
              {t('noData.heading')}
            </Typography>
          )}
        </>
      )}
    </Translation>
  );

  const CardListLayout = () => (
    <Translation>
      {(t) => (
        <Grid container spacing={2}>
          {tabKeys.partyAccount.isVisible && (
            <Grid item xs={6} xl={4}>
              <Card>
                <CardHeader title={t('case:userPopover.partyAccount.heading')} />
                <CardContent>
                  <PartyAccountTab
                    showUnmaskedData={showUnmaskedData}
                    personDetails={personDetails}
                    tenantConfig={tenantConfig}
                    caseConfig={caseConfig}
                    isCustomerCase={isCustomerCase}
                    isCustomer={isCustomer}
                    customerRiskRatingData={customerRiskRatingData}
                    isIndividual={isIndividual}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {tabKeys.customer.isVisible && (
            <Grid item xs={6} xl={4}>
              <Card>
                <CardHeader title={t('case:userPopover.customer.heading')} />
                <CardContent>
                  <CustomerData
                    showUnmaskedData={showUnmaskedData}
                    personDetails={personDetails}
                    tenantConfig={tenantConfig}
                    caseConfig={caseConfig}
                    isCustomerCase={isCustomerCase}
                    isCustomer={isCustomer}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}

          {tabKeys.personal.isVisible && (
            <Grid item xs={6} xl={4}>
              <Card>
                <CardHeader title={t('case:userPopover.personal.heading')} />
                <CardContent>
                  <PersonalData
                    showUnmaskedData={showUnmaskedData}
                    personDetails={personDetails}
                    caseConfig={caseConfig}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {tabKeys.company.isVisible && (
            <Grid item xs={6} xl={4}>
              <Card data-testid="company-card">
                <CardHeader title={t('case:userPopover.company.heading')} />
                <CardContent>
                  <CompanyData
                    showUnmaskedData={showUnmaskedData}
                    personDetails={personDetails}
                    caseConfig={caseConfig}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {tabKeys.contact.isVisible && (
            <Grid item xs={6} xl={4}>
              <Card>
                <CardHeader title={t('case:userPopover.personal.contactInfo.heading')} />
                <CardContent>
                  <ContactInfoView
                    showUnmaskedData={showUnmaskedData}
                    personDetails={personDetails}
                    caseConfig={caseConfig}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {tabKeys.dba.isVisible && (
            <Grid item xs={6} xl={4}>
              <Card>
                <CardHeader title={t('case:userPopover.dba.heading')} />
                <CardContent>
                  <DbaData personDetails={personDetails} />
                </CardContent>
              </Card>
            </Grid>
          )}
          {tabKeys.account.isVisible && (
            <Grid item xs={6} md={4}>
              <Card>
                <CardHeader title={t('case:userPopover.account.heading')} />
                <CardContent>
                  <AccountData personDetails={personDetails} />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </Translation>
  );

  return getDetailsCardMode ? <CardListLayout /> : <TabLayout />;
};

export { PersonDetailsTab, TAB_KEYS };
