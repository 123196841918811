import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography } from '@mui/material';

interface AdditionalIdChipProps {
  form: {
    key?: string;
    values?: string[];
  };
}

export const AdditionalIdChip: FC<AdditionalIdChipProps> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.additionalIds.value.heading')}
      </Box>
      <Typography component="span" className="mr-1" variant="inherit">
        {form.key}
      </Typography>
      {form.values?.map((item, index) => (
        <Chip
          key={index}
          variant="outlined"
          size="small"
          className="mr-1"
          label={item}
          style={{ marginBottom: '1px' }}
        />
      ))}
    </>
  );
};
