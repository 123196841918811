import Party from 'utils/constants/party';

class TransactionData {
  constructor(caseDetail) {
    if (!caseDetail) {
      throw new Error('Param caseDetail is required.');
    }

    this.caseDetail = caseDetail;
  }

  getCaseDetail() {
    return this.caseDetail;
  }

  isDisplayCase() {
    return this.getCaseDetail().transactionCount > 0;
  }

  getTransaction() {
    const transactions = this.getCaseDetail().transactions || [];
    return transactions.length > 0 ? transactions[0] || {} : {};
  }

  getCustomer() {
    const transaction = this.getTransaction();
    if (transaction) {
      return transaction.accountHoldingParty === Party.FUNDS_BENEFICIARY
        ? transaction.fundsBeneficiary || {}
        : transaction.fundsOriginator || {};
    }
    return {};
  }

  getCustomerHolderName() {
    return this.getCustomer().holderName || {};
  }

  getCustomerAccount() {
    return this.getCustomer().accountNumber || {};
  }

  getCounterparty() {
    const transaction = this.getTransaction();
    if (transaction) {
      return transaction.accountHoldingParty === Party.FUNDS_BENEFICIARY
        ? transaction.fundsOriginator || {}
        : transaction.fundsBeneficiary || {};
    }
    return {};
  }

  getCounterpartyHolderName() {
    return this.getCounterparty().holderName || {};
  }

  getCounterpartyAccount() {
    return this.getCounterparty().accountNumber || {};
  }

  getMasterData() {
    return this.getTransaction().masterData || {};
  }

  getBusinessPurposes(isCustomer) {
    const masterData = this.getMasterData();
    const partyData = isCustomer
      ? masterData.accountHoldingPartyData || {}
      : masterData.counterpartyData || {};
    const companyDetails = partyData.companyDetails || {};
    return companyDetails.businessPurposes || [];
  }

  getOverwrites() {
    const transaction = this.getTransaction();
    const configuration = transaction.configuration || {};
    return configuration.overwrites || {};
  }
}

export default TransactionData;
