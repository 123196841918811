export const ACCOUNT_BASED_FREQUENCY_ACTIONS = {
  increaseDecrease: {
    fetch: {
      data: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_GET',
      success: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_GET_SUCCESS',
      error: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_GET_ERROR',
    },
    update: {
      data: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_UPDATE',
      success: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_UPDATE_SUCCESS',
      error: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_UPDATE_ERROR',
    },
    delete: {
      data: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_DELETE',
      success: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_DELETE_SUCCESS',
      error: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_CONFIG_DELETE_ERROR',
    },
  },
};
