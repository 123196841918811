import { BLACKLIST_CONFIG_ACTIONS } from 'middleware/action-types';

const blacklistConfigReducer = (state = { instances: [] }, action) => {
  switch (action.type) {
    case BLACKLIST_CONFIG_ACTIONS.get.success:
    case BLACKLIST_CONFIG_ACTIONS.update.success:
    case BLACKLIST_CONFIG_ACTIONS.delete.success: {
      const payload = action.payload || {};
      return {
        ...state,
        instances: payload.instances,
        updateDate: payload.updatedAt,
        userId: payload.userId,
      };
    }
    default:
      return state;
  }
};

export default blacklistConfigReducer;
