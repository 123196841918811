import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';
import CaseDetailNoteCCApi from 'modules/customer-cases/case-detail/services/case-note/case-note-api-communicator';
import CaseDetailNoteCCManager from 'modules/customer-cases/case-detail/services/case-note/case-note-manager';
import {
  addCustomerCaseNoteError,
  addCustomerCaseNoteSuccess,
  downloadAttachmentNoteError,
  downloadAttachmentNoteSuccess,
} from 'modules/customer-cases/case-detail/middleware/actions/case-note';

const caseNoteCommunicator = new CaseDetailNoteCCApi();
const caseNoteManager = new CaseDetailNoteCCManager(caseNoteCommunicator);

function* addCustomerCaseNote(action, queryAddCaseNote) {
  yield put(startProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.data));

  try {
    const json = yield queryAddCaseNote(action);
    yield put(addCustomerCaseNoteSuccess({ type: action.type, data: json }));
  } catch (error) {
    yield put(addCustomerCaseNoteError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.data));
}

function* addCustomerCaseNoteBegin(action) {
  yield addCustomerCaseNote(action, ({ payload }) =>
    caseNoteManager.addCaseNote(
      payload.caseId,
      payload.tenantId,
      payload.userId,
      payload.note,
      payload.type,
      payload.attachments,
    ),
  );
}

function* addCustomerCaseNoteWatcher() {
  yield takeLatest(CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.data, addCustomerCaseNoteBegin);
}

function* downloadCaseNoteAttachmentBegin(action) {
  const payload = action.payload || {};
  const { attachmentId, noteId, caseId } = payload;

  try {
    yield put(startProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data));
    const json = yield caseNoteManager.downloadAttachment(caseId, noteId, attachmentId);

    const fileData = {
      blob: json,
      properties: payload,
    };

    yield put(downloadAttachmentNoteSuccess(fileData));

    yield put(stopProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data));
  } catch (error) {
    yield put(downloadAttachmentNoteError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data));
  }
}

function* downloadCaseNoteAttachmentCCWatcher() {
  yield takeEvery(
    CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data,
    downloadCaseNoteAttachmentBegin,
  );
}

export { addCustomerCaseNoteWatcher, downloadCaseNoteAttachmentCCWatcher };
