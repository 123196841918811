import { HIDTA_HIFCA_LIST_ACTIONS } from 'middleware/action-types';

const hidtaHifcaReducer = (state = {}, action) => {
  switch (action.type) {
    case HIDTA_HIFCA_LIST_ACTIONS.fileList.get: {
      return state;
    }
    case HIDTA_HIFCA_LIST_ACTIONS.fileList.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }
    default:
      return state;
  }
};

export default hidtaHifcaReducer;
