import { Box, Chip, Divider, FormHelperText, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import { sortBy } from 'lodash';
import { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { TypeCell } from 'components/table/table-cell/case-table-cell';
import Controls from 'components/controls/controls';
import { FEATURE_STATUS } from 'utils/constants/constant';
import { useSelector } from 'react-redux';

const buildCaseScreeningTypeStringValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
  field: i18next.t('mapping:filters.filtering.typesString.key'),
  values: filter?.values,
});

const CaseScreeningTypeStringForm = ({ editForm, onSubmit }) => {
  const detachmentConfig = useSelector((state) => state.authentication?.detachmentConfig);

  const FORM_KEY_PREFIX = 'caseScreeningTypeStrings';
  const { t } = useTranslation();

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  /**
   * validating field form
   * @param newCheckedTypes
   * @returns
   */
  const validate = (newCheckedTypes = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };

    temp.caseTypesString = newCheckedTypes.length ? '' : t('required.heading');

    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const initialFValues = {};

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`${FORM_KEY_PREFIX}[${item}]`] = true;
    });
  }

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const is314aAttached = detachmentConfig?.regulatoryChecks === FEATURE_STATUS.attached;
  const isAdverseMediaAttached = detachmentConfig?.adverseMedia === FEATURE_STATUS.attached;

  const patterns = sortBy(
    Object.values(t('mapping:screeningPatternType'))
      .filter((item) => item.key !== 'REG_314A' || is314aAttached)
      .filter((item) => (item.key !== 'AMP' && item.key !== 'AME') || isAdverseMediaAttached),
    (item) => item.value.heading,
  );

  const handleSelectChange = (event, checkedName) => {
    handleInputChange(event);
    const newTypes = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newTypes);
    validate(newTypes);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
        field: t('mapping:filters.filtering.typesString.key'),
        values: checkedValues,
      });
    }
  };

  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
          {patterns.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Controls.Checkbox
                name={`${FORM_KEY_PREFIX}[${item.key}]`}
                label={
                  <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>
                      <TypeCell singleTypeKey={item.key} />
                    </Grid>
                    <Grid item>{item.value.heading}</Grid>
                  </Grid>
                }
                value={values[`${FORM_KEY_PREFIX}[${item.key}]`] ?? false}
                onChange={(event) => handleSelectChange(event, item.key)}
                size="small"
              />
            </Grid>
          ))}
        </Grid>
        {errors.caseTypesString && (
          <FormHelperText className="pl-3" error>
            {errors.caseTypesString}
          </FormHelperText>
        )}
        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
        </Grid>
      </Form>
    </Grid>
  );
};

const CaseScreeningTypesStringChip = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.typesString.value.heading')}
      </Box>
      {form.values.map((item, index) => (
        <Chip
          key={index}
          variant="outlined"
          size="small"
          className="mr-1"
          label={t(`mapping:screeningPatternType.${item}.value.heading`)}
          icon={<TypeCell singleTypeKey={item} customClass="ml-1 icon-14" />}
        />
      ))}
    </>
  );
};

export {
  CaseScreeningTypeStringForm,
  CaseScreeningTypesStringChip,
  buildCaseScreeningTypeStringValuesFromUrl,
};
