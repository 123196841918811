import React, { FC } from 'react';
import { TransactionAccount } from 'modules/trx-cases/case-detail/model/transaction-account';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getFieldValue } from 'modules/trx-cases/case-detail/components/intermediary/utils/get-field-value';
import { State } from 'state';

type AccountTabProps = {
  account: TransactionAccount;
};

const AccountTab: FC<AccountTabProps> = ({ account }) => {
  const { t } = useTranslation();
  const allMasks = useSelector((state: State) => state.masks?.all);
  const caseDetailDataShown = useSelector((state: State) => state.masks?.caseDetailDataShown);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('case:intermediaryPopover.account.accountNumber')}
        </Typography>
        <Typography variant="body2">
          {getFieldValue(account.accountNumber || '-', caseDetailDataShown, allMasks)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('case:intermediaryPopover.account.holderName')}
        </Typography>
        <Typography variant="body2">
          {getFieldValue(account.holderName || '-', caseDetailDataShown, allMasks)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('case:intermediaryPopover.account.bankIdentifier')}
        </Typography>
        <Typography variant="body2">
          {getFieldValue(account.bankIdentifier || '-', caseDetailDataShown, allMasks)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountTab;
