import { Grid, ListItemText, Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { useTranslation } from 'react-i18next';

const FreeTextRiskDetails = ({ maxPoints, scoreData, popoverMode, title }) => {
  const { t } = useTranslation();

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData.CALCULATED_POINTS ?? 0}
          maxPoints={maxPoints}
          headline={title}
        />
      }
      highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
      detailsComponent={
        <Grid container justifyContent="space-between" className="pb-1">
          {scoreData.ORIGINAL_DATA &&
            scoreData.ORIGINAL_DATA.map((it, k) => (
              <Grid container item key={`cr-label-${k}`} direction="row">
                <ListItemText
                  data-testid={`role-container-${k}`}
                  primary={
                    <Typography variant="body2">
                      {t(
                        'configuration:customerRiskRating.riskFactor.factors.freeText.role.heading',
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {it.role ? it.role : '-'}
                    </Typography>
                  }
                />
                <ListItemText
                  data-testid={`value-container-${k}`}
                  primary={
                    <Typography variant="body2">
                      {t(
                        'configuration:customerRiskRating.riskFactor.factors.freeText.value.heading',
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {it.targetValue}
                    </Typography>
                  }
                />
              </Grid>
            ))}
        </Grid>
      }
    />
  );
};

export { FreeTextRiskDetails };
