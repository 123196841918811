import { AccountBasedFrequencyConfigActions } from 'modules/configuration/rule-manager/account-based-frequency/middleware/action-types';

const AccountBasedFrequencyReducer = (state = {}, action) => {
  switch (action.type) {
    case AccountBasedFrequencyConfigActions.GET_SUCCESS:
    case AccountBasedFrequencyConfigActions.UPDATE_SUCCESS:
    case AccountBasedFrequencyConfigActions.DELETE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default AccountBasedFrequencyReducer;
