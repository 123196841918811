import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ForbiddenAction {
  pageId: string;
  action: string;
}

export const isReadForbiddenAction = (actionType: string) => actionType === 'read';

interface ForbiddenAlertState {
  forbiddenActions: Array<ForbiddenAction>;
}

const initialState: ForbiddenAlertState = {
  forbiddenActions: [],
};

export const forbiddenAlertSlice = createSlice({
  name: 'forbiddenAlert',
  initialState,
  reducers: {
    addReadForbiddenAlert: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        forbiddenActions: [
          ...state.forbiddenActions,
          {
            pageId: action.payload,
            action: 'read',
          },
        ],
      };
      return newState;
    },
    removeForbiddenAlertForPageId: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        forbiddenActions: state.forbiddenActions.filter(
          (forbiddenAction) => forbiddenAction.pageId !== action.payload,
        ),
      };
      return newState;
    },
  },
});

export const { addReadForbiddenAlert, removeForbiddenAlertForPageId } = forbiddenAlertSlice.actions;
export default forbiddenAlertSlice.reducer;
