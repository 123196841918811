import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteSmurfingV2ConfigError,
  deleteSmurfingV2ConfigSuccess,
  getSmurfingV2ConfigError,
  getSmurfingV2ConfigSuccess,
  updateSmurfingV2ConfigError,
  updateSmurfingV2ConfigSuccess,
} from 'modules/configuration/rule-manager/smurfing/middleware/actions/smurfing-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';
import { SmurfingConfigActions } from 'modules/configuration/rule-manager/smurfing/middleware/action-types';

const communicator = new RuleConfigAPICommunicator('smurfing');
const manager = new RuleConfigManager(communicator);

function* getSmurfingV2ConfigBegin(action) {
  yield put(startProcessing(SmurfingConfigActions.SMURFING_V2_CONFIG_GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getSmurfingV2ConfigSuccess(json));
  } catch (error) {
    yield put(getSmurfingV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SmurfingConfigActions.SMURFING_V2_CONFIG_GET));
}

export function* getSmurfingV2ConfigWatcher() {
  yield takeLatest(SmurfingConfigActions.SMURFING_V2_CONFIG_GET, getSmurfingV2ConfigBegin);
}

function* updateSmurfingV2ConfigBegin(action) {
  yield put(startProcessing(SmurfingConfigActions.SMURFING_V2_CONFIG_UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateSmurfingV2ConfigSuccess(json));
  } catch (error) {
    yield put(updateSmurfingV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SmurfingConfigActions.SMURFING_V2_CONFIG_UPDATE));
}

export function* updateSmurfingV2ConfigWatcher() {
  yield takeLatest(SmurfingConfigActions.SMURFING_V2_CONFIG_UPDATE, updateSmurfingV2ConfigBegin);
}

function* deleteSmurfingConfigBegin(action) {
  yield put(startProcessing(SmurfingConfigActions.SMURFING_V2_CONFIG_DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteSmurfingV2ConfigSuccess(json));
  } catch (error) {
    yield put(deleteSmurfingV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SmurfingConfigActions.SMURFING_V2_CONFIG_DELETE));
}

export function* deleteSmurfingConfigWatcher() {
  yield takeLatest(SmurfingConfigActions.SMURFING_V2_CONFIG_DELETE, deleteSmurfingConfigBegin);
}
