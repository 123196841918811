export const TENANT_CONFIG_ACTIONS = {
  TENANT_CONFIG_GET: 'TENANT_CONFIG_GET',
  TENANT_CONFIG_PUT: 'TENANT_CONFIG_PUT',
  TENANT_CONFIG_GET_SUCCESS: 'TENANT_CONFIG_GET_SUCCESS',
  TENANT_CONFIG_PUT_SUCCESS: 'TENANT_CONFIG_PUT_SUCCESS',
  TENANT_CONFIG_GET_ERROR: 'TENANT_CONFIG_GET_ERROR',
  TENANT_CONFIG_PUT_ERROR: 'TENANT_CONFIG_PUT_ERROR',

  ALL_TENANTS_GET: 'ALL_TENANTS_GET',
  ALL_TENANTS_GET_SUCCESS: 'ALL_TENANTS_GET_SUCCESS',
  ALL_TENANTS_GET_ERROR: 'ALL_TENANTS_GET_SUCCESS',

  TENANT_ADD: 'TENANT_ADD',
  TENANT_ADD_SUCCESS: 'TENANT_ADD_SUCCESS',
  TENANT_ADD_ERROR: 'TENANT_ADD_ERROR',
  TENANT_REMOVE_LAST: 'TENANT_REMOVE_LAST',

  ADD_TENANT_TO_USERS: 'ADD_TENANT_TO_USERS',
  ADD_TENANT_TO_USERS_SUCCESS: 'ADD_TENANT_TO_USERS_SUCCESS',
  ADD_TENANT_TO_USERS_ERROR: 'ADD_TENANT_TO_USERS_ERROR',

  REFRESH_CACHE: 'REFRESH_CACHE',
  REFRESH_CACHE_SUCCESS: 'REFRESH_CACHE_SUCCESS',
  REFRESH_CACHE_ERROR: 'REFRESH_CACHE_ERROR',

  USERS_GET: 'USERS_GET',
  USERS_GET_SUCCESS: 'USERS_GET_SUCCESS',
  USERS_GET_ERROR: 'USERS_GET_ERROR',

  USERS_NOT_IN_TENANT_GET: 'USERS_NOT_IN_TENANT_GET',
  USERS_NOT_IN_TENANT_SUCCESS: 'USERS_NOT_IN_TENANT_SUCCESS',
  USERS_NOT_IN_TENANT_ERROR: 'USERS_NOT_IN_TENANT_ERROR',

  USER_UPDATE: 'USER_UPDATE',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',

  masterTenants: {
    get: 'MASTER_TENANTS_GET',
    success: 'MASTER_TENANTS_GET_SUCCESS',
    error: 'MASTER_TENANTS_GET_ERROR',
  },

  tenantStructure: {
    update: 'TENANT_STRUCTURE_UPDATE',
    success: 'TENANT_STRUCTURE_SUCCESS',
    error: 'TENANT_STRUCTURE_ERROR',
    clear: 'TENANT_STRUCTURE_CLEAR',
  },

  callbackConfig: {
    put: 'TENANT_CALLBACK_CONFIG_PUT',
    success: 'TENANT_CALLBACK_CONFIG_PUT_SUCCESS',
    error: 'TENANT_CALLBACK_CONFIG_PUT_ERROR',
  },

  callbackConfigSecret: {
    get: 'TENANT_CALLBACK_CONFIG_SECRET_GET',
    success: 'TENANT_CALLBACK_CONFIG_SECRET_SUCCESS',
    error: 'TENANT_CALLBACK_CONFIG_SECRET_ERROR',
  },
  scorechainConfig: {
    put: 'TENANT_SCORECHAIN_CONFIG_PUT',
    success: 'TENANT_SCORECHAIN_CONFIG_PUT_SUCCESS',
    error: 'TENANT_SCORECHAIN_CONFIG_PUT_ERROR',
  },

  scorechainConfigSecret: {
    get: 'TENANT_SCORECHAIN_CONFIG_SECRET_GET',
    success: 'TENANT_SCORECHAIN_CONFIG_SECRET_SUCCESS',
    error: 'TENANT_SCORECHAIN_CONFIG_SECRET_ERROR',
  },

  createMasterTenant: {
    add: 'TENANT_MASTER_ADD',
    status: 'TENANT_MASTER_GET_STATUS',
    success: 'TENANT_MASTER_ADD_SUCCESS',
    error: 'TENANT_MASTER_ADD_ERROR',
  },
};
