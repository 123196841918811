import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class ConfluenceServiceManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Confluence Config API Communicator instance is required.');
    }
  }

  validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CONFLUENCE_DATA_INVALID);
  }

  getArticleBody(articleId) {
    return this.communicator
      .fetchArticleBody(articleId)
      .then((json) => this.validateConfigJson(json));
  }
}

export default ConfluenceServiceManager;
