import { useState, useEffect } from 'react';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { DetachmentConfig } from 'models/authorization/detachment';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'middleware/store';

const useMachineIntelligenceFeatures = () => {
  const [isAnomalyScoreEnabled, setIsAnomalyScoreEnabled] = useState(false);
  const [isAutoCloseScoreEnabled, setIsAutoCloseScoreEnabled] = useState(false);
  const detachmentConfig: DetachmentConfig = useSelector(
    (state: RootState) => state.authentication?.detachmentConfig || {},
    shallowEqual,
  );
  useEffect(() => {
    const isMIEnabled = isFeatureAccessible(
      DETACHMENT_CONFIG.machineIntelligence.machineIntelligence,
      detachmentConfig,
    );

    if (isMIEnabled) {
      const isBAEnabled = isFeatureAccessible(
        DETACHMENT_CONFIG.machineIntelligence.behavioralAnalysis,
        detachmentConfig,
      );

      const isBFMEnabled = isFeatureAccessible(
        DETACHMENT_CONFIG.machineIntelligence.bankFalsePositiveModel,
        detachmentConfig,
      );

      setIsAnomalyScoreEnabled(isBAEnabled);
      setIsAutoCloseScoreEnabled(isBFMEnabled);
    } else {
      setIsAnomalyScoreEnabled(false);
      setIsAutoCloseScoreEnabled(false);
    }
  }, [detachmentConfig]);

  return {
    isAnomalyScoreEnabled,
    isAutoCloseScoreEnabled,
  };
};

export default useMachineIntelligenceFeatures;
