import TenantManagementService from 'services/tenant/tenant-management-service';

class AuthServiceManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Auth0 API Communicator instance is required.');
    }
  }

  fetchUsers() {
    return this.communicator.fetchUsers();
  }

  getPermissionsAssociatedToUser() {
    return this.communicator.getPermissionsAssociatedToUser();
  }

  getDetachmentConfig(tenantId) {
    return this.communicator.getDetachmentConfigByTenant(
      tenantId ?? TenantManagementService.getActiveTenantId(),
    );
  }

  refreshCache() {
    return this.communicator.refreshCache();
  }

  loginWithDatabase(email, pwd, tenantId) {
    return this.communicator.loginWithDatabase(email, pwd, tenantId);
  }

  loginWithSso(email, picture, nickname, accessToken, tenantId) {
    return this.communicator.loginWithSso(email, picture, nickname, accessToken, tenantId);
  }
}

export default AuthServiceManager;
