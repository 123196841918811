import React, { FC } from 'react';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, Typography } from '@mui/material';
import { Attribute } from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/entity-resolution-risk-details';
import { useSubHeadAttribute } from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/hooks/use-sub-head-attribute';

interface MultipleAttributeDetailsProps {
  scoreData?: {
    CALCULATED_POINTS?: number;
    TITLE?: string;
    ATTRIBUTE?: Attribute | string;
    ORIGINAL_DATA?: { value: string }[];
  };
  popoverMode: boolean;
}

const MultipleAttributeDetails: FC<MultipleAttributeDetailsProps> = ({
  scoreData,
  popoverMode,
}) => {
  const { t } = useTranslation();

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData?.CALCULATED_POINTS ?? 0}
          maxPoints={scoreData?.CALCULATED_POINTS ?? 0} // get from config RISK_FACTOR_INSTANCE once available
          headline={scoreData?.TITLE}
          subHead={`${t(
            'case:userPopover.riskRating.entityResolutionRisks.multipleAttribute.heading',
          )}: ${useSubHeadAttribute(scoreData?.ATTRIBUTE)}`}
        />
      }
      highestPointsApplied={false}
      detailsComponent={
        <Grid container justifyContent="space-between" className="pb-1">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {t(
                'case:userPopover.riskRating.entityResolutionRisks.multipleAttribute.valuesFound.heading',
              )}
            </Typography>
            {scoreData?.ORIGINAL_DATA?.map((v: { value: string }) => (
              <Chip key={v?.value} className="mr-1" size="small" label={v?.value} />
            ))}
          </Grid>
        </Grid>
      }
    />
  );
};

export default MultipleAttributeDetails;
