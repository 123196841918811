import { ConfigurationActions } from 'middleware/action-types';
import RuleManagerIds from 'utils/constants/rule-manager-ids';

const ruleManagerReducer = (state = {}, action) => {
  switch (action.type) {
    case ConfigurationActions.GET_CONFIGURATION_LAST_UPDATE_INFO_SUCCESS: {
      const lastUpdateInfos = action.payload || [];

      const sectionLastUpdateInfos = {};
      lastUpdateInfos.forEach((info) => {
        const lastUpdateInfo = {
          userId: info.userId,
          lastUpdate: info.timestamp,
        };
        switch (info.sectionId) {
          case RuleManagerIds.SANCTION_AND_PEP_SECTION_ID:
            sectionLastUpdateInfos.sanctionAndPep = lastUpdateInfo;
            break;
          case RuleManagerIds.COUNTRY_RULES_SECTION_ID:
            sectionLastUpdateInfos.countryRules = lastUpdateInfo;
            break;
          case RuleManagerIds.VOLUME_RULES_SECTION_ID:
            sectionLastUpdateInfos.volumeRules = lastUpdateInfo;
            break;
          case RuleManagerIds.RISKY_WORDS_RULES_SECTION_ID:
            sectionLastUpdateInfos.riskyWordsRules = lastUpdateInfo;
            break;
          case RuleManagerIds.MACHINE_LEARNING_RULES_SECTION_ID:
            sectionLastUpdateInfos.aiRules = lastUpdateInfo;
            break;
          case RuleManagerIds.ACCOUNT_BLACKLISTED_RULES_SECTION_ID:
            sectionLastUpdateInfos.accountBlacklistedRules = lastUpdateInfo;
            break;
          case RuleManagerIds.CASH_VOLUME_RISK_SECTION_ID:
            sectionLastUpdateInfos.cashTransactionRules = lastUpdateInfo;
            break;
          case RuleManagerIds.MONEY_MULE_SECTION_ID:
            sectionLastUpdateInfos.moneyMuleRules = lastUpdateInfo;
            break;
          case RuleManagerIds.SHELL_COMPANY_SECTION_ID:
            sectionLastUpdateInfos.shellCompanyRules = lastUpdateInfo;
            break;
          case RuleManagerIds.SEND_MONEY_HOME_SECTION_ID:
            sectionLastUpdateInfos.sendMoneyHomeRules = lastUpdateInfo;
            break;
          case RuleManagerIds.REFERENCE_ACCOUNT_SECTION_ID:
            sectionLastUpdateInfos.referenceAccountRules = lastUpdateInfo;
            break;
          case RuleManagerIds.SHARE_CROSS_BANK_SECTION_ID:
            sectionLastUpdateInfos.shareCrossBankRules = lastUpdateInfo;
            break;
          case RuleManagerIds.PASSTHROUGH_RULES_SECTION_ID:
            sectionLastUpdateInfos.passthroughRules = lastUpdateInfo;
            break;
          case RuleManagerIds.BEHAVIORAL_ANALYSIS_SECTION_ID:
            sectionLastUpdateInfos.behavioralAnalysis = lastUpdateInfo;
            break;
          case RuleManagerIds.SMURFING_RULES_SECTION_ID:
            sectionLastUpdateInfos.smurfingRules = lastUpdateInfo;
            break;
          default:
        }
      });

      return {
        ...state,
        sectionLastUpdateInfos,
      };
    }

    default:
      return state;
  }
};

export default ruleManagerReducer;
