import { put, takeEvery } from 'redux-saga/effects';
import { ConfluenceActions } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getConfluenceArticleError,
  getConfluenceArticleSuccess,
} from 'middleware/actions/confluence';
import ConfluenceAPICommunicator from 'services/confluence-api-communicator';
import ConfluenceServiceManager from 'services/confluence-service-manager';

const confluenceAPICommunicator = new ConfluenceAPICommunicator();
const confluenceServiceManager = new ConfluenceServiceManager(confluenceAPICommunicator);

function* getArticleBodyBegin(action) {
  try {
    yield put(startProcessing(ConfluenceActions.CONFLUENCE_ARTICLE_GET));
    const json = yield confluenceServiceManager.getArticleBody(action.articleId);
    yield put(getConfluenceArticleSuccess(json));
  } catch (error) {
    yield put(getConfluenceArticleError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(ConfluenceActions.CONFLUENCE_ARTICLE_GET));
}

export function* getArticleBodyWatcher() {
  yield takeEvery(ConfluenceActions.CONFLUENCE_ARTICLE_GET, getArticleBodyBegin);
}
