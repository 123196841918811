import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import axios from 'axios';
import { LoadingButton } from 'components/loaders/loading-button';
import jsFileDownload from 'js-file-download';

import SessionService from 'modules/authentication/login/services/session-service';
import { useState } from 'react';
import TenantManagementService from 'services/tenant/tenant-management-service';

/* eslint-disable */
const auth = new SessionService();

const FileDownload = ({
  callEndpoint,
  fileName,
  title,
  'data-testid': dataTestId,
  color,
  isDisabled,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  /**
   * Handle file download
   * @param {*} e
   */
  const handleFileClick = (e) => {
    e.preventDefault();

    setIsDownloading(true);

    callEndpoint()
      .then((response) => {
        jsFileDownload(response.data, fileName);

        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.log(error.toString());
      });
  };

  return (
    <LoadingButton
      iconButtonMode
      title={title}
      color={color}
      size="small"
      variant="outlined"
      onClick={(e) => handleFileClick(e)}
      style={{ height: 29 }}
      startIcon={<FileDownloadOutlinedIcon />}
      isLoading={isDownloading}
      isDisabled={isDisabled}
      data-testid={dataTestId}
    />
  );
};

export const headers = (fileName, contentType, userId) => ({
  withCredentials: true,
  headers: {
    'Content-Type': contentType,
    'Content-Disposition': `attachment; filename=${fileName}`,
    tenant_id: TenantManagementService.getActiveTenantId(),
    tenantId: TenantManagementService.getActiveTenantId(),
    correlationId: auth.getCorrelationId(),
    userId,
  },
});

const FileGetDownload = ({
  apiUrl,
  fileName,
  title,
  userId,
  'data-testid': dataTestId,
  isDisabled = false,
}) => {
  const callEndpoint = () => axios.get(apiUrl, headers(fileName, 'text/csv', userId));

  return (
    <FileDownload
      callEndpoint={callEndpoint}
      fileName={fileName}
      title={title}
      data-testid={dataTestId}
      isDisabled={isDisabled}
    />
  );
};

const FilePostDownload = ({
  apiUrl,
  data,
  fileName,
  title,
  'data-testid': dataTestId,
  isDisabled = false,
}) => {
  const callEndpoint = () => axios.post(apiUrl, data, headers(fileName, 'application/json'));

  return (
    <FileDownload
      callEndpoint={callEndpoint}
      fileName={fileName}
      title={title}
      data-testid={dataTestId}
      isDisabled={isDisabled}
    />
  );
};

export { FileGetDownload, FilePostDownload };
/* eslint-enable */
