import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

interface CustomIconButtonProps extends IconButtonProps {
  'data-testid'?: string;
}

const Edit: FC<CustomIconButtonProps> = ({
  id,
  onClick,
  edge = false,
  disabled,
  size = 'medium',
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation();
  const iconButton = (
    <IconButton
      id={id}
      onClick={onClick}
      size={size}
      edge={edge}
      disabled={disabled}
      data-testid={dataTestId}
    >
      <EditIcon />
    </IconButton>
  );

  return disabled ? iconButton : <Tooltip title={t('edit.heading')}>{iconButton}</Tooltip>;
};

export default Edit;
