import { Translation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { SliderThumb } from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
import { RiskChip } from 'components/chips/risk-chip';
import { ColorfulSlider } from 'components/controls/colorful-slider';
import { getNormalizeValue } from 'utils/helpers/math-util';

const RiskLevelSlider = ({ marks, totalPoints, risk, allRiskLevels }) => {
  const theme = useTheme();

  if (!marks || marks.length === 0) {
    return null;
  }
  const maxPoints = marks[marks.length - 1];

  const totalPointsAlreadyInMasks = marks.includes(totalPoints);

  const marksWithTotalPoints = totalPointsAlreadyInMasks ? marks : [...marks, totalPoints];
  marksWithTotalPoints.sort((a, b) => a - b);

  const indexOfTotalPoints = marksWithTotalPoints.indexOf(totalPoints);

  function MyThumbComponent(props) {
    const { style } = props;
    // To make thumb size is the same old version
    // props.style.width = 14;
    style.height = style.width;

    if (risk) {
      style.backgroundColor = theme.custom?.riskChipComponent[risk.toLowerCase()]?.color;
      style.zIndex = 1;
    } else {
      style.backgroundColor = 'transparent';
    }
    const { children, ...other } = props;
    return (
      <SliderThumb
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      >
        {children}
      </SliderThumb>
    );
  }

  const railColors = allRiskLevels?.map(
    (r) => theme.custom?.riskChipComponent[r.toLowerCase()]?.color,
  );

  if (railColors && indexOfTotalPoints > 0 && !totalPointsAlreadyInMasks) {
    railColors.splice(indexOfTotalPoints, 0, railColors[indexOfTotalPoints - 1]);
  }

  return (
    <Translation>
      {(t) => (
        <Box
          left={0}
          top={20}
          position="relative"
          className="pr-3"
          data-testid="risk-level-colorfull-slider"
        >
          <ColorfulSlider
            min={marksWithTotalPoints[0]}
            max={maxPoints}
            defaultValue={totalPoints}
            marks={marksWithTotalPoints.map((value) => {
              const showLabel =
                (value === totalPoints && totalPoints <= maxPoints) ||
                (value === maxPoints && getNormalizeValue(totalPoints, maxPoints, 100) < 80);
              return {
                value,
                label: showLabel ? (
                  <Grid container justifyContent="center">
                    {value === totalPoints && (
                      <Grid className="top-label">
                        <RiskChip
                          risk={risk}
                          label={t(`riskCategory.${risk?.toLowerCase()}.heading`)}
                          size="small"
                        />
                      </Grid>
                    )}
                    {t('configuration:customerRiskRating.riskFactor.points.heading', {
                      points: value,
                    })}
                  </Grid>
                ) : (
                  ''
                ),
              };
            })}
            disabled
            components={{
              Thumb: MyThumbComponent,
            }}
            railColors={railColors}
          />
        </Box>
      )}
    </Translation>
  );
};

export { RiskLevelSlider };
