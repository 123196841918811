import { CUSTOMER_RISK_RATING_CONFIG_ACTIONS } from 'middleware/action-types';

const customerRiskRatingConfigForIndividualReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.data:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.error:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.delete.error: {
      return {
        ...state,
      };
    }

    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.success:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.success:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.createRiskFactor.success:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.deleteRiskFactor.success: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.delete.data: {
      return {};
    }

    default:
      return state;
  }
};

export default customerRiskRatingConfigForIndividualReducer;
