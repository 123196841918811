import { FormControl, FormHelperText, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

const Input = ({
  name,
  id,
  label,
  value,
  type,
  size = 'small',
  error = '',
  onChange,
  multiline,
  placeholder,
  shrink,
  endAdornment,
  startAdornment,
  disabled,
  customValidation,
  ...other
}) => {
  const [validationErrorText, setValidationErrorText] = useState(error);

  useEffect(() => setValidationErrorText(error), [error]);

  const internalOnChange = (event) => {
    let resultValidatedErrorText = '';
    if (customValidation) {
      const { value } = event.target;
      const message = customValidation(value);
      if (message !== '') {
        resultValidatedErrorText = message;
      }
    }
    // noinspection JSCheckFunctionSignatures
    setValidationErrorText(error || resultValidatedErrorText);
    onChange(event);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormControl fullWidth size={size} {...(validationErrorText && { error: true })}>
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        label={label}
        name={name}
        size={size}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        type={type}
        multiline={multiline}
        value={value}
        onChange={internalOnChange}
        InputProps={{
          ...other.InputProps,
          endAdornment,
          startAdornment,
        }}
        InputLabelProps={{
          ...other.InputLabelProps,
          shrink,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(validationErrorText && { error: true })}
      />
      {validationErrorText && <FormHelperText>{validationErrorText}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
