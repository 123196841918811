import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class RiskyWordsV2ConfigAPICommunicator extends APICommunicator {
  fetchConfig() {
    const apiUrl = API_URLS.config.riskyWordsV2.fetch.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );

    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateConfig(data) {
    const apiUrl = API_URLS.config.riskyWordsV2.upsert.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.postJSONForTenant(
      apiUrl,
      { instances: data },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteConfig(id) {
    const apiUrl = API_URLS.config.riskyWordsV2.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.id, id);

    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default RiskyWordsV2ConfigAPICommunicator;
