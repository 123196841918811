import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

import { FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/filters-instantiable-list/middleware/action-types';
import {
  activateFiltersInstantiableListConfigError,
  activateFiltersInstantiableListConfigSuccess,
  deleteFiltersInstantiableListConfigError,
  deleteFiltersInstantiableListConfigSuccess,
  getFiltersInstantiableListConfigError,
  getFiltersInstantiableListConfigSuccess,
  updateFiltersInstantiableListConfigError,
  updateFiltersInstantiableListConfigSuccess,
} from 'modules/configuration/list-manager/filters-instantiable-list/middleware/actions/filters-instantiable-list-config';
import FiltersInstantiableListApiCommunicator from 'modules/configuration/list-manager/filters-instantiable-list/middleware/services/filters-instantiable-list-api';
import { put, takeLatest } from 'redux-saga/effects';
import TenantManagementService from 'services/tenant/tenant-management-service';

const apiCommunicator = new FiltersInstantiableListApiCommunicator();

/**
 * Fetch customer occupation files action
 */
export function* getFiltersInstantiableConfigListBegin(action) {
  try {
    yield put(startProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));

    const json = yield apiCommunicator.fetchFiltersInstantiableListConfig(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getFiltersInstantiableListConfigSuccess(json));

    yield put(stopProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getFiltersInstantiableListConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch customer occupation files action watcher
 */
export function* getFiltersInstantiableListConfigWatcher() {
  yield takeLatest(
    FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch,
    getFiltersInstantiableConfigListBegin,
  );
}

/**
 *  Update active filters file version action
 */
export function* updateActiveFiltersInstantiableListConfigVersionBegin(action) {
  try {
    yield put(startProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));

    const json = yield apiCommunicator.updateActiveFiltersInstantiableListConfigVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateFiltersInstantiableListConfigSuccess(json));

    yield put(stopProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateFiltersInstantiableListConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active customer occupation file version action watcher
 */
export function* updateActiveFiltersInstantiableListConfigVersionWatcher() {
  yield takeLatest(
    FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send,
    updateActiveFiltersInstantiableListConfigVersionBegin,
  );
}

function* updateFiltersInstantiableConfigListConfigBegin(action) {
  yield put(startProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data));
  try {
    const json = yield apiCommunicator.updateFiltersInstantiableListConfig(action.payload);
    yield put(updateFiltersInstantiableListConfigSuccess(json));
  } catch (error) {
    yield put(updateFiltersInstantiableListConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data));
}

export function* updateFiltersInstantiableConfigListConfigWatcher() {
  yield takeLatest(
    FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data,
    updateFiltersInstantiableConfigListConfigBegin,
  );
}

function* deleteFiltersInstantiableConfigListConfigBegin(action) {
  yield put(startProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data));

  try {
    const json = yield apiCommunicator.deleteFiltersInstantiableListConfig(action.payload);
    yield put(deleteFiltersInstantiableListConfigSuccess(json));
  } catch (error) {
    yield put(deleteFiltersInstantiableListConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data));
}

export function* deleteFiltersInstantiableConfigListConfigWatcher() {
  yield takeLatest(
    FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data,
    deleteFiltersInstantiableConfigListConfigBegin,
  );
}
