export const META_DATA = {
  customerRiskRating: {
    key: 'CUSTOMER_RISK_RATING',
  },
  ruleInstance: {
    key: 'rule-instance',
    referenceAccountV2: {
      key: 'reference_account_v2',
    },
    expectedCustomerValue: {
      key: 'expected_customer_value',
    },
  },
};
