import { Grid, Typography } from '@mui/material';
import { Translation } from 'react-i18next';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { RiskChip } from 'components/chips/risk-chip';

const OccupationDetails = (props) => {
  const { scoreData = {}, maxPoints, popoverMode } = props;

  const originalData = scoreData?.ORIGINAL_DATA;
  const calculatedRiskLevel = scoreData?.CALCULATED_OCCUPATION_RISK_LEVEL?.toLowerCase();

  return (
    <Translation>
      {(t) => (
        <ScoreDetailsAccordion
          summaryComponent={
            <WheelPointsWithHeadline
              size={popoverMode ? 'small' : 'medium'}
              points={scoreData?.CALCULATED_POINTS ?? 0}
              maxPoints={maxPoints}
              headline={t('case:userPopover.riskRating.occupation.heading')}
            />
          }
          highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
          detailsComponent={
            <Grid container justifyContent="space-between" className="pb-1">
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {originalData}
                </Typography>
              </Grid>
              <Grid item>
                <RiskChip
                  risk={calculatedRiskLevel}
                  size="small"
                  label={t(`riskCategory.${calculatedRiskLevel}.heading`)}
                  tooltip={t('case:userPopover.riskRating.occupation.subheading')}
                />
              </Grid>
            </Grid>
          }
        />
      )}
    </Translation>
  );
};

export { OccupationDetails };
