class RuleCategoryManager {
  constructor(communicator) {
    this.communicator = communicator;
  }

  /**
   * Returns: { ruleCategories: [ruleCategory, ruleCategory] }
   */
  getRuleCategories() {
    return this.communicator.getRuleCategories();
  }
}

export default RuleCategoryManager;
