import { isEmpty } from 'lodash';
import { isOpenCase } from 'utils/helpers/cases/case-status-util';
import { isGroupCase, getSelectedCaseIndexFromHash } from 'utils/helpers/group-case-util';
import SessionStorageService from 'services/session-storage-service';

const isGroupingActiveOnAssignment = (
  stopGroupingInfo,
  isStopManualEnabled,
  isAssigned,
  caseStatus,
) => {
  if (!isStopManualEnabled && !isAssigned && isEmpty(stopGroupingInfo) && isOpenCase(caseStatus)) {
    return true;
  }
  return false;
};

const isGroupingActive = (stopGroupingInfo, isStopManualEnabled, isAssigned, caseStatus) => {
  if (
    (isOpenCase(caseStatus) && isEmpty(stopGroupingInfo)) ||
    isGroupingActiveOnAssignment(stopGroupingInfo, isStopManualEnabled, isAssigned, caseStatus)
  ) {
    return true;
  }
  return false;
};

const canStopGroup = (stopGroupingInfo, isStopManualEnabled, assignedName, caseStatus) => {
  if (
    isGroupingActive(stopGroupingInfo, isStopManualEnabled, assignedName, caseStatus) &&
    isStopManualEnabled
  ) {
    return true;
  }
  return false;
};

export const identifyGroupCase = (tx1, tx2) => {
  if (isGroupCase(tx1)) {
    return tx1;
  }

  if (isGroupCase(tx2)) {
    return tx2;
  }

  return undefined;
};

export const getSelectedCaseIndex = () => {
  const sessionStorageService = new SessionStorageService();

  let caseIndex = getSelectedCaseIndexFromHash(location.hash);

  if (Number.isNaN(caseIndex)) {
    caseIndex = parseInt(sessionStorageService.getSelectedCaseIndex(), 10) || 0;
  }
  return caseIndex;
};

export const getDisplayCase = (forwardingCases, masterCase) => {
  const forwardingCasesCount = forwardingCases?.length;
  const selectedCaseIndex = getSelectedCaseIndex();
  const selectedForwardingCase =
    selectedCaseIndex < forwardingCasesCount ? forwardingCases[selectedCaseIndex] : undefined;
  return selectedForwardingCase || masterCase;
};

export { isGroupingActiveOnAssignment, isGroupingActive, canStopGroup };
