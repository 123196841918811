import { Button as MuiButton } from '@mui/material';

const Button = ({
  text,
  size = 'medium',
  color = 'inherit',
  variant = 'text',
  onClick,
  disableElevation = true,
  dataTestIdPrefix = '',
  disabled = false,
  ...other
}) => (
  <MuiButton
    disabled={disabled}
    variant={variant}
    size={size}
    disableElevation={disableElevation}
    color={color}
    onClick={onClick}
    data-testid={`${dataTestIdPrefix}-button`}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...other}
  >
    {text}
  </MuiButton>
);
export default Button;
