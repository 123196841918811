import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import {
  ApiKey,
  ApiKeyConfigState,
} from 'modules/configuration/api-key-config/middleware/reducers/api-key-config-reducer';

interface AddNewApiKeyRequest {
  tenantId: string;
  apiKey: string;
  keyName: string;
}

class ApiKeyConfigAPICommunicator extends APICommunicator {
  apiUrl = API_URLS.tenantService.apiKey;

  fetchConfig(): Promise<ApiKeyConfigState> {
    return this.getJSONForTenant(this.apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  createApiKey(keyName: string, apiKey?: string): Promise<ApiKey> {
    return this.putJSONForTenant(
      this.apiUrl,
      {
        tenantId: TenantManagementService.getActiveTenantId(),
        apiKey,
        keyName,
      } as AddNewApiKeyRequest,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteApiKey(keyName?: string): Promise<ApiKeyConfigState> {
    const url = keyName === undefined ? `${this.apiUrl}` : `${this.apiUrl}/${keyName}`;
    return this.deleteJSONForTenant(url, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default ApiKeyConfigAPICommunicator;
