import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';

export const addCustomerCaseNote = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.data,
  payload,
});

export const addCustomerCaseNoteSuccess = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.success,
  payload,
});

export const addCustomerCaseNoteError = () => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.error,
});

export const downloadAttachmentNote = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data,
  payload,
});

export const downloadAttachmentNoteSuccess = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.success,
  payload,
});

export const downloadAttachmentNoteError = () => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.error,
});
