import { PRODUCT_TYPE_LIST_ACTIONS } from 'middleware/action-types';

export const getProductTypeFileListIndividual = (tenantId) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchIndividual,
  payload: { tenantId },
});
export const getProductTypeFileListOrganization = (tenantId) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchOrganization,
  payload: { tenantId },
});

export const getProductTypeFileListIndividualSuccess = (payload) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.fileList.individualSuccess,
  payload,
});
export const getProductTypeFileListOrganizationSuccess = (payload) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.fileList.organizationSuccess,
  payload,
});

export const getProductTypeFileListIndividualError = () => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.fileList.individualError,
});

export const getProductTypeFileListOrganizationError = () => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.fileList.organizationError,
});

export const activateProductTypeListIndividual = (version) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendIndividual,
  payload: { version },
});

export const activateProductTypeListOrganization = (version) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendOrganization,
  payload: { version },
});

export const activateProductTypeListIndividualSuccess = (payload) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.activateVersion.individualSuccess,
  payload,
});
export const activateProductTypeListOrganizationSuccess = (payload) => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.activateVersion.organizationSuccess,
  payload,
});

export const activateProductTypeListIndividualError = () => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.activateVersion.individualError,
});
export const activateProductTypeListOrganizationError = () => ({
  type: PRODUCT_TYPE_LIST_ACTIONS.activateVersion.organizationError,
});
