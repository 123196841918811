import { ACCOUNT_BASED_FREQUENCY_ACTIONS } from 'modules/configuration/rule-manager/account-based-frequency-increase-decrease/middleware/action-types';
import { convertMultiplierToReadablePercentage } from 'modules/configuration/rule-manager/common/helpers/util';

const AccountBasedFrequencyIncreaseDecreaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.fetch.success:
    case ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.update.success:
    case ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.delete.success: {
      return {
        ...state,
        instances: convertMultiplierToReadablePercentage(action),
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default AccountBasedFrequencyIncreaseDecreaseReducer;
