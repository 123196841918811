import { AssessmentScoreDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/assessment-score-details';
import { getConfigKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';

const AdverseMediaDetails = (props) => {
  const configKeys = getConfigKeys();

  return (
    <AssessmentScoreDetails
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      configKey={configKeys.adverseMediaScore}
      headlineKey="adverseMedia"
    />
  );
};

export { AdverseMediaDetails };
