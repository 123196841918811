import { CounterPartyFrequencyConfigActions } from 'modules/configuration/rule-manager/counter-party-frequency/middleware/action-types';

const CounterPartyFrequencyReducer = (state = {}, action) => {
  switch (action.type) {
    case CounterPartyFrequencyConfigActions.GET_SUCCESS:
    case CounterPartyFrequencyConfigActions.UPDATE_SUCCESS:
    case CounterPartyFrequencyConfigActions.DELETE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default CounterPartyFrequencyReducer;
