import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class MccListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('MCC List API Communicator instance is required.');
    }
  }

  /**
   * Checking if incoming JSON is correct
   */
  validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.MCC_LIST_DATA_INVALID);
  }

  /**
   * Fetch MCC file list
   */
  getMccFileList(tenantId) {
    return this.communicator
      .fetchMccFilesList(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  /**
   * Update active MCC file version
   */
  updateActiveMccListVersion(tenantId, payload) {
    return this.communicator.updateActiveMccListVersion(tenantId, payload.version);
  }
}

export default MccListManager;
