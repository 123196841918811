import { Translation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';

const OrganizationDetails = ({ scoreData = {}, maxPoints, popoverMode }) => {
  const metaDataKeys = getMetaDataKeys();

  return (
    <Translation>
      {(t) => (
        <ScoreDetailsAccordion
          summaryComponent={
            <WheelPointsWithHeadline
              size={popoverMode ? 'small' : 'medium'}
              points={scoreData?.[metaDataKeys.calculatedPoints] ?? 0}
              maxPoints={maxPoints}
              headline={t('case:userPopover.riskRating.organizationType.heading')}
            />
          }
          highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
          detailsComponent={
            <Typography variant="body2" color="textSecondary">
              {scoreData?.[metaDataKeys.originalData]}
            </Typography>
          }
        />
      )}
    </Translation>
  );
};

export { OrganizationDetails };
