import { CUSTOMER_RISK_RATING_CONFIG_ACTIONS } from 'middleware/action-types';

export const getCustomerRiskRatingConfig = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.data,
  payload,
});

export const getCustomerRiskRatingConfigSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.success,
  payload,
});

export const getCustomerRiskRatingConfigError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.error,
});

export const updateCustomerRiskRatingConfig = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.data,
  payload,
});

export const updateCustomerRiskRatingConfigSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.success,
  payload,
});

export const updateCustomerRiskRatingError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.error,
});

export const requestForUpdateCustomerRiskRatingConfig = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.requestForUpdate.data,
  payload,
});

export const deleteCustomerRiskRatingConfig = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.delete.data,
});

export const deleteCustomerRiskRatingError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.delete.error,
});

export const createRiskFactor = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.data,
  payload,
});

export const createRiskFactorSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.success,
  payload,
});

export const createRiskFactorError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.error,
});

export const getCustomerRiskRatingConfigForIndividual = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.data,
  payload,
});

export const getCustomerRiskRatingConfigForIndividualSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.success,
  payload,
});

export const getCustomerRiskRatingConfigForIndividualError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.error,
});

export const deleteCustomerRiskRatingConfigForIndividual = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.delete.data,
});

export const deleteCustomerRiskRatingForIndividualError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.delete.error,
});

export const updateCustomerRiskRatingConfigForIndividual = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.data,
  payload,
});

export const updateCustomerRiskRatingConfigForIndividualSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.success,
  payload,
});

export const updateCustomerRiskRatingForIndividualError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.error,
});

export const requestForUpdateCustomerRiskRatingConfigForIndividual = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.requestForUpdate.data,
  payload,
});

export const createRiskFactorForIndividualSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.createRiskFactor.success,
  payload,
});

export const createRiskFactorForIndividualError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.createRiskFactor.error,
});

export const deleteRiskRatingFactorForOrganization = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.deleteRiskFactor.data,
  payload,
});

export const deleteRiskRatingFactorForOrganizationSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.deleteRiskFactor.success,
  payload,
});

export const deleteRiskRatingFactorForOrganizationError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.deleteRiskFactor.error,
});

export const deleteRiskRatingFactorForIndividual = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.deleteRiskFactor.data,
  payload,
});

export const deleteRiskRatingFactorForIndividualSuccess = (payload) => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.deleteRiskFactor.success,
  payload,
});

export const deleteRiskRatingFactorForIndividualError = () => ({
  type: CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.deleteRiskFactor.error,
});
