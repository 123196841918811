/**
 * DEPRECATED
 * please add decision names to rule-manager.js constant to corresponding rule
 */
const DecisionName = {
  PEPSANCTION_DECISION: 'PepSanctionDecision',
  COUNTRY_RISK_DECISION: 'CountryRiskDecision',
  COUNTRY_RISK_V2_DECISION: 'CountryRiskV2Decision',
  VOLUME_RISK_DECISION: 'VolumeRiskDecision',
  VOLUME_RISK_V2_DECISION: 'VolumeRiskV2Decision',
  USAGE_RISK_DECISION: 'UsageRiskDecision',
  ACCOUNT_BLACKLISTED_DECISION: 'AccountBlacklistedDecision',
  MONEY_MULE_DECISION: 'MoneyMuleDecision',
  PHISHING_DECISION: 'PhishingDecision',
  FAN_IN_DECISION: 'FanInDecision',
  CASH_TRANSACTION_DECISION: 'CashTransactionDecision',
  REFERENCE_ACCOUNT_DECISION: 'ReferenceAccountDecision',
  PASSTHROUGH_DECISION: 'PassthroughDecision',
  SMURFING_DECISION: 'SmurfingDecision',
  AI_DECISION: 'AIDecision',
  ANOMALY_DECISION: 'AnomalyDecision',
  ROUND_AMOUNT_DECISION: 'RoundAmountDecision',
  UNUSUAL_COUNTER_PARTY_FREQUENCY_DECISION: 'UnusualCounterPartyBasedFrequencyDecision',
  ACCOUNT_BASED_FREQUENCY_DECISION: 'AccountBasedFrequencyDecision',
  DORMANT_ACCOUNT_ACTIVATION_DECISION: 'DormantAccountActivationDecision',
  CROSS_BORDER_VOLUME_DECISION: 'CrossBorderVolumeDecision',
  VOLUME_INCREASE_DECREASE_DECISION: 'VolumeIncreaseDecreaseDecision',
  SUSPICIOUS_AVG_TICKET_SIZE_DECISION: 'SuspiciousAvgTicketSizeDecision',
  ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE_DECISION:
    'AccountBasedFrequencyIncreaseDecreaseDecision',
  ROUND_AMOUNT_RATIO_INCREASE_DECREASE_DECISION: 'RoundAmountRatioIncreaseDecreaseDecision',
  PEPSANCTION_INSTANTIABLE_DECISION: 'PepSanctionInstantiableDecision',
  BLACKLIST_DECISION: 'BlacklistDecision',
};

export default DecisionName;
