import { RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/action-types';

export const getRiskyWordsInstantiableListConfig = (tenantId) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getRiskyWordsInstantiableListConfigSuccess = (payload) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.success,
  payload,
});

export const getRiskyWordsInstantiableListConfigError = () => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.error,
});
export const updateRiskyWordsInstantiableListConfig = (payload) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data,
  payload,
});

export const updateRiskyWordsInstantiableListConfigSuccess = (payload) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.success,
  payload,
});

export const updateRiskyWordsInstantiableListConfigError = () => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.error,
});
export const deleteRiskyWordsInstantiableListConfig = (payload) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data,
  payload,
});

export const deleteRiskyWordsInstantiableListConfigSuccess = (payload) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.success,
  payload,
});

export const deleteRiskyWordsInstantiableListConfigError = (payload) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.error,
  payload,
});

export const activateRiskyWordsInstantiableListConfig = (version) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateRiskyWordsInstantiableListConfigSuccess = (payload) => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.success,
  payload,
});

export const activateRiskyWordsInstantiableListConfigError = () => ({
  type: RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.error,
});
