import noImage from 'assets/images/flags/no_img.png';
import caf from 'assets/images/flags/ca.png';
import cnf from 'assets/images/flags/cn.png';
import euf from 'assets/images/flags/eu.png';
import usf from 'assets/images/flags/us.png';
import auf from 'assets/images/flags/au.png';
import gbf from 'assets/images/flags/gb.png';
import jpf from 'assets/images/flags/jp.png';
import ilf from 'assets/images/flags/il.png';
import hkf from 'assets/images/flags/hk.png';
import chf from 'assets/images/flags/ch.png';
import uaf from 'assets/images/flags/ua.png';
import inf from 'assets/images/flags/in.png';

import ruf from 'assets/images/flags/ru.png';
import idf from 'assets/images/flags/id.png';
import aff from 'assets/images/flags/af.png';
import atf from 'assets/images/flags/at.png';
import axf from 'assets/images/flags/ax.png';
import alf from 'assets/images/flags/al.png';
import dzf from 'assets/images/flags/dz.png';
import asf from 'assets/images/flags/as.png';
import adf from 'assets/images/flags/ad.png';
import aof from 'assets/images/flags/ao.png';
import aif from 'assets/images/flags/ai.png';
import aqf from 'assets/images/flags/aq.png';

import agf from 'assets/images/flags/ag.png';
import arf from 'assets/images/flags/ar.png';
import amf from 'assets/images/flags/am.png';
import awf from 'assets/images/flags/aw.png';
import azf from 'assets/images/flags/az.png';
import bsf from 'assets/images/flags/bs.png';
import bhf from 'assets/images/flags/bh.png';
import bdf from 'assets/images/flags/bd.png';
import bbf from 'assets/images/flags/bb.png';
import byf from 'assets/images/flags/by.png';
import bef from 'assets/images/flags/be.png';
import bzf from 'assets/images/flags/bz.png';

import bjf from 'assets/images/flags/bj.png';
import bmf from 'assets/images/flags/bm.png';
import btf from 'assets/images/flags/bt.png';
import bof from 'assets/images/flags/bo.png';
import bqf from 'assets/images/flags/bq.png';
import baf from 'assets/images/flags/ba.png';
import bwf from 'assets/images/flags/bw.png';
import bvf from 'assets/images/flags/bv.png';
import brf from 'assets/images/flags/br.png';
import iof from 'assets/images/flags/io.png';
import bnf from 'assets/images/flags/bn.png';
import bgf from 'assets/images/flags/bg.png';

import bff from 'assets/images/flags/bf.png';
import bif from 'assets/images/flags/bi.png';
import cvf from 'assets/images/flags/cv.png';
import khf from 'assets/images/flags/kh.png';
import cmf from 'assets/images/flags/cm.png';
import kyf from 'assets/images/flags/ky.png';
import cff from 'assets/images/flags/cf.png';
import tdf from 'assets/images/flags/td.png';
import clf from 'assets/images/flags/cl.png';
import cxf from 'assets/images/flags/cx.png';
import ccf from 'assets/images/flags/cc.png';
import cof from 'assets/images/flags/co.png';

import kmf from 'assets/images/flags/km.png';
import cgf from 'assets/images/flags/cg.png';
import cdf from 'assets/images/flags/cd.png';
import ckf from 'assets/images/flags/ck.png';
import crf from 'assets/images/flags/cr.png';
import cif from 'assets/images/flags/ci.png';
import hrf from 'assets/images/flags/hr.png';
import cuf from 'assets/images/flags/cu.png';
import cwf from 'assets/images/flags/cw.png';
import cyf from 'assets/images/flags/cy.png';
import czf from 'assets/images/flags/cz.png';
import dkf from 'assets/images/flags/dk.png';

import djf from 'assets/images/flags/dj.png';
import dmf from 'assets/images/flags/dm.png';
import dof from 'assets/images/flags/do.png';
import ecf from 'assets/images/flags/ec.png';
import egf from 'assets/images/flags/eg.png';
import svf from 'assets/images/flags/sv.png';
import gqf from 'assets/images/flags/gq.png';
import erf from 'assets/images/flags/er.png';
import eef from 'assets/images/flags/ee.png';
import etf from 'assets/images/flags/et.png';
import fkf from 'assets/images/flags/fk.png';
import fof from 'assets/images/flags/fo.png';

import fjf from 'assets/images/flags/fj.png';
import fif from 'assets/images/flags/fi.png';
import frf from 'assets/images/flags/fr.png';
import gff from 'assets/images/flags/gf.png';
import pff from 'assets/images/flags/pf.png';
import tff from 'assets/images/flags/tf.png';
import gaf from 'assets/images/flags/ga.png';
import gmf from 'assets/images/flags/gm.png';
import gef from 'assets/images/flags/ge.png';
import def from 'assets/images/flags/de.png';
import ghf from 'assets/images/flags/gh.png';
import gif from 'assets/images/flags/gi.png';

import grf from 'assets/images/flags/gr.png';
import glf from 'assets/images/flags/gl.png';
import gdf from 'assets/images/flags/gd.png';
import gpf from 'assets/images/flags/gp.png';
import guf from 'assets/images/flags/gu.png';
import gtf from 'assets/images/flags/gt.png';
import ggf from 'assets/images/flags/gg.png';
import gnf from 'assets/images/flags/gn.png';
import gwf from 'assets/images/flags/gw.png';
import gyf from 'assets/images/flags/gy.png';
import htf from 'assets/images/flags/ht.png';
import hmf from 'assets/images/flags/hm.png';

import vaf from 'assets/images/flags/va.png';
import hnf from 'assets/images/flags/hn.png';
import huf from 'assets/images/flags/hu.png';
import isf from 'assets/images/flags/is.png';
import irf from 'assets/images/flags/ir.png';
import iqf from 'assets/images/flags/iq.png';
import ief from 'assets/images/flags/ie.png';
import imf from 'assets/images/flags/im.png';
import itf from 'assets/images/flags/it.png';
import jmf from 'assets/images/flags/jm.png';
import jef from 'assets/images/flags/je.png';
import jof from 'assets/images/flags/jo.png';

import kzf from 'assets/images/flags/kz.png';
import kef from 'assets/images/flags/ke.png';
import kif from 'assets/images/flags/ki.png';
import kpf from 'assets/images/flags/kp.png';
import krf from 'assets/images/flags/kr.png';
import kwf from 'assets/images/flags/kw.png';
import kgf from 'assets/images/flags/kg.png';
import laf from 'assets/images/flags/la.png';
import lvf from 'assets/images/flags/lv.png';
import lbf from 'assets/images/flags/lb.png';
import lsf from 'assets/images/flags/ls.png';
import lrf from 'assets/images/flags/lr.png';

import lyf from 'assets/images/flags/ly.png';
import lif from 'assets/images/flags/li.png';
import ltf from 'assets/images/flags/lt.png';
import luf from 'assets/images/flags/lu.png';
import mof from 'assets/images/flags/mo.png';
import mkf from 'assets/images/flags/mk.png';
import mgf from 'assets/images/flags/mg.png';
import mwf from 'assets/images/flags/mw.png';
import myf from 'assets/images/flags/my.png';
import mvf from 'assets/images/flags/mv.png';
import mlf from 'assets/images/flags/ml.png';
import mtf from 'assets/images/flags/mt.png';

import mhf from 'assets/images/flags/mh.png';
import mqf from 'assets/images/flags/mq.png';
import mrf from 'assets/images/flags/mr.png';
import muf from 'assets/images/flags/mu.png';
import nlf from 'assets/images/flags/nl.png';
import nof from 'assets/images/flags/no.png';
import plf from 'assets/images/flags/pl.png';
import ptf from 'assets/images/flags/pt.png';
import rof from 'assets/images/flags/ro.png';
import skf from 'assets/images/flags/sk.png';
import sif from 'assets/images/flags/si.png';
import esf from 'assets/images/flags/es.png';

import sef from 'assets/images/flags/se.png';
import vef from 'assets/images/flags/ve.png';
import ytf from 'assets/images/flags/yt.png';
import mxf from 'assets/images/flags/mx.png';
import fmf from 'assets/images/flags/fm.png';
import mdf from 'assets/images/flags/md.png';
import mcf from 'assets/images/flags/mc.png';
import mnf from 'assets/images/flags/mn.png';
import mef from 'assets/images/flags/me.png';
import msf from 'assets/images/flags/ms.png';
import maf from 'assets/images/flags/ma.png';
import mzf from 'assets/images/flags/mz.png';

import mmf from 'assets/images/flags/mm.png';
import naf from 'assets/images/flags/na.png';
import nrf from 'assets/images/flags/nr.png';
import npf from 'assets/images/flags/np.png';
import ncf from 'assets/images/flags/nc.png';
import nzf from 'assets/images/flags/nz.png';
import nif from 'assets/images/flags/ni.png';
import nef from 'assets/images/flags/ne.png';
import ngf from 'assets/images/flags/ng.png';
import nuf from 'assets/images/flags/nu.png';
import nff from 'assets/images/flags/nf.png';
import mpf from 'assets/images/flags/mp.png';

import omf from 'assets/images/flags/om.png';
import pkf from 'assets/images/flags/pk.png';
import pwf from 'assets/images/flags/pw.png';
import psf from 'assets/images/flags/ps.png';
import paf from 'assets/images/flags/pa.png';
import pgf from 'assets/images/flags/pg.png';
import pyf from 'assets/images/flags/py.png';
import pef from 'assets/images/flags/pe.png';
import phf from 'assets/images/flags/ph.png';
import pnf from 'assets/images/flags/pn.png';
import prf from 'assets/images/flags/pr.png';
import qaf from 'assets/images/flags/qa.png';

import ref from 'assets/images/flags/re.png';
import rwf from 'assets/images/flags/rw.png';
import blf from 'assets/images/flags/bl.png';
import shf from 'assets/images/flags/sh.png';
import knf from 'assets/images/flags/kn.png';
import lcf from 'assets/images/flags/lc.png';
import mff from 'assets/images/flags/mf.png';
import pmf from 'assets/images/flags/pm.png';
import vcf from 'assets/images/flags/vc.png';
import wsf from 'assets/images/flags/ws.png';
import smf from 'assets/images/flags/sm.png';
import stf from 'assets/images/flags/st.png';

import saf from 'assets/images/flags/sa.png';
import snf from 'assets/images/flags/sn.png';
import rsf from 'assets/images/flags/rs.png';
import scf from 'assets/images/flags/sc.png';
import slf from 'assets/images/flags/sl.png';
import sgf from 'assets/images/flags/sg.png';
import sxf from 'assets/images/flags/sx.png';
import sbf from 'assets/images/flags/sb.png';
import sof from 'assets/images/flags/so.png';
import zaf from 'assets/images/flags/za.png';
import gsf from 'assets/images/flags/gs.png';
import ssf from 'assets/images/flags/ss.png';

import lkf from 'assets/images/flags/lk.png';
import sdf from 'assets/images/flags/sd.png';
import srf from 'assets/images/flags/sr.png';
import sjf from 'assets/images/flags/sj.png';
import szf from 'assets/images/flags/sz.png';
import syf from 'assets/images/flags/sy.png';
import twf from 'assets/images/flags/tw.png';
import tjf from 'assets/images/flags/tj.png';
import tzf from 'assets/images/flags/tz.png';
import thf from 'assets/images/flags/th.png';
import tlf from 'assets/images/flags/tl.png';
import tgf from 'assets/images/flags/tg.png';

import tkf from 'assets/images/flags/tk.png';
import tof from 'assets/images/flags/to.png';
import ttf from 'assets/images/flags/tt.png';
import tnf from 'assets/images/flags/tn.png';
import trf from 'assets/images/flags/tr.png';
import tmf from 'assets/images/flags/tm.png';
import tcf from 'assets/images/flags/tc.png';
import tvf from 'assets/images/flags/tv.png';
import ugf from 'assets/images/flags/ug.png';
import aef from 'assets/images/flags/ae.png';
import umf from 'assets/images/flags/um.png';
import uyf from 'assets/images/flags/uy.png';

import uzf from 'assets/images/flags/uz.png';
import vuf from 'assets/images/flags/vu.png';
import vnf from 'assets/images/flags/vn.png';
import vgf from 'assets/images/flags/vg.png';
import vif from 'assets/images/flags/vi.png';
import wff from 'assets/images/flags/wf.png';
import ehf from 'assets/images/flags/eh.png';
import yef from 'assets/images/flags/ye.png';
import zmf from 'assets/images/flags/zm.png';
import zwf from 'assets/images/flags/zw.png';
import xkf from 'assets/images/flags/xk.png';

import pacificf from 'assets/images/flags/pacific.png';
import asiaf from 'assets/images/flags/asia.png';
import globalf from 'assets/images/flags/global.png';
import africaf from 'assets/images/flags/africa.png';
import latinamericaf from 'assets/images/flags/latin_american.png';
import caribbeanf from 'assets/images/flags/caribbean.png';
import middleeastf from 'assets/images/flags/middle_east.png';
import i18next from 'i18next';

const CountryFlags: { [key: string]: string } = {
  caf,
  cnf,
  euf,
  usf,
  auf,
  gbf,
  jpf,
  ilf,
  hkf,
  chf,
  uaf,
  inf,
  ruf,
  idf,
  aff,
  atf,
  axf,
  alf,
  dzf,
  asf,
  adf,
  aof,
  aif,
  aqf,
  agf,
  arf,
  amf,
  awf,
  azf,
  bsf,
  bhf,
  bdf,
  bbf,
  byf,
  bef,
  bzf,
  bjf,
  bmf,
  btf,
  bof,
  bqf,
  baf,
  bwf,
  bvf,
  brf,
  iof,
  bnf,
  bgf,
  bff,
  bif,
  cvf,
  khf,
  cmf,
  kyf,
  cff,
  tdf,
  clf,
  cxf,
  ccf,
  cof,
  kmf,
  cgf,
  cdf,
  ckf,
  crf,
  cif,
  hrf,
  cuf,
  cwf,
  cyf,
  czf,
  dkf,
  djf,
  dmf,
  dof,
  ecf,
  egf,
  svf,
  gqf,
  erf,
  eef,
  etf,
  fkf,
  fof,
  fjf,
  fif,
  frf,
  gff,
  pff,
  tff,
  gaf,
  gmf,
  gef,
  def,
  ghf,
  gif,
  grf,
  glf,
  gdf,
  gpf,
  guf,
  gtf,
  ggf,
  gnf,
  gwf,
  gyf,
  htf,
  hmf,
  vaf,
  hnf,
  huf,
  isf,
  irf,
  iqf,
  ief,
  imf,
  itf,
  jmf,
  jef,
  jof,
  kzf,
  kef,
  kif,
  kpf,
  krf,
  kwf,
  kgf,
  laf,
  lvf,
  lbf,
  lsf,
  lrf,
  lyf,
  lif,
  ltf,
  luf,
  mof,
  mkf,
  mgf,
  mwf,
  myf,
  mvf,
  mlf,
  mtf,
  mhf,
  mqf,
  mrf,
  muf,
  nlf,
  nof,
  plf,
  ptf,
  rof,
  skf,
  sif,
  esf,
  sef,
  vef,
  ytf,
  mxf,
  fmf,
  mdf,
  mcf,
  mnf,
  mef,
  msf,
  maf,
  mzf,
  mmf,
  naf,
  nrf,
  npf,
  ncf,
  nzf,
  nif,
  nef,
  ngf,
  nuf,
  nff,
  mpf,
  omf,
  pkf,
  pwf,
  psf,
  paf,
  pgf,
  pyf,
  pef,
  phf,
  pnf,
  prf,
  qaf,
  ref,
  rwf,
  blf,
  shf,
  knf,
  lcf,
  mff,
  pmf,
  vcf,
  wsf,
  smf,
  stf,
  saf,
  snf,
  rsf,
  scf,
  slf,
  sgf,
  sxf,
  sbf,
  sof,
  zaf,
  gsf,
  ssf,
  lkf,
  sdf,
  srf,
  sjf,
  szf,
  syf,
  twf,
  tjf,
  tzf,
  thf,
  tlf,
  tgf,
  tkf,
  tof,
  ttf,
  tnf,
  trf,
  tmf,
  tcf,
  tvf,
  ugf,
  aef,
  umf,
  uyf,
  uzf,
  vuf,
  vnf,
  vgf,
  vif,
  wff,
  ehf,
  yef,
  zmf,
  zwf,
  xkf,
  pacificf,
  asiaf,
  globalf,
  africaf,
  latinamericaf,
  caribbeanf,
  middleeastf,
  noImage,
};

export const CUSTOM_LIST_CODE = 'customList';

export interface Country {
  code: string;
  text: string;
  highlight?: boolean;
  list?: string[];
  isNoneCountryCodeIso?: boolean;
}

export function getCountryCode(countryName: string) {
  let countryNameTrimmed: string;
  countryNameTrimmed = countryName?.trim().toLowerCase();
  if (countryNameTrimmed === 'uk') countryNameTrimmed = 'united kingdom';
  if (countryNameTrimmed === 'us') countryNameTrimmed = 'united states of america';
  if (countryNameTrimmed === 'eu') countryNameTrimmed = 'all eu/ewr';
  if (countryNameTrimmed === 'russia') countryNameTrimmed = 'russian federation';
  const countryList = [...i18next.t('mapping:countries.countryList')] as unknown as Country[];

  const country = countryList.find((c) => c.text.toLowerCase() === countryNameTrimmed);
  if (country !== undefined) {
    return country.code;
  }

  return '';
}

export function getCountryFlag(countryCode: string) {
  const countryCodeLowerCased = countryCode.toLowerCase().replace(/ /g, '');
  const country = CountryFlags[`${countryCodeLowerCased}f`];
  if (country !== undefined) {
    return country;
  }
  return noImage;
}

const modulo = (aNumStr: string, aDiv: number): number => {
  let tmp = '';
  let i;
  let r;
  for (i = 0; i < aNumStr.length; i++) {
    tmp += aNumStr.charAt(i);
    r = parseInt(tmp, 10) % aDiv;
    tmp = r.toString();
  }
  return parseInt(tmp, 10);
};

// https://stackoverflow.com/questions/21928083/iban-validation-check
const validIban = (value: string): boolean => {
  const rearrange = value.substring(4, value.length) + value.substring(0, 4);
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const alphaMap: { [key: string]: number } = {};
  const number: string[] = [];

  alphabet.forEach((item, index) => {
    alphaMap[item] = index + 10;
  });

  rearrange.split('').forEach((item, index) => {
    number[index] = alphaMap[item] ? alphaMap[item].toString() : item;
  });

  return modulo(number.join('').toString(), 97) === 1;
};

export function isCountryCodeValid(countryCode: string) {
  const countryList = [...i18next.t('mapping:countries.countryList')] as unknown as Country[];
  return countryList.find((c) => c.code === countryCode) !== undefined;
}

export function getCountryCodeFromIBAN(iban: string) {
  if (!iban) {
    return '';
  }

  if (!validIban(iban)) {
    return '';
  }

  const countryCode = iban && iban.length >= 2 ? iban.substring(0, 2) : iban;

  if (isCountryCodeValid(countryCode)) {
    return countryCode;
  }

  return '';
}

export function getCountryName(countryCode = '') {
  if (!countryCode) return '';
  const countryList = [...i18next.t('mapping:countries.countryList')] as unknown as Country[];
  const country = countryList.find((c) => c.code.toLowerCase() === countryCode.toLowerCase());
  return country ? country.text : countryCode;
}

/**
 * Get full country information (includes code, name, flag icon) from region (country code or name)
 */
export function convertToCountry(region?: string | null) {
  const countryList = [...i18next.t('mapping:countries.countryList')] as unknown as Country[];
  const regionLC =
    region !== null && region !== undefined ? region.toLowerCase() : CUSTOM_LIST_CODE.toLowerCase();

  const country = countryList.find(
    (country) => regionLC === country.code.toLowerCase() || regionLC === country.text.toLowerCase(),
  );
  if (country) {
    return {
      name: country.text === 'All EU/EWR' ? 'EU' : country.text,
      code: region ?? CUSTOM_LIST_CODE,
      flag: getCountryFlag(country.code),
    };
  }

  return {
    name: region || '',
    code: region || '',
    flag: noImage,
  };
}
