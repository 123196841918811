import { Chip } from '@mui/material';
import { getCustomerDetails } from 'middleware/actions/customer-details';
import {
  getCustomerRiskRatingConfig,
  getCustomerRiskRatingConfigForIndividual,
} from 'middleware/actions/customer-risk-rating-config';
import { unmask } from 'middleware/actions/mask';
import { getSettings } from 'middleware/actions/settings';
import { isCompany } from 'utils/helpers/cases/case-utility';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersonPopover } from 'components/popover/person-popover';
import { getDefaultCustomerCategoryFromState } from 'utils/helpers/config-utils/config-util';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';

/**
 * chip to display customer details
 * TODO: refactor CustomerChipNameOnly to use this class
 */
const ChipWithPersonPopover = ({
  item = {},
  size,
  label,
  icon,
  style,
  defaultTab,
  dataTestIdPrefix,
}) => {
  const uuid = item?.groupId;
  const { tenantId, configVersion } = item;
  const caseConfig = { tenantId, configVersion };

  const masks = useSelector((state) => state.masks);
  const username = useSelector((state) => state.authentication.user?.email);
  const userPermissions = useSelector((state) => state.authentication?.userPermissions || []);
  const detachmentConfig = useSelector((state) => state.authentication?.detachmentConfig || {});

  /**
   * This component is generic for customer cases & transaction cases
   * DONOT add logic from redux related to a specific business only
   * Eg. useSelector((state) => state?.customerDetails[uuid]);
   * How to overcome this? (pass below param)
   * item.ignoreCustomerDetailState
   * is added so you can skip checking the state and adding generic logic
   */
  const customerDetails = useSelector((state) => state?.customerDetails[uuid]);

  const accountHoldingPartyData = customerDetails?.accountHoldingPartyData;
  const customerRiskRatingData = customerDetails?.riskRatingData;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isUnmasked, setIsUnmasked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isIndividual, setIsIndividual] = useState(false);

  const dispatch = useDispatch();

  const defaultCustomerCategory = useSelector((state) =>
    getDefaultCustomerCategoryFromState({ state, tenantId, configVersion }),
  );
  const customerCategory = customerDetails?.customerCategory ?? item?.customerCategory;

  const isCompanyTransaction = isCompany(customerCategory, defaultCustomerCategory);

  useEffect(
    () => {
      if (item?.ignoreCustomerDetailState) {
        setIsIndividual(!isCompany(item.customerCategory));
      } else {
        setIsIndividual(!isCompany(customerCategory));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerCategory, item.customerCategory],
  );

  useEffect(
    () => {
      if (isClicked) {
        if (isCompanyTransaction) {
          if (
            isFeatureAccessible(
              DETACHMENT_CONFIG.customerRiskRating.organization,
              detachmentConfig,
              userPermissions,
            )
          ) {
            dispatch(getCustomerRiskRatingConfig({ tenantId, configVersion }));
          }
        } else if (
          isFeatureAccessible(
            DETACHMENT_CONFIG.customerRiskRating.individual,
            detachmentConfig,
            userPermissions,
          )
        ) {
          dispatch(getCustomerRiskRatingConfigForIndividual({ tenantId, configVersion }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCompanyTransaction, tenantId, isClicked, configVersion],
  );

  const customer = {
    masked: item?.customers?.[0] ?? {},
    tokens: item?.customerNameTokens?.map((t) => t?.nameToken),
  };

  const customerData = {
    masked: customer?.masked?.name ?? '-',
    tokens: customer?.tokens,
    riskLevel: accountHoldingPartyData?.riskLevel,
    calculatedRiskLevel: item?.riskLevel?.toLowerCase(),
    customerId: accountHoldingPartyData?.customerId,
    customerGroupId: accountHoldingPartyData?.customerGroupId,
    customerStatus: accountHoldingPartyData?.customerStatus,
    customerSince: accountHoldingPartyData?.customerSince,
    customerType: accountHoldingPartyData?.customerType,
    signedProducts: accountHoldingPartyData?.signedProducts,
    underwriterData: customerDetails?.underwriterData,
    referenceAccount: accountHoldingPartyData?.referenceAccount,
    characteristics: accountHoldingPartyData?.characteristics,
    personalDetails: accountHoldingPartyData?.personalDetails,
    companyDetails: accountHoldingPartyData?.companyDetails,
    contactDetails: accountHoldingPartyData?.contactDetails,
  };

  const openDetails = Boolean(anchorEl);

  const handleClick = (event) => {
    const anchorResult = anchorEl ? null : event.currentTarget;
    event.stopPropagation();
    setAnchorEl(anchorResult);

    const dispatchUuid = item?.isCustomerCase ? item?.caseUuid : uuid;
    if (!!anchorResult && !isUnmasked) {
      dispatch(getCustomerDetails(dispatchUuid));
      dispatch(
        unmask({
          masterUuid: dispatchUuid,
          displayUuid: dispatchUuid,
          uuids: [dispatchUuid],
          userId: username,
          isCustomerCase: item?.isCustomerCase,
        }),
      );
      dispatch(getSettings(caseConfig));

      setIsUnmasked(true);
      setIsClicked(true);
    }
  };

  const customerDisplayName =
    masks?.all?.find((mask) => customerData?.tokens?.includes(mask.token))?.unmasked ||
    customerData?.masked;

  const chipProps = {
    style,
    size,
    label,
    icon,
  };

  return (
    <>
      <Chip
        data-testid={`${dataTestIdPrefix}-chip-with-person-popover`}
        className="none max-w-100"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...chipProps}
        onClick={handleClick}
      />

      <PersonPopover
        anchorEl={anchorEl}
        openDetails={openDetails}
        handleClick={handleClick}
        personName={customerDisplayName}
        personDetails={
          // to use customer master data from the right state
          // todo - remove ignoreCustomerDetailState when state is handled correctly
          item?.ignoreCustomerDetailState ? undefined : customerData
        }
        isCustomer
        customerRiskRatingData={customerRiskRatingData}
        tenantId={item?.tenantId}
        defaultTab={defaultTab}
        item={item}
        isCompanyTransaction={isCompanyTransaction}
        caseConfig={caseConfig}
      />
    </>
  );
};

export { ChipWithPersonPopover };
