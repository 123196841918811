import { put, takeLatest } from 'redux-saga/effects';
import ExpectedCustomerValueConfigAPICommunicator from 'services/expected-customer-value-config-api-communicator';
import ExpectedCustomerValueConfigManager from 'services/expected-customer-value-config-manager';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { EXPECTED_CUSTOMER_VALUE_ACTIONS } from 'modules/configuration/rule-manager/expected-customer-value/middleware/action-types';
import {
  deleteExpectedCustomerValueConfigSuccess,
  getExpectedCustomerValueConfigSuccess,
  updateExpectedCustomerValueConfigSuccess,
} from 'modules/configuration/rule-manager/expected-customer-value/middleware/actions/expected-customer-value-config';

const communicator = new ExpectedCustomerValueConfigAPICommunicator();
const manager = new ExpectedCustomerValueConfigManager(communicator);

function* getExpectedCustomerValueConfigBegin(action) {
  yield put(startProcessing(EXPECTED_CUSTOMER_VALUE_ACTIONS.fetch.data));
  try {
    const json = yield manager.getExpectedCustomerValueConfig(action.payload);
    yield put(getExpectedCustomerValueConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(EXPECTED_CUSTOMER_VALUE_ACTIONS.fetch.data));
}

export function* getExpectedCustomerValueConfigWatcher() {
  yield takeLatest(EXPECTED_CUSTOMER_VALUE_ACTIONS.fetch.data, getExpectedCustomerValueConfigBegin);
}

function* updateExpectedCustomerValueConfigBegin(action) {
  yield put(startProcessing(EXPECTED_CUSTOMER_VALUE_ACTIONS.update.send));

  try {
    const json = yield manager.updateExpectedCustomerValueConfig(action.payload);
    yield put(updateExpectedCustomerValueConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(EXPECTED_CUSTOMER_VALUE_ACTIONS.update.send));
}

export function* updateExpectedCustomerValueConfigWatcher() {
  yield takeLatest(
    EXPECTED_CUSTOMER_VALUE_ACTIONS.update.send,
    updateExpectedCustomerValueConfigBegin,
  );
}

function* deleteExpectedCustomerValueConfigBegin(action) {
  yield put(startProcessing(EXPECTED_CUSTOMER_VALUE_ACTIONS.delete.send));

  try {
    const json = yield manager.deleteExpectedCustomerValueConfig(action.payload);
    yield put(deleteExpectedCustomerValueConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(EXPECTED_CUSTOMER_VALUE_ACTIONS.delete.send));
}

export function* deleteExpectedCustomerValueConfigWatcher() {
  yield takeLatest(
    EXPECTED_CUSTOMER_VALUE_ACTIONS.delete.send,
    deleteExpectedCustomerValueConfigBegin,
  );
}
