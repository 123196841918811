import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getDataProviderListConfigSuccess,
  saveDataProviderListConfigSuccess,
  deleteDataProviderListConfigSuccess,
} from 'modules/configuration/list-manager/data-provider-list/reducers/data-provider-list-config-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import DataProviderListConfigAPI from 'modules/configuration/list-manager/data-provider-list/services/data-provider-list-config-api';

const communicator = new DataProviderListConfigAPI();

function* getDataProviderListConfigBegin(action) {
  yield put(startProcessing('dataProviderListConfig/getDataProviderListConfig'));
  try {
    const json = yield communicator.fetchConfig();
    yield put(getDataProviderListConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('dataProviderListConfig/getDataProviderListConfig'));
}

export function* getDataProviderListConfigWatcher() {
  yield takeLatest(
    'dataProviderListConfig/getDataProviderListConfig',
    getDataProviderListConfigBegin,
  );
}

function* saveDataProviderListConfigBegin(action) {
  yield put(startProcessing('dataProviderListConfig/saveDataProviderListConfig'));
  try {
    const json = yield communicator.updateConfig(action.payload);
    yield put(saveDataProviderListConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('dataProviderListConfig/saveDataProviderListConfig'));
}

export function* saveDataProviderListConfigWatcher() {
  yield takeLatest(
    'dataProviderListConfig/saveDataProviderListConfig',
    saveDataProviderListConfigBegin,
  );
}

function* deleteDataProviderListBegin(action) {
  yield put(startProcessing('dataProviderListConfig/deleteDataProviderListConfig'));
  try {
    const json = yield communicator.deleteConfig(action.payload);
    yield put(deleteDataProviderListConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('dataProviderListConfig/deleteDataProviderListConfig'));
}

export function* deleteDataProviderListConfigWatcher() {
  yield takeLatest(
    'dataProviderListConfig/deleteDataProviderListConfig',
    deleteDataProviderListBegin,
  );
}
