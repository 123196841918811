import APICommunicator from 'services/api-communicator';
import ErrorCode from 'utils/constants/error-code';
import { API_URLS } from 'utils/constants/api-config';

export class TransactionFieldsApiCommunicator extends APICommunicator {
  fetchComparableFields() {
    const apiUrl = API_URLS.configService.transactionFields.getAllTransactionStrings;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}
