import {
  SanctionListActions,
  PepSanctionConfigActions,
  PEP_SANCTION_CONFIG_ACTIONS,
} from 'middleware/action-types';

export const getSanctionLists = (size, page, sortParams, filterColumn, filterValue) => ({
  type: SanctionListActions.GET_SANCTION_LIST,
  size,
  page,
  sortParams,
  filterColumn,
  filterValue,
});

export const getSanctionListsSuccess = (payload) => ({
  type: SanctionListActions.GET_SANCTION_LIST_SUCCESS,
  payload,
});

export const getSanctionListsError = () => ({
  type: SanctionListActions.GET_SANCTION_LIST_ERROR,
});

export const getSanctionListLastUpdate = () => ({
  type: SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE,
});

export const getSanctionListLastUpdateSuccess = (payload) => ({
  type: SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE_SUCCESS,
  payload,
});

export const getSanctionListLastUpdateError = () => ({
  type: SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE_ERROR,
});

export const getPepSanctionConfig = () => ({
  type: PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG,
});

export const getInstanciablePepSanctionConfig = () => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.instanciable.get,
});

export const getInstanciablePepSanctionConfigError = () => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.instanciable.error,
});

export const getInstanciablePepSanctionConfigSuccess = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.instanciable.success,
  payload,
});

export const getPepSanctionConfigSuccess = (payload) => ({
  type: PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG_SUCCESS,
  payload,
});

export const getPepSanctionConfigError = () => ({
  type: PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG_ERROR,
});

export const getPepSanctionRegions = () => ({
  type: PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS,
});

export const getPepSanctionRegionsSuccess = (payload) => ({
  type: PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS_SUCCESS,
  payload,
});

export const getPepSanctionRegionsError = () => ({
  type: PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS_ERROR,
});

export const getRegionSanctionLists = (size, page, sortField, sortDirection, filters, region) => ({
  type: PepSanctionConfigActions.GET_REGION_SANCTION_LISTS,
  size,
  page,
  sortField,
  sortDirection,
  filters,
  region,
});

export const getRegionSanctionListsSuccess = (payload) => ({
  type: PepSanctionConfigActions.GET_REGION_SANCTION_LISTS_SUCCESS,
  payload,
});

export const getRegionSanctionListsError = () => ({
  type: PepSanctionConfigActions.GET_REGION_SANCTION_LISTS_ERROR,
});

export const savePepSanctionConfig = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.update.data,
  payload,
});

export const savePepSanctionConfigSuccess = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.update.success,
  payload,
});

export const savePepSanctionConfigError = () => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.update.error,
});

export const saveInstanciablePepSanctionConfig = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.data,
  payload,
});

export const deletePepSanctionInstanciableConfigSuccess = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.success,
  payload,
});

export const deleteInstanciablePepSanctionConfig = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.deleteInstanciable.send,
  payload,
});

export const saveInstanciablePepSanctionConfigSuccess = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.success,
  payload,
});

export const saveInstanciablePepSanctionConfigError = () => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.error,
});

export const saveInstanciablePepSanctionProvider = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.updateInstanciableProvider.data,
  payload,
});

export const saveInstanciablePepSanctionProviderSuccess = (payload) => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.updateInstanciableProvider.success,
  payload,
});

export const saveInstanciablePepSanctionProviderError = () => ({
  type: PEP_SANCTION_CONFIG_ACTIONS.updateInstanciableProvider.error,
});
