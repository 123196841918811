import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class BlacklistInstantiableListApiCommunicator extends APICommunicator {
  fetchBlacklistInstantiableListConfig(tenantId) {
    const apiUrl = API_URLS.listManager.blacklist.fetch.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.RISKY_WORDS_LIST_DATA_NOT_FOUND, tenantId);
  }

  updateBlacklistInstantiableListConfig(data) {
    const apiUrl = API_URLS.listManager.blacklist.upsert.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.postJSONForTenant(
      apiUrl,
      { instances: data },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteBlacklistInstantiableListConfig(id) {
    const apiUrl = API_URLS.listManager.blacklist.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.id, id);

    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateActiveBlacklistInstantiableListConfigVersion(tenantId, version) {
    const apiUrl = API_URLS.listManager.blacklist.updateActiveVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default BlacklistInstantiableListApiCommunicator;
