import { BLACKLIST_LIST_ACTIONS } from 'middleware/action-types';

export const getBlacklistFileList = (tenantId) => ({
  type: BLACKLIST_LIST_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getBlacklistFileListSuccess = (payload) => ({
  type: BLACKLIST_LIST_ACTIONS.fileList.success,
  payload,
});

export const getBlacklistFileListError = () => ({
  type: BLACKLIST_LIST_ACTIONS.fileList.error,
});

export const activateBlacklistList = (version) => ({
  type: BLACKLIST_LIST_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateBlacklistListSuccess = (payload) => ({
  type: BLACKLIST_LIST_ACTIONS.activateVersion.success,
  payload,
});

export const activateBlacklistListError = () => ({
  type: BLACKLIST_LIST_ACTIONS.activateVersion.error,
});
