export const includes = (array, element, testingFunction) => {
  if (testingFunction) {
    const idx = array?.findIndex((element1) => testingFunction(element1, element));
    return idx >= 0;
  }

  return array?.includes(element);
};

export const equalArray = (a, b, testingFunction) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < b.length; ++i) {
    let isIncluded = true;
    if (testingFunction) {
      isIncluded = includes(a, b[i], testingFunction);
    } else {
      isIncluded = a.includes(b[i]);
    }
    if (!isIncluded) return false;
  }

  return true;
};
