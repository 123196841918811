import { Box, Divider, Grid, Chip } from '@mui/material';
import i18next from 'i18next';
import { Translation } from 'react-i18next';

import { useForm, Form } from 'hooks/use-form';
import Controls from 'components/controls/controls';

const ID_FILTER_FIELD = 'paymentMeans';

const buildPaymentMeansValuesFromUrl = (filter) => ({
  field: i18next.t(`mapping:filters.filtering.${ID_FILTER_FIELD}.key`),
  condition: i18next.t(`mapping:operator.collection.${filter.condition}.key`),
  values: filter.values,
});

const PaymentMeansForm = ({ editForm, onSubmit }) => {
  const initialFValues = {
    [`${ID_FILTER_FIELD}`]: editForm?.values || [],
  };

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define, consistent-return
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    if (ID_FILTER_FIELD in fieldValues) {
      temp[ID_FILTER_FIELD] = fieldValues[ID_FILTER_FIELD]?.length
        ? ''
        : i18next.t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
        field: i18next.t(`mapping:filters.filtering.${ID_FILTER_FIELD}.key`),
        values: values[ID_FILTER_FIELD],
      });
    }
  };
  return (
    <Translation>
      {(t) => (
        <Grid container>
          <Form className="w-100">
            <Grid container item className="px-3 py-2" spacing={1}>
              <Grid item xs={12}>
                <Controls.MultipleInput
                  name={ID_FILTER_FIELD}
                  label={t('condition.equal.heading')}
                  value={values[ID_FILTER_FIELD]}
                  onChange={handleInputChange}
                  error={errors[ID_FILTER_FIELD]}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button
                onClick={handleSubmit}
                type="submit"
                color="primary"
                text={t('filter.heading')}
              />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const PaymentMeansChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t(`mapping:filters.filtering.${ID_FILTER_FIELD}.value.heading`)}
        </Box>
        {form.values.map((item, index) => (
          <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
        ))}
      </>
    )}
  </Translation>
);

export { PaymentMeansForm, PaymentMeansChip, buildPaymentMeansValuesFromUrl };
