import { RootState } from 'middleware/store';
import { TenantConfig } from 'models/tenant/tenant';
import { shallowEqual, useSelector } from 'react-redux';

export const useTenantConfig = () => {
  const tenantConfig: TenantConfig = useSelector(
    (state: RootState) => state.tenantConfig ?? {},
    shallowEqual,
  );

  return { tenantConfig };
};
