import { CASE_ACTIONS } from 'middleware/action-types';
import { GroupSearchRequest } from 'models/case/search/group-search-request';

export const getAllCases = (payload: GroupSearchRequest) => ({
  type: CASE_ACTIONS.allCases.fetch.data,
  payload,
});

export const getAllCasesSuccess = (payload: any) => ({
  type: CASE_ACTIONS.allCases.fetch.success,
  payload,
});

export const getAllCasesError = () => ({
  type: CASE_ACTIONS.allCases.fetch.error,
});
