import Errors from 'components/notifications/errors/errors';
import Notifications from 'components/notifications/notifications';

const AlertContainer = () => (
  <div className="alert-container">
    <Errors />
    <Notifications />
  </div>
);

export default AlertContainer;
