import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class CaseDetailApiCommunicator extends APICommunicator {
  fetchCaseDetail(uuid, tenantId) {
    const apiUrl = API_URLS.customerService.singleCase.getDetail.replaceAll(
      API_PARAMS.caseId,
      uuid,
    );

    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateCaseState(uuid, tenantId, body) {
    const apiUrl = API_URLS.customerService.singleCase.updateState.replaceAll(
      API_PARAMS.caseId,
      uuid,
    );

    return this.postJSONForCustomTenant(
      apiUrl,
      body,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default CaseDetailApiCommunicator;
