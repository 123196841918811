import APICommunicator from 'services/api-communicator';
import ErrorCode from 'utils/constants/error-code';
import { API_URLS, API_PARAMS } from '../utils/constants/api-config';

class HidtaHifcaListApiCommunicator extends APICommunicator {
  fetchHidtaHifcaFilesList(tenantId) {
    const apiUrl = API_URLS.hidtaHifca.getMetadata.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.hidtaHifca.dataNotFound, tenantId);
  }

  updateActiveHidtaHifcaListVersion(tenantId, version) {
    const apiUrl = API_URLS.hidtaHifca.updateActiveVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default HidtaHifcaListApiCommunicator;
