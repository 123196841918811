import {
  getCustomerDetailsError,
  getCustomerDetailsSuccess,
} from 'middleware/actions/customer-details';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { CUSTOMER_DETAILS_ACTIONS } from 'middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import CustomerDetailsApiCommunicator from 'services/customer-details-api-communicator';
import CustomerDetailsManager from 'services/customer-details-manager';

const apiCommunicator = new CustomerDetailsApiCommunicator();
const ahpdManager = new CustomerDetailsManager(apiCommunicator);

export function* getCustomerDetailsBegin(action) {
  try {
    yield put(startProcessing(CUSTOMER_DETAILS_ACTIONS.get.data));

    const json = yield ahpdManager.getCustomerDetails(action.payload.caseId);
    yield put(getCustomerDetailsSuccess(json));

    yield put(stopProcessing(CUSTOMER_DETAILS_ACTIONS.get.data));
  } catch (error) {
    yield put(getCustomerDetailsError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_DETAILS_ACTIONS.get.data));
  }
}

export function* getCustomerDetailsWatcher() {
  yield takeLatest(CUSTOMER_DETAILS_ACTIONS.get.data, getCustomerDetailsBegin);
}
