import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class CaseDetailNoteCCManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Case API Communicator instance is required.');
    }
  }

  addCaseNote(caseId, tenantId, userId, note, type, attachments) {
    if (userId != null && userId.length && note != null && note.length) {
      return this.communicator.addCaseNote(caseId, tenantId, userId, note, type, attachments);
    }
    return raiseErrorByCode(ErrorCode.CASE_NOTE_DATA_INVALID);
  }

  downloadAttachment(caseId, noteId, attachmentId) {
    return this.communicator.downloadAttachment(caseId, noteId, attachmentId);
  }
}

export default CaseDetailNoteCCManager;
