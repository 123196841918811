import { SORT_DIRECTION } from 'utils/constants/sort-directions';

/**
 * toggling sort direction, 3rd click will disable sorting on column
 * @param {*} direction
 * @param isInverse
 * @returns
 */
export const toggleSort = (direction, isInverse) => {
  if (isInverse) {
    if (direction === SORT_DIRECTION.desc) {
      return SORT_DIRECTION.asc;
    }

    if (direction === SORT_DIRECTION.asc) {
      return null;
    }

    return SORT_DIRECTION.desc;
  }

  if (direction === SORT_DIRECTION.asc) {
    return SORT_DIRECTION.desc;
  }
  if (direction === SORT_DIRECTION.desc) {
    return null;
  }

  return SORT_DIRECTION.asc;
};
