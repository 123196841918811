import { useTranslation } from 'react-i18next';

import { AlertTitle } from '@mui/material';
import DOMPurify from 'dompurify';

const ErrorSummary = ({ text }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(text),
    }}
  />
);

const ErrorDetails = ({ caseUrl, apiUrl, sessionId }) => {
  const { t } = useTranslation();
  return (
    <>
      <AlertTitle>{t('error:error.details.header')}</AlertTitle>
      <div>
        <div className="pb-1">
          <span>{t('error:error.details.caseUrl')}</span>
          <span className="font-weight-light">{caseUrl}</span>
        </div>
        <div className="pb-1">
          <span>{t('error:error.details.apiUrl')}</span>
          <span className="font-weight-light">{apiUrl ?? '-'}</span>
        </div>
        <div className="pb-1">
          <span>{t('error:error.details.sessionId')}</span>
          <span className="font-weight-light">{sessionId ?? '-'}</span>
        </div>
      </div>
    </>
  );
};

export { ErrorDetails, ErrorSummary };
