import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteCounterPartyFrequencyConfigError,
  deleteCounterPartyFrequencyConfigSuccess,
  getCounterPartyFrequencyConfigError,
  getCounterPartyFrequencyConfigSuccess,
  updateCounterPartyFrequencyConfigError,
  updateCounterPartyFrequencyConfigSuccess,
} from 'modules/configuration/rule-manager/counter-party-frequency/middleware/actions/counter-party-frequency-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';
import { CounterPartyFrequencyConfigActions } from 'modules/configuration/rule-manager/counter-party-frequency/middleware/action-types';

// TODO: define request mapping 'counter-party-frequency' as consts
const communicator = new RuleConfigAPICommunicator('counter-party-frequency');
const manager = new RuleConfigManager(communicator);

function* getCounterPartyFrequencyConfigBegin(action) {
  yield put(startProcessing(CounterPartyFrequencyConfigActions.GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getCounterPartyFrequencyConfigSuccess(json));
  } catch (error) {
    yield put(getCounterPartyFrequencyConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CounterPartyFrequencyConfigActions.GET));
}

export function* getCounterPartyFrequencyConfigWatcher() {
  yield takeLatest(CounterPartyFrequencyConfigActions.GET, getCounterPartyFrequencyConfigBegin);
}

function* updateCounterPartyFrequencyConfigBegin(action) {
  yield put(startProcessing(CounterPartyFrequencyConfigActions.UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateCounterPartyFrequencyConfigSuccess(json));
  } catch (error) {
    yield put(updateCounterPartyFrequencyConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CounterPartyFrequencyConfigActions.UPDATE));
}

export function* updateCounterPartyFrequencyConfigWatcher() {
  yield takeLatest(
    CounterPartyFrequencyConfigActions.UPDATE,
    updateCounterPartyFrequencyConfigBegin,
  );
}

function* deleteCounterPartyFrequencyConfigBegin(action) {
  yield put(startProcessing(CounterPartyFrequencyConfigActions.DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteCounterPartyFrequencyConfigSuccess(json));
  } catch (error) {
    yield put(deleteCounterPartyFrequencyConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CounterPartyFrequencyConfigActions.DELETE));
}

export function* deleteCounterPartyFrequencyConfigWatcher() {
  yield takeLatest(
    CounterPartyFrequencyConfigActions.DELETE,
    deleteCounterPartyFrequencyConfigBegin,
  );
}
