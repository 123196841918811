import { updateDecisionEngineConfig } from 'middleware/actions/decision-engine-config';
import { showError } from 'middleware/actions/error';
import { PassthroughConfigActions } from 'modules/configuration/rule-manager/passthrough/middleware/action-types';
import {
  getPassthroughConfigError,
  getPassthroughConfigSuccess,
  updatePassthroughConfigError,
  updatePassthroughConfigSuccess,
} from '../actions/passthrough-config';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import PassthroughConfigAPICommunicator from 'services/passthrough-config-api-communicator';
import PassthroughConfigManager from 'services/passthrough-config-manager';

const communicator = new PassthroughConfigAPICommunicator();
const manager = new PassthroughConfigManager(communicator);

function* getPassthroughConfigBegin(action) {
  yield put(startProcessing(PassthroughConfigActions.PASSTHROUGH_CONFIG_GET));
  try {
    const json = yield manager.getPassthroughConfig(action.payload);
    yield put(getPassthroughConfigSuccess(json));
  } catch (error) {
    yield put(getPassthroughConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(PassthroughConfigActions.PASSTHROUGH_CONFIG_GET));
}

export function* getPassthroughConfigWatcher() {
  yield takeLatest(PassthroughConfigActions.PASSTHROUGH_CONFIG_GET, getPassthroughConfigBegin);
}

function* updatePassthroughConfigBegin(action) {
  yield put(startProcessing(PassthroughConfigActions.PASSTHROUGH_CONFIG_UPDATE));
  try {
    const json = yield manager.updatePassthroughConfig(action.payload.passthroughConfig);
    yield put(updateDecisionEngineConfig(action.payload.decisionEngineConfig));
    yield put(updatePassthroughConfigSuccess(json));
  } catch (error) {
    yield put(updatePassthroughConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(PassthroughConfigActions.PASSTHROUGH_CONFIG_UPDATE));
}

export function* updatePassthroughConfigWatcher() {
  yield takeLatest(
    PassthroughConfigActions.PASSTHROUGH_CONFIG_UPDATE,
    updatePassthroughConfigBegin,
  );
}
