import React from 'react';
import TenantManagementService from 'services/tenant/tenant-management-service';

interface TenantNameCellProps {
  tenantId: string;
}

export const TenantNameCell = ({ tenantId }: TenantNameCellProps) => {
  const tenantsMap = TenantManagementService.getTenantMap();
  const tenant = tenantsMap.get(tenantId);

  return <span> {tenant && tenant.name ? tenant.name : tenantId} </span>;
};
