import { Button, Typography } from '@mui/material';
import { ReactComponent as UploadIcon } from 'assets/images/icons/file_upload.svg';
import axios from 'axios';
import Spinner from 'components/loaders/spinner';
import i18next from 'i18next';
import { showError } from 'middleware/actions/error';
import { showNotification } from 'middleware/actions/notification';
import SessionService from 'modules/authentication/login/services/session-service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TenantManagementService from 'services/tenant/tenant-management-service';

const auth = new SessionService();

const UploadFile = ({
  disabled,
  maxSize,
  contentTypes,
  uploadUrl,
  onUploadCompleted,
  title,
  uploadInputId,
  formParams = {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [incorrectFile, setIncorrectFile] = useState(false);

  const handleFileSelectClick = (event) => {
    // todo temporary solution
    if (disabled && uploadInputId !== 'product-type-file-upload-individual') {
      event.preventDefault();
    }
  };

  const handleFileChange = ({ target: { files } }) => {
    // todo temporary solution
    if (!files.length || (disabled && uploadInputId !== 'product-type-file-upload-individual')) {
      return;
    }

    setIncorrectFile(false);
    const [{ size }] = files;
    const fileToUpload = files?.[0];

    if (size < maxSize && fileToUpload && contentTypes?.includes(fileToUpload?.type)) {
      const data = new FormData();
      data.append('file', fileToUpload);
      Object.entries(formParams ?? {}).forEach(([key, value]) => {
        data.append(key, `${value}`);
      });
      setUploadInProgress(true);

      axios
        .post(uploadUrl, data, {
          withCredentials: true,
          headers: {
            tenantId: TenantManagementService.getActiveTenantId(),
            correlationId: auth.getCorrelationId(),
            userId: auth.getUsername(),
          },
        })
        .then((res) => {
          setUploadInProgress(false);
          dispatch(
            showNotification({
              message: i18next.t('file.upload.success.file.heading'),
            }),
          );
          onUploadCompleted(res);
        })
        .catch((error) => {
          setUploadInProgress(false);

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const messageFromResponse = error.response?.data?.message;
            dispatch(
              showError({
                message: messageFromResponse || t('file.upload.error.serverError.heading'),
              }),
            );
          } else if (error.request) {
            // The request was made but no response was received, probly timeout due to long processing
            dispatch(
              showError({
                message: t('file.upload.error.longProcessing.heading'),
                custom: { alertType: 'warning' },
              }),
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            dispatch(
              showError({
                message: t('file.upload.error.uploadError.heading'),
              }),
            );
          }
        });
    } else {
      setIncorrectFile(true);
    }
  };

  return (
    <>
      <input
        accept={contentTypes}
        id={uploadInputId}
        type="file"
        style={{ display: 'none' }}
        onClick={handleFileSelectClick}
        onChange={handleFileChange}
        data-testid={uploadInputId}
      />
      <label htmlFor={uploadInputId}>
        <Button
          disabled={disabled || uploadInProgress}
          variant="outlined"
          component="span"
          color="primary"
          size="small"
          startIcon={
            !uploadInProgress && (
              <UploadIcon className={disabled ? 'color-on-surface-disabled' : 'color-primary'} />
            )
          }
        >
          <div>
            <Spinner
              size={15}
              thickness={4}
              color="inherit"
              className="mb-n1xs mr-2"
              isLoading={uploadInProgress}
            />
            {title ?? t('file.upload.heading')}
          </div>
        </Button>
      </label>
      {incorrectFile && (
        <Typography variant="caption" color="error" className="lh-1">
          {t('file.upload.error.incorrectOrCorrupter.heading')}
        </Typography>
      )}
    </>
  );
};

export default UploadFile;
