import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

import CaseAPICommunicator from 'modules/trx-cases/case-detail/services/case/case-api-communicator';
import CaseManager from 'modules/trx-cases/case-detail/services/case/case-manager';

import SessionService from 'modules/authentication/login/services/session-service';
import { CaseActions } from 'middleware/action-types';
import {
  assessCountryRiskError,
  assessCountryRiskSuccess,
  assessError,
  assessLabelError,
  assessLabelSuccess,
  assessSuccess,
  assessVolumeRiskError,
  assessVolumeRiskSuccess,
  getCaseDetail,
  getForwardingCaseDetail,
} from 'middleware/actions/case';

const caseCommunicator = new CaseAPICommunicator();
const caseManager = new CaseManager(caseCommunicator);
const auth = new SessionService();

function* assessVolumeRiskBegin(action) {
  yield put(startProcessing(CaseActions.ASSESS_VOLUME_RISK));
  try {
    const payload = action.payload || {};
    const { caseId } = payload;
    const json = yield caseManager.assessVolumeRisk(caseId, {
      questionId: payload.questionId,
      answer: payload.answer,
      userId: auth.getUsername(),
      custom: payload.custom,
      processedAt: payload.processedAt,
    });
    yield put(
      assessVolumeRiskSuccess({
        masterCaseId: payload.masterCaseId,
        caseId,
        answer: json.answer,
        assessment: json,
      }),
    );
  } catch (error) {
    yield put(assessVolumeRiskError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseActions.ASSESS_VOLUME_RISK));
}

function* assessVolumeRiskWatcher() {
  yield takeLatest(CaseActions.ASSESS_VOLUME_RISK, assessVolumeRiskBegin);
}

function* assessCountryRiskBegin(action) {
  yield put(startProcessing(CaseActions.ASSESS_COUNTRY_RISK));
  try {
    const payload = action.payload || {};
    const { caseId } = payload;
    const response = yield caseManager.assessCountryRisk(caseId, {
      questionId: payload.questionId,
      answer: payload.answer,
      userId: auth.getUsername(),
      processedAt: payload.processedAt,
    });
    yield put(
      assessCountryRiskSuccess({
        masterCaseId: payload.masterCaseId,
        caseId,
        assessment: response,
      }),
    );
  } catch (error) {
    yield put(assessCountryRiskError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseActions.ASSESS_COUNTRY_RISK));
}

function* assessCountryRiskWatcher() {
  yield takeLatest(CaseActions.ASSESS_COUNTRY_RISK, assessCountryRiskBegin);
}

function* assessLabelBegin(action) {
  yield put(startProcessing(CaseActions.ASSESS_LABEL));
  try {
    const payload = action.payload || {};
    const { masterCaseId } = payload;
    const { caseId } = payload;
    const response = yield caseManager.assessLabel(caseId, {
      questionId: payload.questionId,
      answer: payload.answer,
      userId: auth.getUsername(),
      processedAt: payload.processedAt,
    });
    yield put(
      assessLabelSuccess({
        masterCaseId: payload.masterCaseId,
        caseId,
        assessment: response,
      }),
    );
    if (masterCaseId === caseId) {
      yield put(getCaseDetail(caseId, false));
    } else {
      yield put(getForwardingCaseDetail(caseId));
    }
  } catch (error) {
    yield put(assessLabelError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseActions.ASSESS_LABEL));
}

function* assessLabelWatcher() {
  yield takeLatest(CaseActions.ASSESS_LABEL, assessLabelBegin);
}

function* assessBegin(action) {
  yield put(startProcessing(CaseActions.ASSESS));
  try {
    const payload = action.payload || {};
    const { caseId } = payload;
    const response = yield caseManager.assess(caseId, payload.groupCase, {
      questionId: payload.questionId,
      answer: payload.answer,
      userId: auth.getUsername(),
      processedAt: payload.processedAt,
    });
    yield put(
      assessSuccess({
        masterCaseId: payload.masterCaseId,
        caseId,
        assessment: response,
      }),
    );
  } catch (error) {
    yield put(assessError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseActions.ASSESS));
}

function* assessWatcher() {
  yield takeLatest(CaseActions.ASSESS, assessBegin);
}

export { assessWatcher, assessLabelWatcher, assessCountryRiskWatcher, assessVolumeRiskWatcher };
