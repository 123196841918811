import { GOAML_ACTIONS } from 'middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { showNotification } from 'middleware/actions/notification';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getGoAMLXML,
  getGoAMLXMLError,
  getGoAMLXMLSuccess,
  saveGoAMLXMLError,
  saveGoAMLXMLSuccess,
  validateGoAMLXMLError,
  validateGoAMLXMLSuccess,
} from 'middleware/actions/goaml';
import GoAMLAPICommunicator from 'services/goaml-api-communicator';
import GoAMLManager from 'services/goaml-manager';
import { decodeHtml } from 'utils/helpers/string-util';

const apiCommunicator = new GoAMLAPICommunicator();
const manager = new GoAMLManager(apiCommunicator);

function* getGoAMLXMLBegin(action) {
  yield put(startProcessing(GOAML_ACTIONS.getXML.data));
  try {
    const json = yield manager.getXML(action.payload);

    const jsonDecoded = {
      data: decodeHtml(json.data),
    };
    yield put(getGoAMLXMLSuccess(jsonDecoded));
  } catch (error) {
    yield put(getGoAMLXMLError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(GOAML_ACTIONS.getXML.data));
}

export function* getGoAMLXMLWatcher() {
  yield takeLatest(GOAML_ACTIONS.getXML.data, getGoAMLXMLBegin);
}

function* saveGoAMLXMLBegin(action) {
  yield put(startProcessing(GOAML_ACTIONS.saveXML.data));
  try {
    const json = yield manager.saveXML(action.payload);
    yield put(saveGoAMLXMLSuccess(json));
    yield put(showNotification({ message: 'GoAML report has been saved successfully' }));
    const { caseId } = action.payload;
    yield put(getGoAMLXML({ caseId }));
  } catch (error) {
    yield put(saveGoAMLXMLError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(GOAML_ACTIONS.saveXML.data));
}

export function* saveGoAMLXMLWatcher() {
  yield takeLatest(GOAML_ACTIONS.saveXML.data, saveGoAMLXMLBegin);
}

function* validateGoAMLXMLBegin(action) {
  yield put(startProcessing(GOAML_ACTIONS.validateXML.data));
  try {
    const json = yield manager.validateXML(action.payload);
    yield put(validateGoAMLXMLSuccess(json));
  } catch (error) {
    yield put(validateGoAMLXMLError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(GOAML_ACTIONS.validateXML.data));
}

export function* validateGoAMLXMLWatcher() {
  yield takeLatest(GOAML_ACTIONS.validateXML.data, validateGoAMLXMLBegin);
}
