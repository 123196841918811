import { TRX_CASE_DETAIL_ACTIONS } from 'modules/trx-cases/case-detail/middleware/action-types';

export const stopGrouping = (payload) => ({
  type: TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.data,
  payload,
});

export const stopGroupingSuccess = (payload) => ({
  type: TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.success,
  payload,
});

export const stopGroupingError = () => ({
  type: TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.error,
});
