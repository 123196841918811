import { getCaseDetail, getForwardingCaseDetail } from 'middleware/actions/case';
import { hideError, showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { TRX_CASE_DETAIL_ACTIONS } from 'modules/trx-cases/case-detail/middleware/action-types';
import {
  addCaseNoteError,
  addCaseNoteSuccess,
  downloadAttachmentNoteError,
  downloadAttachmentNoteSuccess,
  getDistinctUserIdsFromNotesError,
  getDistinctUserIdsFromNotesSuccess,
} from 'modules/trx-cases/case-detail/middleware/actions/case-notes';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import TrxCaseDetailNoteApi from 'modules/trx-cases/case-detail/services/case-note/case-note-api-communicator';
import TrxCaseDetailNoteManager from 'modules/trx-cases/case-detail/services/case-note/case-note-manager';

const caseNoteCommunicator = new TrxCaseDetailNoteApi();
const caseNoteManager = new TrxCaseDetailNoteManager(caseNoteCommunicator);

function* addCaseNoteBegin(action) {
  const payload = action.payload || {};
  const { uuid } = payload;
  const { masterUuid } = payload;

  try {
    yield put(startProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.add.data));

    const json = yield caseNoteManager.addCaseNote(
      uuid,
      payload.userId,
      payload.note,
      payload.type,
      payload.attachments,
    );

    yield put(addCaseNoteSuccess(json));

    if (masterUuid === uuid) {
      yield put(getCaseDetail(uuid, false));
    } else {
      yield put(getForwardingCaseDetail(uuid));
    }

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.add.data));
  } catch (error) {
    yield put(addCaseNoteError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.add.data));
  }
}

function* addCaseNoteWatcher() {
  yield takeLatest(TRX_CASE_DETAIL_ACTIONS.caseNotes.add.data, addCaseNoteBegin);
}

function* downloadCaseNoteAttachmentBegin(action) {
  const payload = action.payload || {};
  const { attachmentId, noteId } = payload;

  try {
    yield put(startProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data));
    const json = yield caseNoteManager.downloadAttachment(noteId, attachmentId);

    const fileData = {
      blob: json,
      properties: payload,
    };

    yield put(downloadAttachmentNoteSuccess(fileData));

    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data));
  } catch (error) {
    yield put(downloadAttachmentNoteError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data));
  }
}

function* downloadCaseNoteAttachmentWatcher() {
  yield takeEvery(
    TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data,
    downloadCaseNoteAttachmentBegin,
  );
}

function* getDistinctUserIdsFromNotes(action) {
  try {
    yield put(startProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.getUniqueUserId.data));

    const json = yield caseNoteManager.queryDistinctUserIdsFromNotes();
    yield put(getDistinctUserIdsFromNotesSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.getUniqueUserId.data));
  } catch (error) {
    yield put(getDistinctUserIdsFromNotesError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(TRX_CASE_DETAIL_ACTIONS.caseNotes.getUniqueUserId.data));
  }
}

function* getDistinctUserIdsFromNotesWatcher() {
  yield takeLatest(
    TRX_CASE_DETAIL_ACTIONS.caseNotes.getUniqueUserId.data,
    getDistinctUserIdsFromNotes,
  );
}

export {
  addCaseNoteWatcher,
  getDistinctUserIdsFromNotesWatcher,
  downloadCaseNoteAttachmentWatcher,
};
