import { put, takeLatest } from 'redux-saga/effects';
import { VolumeIncreaseDecreaseConfigActions } from 'modules/configuration/rule-manager/volume-increase-decrease/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteVolumeIncreaseDecreaseConfigError,
  deleteVolumeIncreaseDecreaseConfigSuccess,
  getVolumeIncreaseDecreaseConfigError,
  getVolumeIncreaseDecreaseConfigSuccess,
  updateVolumeIncreaseDecreaseConfigError,
  updateVolumeIncreaseDecreaseConfigSuccess,
} from 'modules/configuration/rule-manager/volume-increase-decrease/middleware/actions/volume-increase-decrease-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';

const communicator = new RuleConfigAPICommunicator('volume-increase-decrease');
const manager = new RuleConfigManager(communicator);

function* getVolumeIncreaseDecreaseConfigBegin(action) {
  yield put(
    startProcessing(VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_GET),
  );
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getVolumeIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(getVolumeIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(
    stopProcessing(VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_GET),
  );
}

export function* getVolumeIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_GET,
    getVolumeIncreaseDecreaseConfigBegin,
  );
}

function* updateVolumeIncreaseDecreaseConfigBegin(action) {
  yield put(
    startProcessing(VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_UPDATE),
  );
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateVolumeIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(updateVolumeIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(
    stopProcessing(VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_UPDATE),
  );
}

export function* updateVolumeIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_UPDATE,
    updateVolumeIncreaseDecreaseConfigBegin,
  );
}

function* deleteVolumeIncreaseDecreaseConfigBegin(action) {
  yield put(
    startProcessing(VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_DELETE),
  );
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteVolumeIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(deleteVolumeIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(
    stopProcessing(VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_DELETE),
  );
}

export function* deleteVolumeIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_DELETE,
    deleteVolumeIncreaseDecreaseConfigBegin,
  );
}
