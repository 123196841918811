import { useSelector } from 'react-redux';
import { Box, Divider, Grid } from '@mui/material';
import i18next from 'i18next';
import { Translation, useTranslation } from 'react-i18next';
import { useForm, Form } from 'hooks/use-form';
import Controls from 'components/controls/controls';

const buildFundsTotalValuesFromUrl = (filter) => ({
  field: i18next.t('mapping:filters.filtering.totalAmount.key'),
  condition: i18next.t(`mapping:operator.number.${filter.condition}.key`),
  value: filter?.value,
  currencies: filter?.currencies,
});

const FundsTotalForm = ({ editForm, onSubmit }) => {
  const { t } = useTranslation();

  const filterConditions = [
    t('mapping:operator.number.MONETARY_EQUAL'),
    t('mapping:operator.number.MONETARY_LOWER_THAN'),
    t('mapping:operator.number.MONETARY_GREATER_THAN'),
    t('mapping:operator.number.MONETARY_LOWER_THAN_OR_EQUAL'),
    t('mapping:operator.number.MONETARY_GREATER_THAN_OR_EQUAL'),
  ];

  const currencies = useSelector((state) => [state.tenantConfig?.amountInBaseCurrency] || []);

  const initialFValues = {
    amount: editForm?.value || '',
    condition: editForm?.condition
      ? t(`mapping:operator.number.${editForm.condition}`)
      : filterConditions[0],
    currency: editForm?.currencies ?? currencies.length === 1 ? [currencies[0]] : [],
  };

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    if ('amount' in fieldValues) {
      // eslint-disable-next-line no-use-before-define
      temp.amount = fieldValues.amount ? '' : t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: values.condition.key,
        field: t('mapping:filters.filtering.totalAmount.key'),
        currencies: values.currency,
        value: values.amount,
      });
    }
  };
  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="px-3 py-3" spacing={2}>
          <Grid item xs={4}>
            <Controls.Select
              fullWidth
              name="condition"
              label={t('condition.heading')}
              value={values.condition}
              onChange={handleInputChange}
              options={filterConditions}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={4}>
              <Controls.Select
                fullWidth
                name="currency"
                label={t('case:caseList.filter.currency.heading')}
                value={values.currency}
                onChange={handleInputChange}
                options={currencies}
                multiple
                forceNotEmpty
                displayEmpty
              />
            </Grid>
            <Grid item xs={8}>
              <Controls.Input
                name="amount"
                label={t('case:caseList.filter.amount.heading')}
                value={values.amount}
                onChange={handleInputChange}
                error={errors.amount}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
        </Grid>
      </Form>
    </Grid>
  );
};

const FundsTotalChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.totalAmount.value.heading')}
        </Box>

        <Box fontSize="body2.fontSize">
          <Box fontSize="caption.fontSize" component="span">
            {t(`mapping:operator.number.${form.condition}.value.heading`)}{' '}
            {form.currencies?.join(', ')}
          </Box>{' '}
          {form.value}
        </Box>
      </>
    )}
  </Translation>
);

export { FundsTotalForm, FundsTotalChip, buildFundsTotalValuesFromUrl };
