class PepSanctionManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Pep-sanction communicator instance is required.');
    }
  }

  assessPepSanction(params) {
    return this.communicator.assessPepSanction(params);
  }
}

export default PepSanctionManager;
