import Strings from 'utils/helpers/locales/strings.en';
import DataFeatureName from 'utils/constants/data-feature-name';

const DataFeatureDetails = {
  'account-holding-party-accumulation-time-for-money-spent-in-days': {
    unit: 'time-days',
    explanation_default:
      'Typically accumulation time in credit (money coming in) is within one month as spend is usually less than monthly income',
    explanation_money_mule:
      'A money mule typically passes on high amounts of money within a couple of days.',
    explanation_send_money_home: '',
  },
  'count-of-accounts-with-income-from-same-originator-and-spent-to-same-beneficiary': {
    unit: 'quantity',
    explanation_default: '',
    explanation_money_mule:
      'Money mule schemes typically route money from A (originator side) to B (beneficiary side) using the mules for layering. Yet there is hardly legitimate traffic from/to other parties from the mule operators hence the comparison to other traffic is a strong indicator for suspicion. ',
    explanation_send_money_home: '',
  },
  'count-of-accounts-with-income-from-same-originator-and-spent-to-same-beneficiary-divided-by-count-of-accounts-spent-to-same-beneficiary':
    {
      unit: 'ratio',
      explanation_default: '',
      explanation_money_mule:
        'Normally beneficiaries receive money from different originators. These originators themselves receive money from completely different originators. The number of different originators grows with every level of distance. In a money mule scheme however the number of originators on the second level however decreases again. Most if not all of the first level originators receive money from the single same originator. ',
      explanation_send_money_home: '',
    },
  'ratio-one-rolling-month-volume-vs-all-time-monthly-average-volume': {
    unit: 'ratio',
    explanation_default: '',
    explanation_money_mule:
      'People recruited as money mules usually do not have huge volumes on their accounts given their lower income profile and money mule activity poses a short, yet in volume significant spike on their accounts.',
    explanation_send_money_home: '',
  },
  [DataFeatureName.COMMON_ACCOUNT_NUMBER_HASH_ORIGINATOR]: {
    unit: 'AccountID',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COMMON_ACCOUNT_NUMBER_HASH_BENEFICIARY]: {
    unit: 'AccountID',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COMMON_HOLDER_HASH_BENEFICIARY]: {
    unit: 'AccountID',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COMMON_SETTLED_FUNDS_AMOUNT]: {
    unit: 'AccountID',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_IBAN_ORIGINATOR]: {
    unit: 'Country',
    explanation_default: 'Originator country as derived from originator IBAN',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_IBAN_BENEFICIARY]: {
    unit: 'Country',
    explanation_default: 'Beneficiary country as derived from beneficiary IBAN',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.SIMILARITY_HOLDER_NAME_ORIGINATOR_BENEFICIARY]: {
    unit: 'Score',
    explanation_default: 'Similarity score on a scale from 0 to 1',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.TRANSACTION_INITIATING_PARTY]: {
    unit: 'InitiatingPartyType',
    explanation_default: 'Originator or beneficiary',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY]: {
    unit: 'AccountHoldingPartyType',
    explanation_default: 'Originator or beneficiary',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_COUNTRY_OF_RESIDENCE]: {
    unit: 'Country',
    explanation_default: 'Account holding party country of residence derived from master data',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_COUNTRY_OF_BIRTH]: {
    unit: 'Country',
    explanation_default: 'Account holding party country of birth derived from master data',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_COUNTRY_OF_BIRTH_RISK]: {
    unit: 'RiskScore',
    explanation_default:
      'Account holding party country of birth risk score (low, medium, high) derived from master data',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_NATIONALITY]: {
    unit: 'Country',
    explanation_default: 'Account holding party nationality country derived from master data',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK]: {
    unit: 'RiskScore',
    explanation_default:
      'Account holding party nationality risk score (low, medium, high) derived from master data',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL]: {
    unit: 'RiskScore',
    explanation_default: 'Account holding party risk score (low, medium, high) assigned by bank',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_LAST_NAME_INCLUDED_IN_COUNTER_PARTY_HOLDER_NAME]: {
    unit: 'binary',
    explanation_default: 'Account holder party last name included in counter party holder name',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_SUM_INCOMING_SETTLED_FUNDS_LAST_MONTH]: {
    unit: 'currency-eur',
    explanation_default: 'Account holding party cumulated credit last month',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_RISK_IBAN_ORIGINATOR]: {
    unit: 'RiskScore',
    explanation_default: 'Country risk (low medium, high) derived from originator IBAN',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_RISK_IBAN_BENEFICIARY]: {
    unit: 'RiskScore',
    explanation_default: 'Country risk (low medium, high) derived from beneficiary IBAN',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_RISK_HISTORY_ACCOUNT_HOLDING_PARTY]: {
    unit: 'UNKNOWN',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_RISK_CONSECUTIVE_OK_THRESHOLD]: {
    unit: 'number',
    explanation_default:
      'Threshold for consecutive country risk approvals by operator for the given account combination (originator/beneficiary)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_RISK_CONSECUTIVE_OK_VALUE]: {
    unit: 'number',
    explanation_default:
      'Number of consecutive country risk approvals by operator for the given account combination (originator/beneficiar',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.COUNTRY_RISK_DECISION_CASE_STATUS]: {
    unit: 'CaseStatus',
    explanation_default: 'Country risk case status decision (open, closed, relieved)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_OTHER_HOLDER_ORIGINATOR]: {
    unit: 'binary',
    explanation_default: 'Pepsanction lookup match on originator against other lists',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_OTHER_HOLDER_BENEFICIARY]: {
    unit: 'binary',
    explanation_default: 'Pepsanction lookup match on beneficiary against other lists',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_SANCTION_HOLDER_ORIGINATOR]: {
    unit: 'binary',
    explanation_default: 'Pepsanction lookup match on originator against sanction lists',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_SANCTION_HOLDER_BENEFICIARY]: {
    unit: 'binary',
    explanation_default: 'Pepsanction lookup match on beneficiary against sanction lists',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_VALIDATED_HOLDER_ORIGINATOR]: {
    unit: 'UNKNOWN',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_VALIDATED_HOLDER_BENEFICIARY]: {
    unit: 'UNKNOWN',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_GEOGRAPHICAL_OVERLAP_HOLDER_ORIGINATOR]: {
    unit: 'binary',
    explanation_default: 'Geographical overlap between originator and pepsanction match',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_GEOGRAPHICAL_OVERLAP_HOLDER_BENEFICIARY]: {
    unit: 'binary',
    explanation_default: 'Geographical overlap between beneficiary and pepsanction match',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_GENDER_MATCH_HOLDER_ORIGINATOR]: {
    unit: 'binary',
    explanation_default: 'Same gender between originator and pepsanction match',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_GENDER_MATCH_HOLDER_BENEFICIARY]: {
    unit: 'binary',
    explanation_default: 'Same gender between beneficiary and pepsanction match',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_DATE_OF_BIRTH_MATCH_HOLDER_ORIGINATOR]: {
    unit: 'binary',
    explanation_default: 'Similar data of birth between originator and pepsanction match',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_LOOKUP_MATCH_DATE_OF_BIRTH_MATCH_HOLDER_BENEFICIARY]: {
    unit: 'binary',
    explanation_default: 'Similar data of birth between originator and pepsanction match',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.PEPSANCTION_RISK_DECISION_CASE_STATUS]: {
    unit: 'CaseStatus',
    explanation_default: 'Pepsanction risk case status decision (open, closed, relieved)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_CONFIGURED_THRESHOLD]: {
    unit: 'currency-eur',
    explanation_default: 'Aggreated volume risk threshold within last month',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_SETTLED_FUNDS_AGGREGATED_AMOUNT]: {
    unit: 'currency-eur',
    explanation_default:
      'Aggregated debited funds for account combination (originator/beneficiary)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_AGGREGATED_TX_COUNT]: {
    unit: 'number',
    explanation_default:
      'Aggregated transaction count within last month for account combination (originator/beneficiary)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_AMOUNT_STANDARD_DEVIATION]: {
    unit: 'NOTUSED',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_AMOUNT_STANDARD_DEVIATION_CONFIGURED_THRESHOLD]: {
    unit: 'NOTUSED',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_VOLUME_ACCELERATOR_THRESHOLD_MULTIPLIER]: {
    unit: 'number',
    explanation_default:
      'Configured volume risk accelerator threshold multiplier. Example: If the volume threshold is EUR 10,000 then an accelerator of 2 means that transactions up to accumulated EUR 20,000 will be accepted and automatically filtered if the risk of excess is accepted by the operator above EUR 10,000.',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_AMOUNT_AVERAGE_DISTANCE_PERCENTAGE_THRESHOLD]: {
    unit: 'percent',
    explanation_default:
      'Configured threshold in percent of how much a transaction amount can deviate from the average amount in case of an operator accepted volume excess in volume risk',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_AMOUNT_AVERAGE_DISTANCE_PERCENTAGE_VALUE]: {
    unit: 'percent',
    explanation_default:
      'Actual deviation in percent of current transaction amount compared to average amount for given account combination (originator/beneficiary)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_DECISION_CASE_STATUS]: {
    unit: 'CaseStatus',
    explanation_default: 'Volume risk case status decision (open, closed, relieved)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.USAGE_RISK_SUSPICIOUS_WORDS]: {
    unit: 'Text',
    explanation_default: 'Suspicious words used in usage risk',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.USAGE_RISK_DECISION_CASE_STATUS]: {
    unit: 'CaseStatus',
    explanation_default:
      'Suspicious words used in usage risk case status decision (open, closed, relieved)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.REFERENCE_ACCOUNT_DECISION_CASE_STATUS]: {
    unit: 'CaseStatus',
    explanation_default: 'Reference account mismatch case status decision (open, closed, relieved)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ORIGINATOR_ACCOUNT_NUMBER_EQUALS_REFERENCE_ACCOUNT_NUMBER]: {
    unit: 'binary',
    explanation_default: 'Originator originator account number equal to reference account number',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.TRANSACTION_EXPECTED_BASED_ON_TIME_AND_AMOUNT]: {
    unit: 'score',
    explanation_default:
      'Score between 0 and 1 judging the regularity of a transaction. It is derived in equal portions from timeliness (matches regular frequency) and amount similarity (amount matching regular amount).',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ORIGINATOR_ACCOUNT_BLACKLISTED]: {
    unit: 'binary',
    explanation_default: 'Originator account is on the blacklist',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.BENEFICIARY_ACCOUNT_BLACKLISTED]: {
    unit: 'binary',
    explanation_default: 'Beneficiary account is on the blacklist',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_BLACKLISTED_DECISION_CASE_STATUS]: {
    unit: 'CaseStatus',
    explanation_default: 'Account blacklist case status decision (open, closed, relieved)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ROLLING_MONTH_INCOME]: {
    explanation_default: 'Accumulated amount credited to account within rolling month',
    explanation_money_mule:
      'Money mule receive high volumes of money within a short time frame (as it does not make sense to launder EUR 100 only) from typically an unusual source',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ROLLING_MONTH_OUTCOME]: {
    explanation_default: 'Accumulated amount debited from account within rolling month',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.FUNDS_PASSED_THROUGH_QUICKLY]: {
    unit: 'UNKNOWN', // TODO check if really used
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.SAME_FUNDS_SOURCE_COUNT_ROLLING_YEAR]: {
    unit: 'number',
    explanation_default:
      'Number of transactions from same funds source credited wihtin rolling year',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.SAME_FUNDS_SOURCE_COUNT_EVER]: {
    unit: 'number',
    explanation_default:
      'Number of transactions from same funds source credited in entire account history',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.AVERAGE_MONTH_INCOME]: {
    unit: 'currency-eur',
    explanation_default: 'Average monthly credit amount',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ROLLING_MONTH_INCOME_DIVIDED_BY_AVERAGE_MONTH_INCOME]: {
    unit: 'ratio',
    explanation_default: 'Rolling month credit amount devided by average monthly credit amount',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_ROLLING_YEAR]: {
    unit: 'number',
    explanation_default: 'Number of transactions for account holder within rolling year',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_EVER]: {
    unit: 'number',
    explanation_default: 'Number of transactions for account holder in entire account history',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_AVG_PER_MONTH_ROLLING_YEAR]: {
    unit: 'number',
    explanation_default:
      'Average monthly number of transactions for account holder wihtin rolling year',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_AVG_PER_MONTH_ROLLING_THREE_YEARS]: {
    unit: 'number',
    explanation_default:
      'Average monthly number of transactions for account holder wihtin rolling 3 years',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_TRANSACTION_COUNT_AVG_PER_MONTH_EVER]: {
    unit: 'number',
    explanation_default:
      'Average monthly number of transactions for account holder in entire account history',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_ACCOUNT_AGE_IN_DAYS]: {
    unit: 'days',
    explanation_default: 'Days since the account was opened by account holder (account age).',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_NATIONALITY_EQUAL_ACCOUNT_COUNTRY]: {
    unit: 'binary',
    explanation_default:
      'The account holding party nationality is equal to the account country (derived from IBAN)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_AGE_IN_YEARS]: {
    unit: 'years',
    explanation_default: 'Account holding party age in years (years since account was opened)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ORIGINATOR_DISTANCE_TO_NEXT_LABELED_MONEY_MULE]: {
    unit: 'NOTUSED',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.BENEFICIARY_DISTANCE_TO_NEXT_LABELED_MONEY_MULE]: {
    unit: 'NOTUSED',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.MONEY_MULE_DECISION_CASE_STATUS]: {
    unit: 'CaseStatus',
    explanation_default: 'Money Mule case status decision (open, closed, relieved)',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_HIGH]: {
    unit: 'binary',
    explanation_default: 'Account holder risk level is high',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_LOW]: {
    unit: 'binary',
    explanation_default: 'Account holder risk level is low',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL_MEDIUM]: {
    unit: 'binary',
    explanation_default: 'Account holder risk level is medium',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_FUNDS_BENEFICIARY]: {
    unit: 'binary',
    explanation_default: 'The account holding party is the funds benificiary',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_FUNDS_ORIGINATOR]: {
    unit: 'binary',
    explanation_default: 'The account holding party is the funds originator',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK_MEDIUM]: {
    unit: 'binary',
    explanation_default: 'The account holding party nationality is considered medium risk',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_NATIONALITY_RISK_HIGH]: {
    unit: 'binary',
    explanation_default: 'The account holding party nationality is considered high risk',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.TRANSACTION_INITIATING_PARTY_FUNDS_ORIGINATOR]: {
    unit: 'binary',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.VOLUME_RISK_ONE_MONTH_VELOCITY_ACCELERATOR_THRESHOLD_MULTIPLIER]: {
    unit: 'number', // TODO duplidate entry - which one is used?
    explanation_default:
      'Configured volume risk accelerator threshold multiplier. Example: If the volume threshold is EUR 10,000 then an accelerator of 2 means that transactions up to accumulated EUR 20,000 will be accepted and automatically filtered if the risk of excess is accepted by the operator above EUR 10,000.',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.SIMILARITY_HOLDER_NAME_ORIGINATOR_BENEFICIARY]: {
    unit: 'score',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home:
      'Equality of the family name of the originator and the beneficiary is a strong indicator, that it is a transfer of money between relatives.',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_SUM_INCOMING_SETTLED_FUNDS_LAST_MONTH_VS_SETTLED_FUNDS]: {
    unit: 'ratio',
    explanation_default:
      'The common settled funds divided by the incoming settled funds last month',
    explanation_money_mule: '',
    explanation_send_money_home:
      'It is typical for people with migration background to transfer back regularily a share of their previous income. Amounts that are the larger part of the income or exceed the income would not qualify as an indicator for false positives.',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_COUNTRY_OF_BIRTH_EQUALS_COUNTER_PARTY_COUNTRY_OF_IBAN]: {
    unit: 'binary',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home:
      'The country of birth of the account holder is the country where the money was transferred to. This is a necessary (but not sufficient) indicator for transferring money to relatives in the home country.',
  },
  [DataFeatureName.ORIGINATOR_ACCOUNT_HOLDER_NAME_EQUALS_REFERENCE_ACCOUNT_HOLDER_NAME]: {
    unit: 'binary',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ORIGINATOR_ACCOUNT_NUMBER_COUNTRY_EQUALS_REFERENCE_ACCOUNT_NUMBER_COUNTRY]: {
    unit: 'binary',
    explanation_default: '',
    explanation_money_mule: '',
    explanation_send_money_home: '',
    explanation_reference_account_mismatch: '',
  },
  [DataFeatureName.ACCOUNT_HOLDING_PARTY_GENDER]: {
    unit: 'gender',
  },

  // Account based
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_1_DAY]: {
    name: '1 Day Credit or Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_2_DAYS]: {
    name: '2 Days Credit or Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_3_DAYS]: {
    name: '3 Days Credit or Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_4_DAYS]: {
    name: '4 Days Credit or Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_5_DAYS]: {
    name: '5 Days Credit or Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_6_DAYS]: {
    name: '6 Days Credit or Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_1_WEEK]: {
    name: '1 Week Credit or Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit or Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit or Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_1_MONTH]: {
    name: '1 Month Credit or Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_2_MONTHS]: {
    name: '2 Months Credit or Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_OR_DEBIT_3_MONTHS]: {
    name: '3 Months Credit or Debit',
    timeframeText: '3 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_1_DAY]: {
    name: '1 Day Credit minus Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_2_DAYS]: {
    name: '2 Days Credit minus Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_3_DAYS]: {
    name: '3 Days Credit minus Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_4_DAYS]: {
    name: '4 Days Credit minus Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_5_DAYS]: {
    name: '5 Days Credit minus Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_6_DAYS]: {
    name: '6 Days Credit minus Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_1_WEEK]: {
    name: '1 Week Credit minus Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit minus Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit minus Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_1_MONTH]: {
    name: '1 Month Credit minus Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_2_MONTHS]: {
    name: '2 Months Credit minus Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_ACCOUNT_HOLDING_PARTY_CREDIT_MINUS_DEBIT_3_MONTHS]: {
    name: '3 Months Credit minus Debit',
    timeframeText: '3 months',
  },

  // Transfer based
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.TRANSFER_BASED.VOLUME_TRANSFER_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_1_DAY]: {
    name: '1 Day Credit or Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_2_DAYS]: {
    name: '2 Days Credit or Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_3_DAYS]: {
    name: '3 Days Credit or Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_4_DAYS]: {
    name: '4 Days Credit or Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_5_DAYS]: {
    name: '5 Days Credit or Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_6_DAYS]: {
    name: '6 Days Credit or Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_1_WEEK]: {
    name: '1 Week Credit or Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit or Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit or Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_1_MONTH]: {
    name: '1 Month Credit or Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_2_MONTHS]: {
    name: '2 Months Credit or Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_OR_DEBIT_3_MONTHS]: {
    name: '3 Months Credit or Debit',
    timeframeText: '3 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_1_DAY]: {
    name: '1 Day Credit minus Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_2_DAYS]: {
    name: '2 Days Credit minus Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_3_DAYS]: {
    name: '3 Days Credit minus Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_4_DAYS]: {
    name: '4 Days Credit minus Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_5_DAYS]: {
    name: '5 Days Credit minus Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_6_DAYS]: {
    name: '6 Days Credit minus Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_1_WEEK]: {
    name: '1 Week Credit minus Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit minus Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit minus Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_1_MONTH]: {
    name: '1 Month Credit minus Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_2_MONTHS]: {
    name: '2 Months Credit minus Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_TRANSFER_CREDIT_MINUS_DEBIT_3_MONTHS]: {
    name: '3 Months Credit minus Debit',
    timeframeText: '3 months',
  },

  // Customer based
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.CUSTOMER_BASED.VOLUME_CUSTOMER_ID_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_1_DAY]: {
    name: '1 Day Credit or Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_2_DAYS]: {
    name: '2 Days Credit or Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_3_DAYS]: {
    name: '3 Days Credit or Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_4_DAYS]: {
    name: '4 Days Credit or Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_5_DAYS]: {
    name: '5 Days Credit or Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_6_DAYS]: {
    name: '6 Days Credit or Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_1_WEEK]: {
    name: '1 Week Credit or Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit or Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit or Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_1_MONTH]: {
    name: '1 Month Credit or Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_2_MONTHS]: {
    name: '2 Months Credit or Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_OR_DEBIT_3_MONTHS]: {
    name: '3 Months Credit or Debit',
    timeframeText: '3 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_1_DAY]: {
    name: '1 Day Credit minus Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_2_DAYS]: {
    name: '2 Days Credit minus Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_3_DAYS]: {
    name: '3 Days Credit minus Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_4_DAYS]: {
    name: '4 Days Credit minus Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_5_DAYS]: {
    name: '5 Days Credit minus Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_6_DAYS]: {
    name: '6 Days Credit minus Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_1_WEEK]: {
    name: '1 Week Credit minus Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit minus Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit minus Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_1_MONTH]: {
    name: '1 Month Credit minus Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_2_MONTHS]: {
    name: '2 Months Credit minus Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_ID_CREDIT_MINUS_DEBIT_3_MONTHS]: {
    name: '3 Months Credit minus Debit',
    timeframeText: '3 months',
  },

  // Customer group based
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    index: 1,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    index: 2,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    index: 3,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    index: 4,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    index: 5,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    index: 6,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    index: 7,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    index: 8,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    index: 9,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    index: 10,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    index: 11,
  },
  [DataFeatureName.CUSTOMER_GROUP_BASED.VOLUME_CUSTOMER_GROUP_ID_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    index: 12,
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_1_DAY]: {
    name: '1 Day Credit or Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_2_DAYS]: {
    name: '2 Days Credit or Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_3_DAYS]: {
    name: '3 Days Credit or Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_4_DAYS]: {
    name: '4 Days Credit or Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_5_DAYS]: {
    name: '5 Days Credit or Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_6_DAYS]: {
    name: '6 Days Credit or Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_1_WEEK]: {
    name: '1 Week Credit or Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit or Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit or Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_1_MONTH]: {
    name: '1 Month Credit or Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_2_MONTHS]: {
    name: '2 Months Credit or Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_OR_DEBIT_3_MONTHS]: {
    name: '3 Months Credit or Debit',
    timeframeText: '3 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_1_DAY]: {
    name: '1 Day Credit minus Debit',
    timeframeText: '1 day',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_2_DAYS]: {
    name: '2 Days Credit minus Debit',
    timeframeText: '2 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_3_DAYS]: {
    name: '3 Days Credit minus Debit',
    timeframeText: '3 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_4_DAYS]: {
    name: '4 Days Credit minus Debit',
    timeframeText: '4 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_5_DAYS]: {
    name: '5 Days Credit minus Debit',
    timeframeText: '5 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_6_DAYS]: {
    name: '6 Days Credit minus Debit',
    timeframeText: '6 days',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_1_WEEK]: {
    name: '1 Week Credit minus Debit',
    timeframeText: '1 week',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_2_WEEKS]: {
    name: '2 Weeks Credit minus Debit',
    timeframeText: '2 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_3_WEEKS]: {
    name: '3 Weeks Credit minus Debit',
    timeframeText: '3 weeks',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_1_MONTH]: {
    name: '1 Month Credit minus Debit',
    timeframeText: '1 month',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_2_MONTHS]: {
    name: '2 Months Credit minus Debit',
    timeframeText: '2 months',
  },
  [DataFeatureName.ACCOUNT_BASED.VOLUME_CUSTOMER_GROUP_ID_CREDIT_MINUS_DEBIT_3_MONTHS]: {
    name: '3 Months Credit minus Debit',
    timeframeText: '3 months',
  },

  // FOR ACOUNTER PARTY VOLUME RULES
  // TRANSFER BASED
  // LOW - START
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 2,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 3,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 4,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 5,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 6,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 2,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 3,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 4,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 5,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 6,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 12,
  },
  // LOW - END

  // MEDIUM - START
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 2,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 3,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 4,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 5,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 6,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 2,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 3,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 4,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 5,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 6,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },
  // MEDIUM - END

  // HIGH - START
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 2,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 3,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 4,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 5,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 6,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 2,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 3,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 4,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 5,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 6,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_TRANSFER_BASED
    .VOLUME_TRANSFER_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },
  // HIGH - END

  // ACCOUNT BASED
  // LOW - START
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS]:
    {
      name: '2 Days - Credit only',
      timeframeText: '2 days',
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 2,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS]:
    {
      name: '3 Days - Credit only',
      timeframeText: '3 days',
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 3,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS]:
    {
      name: '4 Days - Credit only',
      timeframeText: '4 days',
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 4,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS]:
    {
      name: '5 Days - Credit only',
      timeframeText: '5 days',
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 5,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS]:
    {
      name: '6 Days - Credit only',
      timeframeText: '6 days',
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 6,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK]:
    {
      name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
      timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 7,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS]:
    {
      name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
      timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 8,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS]:
    {
      name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
      timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 9,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH]:
    {
      name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
      timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 10,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS]:
    {
      name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 8,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS]:
    {
      name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 9,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH]:
    {
      name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 10,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS]:
    {
      name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 11,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS]:
    {
      name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
      riskLevel: Strings.VolumeRules.LOW_RISK,
      index: 12,
    },
  // LOW - END

  // MEDIUM - START
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY]:
    {
      name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.CURRENT_DAY,
      riskLevel: Strings.VolumeRules.MEDIUM_RISK,
      index: 1,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },
  // MEDIUM - END

  // HIGH - START
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY]:
    {
      name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
      timeframeText: Strings.VolumeRules.CURRENT_DAY,
      riskLevel: Strings.VolumeRules.HIGH_RISK,
      index: 1,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS]:
    {
      name: '2 Days - Credit only',
      timeframeText: '2 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS]:
    {
      name: '3 Days - Credit only',
      timeframeText: '3 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS]:
    {
      name: '4 Days - Credit only',
      timeframeText: '4 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS]:
    {
      name: '5 Days - Credit only',
      timeframeText: '5 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS]:
    {
      name: '6 Days - Credit only',
      timeframeText: '6 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK]:
    {
      name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
      timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
      riskLevel: Strings.VolumeRules.HIGH_RISK,
      index: 7,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS]:
    {
      name: '2 Days - Debit only',
      timeframeText: '2 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS]:
    {
      name: '3 Days - Debit only',
      timeframeText: '3 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS]:
    {
      name: '4 Days - Debit only',
      timeframeText: '4 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS]:
    {
      name: '5 Days - Debit only',
      timeframeText: '5 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS]:
    {
      name: '6 Days - Debit only',
      timeframeText: '6 days',
      riskLevel: Strings.VolumeRules.HIGH_RISK,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK]:
    {
      name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
      riskLevel: Strings.VolumeRules.HIGH_RISK,
      index: 7,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS]:
    {
      name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
      riskLevel: Strings.VolumeRules.HIGH_RISK,
      index: 8,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS]:
    {
      name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
      riskLevel: Strings.VolumeRules.HIGH_RISK,
      index: 9,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED.VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH]:
    {
      name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
      timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
      riskLevel: Strings.VolumeRules.HIGH_RISK,
      index: 10,
    },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_ACCOUNT_BASED
    .VOLUME_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },
  // HIGH - END

  // CUSTOMER BASED
  // LOW - START
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 12,
  },
  // LOW - END

  // MEDIUM - START
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },
  // MEDIUM - END

  // HIGH - START
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_BASED
    .VOLUME_CUSTOMER_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },
  // HIGH - END

  // CUSTOMER GROUP BASED
  // LOW - START
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.LOW_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_LOW_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.LOW_RISK,
    index: 12,
  },
  // LOW - END

  // MEDIUM - START
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_MEDIUM_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.MEDIUM_RISK,
    index: 12,
  },
  // MEDIUM - END

  // HIGH - START
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_DAYS]: {
    name: '2 Days - Credit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_DAYS]: {
    name: '3 Days - Credit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_4_DAYS]: {
    name: '4 Days - Credit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_5_DAYS]: {
    name: '5 Days - Credit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_6_DAYS]: {
    name: '6 Days - Credit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_CREDIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.CREDIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },

  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_DAY]: {
    name: `${Strings.VolumeRules.CURRENT_DAY} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.CURRENT_DAY,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 1,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_DAYS]: {
    name: '2 Days - Debit only',
    timeframeText: '2 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_DAYS]: {
    name: '3 Days - Debit only',
    timeframeText: '3 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_4_DAYS]: {
    name: '4 Days - Debit only',
    timeframeText: '4 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_5_DAYS]: {
    name: '5 Days - Debit only',
    timeframeText: '5 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_6_DAYS]: {
    name: '6 Days - Debit only',
    timeframeText: '6 days',
    riskLevel: Strings.VolumeRules.HIGH_RISK,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_WEEK]: {
    name: `${Strings.VolumeRules.ONE_WEEK_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_WEEK_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 7,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_WEEKS]: {
    name: `${Strings.VolumeRules.TWO_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 8,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_WEEKS]: {
    name: `${Strings.VolumeRules.THREE_WEEKS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_WEEKS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 9,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_1_MONTH]: {
    name: `${Strings.VolumeRules.ONE_MONTH_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.ONE_MONTH_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 10,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_2_MONTHS]: {
    name: `${Strings.VolumeRules.TWO_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.TWO_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 11,
  },
  [DataFeatureName.COUNTRY_RISK_CUSTOMER_GROUP_BASED
    .VOLUME_CUSTOMER_GROUP_ID_COUNTER_PARTY_COUNTRY_RISK_HIGH_DEBIT_3_MONTHS]: {
    name: `${Strings.VolumeRules.THREE_MONTHS_ROLLING} - ${Strings.VolumeRules.DEBIT_ONLY}`,
    timeframeText: Strings.VolumeRules.THREE_MONTHS_ROLLING,
    riskLevel: Strings.VolumeRules.HIGH_RISK,
    index: 12,
  },
  // HIGH - END
};

export function getContextExplanation(context, featureName) {
  if (DataFeatureDetails[featureName] === undefined) {
    return '';
  }
  switch (context) {
    case DataFeatureName.MONEY_MULE:
      if (
        DataFeatureDetails[featureName].explanation_money_mule !== undefined &&
        DataFeatureDetails[featureName].explanation_money_mule.length > 0
      ) {
        return DataFeatureDetails[featureName].explanation_money_mule;
      }
      break;
    case DataFeatureName.SEND_MONEY_HOME_TO_FAMILY:
      if (
        DataFeatureDetails[featureName].explanation_send_money_home !== undefined &&
        DataFeatureDetails[featureName].explanation_send_money_home.length > 0
      ) {
        return DataFeatureDetails[featureName].explanation_send_money_home;
      }
      break;
    case DataFeatureName.REFERENCE_ACCOUNT_MISMATCH:
      if (
        DataFeatureDetails[featureName].explanation_reference_account_mismatch !== undefined &&
        DataFeatureDetails[featureName].explanation_reference_account_mismatch.length > 0
      ) {
        return DataFeatureDetails[featureName].explanation_reference_account_mismatch;
      }
      break;
    default:
      break;
  }

  if (
    DataFeatureDetails[featureName].explanation_default !== undefined &&
    DataFeatureDetails[featureName].explanation_default.length > 0
  ) {
    return DataFeatureDetails[featureName].explanation_default;
  }
  return '';
}

const CURRENCY_FEATURE_UNIT_PREFIX = 'currency-';

const isCurrencyUnit = (featureUnit) =>
  !!featureUnit && featureUnit.includes(CURRENCY_FEATURE_UNIT_PREFIX);

const getReadableCurrencyFeatureUnit = (featureUnit) =>
  featureUnit.substring(CURRENCY_FEATURE_UNIT_PREFIX.length).toUpperCase();

export function getFeatureUnit(featureName) {
  if (DataFeatureDetails[featureName] === undefined) {
    return '';
  }
  return DataFeatureDetails[featureName].unit;
}

export function getReadableFeatureUnit(featureName, featureUnit) {
  const uiFeatureUnit = getFeatureUnit(featureName) || featureUnit;

  const result = isCurrencyUnit(uiFeatureUnit)
    ? getReadableCurrencyFeatureUnit(uiFeatureUnit)
    : Strings.Units[uiFeatureUnit];

  return result || '';
}

export function getFeatureDisplay(featureName) {
  if (DataFeatureDetails[featureName] === undefined) {
    return '';
  }
  return DataFeatureDetails[featureName];
}

export default DataFeatureDetails;
