/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { formatMaskedValue } from 'utils/helpers/cases/case-utility';
import { useSelector } from 'react-redux';
import { RootState } from 'middleware/store';
import { SectionLink } from 'components/popover/person-details/person-details-tab/section-link';
import { isEmpty } from 'lodash';
import { TabPanel } from 'components/tabs/tab';
import { DbaData } from 'components/popover/person-details/person-details-tab/dba/dba-data';

interface GeneralDataProps {
  personDetails: any;
  showUnmaskedData: boolean;
}

export const GeneralData: FC<GeneralDataProps> = ({ personDetails, showUnmaskedData }) => {
  const { t } = useTranslation();

  const tabView: { [k: string]: { key: string; back?: string } } = {
    general: {
      key: 'general',
    },

    doingBusinessAs: {
      key: 'doingBusinessAs',
      back: 'general',
    },
  };
  const [currentView, setSelectedView] = useState(tabView.general);

  const handleChange = (viewKey: string) => () => {
    setSelectedView(tabView[viewKey]);
  };

  const allMasks = useSelector((state: RootState) => state.masks?.all);
  const formatMaskedValueInternal = (fieldObject: any) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);

  const partyData = personDetails?.parties
    ? {
        ...personDetails?.parties[0],
        calculatedRiskLevel: personDetails?.calculatedRiskLevel,
        transaction: personDetails?.transaction,
        underwriterData: personDetails?.underwriterData,
      }
    : personDetails;

  const generalData = {
    customerId: formatMaskedValueInternal(partyData?.customerId),
    taxId: formatMaskedValueInternal(partyData?.taxId),
    taxIdType: partyData?.taxIdType,
    doingBusinessAs: partyData?.dba,
  };
  return (
    <>
      <TabPanel index={tabView.general.key} value={currentView.key}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.general.counterpartyId.heading')}
            </Typography>
            <Typography variant="body2" data-testid="customer-id">
              {generalData.customerId ? generalData.customerId : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.taxId.heading')}
            </Typography>
            <Typography variant="body2" data-testid="tax-id">
              {generalData.taxId ?? '-'}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.taxIdType.heading')}
            </Typography>
            <Typography variant="body2">{generalData.taxIdType ?? '-'}</Typography>
          </Grid>
          {!isEmpty(generalData.doingBusinessAs) && (
            <Grid item xs={12} className="border-bottom">
              <SectionLink
                label={t('case:userPopover.personal.identityDocument.heading')}
                changeTabViewOnClick={() => handleChange(tabView.doingBusinessAs.key)}
              />
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel index={tabView.doingBusinessAs.key} value={currentView.key}>
        <DbaData
          showUnmaskedData={showUnmaskedData}
          dbaContactDetails={generalData.doingBusinessAs}
          handleChange={handleChange(tabView.doingBusinessAs.back as string)}
        />
      </TabPanel>
    </>
  );
};
