const RuleManagerIds = {
  SANCTION_AND_PEP_SECTION_ID: '_sanctionAndPep',
  BLACKLIST_SECTION_ID: '_blacklist',
  COUNTRY_RULES_SECTION_ID: '_countryRules',
  VOLUME_RULES_SECTION_ID: '_volumeRules',
  RISKY_WORDS_RULES_SECTION_ID: '_riskyWordsRules',
  MACHINE_LEARNING_RULES_SECTION_ID: '_machineLearningRules',
  ACCOUNT_BLACKLISTED_RULES_SECTION_ID: '_accountBlacklistedRules',
  CASH_VOLUME_RISK_SECTION_ID: '_cashVolumneRisk',
  MONEY_MULE_SECTION_ID: '_moneyMule',
  SHELL_COMPANY_SECTION_ID: '_shellCompany',
  SEND_MONEY_HOME_SECTION_ID: '_sendMoneyHomeToFamily',
  REFERENCE_ACCOUNT_SECTION_ID: '_referenceAccountMismatch',
  SHARE_CROSS_BANK_SECTION_ID: '_shareCrossBank',
  PASSTHROUGH_RULES_SECTION_ID: '_passthroughRules',
  BEHAVIORAL_ANALYSIS_SECTION_ID: '_behavioralAnalysis',
  SMURFING_RULES_SECTION_ID: '_smurfingRules',
};

export default RuleManagerIds;
