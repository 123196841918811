import { getTimezoneNumber } from 'modules/configuration/rule-manager/common/helpers/util';
import querystring from 'querystring';
import Utility from 'utils/helpers/utility';

class DashboardManager {
  constructor(communicator) {
    if (!communicator) {
      throw new Error('Dashboard Manager API Communicator instance is required.');
    }
    this.communicator = communicator;
  }

  // eslint-disable-next-line class-methods-use-this
  prepareQuery(params) {
    const { startDate, endDate } = params;
    const zoneId = getTimezoneNumber();
    const queryParams = {
      startDate: Utility.formatDateForApi(startDate),
      endDate: Utility.formatDateForApi(endDate),
      zoneId,
    };
    return querystring.stringify(queryParams);
  }

  getGroupsCountMetric(params) {
    return this.communicator.getGroupsCountMetric(params);
  }

  getGroupsTypeCountMetric(params) {
    return this.communicator.getGroupsTypeCountMetric(params);
  }

  getGroupsHealthMetric() {
    return this.communicator.getGroupsHealthMetric();
  }

  getGroupsFalsePositiveRate(params) {
    const { currentPeriod, previousPeriod, periodDays } = params;
    const tasks = [];
    tasks.push(
      this.communicator.getGroupsFalsePositiveRate({
        startDate: currentPeriod.startDate,
        endDate: currentPeriod.endDate,
        key: 'currentPeriodFalsePositiveData',
      }),
    );
    tasks.push(
      this.communicator.getGroupsFalsePositiveRate({
        startDate: previousPeriod.startDate,
        endDate: previousPeriod.endDate,
        key: 'previousPeriodFalsePositiveData',
      }),
    );
    return Promise.all(tasks).then((tasksResult) => {
      tasksResult.forEach((item) => {
        item.periodDays = periodDays;
      });
      return Promise.resolve(tasksResult);
    });
  }

  getGroupsTruePositivePattern(params) {
    return this.communicator.getGroupsTruePositivePattern(params);
  }

  getGroupsCasesPerformance(params) {
    return this.communicator.getGroupsCasesPerformance(params);
  }

  getRiskyWordsPattern(params) {
    return this.communicator.getRiskyWordsPattern(params);
  }

  getGroupsTruePositivePatternByInstance(params) {
    return this.communicator.getGroupsTruePositivePatternByInstance(params);
  }

  getGroupsFalsePositivePattern(params) {
    return this.communicator.getGroupsFalsePositivePattern(params);
  }
}

export default DashboardManager;
