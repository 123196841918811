import CustomStyles from 'utils/helpers/custom-styles';
import TENANT_IDS from 'utils/constants/tenant-ids';

const CustomStyleTenants = [];

CustomStyleTenants.push({
  id: TENANT_IDS.multipleEnviroments.mapFinTech,
  name: 'map',
  cssFilePath: './css/style.css',
  selectComponentStyle: CustomStyles.SelectComponent,
  faviconUrl: '/app_icon.png?v=2',
});

CustomStyleTenants.push({
  id: TENANT_IDS.multipleEnviroments.one23Signed,
  name: 'signed',
  cssFilePath: './css/style.css',
  selectComponentStyle: CustomStyles.SelectComponent,
  faviconUrl: '/signed.png?v=2',
});

CustomStyleTenants.push({
  id: TENANT_IDS.multipleEnviroments.six,
  name: 'six',
  cssFilePath: './css/style.css',
  selectComponentStyle: CustomStyles.SelectComponent,
  faviconUrl: '/app_icon.png?v=2',
});

export default CustomStyleTenants;
