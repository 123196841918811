export const ROUND_AMOUNT_ACTIONS = {
  ratioIncreaseDecrease: {
    fetch: {
      data: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_GET',
      success: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_GET_SUCCESS',
      error: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_GET_ERROR',
    },
    update: {
      data: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_UPDATE',
      success: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_UPDATE_SUCCESS',
      error: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_UPDATE_ERROR',
    },
    delete: {
      data: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_DELETE',
      success: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_DELETE_SUCCESS',
      error: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE_CONFIG_DELETE_ERROR',
    },
  },
};
