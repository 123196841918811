import { Grid, Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import { useTranslation } from 'react-i18next';

const CountingScoreDetails = ({ maxPoints, scoreData, popoverMode, title }) => {
  const { t } = useTranslation();
  const metaDataKeys = getMetaDataKeys();
  const originalData = scoreData?.[metaDataKeys.originalData];
  const calculatedTimeWindow = parseInt(
    scoreData?.RISK_FACTOR_INSTANCE?.settings?.timeWindow || '0',
    10,
  );

  const getDetailsType = (riskFactorInstance) => {
    const configs = riskFactorInstance?.pointsConfigs || [];
    const isAnomaly = configs.find((it) => it.name === 'ANOMALY') !== undefined;
    return isAnomaly ? 'anomaly' : 'caseStatus';
  };

  const getPluralityKey = (number) => (number > 1 ? 'many' : 'one');

  const type = getDetailsType(scoreData?.RISK_FACTOR_INSTANCE);

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData[metaDataKeys.calculatedPoints] ?? 0}
          maxPoints={maxPoints}
          headline={title}
        />
      }
      highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
      detailsComponent={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="body2"
              color="textSecondary"
              data-testid={`case-status-${scoreData?.RISK_FACTOR_INSTANCE?.uniqueId}-original-data-container`}
            >
              {`${originalData} ${t(
                `case:userPopover.riskRating.${type}.countingNoun.${getPluralityKey(originalData)}`,
              )}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              color="textSecondary"
              data-testid={`case-status-${scoreData?.RISK_FACTOR_INSTANCE?.uniqueId}-time-window-container`}
            >
              <>
                {t(`case:userPopover.riskRating.${type}.months.heading`, {
                  months: calculatedTimeWindow,
                })}
              </>
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

export { CountingScoreDetails };
