import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class CustomerRiskRatingConfigApiCommunicator extends APICommunicator {
  getCustomerRiskRatingConfig(tenantId, configVersion) {
    if (configVersion) {
      return this.getCustomerRiskRatingConfigInVersion(tenantId, configVersion).then(
        (data) => data,
      );
    }

    return this.getCustomerRiskRatingConfigLatestVersion(tenantId);
  }

  getCustomerRiskRatingConfigForIndividual(tenantId, configVersion) {
    if (configVersion) {
      return this.getCustomerRiskRatingConfigInVersionForIndividual(tenantId, configVersion).then(
        (data) => data,
      );
    }
    return this.getCustomerRiskRatingConfigForIndividualLatestVersion(tenantId);
  }

  getCustomerRiskRatingConfigInVersion(tenantId, configVersion) {
    const apiUrl = API_URLS.config.customerRiskRating.organization.getConfigWithVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.configVersion, configVersion);

    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getCustomerRiskRatingConfigInVersionForIndividual(tenantId, configVersion) {
    const apiUrl = API_URLS.config.customerRiskRating.individual.getConfigWithVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.configVersion, configVersion);

    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getCustomerRiskRatingConfigLatestVersion(tenantId) {
    const apiUrl = API_URLS.config.customerRiskRating.organization.get.replaceAll(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getCustomerRiskRatingConfigForIndividualLatestVersion(tenantId) {
    const apiUrl = API_URLS.config.customerRiskRating.individual.get.replaceAll(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateCustomerRiskRatingConfig(tenantId, payload) {
    const apiUrl = API_URLS.config.customerRiskRating.organization.update.replaceAll(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.postJSONForTenant(
      apiUrl,
      payload,
      ErrorCode.CUSTOMER_RISK_RATING_CONFIG_UPDATE_DATA_INVALID,
    );
  }

  requestForUpdate(payload) {
    return this.postJSONForTenant(
      API_URLS.config.requestForUpdate.create,
      payload,
      ErrorCode.CUSTOMER_RISK_RATING_CONFIG_UPDATE_DATA_INVALID,
    );
  }

  updateCustomerRiskRatingConfigForIndividual(tenantId, payload) {
    const apiUrl = API_URLS.config.customerRiskRating.individual.update.replaceAll(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.postJSONForTenant(
      apiUrl,
      payload,
      ErrorCode.CUSTOMER_RISK_RATING_CONFIG_UPDATE_DATA_INVALID,
    );
  }

  createRiskFactor(tenantId, payload) {
    const apiUrl = API_URLS.config.customerRiskRating.organization.createRiskFactor.replaceAll(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  createRiskFactorForIndividual(tenantId, payload) {
    const apiUrl = API_URLS.config.customerRiskRating.individual.createRiskFactor.replaceAll(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  deleteRiskFactor(tenantId, payload) {
    let baseApiUrl = API_URLS.config.customerRiskRating.organization.deleteRiskFactor;
    if (payload.isIndividual) {
      baseApiUrl = API_URLS.config.customerRiskRating.individual.deleteRiskFactor;
    }

    const apiUrl = baseApiUrl
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.riskFactorId, payload.riskFactorId);

    return this.deleteJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default CustomerRiskRatingConfigApiCommunicator;
