import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

const validateConfigJson = (json) => {
  if (json) {
    return Promise.resolve(json);
  }
  return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
};

export class ReferenceAccountV2ConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Reference Account V2 Config API Communicator instance is required.');
    }
  }

  getReferenceAccountV2Config() {
    return this.communicator.fetchConfig().then((json) => validateConfigJson(json));
  }

  updateReferenceAccountV2Config(data) {
    return this.communicator.updateConfig(data);
  }

  deleteReferenceAccountV2Config(id) {
    return this.communicator.deleteConfig(id);
  }
}
