import Delete from 'components/controls/icon-button/delete';
import Copy from 'components/controls/icon-button/copy';
import Edit from 'components/controls/icon-button/edit';
import Settings from 'components/controls/icon-button/settings';
import Back from 'components/controls/icon-button/back';
import Close from 'components/controls/icon-button/close';
import Assigned from 'components/controls/icon-button/assigned';
import ExpandMore from 'components/controls/icon-button/expand-more';
import AttachFile from 'components/controls/icon-button/attach-file';
import CaseGrouping from 'components/controls/icon-button/case-grouping';

const IconButtons = {
  Edit,
  Settings,
  Delete,
  Back,
  Close,
  Assigned,
  CaseGrouping,
  ExpandMore,
  AttachFile,
  Copy,
};

export default IconButtons;
