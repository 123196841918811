import {
  anyCasesRoute,
  autoClosedCasesRoute,
  closedCasesRoute,
  openCasesRoute,
} from 'utils/constants/routes';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import CaseStatus, { CaseStatuses } from 'models/case/case-state/case-status';
import { CaseDetail } from 'models/case/case-detail';
import { Route } from 'models/common';
import { SettingsAndAccessContext } from 'modules/layout/sidebar/models/settings-and-access-context';

export const getCasesRoute = (caseStatus: CaseStatus) => {
  switch (caseStatus) {
    case CaseStatuses.OPEN:
      return openCasesRoute;
    case CaseStatuses.CLOSED:
      return closedCasesRoute;
    case CaseStatuses.AUTO_CLOSED:
      return autoClosedCasesRoute;
    default:
      return anyCasesRoute;
  }
};

export const openCaseDetailInNewTab = (caseDetail: CaseDetail) => {
  const casesRoute = getCasesRoute(caseDetail.caseStatus);
  const linkToCaseDetailUrl = `${casesRoute.path}/${caseDetail.uuid}`;
  window.open(linkToCaseDetailUrl, '_blank');
};

export const isAccessibleRoute = (
  route: Route,
  settingsAndAccessContext: SettingsAndAccessContext,
) => {
  const { detachmentConfig, userPermissions } = settingsAndAccessContext;

  const checkDetachmentAndPermission = (key: string, permissionToCheck: string) => {
    if (key && permissionToCheck) {
      return isFeatureAccessible(key, detachmentConfig, userPermissions, permissionToCheck);
    }

    if (key) {
      return isFeatureAccessible(key, detachmentConfig);
    }

    if (permissionToCheck) {
      return isFeatureAccessible(permissionToCheck, userPermissions);
    }

    return true;
  };

  const checkAdditional = (checkFunc: CallableFunction) =>
    !checkFunc || checkFunc(settingsAndAccessContext);

  return (
    checkDetachmentAndPermission(route.detachmentConfigKey, route.permission) &&
    checkAdditional(route.additionalAccessibleCheck)
  );
};

export const getAccessibleRoutes = (
  isAuthenticated = false,
  settingsAndAccessContext: SettingsAndAccessContext,
  routes: Array<Route>,
) => {
  const filteredRoutes: Array<Route> = [];

  if (!isAuthenticated) {
    return filteredRoutes;
  }

  routes?.forEach((singleRoute: Route) => {
    if (isAccessibleRoute(singleRoute, settingsAndAccessContext)) {
      filteredRoutes.push(singleRoute);
    }
  });

  return filteredRoutes;
};
