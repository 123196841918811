import { Grid, ListItemText, Typography } from '@mui/material';
import { Translation } from 'react-i18next';
import { WheelPoints } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points';

const WheelPointsWithHeadline = ({ points, maxPoints, headline, subHead, size }) => (
  <Translation>
    {(t) => (
      <Grid container justifyContent="space-between" alignItems="center" direction="row">
        <Grid item xs={9}>
          <ListItemText
            primary={<Typography variant="body2">{headline}</Typography>}
            secondary={
              <Typography variant="body2" color="textSecondary">
                {subHead}
              </Typography>
            }
          />
        </Grid>
        <Grid container item xs={3} justifyContent="flex-end">
          <WheelPoints
            points={points}
            size={size}
            maxPoints={maxPoints}
            tooltip={
              <>
                <Typography variant="overline" component="p" className="color-white lh-1">
                  {points ?? 0}{' '}
                  {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
                </Typography>

                <Typography variant="overline" className="color-on-surface-disabled lh-1">
                  {maxPoints} {t('configuration:customerRiskRating.riskFactor.points.pts.max.text')}
                </Typography>
              </>
            }
          />
        </Grid>
      </Grid>
    )}
  </Translation>
);

export { WheelPointsWithHeadline };
