const TabPanel = (props) => {
  const { children, value, index, visibility, 'data-testid': dataTestId } = props;
  return (
    <>
      {visibility && (
        <div
          className="w-100"
          style={value === index ? { display: 'inline' } : { display: 'none' }}
          data-testid={dataTestId}
        >
          {children}
        </div>
      )}
      {!visibility && (
        <div id={value}>{value === index && <div data-testid={dataTestId}>{children}</div>}</div>
      )}
    </>
  );
};

export { TabPanel };
