import { TRANSACTION_FIELDS_ACTIONS } from 'middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { TransactionFieldsApiCommunicator } from 'services/transaction-fields-api-communicator';
import { TransactionFieldsManager } from 'services/transaction-fields-manager';
import {
  getTransactionFieldsError,
  getTransactionFieldsSuccess,
} from 'middleware/actions/transaction-fields';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

const apiCommunicator = new TransactionFieldsApiCommunicator();
const manager = new TransactionFieldsManager(apiCommunicator);

function* getTransactionFieldsBegin(action) {
  yield put(startProcessing(TRANSACTION_FIELDS_ACTIONS.fetch.data));
  try {
    const json = yield manager.getTransactionFields();
    yield put(getTransactionFieldsSuccess(json));
  } catch (error) {
    yield put(getTransactionFieldsError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TRANSACTION_FIELDS_ACTIONS.fetch.data));
}

export function* getTransactionFieldsWatcher() {
  yield takeLatest(TRANSACTION_FIELDS_ACTIONS.fetch.data, getTransactionFieldsBegin);
}
