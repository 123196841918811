import { TRX_CASE_DETAIL_ACTIONS } from 'modules/trx-cases/case-detail/middleware/action-types';

export const addCaseNote = (payload) => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.add.data,
  payload,
});

export const addCaseNoteSuccess = (payload) => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.add.success,
  payload,
});

export const addCaseNoteError = () => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.add.error,
});

export const downloadAttachmentNote = (payload) => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.data,
  payload,
});

export const downloadAttachmentNoteSuccess = (payload) => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.success,
  payload,
});

export const downloadAttachmentNoteError = () => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.error,
});

export const getDistinctUserIdsFromNotes = () => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.getUniqueUserId.data,
});

export const getDistinctUserIdsFromNotesSuccess = (payload) => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.getUniqueUserId.success,
  payload,
});

export const getDistinctUserIdsFromNotesError = () => ({
  type: TRX_CASE_DETAIL_ACTIONS.caseNotes.getUniqueUserId.error,
});
