import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { unmask } from 'middleware/actions/mask';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TAB_KEYS } from 'components/popover/person-details/person-details-tab/person-details-tab';
import { WheelPoints } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points';
import { PersonPopover } from 'components/popover/person-popover';
import { TabPanel } from 'components/tabs/tab';
import {
  activeGenericRiskFactors,
  buildCategoryMaxPoints,
  buildMaxPointsByRiskFactor,
  buildRiskScoreCategoryDerivedData,
  getRiskScoreCategory,
} from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import { RiskRatingDataDetailAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/risk-rating-data-detail-accordion';
import { RiskRatingDataSummaryView } from 'components/popover/person-details/person-details-tab/risk-rating/risk-rating-data-summary-view';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 60,
  },
  riskLegend: {
    width: 10,
    height: 5,
    borderRadius: 1,
    backgroundColor: ({ risk }) =>
      risk ? theme.custom.riskChipComponent[risk].color : 'transparent',
  },
}));

const tabView = {
  summary: {
    key: 'summary',
  },
  detail: {
    key: 'detail',
  },
};

/**
 * Risk rating data for person popover, person details, case
 * @param {*} props
 */
const RiskRatingData = ({
  getScoreOnly,
  enablePopover,
  item, // customer case from list
  caseConfig,
  tenantConfig,
  isIndividual,
  popoverMode,
  removeScoreLegend,
  isCustomerList = false,

  /* object keys from prepareRiskRatingDataFromCustomerCase in case-utility */
  summary = {},
  detail = {},
  manualRiskLevelChange,
  manualOverrides,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentView, setSelectedView] = useState(tabView.summary);
  const [selectedCategory, setSelectedCategory] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUnmasked, setIsUnmasked] = useState(false);

  const [genericRiskFactors, setGenericRiskFactors] = useState([]);
  const [maxPointsByRiskFactor, setMaxPointsByRiskFactor] = useState({});
  const [riskScoreCategory, setRiskScoreCategory] = useState([]);
  const [riskScoreCategoryDerivedData, setRiskScoreCategoryDerivedData] = useState({});
  const [categoryMaxPoints, setCategoryMaxPoints] = useState({});
  const [maxPoints, setMaxPoints] = useState(0);

  const riskRatingConfig = useSelector((state) =>
    isIndividual
      ? state.configContainer.customerRiskRating.individual
      : state.configContainer.customerRiskRating.organization,
  );

  const openPopover = (event) => {
    const anchorResult = anchorEl && anchorEl === event.currentTarget ? null : event.currentTarget;
    event.stopPropagation();
    setAnchorEl(anchorResult);

    const dispatchUuid = item?.caseUuid;
    if (!!anchorResult && !isUnmasked) {
      dispatch(
        unmask({
          masterUuid: dispatchUuid,
          displayUuid: dispatchUuid,
          uuids: [dispatchUuid],
          isCustomerCase: true,
        }),
      );

      setIsUnmasked(true);
    }
  };

  const closePopover = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChange = (viewKey, category) => () => {
    setSelectedView(tabView[viewKey]);
    setSelectedCategory(category);
  };

  useEffect(() => {
    setGenericRiskFactors(activeGenericRiskFactors(riskRatingConfig));
    setCategoryMaxPoints(buildCategoryMaxPoints(riskRatingConfig));
  }, [riskRatingConfig]);

  useEffect(() => {
    setMaxPointsByRiskFactor(buildMaxPointsByRiskFactor(riskRatingConfig, genericRiskFactors));
  }, [riskRatingConfig, genericRiskFactors]);

  useEffect(() => {
    setRiskScoreCategory(getRiskScoreCategory());
  }, []);

  useEffect(() => {
    setRiskScoreCategoryDerivedData(buildRiskScoreCategoryDerivedData(detail));
  }, [detail]);

  useEffect(() => {
    setMaxPoints(Object.values(categoryMaxPoints).reduce((prev, val) => prev + val, 0));
  }, [categoryMaxPoints]);

  /**
   * Detail view tabs of each category in customer risk rating
   */
  const DetailViewTab = () => {
    const tabKeys = Object.keys(riskScoreCategoryDerivedData).filter(
      (k) => riskScoreCategoryDerivedData[k].catScoreItemsAvailable,
    );

    const [selectedTabKey, setSelectedTab] = useState(selectedCategory ?? tabKeys[0]);

    const handleDetailViewTabChange = (event, newTabKey) => {
      setSelectedTab(newTabKey);
    };

    const classes = useStyles({});

    const labelComponent = (category, t) => {
      const { catTotalPoints } = riskScoreCategoryDerivedData[category];

      return (
        <Grid container direction="column" justifyContent="flex-end">
          <Grid item>
            <WheelPoints
              points={catTotalPoints}
              maxPoints={categoryMaxPoints[category]}
              size="small"
            />
          </Grid>
          <Grid>
            <Typography variant="caption" component="span">
              {t(`configuration:customerRiskRating.riskFactor.${category}.heading`)}
            </Typography>
          </Grid>
        </Grid>
      );
    };

    return (
      <div className="mt-n2">
        <Tabs
          value={selectedTabKey}
          onChange={handleDetailViewTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          className="ml-n2 mr-n2"
        >
          {tabKeys.map((tabKey, idx) => (
            <Tab
              data-testid={`tab-risk-rating-details-${tabKey}`}
              key={idx}
              value={tabKey}
              label={labelComponent(tabKey, t)}
              classes={{ root: classes.root }}
            />
          ))}
        </Tabs>
        <hr className="w-100 mt-n1xs" />

        <Box>
          {tabKeys.map((tabKey, idx) => (
            <TabPanel key={idx} index={tabKey} value={selectedTabKey} visibility>
              <RiskRatingDataDetailAccordion
                category={tabKey}
                catScoreItems={riskScoreCategoryDerivedData[tabKey].catScoreItems}
                catTotalPoints={riskScoreCategoryDerivedData[tabKey].catTotalPoints}
                popoverMode={popoverMode}
                categoryMaxPoints={categoryMaxPoints}
                isIndividual={isIndividual}
                maxPointsByRiskFactor={maxPointsByRiskFactor}
                caseConfig={caseConfig}
                handleChange={(tab) => handleChange(tab)}
              />
            </TabPanel>
          ))}
        </Box>
      </div>
    );
  };

  const SummaryViewWithProps = () => (
    <RiskRatingDataSummaryView
      getScoreOnly={getScoreOnly}
      removeScoreLegend={removeScoreLegend}
      popoverMode={popoverMode}
      manualRiskLevelChange={manualRiskLevelChange}
      manualOverrides={manualOverrides}
      summary={summary}
      maxPoints={maxPoints}
      isIndividual={isIndividual}
      riskScoreCategory={riskScoreCategory}
      riskScoreCategoryDerivedData={riskScoreCategoryDerivedData}
      categoryMaxPoints={categoryMaxPoints}
      handleChange={(view, cat) => handleChange(view, cat)}
      isCustomerList={isCustomerList}
    />
  );

  if (getScoreOnly) {
    return enablePopover ? (
      <div
        onClick={openPopover}
        onKeyPress={openPopover}
        role="button"
        tabIndex={0}
        className="clickable"
      >
        <SummaryViewWithProps />
        <PersonPopover
          anchorEl={anchorEl}
          openDetails={!!anchorEl}
          handleClick={closePopover}
          isCustomer
          isCompanyTransaction={!isIndividual}
          defaultTab={TAB_KEYS.riskRating}
          item={item}
          caseConfig={caseConfig}
          tenantConfig={tenantConfig}
        />
      </div>
    ) : (
      <SummaryViewWithProps />
    );
  }

  return (
    <>
      <TabPanel index={tabView.summary.key} value={currentView.key}>
        <SummaryViewWithProps />
      </TabPanel>
      <TabPanel index={tabView.detail.key} value={currentView.key}>
        <DetailViewTab />
      </TabPanel>
    </>
  );
};

export { RiskRatingData };
