import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

interface CursorStateProps {
  children: ReactNode;
}

const CursorState: FC<CursorStateProps> = ({ children }) => {
  const isProcessing = useSelector((state: any) => state.processing?.isProcessing);

  return (
    <div
      style={{ cursor: isProcessing ? 'wait' : 'auto' }}
      className="d-flex flex-column w-100 h-vh-100"
    >
      {children}
    </div>
  );
};

export default CursorState;
