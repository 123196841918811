import { DetachmentUtilActions } from 'middleware/action-types';
import {
  deleteDetachmentConfigError,
  deleteDetachmentConfigSuccess,
  getDetachmentConfigError,
  getDetachmentConfigSuccess,
  postDetachmentConfigError,
  postDetachmentConfigSuccess,
  putDetachmentConfigError,
  putDetachmentConfigSuccess,
} from 'middleware/actions/detachment-config';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import APICommunicator from 'services/api-communicator';
import DetachmentConfigManager from 'services/detachment-config-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';

const apiCommunicator = new APICommunicator();
const manager = new DetachmentConfigManager(apiCommunicator);

function* updateDetachmentConfigUtilBegin(action) {
  const tenantId = TenantManagementService.getActiveTenantId();

  try {
    yield put(startProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE));
    const json = yield manager.updateDetachmentConfig(action.payload, tenantId);
    yield put(putDetachmentConfigSuccess(json));
    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE));
  } catch (error) {
    yield put(putDetachmentConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE));
  }
}

function* insertDetachmentConfigUtilBegin(action) {
  const tenantId = TenantManagementService.getActiveTenantId();

  try {
    yield put(startProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT));

    const json = yield manager.insertDetachmentConfig(action.payload, tenantId);
    yield put(postDetachmentConfigSuccess(json));

    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT));
  } catch (error) {
    yield put(postDetachmentConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT));
  }
}

function* getDetachmentConfigUtilBegin(action) {
  const tenantId = TenantManagementService.getActiveTenantId();

  try {
    yield put(startProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET));

    const json = yield manager.getDetachmentConfig(tenantId);
    yield put(getDetachmentConfigSuccess(json));

    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET));
  } catch (error) {
    yield put(getDetachmentConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET));
  }
}

function* resetDetachmentConfigUtilBegin(action) {
  const tenantId = TenantManagementService.getActiveTenantId();

  try {
    yield put(startProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET));

    const json = yield manager.resetDetachmentConfig(tenantId);
    yield put(deleteDetachmentConfigSuccess(json));

    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET));
  } catch (error) {
    yield put(deleteDetachmentConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET));
  }
}

export function* updateDetachmentConfigUtilWatcher() {
  yield takeLatest(
    DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE,
    updateDetachmentConfigUtilBegin,
  );
}

export function* insertDetachmentConfigUtilWatcher() {
  yield takeLatest(
    DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT,
    insertDetachmentConfigUtilBegin,
  );
}

export function* getDetachmentConfigUtilWatcher() {
  yield takeLatest(DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET, getDetachmentConfigUtilBegin);
}

export function* resetDetachmentConfigUtilWatcher() {
  yield takeLatest(
    DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET,
    resetDetachmentConfigUtilBegin,
  );
}
