import { KeyValueHeading } from 'components/filters/models/filter';
import USER_PERMISSIONS from 'utils/constants/user-permissions';
import {
  RequiredPermissionsConfig,
  filterOutStatusesByPermissions,
} from 'components/filters/utils/statuses-by-permissions';

const requiredPermissionsConfig: RequiredPermissionsConfig = {
  escalated: [USER_PERMISSIONS.read.escalatedCases],
  // add more processing statuses and required permissions later
};

export const filterProcessingStatusesByPermissions = (
  statuses: Array<KeyValueHeading>,
  userPermissions: Array<string>,
) => filterOutStatusesByPermissions(statuses, requiredPermissionsConfig, userPermissions);
