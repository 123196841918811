import hawkLogo from 'assets/images/logo.png';
import faviconHawk from 'assets/images/hawk-icon.png';
import { TenantIdSchema } from 'models/tenant/tenant';
import { useEffect, useState } from 'react';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { WhitelabelApiCommunicator } from 'services/whitelabel-api-communicator';
import { getWhiteLabelContext } from 'utils/helpers/theme/whitelabel.theme';
import { setFavicon } from 'utils/helpers/utility';

export const useWhiteLabel = () => {
  const [applicationLogo, setApplicationLogo] = useState<string>();
  const [applicationFavicon, setApplicationFavicon] = useState<string>();

  const activeTenantId = TenantManagementService.getLastTenantId();

  const whiteLabelObj = getWhiteLabelContext(activeTenantId);
  useEffect(() => {
    if (!activeTenantId || !TenantIdSchema.safeParse(activeTenantId).success) {
      setApplicationLogo('');
      setApplicationFavicon('');
    } else {
      new WhitelabelApiCommunicator()
        .getLogo(activeTenantId)
        .then((logo?: string) => setApplicationLogo(logo || whiteLabelObj?.logo || hawkLogo));

      let faviconPlaceholder: string;
      if (whiteLabelObj) {
        faviconPlaceholder = whiteLabelObj.favicon || faviconHawk;
      } else {
        faviconPlaceholder = faviconHawk;
      }
      new WhitelabelApiCommunicator()
        .getFavicon(activeTenantId)
        .then((favicon?: string) => setApplicationFavicon(favicon || faviconPlaceholder));
    }
  }, [activeTenantId, whiteLabelObj]);

  useEffect(() => {
    setFavicon(applicationFavicon as string);
  }, [applicationFavicon]);

  return {
    applicationLogo,
    setApplicationLogo,
    applicationFavicon,
    setApplicationFavicon,
  };
};
