import { SuspiciousAvgTicketSizeConfigActions } from 'modules/configuration/rule-manager/suspicious-avg-ticket-size/middleware/action-types';

export const getSuspiciousAvgTicketSizeConfig = () => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET,
});

export const getSuspiciousAvgTicketSizeConfigSuccess = (payload) => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET_SUCCESS,
  payload,
});

export const getSuspiciousAvgTicketSizeConfigError = () => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET_ERROR,
});

export const updateSuspiciousAvgTicketSizeConfig = (payload) => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE,
  payload,
});

export const updateSuspiciousAvgTicketSizeConfigSuccess = (payload) => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateSuspiciousAvgTicketSizeConfigError = () => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE_ERROR,
});

export const deleteSuspiciousAvgTicketSizeConfig = (payload) => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE,
  payload,
});

export const deleteSuspiciousAvgTicketSizeConfigSuccess = (payload) => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE_SUCCESS,
  payload,
});

export const deleteSuspiciousAvgTicketSizeConfigError = () => ({
  type: SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE_ERROR,
});
