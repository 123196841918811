import { Divider, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import { Form, useForm } from 'hooks/use-form';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Filter } from 'components/filters/models/filter';
import { RootState } from 'middleware/store';
import { getCaseNotificationsConfig } from 'modules/configuration/workflows-and-users/components/case-notification/middleware/actions/case-notifications-config';

interface CaseNotificationRuleInstanceProps {
  editForm: Filter;
  onSubmit: (filter: Filter) => void;
}

interface FormValues {
  caseNotificationRuleInstanceIds: string[];
}

const fieldName = 'caseNotificationRuleInstanceIds';

const CaseNotificationRuleInstance = ({
  editForm,
  onSubmit,
}: CaseNotificationRuleInstanceProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const caseNotificationConfig: CaseNotificationConfig = useSelector(
    (state: RootState) => state.configContainer.caseNotificationsConfig as CaseNotificationConfig,
  );
  const [options, setOptions] = useState<string[]>([]);
  const initialFValues: FormValues = {
    caseNotificationRuleInstanceIds: editForm?.values || [],
  };

  useEffect(() => {
    if (isEmpty(caseNotificationConfig)) {
      dispatch(getCaseNotificationsConfig());
    }
  }, [caseNotificationConfig, dispatch]);

  useEffect(() => {
    if (isEmpty(caseNotificationConfig) || isEmpty(caseNotificationConfig.instances)) {
      return;
    }

    setOptions(caseNotificationConfig.instances.map((instance) => instance.title));
  }, [caseNotificationConfig]);

  // eslint-disable-next-line no-use-before-define,consistent-return
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp: Record<string | typeof fieldName, string | null> = { ...errors };
    if (fieldName in fieldValues) {
      temp.caseNotificationRuleInstanceIds = fieldValues.caseNotificationRuleInstanceIds?.length
        ? ''
        : t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleChange = (option: ChangeEvent<HTMLInputElement>) => {
    handleInputChange({
      target: {
        name: fieldName,
        value: caseNotificationConfig.instances
          .filter((value) => option.target.value.includes(value.title))
          .map((value) => value.uniqueId),
      },
    });
  };

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: t(`mapping:filters.filtering.${fieldName}.key`),
        values: values.caseNotificationRuleInstanceIds,
      });
    }
  };

  let caseNotificationInstanceTitles: string[] = [];
  if (caseNotificationConfig && !isEmpty(caseNotificationConfig.instances)) {
    caseNotificationInstanceTitles = caseNotificationConfig.instances
      .filter((value) => values.caseNotificationRuleInstanceIds.includes(value.uniqueId))
      .map((value) => value.title);
  }

  return (
    <Grid container>
      <Form className="w-100">
        <Grid container item className="px-3 py-2" spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                dataTestIdPrefix="case-notigication-rule-instance-value"
                name={fieldName}
                label={t('condition.equal.heading')}
                value={caseNotificationInstanceTitles}
                onChange={handleChange}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore useForm hook returns empty object type and there is no point to cast it to required type null | undefined as this type is also incorrect
                error={errors.caseNotificationRuleInstanceIds}
                options={options}
                shrink
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};

export default CaseNotificationRuleInstance;
