import React, { FC } from 'react';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import { Attribute } from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/entity-resolution-risk-details';
import { useSubHeadAttribute } from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/hooks/use-sub-head-attribute';
import { useAuthorization } from 'hooks/use-authorization';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import USER_PERMISSIONS from 'utils/constants/user-permissions';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface SharedAttributeDetailsProps {
  scoreData?: {
    CALCULATED_POINTS?: number;
    TITLE?: string;
    ATTRIBUTE?: Attribute | string;
    ORIGINAL_DATA?: { value: string; count: number; link?: string };
  };
  popoverMode: boolean;
}

const SharedAttributeDetails: FC<SharedAttributeDetailsProps> = ({ scoreData, popoverMode }) => {
  const { t } = useTranslation();
  const { detachmentConfig, userPermissions } = useAuthorization();
  const isCustomerViewEnabled = isFeatureAccessible(
    DETACHMENT_CONFIG.customerView,
    detachmentConfig,
    userPermissions,
    USER_PERMISSIONS.read.customerCaseList,
  );

  const openInNew = () =>
    scoreData?.ORIGINAL_DATA?.link
      ? window.open(`/${scoreData?.ORIGINAL_DATA?.link}`, '_blank')
      : undefined;

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData?.CALCULATED_POINTS ?? 0}
          maxPoints={scoreData?.CALCULATED_POINTS ?? 0} // ER Showcase - get from config RISK_FACTOR_INSTANCE once available
          headline={scoreData?.TITLE}
          subHead={`${t(
            'case:userPopover.riskRating.entityResolutionRisks.sharedAttribute.heading',
          )}: ${useSubHeadAttribute(scoreData?.ATTRIBUTE)}`}
        />
      }
      highestPointsApplied={false}
      detailsComponent={
        <Grid container justifyContent="space-between" className="pb-1">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.riskRating.entityResolutionRisks.sharedAttribute.value.heading')}
            </Typography>
            <Chip className="mr-1" size="small" label={scoreData?.ORIGINAL_DATA?.value} />
            <Typography variant="body2" color="textSecondary" className="mt-3">
              {t(
                'case:userPopover.riskRating.entityResolutionRisks.sharedAttribute.sharedBy.heading',
              )}
            </Typography>
            <Chip
              size="small"
              label={t(
                'case:userPopover.riskRating.entityResolutionRisks.sharedAttribute.profiles.heading',
                {
                  count: scoreData?.ORIGINAL_DATA?.count,
                },
              )}
              onDelete={() => openInNew()}
              deleteIcon={
                isCustomerViewEnabled && scoreData?.ORIGINAL_DATA?.link ? (
                  <Tooltip
                    title={t(
                      'case:userPopover.riskRating.entityResolutionRisks.sharedAttribute.openProfiles.heading',
                    )}
                  >
                    <OpenInNewIcon fontSize="small" />
                  </Tooltip>
                ) : undefined
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default SharedAttributeDetails;
