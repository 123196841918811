import TENANT_IDS from 'utils/constants/tenant-ids';

const getActiveTenantInfo = (authentication, lastTenantId) => {
  const user = authentication.user ?? {};
  const { tenants } = user;

  if (tenants && Array.isArray(tenants) && tenants.length > 0) {
    const found = tenants.find((t) => t.id === lastTenantId);
    if (found) {
      return {
        id: found.id,
        name: found.name,
      };
    }
    return tenants[0];
  }

  return {
    id: '',
  };
};

const isE2eTenant = (tenantId) => {
  const e2eTestIds = [
    TENANT_IDS.multipleEnviroments.demo,
    TENANT_IDS.multipleEnviroments.nab.investigation,
    TENANT_IDS.multipleEnviroments.dLocal.investigation,
    TENANT_IDS.multipleEnviroments.dockFinancial.b2bE2e,
    TENANT_IDS.multipleEnviroments.dockFinancial.b2cE2e,
    TENANT_IDS.multipleEnviroments.tenantForConfigurationChangeTesting,
    TENANT_IDS.multipleEnviroments.endToEnd,
    TENANT_IDS.multipleEnviroments.masterTenantTest,
    TENANT_IDS.multipleEnviroments.rulesV2,
    TENANT_IDS.multipleEnviroments.lexisNexisTesting,
    TENANT_IDS.multipleEnviroments.hawkConfig,
    TENANT_IDS.multipleEnviroments.endToEndConfig,
    TENANT_IDS.multipleEnviroments.subtenantForCounterpartyGrouping,

    TENANT_IDS.dev.wordline.endToEnd,
    TENANT_IDS.dev.amnis.endToEnd,

    TENANT_IDS.test.worldline.endToEnd,
    TENANT_IDS.test.batchSandbox.endToEnd,
    TENANT_IDS.test.batchSandbox.vrPay,
    TENANT_IDS.test.amnis.investigation,

    TENANT_IDS.qa.subtenantForCounterpartyGrouping,
  ];

  return e2eTestIds.includes(tenantId);
};

export { getActiveTenantInfo, isE2eTenant };
