import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ApiKey {
  key?: string;
  name?: string;
  createdAt?: string;
  createdBy?: string;
}

export interface ApiKeyConfigState {
  updatedAt?: string;
  apiKeys: ApiKey[];
}

export const apiKeyConfigSlice = createSlice({
  name: 'apiKeyConfig',
  initialState: { apiKeys: [] } as ApiKeyConfigState,
  reducers: {
    getApiKeyConfig: (state) => state,
    getApiKeyConfigSuccess: (state, action: PayloadAction<ApiKeyConfigState>) => ({
      ...state,
      updatedAt: action.payload.updatedAt,
      apiKeys: action.payload.apiKeys,
    }),
    deleteApiKeys: (state, _action: PayloadAction<string[]>) => state,
    deleteApiKeysSuccess: (state, action: PayloadAction<ApiKeyConfigState>) => ({
      ...state,
      updatedAt: action.payload.updatedAt,
      apiKeys: action.payload.apiKeys,
    }),
  },
});

export const { getApiKeyConfig, getApiKeyConfigSuccess, deleteApiKeys, deleteApiKeysSuccess } =
  apiKeyConfigSlice.actions;

export default apiKeyConfigSlice.reducer;
