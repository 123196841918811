import ErrorCode from 'utils/constants/error-code';
import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';

class CustomerCaseApiCommunicator extends APICommunicator {
  fetchAllCases(paging, sortingConfig, filterConfig, allMaskedDataShown) {
    const apiUrl = API_URLS.customerService.cases.get;
    const payload = {
      paging,
      sorting: sortingConfig,
      filtering: filterConfig,
      allMaskedDataShown,
    };
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  unmaskCase(data) {
    const apiUrl = API_URLS.customerService.decrypt.anyCase;
    const payload = data;
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default CustomerCaseApiCommunicator;
