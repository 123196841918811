import { Slider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

/**
 * Material UI slider with colorful rail
 * @param {*} props
 */
const ColorfulSlider = (props) => {
  const { min, max, marks, railColors } = props;

  // Build the ranges
  const newValue = marks.map((item) => item.value);
  if (!newValue.includes(min)) {
    newValue.push(min);
  }
  if (!newValue.includes(max)) {
    newValue.push(max);
  }
  newValue.sort((a, b) => a - b);

  const railRanges = [];
  for (let i = 1; i < newValue.length; i++) {
    if (newValue[i] > 0) {
      railRanges.push((newValue[i] * 100) / max);
    }
  }

  const useStyles = makeStyles(() => ({
    rail: {
      opacity: 1,
      zIndex: 1,
      background: ({ railRanges, railColors }) => {
        const newRailColors = [...(railColors ?? [])];
        while (newRailColors.length < railRanges.length) {
          newRailColors.push('rgba(0,0,0,0)');
        }
        const allBgColors = [];
        for (let i = 0; i < railRanges.length; i++) {
          const range = railRanges[i];
          const color = newRailColors[i];
          const oneBgColor = `linear-gradient(to right, ${color} ${range}%, rgba(0,0,0,0) ${range}%)`;
          allBgColors.push(oneBgColor);
        }
        return allBgColors.join(',');
      },
    },
  }));

  const classes = useStyles({ railRanges, railColors });

  const newProps = { ...props };
  delete newProps.railColors;

  return (
    <Slider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...newProps}
      classes={{
        rail: classes.rail,
      }}
    />
  );
};

export { ColorfulSlider };
