import { Translation } from 'react-i18next';
import { ClickableWheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/clickable-wheel-points-with-headline';

const RiskRatingCategorySummary = ({
  category,
  totalPoints,
  subTotalPoints,
  popoverMode,
  onClick,
}) => (
  <Translation>
    {(t) => (
      <ClickableWheelPointsWithHeadline
        data-testid={`risk-rating-category-summary-${category}`}
        points={subTotalPoints}
        maxPoints={totalPoints}
        size={popoverMode ? 'small' : 'medium'}
        headline={t(`configuration:customerRiskRating.riskFactor.${category}.heading`)}
        subHead={t(`configuration:customerRiskRating.riskFactor.${category}.subheading`)}
        onClick={onClick}
      />
    )}
  </Translation>
);

export { RiskRatingCategorySummary };
