class RiskyWordsV2ConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Passthrough V3 Config API Communicator instance is requrired.');
    }
  }

  getRiskyWordsV2Config() {
    return this.communicator.fetchConfig();
  }

  updateRiskyWordsV2Config(data) {
    return this.communicator.updateConfig(data);
  }

  deleteRiskyWordsV2Config(id) {
    return this.communicator.deleteConfig(id);
  }
}

export default RiskyWordsV2ConfigManager;
