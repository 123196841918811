import { PASSTHROUGH_V2_ACTIONS } from 'modules/configuration/rule-manager/passthrough-v2/middleware/action-types';

const passthroughV2ConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSTHROUGH_V2_ACTIONS.fetch.success:
    case PASSTHROUGH_V2_ACTIONS.update.success:
    case PASSTHROUGH_V2_ACTIONS.delete.success:
      return {
        ...state,
        ruleInstances: action.payload.instances,
        updateDate: action.payload.updatedAt,
        userId: action.payload.userId,
      };
    default:
      return state;
  }
};

export default passthroughV2ConfigReducer;
