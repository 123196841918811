import { PLAUSIBILITY_ACTIONS } from 'middleware/action-types';

export const getPlausibilityConfig = () => ({
  type: PLAUSIBILITY_ACTIONS.fetch.data,
});

export const getPlausibilityConfigSuccess = (payload) => ({
  type: PLAUSIBILITY_ACTIONS.fetch.success,
  payload,
});

export const getPlausibilityConfigError = () => ({
  type: PLAUSIBILITY_ACTIONS.fetch.error,
});

export const updatePlausibilityConfig = (payload) => ({
  type: PLAUSIBILITY_ACTIONS.update.data,
  payload,
});

export const updatePlausibilityConfigSuccess = (payload) => ({
  type: PLAUSIBILITY_ACTIONS.update.success,
  payload,
});

export const updatePlausibilityConfigError = (payload) => ({
  type: PLAUSIBILITY_ACTIONS.update.error,
  payload,
});

export const deletePlausibilityConfig = (payload) => ({
  type: PLAUSIBILITY_ACTIONS.delete.data,
  payload,
});

export const deletePlausibilityConfigSuccess = (payload) => ({
  type: PLAUSIBILITY_ACTIONS.delete.success,
  payload,
});

export const deletePlausibilityConfigError = (payload) => ({
  type: PLAUSIBILITY_ACTIONS.delete.error,
  payload,
});
