import { CheckCircle } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UnblockedChipProps extends ChipProps {
  dataTestId: string;
}

export const UnblockedChip: FC<UnblockedChipProps> = ({
  dataTestId = 'unblocked',
  size = 'small',
}) => {
  const theme = useTheme();
  const chipStyle = theme.custom?.riskChipComponent?.low;
  const { t } = useTranslation();

  return (
    <Chip
      data-testid={dataTestId}
      size={size}
      style={chipStyle}
      label={t('unblocked.heading')}
      icon={<CheckCircle style={chipStyle} />}
    />
  );
};
