import { useDispatch } from 'react-redux';
import { hideError } from 'middleware/actions/error';

import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import IconButtons from 'components/controls/icon-button/icon-buttons';

const DetailsToggle = ({ visible, toggle }) => {
  const { t } = useTranslation();
  return (
    <Button color="primary" size="small" onClick={toggle}>
      {`${visible ? t('error:error.toggle.hide') : t('error:error.toggle.show')}`}
    </Button>
  );
};

const ErrorActions = ({ error, detailsVisible, toggleDetails }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleCustomAction = () => {
    dispatch({ ...error.custom.action });
    dispatch(hideError(error));
  };

  return (
    <>
      {error.details && <DetailsToggle visible={detailsVisible} toggle={toggleDetails} />}

      {error.custom.action && (
        <Button color="secondary" size="small" onClick={() => handleCustomAction()}>
          {t(`error:types.${error.code}.heading`)}
        </Button>
      )}

      <IconButtons.Close
        color="inherit"
        fontSize="inherit"
        onClick={() => dispatch(hideError(error))}
        size="small"
      />
    </>
  );
};

export default ErrorActions;
