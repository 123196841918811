export enum EntityResolutionRiskTemplates {
  multipleAttribute = 'MULTIPLE_ATTRIBUTE',
  sharedAttribute = 'SHARED_ATTRIBUTE',
}

export type EntityResolutionRiskTemplate = `${EntityResolutionRiskTemplates}`;

export const ER_RISK_SECTION = 'CUSTOMER';

export enum Attributes {
  name = 'NAME',
  address = 'ADDRESS',
  emailAddress = 'EMAIL_ADDRESS',
  phoneNumber = 'PHONE_NUMBER',
  dob = 'DOB',
  gender = 'GENDER',
  sourceDocumentType = 'SOURCE_DOCUMENT_TYPE',
}
export const GENERIC_PREFIX = 'GENERIC_';
export type Attribute = `${Attributes}`;

export const BASE_SCORE_PER_VALUE = 'base_score_per_value';
export const MAX_SCORE = 'max_score';
export const ACCELERATION_FACTOR = 'accelerationFactor';
