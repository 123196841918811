import { COUNTER_PARTY_VOLUME_ACTIONS } from 'modules/configuration/rule-manager/counter-party-volume/middleware/action-types';

export const getCounterPartyVolumeConfig = () => ({
  type: COUNTER_PARTY_VOLUME_ACTIONS.fetch.data,
});

export const getCounterPartyVolumeConfigSuccess = (payload) => ({
  type: COUNTER_PARTY_VOLUME_ACTIONS.fetch.success,
  payload,
});

export const updateCounterPartyVolumeConfig = (payload) => ({
  type: COUNTER_PARTY_VOLUME_ACTIONS.update.data,
  payload,
});

export const updateCounterPartyVolumeConfigSuccess = (payload) => ({
  type: COUNTER_PARTY_VOLUME_ACTIONS.update.success,
  payload,
});

export const deleteCounterPartyVolumeConfig = (payload) => ({
  type: COUNTER_PARTY_VOLUME_ACTIONS.delete.send,
  payload,
});

export const deleteCounterPartyVolumeConfigSuccess = (payload) => ({
  type: COUNTER_PARTY_VOLUME_ACTIONS.delete.success,
  payload,
});
