export const formatBytes = (bytes, decimals = 1) => {
  if (bytes === 0) return '0 KB';
  const k = 1024;

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let i = Math.floor(Math.log(bytes) / Math.log(k));
  if (i === 0) {
    i = 1;
  }

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i - 1]}`;
};

export const createAndDownloadBlobFile = (body, fileName) => {
  const blob = new Blob([body]);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const convertBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result.split('base64,')[1]);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

export const appendOneFileAsBase64ToFormData = (file, formData, formKey) =>
  convertBase64(file).then((result) =>
    formData.append(formKey, new Blob([result], { type: file.type }), file.name),
  );

export const appendManyFilesAsBase64ToFormData = (files, formData, formKey) => {
  const tasks = files.map((file) => appendOneFileAsBase64ToFormData(file, formData, formKey));

  return Promise.all(tasks);
};
