import { isSameLoggedUser } from 'utils/helpers/cases/case-utility';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import { Badge, Avatar } from '@mui/material';
import { ConditionalTooltip } from 'components/tooltip/tooltip';
import { Translation } from 'react-i18next';

const Assigned = ({ assignedName }) => (
  <Translation>
    {(t) => (
      <ConditionalTooltip title={t('case:caseDetail.caseDrawer.info.heading')}>
        {assignedName ? (
          <span className="my-1">
            <Badge
              className="small"
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <Avatar className="auto bg-background">
                  <InfoIcon className="color-on-surface-disabled icon-16" />
                </Avatar>
              }
            >
              <Avatar
                className={`small ${
                  isSameLoggedUser(assignedName) ? 'bg-primary' : 'bg-on-surface-secondary'
                }`}
              >
                <PersonIcon fontSize="small" />
              </Avatar>
            </Badge>
          </span>
        ) : (
          <InfoIcon className="color-on-surface-secondary" />
        )}
      </ConditionalTooltip>
    )}
  </Translation>
);

export default Assigned;
