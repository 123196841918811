import fetchRetry from 'fetch-retry';
import originalFetch from 'isomorphic-fetch';

import SessionService from 'modules/authentication/login/services/session-service';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_URLS } from 'utils/constants/api-config';

const auth = SessionService.getInstance();

export const logMessageInBackend = (message: string, level: string) => {
  const apiUrl = API_URLS.logService.addLog;

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    tenantId: `${TenantManagementService.getActiveTenantId()}`,
    correlationId: `${auth.getCorrelationId()}`,
  };

  return fetchRetry(originalFetch)(apiUrl, {
    credentials: 'include',
    method: 'POST',
    headers,
    body: JSON.stringify({
      sender: 'Case Manager',
      level,
      message,
      windowLocation: window.location.href,
    }),
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);
    return error;
  });
};
