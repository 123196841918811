import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { CASE_STATISTICS_AND_HEALTH_ACTIONS } from 'modules/dashboard/case-statistic/middleware/action-types';

import { getDashboardMetricSuccess } from 'modules/dashboard/case-statistic/middleware/actions/case-statistic';
import CaseStatisticsAPICommunicator from 'modules/dashboard/case-statistic/middleware/services/case-statistics-communicator';
import { put, takeLatest } from 'redux-saga/effects';
import DashboardManager from 'services/dashboard-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { isItemExpired } from 'utils/helpers/date-time-util';
import { cleanUpLocalStorage } from 'utils/helpers/utility';

const caseStatisticsAPICommunicator = new CaseStatisticsAPICommunicator();
const dashboardManager = new DashboardManager(caseStatisticsAPICommunicator);

// 1 min expired, since report is fast enough
const EXPIRE_TIME_IN_SECONDS = 60;

function* fetchDashboardMetric(action, methodName) {
  yield put(startProcessing(action.type));
  try {
    const tenantId = TenantManagementService.getActiveTenantId();

    let storeKeyPrefix = `${action.type}_${tenantId}`;
    let storeKey = storeKeyPrefix;
    let options = {};
    if (action.payload) {
      // store request params base on option
      const { from, to, name } = action.payload;
      storeKeyPrefix += `_${name}`;
      storeKey = `${storeKeyPrefix}_${from.valueOf()}_${to.valueOf()}`;
      options = {
        startDate: from,
        endDate: to,
      };
    }
    const storeDateKey = `${storeKey}_datetime`;

    let response = localStorage.getItem(storeKey);
    const responseDateString = localStorage.getItem(storeDateKey);

    if (response && !isItemExpired(responseDateString, EXPIRE_TIME_IN_SECONDS)) {
      response = JSON.parse(response);
    } else {
      cleanUpLocalStorage(storeKeyPrefix);
      response = yield dashboardManager[methodName](options);
      localStorage.setItem(storeKey, JSON.stringify(response));
      localStorage.setItem(storeDateKey, new Date().toString());
    }
    yield put(getDashboardMetricSuccess(response, action.type));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(action.type));
}

export function* getGroupsCountMetricWatcher() {
  function* getGroupsCountMetric(action) {
    yield fetchDashboardMetric(action, 'getGroupsCountMetric');
  }

  yield takeLatest(
    CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsCount.data,
    getGroupsCountMetric,
  );
}

export function* getGroupsTypeCountMetricWatcher() {
  function* getGroupsTypeCountMetric(action) {
    yield fetchDashboardMetric(action, 'getGroupsTypeCountMetric');
  }

  yield takeLatest(
    CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsTypeCount.data,
    getGroupsTypeCountMetric,
  );
}

export function* getGroupsHealthMetricWatcher() {
  function* getGroupsHealthMetric(action) {
    yield fetchDashboardMetric(action, 'getGroupsHealthMetric');
  }

  yield takeLatest(
    CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsHealth.data,
    getGroupsHealthMetric,
  );
}
