import { LOCAL_STORAGE, SESSION_STORAGE } from 'utils/constants/constant';

/* eslint class-methods-use-this: [0] */
class SessionStorageService {
  getSelectedCaseIndex() {
    return sessionStorage.getItem(SESSION_STORAGE.caseDetails.selectedCaseIndex);
  }

  setSelectedCaseIndex(value) {
    sessionStorage.setItem(SESSION_STORAGE.caseDetails.selectedCaseIndex, value);
  }

  removeSelectedCaseIndex() {
    sessionStorage.removeItem(SESSION_STORAGE.caseDetails.selectedCaseIndex);
  }

  setUnmaskCount(count) {
    sessionStorage.setItem(LOCAL_STORAGE.caseDetails.unmaskCount, count);
  }

  getUnmaskCount() {
    return sessionStorage.getItem(LOCAL_STORAGE.caseDetails.unmaskCount) || 0;
  }
}

export default SessionStorageService;
