import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import {
  CreateUserRequestDto,
  CreateUserResponseDto,
} from 'modules/configuration/workflows-and-users/components/user-management/models/create-user-dto';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { UserManagementConfig } from 'models/configuration/user-management-config';
import { Role } from 'modules/configuration/workflows-and-users/components/user-management/models/role-dto';
import {
  UserInfoWithRoles,
  populateDataForFiltering,
} from 'modules/configuration/workflows-and-users/components/user-management/models/user-info-with-roles';
import {
  UpdateUserRolesRequestDto,
  BulkUpdateUsersRolesRequestDto,
} from 'modules/configuration/workflows-and-users/components/user-management/models/update-user-roles-dto';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["createUser", "getUsers"] }] */
class UserManagementApi extends APICommunicator {
  createUser(params: CreateUserRequestDto): Promise<CreateUserResponseDto> {
    const apiUrl = API_URLS.userManagementService.createUser;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateUserRoles(userId: string, params: UpdateUserRolesRequestDto) {
    const apiUrl = API_URLS.userManagementService.updateRoles.replace(
      API_PARAMS.userId,
      encodeURI(userId),
    );
    return this.putJSONForTenant(apiUrl, params, ErrorCode.AUTH0_API_ERROR);
  }

  bulkUpdateUserRoles(params: BulkUpdateUsersRolesRequestDto) {
    const apiUrl = API_URLS.userManagementService.bulkUpdateRoles;
    return this.putJSONForTenant(apiUrl, params, ErrorCode.AUTH0_API_ERROR);
  }

  getUsersWithRoles(tenantId: string): Promise<UserInfoWithRoles[]> {
    const apiUrl = API_URLS.userManagementService.getUsersWithRoles.replace(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE).then(
      (users) => {
        users.forEach((user: UserInfoWithRoles) => populateDataForFiltering(user));

        return users;
      },
    );
  }

  getRoles(): Promise<Role[]> {
    const apiUrl = API_URLS.userManagementService.getRoles;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  deactivateUser(userId: string): Promise<void> {
    const apiUrl = API_URLS.userManagementService.userById.replace(
      API_PARAMS.userId,
      encodeURI(userId),
    );
    return this.deleteJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  putTenantUserManagementConfig(params: UserManagementConfig): Promise<UserManagementConfig> {
    const apiUrl = API_URLS.tenantService.userManagement.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId() || '',
    );
    return this.putJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default UserManagementApi;
