import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { AdhocScreeningRequest } from 'modules/customer-data/adhoc-screening/model/adhoc-screening-request';
import { noop } from 'lodash';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { CustomerNote } from 'modules/customer-data/types';

export enum ManualCustomerCheckStatuses {
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface ManualCustomerCheckRequest {
  requestId: string;
  customerRiskRating: boolean;
  customerScreening: boolean;
}

export interface ManualCustomerCheckStatusResponse {
  requestId: string;
  status: string;
  errorMessage?: string;
}

export class CustomerDataApi extends APICommunicator {
  triggerManualCustomerCheck(
    customerIdToken: string,
    request: ManualCustomerCheckRequest,
  ): Promise<ManualCustomerCheckStatusResponse> {
    const apiUrl = API_URLS.customerData.customerDetail.actions.check.trigger.replaceAll(
      API_PARAMS.customerIdToken,
      customerIdToken,
    );
    return this.postJSONForTenant(apiUrl, request, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  createManualCustomerCase(
    customerIdToken: string,
    payload: { reasonTypes: string[]; notes: string; attachments: File[] },
  ): Promise<ManualCustomerCheckStatusResponse> {
    const apiUrl = API_URLS.customerData.customerDetail.actions.cases.create.replaceAll(
      API_PARAMS.customerIdToken,
      customerIdToken,
    );
    const body = { reasonTypes: payload.reasonTypes, note: payload.notes };
    const data = new FormData();
    payload.attachments.forEach((singleAttachment) => {
      data.append('attachments', singleAttachment);
    });
    data.append('request', new Blob([JSON.stringify(body)], { type: 'application/json' }));

    return this.postForCustomTenant(
      apiUrl,
      data,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getManualCustomerCheckStatus(
    customerIdToken: string,
    requestId: string,
  ): Promise<ManualCustomerCheckStatusResponse> {
    const apiUrl = API_URLS.customerData.customerDetail.actions.check.getStatus
      .replaceAll(API_PARAMS.customerIdToken, customerIdToken)
      .replaceAll(API_PARAMS.requestId, requestId);
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  finalizeManualCustomerCheck(customerIdToken: string, requestId: string): Promise<void> {
    const apiUrl = API_URLS.customerData.customerDetail.actions.check.finalize
      .replaceAll(API_PARAMS.customerIdToken, customerIdToken)
      .replaceAll(API_PARAMS.requestId, requestId);
    return this.postJSONForTenant(
      apiUrl,
      undefined,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      undefined,
      () =>
        // endpoint always returns empty response
        Promise.resolve(),
    );
  }

  performAdhocScreening(payload: AdhocScreeningRequest) {
    const apiUrl = API_URLS.customerData.adhocScreening.post;
    return this.postJSONForTenant(
      apiUrl,
      payload,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      undefined,
      noop,
    );
  }

  checkIfCustomerProfileExists(customerIdToken: string) {
    const apiUrl = API_URLS.customerData.customerDetail.actions.checkIfExists.replaceAll(
      API_PARAMS.customerIdToken,
      customerIdToken,
    );
    return this.postJSONForTenant(apiUrl, undefined, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getCustomerDetailV2(customerIdToken: string) {
    const apiUrl = API_URLS.customerData.customerDetail.fetchDetailsV2.replaceAll(
      API_PARAMS.customerIdToken,
      customerIdToken,
    );
    return this.getJSONForUserId(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  downloadCustomerNoteAttachment(noteId: string, attachmentId: string, tenantId: string) {
    let apiUrl = API_URLS.filesService.notes.getFile.replaceAll(API_PARAMS.noteId, noteId);

    apiUrl = apiUrl.replaceAll(API_PARAMS.id, attachmentId);
    return this.getByteArrayFromCaseFiles(
      apiUrl,
      tenantId,
      ErrorCode.TENANT_CASE_FILES_NOT_AVAILABLE,
    );
  }

  createCustomerNote(
    customerIdToken: string,
    note: string,
    tenantId: string,
    attachments: File[],
  ): Promise<CustomerNote> {
    const apiUrl = API_URLS.customerData.customerDetail.actions.notes.create.replaceAll(
      API_PARAMS.customerIdToken,
      customerIdToken,
    );

    const body = {
      note,
      type: 'ADDED_BY_USER',
      tenantId,
      references: [
        {
          id: customerIdToken,
          type: 'customer',
        },
      ],
    };

    const data = new FormData();

    attachments.forEach((attachment) => {
      data.append('attachments', attachment);
    });

    data.append('request', new Blob([JSON.stringify(body)], { type: 'application/json' }));

    return this.postForCustomTenant(
      apiUrl,
      data,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    ) as Promise<CustomerNote>;
  }
}
