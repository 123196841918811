import Button from 'components/controls/button';
import Checkbox from 'components/controls/checkbox';
import RadioGroup from 'components/controls/radio-group';
import DatePicker from 'components/controls/date-picker';
import DownloadFile from 'components/controls/download-file';
import Input from 'components/controls/input';
import MultipleInput from 'components/controls/multiple-input';
import Select from 'components/controls/select';
import Select2 from 'components/controls/select2';
import UploadFile from 'components/controls/upload-file';

const Controls = {
  Input,
  Button,
  Select,
  Select2,
  RadioGroup,
  MultipleInput,
  DatePicker,
  Checkbox,
  UploadFile,
  DownloadFile,
};

export default Controls;
