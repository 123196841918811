import {
  FormControl,
  RadioGroup as MuiRadioGroup,
  Radio,
  FormHelperText,
  FormLabel,
  FormControlLabel,
} from '@mui/material';

const RadioGroup = ({
  name,
  label,
  value,
  error = null,
  onChange,
  options,
  fullWidth,
  disabled,
  defaultValue,
  row,
}) => {
  const getFormValue = (value) => {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return value;
      }
      return options?.find((element) => element.key === value.key);
    }
    return value;
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormControl fullWidth={fullWidth} {...(error && { error: true })}>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <MuiRadioGroup
        row={row}
        label={label}
        name={name}
        disabled={disabled}
        value={getFormValue(value)}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.key}
            control={<Radio />}
            label={item.value?.heading ?? item?.value}
            disabled={disabled}
          />
        ))}
      </MuiRadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export default RadioGroup;
