import { DormantAccountConfigActions } from 'modules/configuration/rule-manager/dormant-account/middleware/action-types';

export const getDormantAccountConfig = () => ({
  type: DormantAccountConfigActions.GET,
});

export const getDormantAccountConfigSuccess = (payload) => ({
  type: DormantAccountConfigActions.GET_SUCCESS,
  payload,
});

export const getDormantAccountConfigError = () => ({
  type: DormantAccountConfigActions.GET_ERROR,
});

export const updateDormantAccountConfig = (payload) => ({
  type: DormantAccountConfigActions.UPDATE,
  payload,
});

export const updateDormantAccountConfigSuccess = (payload) => ({
  type: DormantAccountConfigActions.UPDATE_SUCCESS,
  payload,
});

export const updateDormantAccountConfigError = () => ({
  type: DormantAccountConfigActions.UPDATE_ERROR,
});

export const deleteDormantAccountConfig = (payload) => ({
  type: DormantAccountConfigActions.DELETE,
  payload,
});

export const deleteDormantAccountConfigSuccess = (payload) => ({
  type: DormantAccountConfigActions.DELETE_SUCCESS,
  payload,
});

export const deleteDormantAccountConfigError = () => ({
  type: DormantAccountConfigActions.DELETE_ERROR,
});
