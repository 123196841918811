import { updateDecisionEngineConfig } from 'middleware/actions/decision-engine-config';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import CountryRiskConfigAPICommunicator from 'services/country-risk-config-api-communicator';
import CountryRiskConfigManager from 'services/country-risk-config-manager';
import { CountryRiskConfigActions } from 'modules/configuration/rule-manager/country-config/middleware/action-types';
import {
  getCountryRiskConfig,
  getCountryRiskConfigError,
  getCountryRiskConfigSuccess,
  updateCountryRiskConfigError,
  updateCountryRiskConfigSuccess,
} from 'modules/configuration/rule-manager/country-config/middleware/actions/country-risk-config';

const countryRiskConfigAPICommunicator = new CountryRiskConfigAPICommunicator();
const countryRiskConfigManager = new CountryRiskConfigManager(countryRiskConfigAPICommunicator);

function* getCountryRiskConfigBegin(action) {
  try {
    yield put(startProcessing(CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET));

    const json = yield countryRiskConfigManager.getCountryRiskConfig();
    yield put(getCountryRiskConfigSuccess(json));

    yield put(stopProcessing(CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET));
  } catch (error) {
    yield put(getCountryRiskConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET));
  }
}

export function* getCountryRiskConfigWatcher() {
  yield takeLatest(CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET, getCountryRiskConfigBegin);
}

function* updateCountryRiskConfigBegin(action) {
  try {
    yield put(startProcessing(CountryRiskConfigActions.COUNTRY_RISK_CONFIG_UPDATE));

    const json = yield countryRiskConfigManager.updateCountryRiskConfig(
      action.payload.countryRiskConfig,
    );
    // We need to update decision engine config as riskLevelSource is not a part of country risk config
    yield put(updateDecisionEngineConfig(action.payload.decisionEngineConfig));
    yield put(updateCountryRiskConfigSuccess(json));

    yield put(getCountryRiskConfig());

    yield put(stopProcessing(CountryRiskConfigActions.COUNTRY_RISK_CONFIG_UPDATE));
  } catch (error) {
    yield put(updateCountryRiskConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CountryRiskConfigActions.COUNTRY_RISK_CONFIG_UPDATE));
  }
}

export function* updateCountryRiskConfigWatcher() {
  yield takeLatest(
    CountryRiskConfigActions.COUNTRY_RISK_CONFIG_UPDATE,
    updateCountryRiskConfigBegin,
  );
}
