import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class PassthroughConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Passthrough Config API Communicator instance is required.');
    }
  }

  validatePassthroughConfigJson(json) {
    if (!!json && !!json.amountThresholds) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.PASSTHROUGH_CONFIG_DATA_INVALID);
  }

  getPassthroughConfig() {
    return this.communicator
      .fetchPassthroughConfig()
      .then((json) => this.validatePassthroughConfigJson(json));
  }

  updatePassthroughConfig(params) {
    return this.communicator
      .updatePassthroughConfig(params)
      .then((json) => this.validatePassthroughConfigJson(json));
  }
}

export default PassthroughConfigManager;
