import { put, takeLatest } from 'redux-saga/effects';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { AuthActions } from 'middleware/action-types';
import {
  requestResetPasswordError,
  requestResetPasswordSuccess,
} from 'modules/authentication/reset-password/middleware/actions/reset-password-actions';
import { showError } from 'middleware/actions/error';
import ResetPasswordService from 'modules/authentication/reset-password/services/reset-password-service';

const resetPasswordService = new ResetPasswordService();

function* requestResetPasswordBegin(action) {
  try {
    yield put(startProcessing(AuthActions.REQUEST_RESET_PASSWORD));
    const json = yield resetPasswordService.requestResetPassword(action.emailAddress);
    yield put(requestResetPasswordSuccess(json));
  } catch (error) {
    yield put(requestResetPasswordError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(AuthActions.REQUEST_RESET_PASSWORD));
}

export function* requestResetPasswordWatcher() {
  yield takeLatest(AuthActions.REQUEST_RESET_PASSWORD, requestResetPasswordBegin);
}
