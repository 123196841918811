import { AuthActions, LoginActions } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import { showNotification } from 'middleware/actions/notification';

import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { getTenantConfigBegin } from 'middleware/sagas';
import { AUTH_ACTIONS } from 'modules/authentication/login/middleware/action-types';
import {
  getDetachmentConfigError,
  getDetachmentConfigSuccess,
  getOperatorListError,
  getOperatorListSuccess,
  getPlatformViewingConfig,
  getPlatformViewingConfigError,
  getUserPermissionsError,
  getUserPermissionsSuccess,
  loginWithDatabaseError,
  loginWithDatabaseSuccess,
  loginWithSsoError,
  loginWithSsoSuccess,
  refreshCacheError,
  refreshCacheSuccess,
  setUserInfos,
} from 'modules/authentication/login/middleware/actions/auth-actions';

import AuthAPICommunicator from 'modules/authentication/login/services/auth-api-communicator';
import AuthServiceManager from 'modules/authentication/login/services/auth-service-manager';
import SessionService from 'modules/authentication/login/services/session-service';
import { TENANT_CONFIG_ACTIONS } from 'modules/configuration/workflows-and-users/middleware/action-types';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import TenantManagementService from 'services/tenant/tenant-management-service';
import ErrorCode from 'utils/constants/error-code';
import { getErrorByCode } from 'utils/helpers/error-util';
import { cleanUpConfigBegin } from 'middleware/sagas/customer-risk-rating-config';

const communicator = new AuthAPICommunicator();
const authService = new AuthServiceManager(communicator);
const auth = new SessionService();

export const getAuthState = (state) => state.authentication;

/** Generators */

export function* loginWithSsoStart(action) {
  // eslint-disable-next-line no-labels, no-restricted-syntax
  login: try {
    yield put(startProcessing(LoginActions.START_LOGIN_WITH_SSO));
    const userDetails = yield call(
      [authService, 'loginWithSso'],
      action.email,
      action.picture,
      action.nickname,
      action.accessToken,
      action.tenantId,
    );
    if (userDetails.error === '400' || userDetails.error === '500') {
      yield put(loginWithDatabaseError(userDetails.error));
      yield put(showError({ ...userDetails.error, action }));
      // eslint-disable-next-line no-labels
      break login;
    }

    // fetch API response and set redux state for following API calls
    yield put(setUserInfos(userDetails));
    const state = yield select(getAuthState);
    yield call([auth, 'setUserInformationLocally'], state);
    yield put(loginWithSsoSuccess());
  } catch (error) {
    yield put(loginWithSsoError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(LoginActions.START_LOGIN_WITH_SSO));
}

export function* loginWithDatabaseStart(action) {
  // eslint-disable-next-line no-labels, no-restricted-syntax
  login: try {
    yield put(startProcessing(LoginActions.START_LOGIN_WITH_DATABASE));
    const userDetails = yield call(
      [authService, 'loginWithDatabase'],
      action.username,
      action.pwd,
      action.tenantId,
    );

    if (userDetails.error === '400') {
      yield put(loginWithDatabaseError(userDetails));
      // eslint-disable-next-line no-labels
      break login;
    }

    // fetch API response and set redux state for following API calls
    yield put(setUserInfos(userDetails));
    const state = yield select(getAuthState);
    yield call([auth, 'setUserInformationLocally'], state);
    yield put(loginWithDatabaseSuccess());
  } catch (error) {
    yield put(loginWithDatabaseError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(LoginActions.START_LOGIN_WITH_DATABASE));
}

function* getDetachmentConfigBegin(action) {
  try {
    const json = yield authService.getDetachmentConfig(action?.payload?.tenantId);
    yield put(getDetachmentConfigSuccess(json));
  } catch (error) {
    yield put(getDetachmentConfigError(error));
    yield put(showError({ ...error, action }));
  }
}

const hasElapsedMoreThan = (pointInTime = 0, span) => {
  const now = new Date().getTime();
  const diff = now - pointInTime;

  return diff > span;
};

export function* getUserPermissionsBegin(action) {
  try {
    const authState = yield select(getAuthState);

    if (hasElapsedMoreThan(authState?.userPermissionRefreshAt, 60 * 1000)) {
      const json = yield authService.getPermissionsAssociatedToUser();
      yield put(getUserPermissionsSuccess(json));
    }
  } catch (error) {
    yield put(getUserPermissionsError(error));
    yield put(showError({ ...error, action }));
  }
}

function* getPlatformViewingConfigBegin(action) {
  if (!TenantManagementService.getActiveTenantId()) {
    yield put(showError(getErrorByCode(ErrorCode.NO_TENANT_ID)));
    return;
  }
  yield put(startProcessing(AUTH_ACTIONS.platform.viewingConfig));

  try {
    yield cleanUpConfigBegin();
    yield getUserPermissionsBegin();
    yield getDetachmentConfigBegin();
    yield getTenantConfigBegin();
  } catch (error) {
    yield put(getPlatformViewingConfigError(error));
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(AUTH_ACTIONS.platform.viewingConfig));
}

export function* getUserListBegin(action) {
  try {
    const json = yield authService.fetchUsers();
    yield put(getOperatorListSuccess(json));
  } catch (error) {
    yield put(getOperatorListError(error));
    yield put(showError({ ...error, action }));
  }
}

function* resetCacheBegin(action) {
  try {
    const json = yield authService.refreshCache();
    yield put(refreshCacheSuccess(json));
    yield put(showNotification({ message: json.message }));
  } catch (error) {
    yield put(refreshCacheError(error));
    yield put(showError({ ...error, action }));
  }
}

/** Watchers */

export function* ssoLoginWatcher() {
  yield takeLatest(AUTH_ACTIONS.withSso.login, loginWithSsoStart);
}

export function* databaseLoginWatcher() {
  yield takeLatest(AuthActions.LOGIN_WITH_DATABASE_START, loginWithDatabaseStart);
}

export function* getPlatformViewingConfigWatcher() {
  yield takeLatest(AUTH_ACTIONS.platform.viewingConfig, getPlatformViewingConfigBegin);
}

export function* getAuth0UserPermissionsWatcher() {
  yield takeLatest(AUTH_ACTIONS.permissions.get, getUserPermissionsBegin);
}

export function* getDetachmentConfigWatcher() {
  yield takeLatest(AUTH_ACTIONS.detachmentConfig.get, getDetachmentConfigBegin);
}

export function* getUserListWatcher() {
  yield takeLatest(AUTH_ACTIONS.operatorList.get, getUserListBegin);
}

export function* resetCacheWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.REFRESH_CACHE, resetCacheBegin);
}
