import React, { FC } from 'react';
import { MASKED_VALUE } from 'utils/helpers/cases/case-utility';
import { getLocaleDateString, getLocaleDateTimeString } from 'utils/helpers/date-time-util';

const FormattedDateTime: FC<{
  date?: string | Date | null;
  withTime?: boolean;
  shortLanguage?: boolean;
}> = ({ date, withTime, shortLanguage }) => {
  if (MASKED_VALUE === date) {
    return <span className="text-nowrap">{MASKED_VALUE}</span>;
  }
  if (date && typeof date === 'string' && date.length === 4) {
    return <span className="text-nowrap">{date}</span>;
  }
  const convertDate = date ? new Date(date) : date;
  return withTime ? (
    <span className="text-nowrap">{getLocaleDateTimeString(convertDate)}</span>
  ) : (
    <span className="text-nowrap">{getLocaleDateString(convertDate, shortLanguage)}</span>
  );
};

export default FormattedDateTime;
