export const CrossBorderVolumeConfigActions = {
  CROSS_BORDER_VOLUME_CONFIG_GET: 'CROSS_BORDER_VOLUME_CONFIG_GET',
  CROSS_BORDER_VOLUME_CONFIG_GET_SUCCESS: 'CROSS_BORDER_VOLUME_CONFIG_GET_SUCCESS',
  CROSS_BORDER_VOLUME_CONFIG_GET_ERROR: 'CROSS_BORDER_VOLUME_CONFIG_GET_ERROR',
  CROSS_BORDER_VOLUME_CONFIG_UPDATE: 'CROSS_BORDER_VOLUME_CONFIG_UPDATE',
  CROSS_BORDER_VOLUME_CONFIG_UPDATE_SUCCESS: 'CROSS_BORDER_VOLUME_CONFIG_UPDATE_SUCCESS',
  CROSS_BORDER_VOLUME_CONFIG_UPDATE_ERROR: 'CROSS_BORDER_VOLUME_CONFIG_UPDATE_ERROR',
  CROSS_BORDER_VOLUME_CONFIG_DELETE: 'CROSS_BORDER_VOLUME_CONFIG_DELETE',
  CROSS_BORDER_VOLUME_CONFIG_DELETE_SUCCESS: 'CROSS_BORDER_VOLUME_CONFIG_DELETE_SUCCESS',
  CROSS_BORDER_VOLUME_CONFIG_DELETE_ERROR: 'CROSS_BORDER_VOLUME_CONFIG_DELETE_ERROR',
};
