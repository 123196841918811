import { OCCUPATION_LIST_ACTIONS } from 'middleware/action-types';

export const getOccupationFileList = (tenantId) => ({
  type: OCCUPATION_LIST_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getOccupationFileListSuccess = (payload) => ({
  type: OCCUPATION_LIST_ACTIONS.fileList.success,
  payload,
});

export const getOccupationFileListError = () => ({
  type: OCCUPATION_LIST_ACTIONS.fileList.error,
});

export const activateOccupationList = (version) => ({
  type: OCCUPATION_LIST_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateOccupationListSuccess = (payload) => ({
  type: OCCUPATION_LIST_ACTIONS.activateVersion.success,
  payload,
});

export const activateOccupationListError = () => ({
  type: OCCUPATION_LIST_ACTIONS.activateVersion.error,
});
