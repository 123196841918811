import { Box, Grid, Typography } from '@mui/material';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { useTranslation } from 'react-i18next';
import { RiskRatingCategoryDetails } from 'components/popover/person-details/person-details-tab/risk-rating/risk-rating-category-details';
import { WheelPoints } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points';

/**
 * Detail view of each category in customer risk rating
 * @param {*} props
 */
const RiskRatingDataDetailAccordion = ({
  category,
  catScoreItems,
  catTotalPoints,
  popoverMode,
  categoryMaxPoints,
  isIndividual,
  maxPointsByRiskFactor,
  caseConfig,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container>
        <Grid container item justifyContent="space-between" alignItems="center" className="ml-n2">
          <Grid item>
            <IconButtons.Back edge="start" onClick={handleChange('summary')} />
          </Grid>

          <Grid item xs>
            {popoverMode ? (
              <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                {t(`configuration:customerRiskRating.riskFactor.${category}.heading`)}
              </Box>
            ) : (
              <Typography variant="h6">
                {t(`configuration:customerRiskRating.riskFactor.${category}.heading`)}
              </Typography>
            )}
          </Grid>
          <Grid container item className="w-auto pr-2" justifyContent="flex-end">
            <WheelPoints
              points={catTotalPoints}
              maxPoints={categoryMaxPoints[category]}
              tooltip={
                <>
                  <Typography variant="overline" component="p" className="color-white lh-1">
                    {catTotalPoints ?? 0}{' '}
                    {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
                  </Typography>

                  <Typography variant="overline" className="color-on-surface-disabled lh-1">
                    {categoryMaxPoints[category]}{' '}
                    {t('configuration:customerRiskRating.riskFactor.points.pts.max.subheading')}
                  </Typography>
                </>
              }
              size={popoverMode ? 'medium' : 'large'}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} className="pt-2">
          {Object.keys(catScoreItems).map((k, idx) => (
            <Grid item xs={12} key={idx} className="border-bottom">
              <RiskRatingCategoryDetails
                isIndividual={isIndividual}
                popoverMode={popoverMode}
                scoreName={k}
                scoreData={catScoreItems[k] ?? {}}
                maxPoints={maxPointsByRiskFactor[k]}
                caseConfig={caseConfig}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export { RiskRatingDataDetailAccordion };
