import { CounterPartyFrequencyConfigActions } from 'modules/configuration/rule-manager/counter-party-frequency/middleware/action-types';

export const getCounterPartyFrequencyConfig = () => ({
  type: CounterPartyFrequencyConfigActions.GET,
});

export const getCounterPartyFrequencyConfigSuccess = (payload) => ({
  type: CounterPartyFrequencyConfigActions.GET_SUCCESS,
  payload,
});

export const getCounterPartyFrequencyConfigError = () => ({
  type: CounterPartyFrequencyConfigActions.GET_ERROR,
});

export const updateCounterPartyFrequencyConfig = (payload) => ({
  type: CounterPartyFrequencyConfigActions.UPDATE,
  payload,
});

export const updateCounterPartyFrequencyConfigSuccess = (payload) => ({
  type: CounterPartyFrequencyConfigActions.UPDATE_SUCCESS,
  payload,
});

export const updateCounterPartyFrequencyConfigError = () => ({
  type: CounterPartyFrequencyConfigActions.UPDATE_ERROR,
});

export const deleteCounterPartyFrequencyConfig = (payload) => ({
  type: CounterPartyFrequencyConfigActions.DELETE,
  payload,
});

export const deleteCounterPartyFrequencyConfigSuccess = (payload) => ({
  type: CounterPartyFrequencyConfigActions.DELETE_SUCCESS,
  payload,
});

export const deleteCounterPartyFrequencyConfigError = () => ({
  type: CounterPartyFrequencyConfigActions.DELETE_ERROR,
});
