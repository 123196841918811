import { RootState } from 'middleware/store';
import { DetachmentConfig } from 'models/authorization/detachment';
import { shallowEqual, useSelector } from 'react-redux';
import { FEATURE_STATUS } from 'utils/constants/constant';

interface DetachmentValue {
  detachmentConfig: DetachmentConfig;
  isDetachmentEnabled: boolean;
  [key: string]: string | boolean | DetachmentConfig;
}

export const useDetachment = (detachmentName: string) => {
  const detachmentConfig: DetachmentConfig = useSelector(
    (state: RootState) => state.authentication?.detachmentConfig || {},
    shallowEqual,
  );

  const isDetachmentEnabled = detachmentConfig[detachmentName] === FEATURE_STATUS.attached;

  const detachmentValue: DetachmentValue = {
    detachmentConfig,
    isDetachmentEnabled,
  };
  detachmentValue[detachmentName] = isDetachmentEnabled;
  detachmentValue[`${detachmentName}Attached`] = isDetachmentEnabled;
  return detachmentValue;
};
