class GoAMLManager {
  constructor(communicator) {
    this.communicator = communicator;
  }

  /**
   * Params:
   * - caseId: current case uuid
   * Returns: { data }
   */
  getXML(params) {
    return this.communicator.getXML(params).then((xmlString) => ({
      data: xmlString,
    }));
  }

  /**
   * Params:
   * - caseId: current case uuid
   * - xml: xml string to be saved
   * Returns: { successMessage, errorMessage }
   */
  saveXML(params) {
    return this.communicator.saveXML(params).then((result) => {
      let successMessage = '';
      let errorMessage = '';
      if (result === 'true') {
        successMessage = result;
      } else {
        errorMessage = result;
      }
      return { successMessage, errorMessage };
    });
  }

  /**
   * Params:
   * - caseId: current case uuid
   * - xml: xml string to be validated
   * Returns: { errorMessage }
   */
  validateXML(params) {
    return this.communicator.validateXML(params).then((errMsg) => ({ errorMessage: errMsg }));
  }
}

export default GoAMLManager;
