import { Card, Divider, Grid, Tab } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { ReactComponent as InventoryIcon } from 'assets/images/icons/inventory.svg';
import ExecutionStatusChip from 'components/chips/execution-status-chip';
import { EmptyState } from 'components/empty-state';
import { SkeletonLoader } from 'components/loaders/skeleton/skeleton';
import { LoadingContainer } from 'components/loaders/spinner';
import { EnhancedTableHead } from 'components/table/enhanced-table-head';
import TotalResult from 'components/typography/total-result';
import { useAuthorization } from 'hooks/use-authorization';
import { useDetachment } from 'hooks/use-detachment';
import { cloneDeep, isEmpty } from 'lodash';
import { CASE_ACTIONS } from 'middleware/action-types';
import { getDistinctCurrencyValuesByCaseStatus } from 'middleware/actions/case';
import { getSettings } from 'middleware/actions/settings';
import { useExecutionStatus } from 'modules/configuration/experimentation/hooks/use-execution-status';
import { getEnabledFilters } from 'modules/trx-cases/case-detail/utils/helpers/filters';
import CaseNavigator from 'modules/trx-cases/case-list/components/case-navigator';
import TrxCaseRowContainer from 'modules/trx-cases/case-list/components/row/trx-case-row-container';
import TrxCaseListHeader from 'modules/trx-cases/case-list/components/trx-case-list-header';
import { useCategoryWorkflowConfig } from 'modules/trx-cases/case-list/hooks/use-category-workflow-config';
import { useSearchUrl } from 'modules/trx-cases/case-list/hooks/use-search-url';
import { useUrlBasedTransactionFilters } from 'modules/trx-cases/case-list/hooks/use-url-based-transaction-filters';
import { getAllCases } from 'modules/trx-cases/case-list/middleware/actions/case-list';
import { buildPreFilters } from 'modules/trx-cases/case-list/utils/helpers/filters';
import { buildTableHeadCells } from 'modules/trx-cases/case-list/utils/helpers/table';
import { buildCaseMenuItems } from 'modules/trx-cases/case-list/utils/helpers/trx-case-list-utils';
import useMachineIntelligenceFeatures from 'modules/trx-cases/case-list/utils/helpers/use-machine-intelligence-features';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SessionStorageService from 'services/session-storage-service';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import PageSize from 'utils/constants/page-size';
import { SORT_DIRECTION } from 'utils/constants/sort-directions';
import { toggleSort } from 'utils/helpers/cases/case-table-util';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { canSeeAssignedUser, checkPermissions } from 'utils/helpers/permissions-util';
import RedirectUrl from 'utils/helpers/redirect-url';
import { MenuButton, MenuTriggerType } from 'components/menu-button/menu-button';
import { useCustomViews } from 'hooks/use-custom-views';
import { useAlphaFeature } from 'hooks/use-alpha-feature';
import { DC_TEAM_TEST_TENANTS } from 'utils/constants/tenant-ids';
import { getCustomViewsPath } from 'modules/trx-cases/case-list/utils/helpers/custom-views-path';
import { enrichWithDefaults } from 'utils/helpers/url-utils/url-params-manager';

const sessionStorageService = new SessionStorageService();

const AllCases = ({
  nestedFilter,
  caseStatusFilter,
  allMaskedDataShown,
  children,
  excludedFilters = [],
  queryPreFilters = [],
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const { cases = [], totalCases } = useSelector((state) => state.case);
  const { userPermissions } = useSelector((state) => state.authentication);
  const userId = useSelector((state) => state.authentication.user?.userId);
  const processing = useSelector((state) => state.processing);
  const tenantConfig = useSelector((state) => state.tenantConfig);
  const scrollId = useSelector((state) => state.case?.paging?.scrollId);
  const lastPage = useSelector((state) => state.case?.paging?.lastPage);
  const { detachmentConfig } = useAuthorization();
  const { autoCloseRelabel: isAutoCloseRelabelAttached } = useDetachment('autoCloseRelabel');

  const { isAlphaFeature: isCustomViewsEnabled } = useAlphaFeature(
    DC_TEAM_TEST_TENANTS,
    'https://hawkai.atlassian.net/browse/DC-1308',
    new Date(2024, 10, 31),
  );

  const { isLoading: isCustomViewsLoading, customViews } = useCustomViews(isCustomViewsEnabled);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);

  const { isFeatureAttached } = useAuthorization();
  const isInvestigationStatusAttached = isFeatureAttached('investigationStatus');
  const isCategoryWorkflowsAttached = isFeatureAttached('categoryWorkflows');

  const { categoryWorkflowConfig } = useCategoryWorkflowConfig();
  const categoryWorkflowFraudConfig = categoryWorkflowConfig?.categoryWorkflows?.fraud;

  const executionStatus = useExecutionStatus()?.data?.data?.status;

  const isClosedCasesTable = caseStatusFilter?.key === 'closed';

  const preFilters = useMemo(
    () => buildPreFilters(caseStatusFilter?.key, userId, nestedFilter),
    [caseStatusFilter, userId, nestedFilter],
  );
  const { isAnomalyScoreEnabled, isAutoCloseScoreEnabled } = useMachineIntelligenceFeatures();

  const alertPrioritization = useMemo(
    () => ({
      isAnomalyScoreEnabled,
      isAutoCloseScoreEnabled,
    }),
    [isAnomalyScoreEnabled, isAutoCloseScoreEnabled],
  );

  const excluded = excludedFilters.concat(preFilters.map((x) => x.field));

  const isCategoryWorkflowAttached = isFeatureAccessible(
    DETACHMENT_CONFIG.configurations.categoryWorkflows,
    detachmentConfig,
  );

  const canSeeInvestigationOutcome =
    isCategoryWorkflowAttached &&
    categoryWorkflowFraudConfig?.active &&
    categoryWorkflowFraudConfig?.caseClosingLabelActive;

  const enabledFilters = getEnabledFilters({
    t,
    canSeeAssignedUser: canSeeAssignedUser(userPermissions),
    isInvestigationStatusAttached,
    isCategoryAttached: isCategoryWorkflowsAttached,
    excludedFilters: excluded,
    alertPrioritization: { isAnomalyScoreEnabled, isAutoCloseScoreEnabled },
    canSeeInvestigationOutcome,
    caseNotificationRuleInstanceFilterEnabled: true,
    isAutoCloseRelabelAttached,
  });

  const [columnConfig, setColumnConfig] = useState(
    buildTableHeadCells(
      filterOptions,
      userPermissions,
      t,
      isCategoryWorkflowsAttached,
      isInvestigationStatusAttached,
      alertPrioritization,
      isClosedCasesTable,
      isAutoCloseRelabelAttached,
    ),
  );

  // TODO: replace by detachment config check later
  useEffect(() => {
    setColumnConfig(
      buildTableHeadCells(
        filterOptions,
        userPermissions,
        t,
        isCategoryWorkflowsAttached,
        isInvestigationStatusAttached,
        alertPrioritization,
        isClosedCasesTable,
        isAutoCloseRelabelAttached,
      ),
    );
  }, [
    filterOptions,
    userPermissions,
    isCategoryWorkflowsAttached,
    isInvestigationStatusAttached,
    alertPrioritization,
    isClosedCasesTable,
    t,
    isAutoCloseRelabelAttached,
  ]);

  const handleChangeColumnConfig = (newColumnConfig) => {
    setColumnConfig(newColumnConfig);
  };

  const { urlFilterOptions, sortingOptions, setSortingOptions } = useUrlBasedTransactionFilters(
    enabledFilters,
    categoryWorkflowConfig !== undefined,
  );

  const queryCases = (filterConfig, queryCasesSortingOptions, resetScrollId) => {
    const scroll = resetScrollId ? null : scrollId;

    const filters = [...filterConfig, ...preFilters, ...queryPreFilters];

    if (filterConfig && queryCasesSortingOptions) {
      const query = {
        paging: {
          pageSize: PageSize.CASES,
          scrollId: scroll,
        },
        sorting: {
          orders: queryCasesSortingOptions,
        },
        filtering: {
          filters: cloneDeep(filters),
          operator: 'AND',
        },
        allMaskedDataShown,
      };
      dispatch(getAllCases(query));
    }
  };

  /**
   * handle filtering and attach time frame without adding it to state
   * @param {*} filterConfig
   */
  const handleFilterConfigChanges = (filterConfig) => {
    const filterData = [];

    filterConfig.forEach((filter) => {
      filterData.push(filter.form ?? filter);
    });

    setFilterOptions(filterData);
    queryCases(filterData, sortingOptions, true);
  };

  useSearchUrl(filterOptions, sortingOptions);

  /**
   * running single time on load
   */
  useEffect(
    () => {
      if (!isEmpty(caseStatusFilter)) {
        dispatch(getDistinctCurrencyValuesByCaseStatus(caseStatusFilter?.key.toUpperCase()));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    sessionStorageService.setUnmaskCount(0);
  });

  const requestMoreCases = () => {
    if (cases.length > 0) {
      setCurrentPage(currentPage + 1);
      queryCases(filterOptions, sortingOptions);
    }
  };

  RedirectUrl.save(location.pathname);

  /**
   * handling when recieving a sort request
   * @param {*} event
   * @param {*} headCell
   * @param {*} direction
   */
  const handleRequestSort = (event, headCell, direction) => {
    const isInverse = headCell.sortConfig?.inverseSortIcon;
    let newDirection;
    if (direction) {
      newDirection = toggleSort(direction, isInverse);
    } else if (isInverse) {
      newDirection = SORT_DIRECTION.desc;
    } else {
      newDirection = SORT_DIRECTION.asc;
    }

    const tempSorting = [];

    if (newDirection) {
      tempSorting.push({
        field: headCell.sortConfig.name,
        direction: newDirection,
      });
    }

    sortingOptions
      .filter((so) => so.field !== headCell?.sortConfig?.name)
      .forEach((so) => tempSorting.push(so));

    setSortingOptions(tempSorting);
    queryCases(filterOptions, tempSorting, true);
  };

  const caseTypes = buildCaseMenuItems(userPermissions, isAutoCloseRelabelAttached);

  const { canSeeCaseDetails } = checkPermissions(userPermissions);

  const applyCustomViewRoute = (customView) => {
    const newRoute = getCustomViewsPath();
    const searchEnrichedWithDefault = enrichWithDefaults(customView.value);
    history.push({
      pathname: newRoute,
      search: searchEnrichedWithDefault,
    });
  };

  return (
    <>
      <CaseNavigator caseTypes={caseTypes} search={window.location.search}>
        {isCustomViewsEnabled && customViews?.length > 0 && (
          <Tab
            key={Number.MAX_SAFE_INTEGER}
            disableRipple
            value={getCustomViewsPath()}
            className="px-2 py-0 min-w-0 min-h-0"
            label={
              <MenuButton
                title={t('case:caseList.customViews.savedViews.heading')}
                options={customViews?.map((view) => ({
                  title: view.name,
                  onClick: () => applyCustomViewRoute(view),
                }))}
                isLoading={isCustomViewsLoading}
                isSelected={getCustomViewsPath() === location.pathname}
                trigger={MenuTriggerType.CHIP}
              />
            }
          />
        )}
      </CaseNavigator>
      <Card sx={{ position: 'relative', overflow: 'visible' }}>
        <ExecutionStatusChip executionStatus={executionStatus} distanceToParentMultiplier={8} />
        <Grid container id="scrollableDiv">
          <TrxCaseListHeader
            enabledFilters={enabledFilters}
            columnConfig={columnConfig}
            filterOptions={filterOptions}
            preFilters={preFilters}
            sortingOptions={sortingOptions}
            urlFilterOptions={urlFilterOptions}
            handleChangeColumnConfig={handleChangeColumnConfig}
            handleFilterConfigChanges={handleFilterConfigChanges}
            totalCases={totalCases}
            caseStatusFilter={caseStatusFilter}
          />

          <Grid item xs={12}>
            <Divider light />
          </Grid>

          {Boolean(totalCases || totalCases === 0) && (
            <Grid item className="pl-4">
              <TotalResult value={totalCases} />
            </Grid>
          )}

          <Grid item xs={12} className="ml-3">
            {children}
          </Grid>

          <Grid container item xs={12} style={{ display: 'table' }}>
            <InfiniteScroll
              dataLength={cases.length}
              next={requestMoreCases}
              hasMore={!lastPage}
              hasChildren={false}
              style={{ overflow: 'none', height: '100%' }}
              scrollableTarget="scrollableDiv"
            >
              <TableContainer>
                <Table
                  className="support-right-click"
                  size="small"
                  id="caseList"
                  data-testid="case-list"
                >
                  <EnhancedTableHead
                    sortingOptions={sortingOptions}
                    headCells={columnConfig}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {cases.map((row, index) => {
                      const caseDetailUrl = `${currentPath}/${row.groupId}`;
                      return (
                        <TrxCaseRowContainer
                          columnConfig={columnConfig}
                          testIdPrefix={`case-list-${index}`}
                          key={row.groupId}
                          item={row}
                          canSeeCaseDetails={canSeeCaseDetails}
                          detailUrl={caseDetailUrl}
                          tenantConfig={tenantConfig}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>

            {Boolean(!totalCases || totalCases === 0) && !processing.isProcessing && (
              <EmptyState
                icon={<InventoryIcon className="color-black-50 icon-75" />}
                heading={t('case:caseList.emptyList.heading')}
                subheading={t('case:caseList.emptyList.subheading')}
              />
            )}

            <LoadingContainer actionType={CASE_ACTIONS.allCases.fetch.data}>
              <Grid container item xs={12} spacing={2} className="py-2 px-3">
                <SkeletonLoader variant="row" elementsPerRow={6} />
                <SkeletonLoader variant="row" elementsPerRow={6} />
              </Grid>
            </LoadingContainer>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AllCases;
