import TENANT_IDS from 'utils/constants/tenant-ids';
import { ENV_NAME } from 'utils/helpers/config-utils/config';

const hideErrorInSpecificEnvironments = (tenantId, env) => {
  if (!tenantId) {
    return false;
  }

  const tenantIds = [TENANT_IDS.multipleEnviroments.demo];
  if (!tenantIds.includes(tenantId)) {
    return false;
  }

  const environments = [ENV_NAME.test];
  return !!env && environments.includes(env);
};

const getErrorsToBeShown = (errors, tenantId, env) => {
  if (!Array.isArray(errors)) {
    return [];
  }

  if (hideErrorInSpecificEnvironments(tenantId, env)) {
    errors.forEach((error) => {
      if (error.hide !== true) {
        // Not show danger errors but still show others, such as warnings
        // eslint-disable-next-line no-param-reassign
        error.hide = error.custom.alertType === 'error';
      }
    });
  }

  const errorsToBeShown = errors.filter((error) => error.hide !== true);

  errorsToBeShown.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

  return errorsToBeShown;
};
export { getErrorsToBeShown };
