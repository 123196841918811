import PropTypes from 'prop-types';
import MaskedText from 'components/masked-text';
import HoverComponent from 'components/hover-component';

const HoverMaskedText = ({ token, uuid, reloadCaseId, grow, customClass, dataType }) => {
  return (
    <HoverComponent id={token} noPadding>
      <MaskedText
        token={token}
        uuid={uuid}
        reloadCaseId={reloadCaseId}
        grow={grow}
        customClass={customClass}
        dataType={dataType}
      />
    </HoverComponent>
  );
};

HoverMaskedText.propTypes = {
  token: PropTypes.string,
};

export default HoverMaskedText;
