export const CUSTOMER_CASE_DETAIL_ACTIONS = {
  caseDetail: {
    fetch: {
      data: 'CUSTOMER_CASE_DETAIL_GET',
      success: 'CUSTOMER_CASE_DETAIL_SUCCESS',
      error: 'CUSTOMER_CASE_DETAIL_ERROR',
    },
    stateUpdate: {
      data: 'CUSTOMER_CASE_STATE_UPDATE',
      success: 'CUSTOMER_CASE_STATE_UPDATE_SUCCESS',
      error: 'CUSTOMER_CASE_STATE_UPDATE_ERROR',
    },
    reset: 'CUSTOMER_CASE_DETAIL_RESET',
  },
  caseNotes: {
    add: {
      data: 'CUSTOMER_CASE_ADD_NOTE',
      success: 'CUSTOMER_CASE_ADD_NOTE_SUCCESS',
      error: 'CUSTOMER_CASE_ADD_NOTE_ERROR',
    },
    downloadAttachment: {
      data: 'DOWNLOAD_ATTACHMENT_NOTE_CC',
      success: 'DOWNLOAD_ATTACHMENT_NOTE_SUCCESS_CC',
      error: 'DOWNLOAD_ATTACHMENT_NOTE_ERROR_CC',
    },
  },
  unmaskCase: {
    data: 'UNMASK_CUSTOMER_CASES',
    success: 'UNMASK_CUSTOMER_CASES_SUCCESS',
    error: 'UNMASK_CUSTOMER_CASES_ERROR',
  },
  manualRiskLevelChange: {
    data: 'REQUEST_MANUAL_RISK_LEVEL_CHANGE',
    success: 'REQUEST_MANUAL_RISK_LEVEL_CHANGE_SUCCESS',
    error: 'REQUEST_MANUAL_RISK_LEVEL_CHANGE_ERROR',
  },
  pepSanction: {
    assess: {
      data: 'CUSTOMER_CASE_PEP_SANCTION_ASSESS',
      success: 'CUSTOMER_CASE_PEP_SANCTION_ASSESS_SUCCESS',
      error: 'CUSTOMER_CASE_PEP_SANCTION_ASSESS_ERROR',
    },
  },
};
