import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class CountryRiskConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Country Risk Config API Communicator instance is required.');
    }
  }

  validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  getCountryRiskConfig() {
    return this.communicator.fetchCountryRiskConfig().then((json) => this.validateConfigJson(json));
  }

  getCountryRiskConfigWithTenant(tenantId) {
    return this.communicator
      .fetchCountryRiskConfigWithTenant(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  updateCountryRiskConfig(params) {
    return this.communicator.updateCountryRiskConfig(params);
  }
}

export default CountryRiskConfigManager;
