import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class RuleConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Config API communicator is required.');
    }
  }

  static validateConfigJson(json) {
    if (json) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CONFIG_DATA_INVALID);
  }

  getConfig(params) {
    return this.communicator
      .fetchConfig(params)
      .then((json) => RuleConfigManager.validateConfigJson(json));
  }

  updateConfig(params) {
    return this.communicator
      .updateConfig(params)
      .then((json) => RuleConfigManager.validateConfigJson(json));
  }

  deleteConfig(uniqueId) {
    return this.communicator
      .deleteConfig(uniqueId)
      .then((json) => RuleConfigManager.validateConfigJson(json));
  }
}

export default RuleConfigManager;
