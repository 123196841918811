import { PRODUCT_TYPE_LIST_ACTIONS } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import {
  activateProductTypeListIndividualError,
  activateProductTypeListIndividualSuccess,
  activateProductTypeListOrganizationError,
  activateProductTypeListOrganizationSuccess,
  getProductTypeFileListIndividual,
  getProductTypeFileListIndividualError,
  getProductTypeFileListIndividualSuccess,
  getProductTypeFileListOrganization,
  getProductTypeFileListOrganizationError,
  getProductTypeFileListOrganizationSuccess,
} from 'middleware/actions/product-type-list';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import ProductTypeListApiCommunicator from 'services/product-type-list-api-communicator';
import ProductTypeListManager from 'services/product-type-list-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';

const apiCommunicator = new ProductTypeListApiCommunicator();
const productTypeListManager = new ProductTypeListManager(apiCommunicator);

export function* getProductTypeFileListBeginIndividual(action) {
  try {
    yield put(startProcessing(PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchIndividual));

    const json = yield productTypeListManager.getProductTypeFileListIndividual(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getProductTypeFileListIndividualSuccess(json));

    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchIndividual));
  } catch (error) {
    yield put(getProductTypeFileListIndividualError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchIndividual));
  }
}

export function* getProductTypeFileListBeginOrganization(action) {
  try {
    yield put(startProcessing(PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchOrganization));

    const json = yield productTypeListManager.getProductTypeFileListOrganization(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getProductTypeFileListOrganizationSuccess(json));

    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchOrganization));
  } catch (error) {
    yield put(getProductTypeFileListOrganizationError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchOrganization));
  }
}

/**
 * Fetch customer productType files action watcher
 */
export function* getProductTypeFileListIndividualWatcher() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchIndividual,
    getProductTypeFileListBeginIndividual,
  );
}

export function* getProductTypeFileListOrganizationWatcher() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchOrganization,
    getProductTypeFileListBeginOrganization,
  );
}

export function* activateProductTypeFileListIndividualWatcher() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchIndividual,
    activateProductTypeFileListIndividualWatcher,
  );
}

export function* activateProductTypeFileListOrganizationWatcher() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.fileList.fetchOrganization,
    activateProductTypeFileListOrganizationWatcher,
  );
}

export function* updateActiveProductTypeListVersionBeginIndividual(action) {
  try {
    yield put(startProcessing(PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendIndividual));

    const json = yield productTypeListManager.updateActiveProductTypeListVersionIndividual(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateProductTypeListIndividualSuccess(json));
    yield put(getProductTypeFileListIndividual(TenantManagementService.getActiveTenantId()));

    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendIndividual));
  } catch (error) {
    yield put(activateProductTypeListIndividualError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendIndividual));
  }
}

export function* updateActiveProductTypeListVersionBeginOrganization(action) {
  try {
    yield put(startProcessing(PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendOrganization));

    const json = yield productTypeListManager.updateActiveProductTypeListVersionOrganization(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateProductTypeListOrganizationSuccess(json));
    yield put(getProductTypeFileListOrganization(TenantManagementService.getActiveTenantId()));

    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendOrganization));
  } catch (error) {
    yield put(activateProductTypeListOrganizationError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendOrganization));
  }
}

export function* updateActiveProductTypeListVersionIndividualWatcher() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendIndividual,
    updateActiveProductTypeListVersionBeginIndividual,
  );
}

export function* updateActiveProductTypeListVersionOrganizationWatcher() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendOrganization,
    updateActiveProductTypeListVersionBeginOrganization,
  );
}

export function* activateProductTypeFileListWatcherIndividual() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendIndividual,
    activateProductTypeFileListWatcherIndividual,
  );
}

export function* activateProductTypeFileListWatcherOrganization() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendOrganization,
    activateProductTypeFileListWatcherOrganization,
  );
}

export function* activateUpdateActiveProductTypeListVersionWatcherIndividual() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendIndividual,
    activateProductTypeFileListWatcherIndividual,
  );
}

export function* activateUpdateActiveProductTypeListVersionWatcherOrganization() {
  yield takeLatest(
    PRODUCT_TYPE_LIST_ACTIONS.activateVersion.sendOrganization,
    activateProductTypeFileListWatcherOrganization,
  );
}
