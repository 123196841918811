const ITEM_SEPARATOR = ',';
const VALUES_SEPARATOR = ';';
const KEY_VALUE_SEPARATOR = '=';
const PARAM_SEPARATOR = '&';
const SECTION_OPENING_BRACKET = '(';
const SECTION_CLOSING_BRACKET = ')';
const VALUES_OPENING_BRACKET = '[';
const VALUES_CLOSING_BRACKET = ']';

const extractFieldValuePairs = (inputString) =>
  inputString
    .replace(SECTION_OPENING_BRACKET, '')
    .replace(SECTION_CLOSING_BRACKET, '')
    .split(ITEM_SEPARATOR)
    .map((f) => f.split(KEY_VALUE_SEPARATOR));

class UrlFilterParamsParser {
  static parse(paramsString, tenantConfig, defaultFilter) {
    const filtersParsingResult = [];
    const sortingParsingResult = [];

    const searchParams = new URLSearchParams(paramsString);
    const filterSearchParams = searchParams.get('f'); //

    // add default updatedAt filter if no filter value detected in url
    if (!filterSearchParams && defaultFilter) {
      filtersParsingResult.push(...defaultFilter);
    }

    if (!paramsString || paramsString.length === 0) {
      const tempSorting = tenantConfig?.caseListSort?.split(';');

      if (tempSorting) {
        tempSorting.forEach((sortItem) => {
          const tempSortItem = sortItem.split(',');
          sortingParsingResult.push({
            field: tempSortItem.shift(),
            direction: tempSortItem.shift(),
          });
        });
      }

      return {
        filters: filtersParsingResult,
        sorts: sortingParsingResult,
      };
    }

    searchParams.forEach((v, k) => {
      const configObject = Object.fromEntries(extractFieldValuePairs(v));
      if (k === 'f') {
        if (configObject.values) {
          configObject.values = [
            ...configObject.values
              .replace(VALUES_OPENING_BRACKET, '')
              .replace(VALUES_CLOSING_BRACKET, '')
              .split(VALUES_SEPARATOR)
              .filter((v) => v.length > 0),
          ];
        }
        if (configObject.currencies) {
          configObject.currencies = [
            ...configObject.currencies
              .replace(VALUES_OPENING_BRACKET, '')
              .replace(VALUES_CLOSING_BRACKET, '')
              .split(VALUES_SEPARATOR)
              .filter((v) => v.length > 0),
          ];
        }

        if (configObject.rangeStart && !(configObject.condition === 'INCLUSIVE_INT_RANGE')) {
          if (isNaN(Date.parse(configObject.rangeStart))) {
            return;
          }
          configObject.rangeStart = new Date(configObject.rangeStart).toISOString();
        }
        if (configObject.rangeEnd && !(configObject.condition === 'INCLUSIVE_INT_RANGE')) {
          if (isNaN(Date.parse(configObject.rangeEnd))) {
            configObject.rangeEnd = '';
          } else {
            configObject.rangeEnd = new Date(configObject.rangeEnd).toISOString();
          }
        }

        filtersParsingResult.push(configObject);
      }
      if (k === 's') {
        sortingParsingResult.push(configObject);
      }
    });

    return {
      filters: filtersParsingResult,
      sorts: sortingParsingResult,
    };
  }

  static buildUrl(filterConfig, sortingConfig) {
    const filterQueryUrlPart = filterConfig
      ?.map((filter) =>
        Object.entries(filter)
          .map(([field, value, condition]) => {
            if (['values', 'currencies'].includes(field)) {
              if (!value) {
                return undefined;
              }
              return `${field}=${VALUES_OPENING_BRACKET}${[...value]
                ?.map(encodeURIComponent)
                .join(VALUES_SEPARATOR)}${VALUES_CLOSING_BRACKET}`;
            }
            if (['rangeStart', 'rangeEnd'].includes(field)) {
              if (typeof value === 'string') {
                return `${field}=${value}`;
              }
              if (field === 'rangeEnd' && value === null) {
                return `${field}=`;
              }
              if (!(condition === 'INCLUSIVE_INT_RANGE') && value !== null) {
                return `${field}=${value?.toISOString()}`;
              }
              return undefined;
            }
            return `${field}=${encodeURIComponent(value)}`;
          })
          .join(ITEM_SEPARATOR),
      )
      .map(
        (filterString) => `f=${SECTION_OPENING_BRACKET}${filterString}${SECTION_CLOSING_BRACKET}`,
      )
      .join(PARAM_SEPARATOR);

    const sortingQueryUrlPart = sortingConfig
      ?.map((sort) =>
        Object.entries(sort)
          .map(([field, value]) => `${field}=${value}`)
          .join(ITEM_SEPARATOR),
      )
      .map(
        (sortingString) => `s=${SECTION_OPENING_BRACKET}${sortingString}${SECTION_CLOSING_BRACKET}`,
      )
      .join(PARAM_SEPARATOR);

    const searchUrl = `${filterQueryUrlPart ?? ''}${filterQueryUrlPart ? PARAM_SEPARATOR : ''}${
      sortingQueryUrlPart ?? ''
    }`;

    return searchUrl ? `?${searchUrl}` : '';
  }
}
export default UrlFilterParamsParser;
