import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLeading } from 'redux-saga/effects';
import CustomerListManager from 'modules/customer-data/customer-list/middleware/services/customer-list-manager';
import CustomerListApiCommunicator from 'modules/customer-data/customer-list/middleware/services/customer-list-api';
import {
  getCustomers,
  getCustomersSuccess,
} from 'modules/customer-data/customer-list/middleware/reducers/customer-list-reducer';
import getMasksFromObject from 'utils/helpers/mask-util';
import { updateMasks } from 'middleware/actions/mask';

const customerListCommunicator = new CustomerListApiCommunicator();
const customerListManager = new CustomerListManager(customerListCommunicator);

function* getCustomersBegin(action) {
  yield put(startProcessing(getCustomers.type));
  try {
    const json = yield customerListManager.queryAll(action.payload);

    yield put(
      getCustomersSuccess({
        type: action.type,
        data: json,
      }),
    );
    yield put(updateMasks(getMasksFromObject(json).map((it) => ({ ...it }))));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(getCustomers.type));
}

export function* getCustomersWatcher() {
  yield takeLeading(getCustomers.type, getCustomersBegin);
}
