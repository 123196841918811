import { TRANSACTION_FIELDS_ACTIONS } from 'middleware/action-types';

const transactionFieldsReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTION_FIELDS_ACTIONS.fetch.success:
      return {
        ...state,
        data: action.payload,
      };
    case TRANSACTION_FIELDS_ACTIONS.fetch.error:
      return {
        ...state,
        data: [],
      };

    default:
      return state;
  }
};

export default transactionFieldsReducer;
