import { AssessmentEngineConfigActions } from 'middleware/action-types';

export const getAssessmentEngineConfig = () => ({
  type: AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET,
});

export const getAssessmentEngineConfigSuccess = (payload) => ({
  type: AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET_SUCCESS,
  payload,
});

export const getAssessmentEngineConfigError = () => ({
  type: AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET_ERROR,
});

export const updateAssessmentEngineConfig = (payload) => ({
  type: AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_UPDATE,
  payload,
});

export const updateAssessmentEngineConfigSuccess = (payload) => ({
  type: AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateAssessmentEngineConfigError = () => ({
  type: AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_UPDATE_ERROR,
});
