import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { encodeHtml } from 'utils/helpers/string-util';

class GoAMLAPICommunicator extends APICommunicator {
  getXML({ caseId, createdAt }) {
    const apiUrl = API_URLS.goAMLService.getXML.replaceAll(API_PARAMS.caseId, caseId);
    return this.getXMLForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
      createdAt,
    });
  }

  saveXML({ caseId, xml, createdAt }) {
    const encodeXml = encodeHtml(xml);

    const apiUrl = API_URLS.goAMLService.saveXML.replaceAll(API_PARAMS.caseId, caseId);
    return this.putXMLForTenant(apiUrl, encodeXml, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
      createdAt,
    });
  }

  validateXML({ caseId, xml, createdAt }) {
    const encodeXml = encodeHtml(xml);
    const apiUrl = API_URLS.goAMLService.validateXML.replaceAll(API_PARAMS.caseId, caseId);
    return this.postXMLForTenant(apiUrl, encodeXml, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
      createdAt,
    });
  }
}

export default GoAMLAPICommunicator;
