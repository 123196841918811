import React, { useContext, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Chip, InputAdornment, Popper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { difference, isEqual } from 'lodash';
import FilterPopper from 'components/filters/filter-popper';
import GlobalContext from 'contexts/global/global-context';
import usePrevious from 'hooks/use-previous';
import FilterChipLabel, { mapUrlToFormFilter } from 'components/filters/filter-chip-label';

const Filters = ({
  urlFilterConfig,
  enabledFilters,
  nonDeletableFilters,
  nonEditableFilters,
  dataTestIdPrefix,
  isCustomerCasesList = false,
  showSearchIcon = true,
  textFieldVariant = 'standard',
  displayOnlyMode = false,
  customPlaceholder = '',
  onFilterConfigChanges,
}) => {
  const { t } = useTranslation();
  const initialRender = useRef(true);
  const filterOptions = Object.values(t('mapping:filters.filtering'));

  let updatedFilterOptions = filterOptions.filter((filter) => enabledFilters.includes(filter.key));
  updatedFilterOptions = updatedFilterOptions.sort((a, b) =>
    a.value.heading.localeCompare(b.value.heading),
  );

  const previousUrlFilterConfig = usePrevious(urlFilterConfig);

  const filterRef = useRef();
  const chipRef = useRef([]);

  const [currentFilters, setCurrentFilters] = useState(mapUrlToFormFilter(urlFilterConfig));
  const [selectedFilter, setSelectedFilter] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const { filtersShouldBeOpened, setFiltersShouldBeOpened } = useContext(GlobalContext);

  const handleClick = () => {
    setSelectedFilter(null);
    setAnchorEl(filterRef.current);
  };

  const handleClose = () => {
    setSelectedFilter(null);
  };

  /**
   * AssignedToUser and AssignedToUserId filters are mutually exclusive. If user uses both, the first is removed.
   * Both are sent under the same key {@see handleAssignedToUserFilter} but different conditions what is no currently handled.
   */
  const removePriorForMutuallyExclusiveFilters = (customFilter) => {
    const keys = customFilter.map((filter) => filter.key);
    if (difference(['unassigned', 'assignedToUserId'], keys).length === 0) {
      const unassignedIndex = keys.indexOf('unassigned');
      const assignedToUserIdIndex = keys.indexOf('assignedToUserId');

      const arrayWithoutMutuallyExclusiveFilters =
        unassignedIndex < assignedToUserIdIndex
          ? customFilter.splice(unassignedIndex, 1)
          : customFilter.splice(assignedToUserIdIndex, 1);

      setSelectedFilter(arrayWithoutMutuallyExclusiveFilters);
    }
  };

  const handleCurrentFilters = (customFilter) => {
    removePriorForMutuallyExclusiveFilters(customFilter);

    customFilter
      .filter((filter) => filter.key === 'unassigned')
      .forEach((filter) => {
        filter.form.field = 'assignedToUserId';
      });

    // TO-DO: IRM-21 - remove this obscure dynamic replacement when autoclose flag is no longer maintained.
    customFilter.forEach((item) => {
      if (item?.form?.values) {
        item.form.values = item.form.values.map((value) =>
          value === 'SCREENING_FPR' ? 'SCREENING_AUTOCLOSE' : value,
        );
      }
    });

    setCurrentFilters(customFilter);
    onFilterConfigChanges(customFilter);
  };

  const handleSubmitFilter = (formValues) => {
    selectedFilter.form = formValues;
    const foundIndex = currentFilters.findIndex((filter) => filter.key === selectedFilter.key);
    if (foundIndex < 0) {
      handleCurrentFilters([...currentFilters, selectedFilter]);
    } else {
      const updateFilters = [...currentFilters];
      updateFilters[foundIndex] = selectedFilter;
      handleCurrentFilters(updateFilters);
    }

    handleClose();
  };

  useEffect(() => {
    if (currentFilters?.length) {
      handleCurrentFilters(currentFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (!isEqual(urlFilterConfig, previousUrlFilterConfig)) {
      handleCurrentFilters(mapUrlToFormFilter(urlFilterConfig));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFilterConfig]);

  useEffect(() => {
    filterRef?.current?.focus();
    filterRef?.current?.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const onTagsChange = (event, values, reason) => {
    if (values.length > currentFilters.length) {
      setSelectedFilter(values[values.length - 1]);
      filterRef.current.blur();
    } else if (nonDeletableFilters) {
      if (values.length >= nonDeletableFilters.length) {
        handleCurrentFilters(values);
      }
    } else {
      handleCurrentFilters(values);
    }

    if (reason === 'remove-option') {
      setFiltersShouldBeOpened(true);
    } else if (filtersShouldBeOpened) {
      setFiltersShouldBeOpened(false);
    }
  };

  const updateSelectedFilter = (option) => {
    if (option.key === 'anomalyScoreMax') {
      const { form } = option;
      const updatedValue = form.value * 100;
      setSelectedFilter({ ...option, form: { ...form, value: updatedValue } });
    } else {
      setSelectedFilter(option);
    }
  };

  const handleChipClick = (event, option, index) => {
    event.stopPropagation();
    setAnchorEl(chipRef.current[index]);
    updateSelectedFilter(option);
  };

  const AutocompletePopper = (props) => {
    const { children } = props;
    return (
      Boolean(anchorEl && !selectedFilter) && (
        <Popper
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          data-testid={`${dataTestIdPrefix}-popover`}
          className="w-fit-content z-1300"
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          {children}
        </Popper>
      )
    );
  };

  const getPlaceholderText = () =>
    displayOnlyMode ? undefined : customPlaceholder || t('search.subheading');

  return (
    <>
      <Autocomplete
        value={currentFilters}
        multiple
        openOnFocus={filtersShouldBeOpened}
        onChange={onTagsChange}
        className="p-0"
        PopperComponent={AutocompletePopper}
        options={updatedFilterOptions}
        getOptionLabel={(option) => option.value.heading}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
              ref={(element) => {
                chipRef.current[index] = element;
              }}
              className={`${getTagProps({ index }).className} square min-h`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={
                displayOnlyMode ? undefined : (event) => handleChipClick(event, option, index)
              }
              disabled={nonEditableFilters?.includes(option.key)}
              onDelete={
                displayOnlyMode || nonDeletableFilters?.includes(option.key)
                  ? undefined
                  : getTagProps({ index }).onDelete
              }
              label={<FilterChipLabel option={option} />}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            data-testid={`${dataTestIdPrefix}-text-field`}
            inputRef={filterRef}
            onClick={displayOnlyMode ? undefined : handleClick}
            variant={textFieldVariant}
            placeholder={getPlaceholderText()}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                'data-testid': `${dataTestIdPrefix}-text-field-value`,
                disabled: displayOnlyMode,
              },
              disableUnderline: true,
              endAdornment: null,
              startAdornment: (
                <>
                  {showSearchIcon && (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {selectedFilter && (
        <FilterPopper
          isCustomerCasesList={isCustomerCasesList}
          filter={selectedFilter}
          handleClose={handleClose}
          anchorEl={anchorEl}
          selectedFilter={selectedFilter}
          handleSubmitFilter={handleSubmitFilter}
        />
      )}
    </>
  );
};

export default Filters;
