import { forwardRef, useState } from 'react';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'utils/constants/constant';

import { TextField, Grid, Select, MenuItem, IconButton, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import moment from 'moment';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  menuPaperMonth: {
    top: '56px !important',
    left: '40px !important',
    maxHeight: 250,
  },
  menuPaperYear: {
    top: '56px !important',
    left: '165px !important',
    maxHeight: 250,
  },
}));

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  years,
  months,
}) => {
  const classes = useStyles();

  const handleChangeYear = (event) => {
    changeYear(event.target.value);
  };

  const handleChangeMonth = (event) => {
    changeMonth(months.indexOf(event.target.value));
  };

  return (
    <Grid container wrap="nowrap" className="mb-2">
      <IconButton component="span" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <ChevronLeftIcon />
      </IconButton>

      <Select
        className="mr-2"
        size="small"
        sx={{
          width: 115,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        value={months[getMonth(date)]}
        onChange={(event) => handleChangeMonth(event)}
        MenuProps={{
          disablePortal: true,
          classes: { paper: classes.menuPaperMonth },
        }}
      >
        {months.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>

      <Select
        size="small"
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        value={getYear(date)}
        onChange={(event) => handleChangeYear(event)}
        MenuProps={{
          disablePortal: true,
          classes: { paper: classes.menuPaperYear },
        }}
      >
        {years.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>

      <IconButton component="span" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <ChevronRightIcon />
      </IconButton>
    </Grid>
  );
};

const DatePicker = ({
  name,
  label,
  value,
  fullWidth = true,
  size = 'small',
  onChange,
  hasError = false,
  helperText = '',
  minDate,
  maxDate = new Date(),
  showTime = true,
  timeFormat = TIME_FORMAT.fullHour,
  dateFormat = DATE_TIME_FORMAT.medium,
  'data-testid': dataTestId,
}) => {
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = moment.months();

  const convertToDefEventPara = (eventName, eventValue) => ({
    target: {
      name: eventName,
      value: eventValue,
    },
  });

  const { t } = useTranslation();

  let error = false;
  const handleChange = (name, event) => {
    const selectedFormat = showTime ? DATE_TIME_FORMAT.mediumMoment : DATE_FORMAT.medium;

    const dateInfo = {
      value: event.target.value,
      object: new Date(event.target.value),
    };

    if (moment(dateInfo.value, selectedFormat, true).isValid()) {
      if (dateInfo.object.getTime() !== new Date(value).getTime()) {
        onChange(convertToDefEventPara(name, dateInfo.object));
      }
      error = false;
    } else {
      error = true;
    }
  };

  const DateContainer = ({ className, children }) => {
    const [tempDate, setTempDate] = useState(
      moment(value).format(showTime ? DATE_TIME_FORMAT.mediumMoment : DATE_FORMAT.medium),
    );
    const [isDateVisible, setIsDateVisible] = useState(true);

    const handleChangeInput = (name, event) => {
      handleChange(name, event);
      setTempDate(event.target.value);
    };

    const handleConfirm = () => {
      if (!error) {
        setIsDateVisible(false);
      }
    };

    return (
      isDateVisible && (
        <div className="d-flex">
          <CalendarContainer className={`${className} d-flex`}>
            <Grid container item xs>
              <div className="d-flex">{children}</div>
              <Grid container item xs spacing={1} className="p-2">
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    label={label}
                    value={tempDate}
                    onChange={(event) => handleChangeInput(name, event)}
                    size="small"
                    fullWidth
                    error={error}
                    InputLabelProps={{ shrink: true }}
                    helperText={error ? t('date.error.heading') : ''}
                  />
                </Grid>

                <Grid container item xs={12} justifyContent="end">
                  <Button color="primary" onClick={handleConfirm}>
                    {t('confirm.heading')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CalendarContainer>
        </div>
      )
    );
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      data-testid={dataTestId}
      fullWidth={fullWidth}
      size={size}
      label={label}
      onClick={onClick}
      value={value}
      ref={ref}
      error={hasError}
      helperText={helperText}
    />
  ));

  return (
    <>
      <ReactDatePicker
        selected={moment(value).toDate()}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        maxDate={maxDate}
        minDate={minDate}
        customInput={<CustomInput />}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CustomHeader
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            years={years}
            months={months}
          />
        )}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        showTimeSelect={showTime}
        calendarContainer={DateContainer}
      />
    </>
  );
};

export default DatePicker;
