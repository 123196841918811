import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import React, { FC } from 'react';

const Copy: FC<IconButtonProps> = ({ onClick, disabled, edge = false, size = 'medium' }) => {
  const { t } = useTranslation();
  const iconButton = (
    <IconButton onClick={onClick} disabled={disabled} edge={edge} size={size}>
      <CopyAllIcon />
    </IconButton>
  );
  return disabled ? iconButton : <Tooltip title={t('copy.heading')}>{iconButton}</Tooltip>;
};

export default Copy;
