import React from 'react';
import { Box, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Filter, FilterChipProps } from 'components/filters/models/filter';
import { noRoles } from 'components/filters/fields/role/role-filter-util';
import useAllRoles from 'modules/configuration/workflows-and-users/components/user-management/hooks/use-all-roles';

const RoleChip: React.FC<FilterChipProps> = ({ form }) => {
  const { t } = useTranslation();

  const allRoles = useAllRoles().data ?? [];

  const roleNameById = (id: string): string =>
    [noRoles, ...allRoles].find((r) => r.id === id)?.name ?? '';

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.roles.value.heading')}
      </Box>
      {(form as Filter).values.map((item) => (
        <Chip
          key={item}
          variant="outlined"
          size="small"
          className="mr-1"
          label={roleNameById(item)}
        />
      ))}
    </>
  );
};

export default RoleChip;
