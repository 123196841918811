import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserManagementApi from 'modules/configuration/workflows-and-users/components/user-management/services/user-management-api';
import { showError } from 'middleware/actions/error';

const useAllRoles = () => {
  const { t } = useTranslation('configuration', { keyPrefix: 'userManagement' });
  const dispatch = useDispatch();
  const userManagementApi = new UserManagementApi();
  return useQuery({
    queryKey: ['allRoles'],
    queryFn: () => userManagementApi.getRoles(),
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      dispatch(
        showError({
          message: t('fetchRoles.error.heading'),
        }),
      );
    },
  });
};

export default useAllRoles;
