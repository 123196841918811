import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box, Chip } from '@mui/material';
import { BIN_PAYMENT } from 'utils/constants/constant';
import { getCountryName } from 'utils/constants/country-list';
import { RiskChip } from 'components/chips/risk-chip';
import { useSelector } from 'react-redux';
import {
  getSettingsFromState,
  getCountryRiskLevel,
  getTenantDefaultCurrency,
} from 'utils/helpers/config-utils/config-util';
import { formatMoney } from 'utils/helpers/math-util';
import FormattedDateTime from 'components/formatted-date-time';
import moment from 'moment';

function makeContractDate(value) {
  if (value && value !== '9999-12-31') {
    return new Date(value);
  }
  return null;
}

/**
 * company data for person popover
 * @param {*} props
 */
const AccountData = ({ account, caseConfig }) => {
  const { t } = useTranslation();
  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);
    return settings?.countryRisk;
  });
  const tenantConfig = useSelector((state) => state?.tenantConfig);
  const tenantBaseCurrency = getTenantDefaultCurrency(tenantConfig);
  const contractStartDate = makeContractDate(account.contractualDetails?.contractStartDate);
  const contractEndDate = makeContractDate(account.contractualDetails?.contractEndDate);
  const duration =
    contractEndDate && contractStartDate
      ? Math.round(moment(contractEndDate).diff(contractStartDate, 'months', true))
      : null;

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('case:caseDetail.tabContainer.transaction.holder.heading')}
        </Typography>

        <Typography variant="body2">{account.holderName ?? '-'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('case:caseDetail.tabContainer.transaction.accountNumber.heading')}
        </Typography>
        <Typography variant="body2">{account.accountNumber ?? '-'}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {BIN_PAYMENT.includes(account.bankIdentifier)
            ? t('case:caseDetail.tabContainer.transaction.bankIdentifier.subheading')
            : t('case:caseDetail.tabContainer.transaction.bankIdentifier.heading')}{' '}
        </Typography>
        <Typography variant="body2">{account.bankIdentifier ?? '-'}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body2" color="textSecondary">
          {t('case:caseDetail.tabContainer.transaction.bankCountry.heading')}
        </Typography>
        {account.bankCountry ? (
          <RiskChip
            size="small"
            risk={getCountryRiskLevel(account.bankCountry, countryRisk)}
            label={getCountryName(account.bankCountry)}
          />
        ) : (
          '-'
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="textSecondary">
          {t('case:caseDetail.tabContainer.transaction.scheme.heading')}
        </Typography>
        <Typography variant="body2">{account.scheme ?? '-'}</Typography>
      </Grid>

      {account.countryOfSettlement && (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('case:caseDetail.tabContainer.transaction.countryOfSettlement.heading')}
          </Typography>
          {account.countryOfSettlement ? (
            <RiskChip
              size="small"
              risk={getCountryRiskLevel(account.countryOfSettlement, countryRisk)}
              label={getCountryName(account.countryOfSettlement)}
            />
          ) : (
            '-'
          )}
        </Grid>
      )}

      {account.flags && (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('case:caseDetail.tabContainer.transaction.flags.heading')}
          </Typography>
          {account.flags.length > 0
            ? account.flags.map((flag) => <Chip size="small" label={flag} />)
            : '-'}
        </Grid>
      )}

      {account.contractualDetails && (
        <>
          <Grid container item className="pt-3">
            <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
              {t('case:caseDetail.tabContainer.transaction.contractualDetails.heading')}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractStartDate.heading')}
            </Typography>
            <Typography variant="body2">
              {contractStartDate ? (
                <FormattedDateTime date={contractStartDate} withTime={false} />
              ) : (
                '-'
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractEndDate.heading')}
            </Typography>
            <Typography variant="body2">
              {contractEndDate ? (
                <FormattedDateTime date={contractEndDate} withTime={false} />
              ) : (
                '-'
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractDuration.heading')}
            </Typography>
            <Typography variant="body2">
              {duration
                ? t('case:caseDetail.tabContainer.transaction.contractDuration.subheading', {
                    num: duration,
                  })
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractStatus.heading')}
            </Typography>
            <Typography variant="body2">
              {account.contractualDetails?.contractStatus ?? '-'}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.creditLimit.heading')}
            </Typography>
            {account.creditLimitDetails?.limit ? (
              <Typography variant="body2">
                <Typography component="span" variant="caption" color="textPrimary">
                  {account.creditLimitDetails?.limit.currency.currencyCode}{' '}
                </Typography>
                {formatMoney(account.creditLimitDetails?.limit.number)}
              </Typography>
            ) : (
              '-'
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractValueMonthly.heading')}
            </Typography>
            {account.contractualDetails?.contractValueMonthly ? (
              <Typography variant="body2">
                <Typography component="span" variant="caption" color="textPrimary">
                  {tenantBaseCurrency}{' '}
                </Typography>
                {formatMoney(account.contractualDetails?.contractValueMonthly)}
              </Typography>
            ) : (
              '-'
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractValueYearly.heading')}
            </Typography>
            {account.contractualDetails?.contractValueYearly ? (
              <Typography variant="body2">
                <Typography component="span" variant="caption" color="textPrimary">
                  {tenantBaseCurrency}{' '}
                </Typography>
                {formatMoney(account.contractualDetails?.contractValueYearly)}
              </Typography>
            ) : (
              '-'
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractValueTotal.heading')}
            </Typography>
            {account.contractualDetails?.contractValueTotal ? (
              <Typography variant="body2">
                <Typography component="span" variant="caption" color="textPrimary">
                  {tenantBaseCurrency}{' '}
                </Typography>
                {formatMoney(account.contractualDetails?.contractValueTotal)}
              </Typography>
            ) : (
              '-'
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:caseDetail.tabContainer.transaction.contractOneOffPayment.heading')}
            </Typography>
            {account.contractualDetails?.contractOneOffPayment ? (
              <Typography variant="body2">
                <Typography component="span" variant="caption" color="textPrimary">
                  {tenantBaseCurrency}{' '}
                </Typography>
                {formatMoney(account.contractualDetails?.contractOneOffPayment)}
              </Typography>
            ) : (
              '-'
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export { AccountData };
