import { buildLastUpdatedSubmitValues } from 'components/filters/fields/last-updated';
import { TIME_FRAME_OPTIONS } from 'utils/constants/dashboard-constant';
import UrlFilterParamsParser from 'utils/helpers/url-utils/url-filter-params-parser';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { handleInvalidUpdatedAtFilter } from 'utils/helpers/filters/handle-invalid-updated-at-filter';

export const useUrlBasedTransactionFilters = (enabledFilters, isConfigReady = true) => {
  const { search } = useLocation();
  const tenantConfig = useSelector((state) => state.tenantConfig);

  const getInitialUrl = () => {
    const defaultLastUpdatedAtFilter = buildLastUpdatedSubmitValues(
      TIME_FRAME_OPTIONS.LAST_3_MONTH.value.from,
      '',
    );
    const defaultFilter = [defaultLastUpdatedAtFilter];

    const configFromUrl = UrlFilterParamsParser.parse(search, tenantConfig, defaultFilter);

    configFromUrl.filters = isConfigReady
      ? configFromUrl.filters.filter((x) => enabledFilters.includes(x.field))
      : configFromUrl.filters;

    handleInvalidUpdatedAtFilter(configFromUrl, defaultLastUpdatedAtFilter);

    return configFromUrl;
  };

  const [urlFilterOptions] = useState(getInitialUrl().filters || []);

  const [sortingOptions, setSortingOptions] = useState(getInitialUrl().sorts || []);

  return { urlFilterOptions, sortingOptions, setSortingOptions };
};
