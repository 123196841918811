import { Grid, Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const PercentDeviationScoreDetails = ({ maxPoints, scoreData, popoverMode, title }) => {
  const metaDataKeys = getMetaDataKeys();

  const { t } = useTranslation();

  const [detailLineData, setDetailLineData] = useState([]);

  const requirePercentDeviationName = (riskFactorInstance) =>
    riskFactorInstance.pointsConfigs[0].name;

  useEffect(() => {
    const deviationName = requirePercentDeviationName(scoreData.RISK_FACTOR_INSTANCE);
    const basePercentDeviationKey = 'mapping:customerRiskRating.scoreGroups.DEVIATION.options';
    const loadedPercentDeviations = t(basePercentDeviationKey);

    const detailLineArr = Object.keys(loadedPercentDeviations[deviationName].scoreDetails)
      .filter((key) => scoreData && scoreData[key] && scoreData[key].DEVIATION)
      .map((key) => {
        const deviationPercent = Math.round(scoreData[key].DEVIATION * 100);
        const detailLinePrefix = t(
          `${basePercentDeviationKey}.${deviationName}.scoreDetails.${key}.heading`,
        );
        return {
          key,
          scoreDetailsHeading: `${detailLinePrefix} ${deviationPercent}%`,
        };
      });

    setDetailLineData(detailLineArr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData]);

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData[metaDataKeys.calculatedPoints] ?? 0}
          maxPoints={maxPoints}
          headline={title}
        />
      }
      highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
      detailsComponent={
        <Grid container>
          {detailLineData.map(({ key, scoreDetailsHeading }) => (
            <Grid key={key} item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                data-testid={`percent-deviation-${
                  scoreData?.RISK_FACTOR_INSTANCE?.uniqueId
                }-${key.toLowerCase()}-original-data-container`}
              >
                {scoreDetailsHeading}
              </Typography>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

export { PercentDeviationScoreDetails };
