import SessionService from 'modules/authentication/login/services/session-service';
import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import PageSize from 'utils/constants/page-size';

const buildPayloadForCustomerCases = (customerIdToken, filters, scrollId = '') => {
  const requestFilters = [];
  requestFilters.push({
    condition: 'TEXT_EQUAL',
    field: 'customerIdToken',
    values: [customerIdToken],
  });
  filters?.forEach((it) => requestFilters.push(it.form));

  return {
    paging: { pageSize: PageSize.CUSTOMER_DUE_DILIGENCE, scrollId },
    filtering: {
      filters: requestFilters,
      operator: 'AND',
    },
  };
};

const buildPayloadForTransactionCases = (tenantId, customerIdToken, filters, scrollId = '') => {
  const requestFilters = [];
  requestFilters.push({
    condition: 'TEXT_EQUAL',
    field: 'masterData.accountHoldingPartyDataCustomerId.token',
    values: [customerIdToken],
  });

  filters.forEach((it) => requestFilters.push(it.form));

  return {
    tenantId,
    correlationId: SessionService.getInstance().getCorrelationId(),
    paging: { pageSize: PageSize.CUSTOMER_DUE_DILIGENCE, scrollId },
    filtering: {
      filters: requestFilters,
      operator: 'AND',
    },
    sorting: {
      orders: [
        {
          field: 'createdAt',
          direction: 'DESC',
        },
      ],
    },
  };
};

class CustomerDetailApiCommunicator extends APICommunicator {
  fetch(customerIdToken) {
    const apiUrl = API_URLS.customerData.customerDetail.fetch;

    const payload = {
      paging: { pageSize: 1, scrollId: '' },
      filtering: {
        filters: [
          {
            condition: 'TEXT_EQUAL',
            field: '_id',
            values: [customerIdToken],
          },
        ],
        operator: 'AND',
      },
    };
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getAllCustomerCases(customerIdToken, filters) {
    const apiUrl = API_URLS.customerService.cases.get;

    const payload = buildPayloadForCustomerCases(customerIdToken, filters);
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getMoreCustomerCases({ scrollId, customerIdToken, filters }) {
    const apiUrl = API_URLS.customerService.cases.get;

    const payload = buildPayloadForCustomerCases(customerIdToken, filters, scrollId);

    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getAllTransactionCases(tenantId, customerIdToken, filters) {
    const apiUrl = API_URLS.caseService.search;

    const payload = buildPayloadForTransactionCases(tenantId, customerIdToken, filters);
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getMoreTransactionCases({ tenantId, scrollId, customerIdToken, filters }) {
    const apiUrl = API_URLS.caseService.search;

    const payload = buildPayloadForTransactionCases(tenantId, customerIdToken, filters, scrollId);

    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getCustomerDetailsV2(customerIdToken) {
    const apiUrl = API_URLS.customerService.customerDetails.fetchDetailsV2.replaceAll(
      API_PARAMS.customerIdToken,
      customerIdToken,
    );

    return this.getJSONForUserId(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default CustomerDetailApiCommunicator;
