import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class DataProviderListConfigAPI extends APICommunicator {
  fetchConfig() {
    const apiUrl = API_URLS.listManager.dataProviderList.fetch.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId() as string,
    );

    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateConfig(params: unknown) {
    const apiUrl = API_URLS.listManager.dataProviderList.upsert.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId() as string,
    );
    return this.postJSONForTenant(
      apiUrl,
      {
        instances: [params],
      },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteConfig(id: string) {
    const apiUrl = API_URLS.listManager.dataProviderList.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId() as string)
      .replace(API_PARAMS.id, id);

    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default DataProviderListConfigAPI;
