import auth0, { WebAuth } from 'auth0-js';
import { CorrelationId, CorrelationIdSchema } from 'models/case/correlation-id';
import { Tenant } from 'models/tenant/tenant';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { LOCAL_STORAGE } from 'utils/constants/constant';
import config from 'utils/helpers/config-utils/config';
import { calculateExpireAt } from 'utils/helpers/error-util';
import { getActiveTenantInfo } from 'utils/helpers/tenant-utils/tenant-util';
import { z } from 'zod';

/* eslint-disable class-methods-use-this */
class SessionService {
  // eslint-disable-next-line no-use-before-define
  private static instance: SessionService;

  auth0: WebAuth;

  tenantsMap: Map<string, Tenant>;

  constructor() {
    this.login = this.login.bind(this);
    this.emptyLocalStorage = this.emptyLocalStorage.bind(this);
    this.logout = this.logout.bind(this);

    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getUsername = this.getUsername.bind(this);

    this.auth0 = new auth0.WebAuth(config.auth0);
    this.tenantsMap = new Map();
  }

  public static getInstance(): SessionService {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService();
    }

    return SessionService.instance;
  }

  login() {
    this.auth0.authorize({
      prompt: 'login',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyStorage(storage: any) {
    storage.removeItem('id_token');
    storage.removeItem('expires_at');
    storage.removeItem('nickname');
    storage.removeItem('username');
    storage.removeItem('email');
    storage.removeItem('tenant_id');
    storage.removeItem('tenant_name');
    storage.removeItem('display_name');
    storage.removeItem('role');
    storage.removeItem('user_id');
    storage.removeItem('correlationId');
    storage.removeItem('active_tenant_id');
    storage.removeItem('tenants');
    storage.removeItem('picture');
    storage.removeItem('last_login');
    storage.removeItem('last_tenant_id');
    storage.removeItem('last_tenant_name');

    TenantManagementService.removeTenantIdOfCurrentCase();
  }

  emptyLocalStorage() {
    this.emptyStorage(localStorage);
  }

  emptySessionStorage() {
    this.emptyStorage(sessionStorage);
  }

  emptyStorages() {
    this.emptyLocalStorage();
    this.emptySessionStorage();
  }

  logout() {
    this.emptyStorages();
    this.removeMaskingConfig();
  }

  isAuthenticated() {
    const date = localStorage.getItem('expires_at');
    if (!date) return false;
    const expiresAt = JSON.parse(date);
    const result = new Date().getTime() < expiresAt;
    if (result) {
      const newExpiresAt = calculateExpireAt(config.expiredAfterMinutes);
      localStorage.setItem('expires_at', newExpiresAt);
    }
    return result;
  }

  // eslint-disable-next-line
  getExpiresAt() {
    return localStorage.getItem('expires_at');
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  getNickname() {
    return localStorage.getItem('nickname');
  }

  getEmail() {
    return localStorage.getItem('email');
  }

  getCorrelationId() {
    const correlationId = localStorage.getItem('correlationId');
    CorrelationIdSchema.safeParse(correlationId);
    return correlationId;
  }

  getUserId() {
    return localStorage.getItem('user_id');
  }

  setLastLoginAt(lastLoginAt: string) {
    z.string().datetime().safeParse(lastLoginAt);
    localStorage.setItem('last_login', lastLoginAt);
  }

  setUserPicture(picture: string) {
    localStorage.setItem('picture', picture);
  }

  setUserEmail(email: string) {
    z.string().email().safeParse(email);
    localStorage.setItem('email', email);
  }

  setUserId(userId: string) {
    localStorage.setItem('user_id', userId);
  }

  setUserDisplayName(displayName: string) {
    localStorage.setItem('username', displayName);
  }

  setCorrelationId(correlationId: CorrelationId) {
    CorrelationIdSchema.safeParse(correlationId);
    localStorage.setItem('correlationId', correlationId);
  }

  setExpiresAt(expiresAt: string) {
    z.string().datetime().safeParse(expiresAt);
    localStorage.setItem('expires_at', expiresAt);
  }

  removeTenantIdOfCurrentCase() {
    localStorage.removeItem(LOCAL_STORAGE.currentCase.tenantId);
  }

  removeMaskingConfig() {
    localStorage.removeItem(LOCAL_STORAGE.caseList.companyDataShownState);
    localStorage.removeItem(LOCAL_STORAGE.caseDetails.maskedDataShowState);
  }

  // eslint-disable-next-line
  setUserInformationLocally(reduxAuthState: any) {
    const lastTenantId = TenantManagementService.getLastTenantId() || '';
    const lastTenantName = TenantManagementService.getLastTenantName() || '';

    const tenant = getActiveTenantInfo(reduxAuthState, lastTenantId);

    this.emptyStorages();

    this.setLastLoginAt(reduxAuthState.lastLoginAt);
    this.setCorrelationId(reduxAuthState.correlationId);
    TenantManagementService.setActiveTenantId(tenant.id);
    TenantManagementService.setLastTenantId(tenant.id);

    let displayName = '';
    let tenantName = '';
    let tenants;
    if (reduxAuthState.user) {
      const { picture, email, userId } = reduxAuthState.user || {};
      this.setUserPicture(picture);
      this.setUserEmail(email);
      this.setUserId(userId);

      displayName = reduxAuthState.user.nickname;

      if (reduxAuthState.user.user_metadata) {
        if (reduxAuthState.user.user_metadata.display_name) {
          displayName = reduxAuthState.user.user_metadata.display_name;
        }
        if (reduxAuthState.user.user_metadata.tenant_name) {
          tenantName = reduxAuthState.user.user_metadata.tenant_name;
        }
      } else if (reduxAuthState.user) {
        displayName = reduxAuthState.user.displayName;
      }
      tenants = reduxAuthState.user.tenants;
    }

    this.setUserDisplayName(displayName);
    if (Array.isArray(tenants)) {
      let activeTenantId;
      const found = tenants.find((t: Tenant) => t.id === lastTenantId);
      if (found) {
        activeTenantId = found.id;
        tenantName = found.name;
      } else if (tenants.length > 0) {
        const firstTenant = tenants[0];
        activeTenantId = firstTenant.id;
        tenantName = firstTenant.name;
      }

      TenantManagementService.setActiveTenantId(activeTenantId);
      TenantManagementService.setLastTenantId(activeTenantId);
      TenantManagementService.setTenants(tenants);
      tenantName = tenant.name;
    } else {
      tenantName = lastTenantName;
    }

    TenantManagementService.setTenantName(tenantName);
    TenantManagementService.setLastTenantName(tenantName);

    const expiresAt = calculateExpireAt(config.expiredAfterMinutes);
    this.setExpiresAt(expiresAt);
  }
}

export default SessionService;
