import { SuspiciousAvgTicketSizeConfigActions } from 'modules/configuration/rule-manager/suspicious-avg-ticket-size/middleware/action-types';
import { convertMultiplierToReadablePercentage } from 'modules/configuration/rule-manager/common/helpers/util';

const suspiciousAvgTicketSizeConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_GET_SUCCESS:
    case SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_UPDATE_SUCCESS:
    case SuspiciousAvgTicketSizeConfigActions.SUSPICIOUS_AVG_TICKET_SIZE_CONFIG_DELETE_SUCCESS: {
      return {
        ...state,
        instances: convertMultiplierToReadablePercentage(action),
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }
    default:
      return state;
  }
};

export default suspiciousAvgTicketSizeConfigReducer;
