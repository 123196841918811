import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UrlFilterParamsParser from 'utils/helpers/url-utils/url-filter-params-parser';

export const useSearchUrl = (filterOptions, sortingOptions) => {
  const locationHook = useLocation();

  useEffect(
    () => {
      const filterUrl = UrlFilterParamsParser.buildUrl(filterOptions, sortingOptions);
      const newSearchUrl = location.pathname + filterUrl;

      /*
      We skip react history mechanism here.
      We should use code like this:
      "const history = useHistory() from 'react-router-dom'
      history.replace({ search: filterUrl });"
      When we do that we have a loop inside AllCases component between:
      location, filterOptions and urlFilterOptions. Part of loop are also Filters
      component and function handleFilterConfigChanges .
       */
      window.history.replaceState({ path: newSearchUrl }, '', newSearchUrl);
      locationHook.search = filterUrl;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterOptions, sortingOptions],
  );
};
