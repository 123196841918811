import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const IS_PROCESSING_KEY = 'isProcessing';

interface ProcessingState {
  [key: string]: boolean;
}

const isProcessing = (state: ProcessingState) => {
  let value = false;
  for (const key in state) {
    if (key !== IS_PROCESSING_KEY) {
      value = value || state[key];
    }
  }
  return value;
};

const initialState: ProcessingState = {
  isProcessing: false,
};

export const processingSlice = createSlice({
  name: 'processing',
  initialState,
  reducers: {
    startProcessing: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        [action.payload]: true,
      };
      newState[IS_PROCESSING_KEY] = isProcessing(newState);
      return newState;
    },
    stopProcessing: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        [action.payload]: false,
      };
      newState[IS_PROCESSING_KEY] = isProcessing(newState);
      return newState;
    },
  },
});

export const { startProcessing, stopProcessing } = processingSlice.actions;
export default processingSlice.reducer;
