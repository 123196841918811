import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class CustomerCaseManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Case API Communicator instance is required.');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  validateAllCasesJson(json) {
    const valid = json && Array.isArray(json.cases);
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  queryAllCases(paging, sortingConfig, filterConfig, allMaskedDataShown) {
    return this.communicator
      .fetchAllCases(paging, sortingConfig, filterConfig, allMaskedDataShown)
      .then((json) => this.validateAllCasesJson(json));
  }
}

export default CustomerCaseManager;
