import { put, takeLatest } from 'redux-saga/effects';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { PLAUSIBILITY_ACTIONS } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import {
  deletePlausibilityConfigError,
  deletePlausibilityConfigSuccess,
  getPlausibilityConfigError,
  getPlausibilityConfigSuccess,
  updatePlausibilityConfigError,
  updatePlausibilityConfigSuccess,
} from 'middleware/actions/config/plausibility';
import { PlausibilityConfigApiCommunicator } from 'services/plausibility-config-api-communicator';
import { PlausibilityConfigManager } from 'services/plausibility-config-manager';

const plausibilityConfigAPICommunicator = new PlausibilityConfigApiCommunicator();
const plausibilityConfigManager = new PlausibilityConfigManager(plausibilityConfigAPICommunicator);

function* getPlausibilityConfigBegin(action) {
  try {
    yield put(startProcessing(PLAUSIBILITY_ACTIONS.fetch.data));

    const json = yield plausibilityConfigManager.getPlausibilityConfig();
    yield put(getPlausibilityConfigSuccess(json));

    yield put(stopProcessing(PLAUSIBILITY_ACTIONS.fetch.data));
  } catch (error) {
    yield put(getPlausibilityConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PLAUSIBILITY_ACTIONS.fetch.error));
  }
}

export function* getPlausibilityConfigWatcher() {
  yield takeLatest(PLAUSIBILITY_ACTIONS.fetch.data, getPlausibilityConfigBegin);
}

function* updatePlausibilityConfigBegin(action) {
  yield put(startProcessing(PLAUSIBILITY_ACTIONS.update.data));

  try {
    const json = yield plausibilityConfigManager.updatePlausibilityConfig(action.payload);
    yield put(updatePlausibilityConfigSuccess(json));
  } catch (error) {
    yield put(updatePlausibilityConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PLAUSIBILITY_ACTIONS.update.data));
}

export function* updatePlausibilityConfigWatcher() {
  yield takeLatest(PLAUSIBILITY_ACTIONS.update.data, updatePlausibilityConfigBegin);
}

function* deletePlausibilityConfigBegin(action) {
  yield put(startProcessing(PLAUSIBILITY_ACTIONS.delete.data));

  try {
    const json = yield plausibilityConfigManager.deletePlausibilityConfig(action.payload);
    yield put(deletePlausibilityConfigSuccess(json));
  } catch (error) {
    yield put(deletePlausibilityConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PLAUSIBILITY_ACTIONS.delete.data));
}

export function* deletePlausibilityConfigWatcher() {
  yield takeLatest(PLAUSIBILITY_ACTIONS.delete.data, deletePlausibilityConfigBegin);
}
