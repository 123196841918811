import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

const validateConfigJson = (json) => {
  const valid = json;
  if (valid) {
    return Promise.resolve(json);
  }
  return raiseErrorByCode(ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
};

class SettingsManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Settings API Communicator instance is required.');
    }
  }

  getSettings(tenantId, configVersion) {
    return this.communicator
      .getSettings(tenantId, configVersion)
      .then((json) => validateConfigJson(json));
  }

  updateSettings(tenantId, payload) {
    return this.communicator
      .updateSettings(tenantId, payload)
      .then((json) => validateConfigJson(json));
  }

  updateSettingsContryRisks(tenantId, payload) {
    const { title, model } = payload;
    if (payload.id) {
      return this.communicator
        .updateSettingsContryRisks(tenantId, { title, model }, payload.id)
        .then((json) => validateConfigJson(json));
    }
    return this.communicator
      .addSettingsContryRisks(tenantId, { title, model })
      .then((json) => validateConfigJson(json));
  }

  deleteSettingsContryRisks(tenantId, instanceId) {
    return this.communicator
      .deleteSettingsContryRisks(tenantId, instanceId)
      .then((json) => validateConfigJson(json));
  }
}

export default SettingsManager;
