const VolumeRiskFeatureNames = {};
VolumeRiskFeatureNames.ACCOUNT_BASED = [
  'volume-account-holding-party-credit-1-day',
  'volume-account-holding-party-credit-2-days',
  'volume-account-holding-party-credit-3-days',
  'volume-account-holding-party-credit-4-days',
  'volume-account-holding-party-credit-5-days',
  'volume-account-holding-party-credit-6-days',
  'volume-account-holding-party-credit-1-week',
  'volume-account-holding-party-credit-2-weeks',
  'volume-account-holding-party-credit-3-weeks',
  'volume-account-holding-party-credit-1-month',
  'volume-account-holding-party-credit-2-months',
  'volume-account-holding-party-credit-3-months',
  'volume-account-holding-party-debit-1-day',
  'volume-account-holding-party-debit-2-days',
  'volume-account-holding-party-debit-3-days',
  'volume-account-holding-party-debit-4-days',
  'volume-account-holding-party-debit-5-days',
  'volume-account-holding-party-debit-6-days',
  'volume-account-holding-party-debit-1-week',
  'volume-account-holding-party-debit-2-weeks',
  'volume-account-holding-party-debit-3-weeks',
  'volume-account-holding-party-debit-1-month',
  'volume-account-holding-party-debit-2-months',
  'volume-account-holding-party-debit-3-months',
  'volume-account-holding-party-credit-or-debit-1-day',
  'volume-account-holding-party-credit-or-debit-2-days',
  'volume-account-holding-party-credit-or-debit-3-days',
  'volume-account-holding-party-credit-or-debit-4-days',
  'volume-account-holding-party-credit-or-debit-5-days',
  'volume-account-holding-party-credit-or-debit-6-days',
  'volume-account-holding-party-credit-or-debit-1-week',
  'volume-account-holding-party-credit-or-debit-2-weeks',
  'volume-account-holding-party-credit-or-debit-3-weeks',
  'volume-account-holding-party-credit-or-debit-1-month',
  'volume-account-holding-party-credit-or-debit-2-months',
  'volume-account-holding-party-credit-or-debit-3-months',
  'volume-account-holding-party-credit-minus-debit-1-day',
  'volume-account-holding-party-credit-minus-debit-2-days',
  'volume-account-holding-party-credit-minus-debit-3-days',
  'volume-account-holding-party-credit-minus-debit-4-days',
  'volume-account-holding-party-credit-minus-debit-5-days',
  'volume-account-holding-party-credit-minus-debit-6-days',
  'volume-account-holding-party-credit-minus-debit-1-week',
  'volume-account-holding-party-credit-minus-debit-2-weeks',
  'volume-account-holding-party-credit-minus-debit-3-weeks',
  'volume-account-holding-party-credit-minus-debit-1-month',
  'volume-account-holding-party-credit-minus-debit-2-months',
  'volume-account-holding-party-credit-minus-debit-3-months',
];

VolumeRiskFeatureNames.TRANSFER_BASED = [
  'volume-transfer-credit-1-day',
  'volume-transfer-credit-2-days',
  'volume-transfer-credit-3-days',
  'volume-transfer-credit-4-days',
  'volume-transfer-credit-5-days',
  'volume-transfer-credit-6-days',
  'volume-transfer-credit-1-week',
  'volume-transfer-credit-2-weeks',
  'volume-transfer-credit-3-weeks',
  'volume-transfer-credit-1-month',
  'volume-transfer-credit-2-months',
  'volume-transfer-credit-3-months',
  'volume-transfer-debit-1-day',
  'volume-transfer-debit-2-days',
  'volume-transfer-debit-3-days',
  'volume-transfer-debit-4-days',
  'volume-transfer-debit-5-days',
  'volume-transfer-debit-6-days',
  'volume-transfer-debit-1-week',
  'volume-transfer-debit-2-weeks',
  'volume-transfer-debit-3-weeks',
  'volume-transfer-debit-1-month',
  'volume-transfer-debit-2-months',
  'volume-transfer-debit-3-months',
  'volume-transfer-credit-or-debit-1-day',
  'volume-transfer-credit-or-debit-2-days',
  'volume-transfer-credit-or-debit-3-days',
  'volume-transfer-credit-or-debit-4-days',
  'volume-transfer-credit-or-debit-5-days',
  'volume-transfer-credit-or-debit-6-days',
  'volume-transfer-credit-or-debit-1-week',
  'volume-transfer-credit-or-debit-2-weeks',
  'volume-transfer-credit-or-debit-3-weeks',
  'volume-transfer-credit-or-debit-1-month',
  'volume-transfer-credit-or-debit-2-months',
  'volume-transfer-credit-or-debit-3-months',
  'volume-transfer-credit-minus-debit-1-day',
  'volume-transfer-credit-minus-debit-2-days',
  'volume-transfer-credit-minus-debit-3-days',
  'volume-transfer-credit-minus-debit-4-days',
  'volume-transfer-credit-minus-debit-5-days',
  'volume-transfer-credit-minus-debit-6-days',
  'volume-transfer-credit-minus-debit-1-week',
  'volume-transfer-credit-minus-debit-2-weeks',
  'volume-transfer-credit-minus-debit-3-weeks',
  'volume-transfer-credit-minus-debit-1-month',
  'volume-transfer-credit-minus-debit-2-months',
  'volume-transfer-credit-minus-debit-3-months',
];

VolumeRiskFeatureNames.CUSTOMER_BASED = [
  'volume-customer-id-credit-1-day',
  'volume-customer-id-credit-2-days',
  'volume-customer-id-credit-3-days',
  'volume-customer-id-credit-4-days',
  'volume-customer-id-credit-5-days',
  'volume-customer-id-credit-6-days',
  'volume-customer-id-credit-1-week',
  'volume-customer-id-credit-2-weeks',
  'volume-customer-id-credit-3-weeks',
  'volume-customer-id-credit-1-month',
  'volume-customer-id-credit-2-months',
  'volume-customer-id-credit-3-months',
  'volume-customer-id-debit-1-day',
  'volume-customer-id-debit-2-days',
  'volume-customer-id-debit-3-days',
  'volume-customer-id-debit-4-days',
  'volume-customer-id-debit-5-days',
  'volume-customer-id-debit-6-days',
  'volume-customer-id-debit-1-week',
  'volume-customer-id-debit-2-weeks',
  'volume-customer-id-debit-3-weeks',
  'volume-customer-id-debit-1-month',
  'volume-customer-id-debit-2-months',
  'volume-customer-id-debit-3-months',
  'volume-customer-id-credit-or-debit-1-day',
  'volume-customer-id-credit-or-debit-2-days',
  'volume-customer-id-credit-or-debit-3-days',
  'volume-customer-id-credit-or-debit-4-days',
  'volume-customer-id-credit-or-debit-5-days',
  'volume-customer-id-credit-or-debit-6-days',
  'volume-customer-id-credit-or-debit-1-week',
  'volume-customer-id-credit-or-debit-2-weeks',
  'volume-customer-id-credit-or-debit-3-weeks',
  'volume-customer-id-credit-or-debit-1-month',
  'volume-customer-id-credit-or-debit-2-months',
  'volume-customer-id-credit-or-debit-3-months',
  'volume-customer-id-credit-minus-debit-1-day',
  'volume-customer-id-credit-minus-debit-2-days',
  'volume-customer-id-credit-minus-debit-3-days',
  'volume-customer-id-credit-minus-debit-4-days',
  'volume-customer-id-credit-minus-debit-5-days',
  'volume-customer-id-credit-minus-debit-6-days',
  'volume-customer-id-credit-minus-debit-1-week',
  'volume-customer-id-credit-minus-debit-2-weeks',
  'volume-customer-id-credit-minus-debit-3-weeks',
  'volume-customer-id-credit-minus-debit-1-month',
  'volume-customer-id-credit-minus-debit-2-months',
  'volume-customer-id-credit-minus-debit-3-months',
];

VolumeRiskFeatureNames.CUSTOMER_GROUP_BASED = [
  'volume-customer-group-id-credit-1-day',
  'volume-customer-group-id-credit-2-days',
  'volume-customer-group-id-credit-3-days',
  'volume-customer-group-id-credit-4-days',
  'volume-customer-group-id-credit-5-days',
  'volume-customer-group-id-credit-6-days',
  'volume-customer-group-id-credit-1-week',
  'volume-customer-group-id-credit-2-weeks',
  'volume-customer-group-id-credit-3-weeks',
  'volume-customer-group-id-credit-1-month',
  'volume-customer-group-id-credit-2-months',
  'volume-customer-group-id-credit-3-months',
  'volume-customer-group-id-debit-1-day',
  'volume-customer-group-id-debit-2-days',
  'volume-customer-group-id-debit-3-days',
  'volume-customer-group-id-debit-4-days',
  'volume-customer-group-id-debit-5-days',
  'volume-customer-group-id-debit-6-days',
  'volume-customer-group-id-debit-1-week',
  'volume-customer-group-id-debit-2-weeks',
  'volume-customer-group-id-debit-3-weeks',
  'volume-customer-group-id-debit-1-month',
  'volume-customer-group-id-debit-2-months',
  'volume-customer-group-id-debit-3-months',
  'volume-customer-group-id-credit-or-debit-1-day',
  'volume-customer-group-id-credit-or-debit-2-days',
  'volume-customer-group-id-credit-or-debit-3-days',
  'volume-customer-group-id-credit-or-debit-4-days',
  'volume-customer-group-id-credit-or-debit-5-days',
  'volume-customer-group-id-credit-or-debit-6-days',
  'volume-customer-group-id-credit-or-debit-1-week',
  'volume-customer-group-id-credit-or-debit-2-weeks',
  'volume-customer-group-id-credit-or-debit-3-weeks',
  'volume-customer-group-id-credit-or-debit-1-month',
  'volume-customer-group-id-credit-or-debit-2-months',
  'volume-customer-group-id-credit-or-debit-3-months',
  'volume-customer-group-id-credit-minus-debit-1-day',
  'volume-customer-group-id-credit-minus-debit-2-days',
  'volume-customer-group-id-credit-minus-debit-3-days',
  'volume-customer-group-id-credit-minus-debit-4-days',
  'volume-customer-group-id-credit-minus-debit-5-days',
  'volume-customer-group-id-credit-minus-debit-6-days',
  'volume-customer-group-id-credit-minus-debit-1-week',
  'volume-customer-group-id-credit-minus-debit-2-weeks',
  'volume-customer-group-id-credit-minus-debit-3-weeks',
  'volume-customer-group-id-credit-minus-debit-1-month',
  'volume-customer-group-id-credit-minus-debit-2-months',
  'volume-customer-group-id-credit-minus-debit-3-months',
];

VolumeRiskFeatureNames.COUNTER_PARTY_TRANSFER_BASED = [
  'volume-transfer-counter-party-country-risk-low-credit-1-day',
  'volume-transfer-counter-party-country-risk-low-credit-2-days',
  'volume-transfer-counter-party-country-risk-low-credit-3-days',
  'volume-transfer-counter-party-country-risk-low-credit-4-days',
  'volume-transfer-counter-party-country-risk-low-credit-5-days',
  'volume-transfer-counter-party-country-risk-low-credit-6-days',
  'volume-transfer-counter-party-country-risk-low-credit-1-week',
  'volume-transfer-counter-party-country-risk-low-credit-2-weeks',
  'volume-transfer-counter-party-country-risk-low-credit-3-weeks',
  'volume-transfer-counter-party-country-risk-low-credit-1-month',
  'volume-transfer-counter-party-country-risk-low-credit-2-months',
  'volume-transfer-counter-party-country-risk-low-credit-3-months',
  'volume-transfer-counter-party-country-risk-low-debit-1-day',
  'volume-transfer-counter-party-country-risk-low-debit-2-days',
  'volume-transfer-counter-party-country-risk-low-debit-3-days',
  'volume-transfer-counter-party-country-risk-low-debit-4-days',
  'volume-transfer-counter-party-country-risk-low-debit-5-days',
  'volume-transfer-counter-party-country-risk-low-debit-6-days',
  'volume-transfer-counter-party-country-risk-low-debit-1-week',
  'volume-transfer-counter-party-country-risk-low-debit-2-weeks',
  'volume-transfer-counter-party-country-risk-low-debit-3-weeks',
  'volume-transfer-counter-party-country-risk-low-debit-1-month',
  'volume-transfer-counter-party-country-risk-low-debit-2-months',
  'volume-transfer-counter-party-country-risk-low-debit-3-months',

  'volume-transfer-counter-party-country-risk-medium-credit-1-day',
  'volume-transfer-counter-party-country-risk-medium-credit-2-days',
  'volume-transfer-counter-party-country-risk-medium-credit-3-days',
  'volume-transfer-counter-party-country-risk-medium-credit-4-days',
  'volume-transfer-counter-party-country-risk-medium-credit-5-days',
  'volume-transfer-counter-party-country-risk-medium-credit-6-days',
  'volume-transfer-counter-party-country-risk-medium-credit-1-week',
  'volume-transfer-counter-party-country-risk-medium-credit-2-weeks',
  'volume-transfer-counter-party-country-risk-medium-credit-3-weeks',
  'volume-transfer-counter-party-country-risk-medium-credit-1-month',
  'volume-transfer-counter-party-country-risk-medium-credit-2-months',
  'volume-transfer-counter-party-country-risk-medium-credit-3-months',
  'volume-transfer-counter-party-country-risk-medium-debit-1-day',
  'volume-transfer-counter-party-country-risk-medium-debit-2-days',
  'volume-transfer-counter-party-country-risk-medium-debit-3-days',
  'volume-transfer-counter-party-country-risk-medium-debit-4-days',
  'volume-transfer-counter-party-country-risk-medium-debit-5-days',
  'volume-transfer-counter-party-country-risk-medium-debit-6-days',
  'volume-transfer-counter-party-country-risk-medium-debit-1-week',
  'volume-transfer-counter-party-country-risk-medium-debit-2-weeks',
  'volume-transfer-counter-party-country-risk-medium-debit-3-weeks',
  'volume-transfer-counter-party-country-risk-medium-debit-1-month',
  'volume-transfer-counter-party-country-risk-medium-debit-2-months',
  'volume-transfer-counter-party-country-risk-medium-debit-3-months',

  'volume-transfer-counter-party-country-risk-high-credit-1-day',
  'volume-transfer-counter-party-country-risk-high-credit-2-days',
  'volume-transfer-counter-party-country-risk-high-credit-3-days',
  'volume-transfer-counter-party-country-risk-high-credit-4-days',
  'volume-transfer-counter-party-country-risk-high-credit-5-days',
  'volume-transfer-counter-party-country-risk-high-credit-6-days',
  'volume-transfer-counter-party-country-risk-high-credit-1-week',
  'volume-transfer-counter-party-country-risk-high-credit-2-weeks',
  'volume-transfer-counter-party-country-risk-high-credit-3-weeks',
  'volume-transfer-counter-party-country-risk-high-credit-1-month',
  'volume-transfer-counter-party-country-risk-high-credit-2-months',
  'volume-transfer-counter-party-country-risk-high-credit-3-months',
  'volume-transfer-counter-party-country-risk-high-debit-1-day',
  'volume-transfer-counter-party-country-risk-high-debit-2-days',
  'volume-transfer-counter-party-country-risk-high-debit-3-days',
  'volume-transfer-counter-party-country-risk-high-debit-4-days',
  'volume-transfer-counter-party-country-risk-high-debit-5-days',
  'volume-transfer-counter-party-country-risk-high-debit-6-days',
  'volume-transfer-counter-party-country-risk-high-debit-1-week',
  'volume-transfer-counter-party-country-risk-high-debit-2-weeks',
  'volume-transfer-counter-party-country-risk-high-debit-3-weeks',
  'volume-transfer-counter-party-country-risk-high-debit-1-month',
  'volume-transfer-counter-party-country-risk-high-debit-2-months',
  'volume-transfer-counter-party-country-risk-high-debit-3-months',
];

VolumeRiskFeatureNames.COUNTER_PARTY_ACCOUNT_BASED = [
  'volume-counter-party-country-risk-low-credit-1-day',
  'volume-counter-party-country-risk-low-credit-2-days',
  'volume-counter-party-country-risk-low-credit-3-days',
  'volume-counter-party-country-risk-low-credit-4-days',
  'volume-counter-party-country-risk-low-credit-5-days',
  'volume-counter-party-country-risk-low-credit-6-days',
  'volume-counter-party-country-risk-low-credit-1-week',
  'volume-counter-party-country-risk-low-credit-2-weeks',
  'volume-counter-party-country-risk-low-credit-3-weeks',
  'volume-counter-party-country-risk-low-credit-1-month',
  'volume-counter-party-country-risk-low-credit-2-months',
  'volume-counter-party-country-risk-low-credit-3-months',
  'volume-counter-party-country-risk-low-debit-1-day',
  'volume-counter-party-country-risk-low-debit-2-days',
  'volume-counter-party-country-risk-low-debit-3-days',
  'volume-counter-party-country-risk-low-debit-4-days',
  'volume-counter-party-country-risk-low-debit-5-days',
  'volume-counter-party-country-risk-low-debit-6-days',
  'volume-counter-party-country-risk-low-debit-1-week',
  'volume-counter-party-country-risk-low-debit-2-weeks',
  'volume-counter-party-country-risk-low-debit-3-weeks',
  'volume-counter-party-country-risk-low-debit-1-month',
  'volume-counter-party-country-risk-low-debit-2-months',
  'volume-counter-party-country-risk-low-debit-3-months',

  'volume-counter-party-country-risk-medium-credit-1-day',
  'volume-counter-party-country-risk-medium-credit-2-days',
  'volume-counter-party-country-risk-medium-credit-3-days',
  'volume-counter-party-country-risk-medium-credit-4-days',
  'volume-counter-party-country-risk-medium-credit-5-days',
  'volume-counter-party-country-risk-medium-credit-6-days',
  'volume-counter-party-country-risk-medium-credit-1-week',
  'volume-counter-party-country-risk-medium-credit-2-weeks',
  'volume-counter-party-country-risk-medium-credit-3-weeks',
  'volume-counter-party-country-risk-medium-credit-1-month',
  'volume-counter-party-country-risk-medium-credit-2-months',
  'volume-counter-party-country-risk-medium-credit-3-months',
  'volume-counter-party-country-risk-medium-debit-1-day',
  'volume-counter-party-country-risk-medium-debit-2-days',
  'volume-counter-party-country-risk-medium-debit-3-days',
  'volume-counter-party-country-risk-medium-debit-4-days',
  'volume-counter-party-country-risk-medium-debit-5-days',
  'volume-counter-party-country-risk-medium-debit-6-days',
  'volume-counter-party-country-risk-medium-debit-1-week',
  'volume-counter-party-country-risk-medium-debit-2-weeks',
  'volume-counter-party-country-risk-medium-debit-3-weeks',
  'volume-counter-party-country-risk-medium-debit-1-month',
  'volume-counter-party-country-risk-medium-debit-2-months',
  'volume-counter-party-country-risk-medium-debit-3-months',

  'volume-counter-party-country-risk-high-credit-1-day',
  'volume-counter-party-country-risk-high-credit-2-days',
  'volume-counter-party-country-risk-high-credit-3-days',
  'volume-counter-party-country-risk-high-credit-4-days',
  'volume-counter-party-country-risk-high-credit-5-days',
  'volume-counter-party-country-risk-high-credit-6-days',
  'volume-counter-party-country-risk-high-credit-1-week',
  'volume-counter-party-country-risk-high-credit-2-weeks',
  'volume-counter-party-country-risk-high-credit-3-weeks',
  'volume-counter-party-country-risk-high-credit-1-month',
  'volume-counter-party-country-risk-high-credit-2-months',
  'volume-counter-party-country-risk-high-credit-3-months',
  'volume-counter-party-country-risk-high-debit-1-day',
  'volume-counter-party-country-risk-high-debit-2-days',
  'volume-counter-party-country-risk-high-debit-3-days',
  'volume-counter-party-country-risk-high-debit-4-days',
  'volume-counter-party-country-risk-high-debit-5-days',
  'volume-counter-party-country-risk-high-debit-6-days',
  'volume-counter-party-country-risk-high-debit-1-week',
  'volume-counter-party-country-risk-high-debit-2-weeks',
  'volume-counter-party-country-risk-high-debit-3-weeks',
  'volume-counter-party-country-risk-high-debit-1-month',
  'volume-counter-party-country-risk-high-debit-2-months',
  'volume-counter-party-country-risk-high-debit-3-months',
];

VolumeRiskFeatureNames.COUNTER_PARTY_CUSTOMER_BASED = [
  'volume-customer-id-counter-party-country-risk-high-credit-1-day',
  'volume-customer-id-counter-party-country-risk-low-credit-2-days',
  'volume-customer-id-counter-party-country-risk-low-credit-3-days',
  'volume-customer-id-counter-party-country-risk-low-credit-4-days',
  'volume-customer-id-counter-party-country-risk-low-credit-5-days',
  'volume-customer-id-counter-party-country-risk-low-credit-6-days',
  'volume-customer-id-counter-party-country-risk-low-credit-1-week',
  'volume-customer-id-counter-party-country-risk-low-credit-2-weeks',
  'volume-customer-id-counter-party-country-risk-low-credit-3-weeks',
  'volume-customer-id-counter-party-country-risk-low-credit-1-month',
  'volume-customer-id-counter-party-country-risk-low-credit-2-months',
  'volume-customer-id-counter-party-country-risk-low-credit-3-months',
  'volume-customer-id-counter-party-country-risk-low-debit-1-day',
  'volume-customer-id-counter-party-country-risk-low-debit-2-days',
  'volume-customer-id-counter-party-country-risk-low-debit-3-days',
  'volume-customer-id-counter-party-country-risk-low-debit-4-days',
  'volume-customer-id-counter-party-country-risk-low-debit-5-days',
  'volume-customer-id-counter-party-country-risk-low-debit-6-days',
  'volume-customer-id-counter-party-country-risk-low-debit-1-week',
  'volume-customer-id-counter-party-country-risk-low-debit-2-weeks',
  'volume-customer-id-counter-party-country-risk-low-debit-3-weeks',
  'volume-customer-id-counter-party-country-risk-low-debit-1-month',
  'volume-customer-id-counter-party-country-risk-low-debit-2-months',
  'volume-customer-id-counter-party-country-risk-low-debit-3-months',

  'volume-customer-id-counter-party-country-risk-medium-credit-1-day',
  'volume-customer-id-counter-party-country-risk-medium-credit-2-days',
  'volume-customer-id-counter-party-country-risk-medium-credit-3-days',
  'volume-customer-id-counter-party-country-risk-medium-credit-4-days',
  'volume-customer-id-counter-party-country-risk-medium-credit-5-days',
  'volume-customer-id-counter-party-country-risk-medium-credit-6-days',
  'volume-customer-id-counter-party-country-risk-medium-credit-1-week',
  'volume-customer-id-counter-party-country-risk-medium-credit-2-weeks',
  'volume-customer-id-counter-party-country-risk-medium-credit-3-weeks',
  'volume-customer-id-counter-party-country-risk-medium-credit-1-month',
  'volume-customer-id-counter-party-country-risk-medium-credit-2-months',
  'volume-customer-id-counter-party-country-risk-medium-credit-3-months',
  'volume-customer-id-counter-party-country-risk-medium-debit-1-day',
  'volume-customer-id-counter-party-country-risk-medium-debit-2-days',
  'volume-customer-id-counter-party-country-risk-medium-debit-3-days',
  'volume-customer-id-counter-party-country-risk-medium-debit-4-days',
  'volume-customer-id-counter-party-country-risk-medium-debit-5-days',
  'volume-customer-id-counter-party-country-risk-medium-debit-6-days',
  'volume-customer-id-counter-party-country-risk-medium-debit-1-week',
  'volume-customer-id-counter-party-country-risk-medium-debit-2-weeks',
  'volume-customer-id-counter-party-country-risk-medium-debit-3-weeks',
  'volume-customer-id-counter-party-country-risk-medium-debit-1-month',
  'volume-customer-id-counter-party-country-risk-medium-debit-2-months',
  'volume-customer-id-counter-party-country-risk-medium-debit-3-months',

  'volume-customer-id-counter-party-country-risk-high-credit-1-day',
  'volume-customer-id-counter-party-country-risk-high-credit-2-days',
  'volume-customer-id-counter-party-country-risk-high-credit-3-days',
  'volume-customer-id-counter-party-country-risk-high-credit-4-days',
  'volume-customer-id-counter-party-country-risk-high-credit-5-days',
  'volume-customer-id-counter-party-country-risk-high-credit-6-days',
  'volume-customer-id-counter-party-country-risk-high-credit-1-week',
  'volume-customer-id-counter-party-country-risk-high-credit-2-weeks',
  'volume-customer-id-counter-party-country-risk-high-credit-3-weeks',
  'volume-customer-id-counter-party-country-risk-high-credit-1-month',
  'volume-customer-id-counter-party-country-risk-high-credit-2-months',
  'volume-customer-id-counter-party-country-risk-high-credit-3-months',
  'volume-customer-id-counter-party-country-risk-high-debit-1-day',
  'volume-customer-id-counter-party-country-risk-high-debit-2-days',
  'volume-customer-id-counter-party-country-risk-high-debit-3-days',
  'volume-customer-id-counter-party-country-risk-high-debit-4-days',
  'volume-customer-id-counter-party-country-risk-high-debit-5-days',
  'volume-customer-id-counter-party-country-risk-high-debit-6-days',
  'volume-customer-id-counter-party-country-risk-high-debit-1-week',
  'volume-customer-id-counter-party-country-risk-high-debit-2-weeks',
  'volume-customer-id-counter-party-country-risk-high-debit-3-weeks',
  'volume-customer-id-counter-party-country-risk-high-debit-1-month',
  'volume-customer-id-counter-party-country-risk-high-debit-2-months',
  'volume-customer-id-counter-party-country-risk-high-debit-3-months',
];

VolumeRiskFeatureNames.COUNTER_PARTY_CUSTOMER_GROUP_BASED = [
  'volume-customer-group-id-counter-party-country-risk-low-credit-1-day',
  'volume-customer-group-id-counter-party-country-risk-low-credit-2-days',
  'volume-customer-group-id-counter-party-country-risk-low-credit-3-days',
  'volume-customer-group-id-counter-party-country-risk-low-credit-4-days',
  'volume-customer-group-id-counter-party-country-risk-low-credit-5-days',
  'volume-customer-group-id-counter-party-country-risk-low-credit-6-days',
  'volume-customer-group-id-counter-party-country-risk-low-credit-1-week',
  'volume-customer-group-id-counter-party-country-risk-low-credit-2-weeks',
  'volume-customer-group-id-counter-party-country-risk-low-credit-3-weeks',
  'volume-customer-group-id-counter-party-country-risk-low-credit-1-month',
  'volume-customer-group-id-counter-party-country-risk-low-credit-2-months',
  'volume-customer-group-id-counter-party-country-risk-low-credit-3-months',
  'volume-customer-group-id-counter-party-country-risk-low-debit-1-day',
  'volume-customer-group-id-counter-party-country-risk-low-debit-2-days',
  'volume-customer-group-id-counter-party-country-risk-low-debit-3-days',
  'volume-customer-group-id-counter-party-country-risk-low-debit-4-days',
  'volume-customer-group-id-counter-party-country-risk-low-debit-5-days',
  'volume-customer-group-id-counter-party-country-risk-low-debit-6-days',
  'volume-customer-group-id-counter-party-country-risk-low-debit-1-week',
  'volume-customer-group-id-counter-party-country-risk-low-debit-2-weeks',
  'volume-customer-group-id-counter-party-country-risk-low-debit-3-weeks',
  'volume-customer-group-id-counter-party-country-risk-low-debit-1-month',
  'volume-customer-group-id-counter-party-country-risk-low-debit-2-months',
  'volume-customer-group-id-counter-party-country-risk-low-debit-3-months',

  'volume-customer-group-id-counter-party-country-risk-medium-credit-1-day',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-2-days',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-3-days',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-4-days',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-5-days',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-6-days',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-1-week',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-2-weeks',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-3-weeks',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-1-month',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-2-months',
  'volume-customer-group-id-counter-party-country-risk-medium-credit-3-months',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-1-day',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-2-days',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-3-days',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-4-days',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-5-days',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-6-days',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-1-week',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-2-weeks',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-3-weeks',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-1-month',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-2-months',
  'volume-customer-group-id-counter-party-country-risk-medium-debit-3-months',

  'volume-customer-group-id-counter-party-country-risk-high-credit-1-day',
  'volume-customer-group-id-counter-party-country-risk-high-credit-2-days',
  'volume-customer-group-id-counter-party-country-risk-high-credit-3-days',
  'volume-customer-group-id-counter-party-country-risk-high-credit-4-days',
  'volume-customer-group-id-counter-party-country-risk-high-credit-5-days',
  'volume-customer-group-id-counter-party-country-risk-high-credit-6-days',
  'volume-customer-group-id-counter-party-country-risk-high-credit-1-week',
  'volume-customer-group-id-counter-party-country-risk-high-credit-2-weeks',
  'volume-customer-group-id-counter-party-country-risk-high-credit-3-weeks',
  'volume-customer-group-id-counter-party-country-risk-high-credit-1-month',
  'volume-customer-group-id-counter-party-country-risk-high-credit-2-months',
  'volume-customer-group-id-counter-party-country-risk-high-credit-3-months',
  'volume-customer-group-id-counter-party-country-risk-high-debit-1-day',
  'volume-customer-group-id-counter-party-country-risk-high-debit-2-days',
  'volume-customer-group-id-counter-party-country-risk-high-debit-3-days',
  'volume-customer-group-id-counter-party-country-risk-high-debit-4-days',
  'volume-customer-group-id-counter-party-country-risk-high-debit-5-days',
  'volume-customer-group-id-counter-party-country-risk-high-debit-6-days',
  'volume-customer-group-id-counter-party-country-risk-high-debit-1-week',
  'volume-customer-group-id-counter-party-country-risk-high-debit-2-weeks',
  'volume-customer-group-id-counter-party-country-risk-high-debit-3-weeks',
  'volume-customer-group-id-counter-party-country-risk-high-debit-1-month',
  'volume-customer-group-id-counter-party-country-risk-high-debit-2-months',
  'volume-customer-group-id-counter-party-country-risk-high-debit-3-months',
];

export default VolumeRiskFeatureNames;
