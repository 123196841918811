import { put, takeLatest } from 'redux-saga/effects';
import { RISKY_WORDS_V2_ACTIONS } from 'modules/configuration/rule-manager/risky-words-v2/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteRiskyWordsV2ConfigError,
  deleteRiskyWordsV2ConfigSuccess,
  getRiskyWordsV2ConfigError,
  getRiskyWordsV2ConfigSuccess,
  updateRiskyWordsV2ConfigError,
  updateRiskyWordsV2ConfigSuccess,
} from 'modules/configuration/rule-manager/risky-words-v2/middleware/actions/risky-words-v2-config';
import RiskyWordsV2ConfigAPICommunicator from 'services/risky-words-v2-config-api-communicator';
import RiskyWordsV2ConfigManager from 'services/risky-words-v2-config-manager';

const communicator = new RiskyWordsV2ConfigAPICommunicator();
const manager = new RiskyWordsV2ConfigManager(communicator);

function* getRiskyWordsV2ConfigBegin(action) {
  yield put(startProcessing(RISKY_WORDS_V2_ACTIONS.fetch.data));
  try {
    const json = yield manager.getRiskyWordsV2Config(action.payload);
    yield put(getRiskyWordsV2ConfigSuccess(json));
  } catch (error) {
    yield put(getRiskyWordsV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RISKY_WORDS_V2_ACTIONS.fetch.data));
}

export function* getRiskyWordsV2ConfigWatcher() {
  yield takeLatest(RISKY_WORDS_V2_ACTIONS.fetch.data, getRiskyWordsV2ConfigBegin);
}

function* updateRiskyWordsV2ConfigBegin(action) {
  yield put(startProcessing(RISKY_WORDS_V2_ACTIONS.update.data));
  try {
    const json = yield manager.updateRiskyWordsV2Config(action.payload);
    yield put(updateRiskyWordsV2ConfigSuccess(json));
  } catch (error) {
    yield put(updateRiskyWordsV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RISKY_WORDS_V2_ACTIONS.update.data));
}

export function* updateRiskyWordsV2ConfigWatcher() {
  yield takeLatest(RISKY_WORDS_V2_ACTIONS.update.data, updateRiskyWordsV2ConfigBegin);
}

function* deleteRiskyWordsV2ConfigBegin(action) {
  yield put(startProcessing(RISKY_WORDS_V2_ACTIONS.delete.data));
  try {
    const json = yield manager.deleteRiskyWordsV2Config(action.payload);
    yield put(deleteRiskyWordsV2ConfigSuccess(json));
  } catch (error) {
    yield put(deleteRiskyWordsV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RISKY_WORDS_V2_ACTIONS.delete.data));
}

export function* deleteRiskyWordsV2ConfigWatcher() {
  yield takeLatest(RISKY_WORDS_V2_ACTIONS.delete.data, deleteRiskyWordsV2ConfigBegin);
}
