export const DormantAccountConfigActions = {
  GET: 'DORMANT_ACCOUNT_CONFIG_GET',
  GET_SUCCESS: 'DORMANT_ACCOUNT_CONFIG_GET_SUCCESS',
  GET_ERROR: 'DORMANT_ACCOUNT_CONFIG_GET_ERROR',
  UPDATE: 'DORMANT_ACCOUNT_CONFIG_UPDATE',
  UPDATE_SUCCESS: 'DORMANT_ACCOUNT_CONFIG_UPDATE_SUCCESS',
  UPDATE_ERROR: 'DORMANT_ACCOUNT_CONFIG_UPDATE_ERROR',
  DELETE: 'DORMANT_ACCOUNT_CONFIG_DELETE',
  DELETE_SUCCESS: 'DORMANT_ACCOUNT_CONFIG_DELETE_SUCCESS',
  DELETE_ERROR: 'DORMANT_ACCOUNT_CONFIG_DELETE_ERROR',
};
