const VolumeRiskConfigTypes = {
  TRANSFER_BASED: 'TRANSFER_BASED',
  ACCOUNT_BASED: 'ACCOUNT_BASED',
  CUSTOMER_BASED: 'CUSTOMER_BASED',
  CUSTOMER_GROUP_BASED: 'CUSTOMER_GROUP_BASED',

  COUNTER_PARTY_TRANSFER_BASED: 'COUNTER_PARTY_TRANSFER_BASED',
  COUNTER_PARTY_ACCOUNT_BASED: 'COUNTER_PARTY_ACCOUNT_BASED',
  COUNTER_PARTY_CUSTOMER_BASED: 'COUNTER_PARTY_CUSTOMER_BASED',
  COUNTER_PARTY_CUSTOMER_GROUP_BASED: 'COUNTER_PARTY_CUSTOMER_GROUP_BASED',
};

export default VolumeRiskConfigTypes;
