import { Grid, Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import {
  getApiReferenceMappings,
  getMetaDataKeys,
} from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTenantDefaultCurrency } from 'utils/helpers/config-utils/config';
import { formatMoney } from 'utils/helpers/math-util';
import { CrrCustomerTypes } from 'utils/constants/crr.constants';

const TxnVolumeAndCountDetails = ({ maxPoints, scoreData, popoverMode, title, isIndividual }) => {
  const tenantConfig = useSelector((state) => state?.tenantConfig);
  const { t } = useTranslation();
  const metaDataKeys = getMetaDataKeys();

  const originalData = scoreData?.[metaDataKeys.originalData];
  const riskFactorInstance = scoreData?.[metaDataKeys.riskFactorInstance];
  const calculatedTimeWindow = parseInt(riskFactorInstance?.settings?.timeWindow || '0', 10);

  const txnVolumeAndCountMapping = t('mapping:customerRiskRating.scoreGroups.TXN_VOLUME_AND_COUNT');
  const apiReferenceMappings = getApiReferenceMappings(
    txnVolumeAndCountMapping,
    isIndividual ? CrrCustomerTypes.individual : CrrCustomerTypes.organization,
    riskFactorInstance,
  );

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData?.[metaDataKeys.calculatedPoints] ?? 0}
          maxPoints={maxPoints}
          headline={title}
          subHead={
            originalData &&
            `${apiReferenceMappings?.value?.heading}: ${originalData?.filterByValue}`
          }
        />
      }
      highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
      detailsComponent={
        originalData && (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                variant="body2"
                color="textSecondary"
                data-testid={`case-status-${riskFactorInstance?.uniqueId}-original-data-container`}
              >
                {t('case:userPopover.riskRating.txnVolumeAndCount.numberOfTransactions.heading', {
                  count: originalData?.numberOfTransactions,
                })}{' '}
                <Typography component="span" variant="caption">
                  {getTenantDefaultCurrency(tenantConfig)}{' '}
                </Typography>
                {formatMoney(originalData?.volumeOfTransactions)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="textSecondary"
                data-testid={`case-status-${riskFactorInstance?.uniqueId}-time-window-container`}
              >
                {t(`case:userPopover.riskRating.txnVolumeAndCount.months.heading`, {
                  months: calculatedTimeWindow,
                })}
              </Typography>
            </Grid>
          </Grid>
        )
      }
    />
  );
};

export { TxnVolumeAndCountDetails };
