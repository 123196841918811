class TrxCaseDetailGroupingManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Case API Communicator instance is required.');
    }
  }

  postStopGrouping(caseId) {
    return this.communicator.postStopGrouping(caseId);
  }
}

export default TrxCaseDetailGroupingManager;
