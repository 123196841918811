import SessionService from 'modules/authentication/login/services/session-service';
import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

const auth = new SessionService();

const getCaseTenantId = () =>
  TenantManagementService.getTenantIdOfCurrentCase() || TenantManagementService.getActiveTenantId();

class ManualRiskLevelChangeCCApi extends APICommunicator {
  requestManualRiskLevelChange({
    sourceCaseId,
    targetRiskLevel,
    customerIdToken,
    durationType,
    validUntilMonths,
  }) {
    const apiUrl = API_URLS.customerService.requestManualRiskLevelChange.customer.replaceAll(
      API_PARAMS.customerIdToken,
      customerIdToken,
    );
    const tenantId = getCaseTenantId();
    const userId = auth.getUserId();

    const body = {
      sourceCaseId,
      tenantId,
      userId,
      targetRiskLevel,
      durationType,
      validUntilMonths,
    };

    return this.postJSONForCustomTenant(
      apiUrl,
      body,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default ManualRiskLevelChangeCCApi;
