import i18next from 'i18next';
import { Filter } from 'components/filters/models/filter';

const NO_ROLES_KEY = 'no-roles';

const buildRoleValuesFromUrl = (filter: Filter) => ({
  condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
  field: i18next.t('mapping:filters.filtering.roles.key'),
  values: filter?.values,
});

const noRoles = {
  id: NO_ROLES_KEY,
  name: i18next.t('translation:noRoles.heading'),
};

export default buildRoleValuesFromUrl;

export { noRoles };
