import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import rootReducer from 'middleware/reducers';
import rootSaga from 'middleware/sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// for security reasons, enable redux logger only on dev/test environments
const hostname = window.location.href.split('://')[1].split('/')[0].split(':')[0];
const reduxLoggerEnabled =
  hostname === 'localhost' ||
  hostname.startsWith('dev.') ||
  hostname.startsWith('test.') ||
  hostname.includes('-dev.') ||
  hostname.includes('.qa.');
if (false) {
  const logger = createLogger({
    predicate: (getState: any, action: any) => !action.type.includes('_PROCESSING'),
  });
  middlewares.push(logger as SagaMiddleware);
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
