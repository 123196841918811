import { useSelector } from 'react-redux';
import { getSettingsFromState } from 'utils/helpers/config-utils/config-util';
import { FEATURE_STATUS } from 'utils/constants/constant';
import { useTenantConfig } from 'hooks/use-tenant-config';

export const useCategoryWorkflowConfig = () => {
  const { tenantConfig } = useTenantConfig();
  const categoryWorkflowConfig = useSelector((state) => {
    const settings = getSettingsFromState(
      state,
      tenantConfig?.tenantId,
      FEATURE_STATUS.config.active,
    );
    return settings?.categoryWorkflowConfig;
  });

  return { categoryWorkflowConfig };
};
