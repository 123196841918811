import { useState } from 'react';
import { Translation } from 'react-i18next';
import { Box, Chip, Divider, FormHelperText, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import i18next from 'i18next';
import { FlowCell } from 'components/table/table-cell/case-table-cell';

const buildFlowValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
  field: i18next.t('mapping:filters.filtering.flows.key'),
  values: filter?.values,
});

const FlowForm = ({ editForm, onSubmit }) => {
  const initialFValues = {};
  const flows = Object.values(i18next.t('mapping:flow'));
  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`flow[${item}]`] = true;
    });
  }

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newFlows = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newFlows);
    // eslint-disable-next-line no-use-before-define
    validate(newFlows);
  };

  const validate = (newCheckedFlows = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    temp.flow = newCheckedFlows.length ? '' : i18next.t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
        field: i18next.t('mapping:filters.filtering.flows.key'),
        values: checkedValues,
      });
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  return (
    <Translation>
      {(t) => (
        <Grid container item>
          <Form onSubmit={handleSubmit}>
            <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
              {flows.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Controls.Checkbox
                    name={`flow[${item.key}]`}
                    label={
                      <Grid container wrap="nowrap" alignItems="center">
                        <Grid item className="mr-2 mt-n1">
                          <FlowCell flowKey={item.key} customClass="icon-16" />
                        </Grid>
                        <Grid item>{item.value.heading}</Grid>
                      </Grid>
                    }
                    value={values[`flow[${item.key}]`] ?? false}
                    onChange={(event) => handleSelectChange(event, item.key)}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            {errors.flow && (
              <FormHelperText className="pl-3" error>
                {errors.flow}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const FlowChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.flows.value.heading')}
        </Box>
        {form.values.map((item, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            className="mr-1"
            label={t(`mapping:flow.${item}.value.heading`)}
            icon={<FlowCell flowKey={item} customClass="ml-1 mt-n1xs icon-14" />}
          />
        ))}
      </>
    )}
  </Translation>
);

export { FlowForm, FlowChip, buildFlowValuesFromUrl };
