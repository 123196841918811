import React, { useState, useEffect } from 'react';
import { Divider, FormHelperText, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import {
  FilterFormProps,
  FormValues,
  UseFormReturnType,
  KeyValueHeading,
} from 'components/filters/models/filter';
import useAllRoles from 'modules/configuration/workflows-and-users/components/user-management/hooks/use-all-roles';
import { noRoles } from 'components/filters/fields/role/role-filter-util';
import TextSearch from 'components/text-search';
import { SkeletonLoader } from 'components/loaders/skeleton/skeleton';

const RoleForm: React.FC<FilterFormProps> = ({ editForm, onSubmit }) => {
  const { t } = useTranslation();

  const { data: allRoles = [], isLoading } = useAllRoles();

  const [allOptions, setAllOptions] = useState<KeyValueHeading[]>([]);

  useEffect(() => {
    if (allRoles.length === 0) return;

    const newAllOptions = [noRoles, ...allRoles].map((r) => ({
      key: r.id,
      value: {
        heading: r.name,
      },
      label: r.name,
    }));
    setAllOptions(newAllOptions);
  }, [allRoles]);

  const [filteredOptions, setFilteredOptions] = useState<KeyValueHeading[]>([]);

  useEffect(() => {
    setFilteredOptions(allOptions);
  }, [allOptions]);

  const handleRoleOptionsChanged = (value: KeyValueHeading[]) => {
    setFilteredOptions(value);
  };

  const initialFormValues: FormValues = {};

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFormValues[`roles[${item}]`] = true;
    });
  }

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>, checkedValue: string) => {
    let tempCheckedValues = checkedValues;
    const shouldClearForm =
      (checkedValue === noRoles.id && !checkedValues?.includes(noRoles.id)) ||
      (checkedValue !== noRoles.id && checkedValues?.includes(noRoles.id));
    if (shouldClearForm) {
      // eslint-disable-next-line no-use-before-define
      clearForm();
      tempCheckedValues = [];
    }
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newRoles = tempCheckedValues?.includes(checkedValue)
      ? tempCheckedValues?.filter((name) => name !== checkedValue)
      : [...(tempCheckedValues ?? []), checkedValue];
    setCheckedValues(newRoles);
    // eslint-disable-next-line no-use-before-define
    validate(newRoles);
  };

  /**
   * validating field form
   * @param newRoles
   * @returns
   */
  const validate = (newRoles = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const newErrors: Record<string, string | null> = { ...errors };

    newErrors.errorMessage = newRoles.length ? '' : t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...newErrors,
    });

    return Object.values(newErrors).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange, clearForm }: UseFormReturnType = useForm(
    initialFormValues,
    true,
    validate,
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      const isFilterByNoRoles = checkedValues.includes(noRoles.id);
      const condition = isFilterByNoRoles
        ? t('mapping:operator.collection.COLLECTION_EMPTY.key')
        : t('mapping:operator.collection.COLLECTION_ANY_OF.key');
      onSubmit({
        condition,
        field: t('mapping:filters.filtering.roles.key'),
        values: checkedValues,
      });
    }
  };

  return (
    <Grid container item>
      <Grid item xs={12} className="pl-3 pr-3 pt-1 pb-1">
        <TextSearch
          optionsArray={allOptions}
          resultsI18nKey="searchRoles"
          hideClearCross
          onInputChange={handleRoleOptionsChanged}
        />
      </Grid>
      {isLoading && (
        <Grid item xs={12} className="p-3">
          <SkeletonLoader variant="row" elementsPerRow={1} />
        </Grid>
      )}
      {!isLoading && (
        <Grid item xs={12}>
          <Form onSubmit={handleSubmit}>
            <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
              {filteredOptions.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  key={item.key}
                  className={index === 0 && item.key === noRoles.id ? 'border-bottom' : ''}
                >
                  <Controls.Checkbox
                    id={`roles[${item.key}]`}
                    name={`roles[${item.key}]`}
                    fullWidth={false}
                    size="small"
                    label={
                      <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                        <Grid item>{item.value?.heading}</Grid>
                      </Grid>
                    }
                    value={values[`roles[${item.key}]`] ?? false}
                    inputProps={{ 'data-testid': `role-${item.key}` }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleSelectChange(event, item.key)
                    }
                  />
                </Grid>
              ))}
            </Grid>
            {errors.errorMessage && (
              <FormHelperText className="pl-3" error>
                {errors.errorMessage}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button
                type="submit"
                color="primary"
                text={t('filter.heading')}
                dataTestIdPrefix="filter-by-roles"
                onClick={handleSubmit}
              />
            </Grid>
          </Form>
        </Grid>
      )}
    </Grid>
  );
};

export default RoleForm;
