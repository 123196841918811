import Strings from 'utils/helpers/locales/strings.en';
import { formatMoney } from 'utils/helpers/math-util';
import {
  getTimeframeText,
  getTransactionTypeText,
  getRuleActiveText,
} from 'utils/constants/volume-risk-config-options';
import VolumeRiskConfigurationTypes from 'utils/constants/volume-risk-configuration-type';
import VolumeConstants from 'utils/constants/volume-constants';
import AssessmentQuestion from 'utils/constants/assessment-question';
import AssessmentAnswer from 'utils/constants/assessment-answer';
import {
  getRiskColorByAssessment,
  getFirstTransactionFromCase,
  getDisplayMask,
  getCustomerAccountFromTransaction,
  getCounterPartyFromTransaction,
  isExpiredAssessment,
} from 'utils/helpers/cases/case-utility';
import DataFeatureDetails from 'utils/constants/data-feature-details';
import HoverCountryName from 'components/hover-country-name';
import DataFeatureName from 'utils/constants/data-feature-name';
import CountryRisk from 'utils/constants/country-risk';
import VolumeRiskFeatureNames from 'utils/constants/volume-risk-feature-names';
import Party from 'utils/constants/party';
import { getCountryName, getCountryCode, isCountryCodeValid } from 'utils/constants/country-list';

export function buildTrackChangesMessageForConfigurationType(originalList, newList) {
  let auditTrackMessage = '';
  let index = 0;

  if (originalList.length > 0) {
    originalList.forEach((oldItem) => {
      const newItem = newList.find((i) => i.id === oldItem.id);
      index += 1;
      if (newItem !== undefined) {
        if (oldItem.active !== newItem.active) {
          auditTrackMessage += `<br/><span class='ml16'>- Activation status of rule <b>#${index}</b> changed from <b>${getRuleActiveText(
            oldItem.active,
          )}</b> to <b>${getRuleActiveText(newItem.active)}</b></span>`;
        }

        if (oldItem.timeFrame !== newItem.timeFrame) {
          auditTrackMessage += `<br/><span class='ml16'>- Timeframe of rule <b>#${index}</b> changed from <b>${getTimeframeText(
            oldItem.timeFrame,
          )}</b> to <b>${getTimeframeText(newItem.timeFrame)}</b></span>`;
        }

        if (oldItem.transactionType !== newItem.transactionType) {
          auditTrackMessage += `<br/><span class='ml16'>- Transaction type of rule <b>#${index}</b> changed from <b>${getTransactionTypeText(
            oldItem.transactionType,
          )}</b> to <b>${getTransactionTypeText(newItem.transactionType)}</b></span>`;
        }

        if (oldItem.lowRiskThreshold !== newItem.lowRiskThreshold) {
          auditTrackMessage += `<br/><span class='ml16'>- Low risk customer threshold of rule <b>#${index}</b> changed from <b>${oldItem.lowRiskThreshold}</b> to <b>${newItem.lowRiskThreshold}</b></span>`;
        }

        if (oldItem.mediumRiskThreshold !== newItem.mediumRiskThreshold) {
          auditTrackMessage += `<br/><span class='ml16'>- Medium risk customer threshold of rule <b>#${index}</b> changed from <b>${oldItem.mediumRiskThreshold}</b> to <b>${newItem.mediumRiskThreshold}</b></span>`;
        }

        if (oldItem.highRiskThreshold !== newItem.highRiskThreshold) {
          auditTrackMessage += `<br/><span class='ml16'>- High risk customer threshold of rule <b>#${index}</b> changed from <b>${oldItem.highRiskThreshold}</b> to <b>${newItem.highRiskThreshold}</b></span>`;
        }
      } else {
        auditTrackMessage += `<br/><span class='ml16'>- Rule <b>#${index}</b> has been removed.</span>`;
      }
    });
  }

  newList.forEach((newConfig) => {
    if (newConfig.id.length === 0 || newConfig.id.indexOf(VolumeConstants.TEMP_ID_TOKEN) >= 0) {
      auditTrackMessage += "<br/><span class='ml16'>- New rule added with:</span>";
      auditTrackMessage += `<br/><span class='ml32'>. Timeframe: ${getTimeframeText(
        newConfig.timeFrame,
      )}</span>`;
      auditTrackMessage += `<br/><span class='ml32'>. Transaction type: ${getTransactionTypeText(
        newConfig.transactionType,
      )}</span>`;
      auditTrackMessage += `<br/><span class='ml32'>. Low risk customer threshold: ${
        newConfig.currency
      } ${formatMoney(newConfig.lowRiskThreshold)}</span>`;
      auditTrackMessage += `<br/><span class='ml32'>. Medium risk customer threshold: ${
        newConfig.currency
      } ${formatMoney(newConfig.mediumRiskThreshold)}</span>`;
      auditTrackMessage += `<br/><span class='ml32'>. High risk customer threshold: ${
        newConfig.currency
      } ${formatMoney(newConfig.highRiskThreshold)}</span>`;
    }
  });

  return auditTrackMessage;
}

export function findRule(list, id) {
  return list.find((r) => r.id === id);
}

export function buildVolumeRuleGroups(rules) {
  const transferBasedRules = rules.filter(
    (r) => r.configurationType === VolumeRiskConfigurationTypes.TRANSFER_BASED,
  );
  const accountBasedRules = rules.filter(
    (r) => r.configurationType === VolumeRiskConfigurationTypes.ACCOUNT_BASED,
  );
  const customerBasedRules = rules.filter(
    (r) => r.configurationType === VolumeRiskConfigurationTypes.CUSTOMER_BASED,
  );
  const customerGroupBasedRules = rules.filter(
    (r) => r.configurationType === VolumeRiskConfigurationTypes.CUSTOMER_GROUP_BASED,
  );

  return [
    {
      title: Strings.ConfigurationManagementLabels.TRANSFER_BASED,
      items: transferBasedRules,
    },
    {
      title: Strings.ConfigurationManagementLabels.ACCOUNT_BASED,
      items: accountBasedRules,
    },
    {
      title: Strings.ConfigurationManagementLabels.CUSTOMER_BASED,
      items: customerBasedRules,
    },
    {
      title: Strings.ConfigurationManagementLabels.CUSTOMER_GROUP_BASED,
      items: customerGroupBasedRules,
    },
  ];
}

export function buidAuditTrackMessageForItem(index, config, className) {
  let message = `<br/><span class='ml16'>- Rule #${index}: ${getRuleActiveText(config.active)}`;
  message += `<br/><span class='${className}'>. Timeframe: ${getTimeframeText(
    config.timeFrame,
  )}</span>`;
  message += `<br/><span class='${className}'>. Transaction type: ${getTransactionTypeText(
    config.transactionType,
  )}</span>`;
  message += `<br/><span class='${className}'>. Low risk customer threshold: ${
    config.currency
  } ${formatMoney(config.lowRiskThreshold)}</span>`;
  message += `<br/><span class='${className}'>. Medium risk customer threshold: ${
    config.currency
  } ${formatMoney(config.mediumRiskThreshold)}</span>`;
  message += `<br/><span class='${className}'>. High risk customer threshold: ${
    config.currency
  } ${formatMoney(config.highRiskThreshold)}</span><br/>`;

  return message;
}

export function validateRule(rule) {
  if (rule.timeFrame === undefined) {
    return 1;
  }
  if (rule.transactionType === undefined) {
    return 2;
  }
  if (rule.highRiskThreshold <= 0 || rule.mediumRiskThreshold <= 0 || rule.lowRiskThreshold <= 0) {
    return 3;
  }

  return 0;
}

export function getCurrentHighRiskRules(counterPartyVolumeRisk) {
  const highRiskConfig = counterPartyVolumeRisk?.highRisk || {};
  return highRiskConfig.rules || [];
}

export function getCurrentMediumRiskRules(counterPartyVolumeRisk) {
  const mediumRiskConfig = counterPartyVolumeRisk?.mediumRisk || {};
  return mediumRiskConfig.rules || [];
}

export function getCurrentLowRiskRules(counterPartyVolumeRisk) {
  const lowRiskConfig = counterPartyVolumeRisk?.lowRisk || {};
  return lowRiskConfig.rules || [];
}

/* Samples:
     volume-account-holding-party-debit-1-month
  -> volume-risk-acceptable-for-account-debit-1-month

     volume-transfer-credit-1-month
  -> volume-risk-acceptable-for-transfer-credit-1-month

     volume-customer-id-debit-1-month
  -> volume-risk-acceptable-for-customer-debit-1-month

     volume-customer-group-id-credit-1-day
  -> volume-risk-acceptable-for-customer-group-credit-1-day
*/
const VolumeHitKey2RuleNameMap = [
  {
    keyPart: 'volume-account-holding-party',
    replacement: 'volume-risk-acceptable-for-account',
  },
  {
    keyPart: 'volume-transfer',
    replacement: 'volume-risk-acceptable-for-transfer',
  },
  {
    keyPart: 'volume-customer-id',
    replacement: 'volume-risk-acceptable-for-customer',
  },
  {
    keyPart: 'volume-customer-group-id',
    replacement: 'volume-risk-acceptable-for-customer-group',
  },
];

export function convertVolumeHitKey2RuleName(key) {
  if (!key) {
    return '';
  }
  const map = VolumeHitKey2RuleNameMap.find((m) => key.indexOf(m.keyPart) >= 0);
  return !map ? '' : key.replace(map.keyPart, map.replacement);
}

export function buildVolumeRiskAssessmentKey(questionId, ruleName) {
  if (!questionId || !ruleName) {
    return undefined;
  }
  return `${questionId}_${ruleName}`;
}

export function getAssessmentQuestionByVolumeRiskConfigurationType(type) {
  return AssessmentQuestion[type];
}

export function getAllVolumeRiskAssessmentsByAssessmentQuestion(volumeRisk, questionId) {
  if (!volumeRisk || !questionId) {
    return [];
  }
  const result = [];
  const assessmentEvents = volumeRisk.assessmentEvents || {};
  Object.keys(assessmentEvents).forEach((key) => {
    if (key.indexOf(questionId) === 0 && assessmentEvents[key].assessment) {
      result.push(assessmentEvents[key].assessment);
    }
  });
  return result;
}

export function getVolumeRiskAssessmentByAssessmentQuestion(volumeRisk, questionId, ruleName) {
  if (!volumeRisk) {
    return {};
  }
  const key = buildVolumeRiskAssessmentKey(questionId, ruleName);
  if (!key) {
    return {};
  }
  const assessmentEvents = volumeRisk.assessmentEvents || {};
  const volumeBaseAcceptableForParties = assessmentEvents[key] || {};
  return volumeBaseAcceptableForParties.assessment || {};
}

export function isOKAnswer(answer) {
  return AssessmentAnswer.OK === answer;
}

export function isNOKAnswer(answer) {
  return AssessmentAnswer.NOK === answer;
}

export function getTabColorByAnswers(answers) {
  let combinedAnswer = AssessmentAnswer.INCONCLUSIVE;
  if (Array.isArray(answers) && answers.length > 0) {
    let okCount = 0;
    let nokCount = 0;
    answers.forEach((answer) => {
      if (isOKAnswer(answer)) {
        okCount += 1;
      } else if (isNOKAnswer(answer)) {
        nokCount += 1;
      }
    });

    if (nokCount > 0) {
      combinedAnswer = AssessmentAnswer.NOK;
    } else if (okCount === answers.length) {
      combinedAnswer = AssessmentAnswer.OK;
    }
  }
  return getRiskColorByAssessment(combinedAnswer);
}

export function containsExpiredAssessment(assessments) {
  const expiredAssessments = assessments.filter((assessment) => isExpiredAssessment(assessment));
  return expiredAssessments.length > 0;
}

export function containsFeatureOfExpiration(dataFeatures, volumeRiskConfigurationType) {
  let expiredFeatureNameSuffix = '';

  if (volumeRiskConfigurationType === 'ACCOUNT_BASED') {
    expiredFeatureNameSuffix = 'volume-risk-assessment-expired-for-account-';
  } else if (volumeRiskConfigurationType === 'TRANSFER_BASED') {
    expiredFeatureNameSuffix = 'volume-risk-assessment-expired-for-transfer-';
  } else if (volumeRiskConfigurationType === 'CUSTOMER_BASED') {
    expiredFeatureNameSuffix = 'volume-risk-assessment-expired-for-customer-';
  } else if (volumeRiskConfigurationType === 'CUSTOMER_GROUP_BASED') {
    expiredFeatureNameSuffix = 'volume-risk-assessment-expired-for-customer-group-';
  } else {
    return false;
  }

  const expiredFeatures = Object.keys(dataFeatures).filter((key) =>
    key.startsWith(expiredFeatureNameSuffix),
  );
  return expiredFeatures.length > 0;
}

export function getVolumeRiskColor(caseDetail, volumeRiskConfigurationType) {
  if (!caseDetail || !volumeRiskConfigurationType) {
    return getRiskColorByAssessment('ok');
  }
  const volumeRisk = caseDetail.volumeRisk || {};
  const questionId = getAssessmentQuestionByVolumeRiskConfigurationType(
    volumeRiskConfigurationType,
  );
  const assessments = getAllVolumeRiskAssessmentsByAssessmentQuestion(volumeRisk, questionId);
  const answers = assessments.map((assessment) => assessment.answer);
  const decisionTakenEvent = caseDetail.decisionTakenEvent || {};
  const dataFeatures = decisionTakenEvent.dataFeatures || {};

  if (
    containsExpiredAssessment(assessments) &&
    containsFeatureOfExpiration(dataFeatures, volumeRiskConfigurationType)
  ) {
    answers.push('');
  }
  return getTabColorByAnswers(answers);
}

const DEFAULT_THRESHOLD = '';

export function getVolumeRuleThreshold(
  decisionEngineConfig,
  configurationType,
  dataFeatureName,
  riskLevel = 'low',
) {
  if (
    !decisionEngineConfig ||
    !decisionEngineConfig.volumeRisk ||
    !decisionEngineConfig.volumeRisk.rules
  ) {
    return DEFAULT_THRESHOLD;
  }
  if (!configurationType || !dataFeatureName || !riskLevel) {
    return DEFAULT_THRESHOLD;
  }
  const { volumeRisk } = decisionEngineConfig;
  const { rules } = volumeRisk;

  const rule = rules.find(
    (r) => r.configurationType === configurationType && r.dataFeatureName === dataFeatureName,
  );
  return rule ? rule[`${riskLevel}RiskThreshold`] : DEFAULT_THRESHOLD;
}

// TODO: remove function, likely deprecated
export function getRuleInfo(contributions, configurationType) {
  let ruleInfo = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in contributions) {
    if (key.indexOf(configurationType) >= 0 && key.indexOf('-tx-count') < 0) {
      ruleInfo = DataFeatureDetails[key];
      if (ruleInfo) {
        const txCountRuleName = `${key}-tx-count`;
        ruleInfo.data = contributions[key];
        ruleInfo.txCount = contributions[txCountRuleName];
        break;
      }
    }
  }

  return ruleInfo;
}

export function getConfigByRule(counterPartyVolumeRisk, ruleName, counterPartyCountryRiskLevel) {
  let rules = [];
  if (counterPartyCountryRiskLevel === CountryRisk.HIGH) {
    const highRiskConfig = counterPartyVolumeRisk.highRisk || {};
    rules = highRiskConfig.rules || [];
  } else if (counterPartyCountryRiskLevel === CountryRisk.MEDIUM) {
    const mediumRiskConfig = counterPartyVolumeRisk.mediumRisk || {};
    rules = mediumRiskConfig.rules || [];
  } else {
    const lowRiskConfig = counterPartyVolumeRisk.lowRisk || {};
    rules = lowRiskConfig.rules || [];
  }

  return rules.find((r) => r.dataFeatureName === ruleName) || {};
}

export function getMetadataInfo(dataFeatures, ruleName) {
  const dataFeature = dataFeatures[ruleName];
  if (dataFeature) {
    return dataFeature.metadata;
  }

  return null;
}

export function isCountryRiskCaseWithVolumeChecks(contributions) {
  return (
    !!contributions &&
    contributions[DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL] !== undefined
  );
}

export const getHoverNameCountryList = (countries) => {
  const hoverCountries = [];

  countries.forEach((country, index) => {
    if (country.length > 0) {
      hoverCountries.push(<HoverCountryName key={index} id={country} code={country} />);
      hoverCountries.push(',');
    }
  });

  if (hoverCountries.length > 0) {
    hoverCountries.pop();
  }

  if (hoverCountries.length >= 3) {
    hoverCountries[hoverCountries.length - 2] = 'and';
  }

  return hoverCountries;
};

export function getTransactCountryList(dataFeatureMetadata, ruleComputedMetadata) {
  if (ruleComputedMetadata) {
    return ruleComputedMetadata.transactCountries || [];
  }
  const countryString = dataFeatureMetadata.transactCountries || '';
  return countryString.split(',');
}

export function getMetadataForSelectedRule(metadataComputedEvent, selectedRule, configurationType) {
  if (!selectedRule) {
    return undefined;
  }

  const metadatas = metadataComputedEvent.metadatas || {};

  let configurationTypeMetadata;
  if (selectedRule.indexOf('credit') >= 0) {
    configurationTypeMetadata = metadatas.find((m) => m.key === `${configurationType}_CREDIT`);
  } else {
    configurationTypeMetadata = metadatas.find((m) => m.key === `${configurationType}_DEBIT`);
  }

  if (configurationTypeMetadata) {
    return configurationTypeMetadata.values[selectedRule];
  }

  return undefined;
}

export function getAccountHoldingPartyRiskLevel(contributions) {
  const accountHoldingPartyRiskLevelFeature =
    contributions[DataFeatureName.ACCOUNT_HOLDING_PARTY_BANK_RISK_LEVEL];
  if (accountHoldingPartyRiskLevelFeature) {
    return accountHoldingPartyRiskLevelFeature.importance.classification.toUpperCase();
  }

  return CountryRisk.LOW;
}

export function getCounterPartyCountryRiskLevel(contributions) {
  const counterPartyCountryRiskLevelFeature =
    contributions?.[DataFeatureName.COUNTER_PARTY_COUNTRY_RISK_LEVEL];
  if (counterPartyCountryRiskLevelFeature) {
    return counterPartyCountryRiskLevelFeature.importance.classification.toUpperCase();
  }

  return CountryRisk.LOW;
}

function findVolumeRiskDataFeatures(contributions, featureList) {
  const hits = [];
  Object.keys(contributions).forEach((key) => {
    const featureName = featureList.find((n) => n === key);
    if (featureName) {
      hits.push(contributions[key]);
    }
  });

  return hits;
}

export function getCounterPartyVolumeRiskHits(contributions, configurationType) {
  if (configurationType === VolumeRiskConfigurationTypes.COUNTER_PARTY_TRANSFER_BASED) {
    return findVolumeRiskDataFeatures(
      contributions,
      VolumeRiskFeatureNames.COUNTER_PARTY_TRANSFER_BASED,
    );
  }
  if (configurationType === VolumeRiskConfigurationTypes.COUNTER_PARTY_ACCOUNT_BASED) {
    return findVolumeRiskDataFeatures(
      contributions,
      VolumeRiskFeatureNames.COUNTER_PARTY_ACCOUNT_BASED,
    );
  }
  if (configurationType === VolumeRiskConfigurationTypes.COUNTER_PARTY_CUSTOMER_BASED) {
    return findVolumeRiskDataFeatures(
      contributions,
      VolumeRiskFeatureNames.COUNTER_PARTY_CUSTOMER_BASED,
    );
  }
  if (configurationType === VolumeRiskConfigurationTypes.COUNTER_PARTY_CUSTOMER_GROUP_BASED) {
    return findVolumeRiskDataFeatures(
      contributions,
      VolumeRiskFeatureNames.COUNTER_PARTY_CUSTOMER_GROUP_BASED,
    );
  }

  return [];
}

export function getCountryRiskLevel(country, countryRiskConfig) {
  const lowRiskList = countryRiskConfig.lowRiskList || [];
  const highRiskList = countryRiskConfig.highRiskList || [];
  let riskLevel = CountryRisk.MEDIUM;

  let countryCode = country;
  if (!isCountryCodeValid(countryCode)) {
    countryCode = getCountryCode(country);
  }

  if (lowRiskList.indexOf(countryCode) >= 0) {
    riskLevel = CountryRisk.LOW;
  } else if (highRiskList.indexOf(countryCode) >= 0) {
    riskLevel = CountryRisk.HIGH;
  }

  return riskLevel;
}

export function correctCountryRiskIfPossible(countryRisk, transaction, countryRiskConfig) {
  if (JSON.stringify(countryRiskConfig) === '{}' || JSON.stringify(countryRisk) === '{}') {
    return countryRisk;
  }

  const originatorIbanCountry = countryRisk.originatorIbanCountry || '';
  if (originatorIbanCountry.length === 0) {
    const fundsOriginator = transaction.fundsOriginator || {};
    const bankCountry = fundsOriginator.bankCountry || '';
    if (bankCountry.length > 0) {
      countryRisk.originatorIbanCountry = bankCountry;
      countryRisk.originatorIbanCountryRisk = getCountryRiskLevel(bankCountry, countryRiskConfig);
    }
  }

  const beneficiaryIbanCountry = countryRisk.beneficiaryIbanCountry || '';
  if (beneficiaryIbanCountry.length === 0) {
    const fundsBeneficiary = transaction.fundsBeneficiary || {};
    const bankCountry = fundsBeneficiary.bankCountry || '';
    if (bankCountry.length > 0) {
      countryRisk.beneficiaryIbanCountry = bankCountry;
      countryRisk.beneficiaryIbanCountryRisk = getCountryRiskLevel(bankCountry, countryRiskConfig);
    }
  }

  return countryRisk;
}

export function getCountryV2RiskLevel(transaction, countryRiskConfig) {
  const masterData = transaction.masterData || {};
  const counterpartyData = masterData.counterpartyData || {};
  const contactDetails = counterpartyData.contactDetails || {};
  const countryV2 = contactDetails.countryV2 || '';
  const riskLevel = getCountryRiskLevel(countryV2, countryRiskConfig);

  if (countryV2.length > 0) {
    return {
      country: countryV2,
      riskLevel,
    };
  }

  return null;
}

export function getHighestRiskLevel(countryRisk = {}, accountHoldingParty) {
  if (countryRisk.originatorIbanCountryRisk === CountryRisk.HIGH) {
    return {
      country: countryRisk.originatorIbanCountry,
      riskLevel: CountryRisk.HIGH,
    };
  }
  if (countryRisk.beneficiaryIbanCountryRisk === CountryRisk.HIGH) {
    return {
      country: countryRisk.beneficiaryIbanCountry,
      riskLevel: CountryRisk.HIGH,
    };
  }
  if (
    countryRisk.originatorIbanCountryRisk === CountryRisk.MEDIUM &&
    countryRisk.beneficiaryIbanCountryRisk !== CountryRisk.MEDIUM
  ) {
    return {
      country: countryRisk.originatorIbanCountry,
      riskLevel: CountryRisk.MEDIUM,
    };
  }
  if (
    countryRisk.beneficiaryIbanCountryRisk === CountryRisk.MEDIUM &&
    countryRisk.originatorIbanCountryRisk !== CountryRisk.MEDIUM
  ) {
    return {
      country: countryRisk.beneficiaryIbanCountry,
      riskLevel: CountryRisk.MEDIUM,
    };
  }

  if (accountHoldingParty === Party.FUNDS_ORIGINATOR) {
    return {
      country: countryRisk.originatorIbanCountry,
      riskLevel: CountryRisk.LOW,
    };
  }
  return {
    country: countryRisk.beneficiaryIbanCountry,
    riskLevel: CountryRisk.LOW,
  };
}

export function getHighestCountryBetween(countryV2, bankCountry) {
  if (countryV2 === null && bankCountry) {
    return bankCountry;
  }

  const countryV2RiskLevel = countryV2.riskLevel;
  const bankCountryRiskLevel = bankCountry.riskLevel;

  if (countryV2.country.length === 0) {
    return bankCountry;
  }

  if (countryV2RiskLevel === CountryRisk.HIGH) {
    return countryV2;
  }
  if (bankCountryRiskLevel === CountryRisk.HIGH) {
    return bankCountry;
  }
  if (bankCountryRiskLevel === CountryRisk.MEDIUM && countryV2RiskLevel !== CountryRisk.MEDIUM) {
    return bankCountry;
  }
  if (bankCountryRiskLevel !== CountryRisk.MEDIUM && countryV2RiskLevel === CountryRisk.MEDIUM) {
    return countryV2;
  }

  return bankCountry;
}

export function markDeletedItem(list, ruleId) {
  const rule = list.find((r) => r.id === ruleId);
  if (rule) {
    rule.deleted = true;
  }

  return list;
}

export function isConfigurationTypeExist(contributions, expectedType) {
  return Object.keys(contributions).filter((key) => key.indexOf(expectedType) >= 0).length > 0;
}

export function getTransactionHistoryList(
  caseDetail,
  transactionHistory,
  historyType,
  caseIds,
  rule,
) {
  const transactions = transactionHistory[historyType] || [];

  const txs = [];
  const selectedTx = getFirstTransactionFromCase(caseDetail);
  const selectedTxprocessedAt = selectedTx.processedAt || '';
  const selectedTxprocessedAtDate = new Date(selectedTxprocessedAt);

  transactions.forEach((tx) => {
    const transactionData = getFirstTransactionFromCase(tx);
    const txprocessedAt = transactionData.processedAt || '';
    const txprocessedAtDate = new Date(txprocessedAt);

    if (selectedTxprocessedAtDate.getTime() >= txprocessedAtDate.getTime()) {
      if (rule.timeframe !== Strings.VolumeRules.CURRENT_DAY) {
        txs.push(tx);
      } else if (caseIds.indexOf(tx.uuid) >= 0) {
        txs.push(tx);
      }
    }
  });

  return txs;
}

export function getCustomerAccounts(allMasks, transactions) {
  const accounts = [];
  if (transactions) {
    transactions.forEach((item) => {
      const transaction = getFirstTransactionFromCase(item);
      const accountNumber = getCustomerAccountFromTransaction(transaction);
      const accountNumberText = getDisplayMask(allMasks, accountNumber);
      if (accounts.indexOf(accountNumberText) < 0) {
        accounts.push(accountNumberText);
      }
    });
  }

  return accounts.join(', ');
}

export function getConfiguredThreshold(configuredRule, riskLevel) {
  if (riskLevel === CountryRisk.HIGH) {
    return configuredRule.highRiskThreshold;
  }
  if (riskLevel === CountryRisk.MEDIUM) {
    return configuredRule.mediumRiskThreshold;
  }
  return configuredRule.lowRiskThreshold;
}

export function getHighestCountryRiskInfo(transaction, countryRisk, countryRiskConfig) {
  const countryV2 = getCountryV2RiskLevel(transaction, countryRiskConfig);
  const bankCountryHighestRisk = getHighestRiskLevel(countryRisk);
  return getHighestCountryBetween(countryV2, bankCountryHighestRisk);
}

export function getTransactCountries(countryCodes) {
  const countries = [];

  for (let i = 0; i < countryCodes.length; i++) {
    if (countryCodes[i].length > 0) {
      countries.push(getCountryName(countryCodes[i]));
    }
  }

  return countries.join(', ');
}

export function getCounterPartyCountry(transaction, countryRiskConfig) {
  const counterParty = getCounterPartyFromTransaction(transaction);
  const bankCountry = counterParty.bankCountry || '';
  if (bankCountry.length > 0) {
    counterParty.riskLevel = getCountryRiskLevel(bankCountry, countryRiskConfig);
    counterParty.country = bankCountry;
  }

  const countryV2 = getCountryV2RiskLevel(transaction, countryRiskConfig);
  const highestCountry = getHighestCountryBetween(countryV2, counterParty);
  return getCountryName(highestCountry.country) || '';
}

/**
 *
 * Extract selected rule configuration from MetadataComputedEvent for Country Volume Rules
 * to match case when there is more than one rule for same timeframe and type
 * (e.g. 1 week credit -> two rule: one with organization type filter and one without)
 */
export function getSelectedCountryVolumeRuleConfigFromMetadata(
  caseDetail,
  configurationType,
  selectedRule,
) {
  return caseDetail?.metadataComputedEvent?.metadatas?.find((m) =>
    m.key.startsWith(configurationType),
  )?.values[selectedRule?.key]?.config;
}

/**
 *
 * Extract selected rule configuration from MetadataComputedEvent for Volume Risk Rules
 * to match case when there is more than one rule for same timeframe and type
 * (e.g. 1 week credit -> two rule: one with organization type filter and one without)
 */
export function getSelectedVolumeRiskRuleConfigFromMetadata(caseDetail, selectedRule) {
  return caseDetail?.metadataComputedEvent?.metadatas?.find((m) => m.key === selectedRule?.key)
    ?.values?.config;
}

/**
 *
 * Extract Organization Type from Common Config Criteria
 * If multiple values, join  coma separated
 */
export function getOrganizationTypeFilterValue(ruleConfig) {
  return ruleConfig?.commonConfigCriteria?.organizationType?.join(', ');
}
