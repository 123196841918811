import querystring from 'querystring';
import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';
import { Objects } from 'utils/lang/objects.util';

export const getCaseTenantId = () => {
  let caseTenantId = TenantManagementService.getTenantIdOfCurrentCase();
  caseTenantId = caseTenantId || TenantManagementService.getActiveTenantId();
  return caseTenantId;
};

class CaseAPICommunicator extends APICommunicator {
  fetchCasesByForwardingToCaseId(forwardingToCaseId, caseTenantId, page, pageSize) {
    const baseUrl = API_URLS.caseService.groupCase.queryMembers.replace(
      API_PARAMS.forwardingToCaseId,
      forwardingToCaseId,
    );
    const apiUrl = `${baseUrl}?${querystring.stringify({ page, pageSize })}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
      caseTenantId,
    });
  }

  updateCaseStatus(caseId, caseStatus, transactionStatus, processingStatus, note, createdAt) {
    const apiUrl = API_URLS.caseService.updateCaseStatus;
    const caseTenantId = getCaseTenantId();
    const payload = {
      caseId,
      caseStatus,
      transactionStatus,
      processingStatus,
      note,
    };
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      {
        createdAt,
      },
    );
  }

  fetchAuditTracks(
    size,
    page,
    sortParams,
    filterColumn,
    filterValue,
    filterOperator,
    filterStartDate,
    filterEndDate,
    excludeSystemUser,
  ) {
    const params = {
      size,
      page,
      sortParams,
      filterColumn,
      filterValue,
      filterOperator,
      filterStartDate,
      filterEndDate,
      excludeSystemUser,
    };
    const apiUrl = `${config.baseUrl}/cases/audit-tracks-v2/?${querystring.stringify(params)}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchDistinctCurrencyValuesByCaseStatus(caseStatus) {
    const params = { caseStatus };
    const apiUrl = `${config.baseUrl}/cases/currencies/?${querystring.stringify(params)}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchDistinctUserIdsFromNotes() {
    const apiUrl = `${config.baseUrl}/cases/notes/user-ids`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchDistinctUserIdsFromAuditTracks() {
    const apiUrl = API_URLS.caseService.audit.userIds;

    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchCaseByUUID(uuid, createdAt) {
    let apiUrl = API_URLS.caseService.singleCase.fetchDetails.replaceAll(API_PARAMS.caseId, uuid);
    if (Objects.isNotNull(createdAt)) {
      apiUrl += `?caseCreatedAt=${encodeURIComponent(createdAt)}`;
    }
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_DETAIL_DATA_NOT_FOUND);
  }

  fetchCaseStateChanges(uuid, caseTenantId) {
    const apiUrl = API_URLS.caseService.singleCase.states.possibleStates.replace(
      API_PARAMS.caseId,
      uuid,
    );

    const moreData = {
      caseTenantId,
    };
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_DETAIL_DATA_NOT_FOUND, moreData);
  }

  updateCaseState({
    uuid,
    caseTenantId,
    caseStateChange,
    message,
    operatorAnswer,
    operatorNote,
    assessment,
  }) {
    const apiUrl = API_URLS.caseService.singleCase.states.updateCaseState.replace(
      API_PARAMS.caseId,
      uuid,
    );
    const body = {
      message,
      operatorAnswer,
      operatorNote,
      ...caseStateChange,
      assessment,
    };
    const customHeader = {
      caseTenantId,
    };
    return this.postJSONForTenant(
      apiUrl,
      body,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      customHeader,
    );
  }

  assessSanctionHit(uuid, payload) {
    const apiUrl = `${config.pepSanctionBaseUrl}/assessments/cases/${uuid}`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  addAuditTrack(caseId, userId, message, event, caseStateChange) {
    const apiUrl = `${config.baseUrl}/cases/${caseId}/auditTrack`;
    const body = { caseId, userId, message, event, caseStateChange };
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      body,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  addStandaloneAuditTrack(userId, message, event, sectionId) {
    const apiUrl = `${config.baseUrl}/auditTrack`;
    const body = { userId, message, event, sectionId };
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      body,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  assessVolumeRisk(caseId, payload) {
    const apiUrl = `${config.volumeRiskConfigBaseUrl}/assessments/cases/${caseId}`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  postCountryRiskAssessment(caseId, payload) {
    const apiUrl = `${config.countryRiskBaseUrl}/assessments/cases/${caseId}`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  postAssessment(caseId, isGroupCase, payload) {
    const apiUrl = API_URLS.assessmentService.assessCase
      .replace(API_PARAMS.caseId, caseId)
      .replace(API_PARAMS.isGroupCase, isGroupCase);
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  postLabelEngineAssessment(caseId, payload) {
    const apiUrl = `${config.labelEngineBaseUrl}/assessments/cases/${caseId}`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  fetchTransactions(payload) {
    const apiUrl = `${config.baseUrl}/transaction-history`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  fetchTransactionsV2(filtering, paging, sorting) {
    const apiUrl = API_URLS.caseService.search;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      { filtering, paging, sorting },
      caseTenantId,
      ErrorCode.CASE_DETAIL_DATA_NOT_FOUND,
    );
  }

  getConfigurationLastUpdateInfo(sectionIds) {
    const apiUrl = `${config.baseUrl}/auditTracks/?sectionIds=${sectionIds}`;
    const caseTenantId = getCaseTenantId();
    return this.getJSONForCustomTenant(
      apiUrl,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  assignCaseToAnotherOperator(payload) {
    const caseId = payload.caseId || '';
    const toOperatorId = payload.userId || '';

    const apiUrl = `${config.baseUrl}/cases/${caseId}/assignTo/${encodeURIComponent(toOperatorId)}`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      {},
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  unmaskOneToken(uuid, { masked, token }) {
    const params = { masked, token };
    const apiUrl = `${config.baseUrl}/cases/${uuid}/decrypt-one?${querystring.stringify(params)}`;
    const caseTenantId = getCaseTenantId();
    return this.getJSONForCustomTenant(
      apiUrl,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  unmaskOneCase(params) {
    const { currentCaseId, uuid, isCustomerCase, caseTenantId, isCustomerData } = params;
    let apiUrl;

    if (isCustomerCase) {
      apiUrl = API_URLS.customerService.singleCase.decryptAll.replaceAll(API_PARAMS.caseId, uuid);
    } else if (isCustomerData) {
      apiUrl = API_URLS.customerData.customerDetail.decryptAll.replaceAll(API_PARAMS.id, uuid);
    } else {
      apiUrl = API_URLS.caseService.singleCase.decryptAll.replaceAll(API_PARAMS.caseId, uuid);
      apiUrl += `?currentCaseId=${currentCaseId}`;
    }
    const getFallbackTenantId = caseTenantId ?? getCaseTenantId();

    return this.getJSONForCustomTenant(
      apiUrl,
      getFallbackTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  unmaskMasterData(uuid) {
    const apiUrl = `${config.baseUrl}/cases/${uuid}/decrypt-master-data`;
    const caseTenantId = getCaseTenantId();
    return this.getJSONForCustomTenant(
      apiUrl,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getCustomerPreviousAlerts(customerAccountLookupIdToken, caseId, createdAt, page, pageSize) {
    const params = { customerAccountLookupIdToken, caseId, page, pageSize, createdAt };
    const apiUrl = `${config.baseUrl}/cases/previousAlerts?${querystring.stringify(params)}`;
    const caseTenantId = getCaseTenantId();
    return this.getJSONForCustomTenant(
      apiUrl,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getCustomerProfileBalances(params) {
    const apiUrl = `${config.baseUrl}/cases/customer-balances?${querystring.stringify(params)}`;
    const caseTenantId = getCaseTenantId();
    return this.getJSONForCustomTenant(
      apiUrl,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getCaseLast4MonthsTxMetadata(tenantId, caseId) {
    const apiUrl = `${config.baseUrl}/files/${caseId}/last_4_months_transactions_meta_data.json`;
    return this.getJSONFromCaseFiles(apiUrl, tenantId, ErrorCode.TENANT_CASE_FILES_NOT_AVAILABLE);
  }

  getCaseLast4MonthsTxHeatmapImage(tenantId, caseId) {
    const apiUrl = `${config.baseUrl}/files/${caseId}/last_4_months_transactions.png`;
    return this.getByteArrayFromCaseFiles(
      apiUrl,
      tenantId,
      ErrorCode.TENANT_CASE_FILES_NOT_AVAILABLE,
    );
  }

  breakOutCaseFromGroup(params) {
    const apiUrl = `${config.baseUrl}/cases/group-case/break-out`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      params,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  addCaseToGroup(params) {
    const apiUrl = `${config.baseUrl}/cases/group-case/add`;
    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      params,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getCaseDetailConfig(tenantId, configVersion) {
    const apiUrl = API_URLS.configService.getConfigWithVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.configVersion, configVersion);
    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  getCaseCollection(ids) {
    const apiUrl = API_URLS.caseService.collection.get;
    return this.postJSONForTenant(apiUrl, { caseUuids: ids });
  }
}

export default CaseAPICommunicator;
