import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class SanctionListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Sanction list API Communicator instance is required.');
    }
  }

  static validateSanctionListJson(json) {
    const valid = json && Array.isArray(json.content);
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.SANCTION_LIST_DATA_INVALID);
  }

  queryReferenceNames(size, page, sortParams, filterColumn, filterValue) {
    return this.communicator
      .fetchReferenceNames(size, page, sortParams, filterColumn, filterValue)
      .then((json) => SanctionListManager.validateSanctionListJson(json));
  }

  querySanctionListLastUpdate() {
    return this.communicator.fetchSanctionListLastUpdate().then((json) => json);
  }

  queryPepSanctionConfig() {
    return this.communicator.fetchPepSanctionConfig().then((json) => json);
  }

  queryInstanciablePepSanctionConfig() {
    return this.communicator.fetchInstanciablePepSanctionConfig().then((json) => ({
      ...json,
      instances: json.instances.map((instance) => ({
        ...instance,
        isBlocking: instance.isBlocking === null ? false : instance.isBlocking,
      })),
    }));
  }

  queryPepSanctionRegions() {
    return this.communicator.fetchPepSanctionRegions().then((json) => json);
  }

  queryRegionSanctionLists(size, page, sortField, sortDirection, filters) {
    return this.communicator
      .fetchRegionSanctionLists(size, page, sortField, sortDirection, filters)
      .then((json) => json);
  }

  savePepSanctionConfig(params) {
    return this.communicator.savePepSanctionConfig(params);
  }

  saveInstanciablePepSanctionConfig(params) {
    return this.communicator.saveInstanciablePepSanctionConfig(params);
  }

  deletePepSanctionInstanciableConfig(id) {
    return this.communicator.deletePepSanctionInstanciableConfig(id);
  }

  updatePepSanctionInstanciableProvider(provider) {
    return this.communicator.updatePepSanctionInstanciableProvider(provider);
  }
}

export default SanctionListManager;
