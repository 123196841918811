class CustomerDetailManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Customer Detail API Communicator instance is required.');
    }
  }

  fetch(customerIdToken) {
    return this.communicator.fetch(customerIdToken);
  }

  getAllCustomerCases(customerIdToken, filters) {
    return this.communicator.getAllCustomerCases(customerIdToken, filters);
  }

  getMoreCustomerCases({ scrollId, customerIdToken, filters }) {
    return this.communicator.getMoreCustomerCases({ scrollId, customerIdToken, filters });
  }

  getAllTransactionCases(tenantId, customerIdToken, filters) {
    return this.communicator.getAllTransactionCases(tenantId, customerIdToken, filters);
  }

  getMoreTransactionCases({ tenantId, scrollId, customerIdToken, filters }) {
    return this.communicator.getMoreTransactionCases({
      tenantId,
      scrollId,
      customerIdToken,
      filters,
    });
  }

  getCustomerDetailsV2(customerIdToken) {
    return this.communicator.getCustomerDetailsV2(customerIdToken);
  }
}

export default CustomerDetailManager;
