export const PASSTHROUGH_V3_ACTIONS = {
  fetch: {
    data: 'PASSTHROUGH_V3_CONFIG_GET',
    success: 'PASSTHROUGH_V3_CONFIG_GET_SUCCESS',
  },
  update: {
    data: 'PASSTHROUGH_V3_CONFIG_UPDATE',
    success: 'PASSTHROUGH_V3_CONFIG_UPDATE_SUCCESS',
  },
  delete: {
    send: 'PASSTHROUGH_V3_CONFIG_DELETE',
    success: 'PASSTHROUGH_V3_CONFIG_DELETE_SUCCESS',
  },
};
