import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class CountryRiskConfigAPICommunicator extends APICommunicator {
  fetchCountryRiskConfig() {
    const apiUrl = API_URLS.configService.countryRisk.getConfig.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchCountryRiskConfigWithTenant(tenantId) {
    const apiUrl = API_URLS.configService.countryRisk.getConfig.replace(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateLowCountryRiskConfig(params) {
    const apiUrl = `${
      config.configurationUrl
    }/config/countryrisk/${TenantManagementService.getActiveTenantId()}/low-risk`;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateMediumCountryRiskConfig(params) {
    const apiUrl = `${
      config.configurationUrl
    }/config/countryrisk/${TenantManagementService.getActiveTenantId()}/medium-risk`;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateHighCountryRiskConfig(params) {
    const apiUrl = `${
      config.configurationUrl
    }/config/countryrisk/${TenantManagementService.getActiveTenantId()}/high-risk`;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateCountryRiskConfig(params) {
    return this.updateLowCountryRiskConfig(params).then(() =>
      this.updateMediumCountryRiskConfig(params).then(() =>
        this.updateHighCountryRiskConfig(params),
      ),
    );
  }
}

export default CountryRiskConfigAPICommunicator;
