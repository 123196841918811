import APICommunicator from 'services/api-communicator';
import { API_URLS, API_PARAMS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { Tenant } from 'models/tenant/tenant';
import TenantManagementService from 'services/tenant/tenant-management-service';
import {
  isTimeframeValid,
  isSamplingRateValid,
} from 'modules/configuration/experimentation/util/experimentation-util';

interface SearchTenantDto {
  tenantIds: Array<string>;
}

interface SearchTenantResponse {
  tenants: Array<Tenant>;
}

export type ExecutionStatus = 'running' | 'complete';

interface ExperimentDto {
  fromDate: string;
  toDate: string;
  sourceTenantId: string;
  caseStatuses: Array<string>;
  samplingRate: number;
}

const isValidExperimentDto = ({ fromDate, toDate, sourceTenantId, samplingRate }: ExperimentDto) =>
  !!fromDate &&
  !!toDate &&
  !!sourceTenantId &&
  isTimeframeValid(new Date(fromDate), new Date(toDate)) &&
  isSamplingRateValid(samplingRate);

const buildQueryParams = (key: string, values: Array<string>) => {
  if (!values || values.length === 0) {
    return '';
  }
  const queryParams = values.map((value) => `${key}=${value}`).join('&');
  return `&${queryParams}`;
};

interface ExecutionStatusResponse {
  data?: {
    status?: ExecutionStatus;
    context?: {
      request?: ExperimentDto;
    };
  };
}

class ExperimentationApi extends APICommunicator {
  search(params: SearchTenantDto): Promise<SearchTenantResponse> {
    const apiUrl = API_URLS.tenantService.search;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  estimate(params: ExperimentDto) {
    if (!isValidExperimentDto(params)) {
      return Promise.resolve();
    }

    const activeTenantId = TenantManagementService.getActiveTenantId() ?? '';
    const apiUrl =
      API_URLS.experimentationService.estimate
        .replace(API_PARAMS.tenantId, activeTenantId)
        .replace(API_PARAMS.sourceTenantId, params.sourceTenantId)
        .replace(API_PARAMS.fromDate, params.fromDate)
        .replace(API_PARAMS.toDate, params.toDate)
        .replace(API_PARAMS.samplingRate, params.samplingRate as unknown as string) +
      buildQueryParams('caseStatuses', params.caseStatuses);
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  experiment(params: ExperimentDto) {
    const activeTenantId = TenantManagementService.getActiveTenantId() ?? '';
    const apiUrl = API_URLS.experimentationService.experiment.replace(
      API_PARAMS.tenantId,
      activeTenantId,
    );
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  executionStatus(): Promise<ExecutionStatusResponse> {
    const apiUrl = API_URLS.experimentationService.executionStatus;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {});
  }
}

export default ExperimentationApi;

export { SearchTenantDto, SearchTenantResponse, ExperimentDto, isValidExperimentDto };
