import { updateProcessingStatusesByAutoClosedRelabeling } from 'components/filters/utils/processing-statuses-by-autoclosed-relabeling';
import { useDetachment } from 'hooks/use-detachment';
import { useState } from 'react';
import { Translation } from 'react-i18next';
import { Box, Chip, Divider, FormHelperText, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import i18next from 'i18next';

const buildProcessingStatusV2ValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t('mapping:filters.filtering.processingStatusesV2.key'),
  values: filter?.values,
});

const buildProcessingStatusValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t('mapping:filters.filtering.processingStatus.key'),
  values: filter?.values,
});

const ProcessingStatusForm = ({ processingStatuses, editForm, onSubmit, filterKey }) => {
  const { autoCloseRelabel: isAutoCloseRelabelAttached } = useDetachment('autoCloseRelabel');

  updateProcessingStatusesByAutoClosedRelabeling(processingStatuses, isAutoCloseRelabelAttached);

  const initialFValues = {};

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`processingStatuses[${item}]`] = true;
    });
  }

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newStatuses = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newStatuses);
    // eslint-disable-next-line no-use-before-define
    validate(newStatuses);
  };

  const validate = (newCheckedStatuses = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    temp.processingStatus = newCheckedStatuses.length ? '' : i18next.t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
        field: filterKey,
        values: checkedValues,
      });
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  return (
    <Translation>
      {(t) => (
        <Grid container item>
          <Form onSubmit={handleSubmit}>
            <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
              {processingStatuses.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Controls.Checkbox
                    name={`processingStatuses[${item.key}]`}
                    label={item.value.heading}
                    value={values[`processingStatuses[${item.key}]`] ?? false}
                    onChange={(event) => handleSelectChange(event, item.key)}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            {errors.processingStatus && (
              <FormHelperText className="pl-3" error>
                {errors.flow}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const ProcessingStatusChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.processingStatusesV2.value.heading')}
        </Box>
        {form.values.map((item, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            className="mr-1"
            label={t([
              `mapping:processingStatus.${item}.value.heading`,
              `mapping:processingStatusV2.${item}.value.heading`,
            ])}
          />
        ))}
      </>
    )}
  </Translation>
);

export {
  ProcessingStatusForm,
  ProcessingStatusChip,
  buildProcessingStatusValuesFromUrl,
  buildProcessingStatusV2ValuesFromUrl,
};
