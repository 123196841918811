import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class ToolingManager {
  constructor(communicator) {
    this.communicator = communicator;
  }

  reprocessCases(tenantId, payload) {
    return this.communicator.postForReprocessing(payload, tenantId, 'ERROR DURING REPROCESSING');
  }

  validateCasesJson(json) {
    const valid = !!json && (!!json.data || !!json.errorMessage);
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  /**
   * Params:
   * - tenantIds: string contains list of tenant Ids, comma separator
   * - queryJson: JSON object represents Elasticsearch query string
   */
  query(params) {
    return this.communicator.query(params).then((json) => this.validateCasesJson(json));
  }

  /**
   * Params:
   * - tenantIds: string contains list of tenant Ids, comma separator
   * - queryJson: JSON object represents Elasticsearch query string
   * - configVersionOption: one of possible values, ORIGINAL, LATEST or MANUAL_INPUT
   * - configVersion: config version number
   */
  reprocess(params) {
    return this.communicator.reprocess(params).then((json) => this.validateCasesJson(json));
  }
}

export default ToolingManager;
