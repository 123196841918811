import { useTranslation } from 'react-i18next';
import { makeSnakeCaseReadable } from 'utils/helpers/string-util';
import {
  Attribute,
  GENERIC_PREFIX,
} from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/entity-resolution-risk-details';

export const useSubHeadAttribute = (attr?: Attribute | string) => {
  const { t, i18n } = useTranslation();
  if (attr) {
    if (attr.startsWith(GENERIC_PREFIX)) {
      return makeSnakeCaseReadable(attr.substring(GENERIC_PREFIX.length));
    }
    if (
      i18n.exists(`case:userPopover.riskRating.entityResolutionRisks.attributes.${attr}.heading`)
    ) {
      return t(`case:userPopover.riskRating.entityResolutionRisks.attributes.${attr}.heading`);
    }
    return attr;
  }
  return '-';
};
