import moment from 'moment';

import Party from 'utils/constants/party';
import Strings from 'utils/helpers/locales/strings.en';
import TENANT_IDS from 'utils/constants/tenant-ids';
import Default from 'utils/constants/default';
import { SORT_DIRECTION } from 'utils/constants/sort-directions';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import querystring from 'querystring';
import config from 'utils/helpers/config-utils/config';
import $ from 'jquery';
import { equals } from './list-string-util';
import { formatMoney } from 'utils/helpers/math-util';
import { checkIfValidUUID } from 'utils/helpers/examination-util';
import { encodeHtml } from 'utils/helpers/string-util';
import { REGEX } from 'utils/constants/constant';
import { areFiltersEqual } from 'modules/configuration/rule-manager/common/filters/dynamic/filter-util';
import { equalArray } from 'utils/helpers/array-util';

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const DATE_TIME_FORMAT2 = 'YYYY-MM-DDTHH:mm:ss.SSS';

const DEFAULT_DATA_DATE = '1970-01-01';

class Utility {
  static formatDateForApi(date) {
    return date === null ? '' : moment(date).format(DATE_TIME_FORMAT2);
  }

  static formatDateForApiAsZonedDateTime(date) {
    return date === null ? '' : moment(date).format(DATE_TIME_FORMAT);
  }
}

export default Utility;

export function formatMoneyAndCurrency(amount, currency) {
  return `${currency || Default.CURRENCY} ${formatMoney(amount)}`;
}

export function getUrlParameter(props, name) {
  const match = props.match || {};
  const params = match.params || {};
  return params[name] || undefined;
}

export function getSortIcon(isSortable, isSorted, sortDirection) {
  if (isSortable) {
    if (isSorted) {
      return sortDirection === SORT_DIRECTION.desc ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      );
    }
    return <ArrowUpwardIcon fontSize="small" />;
  }

  return null;
}

export function generateSortParams(newSortParamList) {
  let newSortParams = '';
  newSortParamList.forEach((p) => {
    newSortParams += `${p.column},${p.direction};`;
  });

  return newSortParams;
}

export function isColumnSorting(sortParamList, column) {
  for (let i = 0; i < sortParamList.length; i++) {
    if (sortParamList[i].column === column) {
      return true;
    }
  }

  return false;
}

export function getFundsTitle(party) {
  return party === Party.FUNDS_ORIGINATOR
    ? Strings.CaseTable['Funds originator']
    : Strings.CaseTable['Funds beneficiary'];
}

export function lowerFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getFilterDateValue(date) {
  return date === null ? '' : moment(date).format(DATE_TIME_FORMAT);
}

export function getDisplayValue(isShow) {
  return isShow ? { display: 'initial' } : { display: 'none' };
}

export function getMilliseconds(date) {
  return new Date(date || DEFAULT_DATA_DATE).getTime();
}

export function buildSanctionMap(desctiptions) {
  const map = new Map();

  for (let i = 0; i < desctiptions.length; i++) {
    const d = desctiptions[i];

    const key = d.sanctionedEntityType.name;

    if (!map.has(key)) {
      map.set(key, new Map());
      map.get(key).set('entityType', d.sanctionedEntityType);
      map.get(key).set('listType', []);
    }

    if (d.sanctionListType && map.get(key)) {
      map.get(key).get('listType').push(d.sanctionListType.fullName);
    }
  }

  // Remove sanction type = SIP and list type in 'Other Official Lists' when have more than one sanction type
  if (map.size > 1) {
    map.forEach((el) => {
      if (
        el.get('entityType').name === 'SIP' &&
        el.get('listType').length === 1 &&
        el.get('listType').indexOf('Other Official Lists') >= 0
      ) {
        map.delete('SIP');
      }
    });
  }

  return map;
}

export function getURLFromString(rawString) {
  const replaceString = rawString.replace(')', '');
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return replaceString.match(urlRegex);
}

export function replaceURLFromString(rawString) {
  const replaceString = rawString.replace(')', '');
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return replaceString.replace(urlRegex, '');
}

export function subtractToHours(time1, time2) {
  const startDate = moment(time1);
  const endDate = moment(time2);
  const duration = moment.duration(startDate.diff(endDate));
  return duration.asHours();
}

export function ordinalSuffixOf(number) {
  const j = number % 10;
  const k = number % 100;
  let suffix;
  if (j === 1 && k !== 11) {
    suffix = 'st';
  } else if (j === 2 && k !== 12) {
    suffix = 'nd';
  } else if (j === 3 && k !== 13) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }
  return suffix;
}

export function randomInterval() {
  return 1000 * Math.floor(Math.random() * 6) + 5000;
}

const customerHint = {
  text: Strings.CaseTable.Customer,
  color: 'dark',
  showMasterData: true,
};
const initiatorHint = {
  text: Strings.CaseTable.Initiator,
  color: 'secondary',
};

const counterPartyHint = {
  text: Strings.CaseTable['Counter party'],
  color: 'dark',
  showMasterData: true,
};

export function getHintsForParty(party, transaction) {
  const hints = [];
  const { accountHoldingParty } = transaction;
  const { initiatingParty } = transaction;
  if (accountHoldingParty === party) {
    hints.push(customerHint);
  } else {
    hints.push(counterPartyHint);
  }

  if (initiatingParty === party) {
    hints.push(initiatorHint);
  }
  return hints;
}

export function randomString(length) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function createElementId(text) {
  if (text != null) {
    return text.replace(/[^a-zA-Z0-9]/g, '_');
  }
  return randomString(10);
}

export function getMonthName(month) {
  if (month < 1 || month > 12) return '';

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return months[month - 1];
}

export function isNull(obj) {
  return obj === undefined || obj === null;
}

export function isNotNull(obj) {
  return !isNull(obj);
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item) && item !== null;
}

export function getLocaleString(strings, key) {
  if (strings == null || key == null) {
    return '';
  }
  const result = strings[key];
  return result != null ? result : key;
}

export function getRiskLabelText(riskLevel) {
  if (!riskLevel) {
    return null;
  }
  if (riskLevel.indexOf('low') >= 0) {
    return Strings.CustomerPersonDetailLabels.LOW;
  }
  if (riskLevel.indexOf('high') >= 0) {
    return Strings.CustomerPersonDetailLabels.HIGH;
  }
  if (riskLevel.indexOf('medium') >= 0) {
    return Strings.CustomerPersonDetailLabels.MEDIUM;
  }
  return null;
}

export function getRiskLabel(riskLevel) {
  const riskLabelText = getRiskLabelText(riskLevel);
  if (riskLabelText) {
    let css = 'success';
    if (riskLevel.indexOf('medium') >= 0) {
      css = 'warning';
    } else if (riskLevel.indexOf('high') >= 0) {
      css = 'danger';
    }
    return <span className={`p015 badge badge-pill badge-${css}`}>{riskLabelText}</span>;
  }
  return null;
}

export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function mapToArray(map, keyName) {
  const result = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in map) {
    const mapValue = map[key];
    mapValue[keyName] = key;
    result.push(mapValue);
  }
  return result;
}

export function callback2Promise(func, params) {
  return new Promise((resolve, reject) => {
    func(params, (err, data) => {
      if (err) reject(err);
      else resolve(data);
    });
  });
}

export function getDaysUntilToday() {
  const oneDay = 24 * 60 * 60 * 1000;

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  return Math.round(Math.abs((firstDay - today) / oneDay));
}

export function deepCopy(source) {
  return JSON.parse(JSON.stringify(source));
}

export function removeItemFromList(id, list) {
  for (let i = 0; i < list.length; i++) {
    if (id === list[i].id) {
      list.splice(i, 1);
    }
  }

  return list;
}

export function validateOneEmail(oneEmail) {
  if (oneEmail) {
    const email = oneEmail.trim();
    if (email.length === 0) {
      return false;
    }

    const match = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return !!match;
  }
  return false;
}

export function validateEmails(mails) {
  if (mails) {
    let mailList = mails.trim();
    if (mailList.length === 0) {
      return false;
    }

    mailList = mailList.replace(/,/g, ';');
    mailList = mailList.replace(/ /g, ';');
    const mailArray = mailList.split(';');
    for (let i = 0; i < mailArray.length; i++) {
      if (!!mailArray[i] && !validateOneEmail(mailArray[i])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function sameNumber(num1, num2) {
  if (isNaN(num1) || isNaN(num2)) {
    return false;
  }
  return parseFloat(num1) === parseFloat(num2);
}

export function showOnTenant(tenantId) {
  return tenantId === TENANT_IDS.dev.demo || tenantId === TENANT_IDS.multipleEnviroments.hawkConfig;
}

export function getViewDetailsIcon() {
  return <FindInPageIcon />;
}

export function getHighlightedText(text, highlight) {
  if (!text || !highlight) {
    return text;
  }

  const lowercaseHighlight = highlight.toLowerCase();
  const parts = text.split(new RegExp(`(${lowercaseHighlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span key={i} className={part.toLowerCase() === lowercaseHighlight ? 'matched-letter' : ''}>
          {part}
        </span>
      ))}
    </span>
  );
}

export function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

export function updateTreeItems(node, selectedRule) {
  for (let i = 0; i < node.items.length; i++) {
    const subNode = node.items[i];
    subNode.disabled = subNode.id !== selectedRule.id;
  }
}

export function updateTreeSource(selectedRule, treeViewSource) {
  if (selectedRule === undefined) {
    return;
  }

  for (let i = 0; i < treeViewSource.length; i++) {
    const node = treeViewSource[i];
    node.disabled = node.id !== selectedRule.id;

    if (node.items.length > 0) {
      updateTreeItems(node, selectedRule);
    }
  }

  // eslint-disable-next-line consistent-return
  return treeViewSource;
}

export function getTenantsFromString(tenantsString) {
  if (!tenantsString) {
    return [];
  }

  if (typeof tenantsString === 'string') {
    return JSON.parse(tenantsString);
  }

  return tenantsString;
}

export function getTimeUnitDescription(timeUnit) {
  let description;
  switch (timeUnit) {
    case '1d':
      description = 'day';
      break;

    case '1h':
      description = 'hour';
      break;

    case '10m':
      description = '10 minutes';
      break;

    default:
      description = '';
      break;
  }

  return description;
}

export const cleanUpLocalStorage = (prefix) => {
  const arr = [];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(0, prefix.length) === prefix) {
      arr.push(localStorage.key(i));
    }
  }

  for (let j = 0; j < arr.length; j++) {
    localStorage.removeItem(arr[j]);
  }
};

export const indexOfIgnoreCase = (str, substr) => {
  if (!str || !substr) {
    return -1;
  }
  return str.toLowerCase().indexOf(substr.toLowerCase());
};

export const includesIgnoreCase = (str, substr) => indexOfIgnoreCase(str, substr) >= 0;

export function copyCommonConfigCriteriaToRuleData(ruleData) {
  const commonConfigCriteria = ruleData.commonConfigCriteria || {};
  return {
    ...ruleData,
    productType: commonConfigCriteria.productType,
    organizationType: commonConfigCriteria.organizationType,
    riskLevel: commonConfigCriteria.riskLevel,
    notes: commonConfigCriteria.notes,
    countryRiskLevel: commonConfigCriteria.countryRiskLevel,
    customerType: commonConfigCriteria.customerType,
    executionScopes: commonConfigCriteria.executionScopes,
    executionScopesOption: commonConfigCriteria.executionScopesOption,
    paymentMeans: commonConfigCriteria.paymentMeans,
    dynamicFilters: commonConfigCriteria.dynamicFilters,
    dynamicComparisonFilters: commonConfigCriteria.dynamicComparisonFilters,
  };
}

export function isCommonCriteriaChanged(data, state) {
  const commonConfigCriteria = data.commonConfigCriteria || {};

  return (
    !equals(commonConfigCriteria.productType, state.productType) ||
    !equals(commonConfigCriteria.organizationType, state.organizationType) ||
    commonConfigCriteria.riskLevel !== state.riskLevel ||
    commonConfigCriteria.countryRiskLevel !== state.countryRiskLevel ||
    commonConfigCriteria.customerType !== state.customerType ||
    !equals(commonConfigCriteria.executionScopes, state.executionScopes) ||
    commonConfigCriteria.executionScopesOption !== state.executionScopesOption ||
    (commonConfigCriteria.notes ?? '') !== encodeHtml(state.notes) ||
    commonConfigCriteria.paymentMeans !== state.paymentMeans ||
    !equalArray(commonConfigCriteria.dynamicFilters, state.dynamicFilters, areFiltersEqual) ||
    !equalArray(
      commonConfigCriteria.dynamicComparisonFilters,
      state.dynamicComparisonFilters,
      areFiltersEqual,
    ) ||
    commonConfigCriteria.countryListInstance !== state.countryListInstance
  );
}

export function getVisibilityIcon(currentState) {
  return currentState ? (
    <VisibilityIcon fontSize="small" className="color-on-secondary" />
  ) : (
    <VisibilityOffIcon fontSize="small" className="color-on-surface-secondary" />
  );
}

export function toggleVisibilityButton(state) {
  if (state) {
    $('#visibilityButton').removeClass('d-none');
  } else {
    $('#visibilityButton').addClass('d-none');
  }
}

export function setFavicon(source) {
  const favicon = document.getElementById('favicon');
  if (favicon !== null) {
    favicon.href = source;
  }
}

/**
 * parse querystring from object for the report donwload url
 * @param {*} params
 */
const prepareQueryForReportV2 = (params) => {
  const { startDate, endDate, chartType } = params;

  const queryParams = {
    startDate: moment(startDate).format(DATE_TIME_FORMAT),
    endDate: moment(endDate).format(DATE_TIME_FORMAT),
    chartType,
  };
  return querystring.stringify(queryParams);
};

/**
 * build report url for download CVS using V2 API (ElasticSearch)
 * @param {*} reportBaseUrl
 * @param {*} selectedTimeframe
 */
const getReportUrlV2 = (reportBaseUrl, selectedTimeframe) =>
  config.baseUrlV2 +
  reportBaseUrl +
  prepareQueryForReportV2({
    startDate: selectedTimeframe.value.from,
    endDate: selectedTimeframe.value.to,
  });
/**
 * build report url for download CVS using V1 API
 * @param {*} reportBaseUrl
 * @param {*} selectedTimeframe
 */
const getReportUrl = (reportBaseUrl, selectedTimeframe) =>
  config.baseUrl +
  reportBaseUrl +
  prepareQueryForReportV2({
    startDate: selectedTimeframe.value.from,
    endDate: selectedTimeframe.value.to,
  });

const getReportUrlTruePositiveV2 = (reportBaseUrl, selectedTimeframe, chartType) =>
  config.baseUrlV2 +
  reportBaseUrl +
  prepareQueryForReportV2({
    startDate: selectedTimeframe.value.from,
    endDate: selectedTimeframe.value.to,
    chartType,
  });

/**
 * build CVS file name
 * @param {*} prefix
 * @param {*} selectedTimeframe
 */
const buildCSVFileName = (prefix, selectedTimeframe) =>
  `${prefix}${Utility.formatDateForApi(selectedTimeframe.value.from)}-${Utility.formatDateForApi(
    selectedTimeframe.value.to,
  )}.csv`;

/**
 * Search for any match of array keys inside property object
 * @param {*} array array of keys
 * @param {*} properties object containing all keys
 */
const getFirstPropertyInArray = (array, properties) => {
  let foundItem;
  if (array) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const item of array) {
      if (properties && properties[item]) {
        foundItem = properties[item];
        break;
      }
    }
  }
  return foundItem;
};

const formatNumberWithCommas = (num) => num.toString().replace(REGEX.digitThreeCount, ',');

const findTenantId = (path) => {
  if (!path) {
    return undefined;
  }

  const uuidLength = 36;
  const tenantIdPreceeding = 'caseManager/';
  const idx = path.indexOf(tenantIdPreceeding);
  if (idx < 0) {
    return undefined;
  }

  const potentialTenantId = path.substring(
    idx + tenantIdPreceeding.length,
    idx + tenantIdPreceeding.length + uuidLength,
  );
  if (checkIfValidUUID(potentialTenantId)) {
    return potentialTenantId;
  }

  return undefined;
};

const replaceTenantId = (path, newTenantId) => {
  const potentialTenantId = findTenantId(path);
  if (potentialTenantId) {
    return path.replace(potentialTenantId, newTenantId);
  }

  return path;
};

export {
  getReportUrl,
  getReportUrlV2,
  getReportUrlTruePositiveV2,
  buildCSVFileName,
  getFirstPropertyInArray,
  formatNumberWithCommas,
  findTenantId,
  replaceTenantId,
};
