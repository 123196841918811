export class Objects {
  // eslint-disable-next-line
  public static isNull(obj?: any | null | undefined): boolean {
    return typeof obj === 'undefined' || obj === null;
  }

  // eslint-disable-next-line
  public static isNotNull(obj?: any | null | undefined): boolean {
    return !Objects.isNull(obj);
  }

  // eslint-disable-next-line
  public static isNotNullAndEmpty(obj?: any | null | undefined): boolean {
    return Objects.isNotNull(obj) && Object.keys(obj).length > 0;
  }
}
