export const getLines = (chunk?: Uint8Array): string[] => {
  const decoder = new TextDecoder('utf-8');
  const decodedChunk = decoder.decode(chunk);
  return decodedChunk.split('\n');
};

export const trimLastLine = (lines: string[]): string => {
  const newLines = lines.slice(0, lines.length - 1);
  return newLines.join('\n');
};
