import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import i18next from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

/**
 *
 * @deprecated please do not modify this file
 * use Select2 as it is in typescript
 *
 * */
const Select = ({
  name,
  label,
  value,
  error = '',
  onChange,
  options,
  fullWidth,
  multiple,
  disableSelected,
  disabled,
  variant = 'outlined',
  size = 'small',
  forceNotEmpty,
  displayEmpty,
  displayUsingChips = false,
  noOptionPlaceholder,
  required,
  dataTestId = '',
  selectAllOption = false,
}) => {
  const SELECT_ALL = 'select_all';
  const emptyText = displayEmpty ? i18next.t('any.heading') : '';
  const { t } = useTranslation();

  const forceSingleSelection = (name, event) => {
    const eventValue = {
      target: {
        name,
        value: event.target.value,
      },
    };

    if (options.length === 1) {
      eventValue.target.value = value;
    } else if (event.target.value.includes('')) {
      eventValue.target.value = [];
    }

    return eventValue;
  };

  const getFormValue = (value) => {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return value;
      }
      return options?.find((element) => element.key === value.key);
    }
    return value;
  };

  const getFormRenderValue = (value) => {
    if (noOptionPlaceholder && !options.length) {
      return (
        <Typography component="em" color="textSecondary">
          {noOptionPlaceholder}
        </Typography>
      );
    }
    if (Array.isArray(value)) {
      if (value.length) {
        if (typeof value[0] === 'object' && value[0].value?.heading) {
          return value.map((element) => element.value?.heading).join(', ');
        }
        return value.join(', ');
      }
      return emptyText;
    }
    if (typeof value === 'object') {
      return (
        <ListItem dense className="p-0">
          {!!value.value?.icon && (
            <ListItemIcon className="min-w-0 pr-2">{value.value?.icon}</ListItemIcon>
          )}
          <ListItemText
            className={!multiple ? `my-0` : ''}
            disableTypography
            primary={value.value?.heading}
          />
        </ListItem>
      );
    }

    return value;
  };

  const getFormRenderChips = (value) => {
    const elementsToDisplay = [...(Array.isArray(value) ? value : [value])];
    return elementsToDisplay.map((element, index) => (
      <Chip
        size={size}
        key={index}
        variant="filled"
        color="default"
        className="ml-1"
        label={element.value.heading}
      />
    ));
  };

  const changeHandler = (event) => {
    if (multiple && selectAllOption && event.target?.value?.includes(SELECT_ALL)) {
      onChange({
        target: {
          name,
          value: options.length === value.length ? [] : options,
        },
      });
    } else if (forceNotEmpty) {
      onChange(forceSingleSelection(name, event));
    } else {
      onChange(event);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormControl
      fullWidth={fullWidth}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(error && { error: true })}
      size={size}
      required={required}
      data-testid={dataTestId}
    >
      <InputLabel shrink={forceNotEmpty}>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        disabled={disabled}
        variant={variant}
        multiple={multiple}
        displayEmpty={displayEmpty}
        value={getFormValue(value) || ''}
        onChange={changeHandler}
        renderValue={(selected) =>
          displayUsingChips ? getFormRenderChips(selected) : getFormRenderValue(selected)
        }
      >
        {multiple && selectAllOption && options.length > 1 && (
          <MenuItem value={SELECT_ALL}>
            <Checkbox
              color="primary"
              size="small"
              checked={options.length > 0 && value.length === options.length}
              indeterminate={value.length > 0 && value.length < options.length}
            />
            <ListItemText disableTypography primary={t('selectAll.heading')} />
          </MenuItem>
        )}

        {displayEmpty && options.length > 1 && (
          <MenuItem value="">
            <Checkbox checked={!value.length} />
            <ListItemText disableTypography primary={emptyText} />
          </MenuItem>
        )}

        {options.map((item, index) => (
          <MenuItem key={index} value={item} disabled={disableSelected?.includes(item.key)}>
            {multiple && (
              <Checkbox
                color="primary"
                size="small"
                checked={_.findIndex(value, (o) => _.isMatch(o, item)) > -1}
              />
            )}
            {!!item.value?.icon && <ListItemIcon>{item.value?.icon}</ListItemIcon>}
            <ListItemText
              disableTypography
              primary={typeof item === 'object' ? item.value?.heading : item}
            />
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export default Select;
