import { CircularProgress } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFirstPropertyInArray } from 'utils/helpers/utility';

/**
 * showing loader as first child and rendering other children if found after loading
 * @param {*} forceLoad if an additional condition is required to show the loading behavior
 * @returns
 */
const LoadingContainer = ({ actionType, children, forceLoad }) => {
  const processing = useSelector((state) => state.processing);

  const isProcessing = useMemo(() => {
    if (isNil(actionType)) {
      return processing?.isProcessing;
    }

    let tempActions = actionType;
    if (!Array.isArray(actionType) && actionType) {
      tempActions = [actionType];
    }
    return getFirstPropertyInArray(tempActions, processing);
  }, [actionType, processing]);

  const getLoadingChild = () => {
    if (children.length) {
      return children[0];
    }
    return children;
  };

  const getContentChild = () => {
    if (children.length) {
      return children.length > 1 ? children?.slice(1) : null;
    }
    return null;
  };

  if (forceLoad === undefined) {
    return isProcessing ? getLoadingChild() : getContentChild();
  }

  return isProcessing && forceLoad ? getLoadingChild() : getContentChild();
};

/**
 * Circular spinner
 * @param actionType single action for spinner
 * @param multipleActions multiple actions to show spinner
 * @param isLoading sending a boolean instead of sending action for spinner
 */
const Spinner = ({ actionType, multipleActions, isLoading, size, thickness, color, className }) => {
  const processing = useSelector((state) => state.processing);
  let isProcessing;
  if (multipleActions) {
    isProcessing = getFirstPropertyInArray(multipleActions, processing);
  } else if (actionType) {
    isProcessing = processing[actionType];
  } else if (isLoading === undefined) {
    isProcessing = processing?.isProcessing;
  } else {
    isProcessing = isLoading;
  }

  return isProcessing ? (
    <CircularProgress size={size} color={color} thickness={thickness} className={className} />
  ) : null;
};

export default Spinner;

export { LoadingContainer };
