import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import APICommunicator from 'services/api-communicator';

class SettingsApiCommunicator extends APICommunicator {
  getSettings(tenantId, configVersion) {
    let apiUrl;
    if (configVersion) {
      apiUrl = API_URLS.settings.getByVersion
        .replaceAll(API_PARAMS.tenantId, tenantId)
        .replaceAll(API_PARAMS.configVersion, configVersion);
    } else {
      apiUrl = API_URLS.settings.get.replaceAll(API_PARAMS.tenantId, tenantId);
    }
    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateSettings(tenantId, payload) {
    const apiUrl = API_URLS.settings.update.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.postJSONForCustomTenant(
      apiUrl,
      payload,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  addSettingsContryRisks(tenantId, { title, model }) {
    const body = {
      title,
      prohibitedRiskCountries: model.countries.prohibited
        .filter((country) => country.value)
        .map((country) => country.code),
      highRiskCountries: model.countries.high
        .filter((country) => country.value)
        .map((country) => country.code),
      lowRiskCountries: model.countries.low
        .filter((country) => country.value)
        .map((country) => country.code),
    };

    const apiUrl = API_URLS.settings.countryRisks.add.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.postJSONForCustomTenant(
      apiUrl,
      body,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateSettingsContryRisks(tenantId, { title, model }, id) {
    const body = {
      title,
      prohibitedRiskCountries: model.countries.prohibited
        .filter((country) => country.value)
        .map((country) => country.code),
      highRiskCountries: model.countries.high
        .filter((country) => country.value)
        .map((country) => country.code),
      lowRiskCountries: model.countries.low
        .filter((country) => country.value)
        .map((country) => country.code),
    };

    const apiUrl = API_URLS.settings.countryRisks.update
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.id, id);

    return this.putJSONForCustomTenant(
      apiUrl,
      body,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteSettingsContryRisks(tenantId, instanceId) {
    const apiUrl = API_URLS.settings.countryRisks.delete
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.id, instanceId);

    return this.deleteForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default SettingsApiCommunicator;
