import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Form, FormGroup } from 'reactstrap';
import Alert from '@mui/material/Alert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NavLink } from 'react-router-dom';
import config from 'utils/helpers/config-utils/config';
import CaseRouteUrls from 'utils/constants/case-route-url';
import { LoadingButton } from 'components/loaders/loading-button';
import { LoginActions } from 'middleware/action-types';
import { Translation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AppLogo from 'components/app-logo';

const LoginViewV1 = ({
  hasError,
  isProcessing,
  errorMessage,
  username,
  handleEmailChanges,
  isPwdVisible,
  password,
  handlePwdChanges,
  handleShowPasswordChanges,
  handleLogin,
  handleSsoLogin,
}) => (
  <Translation>
    {(t) => (
      <Dialog className="backdrop-none" PaperProps={{ elevation: 1 }} fullWidth maxWidth="xs" open>
        <DialogContent>
          <Form>
            <FormGroup>
              <Grid container spacing={1}>
                <Grid container item xs={12} justifyContent="center">
                  <AppLogo className="app-logo pb-2 pt-2" />
                </Grid>
                <Grid container spacing={2}>
                  <Grid container item xs={12} justifyContent="center">
                    <Typography color="textSecondary" variant="body2">
                      {t('appTitle.heading')}
                    </Typography>
                  </Grid>
                  {hasError && !isProcessing ? (
                    <Grid item xs={12} id="login-message">
                      <Alert severity="error"> {errorMessage}</Alert>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="email"
                      type="email"
                      label={t('login.username.heading')}
                      size="small"
                      autoFocus
                      value={username}
                      onChange={handleEmailChanges}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="password"
                      label={t('login.password.heading')}
                      size="small"
                      type={isPwdVisible ? 'text' : 'password'}
                      value={password}
                      onChange={handlePwdChanges}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={handleShowPasswordChanges}>
                              {isPwdVisible ? (
                                <Visibility fontSize="small" />
                              ) : (
                                <VisibilityOff fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <NavLink
                    className="text-decoration-none"
                    to={config.APP_ROOT_URL + CaseRouteUrls.RESET_PASSWORD}
                  >
                    <Typography component="span" variant="body2" color="secondary">
                      {t('login.password.subheading')}
                    </Typography>
                  </NavLink>
                </Grid>

                <Grid item xs={12} className="mt-3">
                  <LoadingButton
                    type="submit"
                    id="loginButton"
                    fullWidth
                    title={t('login.heading')}
                    color="primary"
                    variant="contained"
                    onClick={handleLogin}
                    multipleActions={[LoginActions.START_LOGIN_WITH_DATABASE]}
                  />
                </Grid>
                <Grid item xs={12} className="mt-2">
                  <Divider flexItem light />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className="pb-2 pt-3"
                    component="p"
                  >
                    {t('sso.info.heading')}
                  </Typography>
                  <LoadingButton
                    type="submit"
                    id="ssoButton"
                    fullWidth
                    startIcon={<LockOutlinedIcon className="color-primary icon-20" />}
                    title={t('sso.heading')}
                    color="primary"
                    variant="outlined"
                    onClick={handleSsoLogin}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Form>
        </DialogContent>
      </Dialog>
    )}
  </Translation>
);

export default LoginViewV1;
