export const EFFICIENCY_ACTIONS = {
  efficiency: {
    falsePositiveRate: {
      data: 'GET_GROUPS_FALSE_POSITIVE_RATE',
      success: 'GET_GROUPS_FALSE_POSITIVE_RATE_SUCCESS',
      error: 'GET_GROUPS_FALSE_POSITIVE_RATE_ERROR',
    },
    falsePositivePattern: {
      data: 'GET_GROUPS_FALSE_POSITIVE_PATTERN',
      success: 'GET_GROUPS_FALSE_POSITIVE_PATTERN_SUCCESS',
      error: 'GET_GROUPS_FALSE_POSITIVE_PATTERN_ERROR',
    },
    truePositivePattern: {
      data: 'GET_GROUPS_TRUE_POSITIVE_PATTERN',
      success: 'GET_GROUPS_TRUE_POSITIVE_PATTERN_SUCCESS',
      error: 'GET_GROUPS_TRUE_POSITIVE_PATTERN_ERROR',
    },
    casesPerformance: {
      data: 'GET_GROUPS_CASES_PERFORMANCE',
      success: 'GET_GROUPS_CASES_PERFORMANCE_SUCCESS',
      error: 'GET_GROUPS_CASES_PERFORMANCE_ERROR',
    },
    riskyWordsPattern: {
      data: 'GET_RISKY_WORDS_PATTERN',
      success: 'GET_RISKY_WORDS_SUCCESS',
      error: 'GET_RISKY_WORDS_ERROR',
    },
  },
};
