import { Divider, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import { Form, useForm } from 'hooks/use-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from 'components/filters/models/filter';

interface RuleInstancesFormProps {
  editForm: { values: string[] };
  onSubmit: (filter: Filter) => void;
}

type useFormReturnType = ReturnType<typeof useForm> & {
  values: { ruleInstances: string[] };
  errors: Record<string, null>;
};

export const RuleInstancesForm = ({ editForm, onSubmit }: RuleInstancesFormProps) => {
  const { t } = useTranslation();
  const initialFValues = {
    ruleInstances: editForm?.values || [],
  };

  const { values, errors, setErrors, handleInputChange }: useFormReturnType = useForm(
    initialFValues,
    true,
    // eslint-disable-next-line no-use-before-define
    validate,
  );

  function validate(fieldValues = values): boolean {
    const temp: Record<string, string | null> = { ...errors };
    if ('ruleInstances' in fieldValues) {
      temp.ruleInstances = fieldValues.ruleInstances?.length ? '' : t('required.heading');
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }

    return false;
  }

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: t('mapping:filters.filtering.ruleInstances.key'),
        values: values.ruleInstances.map((ruleInstanceWildcard: string) =>
          ruleInstanceWildcard.toUpperCase(),
        ),
      });
    }
  };

  return (
    <Grid container>
      <Form className="w-100">
        <Grid container item className="px-3 py-2" spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                dataTestIdPrefix="filter-rule-instances"
                name="ruleInstances"
                label={t('condition.equal.heading')}
                value={values.ruleInstances}
                onChange={handleInputChange}
                error={errors.ruleInstances}
                shrink
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            dataTestIdPrefix="filter-by-rule-instances"
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};
