import { Grid, Typography } from '@mui/material';
import { RiskChip } from 'components/chips/risk-chip';
import { useTranslation } from 'react-i18next';

const ManualRiskRatingSummary = ({
  manualRiskLevel,
  previousRiskLevel,
  riskRatingScoreOnly,
  activeTill,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container item spacing={2}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography color="textSecondary" variant="body2">
            {t('case:userPopover.riskRating.manualRiskLevel.heading')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="textSecondary" variant="body2">
            {t('case:userPopover.riskRating.activeTill.heading')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="textSecondary" variant="body2">
            {t('case:userPopover.riskRating.previousRiskLevel.heading')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6} spacing={2} alignItems="center">
          <Grid item>
            <RiskChip
              size="small"
              risk={manualRiskLevel}
              label={t(`riskCategory.${manualRiskLevel}.heading`)}
            />
          </Grid>
          <Grid item className="lh-0">
            {riskRatingScoreOnly}
          </Grid>
        </Grid>
        <Grid container item xs={3} alignItems="center">
          <Typography color="textSecondary" variant="body2">
            {activeTill}
          </Typography>
        </Grid>
        <Grid container item xs={3} alignItems="center">
          <RiskChip
            size="small"
            risk={previousRiskLevel}
            label={previousRiskLevel ? t(`riskCategory.${previousRiskLevel}.heading`) : '-'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ManualRiskRatingSummary };
