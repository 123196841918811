import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class TrxCaseDetailNoteManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Case API Communicator instance is required.');
    }
  }

  queryDistinctUserIdsFromNotes() {
    return this.communicator.fetchDistinctUserIdsFromNotes().then((json) => Promise.resolve(json));
  }

  addCaseNote(uuid, userId, note, type, attachments) {
    if (userId != null && userId.length > 0 && note != null && note.length > 0) {
      return this.communicator.addCaseNote(uuid, userId, note, type, attachments);
    }
    return raiseErrorByCode(ErrorCode.CASE_NOTE_DATA_INVALID);
  }

  downloadAttachment(noteId, attachmentId) {
    return this.communicator.downloadAttachment(noteId, attachmentId);
  }
}

export default TrxCaseDetailNoteManager;
