import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLeading } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import {
  getAllCustomerCases,
  getAllCustomerCasesSuccess,
  getAllTransactionCases,
  getAllTransactionCasesSuccess,
  getCustomerDetail,
  getCustomerDetailSuccess,
  getMoreCustomerCases,
  getMoreTransactionCases,
} from 'modules/customer-data/customer-detail/middleware/reducers/customer-detail-reducer';
import CustomerDetailApiCommunicator from 'modules/customer-data/customer-detail/middleware/services/customer-detail-api';
import CustomerDetailManager from 'modules/customer-data/customer-detail/middleware/services/customer-detail-manager';
import { unmask, updateMasks } from 'middleware/actions/mask';
import getMasksFromObject from 'utils/helpers/mask-util';
import { deepCopy } from 'utils/helpers/utility';

const customerDetailCommunicator = new CustomerDetailApiCommunicator();
const customerDetailManager = new CustomerDetailManager(customerDetailCommunicator);

function* getCustomerDetailBegin(action) {
  yield put(startProcessing(getCustomerDetail.type));

  try {
    const json = yield customerDetailManager.fetch(action.payload);
    const customer = json.customers[0];
    yield put(getCustomerDetailSuccess(customer));
    yield put(updateMasks(getMasksFromObject(customer)));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(getCustomerDetail.type));
}

export function* getCustomerDetailWatcher() {
  yield takeLeading(getCustomerDetail.type, getCustomerDetailBegin);
}

function* getAllCustomerCasesBegin(action) {
  yield put(startProcessing(getAllCustomerCases.type));

  try {
    const json = yield customerDetailManager.getAllCustomerCases(
      action.payload.uuid,
      action.payload.filters,
    );
    yield put(getAllCustomerCasesSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(getAllCustomerCases.type));
}

export function* getAllCustomerCasesWatcher() {
  yield takeLeading(getAllCustomerCases.type, getAllCustomerCasesBegin);
}

function* getMoreCustomerCasesBegin(action) {
  yield put(startProcessing(getMoreCustomerCases.type));

  try {
    const json = yield customerDetailManager.getMoreCustomerCases(action.payload);
    yield put(getAllCustomerCasesSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(getMoreCustomerCases.type));
}

export function* getMoreCustomerCasesWatcher() {
  yield takeLeading(getMoreCustomerCases.type, getMoreCustomerCasesBegin);
}

function* getAllTransactionCasesBegin(action) {
  yield put(startProcessing(getAllTransactionCases.type));

  try {
    const json = yield customerDetailManager.getAllTransactionCases(
      action.payload.tenantId,
      action.payload.uuid,
      action.payload.filters,
    );
    const caseUuidsToDecrypt = json.cases.map((trxCase) => trxCase.uuid);

    if (action.payload.shouldUnmask) {
      yield put(
        unmask({
          masterUuid: action.payload.uuid,
          displayUuid: action.payload.uuid,
          uuids: caseUuidsToDecrypt,
        }),
      );
    }

    yield put(getAllTransactionCasesSuccess(json));
    yield put(updateMasks(deepCopy(getMasksFromObject(json))));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(getAllTransactionCases.type));
}

export function* getAllTransactionCasesWatcher() {
  yield takeLeading(getAllTransactionCases.type, getAllTransactionCasesBegin);
}

function* getMoreTransactionCasesBegin(action) {
  yield put(startProcessing(getMoreTransactionCases.type));

  try {
    const json = yield customerDetailManager.getMoreTransactionCases(action.payload);
    yield put(getAllTransactionCasesSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(getMoreTransactionCases.type));
}

export function* getMoreTransactionCasesWatcher() {
  yield takeLeading(getMoreTransactionCases.type, getMoreTransactionCasesBegin);
}
