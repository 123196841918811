export const REFERENCE_ACCOUNT_V2_ACTIONS = {
  fetch: {
    data: 'REFERENCE_ACCOUNT_V2_CONFIG_GET',
    success: 'REFERENCE_ACCOUNT_V2_CONFIG_GET_SUCCESS',
    error: 'REFERENCE_ACCOUNT_V2_CONFIG_GET_ERROR',
  },
  update: {
    data: 'REFERENCE_ACCOUNT_V2_CONFIG_UPDATE',
    success: 'REFERENCE_ACCOUNT_V2_CONFIG_UPDATE_SUCCESS',
    error: 'REFERENCE_ACCOUNT_V2_CONFIG_UPDATE_ERROR',
  },
  delete: {
    data: 'REFERENCE_ACCOUNT_V2_CONFIG_DELETE',
    success: 'REFERENCE_ACCOUNT_V2_CONFIG_DELETE_SUCCESS',
    error: 'REFERENCE_ACCOUNT_V2_CONFIG_DELETE_ERROR',
  },
};
