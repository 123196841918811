import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';
import { FlaggingConfigActions } from 'modules/configuration/rule-manager/flagging/middleware/action-types';
import {
  deleteFlaggingConfigError,
  deleteFlaggingConfigSuccess,
  getFlaggingConfigError,
  getFlaggingConfigSuccess,
  updateFlaggingConfigError,
  updateFlaggingConfigSuccess,
} from 'modules/configuration/rule-manager/flagging/middleware/actions/flagging-config';

const communicator = new RuleConfigAPICommunicator('flagging');
const manager = new RuleConfigManager(communicator);

function* getFlaggingConfigBegin(action) {
  yield put(startProcessing(FlaggingConfigActions.FLAGGING_CONFIG_GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getFlaggingConfigSuccess(json));
  } catch (error) {
    yield put(getFlaggingConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(FlaggingConfigActions.FLAGGING_CONFIG_GET));
}

export function* getFlaggingConfigWatcher() {
  yield takeLatest(FlaggingConfigActions.FLAGGING_CONFIG_GET, getFlaggingConfigBegin);
}

function* updateFlaggingConfigBegin(action) {
  yield put(startProcessing(FlaggingConfigActions.FLAGGING_CONFIG_UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateFlaggingConfigSuccess(json));
  } catch (error) {
    yield put(updateFlaggingConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(FlaggingConfigActions.FLAGGING_CONFIG_UPDATE));
}

export function* updateFlaggingConfigWatcher() {
  yield takeLatest(FlaggingConfigActions.FLAGGING_CONFIG_UPDATE, updateFlaggingConfigBegin);
}

function* deleteFlaggingConfigBegin(action) {
  yield put(startProcessing(FlaggingConfigActions.FLAGGING_CONFIG_DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteFlaggingConfigSuccess(json));
  } catch (error) {
    yield put(deleteFlaggingConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(FlaggingConfigActions.FLAGGING_CONFIG_DELETE));
}

export function* deleteFlaggingConfigWatcher() {
  yield takeLatest(FlaggingConfigActions.FLAGGING_CONFIG_DELETE, deleteFlaggingConfigBegin);
}
