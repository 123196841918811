import { addToList } from 'utils/helpers/list-string-util';

export const getCaseIdsFromTransactionHistory = (transactionHistory) => {
  if (!transactionHistory) {
    return undefined;
  }

  const caseIds = [];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const transType in transactionHistory) {
    transactionHistory[transType].forEach((caseDetail) => {
      if (caseDetail.transactions?.length > 0) {
        addToList(caseDetail.uuid, caseIds);
      }
    });
  }

  return caseIds;
};

export const getCaseIdsFromNetworkAnalysis = (networkAnalysis) => {
  if (!networkAnalysis) {
    return undefined;
  }

  const caseIds = [];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const diagramId in networkAnalysis) {
    networkAnalysis[diagramId].forEach((e) => {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const caseId in e.incomeV2) {
        addToList(caseId, caseIds);
      }
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const caseId in e.spendV2) {
        addToList(caseId, caseIds);
      }
    });
  }

  return caseIds;
};

export const getAllCurrentCasesId = (caseId, transactionHistory, networkAnalysis) => {
  const caseIds = [caseId];

  const caseIdsFromTransactionHistory = getCaseIdsFromTransactionHistory(transactionHistory);
  if (Array.isArray(caseIdsFromTransactionHistory) && caseIdsFromTransactionHistory.length > 0) {
    caseIdsFromTransactionHistory.forEach((caseId) => {
      addToList(caseId, caseIds);
    });
  }

  const caseIdsFromNetworkAnalysis = getCaseIdsFromNetworkAnalysis(networkAnalysis);
  if (Array.isArray(caseIdsFromNetworkAnalysis) && caseIdsFromNetworkAnalysis.length > 0) {
    caseIdsFromNetworkAnalysis.forEach((caseId) => {
      addToList(caseId, caseIds);
    });
  }

  return caseIds;
};
