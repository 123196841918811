import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';

export const getCaseDetail = (uuid, tenantId) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.data,
  payload: {
    uuid,
    tenantId,
  },
});

export const getCaseDetailSuccess = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.success,
  payload,
});

export const getCaseDetailError = () => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.error,
});

export const resetSelectedCaseDetail = () => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.reset,
});
