import config from 'utils/helpers/config-utils/config';
import ErrorCode from 'utils/constants/error-code';
import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';

class AuthAPICommunicator extends APICommunicator {
  fetchUsers() {
    const apiUrl = `${config.auth0BaseUrl}/users`;
    return this.getJSONForTenant(apiUrl, ErrorCode.AUTH0_API_NOT_AVAILABLE);
  }

  getPermissionsAssociatedToUser() {
    const apiUrl = `${config.auth0BaseUrl}/users/current/permissions`;
    return this.getJSONForTenant(apiUrl, ErrorCode.AUTH0_API_NOT_AVAILABLE);
  }

  refreshCache() {
    const apiUrl = `${config.auth0BaseUrl}/caching/reset`;
    return this.getJSONForTenant(apiUrl, ErrorCode.AUTH0_API_NOT_AVAILABLE);
  }

  getDetachmentConfigByTenant(tenantId) {
    const apiUrl = `${config.configurationUrl}/config/detachment`;
    return this.getJSONForCustomTenant(apiUrl, tenantId, ErrorCode.DETACHMENT_CONFIG_MISSING);
  }

  loginWithDatabase(username, pwd, tenantId) {
    const apiUrl = API_URLS.authService.login.database;
    return this.postJSON(apiUrl, { username, pwd, tenantId }, ErrorCode.AUTH0_API_NOT_AVAILABLE);
  }

  loginWithSso(email, picture, nickname, accessToken, tenantId) {
    const apiUrl = API_URLS.authService.login.sso;
    return this.postJSONWithAuthorizationHeader(
      apiUrl,
      accessToken,
      { email, picture, nickname, tenantId },
      ErrorCode.AUTH0_API_NOT_AVAILABLE,
    );
  }

  requestResetPassword(emailAddress) {
    const apiUrl = `${config.auth0BaseUrl}/resetPassword`;
    return this.postJSON(apiUrl, { emailAddress }, ErrorCode.AUTH0_API_NOT_AVAILABLE);
  }
}

export default AuthAPICommunicator;
