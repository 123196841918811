import { PLAUSIBILITY_ACTIONS } from 'middleware/action-types';

const plausibilityReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAUSIBILITY_ACTIONS.fetch.data:
    case PLAUSIBILITY_ACTIONS.fetch.error: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case PLAUSIBILITY_ACTIONS.fetch.success:
    case PLAUSIBILITY_ACTIONS.update.success:
    case PLAUSIBILITY_ACTIONS.delete.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default plausibilityReducer;
