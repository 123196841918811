import { useContext, useEffect, useState } from 'react';
import CustomerCaseDetailContext from 'modules/customer-cases/case-detail/contexts/customer-case-detail/customer-case-detail-context';

const tabView: { [k: string]: { key: string; back?: string } } = {
  general: {
    key: 'general',
  },
  characteristics: {
    key: 'characteristics',
    back: 'general',
  },
  customerRelationshipDetails: {
    key: 'customerRelationshipDetails',
    back: 'general',
  },
  doingBusinessAs: {
    key: 'doingBusinessAs',
    back: 'general',
  },
};

const useCustomerDataView = () => {
  const { customerDataCurrentView, setCustomerDataCurrentView } =
    useContext(CustomerCaseDetailContext) || {};

  const [currentView, setCurrentView] = useState(customerDataCurrentView || tabView.general);

  useEffect(() => {
    if (setCustomerDataCurrentView) {
      setCustomerDataCurrentView(currentView);
    }
  }, [currentView, setCustomerDataCurrentView]);

  return {
    currentView,
    setCurrentView,
    tabView,
  };
};

export { useCustomerDataView, tabView };
