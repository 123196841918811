import ConfigPropagationAPICommunicator from 'services/config-propagation-api-communicator';
import { Tenant } from 'models/tenant/tenant';
import { CopyReport } from 'modules/configuration/config-propagation/model';

class ConfigPropagationManager {
  communicator: ConfigPropagationAPICommunicator;

  constructor(communicator: ConfigPropagationAPICommunicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Config Propagation API Communicator instance is required.');
    }
  }

  fetchAllowedTenants = (): Promise<Tenant[]> => this.communicator.fetchAllowedTenants();

  copyInstance = (
    targetTenants: string[],
    ruleName: string,
    instanceId: string,
  ): Promise<CopyReport> => this.communicator.copyInstance(targetTenants, ruleName, instanceId);
}

export default ConfigPropagationManager;
