import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import AuditTrailApiCommunicator from 'modules/audit-trail/services/audit-trail-api-communicator';
import AudiTrailManager from 'modules/audit-trail/services/audit-trail-manager';
import { getDataProviderAuditTrailSuccess } from 'modules/configuration/list-manager/data-provider-list/reducers/data-provider-audit-trail-reducer';

const auditTrailCommunicator = new AuditTrailApiCommunicator();
const auditTrailManager = new AudiTrailManager(auditTrailCommunicator);

function* getDataProviderAuditTrailBegin(action) {
  yield put(startProcessing('dataProviderAuditTrail/getDataProviderAuditTrail'));
  try {
    const { paging, sorting, filtering, customHeader, tenantId } = action.payload;

    const json = yield auditTrailManager.searchAuditTrails(
      paging,
      sorting,
      filtering,
      customHeader,
      tenantId,
    );

    const oneDaysAgo = new Date();
    oneDaysAgo.setDate(new Date().getDate() - 3);

    const additionalAuditLogs = yield auditTrailManager.searchAuditTrails(
      paging,
      sorting,
      {
        filters: [
          {
            field: 'createdAt',
            condition: 'INCLUSIVE_DATE_RANGE',
            rangeStart: oneDaysAgo.toISOString(),
            rangeEnd: new Date().toISOString(),
          },
          {
            field: 'event',
            condition: 'TEXT_EQUAL',
            values: ['LEXIS_NEXIS_NO_FILE'],
          },
        ],
        operator: 'AND',
      },
      customHeader,
      tenantId,
    );
    json.auditTrails = [...json.auditTrails, ...additionalAuditLogs.auditTrails];
    yield put(getDataProviderAuditTrailSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('dataProviderAuditTrail/getDataProviderAuditTrail'));
}

export function* getDataProviderAuditTrailWatcher() {
  yield takeLatest(
    'dataProviderAuditTrail/getDataProviderAuditTrail',
    getDataProviderAuditTrailBegin,
  );
}
