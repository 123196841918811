import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

/* eslint-disable @typescript-eslint/no-explicit-any */

type ObjectWithLabel = { label: string };

export type OptionsFilterFn<T extends ObjectWithLabel = ObjectWithLabel> = (
  optionsArray: T[],
  filteringValue: string,
) => T[];

interface TextSearchProps {
  optionsArray: any[];
  onInputChange: (value: any[]) => void;
  placeholder?: any;
  resultsI18nKey?: string;
  handleKeyDown?: any;
  open?: any;
  toggleOpen?: (value: boolean) => void;
  hideClearCross?: boolean;
  fontSize?: number | string | undefined;
  optionsFilter?: OptionsFilterFn;
  dataTestIdPrefix?: string;
}

const defaultOptionFilter: OptionsFilterFn = (
  optionsArray: { label: string }[],
  filteringValue: string,
) => {
  const lwValue = filteringValue.toLowerCase();
  return optionsArray.filter((option: any) => option.label.toLowerCase().includes(lwValue));
};

type AutoCompleteOnInputChange = (
  event: React.SyntheticEvent,
  value: string,
  reason: AutocompleteInputChangeReason,
) => void;

const TextSearch = ({
  optionsArray,
  onInputChange,
  placeholder,
  resultsI18nKey = 'search',
  handleKeyDown,
  open = false,
  toggleOpen,
  hideClearCross,
  fontSize,
  optionsFilter = defaultOptionFilter,
  dataTestIdPrefix = 'text-search',
}: TextSearchProps) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('');
  const [numHits, setNumHits] = useState(optionsArray.length);

  useEffect(() => {
    setNumHits(optionsArray.length);
  }, [optionsArray]);

  const filterItems = (value: string) => {
    let tempOptions = optionsArray;

    if (value) {
      tempOptions = optionsFilter(optionsArray, value);
    }
    setNumHits(tempOptions.length);
    const filteredOptions = tempOptions;

    onInputChange(filteredOptions);
  };

  const handleChange: AutoCompleteOnInputChange = (event: React.SyntheticEvent, value: string) => {
    setSelectedValue(value ?? '');
    filterItems(value);
  };

  const resultsTextSwitch = () => {
    switch (numHits) {
      case 0:
        return t(`configuration:ruleManager.common.${resultsI18nKey}.results.noResults.heading`);
      case 1:
        return t(`configuration:ruleManager.common.${resultsI18nKey}.results.oneResult.heading`);

      default:
        return t(`configuration:ruleManager.common.${resultsI18nKey}.results.manyResults.heading`, {
          numHits,
        });
    }
  };

  const inputProps = (params: any) => {
    const props = {
      ...params.InputProps,
      onKeyDown: (e: any) => (handleKeyDown ? handleKeyDown(e) : null),
      inputProps: {
        ...params.inputProps,
        style: { fontSize },
        'data-testid': `${dataTestIdPrefix}-text-field-value`,
      },
      startAdornment: (
        <>
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
          {params.InputProps.startAdornment}
        </>
      ),
    };

    if (hideClearCross) {
      props.endAdornment = null;
    }
    return props;
  };

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          options={optionsArray}
          value={selectedValue}
          onInputChange={handleChange}
          open={open}
          onOpen={() => (toggleOpen ? toggleOpen(true) : null)}
          onClose={() => (toggleOpen ? toggleOpen(false) : null)}
          renderOption={(props, option: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="standard"
              placeholder={
                placeholder ?? t(`configuration:ruleManager.common.${resultsI18nKey}.heading`)
              }
              data-testid={`${dataTestIdPrefix}-text-field`}
              InputProps={inputProps(params)}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Typography color="textSecondary" variant="caption">
          {resultsTextSwitch()}
        </Typography>
      </Grid>
    </>
  );
};

export default TextSearch;
