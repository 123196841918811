import Strings from 'utils/helpers/locales/strings.en';
import { CaseStatuses } from 'models/case/case-state/case-status';

const CaseStatusFilters = {
  MY_CASES: {
    key: 'my_cases',
    values: [CaseStatuses.MY_CASES],
    title: Strings.Sidebar['My Cases'],
  },
  OPEN: {
    key: 'open',
    values: [CaseStatuses.OPEN],
    title: Strings.Sidebar['Open Cases'],
  },
  CLOSED: {
    key: 'closed',
    values: [CaseStatuses.CLOSED],
    title: Strings.Sidebar['Closed Cases'],
  },
  AUTO_CLOSED: {
    key: 'auto_closed',
    values: [CaseStatuses.AUTO_CLOSED],
    title: Strings.Sidebar['Automatically Closed Cases'],
  },
  NOT_OPENED: {
    key: 'not_opened',
    values: [CaseStatuses.NOT_OPENED],
    title: Strings.Sidebar['Not Opened Cases'],
  },
  BEING_PROCESSED: {
    key: 'being_processed',
    values: [CaseStatuses.OPENING, CaseStatuses.COMPUTING],
    title: Strings.Sidebar['Cases Being Processed'],
  },
  ESCALATED: {
    key: 'escalated',
    values: [CaseStatuses.ESCALATED],
    title: Strings.Sidebar['Escalated Cases'],
  },
  AUTO_CLOSED_REVIEW: {
    key: 'auto_closed_review',
    values: [CaseStatuses.AUTO_CLOSED_REVIEW],
    title: Strings.Sidebar['Auto-Closed review'],
  },
  ANY_CASES: {
    key: 'any',
    values: [CaseStatuses.ANY],
    title: Strings.Sidebar.MENU,
  },
  FRAUD_CASES: {
    key: 'fraud',
    values: [CaseStatuses.FRAUD],
    title: Strings.Sidebar['Fraud Cases'],
  },
  PRIORITY: {
    key: 'priority',
    values: [CaseStatuses.OPEN],
    title: Strings.Sidebar['Priority Cases'],
  },
};

export default CaseStatusFilters;

export const getCaseStatusFilter = (caseStatus) => {
  const keys = Object.keys(CaseStatusFilters);
  for (let i = 0; i < keys.length; i++) {
    const caseStatusFilter = CaseStatusFilters[keys[i]];
    if (caseStatusFilter.values.includes(caseStatus)) {
      return caseStatusFilter;
    }
  }
  return undefined;
};
