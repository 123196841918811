import { COUNTRY_RISK_V2_ACTIONS } from 'modules/configuration/rule-manager/country-config-v2/middleware/action-types';

export const getCountryRiskV2Config = (payload) => ({
  type: COUNTRY_RISK_V2_ACTIONS.fetch.data,
  payload,
});

export const getCountryRiskV2ConfigSuccess = (payload) => ({
  type: COUNTRY_RISK_V2_ACTIONS.fetch.success,
  payload,
});

export const getCountryRiskV2ConfigError = () => ({
  type: COUNTRY_RISK_V2_ACTIONS.fetch.error,
});

export const updateCountryRiskV2Config = (payload) => ({
  type: COUNTRY_RISK_V2_ACTIONS.update.data,
  payload,
});

export const updateCountryRiskV2ConfigSuccess = (payload) => ({
  type: COUNTRY_RISK_V2_ACTIONS.update.success,
  payload,
});

export const updateCountryRiskV2ConfigError = () => ({
  type: COUNTRY_RISK_V2_ACTIONS.update.error,
});

export const deleteCountryRiskV2Config = (payload) => ({
  type: COUNTRY_RISK_V2_ACTIONS.delete.data,
  payload,
});

export const deleteCountryRiskV2ConfigSuccess = (payload) => ({
  type: COUNTRY_RISK_V2_ACTIONS.delete.success,
  payload,
});

export const deleteCountryRiskV2ConfigError = () => ({
  type: COUNTRY_RISK_V2_ACTIONS.delete.error,
});
