import { KeyValueHeading } from 'components/filters/models/filter';

export interface RequiredPermissionsConfig {
  [key: string]: Array<string>;
}

const getRequiredPermissions = (
  status: string,
  requiredPermissionsConfig: RequiredPermissionsConfig,
): Array<string> => requiredPermissionsConfig[status];

const hasAllRequiredPermissions = (
  requiredPermissions: Array<string>,
  userPermissions: Array<string>,
) =>
  !requiredPermissions ||
  requiredPermissions.length === 0 ||
  requiredPermissions.every((requiredPermission) => userPermissions?.includes(requiredPermission));

export const filterOutStatusesByPermissions = (
  statuses: Array<KeyValueHeading>,
  requiredPermissionsConfig: RequiredPermissionsConfig,
  userPermissions: Array<string>,
) =>
  statuses.filter((i) =>
    hasAllRequiredPermissions(
      getRequiredPermissions(i.key.toLowerCase(), requiredPermissionsConfig),
      userPermissions,
    ),
  );
