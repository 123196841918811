import { Box, Divider, Grid, Chip } from '@mui/material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useForm, Form } from 'hooks/use-form';
import Controls from 'components/controls/controls';

const buildCaseIdsValuesFromUrl = (filter) => ({
  field: i18next.t('mapping:filters.filtering.caseIds.key'),
  condition: i18next.t(`mapping:operator.text.${filter.condition}.key`),
  values: filter.values,
});

const CaseIdForm = ({ editForm, onSubmit, filterKey }) => {
  const { t } = useTranslation();
  const initialFValues = {
    caseIds: editForm?.values || [],
  };

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define, consistent-return
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    if ('caseIds' in fieldValues) {
      temp.caseIds = fieldValues.caseIds?.length ? '' : t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: filterKey,
        values: values.caseIds,
      });
    }
  };
  return (
    <Grid container>
      <Form className="w-100">
        <Grid container item className="px-3 py-3" spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                dataTestIdPrefix="filter-by-case-id"
                name="caseIds"
                label={t('condition.equal.heading')}
                value={values.caseIds}
                onChange={handleInputChange}
                error={errors.caseIds}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            dataTestIdPrefix="filter-by-case-id"
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};

const CaseIdChip = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.caseIds.value.heading')}
      </Box>
      {form.values.map((item, index) => (
        <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
      ))}
    </>
  );
};

export { CaseIdForm, CaseIdChip, buildCaseIdsValuesFromUrl };
