import { TFunction } from 'react-i18next';

interface EnabledFiltersProps {
  t: TFunction;
  isCategoryAttached: boolean;
  isInvestigationStatusAttached: boolean;
  canSeeAssignedUser: boolean;
  excludedFilters: string[];
  alertPrioritization: {
    isAnomalyScoreEnabled?: boolean;
    isAutoCloseScoreEnabled?: boolean;
  };
  canSeeInvestigationOutcome: boolean;
  caseNotificationRuleInstanceFilterEnabled?: boolean;
  isAutoCloseRelabelAttached?: boolean;
}

export const getEnabledFilters = ({
  t,
  isCategoryAttached = false,
  isInvestigationStatusAttached = false,
  canSeeAssignedUser = false,
  excludedFilters = [],
  alertPrioritization = {},
  canSeeInvestigationOutcome = false,
  caseNotificationRuleInstanceFilterEnabled = false,
  isAutoCloseRelabelAttached = false,
}: EnabledFiltersProps) => {
  const filters = [
    t('mapping:filters.filtering.updatedAt.key'),
    t('mapping:filters.filtering.escalatedAt.key'),
    t('mapping:filters.filtering.createdAt.key'),

    t('mapping:filters.filtering.totalAmount.key'),
    t('mapping:filters.filtering.unassigned.key'),
    t('mapping:filters.filtering.tenantTransactionIds.key'),
    t('mapping:filters.filtering.tenantId.key'),
    t('mapping:filters.filtering.types.key'),
    t('mapping:filters.filtering.paymentMeans.key'),
    t('mapping:filters.filtering.flows.key'),
    t('mapping:filters.filtering.transactionStatusesV2.key'),
    t('mapping:filters.filtering.processingStatusesV2.key'),
    t('mapping:filters.filtering.riskLevels.key'),
    t('mapping:filters.filtering.caseStatuses.key'),
    t('mapping:filters.filtering.processedAts.key'),
    t('mapping:filters.filtering.ruleInstances.key'),

    t('mapping:filters.filtering.caseIds.key'),
    t('mapping:filters.filtering.accountIds.key'),
    t('mapping:filters.filtering.accountNumbers.key'),

    t('mapping:filters.filtering.customer.key'),
    t('mapping:filters.filtering.customerId.key'),
    t('mapping:filters.filtering.customerGroupIds.key'),
    t('mapping:filters.filtering.intermediaryName.key'),
    t('mapping:filters.filtering.customerCategory.key'),
    t('mapping:filters.filtering.additionalIds.key'),
    t('mapping:filters.filtering.channels.key'),
    t('mapping:filters.filtering.orderIds.key'),
    t('mapping:filters.filtering.executionScopes.key'),
    t('mapping:filters.filtering.beneficiariesBankCountries.key'),
    t('mapping:filters.filtering.originatorsBankCountries.key'),
  ];

  if (caseNotificationRuleInstanceFilterEnabled) {
    filters.push(t('mapping:filters.filtering.caseNotificationRuleInstanceIds.key'));
  }
  if (isCategoryAttached) {
    filters.push(t('mapping:filters.filtering.ruleCategories.key'));
  }
  if (isInvestigationStatusAttached) {
    filters.push(t('mapping:filters.filtering.investigationStatuses.key'));
  }
  if (canSeeAssignedUser) {
    filters.push(t('mapping:filters.filtering.assignedToUserId.key'));
  }
  if (alertPrioritization.isAnomalyScoreEnabled) {
    filters.push(t('mapping:filters.filtering.anomalyScoreMax.key'));
  }
  if (alertPrioritization.isAutoCloseScoreEnabled) {
    if (isAutoCloseRelabelAttached) {
      filters.push(t('mapping:filters.filtering.falsePositiveReductionScore.key'));
    } else filters.push(t('mapping:filters.filtering.autoCloseScoreMin.key'));
  }
  if (canSeeInvestigationOutcome) {
    filters.push(t('mapping:filters.filtering.investigationOutcome.key'));
  }
  return filters.filter((item) => !excludedFilters.includes(item));
};

export const nonDeletableFilters = (t: TFunction) => [t('mapping:filters.filtering.updatedAt.key')];
