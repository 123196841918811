class CustomerDetailsManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Customer Details API Communicator instance is required');
    }
  }

  getCustomerDetails(caseId) {
    return this.communicator.getCustomerDetails(caseId).then((json) => Promise.resolve(json));
  }
}

export default CustomerDetailsManager;
