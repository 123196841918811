import { equals } from 'utils/helpers/list-string-util';
import { trimString } from 'utils/helpers/string-util';
import i18next from 'i18next';

export const areFiltersEqual = (filter1, filter2) =>
  filter1?.name === filter2?.name &&
  equals(filter1?.models, filter2?.models) &&
  filter1?.operator === filter2?.operator &&
  equals(filter1?.filterValues, filter2?.filterValues);

export const isValueValid = (value) => !!value?.trim();

export const areValuesValid = (values) => {
  if (!Array.isArray(values) || values.length === 0) return false;
  const idx = values.findIndex((value) => !isValueValid(value));
  return idx < 0;
};

export const trimValues = (values) => values?.map((value) => trimString(value));

export const getRawValues = (filterValues) =>
  filterValues?.map((filterValue) => filterValue.rawValue);

export const normalizeFilter = (filter) => ({
  ...filter,
  filterValues: filter?.filterValues?.map((filterValue) => ({
    rawValue: trimString(filterValue.rawValue),
  })),
});

export const normalizeFilters = (filters) => filters?.map((filter) => normalizeFilter(filter));

const getRawValueFieldTypeBySelectedOperator = (selectedFieldName, selectedOperator) => {
  const operatorConfigs = Object.values(i18next.t('mapping:dynamicFilter.operator'));
  const operatorConfig = operatorConfigs.find((oc) => oc.key === selectedOperator);
  return operatorConfig?.valueFieldType;
};

export const isListTypeField = (filterConfigs, selectedField) =>
  filterConfigs?.find((df) => df.name === selectedField)?.type === 'list';

export const getValueFieldTypeBySelectedOperator = (
  filterConfigs,
  selectedFieldName,
  selectedOperator,
) => {
  const isList = isListTypeField(filterConfigs, selectedFieldName);
  if (selectedOperator === 'is' && isList) {
    return 'multi';
  }
  return getRawValueFieldTypeBySelectedOperator(selectedFieldName, selectedOperator);
};

const isFilterValueRequired = (filter) => {
  const valueFieldType = getRawValueFieldTypeBySelectedOperator(filter?.name, filter?.operator);
  return valueFieldType !== 'none';
};

export const isFilterValid = (filter) => {
  if (!filter?.name) return false;
  if (!(filter?.models?.length > 0)) return false;
  if (!filter?.operator) return false;

  const filtersListSelected = filter?.listInstanceConfig?.uniqueId?.length > 0;

  return (
    !isFilterValueRequired(filter) ||
    areValuesValid(getRawValues(filter?.filterValues)) ||
    filtersListSelected
  );
};

export const areFiltersValid = (filters) => {
  const invalidFilter = filters?.find((f) => !isFilterValid(f));
  return !invalidFilter;
};

export const getFilterValueSuffix = (filterName) => {
  const filterNameToSuffixMap = new Map([
    ['customerSince', i18next.t('mapping:dynamicFilter.suffix.days.value.heading')],
  ]);

  return filterNameToSuffixMap.get(filterName) ?? '';
};

export const formatDynamicFilterValue = (filterName, filterValue) => {
  let formattedValue = filterValue;
  const suffix = getFilterValueSuffix(filterName);
  if (suffix) {
    formattedValue += ` ${suffix}`;
  }
  return formattedValue;
};

export const excludeFieldsFromFilterConfigs = (filterConfigs, ignoreFields) =>
  filterConfigs?.filter((df) => !ignoreFields?.includes(df.name)) ?? [];

export const getModelsBySelectedField = (filterConfigs, selectedField) =>
  filterConfigs?.find((df) => df.name === selectedField)?.models ?? [];

export const getOperatorsBySelectedField = (filterConfigs, selectedField) =>
  filterConfigs?.find((df) => df.name === selectedField)?.operators ?? [];
