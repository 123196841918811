import { AUTH_ACTIONS } from 'modules/authentication/login/middleware/action-types';
import { TENANT_CONFIG_ACTIONS } from 'modules/configuration/workflows-and-users/middleware/action-types';

export const loginWithSsoStart = (email, picture, nickname, accessToken, tenantId) => ({
  type: AUTH_ACTIONS.withSso.login,
  email,
  picture,
  nickname,
  accessToken,
  tenantId,
});

export const loginWithSsoSuccess = (payload) => ({
  type: AUTH_ACTIONS.withSso.success,
  payload,
});

export const loginWithSsoError = (error) => ({
  type: AUTH_ACTIONS.withSso.error,
  payload: error,
});

export const logoutWithSsoSuccess = () => ({
  type: AUTH_ACTIONS.withSso.logout,
});

export const loginWithDatabaseStart = (username, pwd, tenantId) => ({
  type: AUTH_ACTIONS.withDatabase.login,
  username,
  pwd,
  tenantId,
});

export const loginWithDatabaseSuccess = (payload) => ({
  type: AUTH_ACTIONS.withDatabase.success,
  payload,
});

export const loginWithDatabaseError = (error) => ({
  type: AUTH_ACTIONS.withDatabase.error,
  payload: error,
});

export const logoutWithDatabaseSuccess = () => ({
  type: AUTH_ACTIONS.withDatabase.logout,
});

export const setUserInfos = (payload) => ({
  type: AUTH_ACTIONS.userInfos.set,
  payload,
});

export const getPlatformViewingConfig = () => ({
  type: AUTH_ACTIONS.platform.viewingConfig,
});

export const getPlatformViewingConfigError = (error) => ({
  type: AUTH_ACTIONS.platform.error,
  payload: error,
});

export const getUserPermissions = () => ({
  type: AUTH_ACTIONS.permissions.get,
});

export const getUserPermissionsSuccess = (payload) => ({
  type: AUTH_ACTIONS.permissions.success,
  payload,
});

export const getUserPermissionsError = (error) => ({
  type: AUTH_ACTIONS.permissions.error,
  payload: error,
});

export const getDetachmentConfig = (payload) => ({
  type: AUTH_ACTIONS.detachmentConfig.get,
  payload,
});

export const getDetachmentConfigSuccess = (payload) => ({
  type: AUTH_ACTIONS.detachmentConfig.success,
  payload,
});

export const getDetachmentConfigError = (error) => ({
  type: AUTH_ACTIONS.detachmentConfig.error,
  payload: error,
});

export const getOperatorList = () => ({
  type: AUTH_ACTIONS.operatorList.get,
});

export const getOperatorListSuccess = (payload) => ({
  type: AUTH_ACTIONS.operatorList.success,
  payload,
});

export const getOperatorListError = (error) => ({
  type: AUTH_ACTIONS.operatorList.error,
  payload: error,
});

export const refreshCache = () => ({
  type: TENANT_CONFIG_ACTIONS.REFRESH_CACHE,
});

export const refreshCacheSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.REFRESH_CACHE_SUCCESS,
  payload,
});

export const refreshCacheError = (error) => ({
  type: TENANT_CONFIG_ACTIONS.REFRESH_CACHE_ERROR,
  payload: error,
});
