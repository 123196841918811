import { DormantAccountConfigActions } from 'modules/configuration/rule-manager/dormant-account/middleware/action-types';

const dormantAccountConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case DormantAccountConfigActions.GET_SUCCESS:
    case DormantAccountConfigActions.UPDATE_SUCCESS:
    case DormantAccountConfigActions.DELETE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default dormantAccountConfigReducer;
