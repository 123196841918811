import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteAccountBasedFrequencyConfigError,
  deleteAccountBasedFrequencyConfigSuccess,
  getAccountBasedFrequencyConfigError,
  getAccountBasedFrequencyConfigSuccess,
  updateAccountBasedFrequencyConfigError,
  updateAccountBasedFrequencyConfigSuccess,
} from 'modules/configuration/rule-manager/account-based-frequency/middleware/actions/account-based-frequency-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';
import { AccountBasedFrequencyConfigActions } from 'modules/configuration/rule-manager/account-based-frequency/middleware/action-types';

const communicator = new RuleConfigAPICommunicator('account-based-frequency');
const manager = new RuleConfigManager(communicator);

function* getAccountBasedFrequencyConfigBegin(action) {
  yield put(startProcessing(AccountBasedFrequencyConfigActions.GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getAccountBasedFrequencyConfigSuccess(json));
  } catch (error) {
    yield put(getAccountBasedFrequencyConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(AccountBasedFrequencyConfigActions.GET));
}

export function* getAccountBasedFrequencyConfigWatcher() {
  yield takeLatest(AccountBasedFrequencyConfigActions.GET, getAccountBasedFrequencyConfigBegin);
}

function* updateAccountBasedFrequencyConfigBegin(action) {
  yield put(startProcessing(AccountBasedFrequencyConfigActions.UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateAccountBasedFrequencyConfigSuccess(json));
  } catch (error) {
    yield put(updateAccountBasedFrequencyConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(AccountBasedFrequencyConfigActions.UPDATE));
}

export function* updateAccountBasedFrequencyConfigWatcher() {
  yield takeLatest(
    AccountBasedFrequencyConfigActions.UPDATE,
    updateAccountBasedFrequencyConfigBegin,
  );
}

function* deleteAccountBasedFrequencyConfigBegin(action) {
  yield put(startProcessing(AccountBasedFrequencyConfigActions.DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteAccountBasedFrequencyConfigSuccess(json));
  } catch (error) {
    yield put(deleteAccountBasedFrequencyConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(AccountBasedFrequencyConfigActions.DELETE));
}

export function* deleteAccountBasedFrequencyConfigWatcher() {
  yield takeLatest(
    AccountBasedFrequencyConfigActions.DELETE,
    deleteAccountBasedFrequencyConfigBegin,
  );
}
