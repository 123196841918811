import { ToolingActions, TOOLING_ACTIONS } from 'middleware/action-types';

const toolingReducer = (state = {}, action) => {
  switch (action.type) {
    case ToolingActions.REPROCESS_CASE_POST_SUCCESS:
    case ToolingActions.REPROCESS_CASE_POST_ERROR:
      return {
        ...state,
        reprocessingResponse: action.payload,
      };

    case TOOLING_ACTIONS.query.send:
    case TOOLING_ACTIONS.reprocess.send:
      return {
        ...state,
        reprocessingResponseV2: {},
      };

    case TOOLING_ACTIONS.query.success:
    case TOOLING_ACTIONS.reprocess.success:
      const reprocessingResponseV2 = action.payload ?? {};
      const { data } = reprocessingResponseV2;
      return {
        ...state,
        reprocessingResponseV2: {
          data: data ? JSON.parse(data) : undefined,
          errorMessage: reprocessingResponseV2.errorMessage,
        },
      };

    default:
      return state;
  }
};

export default toolingReducer;
