import { MASKED_ACTIONS } from 'middleware/action-types';
import { Mask } from 'models/mask';
import { Reducer } from 'redux';
import LocalStorageService from 'services/local-storage-service';
import { addListToList } from 'utils/helpers/list-string-util';

const localStorageService = new LocalStorageService();

const copyMaskData = (s: Mask, d: Mask) => {
  if (s.masked) {
    d.masked = s.masked;
  }

  if (s.decrypted) {
    d.unmasked = s.decrypted;
  }
};

interface MaskedState {
  all: Mask[];
  unmaskedTokens: string[];
  unmaskedCaseIds: string[];
  casesDataShown: boolean;
  caseDetailDataShown: boolean;
}

const initialState: MaskedState = {
  all: [],
  unmaskedTokens: [],
  unmaskedCaseIds: [],
  casesDataShown: false,
  caseDetailDataShown: false,
};

const maskReducer: Reducer<MaskedState> = (state = initialState, action) => {
  const all = state.all || [];
  switch (action.type) {
    case MASKED_ACTIONS.unmask.success:
    case MASKED_ACTIONS.unmask.update: {
      const newMasks = [...all];
      const payload = action.payload || [];
      const unmaskedTokens = state.unmaskedTokens || [];

      if (payload.token) {
        unmaskedTokens.push(payload.token);
      }

      payload.forEach((payloadItem: { token: string }) => {
        const alreadyInMaskObject = newMasks.find((i) => i.token === payloadItem.token);
        if (alreadyInMaskObject) {
          copyMaskData(payloadItem, alreadyInMaskObject);
        } else {
          copyMaskData(payloadItem, payloadItem);
          newMasks.push(payloadItem);
        }
      });

      return {
        ...state,
        all: newMasks,
        unmaskedTokens,
      };
    }

    case MASKED_ACTIONS.unmaskCases.success: {
      const unmaskedCaseIds = state.unmaskedCaseIds || [];
      const newList = action.payload || [];
      addListToList(unmaskedCaseIds, newList);
      return {
        ...state,
        unmaskedCaseIds: newList,
        all,
      };
    }

    case MASKED_ACTIONS.dataShowState.cases: {
      const { payload } = action;
      return {
        ...state,
        casesDataShown: payload,
        all,
      };
    }

    case MASKED_ACTIONS.dataShowState.caseDetail: {
      const { payload } = action;
      return {
        ...state,
        caseDetailDataShown: payload,
        all,
      };
    }

    default:
      return {
        ...state,
        casesDataShown: localStorageService.getCasesDataShownState() === 'true',
        caseDetailDataShown: localStorageService.getCaseDetailDataShownState() === 'true',
        all,
      };
  }
};

export default maskReducer;
