import { useSelector } from 'react-redux';

import ToastMessage from 'components/notifications/toast-message';

const Notifications = () => {
  const notifications = useSelector((state) => state.notification.notifications || []);
  const toastList = [];
  notifications.map((notification, idx) =>
    toastList.push(<ToastMessage key={idx} data={notification} index={idx} />),
  );

  toastList.reverse();
  return <div>{toastList}</div>;
};

export default Notifications;
