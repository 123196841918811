import { Box, Divider, Grid, InputAdornment, FormHelperText } from '@mui/material';
import i18next from 'i18next';
import { Translation, useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useForm, Form } from 'hooks/use-form';
import Controls from 'components/controls/controls';

const buildRiskPointsValuesFromUrl = (filter) => ({
  field: i18next.t('mapping:filters.filtering.totalPoints.key'),
  condition: i18next.t(`mapping:operator.number.${filter.condition}.key`),
  rangeStart: filter.rangeStart,
  rangeEnd: filter.rangeEnd,
});

const RiskPointsForm = ({ editForm, onSubmit }) => {
  const initialFValues = {
    rangeStart: editForm?.rangeStart || '',
    rangeEnd: editForm?.rangeEnd || '',
  };
  const { t } = useTranslation();

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    if ('rangeStart' in fieldValues) {
      // eslint-disable-next-line no-use-before-define
      if (!fieldValues?.rangeStart && !values.rangeEnd) {
        temp.rangeStart = t('required.subheading');
      } else {
        temp.rangeStart = '';
        temp.rangeEnd = '';
      }
    } else if ('rangeEnd' in fieldValues) {
      // eslint-disable-next-line no-use-before-define
      if (!fieldValues?.rangeEnd && !values.rangeStart) {
        temp.rangeEnd = t('required.subheading');
      } else {
        temp.rangeStart = '';
        temp.rangeEnd = '';
      }
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.number.INCLUSIVE_INT_RANGE.key'),
        field: t('mapping:filters.filtering.totalPoints.key'),
        rangeStart: values.rangeStart,
        rangeEnd: values.rangeEnd,
      });
    }
  };
  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="px-3 py-3" spacing={0.5}>
          <Grid container item xs={12} justifyContent="center" alignItems="center" wrap="nowrap">
            <Controls.Input
              name="rangeStart"
              type="number"
              label={t('from.subheading')}
              value={values.rangeStart}
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment position="end">
                  {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
                </InputAdornment>
              }
            />

            <ArrowForwardIcon fontSize="small" color="action" className="mx-1" />

            <Controls.Input
              name="rangeEnd"
              type="number"
              label={t('to.text')}
              value={values.rangeEnd}
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment position="end">
                  {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
                </InputAdornment>
              }
            />
          </Grid>

          {(errors.rangeStart || errors.rangeEnd) && (
            <Grid item>
              <FormHelperText error>{errors.rangeStart ?? errors.rangeEnd}</FormHelperText>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
        </Grid>
      </Form>
    </Grid>
  );
};

const RiskPointsChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.totalPoints.value.heading')}
        </Box>

        <Box fontSize="body2.fontSize">
          {form.rangeStart ? form.rangeStart : '0'}{' '}
          {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
          <ArrowForwardIcon fontSize="small" color="action" className="mx-1" />
          {form.rangeEnd
            ? form.rangeEnd
            : t('configuration:customerRiskRating.riskFactor.points.maxPoints.subheading')}{' '}
          {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
        </Box>
      </>
    )}
  </Translation>
);

export { RiskPointsForm, RiskPointsChip, buildRiskPointsValuesFromUrl };
