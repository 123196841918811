import { VOLUME_RISK_V2_ACTIONS } from 'modules/configuration/rule-manager/volume-config-v2/middleware/action-types';

const VolumeRiskV2ConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case VOLUME_RISK_V2_ACTIONS.fetch.success:
    case VOLUME_RISK_V2_ACTIONS.update.success:
    case VOLUME_RISK_V2_ACTIONS.delete.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default VolumeRiskV2ConfigReducer;
