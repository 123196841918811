import i18next from 'i18next';

export const isNewCase = (processingStatus) =>
  processingStatus === i18next.t('mapping:processingStatus.NEW.key') ||
  processingStatus === i18next.t('mapping:processingStatusV2.new.key');

export const isEscalatedCase = (processingStatus) =>
  processingStatus === i18next.t('mapping:processingStatus.ESCALATED.key') ||
  processingStatus === i18next.t('mapping:processingStatusV2.escalated.key');

export const isSarFilingCase = (processingStatus) =>
  processingStatus === i18next.t('mapping:processingStatus.SAR-FILING.key') ||
  processingStatus === i18next.t('mapping:processingStatusV2.sar-filing.key');
