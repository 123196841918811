import { DecisionEngineActions } from 'middleware/action-types';

const decisionEngineConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case DecisionEngineActions.GET_DECISION_ENGINE_CONFIG:
    case DecisionEngineActions.GET_DECISION_ENGINE_CONFIG_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG_SUCCESS:
    case DecisionEngineActions.GET_DECISION_ENGINE_CONFIG_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default decisionEngineConfigReducer;
