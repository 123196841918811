import { put, takeEvery } from 'redux-saga/effects';
import { NETWORK_ANALYSIS_ACTION } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getNetworkAnalysisError,
  getNetworkAnalysisSuccess,
  getPassthroughNetworkError,
  getPassthroughNetworkSuccess,
  getPassthroughV2NetworkError,
  getPassthroughV2NetworkSuccess,
  getPassthroughV3NetworkError,
  getPassthroughV3NetworkSuccess,
} from 'middleware/actions/network-analysis';
import { updateMasks } from 'middleware/actions/mask';
import {
  getMaskedsFromNetworkAnalysis,
  getMaskedsFromPassthroughV3,
} from 'utils/helpers/cases/case-utility';
import { deepCopy } from 'utils/helpers/utility';
import NetworkAnalysisAPICommunicator from 'services/network-analysis-api-communicator';
import NetworkAnalysisManager from 'services/network-analysis-manager';

const manager = new NetworkAnalysisManager(new NetworkAnalysisAPICommunicator());

function* getNetworkAnalysisBegin(action) {
  yield put(startProcessing(NETWORK_ANALYSIS_ACTION.GET_NETWORK_ANALYSIS));
  try {
    const payload = action.payload || {};
    const json = yield manager.getNetworkAnalysis(action.payload);
    yield put(updateMasks(getMaskedsFromNetworkAnalysis(deepCopy(json.middleManList))));
    yield put(
      getNetworkAnalysisSuccess({
        ...json,
        type: payload.type,
      }),
    );
  } catch (error) {
    yield put(getNetworkAnalysisError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(NETWORK_ANALYSIS_ACTION.GET_NETWORK_ANALYSIS));
}

export function* getNetworkAnalysisWatcher() {
  yield takeEvery(NETWORK_ANALYSIS_ACTION.GET_NETWORK_ANALYSIS, getNetworkAnalysisBegin);
}

function* getPassthroughNetworkBegin(action) {
  yield put(startProcessing(NETWORK_ANALYSIS_ACTION.GET_PASSTHROUGH_NETWORK));
  try {
    const payload = action.payload || {};
    const json = yield manager.getPassthroughNetwork(action.payload);
    yield put(updateMasks(getMaskedsFromNetworkAnalysis(deepCopy(json.middleManList))));
    yield put(
      getPassthroughNetworkSuccess({
        ...json,
        type: payload.type,
      }),
    );
  } catch (error) {
    yield put(getPassthroughNetworkError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(NETWORK_ANALYSIS_ACTION.GET_PASSTHROUGH_NETWORK));
}

export function* getPassthroughNetworkWatcher() {
  yield takeEvery(NETWORK_ANALYSIS_ACTION.GET_PASSTHROUGH_NETWORK, getPassthroughNetworkBegin);
}

function* getPassthroughV2NetworkBegin(action) {
  yield put(startProcessing(NETWORK_ANALYSIS_ACTION.passthroughV2.get));
  if (action.payload.passthroughType) {
    try {
      const payload = action.payload || {};
      const json = yield manager.getPassthroughV2Network(action.payload);
      yield put(updateMasks(getMaskedsFromNetworkAnalysis(deepCopy(json.middleManList))));
      yield put(
        getPassthroughV2NetworkSuccess({
          ...json,
          type: payload.type,
        }),
      );
    } catch (error) {
      yield put(getPassthroughV2NetworkError());
      yield put(showError({ ...error, action }));
    }
  }
  yield put(stopProcessing(NETWORK_ANALYSIS_ACTION.passthroughV2.get));
}

export function* getPassthroughV2NetworkWatcher() {
  yield takeEvery(NETWORK_ANALYSIS_ACTION.passthroughV2.get, getPassthroughV2NetworkBegin);
}

function* getPassthroughV3NetworkBegin(action) {
  yield put(startProcessing(NETWORK_ANALYSIS_ACTION.passthroughV3.get));
  if (action.payload) {
    try {
      const json = yield manager.getPassthroughV3Network(action.payload);
      yield put(updateMasks(getMaskedsFromPassthroughV3(deepCopy(json.middleManList))));
      yield put(
        getPassthroughV3NetworkSuccess({
          ...json,
          type: 'passV3',
        }),
      );
    } catch (error) {
      yield put(getPassthroughV3NetworkError());
      yield put(showError({ ...error, action }));
    }
  }
  yield put(stopProcessing(NETWORK_ANALYSIS_ACTION.passthroughV3.get));
}

export function* getPassthroughV3NetworkWatcher() {
  yield takeEvery(NETWORK_ANALYSIS_ACTION.passthroughV3.get, getPassthroughV3NetworkBegin);
}
