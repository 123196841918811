import { TRANSACTION_PARTY } from 'utils/constants/constant';
import { getMaskValue } from 'utils/helpers/cases/case-utility';

// eslint-disable-next-line consistent-return
export const getMasterDataParties = (partyKey: any, transaction: any, masterData: any) => {
  if (!masterData) {
    return undefined;
  }
  if (partyKey === TRANSACTION_PARTY.originator) {
    return transaction?.accountHoldingParty === TRANSACTION_PARTY.originator
      ? [masterData.accountHoldingPartyData, ...(masterData.accountHoldingParties || [])]
      : [masterData.counterpartyData, ...(masterData.counterparties || [])];
  }
  if (partyKey === TRANSACTION_PARTY.beneficiary) {
    return transaction?.accountHoldingParty === TRANSACTION_PARTY.beneficiary
      ? [masterData.accountHoldingPartyData, ...(masterData.accountHoldingParties || [])]
      : [masterData.counterpartyData, ...(masterData.counterparties || [])];
  }
};

/**
 * get holder name from transaction
 * @param {*} transaction
 */
const getHolderName = (transaction: any) => transaction?.holderName || transaction?.holder;

/**
 * calculate multiple parties person data to display
 * @param {*} allMasks
 * @param {*} listParties
 * @param {*} caseDetailDataShown
 */
export const getPartiesPersonData = (allMasks: any, listParties: any, caseDetailDataShown: any) => {
  const partyPersonData: Array<any> = [];
  if (caseDetailDataShown) {
    listParties.forEach((party: any) => {
      const partyData: any = {};
      partyData.firstName = getMaskValue(allMasks, party?.personalDetails?.personName?.firstName);
      partyData.middleNames = getMaskValue(
        allMasks,
        party?.personalDetails?.personName?.middleNames,
      );
      partyData.lastName = getMaskValue(allMasks, party?.personalDetails?.personName?.lastName);
      partyData.fullName = getMaskValue(allMasks, party?.personalDetails?.personName?.fullName);
      partyData.fullNameStructured = getMaskValue(
        allMasks,
        party?.personalDetails?.personName?.fullNameStructured,
      );
      partyData.companyName = getMaskValue(allMasks, party?.companyDetails?.companyName);
      partyPersonData.push(partyData);
    });
  } else {
    listParties.forEach((party: any) => {
      const partyData: any = {};
      partyData.firstName = party?.personalDetails?.personName?.firstName?.masked;
      partyData.middleNames = party?.personalDetails?.personName?.middleNames?.masked;
      partyData.lastName = party?.personalDetails?.personName?.lastName?.masked;
      partyData.fullName = party?.personalDetails?.personName?.fullName?.masked;
      partyData.fullNameStructured = party?.personalDetails?.personName?.fullNameStructured?.masked;
      partyData.companyName = party?.companyDetails?.companyName?.masked;
      partyPersonData.push(partyData);
    });
  }

  return partyPersonData;
};

/**
 * calculate person data to display
 * @param {*} allMasks
 * @param {*} personDetailsData
 * @param {*} caseDetailDataShown
 */
export const getPersonData = (allMasks: any, personDetailsData: any, caseDetailDataShown: any) => {
  const personData: any = {};
  const transaction = personDetailsData?.transaction;

  if (caseDetailDataShown) {
    personData.firstName = getMaskValue(
      allMasks,
      personDetailsData?.personalDetails?.personName?.firstName,
    );
    personData.middleNames = getMaskValue(
      allMasks,
      personDetailsData?.personalDetails?.personName?.middleNames,
    );
    personData.lastName = getMaskValue(
      allMasks,
      personDetailsData?.personalDetails?.personName?.lastName,
    );
    personData.fullName = getMaskValue(
      allMasks,
      personDetailsData?.personalDetails?.personName?.fullName,
    );
    personData.fullNameStructured = getMaskValue(
      allMasks,
      personDetailsData?.personalDetails?.personName?.fullNameStructured,
    );
    personData.companyName = getMaskValue(allMasks, personDetailsData?.companyDetails?.companyName);
    personData.holderName = getMaskValue(allMasks, getHolderName(transaction));
  } else {
    personData.firstName = personDetailsData?.personalDetails?.personName?.firstName?.masked;
    personData.middleNames = personDetailsData?.personalDetails?.personName?.middleNames?.masked;
    personData.lastName = personDetailsData?.personalDetails?.personName?.lastName?.masked;
    personData.fullName = personDetailsData?.personalDetails?.personName?.fullName?.masked;
    personData.fullNameStructured =
      personDetailsData?.personalDetails?.personName?.fullNameStructured?.masked;
    personData.companyName = personDetailsData?.companyDetails?.companyName?.masked;
    personData.holderName = getHolderName(transaction)?.masked;
  }

  if (personDetailsData?.parties && personDetailsData?.parties.length > 1) {
    personData.partyPersonData = getPartiesPersonData(
      allMasks,
      personDetailsData?.parties,
      caseDetailDataShown,
    );
  }
  return personData;
};
