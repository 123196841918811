import { Grid } from '@mui/material';
import { RuleCategoryChip } from 'modules/configuration/rule-manager/common/rule-category/rule-category-chip';

const RuleCategoryChips = ({ ruleCategories, size, classes = 'ml-3' }) => (
  <Grid item className={classes}>
    <Grid item container spacing={1} justifyContent="flex-start">
      {ruleCategories?.map((ruleCategory, idx) => (
        <Grid item key={idx}>
          <RuleCategoryChip ruleCategory={ruleCategory} size={size} />
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export { RuleCategoryChips };
