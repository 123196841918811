import { IconButton, Tooltip } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Translation } from 'react-i18next';

const ExpandMoreStyled = styled((props) => {
  const { expand, ...other } = props;
  return (
    <Translation>
      {(t) => (
        <Tooltip title={t('details.heading')}>
          <IconButton {...other} />
        </Tooltip>
      )}
    </Translation>
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMore = ({
  onClick,
  title,
  expand,
  className,
  type = 'text',
  color,
  edge,
  size = 'small',
}) =>
  title ? (
    <Button
      className={className}
      type={type}
      color={color}
      edge={edge}
      size={size}
      onClick={onClick}
      endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    >
      {title}
    </Button>
  ) : (
    <ExpandMoreStyled expand={expand} edge={edge} onClick={onClick}>
      <ExpandMoreIcon />
    </ExpandMoreStyled>
  );

export default ExpandMore;
