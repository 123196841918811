import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RuleCategoryChip = ({ ruleCategory, size = 'medium' }) => {
  const { t } = useTranslation();

  return (
    <Chip
      data-testid="rule-category"
      size={size}
      label={t(`mapping:ruleCategory.${ruleCategory?.key}.value.heading`)}
    />
  );
};

export { RuleCategoryChip };
