import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

export type RiskFactorUploadedFile = {
  version: number;
  fileName: string;
  totalLines: number;
  correctLines: number;
  rejectedLines: number;
  skippedLines: number;
  createdAt: string;
  userId: string;
};

export type RiskFactorMetadata = {
  latestVersion: number;
  uploadedFiles: RiskFactorUploadedFile[];
};

export type RiskLevelMapInstance = {
  riskFactorInstanceId: string;
  metadata: RiskFactorMetadata;
};

export interface RiskLevelMapsState {
  instances: RiskLevelMapInstance[];
}

const initialState: RiskLevelMapsState = {
  instances: [],
};

export const riskFactorRiskLevelMapSlice: Slice<RiskLevelMapsState> = createSlice({
  initialState,
  name: 'riskFactorRiskLevelMapList',
  reducers: {
    getListForRiskFactor: {
      reducer: (state) => state,
      prepare: (riskFactorInstanceId) => ({
        payload: { riskFactorInstanceId },
      }),
    },
    getListForRiskFactorSuccess: (state, action: PayloadAction<RiskLevelMapInstance>) => {
      const riskLevelMapInstance = action.payload;
      const filteredInstances = (state.instances || []).filter(
        (it) => it.riskFactorInstanceId !== riskLevelMapInstance.riskFactorInstanceId,
      );
      return {
        ...state,
        instances: [...filteredInstances, riskLevelMapInstance],
      };
    },
  },
});

export const { getListForRiskFactor, getListForRiskFactorSuccess } =
  riskFactorRiskLevelMapSlice.actions;

export default riskFactorRiskLevelMapSlice.reducer;
