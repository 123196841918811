import APICommunicator from 'services/api-communicator';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class ConfluenceAPICommunicator extends APICommunicator {
  fetchArticleBody(articleId) {
    const apiUrl = `${config.baseUrl}/confluence/document/${articleId}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CONFLUENCE_PAGE_NOT_AVAILABLE);
  }
}

export default ConfluenceAPICommunicator;
