import history from 'utils/helpers/history';

const onRedirectCallback = (appState?: any) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

export const providerConfig = {
  domain: window.REACT_APP_AUTH0_DOMAIN,
  clientId: window.REACT_APP_AUTH0_CLIENT_ID,
  audience: window.REACT_APP_AUTH0_MANAGEMENT_API_AUDIENCE,
  redirectUri: window.location.origin,
  useRefreshToken: true,
  onRedirectCallback,
};
