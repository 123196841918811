import { RULE_CATEGORY_ACTIONS } from 'middleware/action-types';

export const getRuleCategories = (payload) => ({
  type: RULE_CATEGORY_ACTIONS.fetch.data,
  payload,
});

export const getRuleCategoriesSuccess = (payload) => ({
  type: RULE_CATEGORY_ACTIONS.fetch.success,
  payload,
});

export const getRuleCategoriesError = () => ({
  type: RULE_CATEGORY_ACTIONS.fetch.error,
});
