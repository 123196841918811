import TransactionData from 'utils/helpers/data-reader/transaction-data';
import { filterBusinessPurposeHintsByCode } from 'utils/constants/business-purpose-hints';

import { Tooltip, Chip, Typography } from '@mui/material';

const MAX_LENGTH = 15;
const BusinessPurposeChip = ({ caseDetail, isCustomer, providedBusinessPurpose, label }) => {
  let businessPurpose;

  if (providedBusinessPurpose) {
    businessPurpose = Array.isArray(providedBusinessPurpose)
      ? providedBusinessPurpose
      : [providedBusinessPurpose];
  } else {
    const transactionData = new TransactionData(caseDetail || {});
    businessPurpose = transactionData.getBusinessPurposes(isCustomer);
  }

  return (
    <>
      {Boolean(label && businessPurpose.length) && (
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      )}
      {businessPurpose
        ? businessPurpose.map((product, index) => (
            <Tooltip
              key={index}
              title={
                filterBusinessPurposeHintsByCode(product).length ? (
                  filterBusinessPurposeHintsByCode(product).map((purpose, purposeIndex) => (
                    <p key={purposeIndex} className="mb-0">
                      {purpose.hint}
                    </p>
                  ))
                ) : (
                  <p className="mb-0">{product}</p>
                )
              }
            >
              <Chip
                label={
                  product?.length > MAX_LENGTH
                    ? product.substring(0, MAX_LENGTH).concat('...')
                    : product
                }
                size="small"
                key={index}
                className="mr-1"
              />
            </Tooltip>
          ))
        : null}
    </>
  );
};

export { BusinessPurposeChip };
