import config from 'utils/helpers/config-utils/config';
import ErrorCode from 'utils/constants/error-code';

class DetachmentConfigManager {
  constructor(apiCommunicator) {
    this.communicator = apiCommunicator;
  }

  updateDetachmentConfig(payload, tenantId) {
    const url = `${config.configurationUrl}/config/detachment`;
    return this.communicator.putJSONForCustomTenant(
      url,
      payload,
      tenantId,
      ErrorCode.DETACHMENT_CONFIG_FAILED_UPDATE,
    );
  }

  insertDetachmentConfig(payload, tenantId) {
    const url = `${config.configurationUrl}/config/detachment`;
    return this.communicator.postJSONForCustomTenant(
      url,
      payload,
      tenantId,
      ErrorCode.DETACHMENT_CONFIG_FAILED_UPDATE,
    );
  }

  getDetachmentConfig(tenantId) {
    const url = `${config.configurationUrl}/config/detachment`;
    return this.communicator.getJSONForCustomTenant(
      url,
      tenantId,
      ErrorCode.DETACHMENT_CONFIG_MISSING,
    );
  }

  resetDetachmentConfig(tenantId) {
    const url = `${config.configurationUrl}/config/detachment`;
    return this.communicator.deleteForCustomTenant(
      url,
      tenantId,
      ErrorCode.DETACHMENT_CONFIG_MISSING,
    );
  }
}

export default DetachmentConfigManager;
