import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { WheelPoints } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import { RiskLevelSlider } from 'components/popover/person-details/person-details-tab/risk-rating/risk-level-slider';
import { ManualWheel } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/manual-wheel';

const RiskRatingSummary = (props) => {
  const { t } = useTranslation();
  const metaDataKeys = getMetaDataKeys();
  const { totalPoints: metaDataTotalPoints, finalRiskLevel: metaDataFinalRiskLevel } = metaDataKeys;
  const {
    maxPoints,
    [metaDataTotalPoints]: totalPoints,
    [metaDataFinalRiskLevel]: finalRiskLevel,
    isIndividual,
    popoverMode,
    removeScoreLegend,
    getScoreOnly,
    manualRiskLevelChange = false,
    isCustomerList = false,
  } = props;

  const riskRatingConfigForOrganization = useSelector(
    (state) => state.configContainer.customerRiskRating.organization,
  );
  const riskRatingConfigForIndividual = useSelector(
    (state) => state.configContainer.customerRiskRating.individual,
  );

  const marksForOrganization =
    riskRatingConfigForOrganization?.riskLevelConfigs?.map((item) => item.lowerPointsBound ?? 0) ??
    [];
  if (!marksForOrganization.includes(maxPoints)) {
    marksForOrganization.push(maxPoints);
  }

  const marksForIndividual =
    riskRatingConfigForIndividual?.riskLevelConfigs?.map((item) => item.lowerPointsBound ?? 0) ??
    [];
  if (!marksForIndividual.includes(maxPoints)) {
    marksForIndividual.push(maxPoints);
  }
  const allRiskLevelsForOrganization = riskRatingConfigForOrganization?.riskLevelConfigs?.map(
    (item) => item.riskLevel,
  );

  const allRiskLevelsForIndividual = riskRatingConfigForIndividual?.riskLevelConfigs?.map(
    (item) => item.riskLevel,
  );

  const wheelSize = () => {
    if (isCustomerList) {
      return 'small';
    }
    return popoverMode ? 'medium' : 'large';
  };
  const ScoreWheel = () => (
    <WheelPoints
      points={totalPoints}
      maxPoints={maxPoints}
      size={wheelSize()}
      risk={finalRiskLevel}
      tooltip={
        <>
          <Typography variant="overline" component="p" className="color-white lh-1">
            {totalPoints ?? 0} {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
          </Typography>

          <Typography variant="overline" className="color-on-surface-disabled lh-1">
            {maxPoints} {t('configuration:customerRiskRating.riskFactor.points.pts.max.heading')}
          </Typography>
        </>
      }
      subheading={
        removeScoreLegend
          ? null
          : t('configuration:customerRiskRating.riskFactor.points.pts.heading')
      }
    />
  );

  const OverallScore = () =>
    manualRiskLevelChange ? (
      <ManualWheel size={wheelSize()} risk={finalRiskLevel} />
    ) : (
      <ScoreWheel />
    );

  if (getScoreOnly) {
    return <OverallScore />;
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body2" component="div" color="textSecondary">
            {t('case:userPopover.riskRating.summary.heading')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className="pt-2" spacing={2} wrap="nowrap">
        <Grid item className="ml-n1">
          <OverallScore />
        </Grid>
        <Grid item xs container justifyContent="center">
          {!isIndividual && allRiskLevelsForOrganization && (
            <Grid item xs={11} xl={popoverMode ? 11 : 9}>
              <RiskLevelSlider
                risk={finalRiskLevel}
                marks={marksForOrganization}
                totalPoints={totalPoints}
                allRiskLevels={allRiskLevelsForOrganization}
              />
            </Grid>
          )}
          {isIndividual && allRiskLevelsForIndividual && (
            <Grid item xs={11} xl={popoverMode ? 11 : 9}>
              <RiskLevelSlider
                risk={finalRiskLevel}
                marks={marksForIndividual}
                totalPoints={totalPoints}
                allRiskLevels={allRiskLevelsForIndividual}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { RiskRatingSummary };
