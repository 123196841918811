import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import { useSelector } from 'react-redux';
import { getTenantDefaultCurrency } from 'utils/helpers/config-utils/config';
import { formatMoney } from 'utils/helpers/math-util';

const IncomeMonetaryValueRangeDetails = ({ maxPoints, scoreData, popoverMode, title }) => {
  const { t } = useTranslation();
  const metaDataKeys = getMetaDataKeys();
  const originalData = scoreData[metaDataKeys.originalData];
  const tenantConfig = useSelector((state) => state?.tenantConfig);
  const selectedType = scoreData[metaDataKeys.selectedTypeExpectedIncome];
  const expectedIncomeType = () => {
    const types = t(
      'mapping:customerRiskRating.sections.CUSTOMER.value.editors.EXPECTED_INCOME.types',
    );
    return Object.values(types).find(({ key }) => key === selectedType)?.value?.heading;
  };

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData[metaDataKeys.calculatedPoints] ?? 0}
          maxPoints={maxPoints}
          headline={title || t('case:userPopover.riskRating.expectedIncome.heading')}
        />
      }
      highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
      detailsComponent={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {expectedIncomeType() ?? selectedType}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {originalData && (
                <Typography component="span" variant="caption">
                  {getTenantDefaultCurrency(tenantConfig)}{' '}
                </Typography>
              )}
              {formatMoney(originalData) || t('case:userPopover.riskRating.missingData.heading')}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

export { IncomeMonetaryValueRangeDetails };
