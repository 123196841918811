/**
 * DEPRECATED BELOW
 * for adding routes, use the new object APP_ROUTES in routes.js
 */

const CaseRouteUrls = {
  ANY_CASES: '/:tenantId?/cases',
  MY_CASES: '/:tenantId?/myCases',
  OPEN_CASES: '/:tenantId?/openCases',
  AML_CASES: '/:tenantId?/amlCases',
  PRIORITY_CASES: '/:tenantId?/priorityCases',
  SANCTION_CASES: '/:tenantId?/sanctionCases',
  FRAUD_CASES: '/:tenantId?/fraudCases',
  CLOSED_CASES: '/:tenantId?/closedCases',
  AUTO_CLOSED_CASES: '/:tenantId?/autoClosedCases',
  NOT_OPENED_CASES: '/:tenantId?/notOpenedCases',
  BEING_PROCESSED_CASES: '/:tenantId?/beingProcessedCases',
  ESCALATED_CASES: '/:tenantId?/escalatedCases',
  AUTO_CLOSED_REVIEW_CASES: '/:tenantId?/autoClosedReviewCases',
  NOT_SUBSTANTIATED_CASES: '/:tenantId?/notSubstantiatedCases',

  ANY_CASES_DETAIL: '/:tenantId?/cases/:uuid',
  MY_CASES_DETAIL: '/:tenantId?/myCases/:uuid',
  OPEN_CASES_DETAIL: '/:tenantId?/openCases/:uuid',
  AML_CASES_DETAIL: '/:tenantId?/amlCases/:uuid',
  PRIORITY_CASES_DETAIL: '/:tenantId?/priorityCases/:uuid',
  SANCTION_CASES_DETAIL: '/:tenantId?/sanctionCases/:uuid',
  CLOSED_CASES_DETAIL: '/:tenantId?/closedCases/:uuid',
  AUTO_CLOSED_CASES_DETAIL: '/:tenantId?/autoClosedCases/:uuid',
  NOT_OPENED_CASES_DETAIL: '/:tenantId?/notOpenedCases/:uuid',
  BEING_PROCESSED_CASES_DETAIL: '/:tenantId?/beingProcessedCases/:uuid',
  ESCALATED_CASES_DETAIL: '/:tenantId?/escalatedCases/:uuid',
  AUTO_CLOSED_REVIEW_CASES_DETAIL: '/:tenantId?/autoClosedReviewCases/:uuid',
  NOT_SUBSTANTIATED_CASES_DETAIL: '/:tenantId?/notSubstantiatedCases/:uuid',

  ANY_CASES_PATH: '/cases',
  MY_CASES_PATH: '/myCases',
  OPEN_CASES_PATH: '/openCases',
  AML_CASES_PATH: '/amlCases',
  PRIORITY_CASES_PATH: '/priorityCases',
  SANCTION_CASES_PATH: '/sanctionCases',
  FRAUD_CASES_PATH: '/fraudCases',
  CLOSED_CASES_PATH: '/closedCases',
  AUTO_CLOSED_CASES_PATH: '/autoClosedCases',
  NOT_OPENED_CASES_PATH: '/notOpenedCases',
  BEING_PROCESSED_CASES_PATH: '/beingProcessedCases',
  ESCALATED_CASES_PATH: '/escalatedCases',
  FINCEN_CTR_REPORT: '/regulatory-reporting/report/fincen/ctr',
  FINCEN_SAR_REPORT: '/regulatory-reporting/report/fincen/sar',
  GOAML_CH_STR_REPORT: '/regulatory-reporting/report/goaml-ch/str',
  AUTO_CLOSED_REVIEW_CASES_PATH: '/autoClosedReviewCases',
  NOT_SUBSTANTIATED_CASES_PATH: '/notSubstantiatedCases',

  LOGIN: '/login',
  LOGOUT: '/logout',
  ERROR: '/error',
  RESET_PASSWORD: '/resetPassword',
  TENANT_CONFIG: '/workflowUsers',
  AUDIT_TRACK: '/auditTrack',
  RULE_MANAGER: '/ruleManager',
  DATA_FEEDS: '/dataFeeds',
  EFFICIENCY_DASHBOARD: '/efficiencyDashboard',
  CASE_STATISTIC_DASHBOARD: '/caseStatisticDashboard',
  MACHINE_INTELLIGENCE: '/machineIntelligence',
  DETACHMENT_CONFIG: '/detachmentConfiguration',
  CUSTOMER_RISK_RATING_CONFIG: '/customerRiskRating',
  SETTINGS: '/settings',

  TOOLING: '/tooling',
  TENANT_MANAGER: '/tenantManager',
};

export default CaseRouteUrls;
