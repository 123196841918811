import { FlaggingConfigActions } from 'modules/configuration/rule-manager/flagging/middleware/action-types';

const flaggingConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case FlaggingConfigActions.FLAGGING_CONFIG_GET_SUCCESS:
    case FlaggingConfigActions.FLAGGING_CONFIG_UPDATE_SUCCESS:
    case FlaggingConfigActions.FLAGGING_CONFIG_DELETE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default flaggingConfigReducer;
