import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Slider } from '@mui/material';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import {
  buildIndividualPointConfigs,
  getMetaDataKeys,
} from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';

/**
 * Base component for displaying all score details,
 * which are calculated from assessment score,
 * such as PEP, Adverse Media.
 */
const AssessmentScoreDetails = ({
  maxPoints,
  scoreData: propsScoreData,
  configKey,
  popoverMode,
  headlineKey,
  headlineKeyWithoutTranslation,
  isIndividual,
}) => {
  const scoreData = propsScoreData ?? {};

  const riskRatingConfig = useSelector((state) =>
    isIndividual
      ? state.configContainer.customerRiskRating.individual
      : state.configContainer.customerRiskRating.organization,
  );
  const individualPointsConfig = buildIndividualPointConfigs(riskRatingConfig?.pointsConfigs);
  const scoreConfig = individualPointsConfig[configKey];
  if (!scoreConfig && !scoreData?.RISK_FACTOR_INSTANCE) {
    return null;
  }

  const metaDataKeys = getMetaDataKeys();
  const min = parseFloat(scoreData[metaDataKeys.riskFactorInstance]?.settings?.minValue) || 0;
  const max = parseFloat(scoreData[metaDataKeys.riskFactorInstance]?.settings?.maxValue) || 1;
  const score = scoreData[metaDataKeys.originalData] ?? 0;

  return (
    <Translation>
      {(t) => (
        <ScoreDetailsAccordion
          summaryComponent={
            <WheelPointsWithHeadline
              size={popoverMode ? 'small' : 'medium'}
              points={scoreData[metaDataKeys.calculatedPoints] ?? 0}
              maxPoints={maxPoints}
              headline={
                headlineKeyWithoutTranslation ||
                t(`case:userPopover.riskRating.${headlineKey}.heading`)
              }
            />
          }
          highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
          detailsComponent={
            <div className="pt-3 w-100">
              <Slider
                min={min}
                max={max}
                value={[score]}
                disabled
                marks={[min, max].map((item) => ({ value: item, label: item }))}
                valueLabelDisplay="on"
              />
            </div>
          }
        />
      )}
    </Translation>
  );
};

export { AssessmentScoreDetails };
