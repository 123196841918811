import { Fade } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MultiValueChip from 'components/chips/multi-value-chip';
import MultiValueChipWithTitle from 'components/chips/multi-value-chip-with-title';
import {
  AnomalyScoreCell,
  AssignToUserCell,
  AutoCloseScoreCell,
  CaseStatusCell,
  CustomerCell,
  FlowCell,
  MaskedCell,
  PlainTextAsChipGroupCell,
  ProcessingStatusCell,
  TimestampCell,
  TotalAmountCell,
  TransactionCountCell,
  TransactionRiskLevelCell,
  TransactionStatusCell,
  TypeCell,
} from 'components/table/table-cell/case-table-cell';
import { TenantNameCell } from 'components/table/table-cell/tenant-name-cell';
import SelectedCaseContext from 'contexts/case-detail/selected-case-context';
import { RuleCategoryChips } from 'modules/configuration/rule-manager/common/rule-category/rule-category-chips';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  InvestigationStatusCell,
  InvestigationOutcomeCell,
} from 'components/table/table-cell/case-table-cells';

/**
 * Since we migrated to React v18 "open in new tab" won't work anymore with <TableTow/>
 * Adding <a/> tags is a workaround found here: https://robertcooper.me/post/table-row-links
 * */
export const TrxCaseRow = ({
  caseDetails,
  onClick,
  canSeeCaseDetails,
  testIdPrefix,
  detailUrl,
  tenantConfig,
  txCount,
  columnConfig,
}) => {
  const { t } = useTranslation();
  const anomalyThreshold = useSelector(
    (state) => state.case.decisionEngineConfig?.anomalyDeeplScoreThreshold || 0.8,
  );
  const machineCertaintyProbabilityThreshold = useSelector(
    (state) => state.case.decisionEngineConfig?.machineCertaintyProbabilityThreshold || 0.8,
  );
  const LinkToCaseDetails = () => {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    if (canSeeCaseDetails) return <a href={detailUrl} tabIndex="-1" />;
    return null;
  };

  const getRowContent = (rowKey) => {
    switch (rowKey) {
      case t('mapping:filters.sorting.ruleCategories.key'):
        return (
          <TableCell align="left">
            <RuleCategoryChips
              classes="mr-0"
              size="small"
              ruleCategories={caseDetails?.ruleCategories}
            />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.typesString.key'):
        return (
          <TableCell align="left">
            <TypeCell item={caseDetails} testIdPrefix={testIdPrefix} />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.caseId.key'):
        return (
          <TableCell align="left">
            <PlainTextAsChipGroupCell item={caseDetails} fieldName="caseIds" maxNumber={2} />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.tenantId.key'):
        return (
          <TableCell align="left">
            <TenantNameCell tenantId={caseDetails.tenantId} />
          </TableCell>
        );
      case t('mapping:filters.sorting.caseStatus.key'):
        return (
          <TableCell align="left">
            <CaseStatusCell item={caseDetails} />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.processingStatus.key'):
        return (
          <TableCell align="left">
            <ProcessingStatusCell item={caseDetails} />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.investigationStatus.key'):
        return (
          <TableCell align="left">
            <InvestigationStatusCell caseDetail={caseDetails} />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.transactionStatus.key'):
        return (
          <TableCell align="left">
            <TransactionStatusCell item={caseDetails} />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.risk.key'): {
        return (
          <TableCell align="left">
            <TransactionRiskLevelCell
              item={caseDetails}
              clickable
              dataTestIdPrefix={testIdPrefix}
            />
            <LinkToCaseDetails />
          </TableCell>
        );
      }
      case t('mapping:filters.sorting.anomalyScoreMax.key'): {
        return (
          <TableCell align="left">
            <AnomalyScoreCell item={caseDetails} anomalyThreshold={anomalyThreshold} />
            <LinkToCaseDetails />
          </TableCell>
        );
      }
      case t('mapping:filters.sorting.autoCloseScoreMin.key'): {
        return (
          <TableCell align="left">
            <AutoCloseScoreCell
              item={caseDetails}
              machineCertaintyProbabilityThreshold={machineCertaintyProbabilityThreshold}
            />
            <LinkToCaseDetails />
          </TableCell>
        );
      }
      case t('mapping:filters.sorting.customer.key'):
        return (
          <TableCell align="left">
            <CustomerCell
              item={caseDetails}
              tenantConfig={tenantConfig}
              dataTestIdPrefix={testIdPrefix}
            />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.customerID.key'):
        return (
          <TableCell align="left">
            <MaskedCell item={caseDetails} fieldName="customerIDs" />
          </TableCell>
        );

      case t('mapping:filters.sorting.customerGroupID.key'):
        return (
          <TableCell align="left">
            <MaskedCell item={caseDetails} fieldName="customerGroups" />
          </TableCell>
        );

      case t('mapping:filters.sorting.accountNumbers.key'):
        return (
          <TableCell align="left">
            <MaskedCell item={caseDetails} fieldName="accountNumbers" />
          </TableCell>
        );

      case t('mapping:filters.sorting.transactionCount.key'):
        return (
          <TableCell align="right">
            <TransactionCountCell txCount={txCount} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.totalAmount.key'):
        return (
          <TableCell align="right">
            <TotalAmountCell item={caseDetails} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.flow.key'):
        return (
          <TableCell align="center">
            <FlowCell item={caseDetails} customClass="flow-img" />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.updatedAt.key'):
        return (
          <TableCell align="left">
            <TimestampCell item={caseDetails} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.createdAt.key'):
        return (
          <TableCell align="left">
            <TimestampCell timestamp={caseDetails?.createdAt} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.assignedToUserId.key'):
        return (
          <TableCell align="left">
            <AssignToUserCell item={caseDetails} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.lastProcessedAt.key'):
        return (
          <TableCell align="left">
            <TimestampCell timestamp={caseDetails?.lastProcessedAt} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.paymentMeans.key'):
        return (
          <TableCell align="left">
            <MultiValueChip values={caseDetails?.paymentMeans} maxNumber={3} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.intermediaryName.key'):
        return (
          <TableCell align="left">
            <PlainTextAsChipGroupCell item={caseDetails} fieldName="intermediaryNames" />
          </TableCell>
        );

      case t('mapping:filters.sorting.additionalIds.key'):
        return (
          <TableCell align="left">
            <MultiValueChipWithTitle values={caseDetails?.additionalIds} maxNumber={2} />
            <LinkToCaseDetails />
          </TableCell>
        );

      case t('mapping:filters.sorting.investigationOutcome.key'):
        return (
          <TableCell align="left">
            <InvestigationOutcomeCell caseDetail={caseDetails} />
          </TableCell>
        );
      case t('mapping:filters.sorting.orderIds.key'):
        return (
          <TableCell align="left">
            <span>{caseDetails?.orderIds?.join(', ')}</span>
          </TableCell>
        );

      case t('mapping:filters.sorting.executionScopes.key'):
        return (
          <TableCell align="left">
            <span>{caseDetails?.executionScopes?.join(', ')}</span>
          </TableCell>
        );
      case t('mapping:filters.sorting.originatorsBankCountries.key'):
        return (
          <TableCell align="left">
            <MultiValueChip values={caseDetails?.originatorsBankCountries} maxNumber={2} />
            <LinkToCaseDetails />
          </TableCell>
        );
      case t('mapping:filters.sorting.beneficiariesBankCountries.key'):
        return (
          <TableCell align="left">
            <MultiValueChip values={caseDetails?.beneficiariesBankCountries} maxNumber={2} />
            <LinkToCaseDetails />
          </TableCell>
        );
      default:
        return null;
    }
  };

  return (
    <SelectedCaseContext.Provider
      value={{
        selectedCase: caseDetails,
      }}
    >
      <TableRow hover onClick={(event) => onClick(event)}>
        {columnConfig.map((singleColumn, index) => (
          <Fade
            key={index}
            in={singleColumn.isVisible}
            className={!singleColumn.isVisible ? 'd-none' : ''}
          >
            {getRowContent(singleColumn.sortConfig.name)}
          </Fade>
        ))}
      </TableRow>
    </SelectedCaseContext.Provider>
  );
};

export default TrxCaseRow;
