import moment from 'moment/moment';
import { DATE_TIME_FORMAT, REGEX } from 'utils/constants/constant';

/**
 * Replaces all occurrences of date times e.g. 2023-07-30T03:00:00Z to default date time format.
 * @param str string to search and replace all date times.
 * @returns {string} string containing formatted date times.
 */
/* eslint-disable no-cond-assign */
export const formatAllDateTimes = (str) =>
  str.replace(REGEX.isoUtcDateTime, (it) => moment(it).format(DATE_TIME_FORMAT.mediumMoment));
