import { FEATURE_STATUS } from 'utils/constants/constant';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import { hasPermission } from 'utils/helpers/permissions-util';
import { APP_ROUTES } from 'utils/constants/routes';
import { UserPermission } from 'models/authorization/permissions';
import { DetachmentConfig, DetachmentStatus } from 'models/authorization/detachment';

const {
  customerCasesList: customerCasesListConfig,
  configurations: {
    settings: settingsConfig,
    ruleManager: ruleManagerConfig,
    listManager: listManagerConfig,
    machineIntelligence: machineIntelligenceConfig,
    dataFeeds: dataFeedsConfig,
    tenantConfig,
  },
  customerRiskRating: { organization: crrOrganization, individual: crrIndividual },
} = DETACHMENT_CONFIG;

/**
 * standard key to check if activated
 * @param {String} featureStatus
 * @returns
 */
export const isFeatureAttached = (featureStatus: DetachmentStatus) =>
  featureStatus === FEATURE_STATUS.attached;

export const hasConfigAndPermission = (
  key: string,
  value: DetachmentStatus,
  userPermissions: Array<UserPermission>,
  permissionsToCheck: string,
) => {
  const isAuthorizedToSee = (permission: string) => {
    if (key) {
      return hasPermission(userPermissions, permission) && isFeatureAttached(value);
    }
    return hasPermission(userPermissions, permission);
  };

  if (permissionsToCheck) {
    return isAuthorizedToSee(permissionsToCheck);
  }

  switch (key) {
    case customerCasesListConfig: {
      return isAuthorizedToSee(APP_ROUTES.customer.cases.permission);
    }
    case crrOrganization:
    case crrIndividual: {
      return isAuthorizedToSee(APP_ROUTES.customerRiskRating.permission);
    }
    case settingsConfig: {
      return isAuthorizedToSee(APP_ROUTES.settings.permission);
    }
    case tenantConfig: {
      return isAuthorizedToSee(APP_ROUTES.workflowAndUsers.permission);
    }
    case ruleManagerConfig: {
      return isAuthorizedToSee(APP_ROUTES.ruleManager.permission);
    }
    case listManagerConfig: {
      return isAuthorizedToSee(APP_ROUTES.listManager.permission);
    }
    case machineIntelligenceConfig: {
      return isAuthorizedToSee(APP_ROUTES.machineIntelligence.permission);
    }
    case dataFeedsConfig: {
      return isAuthorizedToSee(APP_ROUTES.dataFeeds.permission);
    }
    default: {
      return false;
    }
  }
};

// TODO: separate logic -> single responsibility
export function isFeatureAccessible(
  key: string,
  detachmentConfig: DetachmentConfig | Array<UserPermission>,
  userPermissions?: Array<UserPermission>,
  permissionToCheck?: UserPermission | string,
) {
  if (arguments.length <= 1) return false;

  /** Omit third argument. Use second argument for permissions */
  if (Array.isArray(detachmentConfig) && !userPermissions) {
    return hasPermission(detachmentConfig, key);
  }

  /** Pass detachment config as second argument. Omit third argument. */
  if (detachmentConfig && !userPermissions) {
    // @ts-ignore
    return isFeatureAttached(detachmentConfig[key]);
  }

  /** Pass null as second argument. Use third argument for permission */
  if (!detachmentConfig && userPermissions) {
    return hasPermission(userPermissions, key);
  }

  /** check for both arguments */
  // @ts-ignore // TODO: split up logic
  return hasConfigAndPermission(key, detachmentConfig?.[key], userPermissions, permissionToCheck);
}
