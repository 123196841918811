import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class RuleCategoryAPICommunicator extends APICommunicator {
  getRuleCategories() {
    const apiUrl = API_URLS.configService.ruleCategory.fetch;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default RuleCategoryAPICommunicator;
