import { REGEX } from 'utils/constants/constant';

export const checkIfValidUUID = (str) => {
  // REGEX.uuid defines the g(lobal) modifier, thus to use it with
  // different strings a new RegEx has to be created
  const uuidRegex = new RegExp(REGEX.uuid);
  return uuidRegex.test(str);
};

const validateTenantId = (value) => {
  if (value) {
    return checkIfValidUUID(value);
  }

  return true;
};

const validateApiKey = (value) => {
  if (value) {
    const apiKeyValidation = new RegExp(REGEX.generatedApiKeyOrAtleast40Alphanumeric);
    return apiKeyValidation.test(value);
  }

  return true;
};

export { validateTenantId, validateApiKey };

export function isEmptyObject(obj) {
  return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
}

// TODO: We have to find the right way to get the uuid from the URL
// Do not extend or reuse this function
export const isCaseRoute = () => {
  const currentPath = window.location.pathname;
  const paths = currentPath.split('/');
  let uuidCount = 0;
  paths.forEach((i) => {
    if (checkIfValidUUID(i)) {
      uuidCount += 1;
    }
  });

  return uuidCount === 2;
};

export const containsAtLeastTwoUuids = (currentPath) => {
  const paths = currentPath.split('/');
  let uuidCount = 0;
  paths.forEach((i) => {
    if (checkIfValidUUID(i)) {
      uuidCount += 1;
    }
  });

  return uuidCount >= 2;
};
