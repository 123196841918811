import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/action-types';
import {
  activateBlacklistInstantiableListConfigError,
  activateBlacklistInstantiableListConfigSuccess,
  deleteBlacklistInstantiableListConfigError,
  deleteBlacklistInstantiableListConfigSuccess,
  getBlacklistInstantiableListConfigError,
  getBlacklistInstantiableListConfigSuccess,
  updateBlacklistInstantiableListConfigError,
  updateBlacklistInstantiableListConfigSuccess,
} from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/actions/blacklist-instantiable-list-config';
import BlacklistInstantiableListApiCommunicator from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/services/blacklist-instantiable-list-api-communicator';
import BlacklistInstantiableListManager from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/services/blacklist-instantiable-list-manager';
import { put, takeLatest } from 'redux-saga/effects';
import TenantManagementService from 'services/tenant/tenant-management-service';

const apiCommunicator = new BlacklistInstantiableListApiCommunicator();
const occupationListManager = new BlacklistInstantiableListManager(apiCommunicator);

/**
 * Fetch customer occupation files action
 */
export function* getBlacklistInstantiableConfigListBegin(action) {
  try {
    yield put(startProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));

    const json = yield occupationListManager.getBlacklistInstantiableListConfig(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getBlacklistInstantiableListConfigSuccess(json));

    yield put(stopProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getBlacklistInstantiableListConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch customer occupation files action watcher
 */
export function* getBlacklistInstantiableListConfigWatcher() {
  yield takeLatest(
    BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch,
    getBlacklistInstantiableConfigListBegin,
  );
}

function* updateBlacklistInstantiableListConfigBegin(action) {
  yield put(startProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data));
  try {
    const json = yield occupationListManager.updateBlacklistInstantiableListConfig(action.payload);
    yield put(updateBlacklistInstantiableListConfigSuccess(json));
  } catch (error) {
    yield put(updateBlacklistInstantiableListConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data));
}

export function* updateBlacklistInstantiableListConfigWatcher() {
  yield takeLatest(
    BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data,
    updateBlacklistInstantiableListConfigBegin,
  );
}

/**
 *  Update active risky words v2 file version action
 */
export function* updateActiveBlacklistInstantiableListConfigVersionBegin(action) {
  try {
    yield put(startProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));

    const json = yield occupationListManager.activateBlacklistInstantiableListConfig(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateBlacklistInstantiableListConfigSuccess(json));

    yield put(stopProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateBlacklistInstantiableListConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active customer occupation file version action watcher
 */
export function* updateActiveBlacklistInstantiableListConfigVersionWatcher() {
  yield takeLatest(
    BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send,
    updateActiveBlacklistInstantiableListConfigVersionBegin,
  );
}

function* deleteBlacklistInstantiableListConfigBegin(action) {
  yield put(startProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data));

  try {
    const json = yield occupationListManager.deleteBlacklistInstantiableListConfig(action.payload);
    yield put(deleteBlacklistInstantiableListConfigSuccess(json));
  } catch (error) {
    yield put(deleteBlacklistInstantiableListConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data));
}

export function* deleteBlacklistInstantiableListConfigWatcher() {
  yield takeLatest(
    BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data,
    deleteBlacklistInstantiableListConfigBegin,
  );
}
