import { Component } from 'react';
import PropTypes from 'prop-types';
import { getCountryCodeFromIBAN, getCountryName } from 'utils/constants/country-list';

class CountryName extends Component {
  render() {
    const countryCode = this.props.code ? this.props.code : getCountryCodeFromIBAN(this.props.iban);

    return <span>{getCountryName(countryCode)}</span>;
  }
}

CountryName.propTypes = {
  code: PropTypes.string,
  iban: PropTypes.string,
};

export default CountryName;
