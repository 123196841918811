import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import i18next from 'i18next';
import jsFileDownload from 'js-file-download';
import { showError } from 'middleware/actions/error';
import SessionService from 'modules/authentication/login/services/session-service';
import { Translation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TenantManagementService from 'services/tenant/tenant-management-service';

const auth = new SessionService();

const DownloadFile = ({ disabled, downloadUrl, fileName, edge, size }) => {
  const dispatch = useDispatch();

  const handleOnClick = (event) => {
    event.preventDefault();
    const url = `${downloadUrl}?correlationId=${auth.getCorrelationId()}`;
    axios
      .get(url, {
        withCredentials: true,
        headers: {
          'Content-Disposition': `attachment; filename=${fileName}`,
          tenant_id: TenantManagementService.getActiveTenantId(),
          tenantId: TenantManagementService.getActiveTenantId(),
          correlationId: auth.getCorrelationId(),
          userId: auth.getUsername(),
        },
      })
      .then((response) => {
        jsFileDownload(response.data, fileName);
      })
      .catch(() => {
        dispatch(
          showError({
            message: i18next.t('file.download.error.downloadError.heading'),
          }),
        );
      });
  };

  return (
    <Translation>
      {(t) => (
        <Tooltip title={t('download.heading')}>
          <IconButton disabled={disabled} onClick={handleOnClick} edge={edge} size={size}>
            <FileDownloadOutlinedIcon
              className={disabled ? 'color-on-surface-disabled' : 'color-on-surface-secondary'}
            />
          </IconButton>
        </Tooltip>
      )}
    </Translation>
  );
};

export default DownloadFile;
