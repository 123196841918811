import { Divider, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import { tenantIdsToTenants } from 'components/filters/fields/tenant-name/tenant-name-filter-helper';
import { Filter } from 'components/filters/models/filter';
import { Form, useForm } from 'hooks/use-form';
import { isEmpty } from 'lodash';
import { Tenant } from 'models/tenant/tenant';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { getTenantsFromString } from 'utils/helpers/utility';

interface TenantNameFormProps {
  editForm: { values: string[] };
  onSubmit: (filter: Filter) => void;
}

interface TenantOption {
  id: string;
  heading: string;
}

type useFormReturnType = ReturnType<typeof useForm> & {
  values: { tenants: string[] };
  errors: Record<string, null>;
};

/**
 * Form prompts tenant names for the user but under the hood it converts tenantNames to tenantIds.
 */
export const TenantNameForm = ({ editForm, onSubmit }: TenantNameFormProps) => {
  const allTenants = TenantManagementService.getTenants();
  const userTenants = getTenantsFromString(allTenants) as Tenant[];

  const { t } = useTranslation();
  const [options, setOptions] = useState<TenantOption[]>([]);

  const initialFValues = {
    tenants: editForm?.values
      ? tenantIdsToTenants(editForm.values, userTenants).map((value) => ({
          id: value.id,
          heading: value.name,
        }))
      : [],
  };

  useEffect(() => {
    if (isEmpty(userTenants)) {
      return;
    }

    const uniqueTenants = userTenants.map((tenant) => ({
      id: tenant.id,
      heading: tenant.name ?? tenant.id,
    }));
    setOptions(uniqueTenants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, errors, setErrors, handleInputChange }: useFormReturnType = useForm(
    initialFValues,
    true,
    // eslint-disable-next-line no-use-before-define
    validate,
  );

  function validate(fieldValues = values): boolean {
    const temp: Record<string, string | null> = { ...errors };
    if ('tenants' in fieldValues) {
      temp.tenants = fieldValues.tenants?.length ? '' : t('required.heading');
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }

    return false;
  }

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: t('mapping:filters.filtering.tenantId.key'),
        values: values.tenants.map((tenant: TenantOption) => tenant.id),
      });
    }
  };

  return (
    <Grid container>
      <Form className="w-100">
        <Grid container item className="px-3 py-2" spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                dataTestIdPrefix="filter-rule-instances"
                name="tenants"
                label={t('condition.equal.heading')}
                value={values.tenants}
                onChange={handleInputChange}
                error={errors.tenants}
                options={options}
                splitValueByCommaEnabled={false}
                shrink
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            dataTestIdPrefix="filter-by-tenant-names"
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};
