import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'hooks/use-form';
import { Divider, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import React, { FC } from 'react';
import { Filter } from 'components/filters/models/filter';

interface CustomerNameFormProps {
  onSubmit: CallableFunction;
  editForm: Filter;
  filterKey: string;
}

export const CustomerNameForm: FC<CustomerNameFormProps> = ({ editForm, onSubmit, filterKey }) => {
  const { t } = useTranslation();
  const initialFValues = {
    customerName: editForm?.values || [],
  };

  type useFormReturnType = ReturnType<typeof useForm> & {
    errors: Record<string, null>;
  };
  const { values, errors, setErrors, handleInputChange }: useFormReturnType = useForm(
    initialFValues,
    true,
    // eslint-disable-next-line no-use-before-define
    validate,
  );

  function validate(fieldValues = values): boolean {
    const temp: Record<string, string | null> = { ...errors };
    if ('customerName' in fieldValues) {
      temp.customerName = fieldValues.customerName?.length ? '' : t('required.heading');
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }

    return false;
  }

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: filterKey,
        values: values.customerName,
      });
    }
  };
  return (
    <Grid container>
      <Form className="w-100">
        <Grid container item className="px-3 py-3" spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                dataTestIdPrefix="filter-by-customer-name"
                name="customerName"
                label={t('condition.equal.heading')}
                value={values.customerName}
                onChange={handleInputChange}
                error={errors.customerName}
                splitValueByCommaEnabled={false}
                shrink
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            dataTestIdPrefix="filter-by-customer-name"
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};
