import { RESET_PASSWORD_ACTIONS } from '../action-types';

export const requestResetPassword = (emailAddress) => ({
  type: RESET_PASSWORD_ACTIONS.request,
  emailAddress,
});
export const requestResetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_ACTIONS.success,
  payload,
});
export const requestResetPasswordError = (error) => ({
  type: RESET_PASSWORD_ACTIONS.error,
  payload: error,
});
