import { Box } from '@mui/material';
import i18next from 'i18next';
import { Translation, useTranslation } from 'react-i18next';

const buildUnassignedValuesFromUrl = (filter) => ({
  field: i18next.t('mapping:filters.filtering.unassigned.key'),
  condition: i18next.t(`mapping:operator.text.${filter?.condition}.key`),
  values: filter?.values,
});

const UnassignedForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  onSubmit({
    condition: t('mapping:operator.text.EMPTY.key'),
    field: t('mapping:filters.filtering.unassigned.key'),
  });
  return null;
};

const UnassignedChip = () => (
  <Translation>
    {(t) => (
      <Box fontSize="body2.fontSize">{t('mapping:filters.filtering.unassigned.value.heading')}</Box>
    )}
  </Translation>
);

export { UnassignedForm, UnassignedChip, buildUnassignedValuesFromUrl };
