export const AUTH_ACTIONS = {
  withDatabase: {
    login: 'LOGIN_WITH_DATABASE_START',
    success: 'LOGIN_WITH_DATABASE_SUCCESS',
    error: 'LOGIN_WITH_DATABASE_ERROR',
    logout: 'LOGOUT_WITH_DATABASE_SUCCESS',
  },
  withSso: {
    login: 'LOGIN_WITH_SSO_START',
    success: 'LOGIN_WITH_SSO_SUCCESS',
    error: 'LOGIN_WITH_SSO_ERROR',
    logout: 'LOGOUT_WITH_SSO_SUCCESS',
  },
  permissions: {
    get: 'GET_USER_PERMISSIONS',
    success: 'GET_USER_PERMISSIONS_SUCCESS',
    error: 'GET_USER_PERMISSIONS_ERROR',
  },
  operatorList: {
    get: 'GET_OPERATOR_LIST',
    success: 'GET_OPERATOR_LIST_SUCCESS',
    error: 'GET_OPERATOR_LIST_ERROR',
  },
  detachmentConfig: {
    get: 'GET_DETACHMENT_CONFIG',
    success: 'GET_DETACHMENT_CONFIG_SUCCESS',
    error: 'GET_DETACHMENT_CONFIG_ERROR',
  },
  platform: {
    viewingConfig: 'PLATFORM_VIEWING_CONFIG',
    error: 'PLATFORM_VIEWING_CONFIG_ERROR',
  },
  userInfos: {
    set: 'SET_USER_INFOS',
    fetch: 'FETCH_USER_INFOS',
  },
  userConfig: {
    fetch: 'FETCH_USER_CONFIG',
  },
};
