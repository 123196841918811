import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class RiskyWordsV2ListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('RiskyWordsV2 List API Communicator instance is required.');
    }
  }

  /**
   * Checking if incoming JSON is correct
   */
  // eslint-disable-next-line class-methods-use-this
  validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.RISKY_WORDS_LIST_DATA_INVALID);
  }

  /**
   * Fetch RiskyWordsV2 file list
   */
  getRiskyWordsV2FileList(tenantId) {
    return this.communicator
      .fetchRiskyWordsV2FilesList(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  /**
   * Update active RiskyWordsV2 file version
   */
  updateActiveRiskyWordsV2ListVersion(tenantId, payload) {
    return this.communicator.updateActiveRiskyWordsV2ListVersion(tenantId, payload.version);
  }
}

export default RiskyWordsV2ListManager;
