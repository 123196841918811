import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';
import i18next from 'i18next';

class NetworkAnalysisManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Network Analysis API Communicator instance is required.');
    }
  }

  static validateConfigJson(json) {
    if (json && json.middleManList && Array.isArray(json.middleManList)) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  getNetworkAnalysis(payload) {
    return this.communicator
      .fetchNetworkAnalysis(payload.caseId, payload.createdAt)
      .then((json) => NetworkAnalysisManager.validateConfigJson(json));
  }

  getPassthroughNetwork(payload) {
    return this.communicator
      .fetchPassthroughNetwork(
        payload.caseId,
        payload.fundsOriginatorToken,
        payload.numberOfDays,
        payload.createdAt,
      )
      .then((json) => NetworkAnalysisManager.validateConfigJson(json));
  }

  getPassthroughV2Network(payload) {
    let networkPromise;
    if (
      payload.passthroughType?.toUpperCase() === i18next.t('mapping:flow.inbound.key').toUpperCase()
    ) {
      networkPromise = this.communicator.fetchInboundPassthroughV2Network(
        payload.caseId,
        payload.outgoingCaseList,
        payload.createdAt,
      );
    } else {
      networkPromise = this.communicator.fetchPassthroughV2Network(
        payload.caseId,
        payload.fundsOriginatorToken,
        payload.numberOfDays,
        payload.passthroughType,
        payload.createdAt,
        payload.configurationType,
      );
    }

    return networkPromise.then((json) => NetworkAnalysisManager.validateConfigJson(json));
  }

  getPassthroughV3Network(payload) {
    const networkPromise = this.communicator.fetchPassthroughV3Network(
      payload.caseId,
      payload.incomingAccountLookupIdTokens,
      payload.outgoingAccountLookupIdTokens,
      payload.ruleInstanceKey,
      payload.createdAt,
    );

    return networkPromise.then((json) => NetworkAnalysisManager.validateConfigJson(json));
  }
}

export default NetworkAnalysisManager;
