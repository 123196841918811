import Default from 'utils/constants/default';
import { FEATURE_STATUS } from 'utils/constants/constant';
import CountryRisk from 'utils/constants/country-risk';
import { getCountryCode, isCountryCodeValid } from 'utils/constants/country-list';

export const getSettingsFromState = (state, tenantId, configVersion) => {
  const settings = state?.settings ?? {};
  const settingsForTenant = settings[tenantId] ?? {};
  return settingsForTenant[configVersion ?? FEATURE_STATUS.config.active] ?? {};
};

export const getDefaultCustomerCategoryFromState = ({ state, tenantId, configVersion }) => {
  const settings = getSettingsFromState(state, tenantId, configVersion);
  return settings?.customerConfig?.defaultCustomerCategory;
};

export function getTenantDefaultCurrency(tenantConfig) {
  if (tenantConfig === undefined) {
    return Default.CURRENCY;
  }

  return tenantConfig.amountInBaseCurrency || Default.CURRENCY;
}

export function getCountryRiskLevel(country, countryRisk) {
  const map = [
    {
      key: CountryRisk.LOW,
      value: countryRisk?.lowRiskCountries,
    },
    {
      key: CountryRisk.HIGH,
      value: countryRisk?.highRiskCountries,
    },
    {
      key: CountryRisk.PROHIBITED,
      value: countryRisk?.prohibitedRiskCountries,
    },
  ];

  let countryCode = country;
  if (!isCountryCodeValid(countryCode)) {
    countryCode = getCountryCode(country);
  }

  for (let i = 0; i < map.length; i++) {
    if (map[i]?.value?.indexOf(countryCode) >= 0) {
      return map[i]?.key;
    }
  }

  return CountryRisk.MEDIUM;
}

export const mapPointsConfigs = (riskFactorInstances = []) => {
  riskFactorInstances?.forEach((instance) => {
    instance.pointsConfigs = instance?.pointsConfigs?.map((value) => ({
      group: value?.group,
      name: value?.name,
      type: value?.type,
      lowerScoreBound: value?.lowerBound,
      upperScoreBound: value?.upperBound,
      points: value?.points,
      customProperties: value?.customProperties ? { ...value.customProperties } : undefined,
    }));
  });
};
