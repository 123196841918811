import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type JSONValue = string | number | boolean | { [key: string]: JSONValue } | Array<JSONValue>;

interface FanInConfigState {
  instances: Array<JSONValue>;
  updatedAt: string;
  userId: string;
}

const initialState: FanInConfigState = {
  instances: [],
  updatedAt: '',
  userId: '',
};

export const fanInConfigReadingSlice = createSlice({
  name: 'fanIn',
  initialState,
  reducers: {
    getFanInConfig: (state) => state,
    getFanInConfigSuccess: (state, action: PayloadAction<FanInConfigState>) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    saveFanInConfig: (state) => state,
    saveFanInConfigSuccess: (state, action: PayloadAction<FanInConfigState>) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    deleteFanInConfig: (state) => state,
    deleteFanInConfigSuccess: (state, action: PayloadAction<FanInConfigState>) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
  },
});

export const {
  getFanInConfig,
  getFanInConfigSuccess,
  saveFanInConfig,
  saveFanInConfigSuccess,
  deleteFanInConfig,
} = fanInConfigReadingSlice.actions;

export default fanInConfigReadingSlice.reducer;
