import { EFFICIENCY_ACTIONS } from 'modules/dashboard/efficiency/middleware/action-types';

export const getGroupsFalsePositiveRate = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.falsePositiveRate.data,
  payload,
});

export const getGroupsFalsePositiveRateSuccess = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.falsePositiveRate.success,
  payload,
});

export const getGroupsFalsePositiveRateError = () => ({
  type: EFFICIENCY_ACTIONS.efficiency.falsePositiveRate.error,
});

export const getGroupsFalsePositivePattern = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.falsePositivePattern.data,
  payload,
});

export const getGroupsFalsePositivePatternSuccess = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.falsePositivePattern.success,
  payload,
});

export const getGroupsFalsePositivePatternError = () => ({
  type: EFFICIENCY_ACTIONS.efficiency.falsePositivePattern.error,
});

export const getGroupsTruePositivePattern = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.truePositivePattern.data,
  payload,
});

export const getGroupsTruePositivePatternSuccess = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.truePositivePattern.success,
  payload,
});

export const getGroupsTruePositivePatternError = () => ({
  type: EFFICIENCY_ACTIONS.efficiency.truePositivePattern.error,
});

export const getGroupsCasesPerformance = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.casesPerformance.data,
  payload,
});

export const getGroupsCasesPerformanceSuccess = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.casesPerformance.success,
  payload,
});

export const getGroupsCasesPerformanceError = () => ({
  type: EFFICIENCY_ACTIONS.efficiency.casesPerformance.error,
});

export const getRiskyWordsPattern = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.data,
  payload,
});

export const getRiskyWordsPatternSuccess = (payload) => ({
  type: EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.success,
  payload,
});

export const getRiskyWordsPatternError = () => ({
  type: EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.error,
});
