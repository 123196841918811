export interface SingleOption {
  heading?: string;
  key?: string;
  value?: {
    heading?: string;
  };
}

export type AllOptions = SingleOption[] | SingleOption;

export const renderOptionLabel = (singleOption: SingleOption): string | SingleOption =>
  singleOption?.heading ?? singleOption?.value?.heading ?? singleOption ?? '';

export const getOptionKey = (singleOption: SingleOption): string | SingleOption => {
  if (singleOption?.key) {
    return singleOption.key;
  }
  return singleOption;
};

export const isOptionSelected = (singleOption: SingleOption, allOptions: AllOptions): boolean => {
  if (Array.isArray(allOptions)) {
    if (singleOption?.key) {
      return Boolean(allOptions.find((data) => data.key === singleOption.key));
    }
    return Boolean(allOptions.find((data) => data === singleOption));
  }
  if (singleOption?.key && allOptions) {
    return singleOption.key === allOptions.key;
  }
  return singleOption === allOptions;
};
