import PassthroughV2ConfigAPICommunicator from 'services/passthrough-v2-config-api-communicator';
import PassthroughV2ConfigManager from 'services/passthrough-v2-config-manager';
import { showError } from 'middleware/actions/error';
import {
  deletePassthroughV2ConfigSuccess,
  getPassthroughV2ConfigSuccess,
  updatePassthroughV2ConfigSuccess,
} from 'modules/configuration/rule-manager/passthrough-v2/middleware/actions/passthrough-v2-config';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { PASSTHROUGH_V2_ACTIONS } from 'modules/configuration/rule-manager/passthrough-v2/middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';

const communicator = new PassthroughV2ConfigAPICommunicator();
const manager = new PassthroughV2ConfigManager(communicator);

function* getPassthroughV2ConfigBegin(action) {
  yield put(startProcessing(PASSTHROUGH_V2_ACTIONS.fetch.data));
  try {
    const json = yield manager.getPassthroughV2Config(action.payload);
    yield put(getPassthroughV2ConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PASSTHROUGH_V2_ACTIONS.fetch.data));
}

export function* getPassthroughV2ConfigWatcher() {
  yield takeLatest(PASSTHROUGH_V2_ACTIONS.fetch.data, getPassthroughV2ConfigBegin);
}

function* updatePassthroughV2ConfigBegin(action) {
  yield put(startProcessing(PASSTHROUGH_V2_ACTIONS.update.data));

  try {
    const json = yield manager.updatePassthroughV2Config(action.payload);
    yield put(updatePassthroughV2ConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PASSTHROUGH_V2_ACTIONS.update.data));
}

export function* updatePassthroughV2ConfigWatcher() {
  yield takeLatest(PASSTHROUGH_V2_ACTIONS.update.data, updatePassthroughV2ConfigBegin);
}

function* deletePassthroughV2ConfigBegin(action) {
  yield put(startProcessing(PASSTHROUGH_V2_ACTIONS.delete.send));

  try {
    const json = yield manager.deletePassthroughV2Config(action.payload);
    yield put(deletePassthroughV2ConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PASSTHROUGH_V2_ACTIONS.delete.send));
}

export function* deletePassthroughV2ConfigWatcher() {
  yield takeLatest(PASSTHROUGH_V2_ACTIONS.delete.send, deletePassthroughV2ConfigBegin);
}
