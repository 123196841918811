import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AmlRiskScreeningInstance } from 'modules/configuration/rule-manager/aml-risk-screening/models/aml-risk-screening';

interface ImportStatusRequest {
  provider: string;
}

interface ImportStatus {
  provider: string;
  importDate: string;
  fileDate: string;
}

interface AmlRiskScreeningConfigState {
  instances: Array<any>;
  updatedAt: string;
  userId: string;
  importStatus: ImportStatus;
}

const initialState: AmlRiskScreeningConfigState = {
  instances: [],
  updatedAt: '',
  userId: '',
  importStatus: { provider: '', importDate: '', fileDate: '' },
};

export const amlRiskScreeningConfigReadingSlice = createSlice({
  name: 'amlRiskScreening',
  initialState,
  reducers: {
    getAmlRiskScreeningConfig: (state) => state,
    getAmlRiskScreeningConfigSuccess: (
      state,
      action: PayloadAction<AmlRiskScreeningConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    saveAmlRiskScreeningConfig: (state, _action: PayloadAction<AmlRiskScreeningInstance>) => state,
    saveAmlRiskScreeningConfigSuccess: (
      state,
      action: PayloadAction<AmlRiskScreeningConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    deleteAmlRiskScreeningConfig: (state, _action: PayloadAction<string>) => state,
    deleteAmlRiskScreeningConfigSuccess: (
      state,
      action: PayloadAction<AmlRiskScreeningConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    getAmlRiskScreeningImportStatus: (state, _payload: PayloadAction<ImportStatusRequest>) => state,
    getAmlRiskScreeningImportStatusSuccess: (
      state,
      action: PayloadAction<AmlRiskScreeningConfigState>,
    ) => {
      const newState = {
        ...state,
        importStatus: action.payload?.importStatus,
      };
      return newState;
    },
  },
});

export const {
  getAmlRiskScreeningConfig,
  getAmlRiskScreeningConfigSuccess,
  saveAmlRiskScreeningConfig,
  saveAmlRiskScreeningConfigSuccess,
  deleteAmlRiskScreeningConfig,
  deleteAmlRiskScreeningConfigSuccess,
  getAmlRiskScreeningImportStatus,
  getAmlRiskScreeningImportStatusSuccess,
} = amlRiskScreeningConfigReadingSlice.actions;

export default amlRiskScreeningConfigReadingSlice.reducer;
