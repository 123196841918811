import { PASSTHROUGH_V3_ACTIONS } from 'modules/configuration/rule-manager/passthrough-v3/middleware/action-types';

export const getPassthroughV3Config = () => ({
  type: PASSTHROUGH_V3_ACTIONS.fetch.data,
});

export const getPassthroughV3ConfigSuccess = (payload) => ({
  type: PASSTHROUGH_V3_ACTIONS.fetch.success,
  payload,
});

export const updatePassthroughV3Config = (payload) => ({
  type: PASSTHROUGH_V3_ACTIONS.update.data,
  payload,
});

export const updatePassthroughV3ConfigSuccess = (payload) => ({
  type: PASSTHROUGH_V3_ACTIONS.update.success,
  payload,
});

export const deletePassthroughV3Config = (payload) => ({
  type: PASSTHROUGH_V3_ACTIONS.delete.send,
  payload,
});

export const deletePassthroughV3ConfigSuccess = (payload) => ({
  type: PASSTHROUGH_V3_ACTIONS.delete.success,
  payload,
});
