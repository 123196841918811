import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

const getCaseTenantId = () => {
  let caseTenantId = TenantManagementService.getTenantIdOfCurrentCase();
  caseTenantId = caseTenantId || TenantManagementService.getActiveTenantId();
  return caseTenantId;
};

class CaseDetailNoteCCApi extends APICommunicator {
  addCaseNote(caseId, tenantId, userId, note, type, attachments) {
    const apiUrl = API_URLS.customerService.singleCase.notes.getAll.replaceAll(
      API_PARAMS.caseId,
      caseId,
    );

    const body = { userId, note, type, tenantId };

    const data = new FormData();

    attachments?.forEach((singleAttachment) => {
      data.append('attachments', singleAttachment);
    });

    data.append('request', new Blob([JSON.stringify(body)], { type: 'application/json' }));

    return this.postForCustomTenant(
      apiUrl,
      data,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  downloadAttachment(caseId, noteId, attachmentId) {
    let apiUrl = API_URLS.customerService.singleCase.notes.getFile;
    apiUrl = apiUrl.replaceAll(API_PARAMS.caseId, caseId);
    apiUrl = apiUrl.replaceAll(API_PARAMS.noteId, noteId);
    apiUrl = apiUrl.replaceAll(API_PARAMS.id, attachmentId);

    const tenantId = getCaseTenantId();
    return this.getByteArrayFromCaseFiles(
      apiUrl,
      tenantId,
      ErrorCode.TENANT_CASE_FILES_NOT_AVAILABLE,
    );
  }
}

export default CaseDetailNoteCCApi;
