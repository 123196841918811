import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnmaskedIdentityDocument } from 'components/popover/person-details/person-details-tab/documents/identity-document';
import { RiskChip } from 'components/chips/risk-chip';

interface DataFieldProps {
  header: string;
  value?: string;
}

const DataField = ({ header, value }: DataFieldProps) => (
  <>
    <Typography variant="body2" color="textSecondary">
      {header}
    </Typography>
    {value ? <Typography variant="body2">{value}</Typography> : '-'}
  </>
);

export const DocumentDataBody = ({ document }: { document: UnmaskedIdentityDocument }) => {
  const { t } = useTranslation();

  return (
    <Grid container item flexDirection="row" spacing={1}>
      <Grid item xs={12} md={6}>
        <DataField
          header={t('case:userPopover.personal.identityDocument.kind.heading')}
          value={document.kind}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DataField
          header={t('case:userPopover.personal.identityDocument.id.heading')}
          value={document.id}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DataField
          header={t('case:userPopover.personal.identityDocument.issuingAuthority.heading')}
          value={document.issuingAuthority}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" color="textSecondary">
          {t('case:userPopover.personal.identityDocument.issuingCountry.heading')}
        </Typography>
        {document?.issuingCountryProperties?.value ? (
          <RiskChip
            size="small"
            risk={document?.issuingCountryProperties.riskLevel}
            label={document?.issuingCountryProperties.name}
          />
        ) : (
          '-'
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <DataField
          header={t('case:userPopover.personal.identityDocument.issuingState.heading')}
          value={document.issuingState}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DataField
          header={t('case:userPopover.personal.identityDocument.expiryDate.heading')}
          value={document.expiryDate}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('case:userPopover.personal.identityDocument.link.heading')}
          </Typography>
          {document.link ? (
            <Typography variant="body2" noWrap>
              <a href={document.link}>{document.link}</a>
            </Typography>
          ) : (
            '-'
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
