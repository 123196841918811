import { put, takeLatest } from 'redux-saga/effects';
import { ROUND_AMOUNT_ACTIONS } from 'modules/configuration/rule-manager/round-amount-ratio-increase-decrease/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteRoundAmountRatioIncreaseDecreaseConfigError,
  deleteRoundAmountRatioIncreaseDecreaseConfigSuccess,
  getRoundAmountRatioIncreaseDecreaseConfigError,
  getRoundAmountRatioIncreaseDecreaseConfigSuccess,
  updateRoundAmountRatioIncreaseDecreaseConfigError,
  updateRoundAmountRatioIncreaseDecreaseConfigSuccess,
} from 'modules/configuration/rule-manager/round-amount-ratio-increase-decrease/middleware/actions/round-amount-ratio-increase-decrease-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';

const communicator = new RuleConfigAPICommunicator('round-amount-ratio-increase-decrease');
const manager = new RuleConfigManager(communicator);

function* getRoundAmountRatioIncreaseDecreaseConfigBegin(action) {
  yield put(startProcessing(ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.fetch.data));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getRoundAmountRatioIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(getRoundAmountRatioIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.fetch.data));
}

export function* getRoundAmountRatioIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.fetch.data,
    getRoundAmountRatioIncreaseDecreaseConfigBegin,
  );
}

function* updateRoundAmountRatioIncreaseDecreaseConfigBegin(action) {
  yield put(startProcessing(ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.update.data));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateRoundAmountRatioIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(updateRoundAmountRatioIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.update.data));
}

export function* updateRoundAmountRatioIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.update.data,
    updateRoundAmountRatioIncreaseDecreaseConfigBegin,
  );
}

function* deleteRoundAmountRatioIncreaseDecreaseConfigBegin(action) {
  yield put(startProcessing(ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.delete.data));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteRoundAmountRatioIncreaseDecreaseConfigSuccess(json));
  } catch (error) {
    yield put(deleteRoundAmountRatioIncreaseDecreaseConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.delete.data));
}

export function* deleteRoundAmountRatioIncreaseDecreaseConfigWatcher() {
  yield takeLatest(
    ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.delete.data,
    deleteRoundAmountRatioIncreaseDecreaseConfigBegin,
  );
}
