import { VOLUME_RISK_V2_ACTIONS } from 'modules/configuration/rule-manager/volume-config-v2/middleware/action-types';

export const getVolumeRiskV2Config = () => ({
  type: VOLUME_RISK_V2_ACTIONS.fetch.data,
});

export const getVolumeRiskV2ConfigSuccess = (payload) => ({
  type: VOLUME_RISK_V2_ACTIONS.fetch.success,
  payload,
});

export const getVolumeRiskV2ConfigError = () => ({
  type: VOLUME_RISK_V2_ACTIONS.fetch.error,
});

export const updateVolumeRiskV2Config = (payload) => ({
  type: VOLUME_RISK_V2_ACTIONS.update.data,
  payload,
});

export const updateVolumeRiskV2ConfigSuccess = (payload) => ({
  type: VOLUME_RISK_V2_ACTIONS.update.success,
  payload,
});

export const updateVolumeRiskV2ConfigError = () => ({
  type: VOLUME_RISK_V2_ACTIONS.update.error,
});

export const deleteVolumeRiskV2Config = (payload) => ({
  type: VOLUME_RISK_V2_ACTIONS.delete.data,
  payload,
});

export const deleteVolumeRiskV2ConfigSuccess = (payload) => ({
  type: VOLUME_RISK_V2_ACTIONS.delete.success,
  payload,
});

export const deleteVolumeRiskV2ConfigError = () => ({
  type: VOLUME_RISK_V2_ACTIONS.delete.error,
});
