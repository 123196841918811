import { showError } from 'middleware/actions/error';
import { updateMasks } from 'middleware/actions/mask';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { getSettings } from 'middleware/actions/settings';
import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';
import {
  getCaseDetailError,
  getCaseDetailSuccess,
} from 'modules/customer-cases/case-detail/middleware/actions/customer-case-details';
import CaseDetailApiCommunicator from 'modules/customer-cases/case-detail/services/case-detail-api-communicator';
import CaseDetailManager from 'modules/customer-cases/case-detail/services/case-detail-manager';
import { put, takeLatest } from 'redux-saga/effects';
import { getMaskedsFromCase } from 'utils/helpers/cases/case-utility';

const caseDetailCommunicator = new CaseDetailApiCommunicator();
const caseDetailManager = new CaseDetailManager(caseDetailCommunicator);

function* getCaseDetail(action, queryCaseDetail) {
  yield put(startProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.data));
  try {
    const json = yield queryCaseDetail(action);

    yield put(
      getSettings({
        tenantId: json?.crrCase?.tenantId,
        configVersion: json?.crrCase?.configVersion,
      }),
    );

    yield put(
      getCaseDetailSuccess({
        type: action.type,
        data: json,
      }),
    );

    yield put(updateMasks(getMaskedsFromCase(json.crrCase, true)));
  } catch (error) {
    yield put(getCaseDetailError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.data));
}

function* getCaseDetailBegin(action) {
  yield getCaseDetail(action, ({ payload }) => {
    const { uuid, tenantId } = payload;
    return caseDetailManager.queryCaseDetail(uuid, tenantId);
  });
}

export function* getCaseDetailWatcher() {
  yield takeLatest(CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.data, getCaseDetailBegin);
}
