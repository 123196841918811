import { RoundAmountConfigActions } from 'modules/configuration/rule-manager/round-amount/middleware/action-types';

const roundAmountConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_GET_SUCCESS:
    case RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_UPDATE_SUCCESS:
    case RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_DELETE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default roundAmountConfigReducer;
