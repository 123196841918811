import { generatePath } from 'react-router';
import TenantManagementService from 'services/tenant/tenant-management-service';
import config from 'utils/helpers/config-utils/config';
import { getCasesRoute } from 'utils/helpers/route-util';

export function getCaseDetailURL(defaultTenantId, path, caseId, isCustomerCase) {
  const rootUrl = isCustomerCase ? config.APP_ROOT_URL_CUSTOMERS : config.APP_ROOT_URL;
  if (defaultTenantId) {
    return `${rootUrl}/${defaultTenantId}${path}/${caseId}`;
  }

  return `${rootUrl}/${path}/${caseId}`;
}

export function buildRoute(pathName, params) {
  const activeTenantId = TenantManagementService.getActiveTenantId();
  let route = pathName.path ? pathName.path : pathName;
  if (activeTenantId) {
    route = generatePath(route, {
      ...params,
      tenantId: activeTenantId,
    });
  }
  return route;
}

export function buildRouteToCaseDetail(caseStatus, caseId) {
  const caseRoute = getCasesRoute(caseStatus);
  const route = buildRoute(caseRoute.path);
  return `${route}/${caseId}`;
}
