import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class ProductTypeListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Product Type List API Communicator instance is required.');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.PRODUCT_TYPE_LIST_DATA_INVALID);
  }

  getProductTypeFileListIndividual(tenantId) {
    return this.communicator
      .fetchProductTypeFilesListIndividual(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  getProductTypeFileListOrganization(tenantId) {
    return this.communicator
      .fetchProductTypeFilesListOrganization(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  updateActiveProductTypeListVersionIndividual(tenantId, payload) {
    return this.communicator.updateActiveProductTypeListVersionIndividual(
      tenantId,
      payload.version,
    );
  }

  updateActiveProductTypeListVersionOrganization(tenantId, payload) {
    return this.communicator.updateActiveProductTypeListVersionOrganization(
      tenantId,
      payload.version,
    );
  }
}

export default ProductTypeListManager;
