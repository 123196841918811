function concatTransactions(prevTransactions, newTransactions) {
  if (prevTransactions.length === 0) {
    prevTransactions = newTransactions;
  } else {
    newTransactions.forEach((newTx) => {
      const tx = prevTransactions.find((t) => t.uuid === newTx.uuid);
      if (tx === undefined) {
        prevTransactions.push(newTx);
      }
    });
  }
  return prevTransactions;
}

export function getDerivedTransactionHistory(state, type, prevTransactions, newTransactions) {
  const transactionHistory = state.transactionHistory || {};
  return {
    ...transactionHistory,
    [type]: concatTransactions(prevTransactions || [], newTransactions || []),
  };
}
