export const RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS = {
  fileList: {
    fetch: 'GET_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG',
    success: 'GET_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_SUCCESS',
    error: 'GET_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ERROR',
  },
  update: {
    data: 'RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_UPDATE',
    success: 'RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_UPDATE_SUCCESS',
    error: 'RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_UPDATE_ERROR',
  },
  delete: {
    data: 'DELETE_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG',
    success: 'DELETE_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_SUCCESS',
    error: 'DELETE_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG',
    success: 'ACTIVATE_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_SUCCESS',
    error: 'ACTIVATE_RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ERROR',
  },
};
