import { RISKY_WORDS_V2_LIST_ACTIONS } from 'middleware/action-types';

export const getRiskyWordsV2FileList = (tenantId) => ({
  type: RISKY_WORDS_V2_LIST_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getRiskyWordsV2FileListSuccess = (payload) => ({
  type: RISKY_WORDS_V2_LIST_ACTIONS.fileList.success,
  payload,
});

export const getRiskyWordsV2FileListError = () => ({
  type: RISKY_WORDS_V2_LIST_ACTIONS.fileList.error,
});

export const activateRiskyWordsV2List = (version) => ({
  type: RISKY_WORDS_V2_LIST_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateRiskyWordsV2ListSuccess = (payload) => ({
  type: RISKY_WORDS_V2_LIST_ACTIONS.activateVersion.success,
  payload,
});

export const activateRiskyWordsV2ListError = () => ({
  type: RISKY_WORDS_V2_LIST_ACTIONS.activateVersion.error,
});
