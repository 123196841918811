import { CUSTOMER_DETAILS_ACTIONS } from 'middleware/action-types';

const customerDetailsReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_ACTIONS.get.data:
    case CUSTOMER_DETAILS_ACTIONS.get.error: {
      return {
        ...state,
      };
    }

    case CUSTOMER_DETAILS_ACTIONS.get.success: {
      return {
        ...state,
        [action.payload?.caseId]: {
          accountHoldingPartyData: action.payload?.accountHoldingPartyData,
          underwriterData: action.payload?.underwriterData,
          riskRatingData: action.payload?.riskRatingData,
          customerCategory: action.payload?.customerCategory,
        },
      };
    }

    default:
      return state;
  }
};

export default customerDetailsReducer;
