import { BLACKLIST_LIST_ACTIONS } from 'middleware/action-types';
import {
  activateBlacklistListError,
  activateBlacklistListSuccess,
  getBlacklistFileListError,
  getBlacklistFileListSuccess,
} from 'middleware/actions/blacklist-list-config';

import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import BlacklistListApiCommunicator from 'services/blacklist-list-api-communicator';
import BlacklistListManager from 'services/blacklist-list-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';

const communicator = new BlacklistListApiCommunicator();
const manager = new BlacklistListManager(communicator);

/**
 * Fetch customer occupation files action
 */
export function* getBlacklistFileListBegin(action) {
  try {
    yield put(startProcessing(BLACKLIST_LIST_ACTIONS.fileList.fetch));

    const json = yield manager.getBlacklistFileList(TenantManagementService.getActiveTenantId());
    yield put(getBlacklistFileListSuccess(json));
    yield put(stopProcessing(BLACKLIST_LIST_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getBlacklistFileListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(BLACKLIST_LIST_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch customer occupation files action watcher
 */
export function* getBlacklistFileListWatcher() {
  yield takeLatest(BLACKLIST_LIST_ACTIONS.fileList.fetch, getBlacklistFileListBegin);
}

/**
 *  Update active risky words v2 file version action
 */
export function* updateActiveBlacklistListVersionBegin(action) {
  try {
    yield put(startProcessing(BLACKLIST_LIST_ACTIONS.activateVersion.send));

    const json = yield manager.updateBlacklistListVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateBlacklistListSuccess(json));

    yield put(stopProcessing(BLACKLIST_LIST_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateBlacklistListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(BLACKLIST_LIST_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active customer occupation file version action watcher
 */
export function* updateActiveBlacklistListVersionWatcher() {
  yield takeLatest(
    BLACKLIST_LIST_ACTIONS.activateVersion.send,
    updateActiveBlacklistListVersionBegin,
  );
}
