import { Translation, useTranslation } from 'react-i18next';
import { Box, Divider, FormHelperText, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import i18next from 'i18next';
import { RescreeningIcon } from 'components/icons/icons';

const buildScreeningTypeValuesFromUrl = (filter) => ({
  condition: filter.condition,
  field: i18next.t('mapping:filters.filtering.rescreening.key'),
});

const ScreeningTypeForm = ({ editForm, onSubmit }) => {
  const { t } = useTranslation();

  const screeningOptions = Object.values(t('mapping:operator.condition'));
  screeningOptions.forEach((element) => {
    if (element.key === t('mapping:operator.condition.IS_TRUE.key')) {
      element.value = (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <RescreeningIcon />
          </Grid>
          <Grid item>{element.value.heading}</Grid>
        </Grid>
      );
    }
  });

  const initialFValues = {
    condition: editForm?.condition || t('mapping:operator.condition.IS_TRUE.key'),
  };
  // eslint-disable-next-line no-use-before-define
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };

    if ('condition' in fieldValues) {
      // eslint-disable-next-line no-use-before-define
      temp.condition = fieldValues.condition ? '' : t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: values.condition,
        field: t('mapping:filters.filtering.rescreening.key'),
      });
    }
  };

  return (
    <Translation>
      {(t) => (
        <Grid container item>
          <Form onSubmit={handleSubmit} className="w-100">
            <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
              <Controls.RadioGroup
                name="condition"
                options={screeningOptions}
                defaultValue={initialFValues.condition}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            {errors.flow && (
              <FormHelperText className="pl-3" error>
                {errors.flow}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const ScreeningTypeChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.rescreening.value.heading')}
        </Box>

        <Box fontSize="body2.fontSize">
          {form.condition === t('mapping:operator.condition.IS_TRUE.key') && (
            <RescreeningIcon fontSize="small" className="mr-1" />
          )}

          {t(`mapping:operator.condition.${form.condition}.value.heading`)}
        </Box>
      </>
    )}
  </Translation>
);

export { ScreeningTypeForm, ScreeningTypeChip, buildScreeningTypeValuesFromUrl };
