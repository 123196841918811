import { randomHtmlId } from 'utils/helpers/math-util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface InfoTooltipProps {
  id?: string;
}

const InfoTooltip: React.FC<PropsWithChildren<InfoTooltipProps>> = ({ children, id }) => {
  const tooltipId = id ?? randomHtmlId();
  return (
    <Tooltip
      componentsProps={{ tooltip: { className: 'content' } }}
      title={
        <Typography variant="body2" component="div" style={{ whiteSpace: 'pre-line' }}>
          {children}
        </Typography>
      }
    >
      <IconButton size="small">
        <InfoOutlinedIcon id={tooltipId} />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
