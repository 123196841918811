import GlobalContext from 'contexts/global/global-context';
import { isEmpty, isNil } from 'lodash';
import { useContext, useEffect, useState } from 'react';

const FEATURE_LINK_PATH = 'https://hawkai.atlassian.net/browse';

/**
 * Feature toggle hook for dev purposes. Checks if passed tenantIds match with currently selected tenant.
 * @param tenantIds - Tenant IDS. Accepts array and string as well.
 * @param featureLink - Link to the feature.
 * @param expirationDate - When the feature toggle should be removed.
 * @returns {{isAlphaFeature: boolean}}
 */
const useAlphaFeature = (tenantIds, featureLink, expirationDate) => {
  const { selectedTenant } = useContext(GlobalContext) || {};
  const [isAlphaFeature, setIsAlphaFeature] = useState(tenantIds.includes(selectedTenant?.id));

  if (isEmpty(featureLink) || !featureLink.startsWith(FEATURE_LINK_PATH)) {
    throw Error(
      'Parameter featureLink is required and make sure it starts with https://hawkai.atlassian.net/browse',
    );
  }

  if (isNil(expirationDate) || !(expirationDate instanceof Date)) {
    throw Error("The date parameter is required or it's not Date type");
  }

  if (isNil(selectedTenant) || isNil(selectedTenant.id)) {
    throw Error('Selected tenant ID not found');
  }

  useEffect(() => {
    setIsAlphaFeature(tenantIds.includes(selectedTenant?.id));
  }, [selectedTenant, tenantIds]);

  return {
    isAlphaFeature,
  };
};

export { useAlphaFeature };
