import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { RISK_CATEGORY } from 'utils/constants/constant';

/**
 * representing chips with risk
 * @param {*} param
 */
const RiskChip = ({
  size,
  label,
  tooltip,
  risk,
  disabled,
  icon,
  clickable,
  id,
  dataTestIdPrefix,
  className,
}) => {
  let riskValue = risk?.toLowerCase();
  riskValue = riskValue?.replace(RISK_CATEGORY.default, '');

  const dataTestId =
    id ?? (dataTestIdPrefix ? `${dataTestIdPrefix}-risk-chip` : 'risk-rating-chip');
  const theme = useTheme();
  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Chip
            icon={icon}
            disabled={disabled}
            style={theme.custom?.riskChipComponent?.[riskValue]}
            size={size}
            label={label}
            clickable={clickable}
            data-testid={dataTestId}
          />
        </Tooltip>
      ) : (
        <Chip
          className={className}
          icon={icon}
          disabled={disabled}
          style={theme.custom?.riskChipComponent?.[riskValue]}
          size={size}
          label={label}
          clickable={clickable}
          data-testid={dataTestId}
        />
      )}
    </>
  );
};

export { RiskChip };
