import { put, takeLatest } from 'redux-saga/effects';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { showError } from 'middleware/actions/error';
import { FanInConfigApi } from 'modules/configuration/machine-intelligence/fan-in/services/fan-in-config-api-communicator';
import {
  getFanInConfigSuccess,
  saveFanInConfigSuccess,
} from 'modules/configuration/machine-intelligence/fan-in/middleware/reducers/fan-in-reducer';

const communicator = new FanInConfigApi();

function* getFanInConfigBegin(action) {
  yield put(startProcessing('fanIn/getFanInConfig'));
  try {
    const json = yield communicator.fetchConfig();
    yield put(getFanInConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('fanIn/getFanInConfig'));
}

export function* getFanInConfigWatcher() {
  yield takeLatest('fanIn/getFanInConfig', getFanInConfigBegin);
}

function* saveFanInConfigBegin(action) {
  yield put(startProcessing('fanIn/saveFanInConfig'));

  try {
    const json = yield communicator.updateConfig(action.payload);
    yield put(saveFanInConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing('fanIn/saveFanInConfig'));
}

export function* saveFanInConfigWatcher() {
  yield takeLatest('fanIn/saveFanInConfig', saveFanInConfigBegin);
}

function* deleteFanInConfigBegin(action) {
  yield put(startProcessing('fanIn/deleteFanInConfig'));
  try {
    const json = yield communicator.deleteConfig(action.payload);
    yield put(saveFanInConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('fanIn/deleteFanInConfig'));
}

export function* deleteFanInConfigWatcher() {
  yield takeLatest('fanIn/deleteFanInConfig', deleteFanInConfigBegin);
}
