import { isObject, isNil, isEmpty, isNumber, every, map } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmptyNestedChecks = (obj: any): boolean => {
  if (isObject(obj)) {
    if (Object.keys(obj).length === 0) return true;
    return every(map(obj, (v) => isEmptyNestedChecks(v)));
  }

  if (isNumber(obj)) return false;
  return isNil(obj) || isEmpty(obj);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPropertyValue = (obj: any, fieldWithDot: string): any => {
  const fields = fieldWithDot.split('.');
  let propertyValue = obj;
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (propertyValue) {
      propertyValue = propertyValue[field];
    }
  }
  return propertyValue;
};
