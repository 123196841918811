import { useAuth0 } from '@auth0/auth0-react';
import RedirectUrl from 'utils/helpers/redirect-url';
import { Redirect } from 'react-router-dom';
import SessionService from 'modules/authentication/login/services/session-service';
import { useAuthorization } from 'hooks/use-authorization';
import { useTenantConfig } from 'hooks/use-tenant-config';

const InitialRedirect = () => {
  const { isAuthenticated: isAuth0Authenticated } = useAuth0();
  const authService = new SessionService();
  const isAuthenticatedDatabase = authService.isAuthenticated();

  const isAuthenticated = isAuth0Authenticated || isAuthenticatedDatabase;

  const { userPermissions, detachmentConfig } = useAuthorization();
  const { tenantConfig } = useTenantConfig();

  if (!isAuthenticated) return <Redirect to="/" />;

  if (isAuthenticated && detachmentConfig && userPermissions.length > 0) {
    const redirectUrl = RedirectUrl.getRedirectUrl(isAuthenticated, {
      detachmentConfig,
      userPermissions,
      tenantConfig,
    });

    return <Redirect to={redirectUrl} />;
  }

  return <></>;
};

export default InitialRedirect;
