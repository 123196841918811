import APICommunicator from 'services/api-communicator';
import ErrorCode from 'utils/constants/error-code';
import { API_URLS, API_PARAMS } from '../utils/constants/api-config';

class MccListApiCommunicator extends APICommunicator {
  fetchMccFilesList(tenantId) {
    const apiUrl = API_URLS.mccService.getMetadata.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.MCC_LIST_DATA_NOT_FOUND, tenantId);
  }

  updateActiveMccListVersion(tenantId, version) {
    const apiUrl = API_URLS.mccService.updateActiveVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default MccListApiCommunicator;
