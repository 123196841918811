import { Box, Chip } from '@mui/material';
import React from 'react';
import { Translation } from 'react-i18next';

interface RuleInstancesChipProps {
  form: {
    values: string[];
  };
}

export const RuleInstancesChip = ({ form }: RuleInstancesChipProps) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.ruleInstances.value.heading')}
        </Box>
        {form.values.map((item, index) => (
          <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
        ))}
      </>
    )}
  </Translation>
);
