import { SmurfingConfigActions } from 'modules/configuration/rule-manager/smurfing/middleware/action-types';

export const getSmurfingV2Config = () => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_GET,
});

export const getSmurfingV2ConfigSuccess = (payload) => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_GET_SUCCESS,
  payload,
});

export const getSmurfingV2ConfigError = () => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_GET_ERROR,
});

export const updateSmurfingV2Config = (payload) => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_UPDATE,
  payload,
});

export const updateSmurfingV2ConfigSuccess = (payload) => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateSmurfingV2ConfigError = () => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_UPDATE_ERROR,
});

export const deleteSmurfingV2Config = (payload) => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_DELETE,
  payload,
});

export const deleteSmurfingV2ConfigSuccess = (payload) => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_DELETE_SUCCESS,
  payload,
});

export const deleteSmurfingV2ConfigError = () => ({
  type: SmurfingConfigActions.SMURFING_V2_CONFIG_DELETE_ERROR,
});
