import { cloneDeep } from 'lodash';
import { CUSTOMER_CASE_ACTIONS } from 'modules/customer-cases/case-list/middleware/action-types';

export const getAllCases = (size, scrollId, sortingConfig, filterConfig, allMaskedDataShown) => ({
  type: CUSTOMER_CASE_ACTIONS.allCases.fetch.data,
  payload: {
    paging: {
      pageSize: size,
      scrollId,
    },
    sorting: {
      orders: sortingConfig,
    },
    filtering: {
      filters: cloneDeep(filterConfig),
      operator: 'AND',
    },
    allMaskedDataShown,
  },
});

export const getAllCasesSuccess = (payload) => ({
  type: CUSTOMER_CASE_ACTIONS.allCases.fetch.success,
  payload,
});

export const getAllCasesError = () => ({
  type: CUSTOMER_CASE_ACTIONS.allCases.fetch.error,
});
