import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteRoundAmountConfigError,
  deleteRoundAmountConfigSuccess,
  getRoundAmountConfigError,
  getRoundAmountConfigSuccess,
  updateRoundAmountConfigError,
  updateRoundAmountConfigSuccess,
} from 'modules/configuration/rule-manager/round-amount/middleware/actions/round-amount-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';
import { RoundAmountConfigActions } from 'modules/configuration/rule-manager/round-amount/middleware/action-types';

const communicator = new RuleConfigAPICommunicator('round-amount');
const manager = new RuleConfigManager(communicator);

function* getRoundAmountConfigBegin(action) {
  yield put(startProcessing(RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getRoundAmountConfigSuccess(json));
  } catch (error) {
    yield put(getRoundAmountConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_GET));
}

export function* getRoundAmountConfigWatcher() {
  yield takeLatest(RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_GET, getRoundAmountConfigBegin);
}

function* updateRoundAmountConfigBegin(action) {
  yield put(startProcessing(RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateRoundAmountConfigSuccess(json));
  } catch (error) {
    yield put(updateRoundAmountConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_UPDATE));
}

export function* updateRoundAmountConfigWatcher() {
  yield takeLatest(
    RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_UPDATE,
    updateRoundAmountConfigBegin,
  );
}

function* deleteRoundAmountConfigBegin(action) {
  yield put(startProcessing(RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteRoundAmountConfigSuccess(json));
  } catch (error) {
    yield put(deleteRoundAmountConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_DELETE));
}

export function* deleteRoundAmountConfigWatcher() {
  yield takeLatest(
    RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_DELETE,
    deleteRoundAmountConfigBegin,
  );
}
