import { Grid, ListItemText, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Translation } from 'react-i18next';
import { WheelPoints } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points';

const ClickableWheelPointsWithHeadline = ({
  'data-testid': dataTestid,
  points,
  maxPoints,
  size,
  headline,
  subHead,
  onClick,
}) => (
  <Translation>
    {(t) => (
      <Grid
        container
        data-testid={dataTestid}
        justifyContent="space-between"
        alignItems="center"
        className="clickable"
        onClick={onClick}
        spacing={2}
        wrap="nowrap"
      >
        <Grid item container className="w-auto pl-3 position-relative">
          <WheelPoints
            points={points}
            maxPoints={maxPoints}
            size={size}
            tooltip={
              <>
                <Typography variant="overline" component="p" className="color-white lh-1">
                  {points ?? 0}
                  {t('configuration:customerRiskRating.riskFactor.points.pts.heading')}
                </Typography>

                <Typography variant="overline" className="color-on-surface-disabled lh-1">
                  {maxPoints}
                  {t('configuration:customerRiskRating.riskFactor.points.pts.max.subheading')}
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item container xs alignItems="center" className="position-relative">
          <ListItemText
            primary={
              <Typography variant="body2" className={size === 'small' ? 'lh-1' : ''}>
                {headline}
              </Typography>
            }
            secondary={
              <Typography variant={size === 'small' ? 'caption' : 'body2'} color="textSecondary">
                {subHead}
              </Typography>
            }
          />
        </Grid>
        <Grid container item justifyContent="flex-end" className="w-auto position-relative">
          <ArrowForward color="primary" className="mr-1" />
        </Grid>
      </Grid>
    )}
  </Translation>
);

// eslint-disable-next-line import/prefer-default-export
export { ClickableWheelPointsWithHeadline };
