import { PassthroughConfigActions } from 'modules/configuration/rule-manager/passthrough/middleware/action-types';

const passthroughConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case PassthroughConfigActions.PASSTHROUGH_CONFIG_GET_SUCCESS:
    case PassthroughConfigActions.PASSTHROUGH_CONFIG_UPDATE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default passthroughConfigReducer;
