import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class RiskyWordsV2ListApiCommunicator extends APICommunicator {
  fetchRiskyWordsInstantiableListConfig(tenantId) {
    const apiUrl = API_URLS.listManager.riskyWords.fetch.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.RISKY_WORDS_LIST_DATA_NOT_FOUND, tenantId);
  }

  updateRiskyWordsInstantiableListConfig(data) {
    const apiUrl = API_URLS.listManager.riskyWords.upsert.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.postJSONForTenant(
      apiUrl,
      { instances: data },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteRiskyWordsInstantiableListConfig(id) {
    const apiUrl = API_URLS.listManager.riskyWords.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.id, id);

    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateActiveRiskyWordsInstantiableListConfigVersion(tenantId, version) {
    const apiUrl = API_URLS.listManager.riskyWords.updateActiveVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default RiskyWordsV2ListApiCommunicator;
