import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

import { RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/action-types';
import {
  activateRiskyWordsInstantiableListConfigError,
  activateRiskyWordsInstantiableListConfigSuccess,
  deleteRiskyWordsInstantiableListConfigError,
  deleteRiskyWordsInstantiableListConfigSuccess,
  getRiskyWordsInstantiableListConfigError,
  getRiskyWordsInstantiableListConfigSuccess,
  updateRiskyWordsInstantiableListConfigError,
  updateRiskyWordsInstantiableListConfigSuccess,
} from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/actions/risky-words-instantiable-list-config';
import RiskyWordsInstantiableListApiCommunicator from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/services/risky-words-instantiable-list-api-communicator';
import RiskyWordsInstantiableListManager from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/services/risky-words-instantiable-list-manager';
import { put, takeLatest } from 'redux-saga/effects';
import TenantManagementService from 'services/tenant/tenant-management-service';

const apiCommunicator = new RiskyWordsInstantiableListApiCommunicator();
const occupationListManager = new RiskyWordsInstantiableListManager(apiCommunicator);

/**
 * Fetch customer occupation files action
 */
export function* getRiskyWordsInstantiableConfigListBegin(action) {
  try {
    yield put(startProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));

    const json = yield occupationListManager.getRiskyWordsInstantiableListConfig(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getRiskyWordsInstantiableListConfigSuccess(json));

    yield put(stopProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getRiskyWordsInstantiableListConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch customer occupation files action watcher
 */
export function* getRiskyWordsInstantiableListConfigWatcher() {
  yield takeLatest(
    RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch,
    getRiskyWordsInstantiableConfigListBegin,
  );
}

/**
 *  Update active risky words v2 file version action
 */
export function* updateActiveRiskyWordsInstantiableListConfigVersionBegin(action) {
  try {
    yield put(startProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));

    const json = yield occupationListManager.updateActiveRiskyWordsInstantiableListConfigVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateRiskyWordsInstantiableListConfigSuccess(json));

    yield put(stopProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateRiskyWordsInstantiableListConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active customer occupation file version action watcher
 */
export function* updateActiveRiskyWordInstantiableListConfigVersionWatcher() {
  yield takeLatest(
    RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send,
    updateActiveRiskyWordsInstantiableListConfigVersionBegin,
  );
}

function* updateRiskyWordsInstantiableConfigListConfigBegin(action) {
  yield put(startProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data));
  try {
    const json = yield occupationListManager.updateRiskyWordsInstantiableListConfig(action.payload);
    yield put(updateRiskyWordsInstantiableListConfigSuccess(json));
  } catch (error) {
    yield put(updateRiskyWordsInstantiableListConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data));
}

export function* updateRiskyWordsInstantiableConfigListConfigWatcher() {
  yield takeLatest(
    RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data,
    updateRiskyWordsInstantiableConfigListConfigBegin,
  );
}

function* deleteRiskyWordsInstantiableConfigListConfigBegin(action) {
  yield put(startProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data));

  try {
    const json = yield occupationListManager.deleteRiskyWordsInstantiableListConfig(action.payload);
    yield put(deleteRiskyWordsInstantiableListConfigSuccess(json));
  } catch (error) {
    yield put(deleteRiskyWordsInstantiableListConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data));
}

export function* deleteRiskyWordsInstantiableConfigListConfigWatcher() {
  yield takeLatest(
    RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data,
    deleteRiskyWordsInstantiableConfigListConfigBegin,
  );
}
