import { ToolingActions, TOOLING_ACTIONS } from 'middleware/action-types';

export const postReprocessCase = (payload) => ({
  type: ToolingActions.REPROCESS_CASE_POST,
  payload,
});

export const postReprocessCaseSuccess = (payload) => ({
  type: ToolingActions.REPROCESS_CASE_POST_SUCCESS,
  payload,
});

export const postReprocessCaseError = (error) => ({
  type: ToolingActions.REPROCESS_CASE_POST_ERROR,
  payload: error,
});

export const queryReprocessCases = (payload) => ({
  type: TOOLING_ACTIONS.query.send,
  payload,
});

export const queryReprocessCasesSuccess = (payload) => ({
  type: TOOLING_ACTIONS.query.success,
  payload,
});

export const queryReprocessCasesError = (error) => ({
  type: TOOLING_ACTIONS.query.error,
  payload: error,
});

export const reprocessCases = (payload) => ({
  type: TOOLING_ACTIONS.reprocess.send,
  payload,
});

export const reprocessCasesSuccess = (payload) => ({
  type: TOOLING_ACTIONS.reprocess.success,
  payload,
});

export const reprocessCasesError = (error) => ({
  type: TOOLING_ACTIONS.reprocess.error,
  payload: error,
});
