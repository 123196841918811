import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import i18next from 'i18next';
import { showNotification } from 'middleware/actions/notification';
import { showError } from 'middleware/actions/error';
import { USER_MANAGEMENT_ACTIONS } from 'modules/configuration/workflows-and-users/components/user-management/middleware/action-types';
import {
  saveTenantUserManagementConfigError,
  saveTenantUserManagementConfigSuccess,
} from 'modules/configuration/workflows-and-users/components/user-management/middleware/actions/user-management';
import UserManagementApi from 'modules/configuration/workflows-and-users/components/user-management/services/user-management-api';
import { UserManagementConfig } from 'models/configuration/user-management-config';

const manager = new UserManagementApi();

const userManagementConfigActions = Object.values(USER_MANAGEMENT_ACTIONS.userManagementConfig);

type ActionType = typeof userManagementConfigActions[number];

type Action = {
  type: ActionType;
  payload: UserManagementConfig;
};

function* putTenantUserManagementConfigBegin(action: Action): Generator {
  yield put(startProcessing(USER_MANAGEMENT_ACTIONS.userManagementConfig.data));
  try {
    const json = yield manager.putTenantUserManagementConfig(action.payload);
    yield put(saveTenantUserManagementConfigSuccess(json as UserManagementConfig));
    const successMessage = {
      message: i18next.t('configuration:notifications.success.heading'),
    };
    yield put(showNotification(successMessage));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(saveTenantUserManagementConfigError());
    yield showError({ ...error, action });
  }
  yield put(stopProcessing(USER_MANAGEMENT_ACTIONS.userManagementConfig.data));
}

export function* putTenantUserManagementConfigWatcher() {
  yield takeLatest(
    USER_MANAGEMENT_ACTIONS.userManagementConfig.data,
    putTenantUserManagementConfigBegin,
  );
}
