import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

const getCaseTenantId = () =>
  TenantManagementService.getTenantIdOfCurrentCase() || TenantManagementService.getActiveTenantId();

class PepSanctionApiCommunicator extends APICommunicator {
  assessPepSanction(params) {
    const apiUrl = API_URLS.assessmentService.pepSanction.assess.replaceAll(
      API_PARAMS.caseId,
      params?.caseId,
    );

    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      params,
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default PepSanctionApiCommunicator;
