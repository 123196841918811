import { Avatar, Badge, Chip, Grid, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RiskChip } from 'components/chips/risk-chip';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { getAccountIcon } from 'components/icons/icons';
import { CustomerProfileLink } from 'components/popover/person-details/customer-profile-link';
import { RiskRatingData } from 'components/popover/person-details/person-details-tab/risk-rating-data';
import { useAuthorization } from 'hooks/use-authorization';
import React from 'react';
import { Translation } from 'react-i18next';
import { filterBusinessPurposeHintsByCode } from 'utils/constants/business-purpose-hints';
import { RISK_CATEGORY } from 'utils/constants/constant';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import { getPersonLabel } from 'utils/helpers/cases/case-utility';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { makeSnakeCaseReadable } from 'utils/helpers/string-util';

/**
 * retrieving correct icon to represent customer
 */
const SmallAvatar = styled(Avatar)(() => ({
  width: 24,
  height: 24,
  border: `2px solid #fff`,
  background: '#fff',
}));

/**
 * header for popover of person details
 */
const PersonDetailsHeader = ({
  personDetails,
  personName,
  isCustomer,
  onClose,
  isPageContent,
  customerRiskRatingData,
  isIndividual,
  caseConfig,
  showCustomerDetailsLink,
}) => {
  const { detachmentConfig, userPermissions } = useAuthorization();

  const riskLevel = personDetails?.riskLevel?.replace(RISK_CATEGORY.default, '');
  let calculatedRiskLevel = isCustomer
    ? personDetails?.calculatedRiskLevel?.replace(RISK_CATEGORY.default, '')
    : riskLevel;

  calculatedRiskLevel = calculatedRiskLevel?.toLowerCase();

  const businessPurpose = personDetails?.companyDetails?.businessPurposes;
  const customerIdToken = personDetails?.customerId?.token;

  const getInitials = () => {
    const initials = personName?.charAt?.(0) ?? '-';
    return initials.toUpperCase();
  };

  const isStaff = Boolean(personDetails?.role);
  const staffRole = isStaff ? makeSnakeCaseReadable(personDetails.role) : '-';

  const PersonHeader = () => (
    <Translation>
      {(t) => (
        <Grid container spacing={1}>
          <Grid item>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                isStaff ? null : (
                  <SmallAvatar>
                    {getAccountIcon(isCustomer, !isIndividual, calculatedRiskLevel, 'small')}
                  </SmallAvatar>
                )
              }
            >
              <Avatar sx={{ width: 55, height: 55 }}>{getInitials()}</Avatar>
            </Badge>
          </Grid>
          <Grid item xs container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography variant="body2" className="text-capitalize">
                  {personName ?? (
                    <span className="font-italic color-on-surface-secondary">
                      {getPersonLabel(isCustomer, isStaff)}
                    </span>
                  )}
                  {showCustomerDetailsLink && (
                    <CustomerProfileLink customerIdToken={customerIdToken} />
                  )}
                </Typography>
                {calculatedRiskLevel && (
                  <RiskChip
                    size="small"
                    risk={calculatedRiskLevel}
                    label={t(`riskCategory.${calculatedRiskLevel}.heading`)}
                    tooltip={t(`riskCategory.heading`)}
                  />
                )}
                {isStaff && (
                  <Typography variant="body2" color="textSecondary" className="text-capitalize">
                    <span>{staffRole}</span>
                  </Typography>
                )}
                {businessPurpose?.map((product, index) => (
                  <Tooltip
                    key={index}
                    title={filterBusinessPurposeHintsByCode(product).map(
                      (purpose, purposeIndex) => (
                        <p key={purposeIndex} className="mb-0">
                          {purpose.hint}
                        </p>
                      ),
                    )}
                  >
                    <Chip label={product} size="small" key={index} className="ml-1" />
                  </Tooltip>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {!!onClose && (
            <Grid item className="mt-n2">
              <IconButtons.Close onClick={onClose} edge="end" />
            </Grid>
          )}
        </Grid>
      )}
    </Translation>
  );

  const PersonCrrHeader = () => (
    <Translation>
      {(t) => (
        <Grid container spacing={1}>
          <Grid item>
            <RiskRatingData
              popoverMode={!isPageContent}
              getScoreOnly
              isIndividual={isIndividual}
              caseConfig={caseConfig}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...customerRiskRatingData}
            />
          </Grid>

          <Grid item xs container alignItems="center">
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  variant={isPageContent ? 'h5' : 'body2'}
                  className="text-capitalize mb-2"
                  color="textPrimary"
                >
                  {/* <HoverMaskedText
                    {...personDetails?.companyDetails?.companyName}
                  /> */}

                  {personName ?? (
                    <span className="font-italic color-on-surface-secondary">
                      {isCustomer ? t('customer.heading') : t('counterParty.heading')}
                    </span>
                  )}
                  <span className="pl-2">
                    {getAccountIcon(isCustomer, !isIndividual, calculatedRiskLevel, 'small')}
                  </span>
                  {showCustomerDetailsLink && (
                    <CustomerProfileLink customerIdToken={customerIdToken} />
                  )}
                </Typography>
                {calculatedRiskLevel && (
                  <RiskChip
                    size="small"
                    risk={calculatedRiskLevel}
                    label={t(`riskCategory.${calculatedRiskLevel}.heading`)}
                    tooltip={t(`riskCategory.heading`)}
                  />
                )}
                {businessPurpose?.map((product, index) => (
                  <Tooltip
                    key={index}
                    title={filterBusinessPurposeHintsByCode(product).map(
                      (purpose, purposeIndex) => (
                        <p key={purposeIndex} className="mb-0">
                          {purpose.hint}
                        </p>
                      ),
                    )}
                  >
                    <Chip label={product} size="small" key={index} className="ml-1" />
                  </Tooltip>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {!isPageContent && (
            <Grid item className="mt-n2">
              <IconButtons.Close onClick={onClose} edge="end" />
            </Grid>
          )}
        </Grid>
      )}
    </Translation>
  );

  return (isIndividual
    ? isFeatureAccessible(
        DETACHMENT_CONFIG.customerRiskRating.individual,
        detachmentConfig,
        userPermissions,
      )
    : isFeatureAccessible(
        DETACHMENT_CONFIG.customerRiskRating.organization,
        detachmentConfig,
        userPermissions,
      )) &&
    customerRiskRatingData &&
    isCustomer ? (
    <PersonCrrHeader />
  ) : (
    <PersonHeader />
  );
};

export { PersonDetailsHeader };
