import { APICommunicator } from 'services/api-communicator';
import { errorLog } from 'services/api/error-handling/error-reporting';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_URLS } from 'utils/constants/api-config';
import { HTTP_METHODS } from 'utils/constants/constant';
import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class ToolingApiCommunicator extends APICommunicator {
  postForReprocessing(obj, tenantId, errorCodeIfError) {
    const apiUrl = API_URLS.reprocessingService.v1.reprocess;

    return fetch(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        tenantId,
        correlationId: '00000000-0000-0000-0000-000000000000',
      },
      body: JSON.stringify(obj),
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: obj,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postForReprocessing',
        };
        errorLog(errorReportObj);

        // eslint-disable-next-line no-console
        console.log(error.toString());
        return raiseErrorByCode(errorCodeIfError);
      });
  }

  query({ tenantIds, queryJson }) {
    const apiUrl = API_URLS.reprocessingService.v2.query;
    const activeTenantId = TenantManagementService.getActiveTenantId();
    const body = {
      query: queryJson,
    };
    return this.postJSONForListTenants(
      apiUrl,
      body,
      activeTenantId,
      tenantIds,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  reprocess({ tenantIds, queryJson, configVersionOption, configVersion }) {
    const apiUrl = API_URLS.reprocessingService.v2.reprocess;
    const activeTenantId = TenantManagementService.getActiveTenantId();
    const body = {
      query: queryJson,
      configVersionOption,
      configVersion,
    };
    return this.postJSONForListTenants(
      apiUrl,
      body,
      activeTenantId,
      tenantIds,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default ToolingApiCommunicator;
