import querystring from 'querystring';
import APICommunicator from 'services/api-communicator';
import config from 'utils/helpers/config-utils/config';
import ErrorCode from 'utils/constants/error-code';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';

class NetworkAnalysisAPICommunicator extends APICommunicator {
  fetchNetworkAnalysis(caseId, createdAt) {
    const apiUrl = `${config.baseUrl}/network-analysis/money-mule/${caseId}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
      createdAt,
    });
  }

  fetchPassthroughNetwork(caseId, fundsOriginatorToken, numberOfDays, createdAt) {
    const params = { fundsOriginatorToken, numberOfDays };
    const apiUrl = `${
      config.baseUrl
    }/network-analysis/passthrough/${caseId}?${querystring.stringify(params)}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
      createdAt,
    });
  }

  fetchInboundPassthroughV2Network(caseId, outgoingCaseList, createdAt) {
    const apiUrl = API_URLS.networkAnalysisService.passthroughV2.incoming.replace(
      API_PARAMS.caseId,
      caseId,
    );

    return this.postJSONForTenant(
      apiUrl,
      { outgoingCaseList },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      {
        createdAt,
      },
    );
  }

  fetchPassthroughV2Network(
    caseId,
    fundsOriginatorToken,
    numberOfDays,
    passthroughType,
    createdAt,
    configurationType,
  ) {
    if (passthroughType) {
      const params = { fundsOriginatorToken, numberOfDays, configurationType };
      const apiUrl = `${API_URLS.networkAnalysisService.passthroughV2.outgoing.replace(
        API_PARAMS.caseId,
        caseId,
      )}${querystring.stringify(params)}`;
      return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
        createdAt,
      });
    }
    return new Promise((res) => res({}));
  }

  fetchPassthroughV3Network(
    caseId,
    incomingAccountLookupIdTokens,
    outgoingAccountLookupIdTokens,
    ruleInstanceKey,
    createdAt,
  ) {
    const params = {
      incomingAccountLookupIdTokens,
      outgoingAccountLookupIdTokens,
      ruleInstanceKey,
    };
    const apiUrl = `${API_URLS.networkAnalysisService.passthroughV3.outgoing.replace(
      API_PARAMS.caseId,
      caseId,
    )}`;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, {
      createdAt,
    });
  }
}

export default NetworkAnalysisAPICommunicator;
