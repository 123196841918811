import VolumeRiskConfigTypes from 'utils/constants/volume-risk-configuration-type';

const AssessmentQuestion = {
  VOLUME_RISK_CUSTOMER_TRANSACTION_QUESTION: 'volume-risk-customer-transaction-question',
  REFERENCE_ACCOUNT_MISMATCH_QUESTION: 'reference-account-mismatch-question',
  OPERATOR_CASE_FEEDBACK_QUESTION: 'operator-case-feedback',

  COUNTRY_RISK_ACCEPTABLE_FOR_PARTIES_ID: 'question-0000001',
  VOLUME_RISK_ACCEPTABLE_FOR_PARTIES_ID: 'question-0000002',
  PEPSANCTION_RISK_ACCEPTABLE_FOR_PARTIES_ID: 'question-0000003',
  MONEY_MULE_CORRECT_DETECTION: 'question-0000004',
  SHELL_COMPANY_CORRECT_DETECTION: 'question-0000005',
  BLACKLIST_RISK_ACCEPTABLE_FOR_PARTIES_ID: 'question-0000013',

  [VolumeRiskConfigTypes.ACCOUNT_BASED]: 'question-0000006',
  [VolumeRiskConfigTypes.TRANSFER_BASED]: 'question-0000007',
  [VolumeRiskConfigTypes.CUSTOMER_BASED]: 'question-0000008',
  [VolumeRiskConfigTypes.CUSTOMER_GROUP_BASED]: 'question-0000009',

  OPERATOR_CASE_FEEDBACK_ID: 'question-0000010',
  PHISHING_CORRECT_DETECTION: 'question-00000011',
  FAN_IN_CORRECT_DETECTION: 'question-00000012',
};

export default AssessmentQuestion;
