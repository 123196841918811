import { BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/action-types';

export const getBlacklistInstantiableListConfig = (tenantId) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getBlacklistInstantiableListConfigSuccess = (payload) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.success,
  payload,
});

export const getBlacklistInstantiableListConfigError = () => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.error,
});

export const updateBlacklistInstantiableListConfig = (payload) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data,
  payload,
});

export const updateBlacklistInstantiableListConfigSuccess = (payload) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.success,
  payload,
});

export const updateBlacklistInstantiableListConfigError = () => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.error,
});
export const deleteBlacklistInstantiableListConfig = (payload) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data,
  payload,
});

export const deleteBlacklistInstantiableListConfigSuccess = (payload) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.success,
  payload,
});

export const deleteBlacklistInstantiableListConfigError = (payload) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.error,
  payload,
});
export const activateBlacklistInstantiableListConfig = (version) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateBlacklistInstantiableListConfigSuccess = (payload) => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.success,
  payload,
});

export const activateBlacklistInstantiableListConfigError = () => ({
  type: BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.error,
});
