import { FILTERS_LIST_ACTIONS } from 'middleware/action-types';
import {
  activateFiltersListError,
  activateFiltersListSuccess,
  getFiltersFileListError,
  getFiltersFileListSuccess,
} from 'middleware/actions/filters-list';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import FiltersListApiCommunicator from 'services/filters-list-api-communicator';
import FiltersListManager from 'services/filters-list-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { showError } from '../actions/error';

const apiCommunicator = new FiltersListApiCommunicator();
const listManager = new FiltersListManager(apiCommunicator);

/**
 * Fetch customer occupation files action
 */
export function* getFiltersFileListBegin(action) {
  try {
    yield put(startProcessing(FILTERS_LIST_ACTIONS.fileList.fetch));

    const json = yield listManager.getFiltersFileList(TenantManagementService.getActiveTenantId());
    yield put(getFiltersFileListSuccess(json));

    yield put(stopProcessing(FILTERS_LIST_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getFiltersFileListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(FILTERS_LIST_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch customer occupation files action watcher
 */
export function* getFiltersFileListWatcher() {
  yield takeLatest(FILTERS_LIST_ACTIONS.fileList.fetch, getFiltersFileListBegin);
}

/**
 *  Update active filters file version action
 */
export function* updateActiveFiltersListVersionBegin(action) {
  try {
    yield put(startProcessing(FILTERS_LIST_ACTIONS.activateVersion.send));

    const json = yield listManager.updateActiveFiltersListVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateFiltersListSuccess(json));

    yield put(stopProcessing(FILTERS_LIST_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateFiltersListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(FILTERS_LIST_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active customer occupation file version action watcher
 */
export function* updateActiveFiltersListVersionWatcher() {
  yield takeLatest(FILTERS_LIST_ACTIONS.activateVersion.send, updateActiveFiltersListVersionBegin);
}
