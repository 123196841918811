import { ROUND_AMOUNT_ACTIONS } from 'modules/configuration/rule-manager/round-amount-ratio-increase-decrease/middleware/action-types';

export const getRoundAmountRatioIncreaseDecreaseConfig = () => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.fetch.data,
});

export const getRoundAmountRatioIncreaseDecreaseConfigSuccess = (payload) => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.fetch.success,
  payload,
});

export const getRoundAmountRatioIncreaseDecreaseConfigError = () => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.fetch.error,
});

export const updateRoundAmountRatioIncreaseDecreaseConfig = (payload) => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.update.data,
  payload,
});

export const updateRoundAmountRatioIncreaseDecreaseConfigSuccess = (payload) => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.update.success,
  payload,
});

export const updateRoundAmountRatioIncreaseDecreaseConfigError = () => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.update.error,
});

export const deleteRoundAmountRatioIncreaseDecreaseConfig = (payload) => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.delete.data,
  payload,
});

export const deleteRoundAmountRatioIncreaseDecreaseConfigSuccess = (payload) => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.delete.success,
  payload,
});

export const deleteRoundAmountRatioIncreaseDecreaseConfigError = () => ({
  type: ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.delete.error,
});
