import sftpReducer from 'modules/configuration/settings/sftp-config/middleware/reducers/sftp-reducer';
import apiKeyConfigReducer from 'modules/configuration/api-key-config/middleware/reducers/api-key-config-reducer';
import caseNotificationsReducer from 'modules/configuration/workflows-and-users/components/case-notification/middleware/reducers/case-notifications-reducer';
import { combineReducers } from 'redux';
import accountBasedFrequencyConfigReducer from 'modules/configuration/rule-manager/account-based-frequency/middleware/reducers/account-based-frequency-config-reducer';
import accountBasedFrequencyIncreaseDecreaseConfigReducer from 'modules/configuration/rule-manager/account-based-frequency-increase-decrease/middleware/reducers/account-based-frequency-increase-decrease-config-reducer';
import assessmentEngineConfigReducer from 'middleware/reducers/assessment-engine-config-reducer';
import auditTrackReducer from 'middleware/reducers/audit-track-reducer';
import auditTrailReducer from 'modules/audit-trail/middleware/reducers/audit-trail-reducer';
import authReducer from 'modules/authentication/login/middleware/reducers/auth-reducer';
import caseReducer from 'modules/trx-cases/case-detail/middleware/reducers/case-reducer';
import caseStatisticsReducer from 'modules/dashboard/case-statistic/middleware/reducers/case-statistics-reducer';
import counterPartyFrequencyConfigReducer from 'modules/configuration/rule-manager/counter-party-frequency/middleware/reducers/counter-party-frequency-config-reducer';
import counterPartyVolumeConfigReducer from 'modules/configuration/rule-manager/counter-party-volume/middleware/reducers/counter-party-volume-config-reducer';
import countryRiskConfigReducer from 'modules/configuration/rule-manager/country-config/middleware/reducers/country-risk-config-reducer';
import countryRiskV2ConfigReducer from 'modules/configuration/rule-manager/country-config-v2/middleware/reducers/country-risk-v2-config-reducer';
import crossBorderVolumeConfigReducer from 'modules/configuration/rule-manager/cross-border-volume/middleware/reducers/cross-border-volume-config-reducer';
import customerCaseDetailReducer from 'modules/customer-cases/case-detail/middleware/reducers/case-detail';
import customerCaseListReducer from 'modules/customer-cases/case-list/middleware/reducers/case-list-reducer';
import customerDetailsReducer from 'middleware/reducers/customer-details-reducer';
import languageConfigReducer from 'services/language/language-reducer';
import customerRiskRatingConfigForIndividualReducer from './customer-risk-rating-config-for-individual-reducer';
import customerRiskRatingConfigForOrganizationReducer from 'middleware/reducers/customer-risk-rating-config-for-organization-reducer';
import decisionEngineConfigReducer from 'middleware/reducers/decision-engine-config-reducer';
import detachmentConfigReducer from 'middleware/reducers/detachment-config-reducer';
import dormantAccountConfigReducer from 'modules/configuration/rule-manager/dormant-account/middleware/reducers/dormant-account-config-reducer';
import efficiencyReducer from 'modules/dashboard/efficiency/middleware/reducers/efficiency-reducer';
import errorReducer from 'middleware/reducers/error-reducer';
import goAMLReducer from 'middleware/reducers/goaml-reducer';
import hidtaHifcaReducer from 'middleware/reducers/hidta-hifca-reducer';
import maskReducer from 'middleware/reducers/mask-reducer';
import mccListReducer from 'middleware/reducers/mcc-list-reducer';
import networkAnalysisReducer from 'middleware/reducers/network-analysis-reducer';
import notificationReducer from 'middleware/reducers/notification-reducer';
import occupationListReducer from 'middleware/reducers/occupation-list-reducer';
import passthroughConfigReducer from 'modules/configuration/rule-manager/passthrough/middleware/reducers/passthrough-config-reducer';
import passthroughV2ConfigReducer from 'modules/configuration/rule-manager/passthrough-v2/middleware/reducers/passthrough-v2-config-reducer';
import passthroughV3ConfigReducer from 'modules/configuration/rule-manager/passthrough-v3/middleware/reducers/passthrough-v3-config-reducer';
import expectedCustomerValueConfigReducer from 'modules/configuration/rule-manager/expected-customer-value/middleware/reducers/expected-customer-value-config-reducer';
import processingReducer from 'middleware/reducers/processing-reducer';
import productTypeListReducer from 'middleware/reducers/product-type-list-reducer';
import resetPasswordReducer from 'modules/authentication/reset-password/middleware/reducers/reset-password-reducer';
import roundAmountConfigReducer from 'modules/configuration/rule-manager/round-amount/middleware/reducers/round-amount-config-reducer';
import roundAmountRatioIncreaseDecreaseConfigReducer from 'modules/configuration/rule-manager/round-amount-ratio-increase-decrease/middleware/reducers/round-amount-ratio-increase-decrease-config-reducer';
import ruleManagerReducer from 'middleware/reducers/rule-manager-reducer';
import sanctionListReducer from 'middleware/reducers/sanction-list-reducer';
import settingsReducer from 'middleware/reducers/settings-reducer';
import smurfingV2ConfigReducer from 'modules/configuration/rule-manager/smurfing/middleware/reducers/smurfing-v2-config-reducer';
import suspiciousAvgTicketSizeConfigReducer from 'modules/configuration/rule-manager/suspicious-avg-ticket-size/middleware/reducers/suspicious-avg-ticket-size-config-reducer';
import tenantConfigReducer from 'modules/configuration/workflows-and-users/middleware/reducers/tenant-config-reducer';
import toolingReducer from 'middleware/reducers/tooling-reducer';
import volumeIncreaseDecreaseConfigReducer from 'modules/configuration/rule-manager/volume-increase-decrease/middleware/reducers/volume-increase-decrease-config-reducer';
import volumeRiskConfigReducer from 'middleware/reducers/volume-risk-config-reducer';
import volumeRiskV2ConfigReducer from 'modules/configuration/rule-manager/volume-config-v2/middleware/reducers/volume-risk-v2-config-reducer';
import blacklistConfigReducer from 'middleware/reducers/blacklist-config-reducer';
import ruleCategoryReducer from 'middleware/reducers/rule-category-reducer';
import riskyWordsV2Reducer from 'modules/configuration/rule-manager/risky-words-v2/middleware/reducers/risky-words-v2-config-reducer';
import riskyWordsV2ListReducer from 'middleware/reducers/risky-words-v2-list-reducer';
import plausibilityReducer from 'middleware/reducers/config/plausibility-reducer';
import referenceAccountV2Reducer from 'modules/configuration/rule-manager/reference-account-v2/middleware/reducers/reference-account-v2-reducer';
import blacklistListReducer from 'middleware/reducers/blacklist-list-reducer';
import filtersListReducer from 'middleware/reducers/filters-list-reducer';
import dataProviderListReducer from 'modules/configuration/list-manager/data-provider-list/reducers/data-provider-list-config-reducer';
import dataProviderAuditTrailReducer from 'modules/configuration/list-manager/data-provider-list/reducers/data-provider-audit-trail-reducer';
import riskyWordsInstantiableListConfigReducer from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/reducers/risky-words-instantiable-list-config-reducer';
import blacklistInstantiableListConfigReducer from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/reducers/blacklist-instantiable-list-config-reducer';
import filtersInstantiableListConfigReducer from 'modules/configuration/list-manager/filters-instantiable-list/middleware/reducers/filters-instantiable-list-config-reducer';
import transactionFieldsReducer from 'middleware/reducers/config/transaction-fields-reducer';
import amlRiskScreeningReducer from 'middleware/reducers/aml-risk-screening-reducer';
import flaggingConfigReducer from 'modules/configuration/rule-manager/flagging/middleware/reducers/flagging-config-reducer';
import riskFactorRiskLevelMapReducer from 'modules/configuration/customer-risk-rating/middleware/action-types';
import currentRuleReducer from 'middleware/reducers/config/current-rule-reducer';
import ruleBuilderReducer from 'modules/configuration/rule-manager/common/rule-builder/middleware/reducers/rule-builder-reducer';
import customerListReducer from 'modules/customer-data/customer-list/middleware/reducers/customer-list-reducer';
import customerDetailReducer from 'modules/customer-data/customer-detail/middleware/reducers/customer-detail-reducer';
import fanInReducer from 'modules/configuration/machine-intelligence/fan-in/middleware/reducers/fan-in-reducer';
import configPropagationSlice from 'modules/configuration/config-propagation/middleware/reducer/config-propagation-reducer';
import behavioralAnalysisReducer from 'modules/configuration/machine-intelligence/behavioral-analysis/middleware/reducers/behavioral-analysis-reducer';
import entityResolutionConfigReducer from 'modules/entity-resolution/middleware/reducers/entity-resolution-config-reducer';
import entityResolutionProfileReducer from 'modules/entity-resolution/middleware/reducers/entity-resolution-profile-reducer';
import forbiddenAlertReducer from 'middleware/reducers/forbidden-alert-reducer';

/**
 * DO NOT ADD ROOT LEVEL OBJECTS IN REDUCER
 * PLEASE consult with a Sr. Frontend to add your new reducer to an existing/ new container
 */
const rootReducer = combineReducers({
  accountBasedFrequencyConfig: accountBasedFrequencyConfigReducer,
  accountBasedFrequencyIncreaseDecreaseConfig: accountBasedFrequencyIncreaseDecreaseConfigReducer,
  assessmentEngineConfig: assessmentEngineConfigReducer,
  auditTracks: auditTrackReducer,
  auditTrailContainer: combineReducers({
    auditTrail: auditTrailReducer,
  }),
  authentication: authReducer,
  blacklistConfig: blacklistConfigReducer,
  case: caseReducer,
  configContainer: combineReducers({
    customerRiskRating: combineReducers({
      organization: customerRiskRatingConfigForOrganizationReducer,
      individual: customerRiskRatingConfigForIndividualReducer,
      riskLevelMaps: riskFactorRiskLevelMapReducer,
    }),
    ruleManager: combineReducers({
      plausibility: plausibilityReducer,
      transactionFields: transactionFieldsReducer,
      referenceAccountV2: referenceAccountV2Reducer,
      amlRiskScreeningConfig: amlRiskScreeningReducer,
      ruleBuilder: ruleBuilderReducer,
      flaggingConfig: flaggingConfigReducer,
      currentRule: currentRuleReducer,
      configPropagation: configPropagationSlice.reducer,
    }),
    caseNotificationsConfig: caseNotificationsReducer,
    sftpConfig: sftpReducer,
    apiKeyConfig: apiKeyConfigReducer,
    languageConfig: languageConfigReducer,
  }),
  counterPartyFrequencyConfig: counterPartyFrequencyConfigReducer,
  counterPartyVolumeConfig: counterPartyVolumeConfigReducer,
  countryRiskConfig: countryRiskConfigReducer,
  countryRiskV2Config: countryRiskV2ConfigReducer,
  crossBorderVolumeConfig: crossBorderVolumeConfigReducer,
  customerCasesContainer: combineReducers({
    allCases: customerCaseListReducer,
    selectedCase: customerCaseDetailReducer,
  }),
  customerDetails: customerDetailsReducer,
  customerDataContainer: combineReducers({
    allCustomers: customerListReducer,
    selectedCustomer: customerDetailReducer,
  }),
  dashboardContainer: combineReducers({
    efficiency: efficiencyReducer,
    caseStatistics: caseStatisticsReducer,
  }),
  decisionEngineConfig: decisionEngineConfigReducer,
  detachmentConfig: detachmentConfigReducer,
  dormantAccountConfig: dormantAccountConfigReducer,
  entityResolutionContainer: combineReducers({
    config: entityResolutionConfigReducer,
    profile: entityResolutionProfileReducer,
  }),
  error: errorReducer,
  goAML: goAMLReducer,
  hidtaHifca: hidtaHifcaReducer,
  masks: maskReducer,
  mccList: mccListReducer,
  networkAnalysis: networkAnalysisReducer,
  notification: notificationReducer,
  occupationList: occupationListReducer,
  passthroughConfig: passthroughConfigReducer,
  passthroughV2Config: passthroughV2ConfigReducer,
  passthroughV3Config: passthroughV3ConfigReducer,
  processing: processingReducer,
  productTypeList: productTypeListReducer,
  resetPassword: resetPasswordReducer,
  roundAmountConfig: roundAmountConfigReducer,
  roundAmountRatioIncreaseDecreaseConfig: roundAmountRatioIncreaseDecreaseConfigReducer,
  ruleManager: ruleManagerReducer,
  settings: settingsReducer,
  sanctionList: sanctionListReducer,
  smurfingV2Config: smurfingV2ConfigReducer,
  suspiciousAvgTicketSizeConfig: suspiciousAvgTicketSizeConfigReducer,
  tenantConfig: tenantConfigReducer,
  tooling: toolingReducer,
  volumeIncreaseDecreaseConfig: volumeIncreaseDecreaseConfigReducer,
  volumeRiskConfig: volumeRiskConfigReducer,
  volumeRiskV2Config: volumeRiskV2ConfigReducer,
  ruleCategory: ruleCategoryReducer,
  riskyWordsV2Config: riskyWordsV2Reducer,
  riskyWordsV2List: riskyWordsV2ListReducer,
  blacklistList: blacklistListReducer,
  machineIntelligence: combineReducers({
    fanIn: fanInReducer,
    behavioralAnalysis: behavioralAnalysisReducer,
  }),
  listManager: combineReducers({
    riskyWordsInstantiableListConfig: riskyWordsInstantiableListConfigReducer,
    blacklistInstantiableListConfig: blacklistInstantiableListConfigReducer,
    filtersInstantiableListConfig: filtersInstantiableListConfigReducer,
    filtersList: filtersListReducer,
    dataProviderList: dataProviderListReducer,
    dataProviderAuditTrail: dataProviderAuditTrailReducer,
  }),
  expectedCustomerValueConfig: expectedCustomerValueConfigReducer,
  forbiddenAlert: forbiddenAlertReducer,
});

/**
 * DO NOT ADD ROOT LEVEL OBJECTS IN REDUCER
 * E.g. for a new rule config reducer, add it under configContainer/ruleManager block
 * PLEASE consult with a Sr. Frontend to add your new reducer to an existing/ new container
 */

export default rootReducer;
