import { showError } from 'middleware/actions/error';
import { updateMasks } from 'middleware/actions/mask';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { CUSTOMER_CASE_ACTIONS } from 'modules/customer-cases/case-list/middleware/action-types';
import {
  getAllCasesError,
  getAllCasesSuccess,
} from 'modules/customer-cases/case-list/middleware/actions/case-list.action';
import CustomerCaseApiCommunicator from 'modules/customer-cases/case-list/services/customer-case-api-communicator';
import CustomerCaseManager from 'modules/customer-cases/case-list/services/customer-case-manager';
import { put, takeLatest } from 'redux-saga/effects';
import { getMaskedsFromCases } from 'utils/helpers/cases/case-utility';

const customerCaseCommunicator = new CustomerCaseApiCommunicator();
const customerCaseManager = new CustomerCaseManager(customerCaseCommunicator);

function* getCustomerCases(action, queryAllCases) {
  yield put(startProcessing(CUSTOMER_CASE_ACTIONS.allCases.fetch.data));
  try {
    const json = yield queryAllCases(action);

    yield put(
      getAllCasesSuccess({
        type: action.type,
        data: json,
      }),
    );
    yield put(updateMasks(getMaskedsFromCases(json.cases)));
  } catch (error) {
    yield put(getAllCasesError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CUSTOMER_CASE_ACTIONS.allCases.fetch.data));
}

function* getCustomerCasesBegin(action) {
  yield getCustomerCases(action, ({ payload }) => {
    const { filtering, paging, sorting } = payload;

    return customerCaseManager.queryAllCases(paging, sorting, filtering);
  });
}

export function* getCustomerCasesWatcher() {
  yield takeLatest(CUSTOMER_CASE_ACTIONS.allCases.fetch.data, getCustomerCasesBegin);
}
