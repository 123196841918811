import { Check } from '@mui/icons-material';
import { Chip, CircularProgress, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ExecutionStatus } from 'modules/configuration/experimentation/service/experimentation-api';
import React, { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ExecutionStatusChipProps {
  executionStatus?: ExecutionStatus;
  distanceToParentMultiplier?: number;
}

interface StyledChipProps {
  status: ExecutionStatus;
  icon: JSX.Element;
  label: string;
}

// Need to add `sx={{ position: 'relative', overflow: 'visible' }}`
// prop to the container for this chip to be positioned correctly.
//
// Position of the chip can be corrected using its props.
const ExecutionStatusChip: FC<ExecutionStatusChipProps> = ({
  executionStatus,
  distanceToParentMultiplier = 1,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const distanceToParent = distanceToParentMultiplier * -0.5;

  const StyledChip: FC<StyledChipProps> = forwardRef(
    ({ status, icon, label, ...props }, ref: React.Ref<HTMLDivElement>) => (
      <Chip
        sx={{
          position: 'absolute',
          right: '1rem',
          top: `${distanceToParent}rem`,
          transform: 'translate(0%, -100%)',
        }}
        style={theme.custom?.executionStatusChipComponent?.[status]}
        icon={icon}
        label={label}
        data-testid="experiment-execution-status"
        ref={ref}
        {...props}
      />
    ),
  );

  if (executionStatus === 'running') {
    return (
      <Tooltip title={t(`experimentationTenant.executionStatus.running.tooltip`)}>
        <StyledChip
          status="running"
          icon={<CircularProgress size="1.125rem" color="secondary" />}
          label={t(`experimentationTenant.executionStatus.running.heading`)}
        />
      </Tooltip>
    );
  }
  if (executionStatus === 'complete') {
    return (
      <StyledChip
        status="complete"
        icon={<Check color="success" />}
        label={t(`experimentationTenant.executionStatus.complete.heading`)}
      />
    );
  }
  return <></>;
};

export default ExecutionStatusChip;
