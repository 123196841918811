import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class CustomerRiskRatingConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Customer Risk Rating Config API Communicator instance is required');
    }
  }

  static validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CUSTOMER_RISK_RATING_CONFIG_DATA_INVALID);
  }

  getCustomerRiskRatingConfig(tenantId, configVersion) {
    return this.communicator
      .getCustomerRiskRatingConfig(tenantId, configVersion)
      .then((json) => CustomerRiskRatingConfigManager.validateConfigJson(json));
  }

  getCustomerRiskRatingConfigForIndividual(tenantId, configVersion) {
    return this.communicator
      .getCustomerRiskRatingConfigForIndividual(tenantId, configVersion)
      .then((json) => CustomerRiskRatingConfigManager.validateConfigJson(json));
  }

  updateCustomerRiskRatingConfig(tenantId, payload) {
    return this.communicator
      .updateCustomerRiskRatingConfig(tenantId, payload)
      .then((json) => CustomerRiskRatingConfigManager.validateConfigJson(json));
  }

  updateCustomerRiskRatingConfigForIndividual(tenantId, payload) {
    return this.communicator
      .updateCustomerRiskRatingConfigForIndividual(tenantId, payload)
      .then((json) => CustomerRiskRatingConfigManager.validateConfigJson(json));
  }

  createRiskFactor(tenantId, payload) {
    return this.communicator
      .createRiskFactor(tenantId, payload)
      .then((json) => CustomerRiskRatingConfigManager.validateConfigJson(json));
  }

  createRiskFactorForIndividual(tenantId, payload) {
    return this.communicator
      .createRiskFactorForIndividual(tenantId, payload)
      .then((json) => CustomerRiskRatingConfigManager.validateConfigJson(json));
  }

  deleteRiskFactor(tenantId, payload) {
    return this.communicator
      .deleteRiskFactor(tenantId, payload)
      .then((json) => CustomerRiskRatingConfigManager.validateConfigJson(json));
  }
}

export default CustomerRiskRatingConfigManager;
