import { isOpenCase } from 'utils/helpers/cases/case-status-util';

export function getSelectedCaseIndexFromHash(hash) {
  if (!hash || !hash.startsWith('#') || hash.length < 2) {
    return 0;
  }

  return parseInt(hash.substring(1), 10);
}

export function isAssignedToMe(me, tx) {
  return !!tx && !!me && tx.assignedToUserId === me;
}

export function isGroupCase(tx) {
  return !!tx && tx.forwardingCasesCount > 1;
}

export function isEmptyGroupCase(tx) {
  return !!tx && tx.forwardingCasesCount === -1;
}

export function isGroupMemberCase(tx) {
  return !!tx && !!tx.forwardingToCaseId && tx.forwardingToCaseId.length > 0;
}

export function isStandaloneCase(tx) {
  return !!tx && !isGroupCase(tx) && !isEmptyGroupCase(tx) && !isGroupMemberCase(tx);
}

export function isAssignedToDifferentUser(tx, userId) {
  return !!tx && !!userId && !!tx.assignedToUserId && tx.assignedToUserId !== userId;
}

export function getGroupCaseId(tx) {
  if (!tx) {
    return undefined;
  }

  return isGroupCase(tx) ? tx.uuid : tx.forwardingToCaseId;
}

export function canAddCaseToGroup(tx1, tx2, me) {
  // Cases must be OPEN
  if (!isOpenCase(tx1?.caseStatus) || !isOpenCase(tx2?.caseStatus)) {
    return false;
  }

  // At least one case is assigned to me
  if (!isAssignedToMe(me, tx1) && !isAssignedToMe(me, tx2)) {
    return false;
  }

  if (isGroupMemberCase(tx1) || isGroupMemberCase(tx2)) {
    return false;
  }

  // At least one case is standalone case
  return isStandaloneCase(tx1) || isStandaloneCase(tx2);
}

export function getGroupCaseActionLabel(tx1, tx2) {
  if (isGroupCase(tx1)) {
    return 'Add case to current case group';
  }

  if (isGroupCase(tx2)) {
    return 'Add current case to case group';
  }

  return 'Create new case group';
}
