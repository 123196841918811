import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

interface CustomerNameChipProps {
  form: {
    values: string[];
  };
}

export const CustomerNameChip: FC<CustomerNameChipProps> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.customerName.value.heading')}
      </Box>
      {form.values.map((item, index) => (
        <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
      ))}
    </>
  );
};
