import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

interface CustomercategoryChipProps {
  form: {
    values: string[];
  };
}

export const CustomerCategoryChip: FC<CustomercategoryChipProps> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.customerCategory.value.heading')}
      </Box>
      {form.values.map((item, index) => (
        <Chip
          key={index}
          variant="outlined"
          size="small"
          className="mr-1"
          label={t(`mapping:customerType.${item}.value.heading`)}
        />
      ))}
    </>
  );
};
