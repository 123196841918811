import { RoundAmountConfigActions } from 'modules/configuration/rule-manager/round-amount/middleware/action-types';

export const getRoundAmountConfig = () => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_GET,
});

export const getRoundAmountConfigSuccess = (payload) => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_GET_SUCCESS,
  payload,
});

export const getRoundAmountConfigError = () => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_GET_ERROR,
});

export const updateRoundAmountConfig = (payload) => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_UPDATE,
  payload,
});

export const updateRoundAmountConfigSuccess = (payload) => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateRoundAmountConfigError = () => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_UPDATE_ERROR,
});

export const deleteRoundAmountConfig = (payload) => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_DELETE,
  payload,
});

export const deleteRoundAmountConfigSuccess = (payload) => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_DELETE_SUCCESS,
  payload,
});

export const deleteRoundAmountConfigError = () => ({
  type: RoundAmountConfigActions.ROUND_AMOUNT_CONFIG_DELETE_ERROR,
});
