import { Filter } from 'models/api/search';
import { deepCopy } from 'utils/helpers/utility';
import { isEmptyNestedChecks } from 'utils/helpers/object-util';
import { TIME_FRAME_OPTIONS } from 'utils/constants/dashboard-constant';

export type Operator = {
  email: string;
  name: string;
  user_id: string;
};

export const prepareFiltersForHttpRequest = (filterOptions: Filter[]): Filter[] => {
  const filterOptionsCopy: Filter[] = deepCopy(filterOptions);

  filterOptionsCopy.forEach((filter: Filter) => {
    if (filter.field === 'updatedAt' && isEmptyNestedChecks(filter?.rangeEnd)) {
      filter.rangeEnd = TIME_FRAME_OPTIONS.LAST_3_MONTH.value.to;
    }
  });

  return filterOptionsCopy;
};
