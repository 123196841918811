import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class PassthroughConfigAPICommunicator extends APICommunicator {
  fetchPassthroughConfig() {
    const apiUrl = `${
      config.configurationUrl
    }/config/passthrough/${TenantManagementService.getActiveTenantId()}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updatePassthroughConfig(params) {
    const apiUrl = `${
      config.configurationUrl
    }/config/passthrough/${TenantManagementService.getActiveTenantId()}`;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default PassthroughConfigAPICommunicator;
