import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EntityResolutionProfile } from 'modules/entity-resolution/models/entity-resolution-profile';

export interface EntityProfileRequest {
  customerIdToken: string;
}

const initialState: EntityResolutionProfile = {
  entity: null,
  deleteProfileLoading: false,
};

export interface DeleteEntityProfileRequest {
  entityId: string;
}

export interface DeleteEntityProfileResponse {
  deleted: boolean;
}

const entityResolutionProfileSlice = createSlice({
  name: 'entityResolutionProfile',
  initialState,
  reducers: {
    getEntityResolutionProfile: (state, _action: PayloadAction<EntityProfileRequest>) => state,
    getEntityResolutionProfileSuccess: (state, action: PayloadAction<EntityResolutionProfile>) => {
      const newState = {
        ...state,
        ...action?.payload,
      };
      return newState;
    },
    deleteEntityResolutionProfile: (state, _action: PayloadAction<DeleteEntityProfileRequest>) => ({
      ...state,
      deleteProfileLoading: true,
    }),
    deleteEntityResolutionProfileSuccess: (_) => initialState,
    updateDeleteProfileLoading: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  getEntityResolutionProfile,
  getEntityResolutionProfileSuccess,
  deleteEntityResolutionProfile,
  deleteEntityResolutionProfileSuccess,
  updateDeleteProfileLoading,
} = entityResolutionProfileSlice.actions;

export default entityResolutionProfileSlice.reducer;
