import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class DecisionEngineConfigAPICommunicator extends APICommunicator {
  fetchDecisionEngineConfig() {
    const apiUrl = API_URLS.configService.decisionEngine.getConfig.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchDecisionEngineConfigWithTenant(tenantId) {
    const apiUrl = API_URLS.configService.decisionEngine.getConfig.replace(
      API_PARAMS.tenantId,
      tenantId,
    );
    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateDecisionEngineConfig(params) {
    const apiUrl = `${
      config.configurationUrl
    }/config/decision/${TenantManagementService.getActiveTenantId()}`;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default DecisionEngineConfigAPICommunicator;
