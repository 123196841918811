import { VolumeIncreaseDecreaseConfigActions } from 'modules/configuration/rule-manager/volume-increase-decrease/middleware/action-types';

export const getVolumeIncreaseDecreaseConfig = () => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_GET,
});

export const getVolumeIncreaseDecreaseConfigSuccess = (payload) => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_GET_SUCCESS,
  payload,
});

export const getVolumeIncreaseDecreaseConfigError = () => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_GET_ERROR,
});

export const updateVolumeIncreaseDecreaseConfig = (payload) => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_UPDATE,
  payload,
});

export const updateVolumeIncreaseDecreaseConfigSuccess = (payload) => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateVolumeIncreaseDecreaseConfigError = () => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_UPDATE_ERROR,
});

export const deleteVolumeIncreaseDecreaseConfig = (payload) => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_DELETE,
  payload,
});

export const deleteVolumeIncreaseDecreaseConfigSuccess = (payload) => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_DELETE_SUCCESS,
  payload,
});

export const deleteVolumeIncreaseDecreaseConfigError = () => ({
  type: VolumeIncreaseDecreaseConfigActions.VOLUME_INCREASE_DECREASE_CONFIG_DELETE_ERROR,
});
