class ExpectedCustomerValueConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('ExpectedCustomerValue Config API Communicator instance is requrired.');
    }
  }

  getExpectedCustomerValueConfig() {
    return this.communicator.fetchConfig();
  }

  updateExpectedCustomerValueConfig(data) {
    return this.communicator.updateConfig(data);
  }

  deleteExpectedCustomerValueConfig(id) {
    return this.communicator.deleteConfig(id);
  }
}

export default ExpectedCustomerValueConfigManager;
