import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { ScreeningListSearchRequest } from 'modules/configuration/list-manager/data-provider-list/models/screening-list-search-request';
import { ScreeningListAttributesRequest } from 'modules/configuration/list-manager/data-provider-list/models/screening-list-attributes-request';

class ScreeningListAPI extends APICommunicator {
  fetchScreeningList(requestBody: ScreeningListSearchRequest) {
    const apiUrl = API_URLS.config.screeningManager.fetch;

    return this.postJSONForTenant(
      apiUrl,
      requestBody,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  fetchScreeningAttributes(requestBody: ScreeningListAttributesRequest) {
    const apiUrl = API_URLS.config.screeningManager.fetchAttributes;

    return this.postJSONForTenant(
      apiUrl,
      requestBody,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default ScreeningListAPI;
