import { Filter, NestedFilter } from 'components/filters/models/filter';
import i18next from 'i18next';

const getTextEqualFilter =
  (key: string) =>
  (values: string[]): Filter => ({
    field: i18next.t(`mapping:filters.filtering.${key}.key`),
    condition: i18next.t(`mapping:operator.text.TEXT_EQUAL.key`),
    values,
  });

const createCounterPartyFilter =
  (fundsBeneficiaryFieldName: string, fundsOriginatorFieldName: string) => (values: string[]) =>
    ({
      condition: i18next.t('mapping:operator.complex.OR.key'),
      filters: [
        {
          condition: i18next.t('mapping:operator.complex.AND.key'),
          filters: [
            {
              condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
              field: i18next.t('mapping:filters.filtering.accountHoldingParty.key'),
              values: [i18next.t('mapping:accountHoldingParty.FUNDS_ORIGINATOR.key')],
            },
            {
              condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
              field: i18next.t(`mapping:filters.filtering.${fundsBeneficiaryFieldName}.key`),
              values,
            },
          ],
        } as NestedFilter,
        {
          condition: i18next.t('mapping:operator.complex.AND.key'),
          filters: [
            {
              condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
              field: i18next.t('mapping:filters.filtering.accountHoldingParty.key'),
              values: [i18next.t('mapping:accountHoldingParty.FUNDS_BENEFICIARY.key')],
            },
            {
              condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
              field: i18next.t(`mapping:filters.filtering.${fundsOriginatorFieldName}.key`),
              values,
            },
          ],
        } as NestedFilter,
      ],
    } as NestedFilter);

export const usageFilterCreator = getTextEqualFilter('usage');
export const tenantTransactionIdFilterCreator = getTextEqualFilter('tenantTransactionId');
export const counterpartyAccountFilterCreator = createCounterPartyFilter(
  'fundsBeneficiaryAccountNumber',
  'fundsOriginatorAccountNumber',
);
export const counterpartyNameFilterCreator = createCounterPartyFilter(
  'fundsBeneficiaryHolderName',
  'fundsOriginatorHolderName',
);

const getSimpleFilterToValueConverter = (value?: NestedFilter | Filter): string[] => {
  if (!value) {
    return [];
  }
  return (value as Filter).values;
};

const createFilterBySearchingValueInNestedFilters =
  (fieldName: string) => (form?: Filter | NestedFilter) => {
    if (!form) {
      return [];
    }
    const rootNestedFilters = (form as NestedFilter).filters;
    return (
      ((rootNestedFilters[0] as NestedFilter).filters as Filter[])
        .filter(
          (it: Filter) => it.field === i18next.t(`mapping:filters.filtering.${fieldName}.key`),
        )
        .map((it) => it.values)
        .at(0) ?? []
    );
  };

export const usageFilterToValueConverter = getSimpleFilterToValueConverter;
export const tenantTransactionIdFilterToValueConverter = getSimpleFilterToValueConverter;

export const counterpartyAccountFilterToValueConverter =
  createFilterBySearchingValueInNestedFilters('fundsBeneficiaryAccountNumber');

export const counterpartyNameFilterToValueConverter = createFilterBySearchingValueInNestedFilters(
  'fundsBeneficiaryHolderName',
);
