import { AssessmentEngineConfigActions } from 'middleware/action-types';

const assessmentEngineConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET:
    case AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case AssessmentEngineConfigActions.ASSESSMENT_ENGINE_CONFIG_GET_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default assessmentEngineConfigReducer;
