import { CaseNotificationsConfigActions } from 'modules/configuration/workflows-and-users/components/case-notification/middleware/action-types';

export const getCaseNotificationsConfig = () => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_GET,
});

export const getCaseNotificationsConfigSuccess = (payload) => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_GET_SUCCESS,
  payload,
});

export const getCaseNotificationsConfigError = () => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_GET_ERROR,
});

export const updateCaseNotificationsConfig = (payload) => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_UPDATE,
  payload,
});

export const updateCaseNotificationsConfigSuccess = (payload) => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateCaseNotificationsConfigError = () => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_UPDATE_ERROR,
});

export const deleteCaseNotificationsConfig = (payload) => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_DELETE,
  payload,
});

export const deleteCaseNotificationsConfigSuccess = (payload) => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_DELETE_SUCCESS,
  payload,
});

export const deleteCaseNotificationsConfigError = () => ({
  type: CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_DELETE_ERROR,
});
