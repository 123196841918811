import Strings from 'utils/helpers/locales/strings.en';

const VolumeConfigOptions = {
  TIMEFRAMES: [
    { value: '1d', label: Strings.VolumeRules.CURRENT_DAY },
    { value: '1w', label: Strings.VolumeRules.ONE_WEEK_ROLLING },
    { value: '2w', label: Strings.VolumeRules.TWO_WEEKS_ROLLING },
    { value: '3w', label: Strings.VolumeRules.THREE_WEEKS_ROLLING },
    { value: '1m', label: Strings.VolumeRules.ONE_MONTH_ROLLING },
    { value: '2m', label: Strings.VolumeRules.TWO_MONTHS_ROLLING },
    { value: '3m', label: Strings.VolumeRules.THREE_MONTHS_ROLLING },
  ],
  TRANSACTION_TYPES: [
    { value: 'credit', label: Strings.VolumeRules.CREDIT_ONLY },
    { value: 'debit', label: Strings.VolumeRules.DEBIT_ONLY },
    // { value: "credit/debit", label: "Credit ot Debit" },
    // { value: "credit-debit", label: "Credit minus Debit" },
  ],
  CONFIGURATION_TYPE: {
    TRANSFER_BASED: 'TRANSFER_BASED',
    ACCOUNT_BASED: 'ACCOUNT_BASED',
    CUSTOMER_BASED: 'CUSTOMER_BASED',
    CUSTOMER_GROUP_BASED: 'CUSTOMER_GROUP_BASED',
  },
};

export default VolumeConfigOptions;

export function getTimeframeText(value) {
  const item = VolumeConfigOptions.TIMEFRAMES.find((i) => i.value === value);
  if (item) {
    return item.label;
  }

  return '';
}

export function getTransactionTypeText(value) {
  const item = VolumeConfigOptions.TRANSACTION_TYPES.find((i) => i.value === value);
  if (item) {
    return item.label;
  }

  return '';
}

export function getRuleActiveText(value) {
  if (value) {
    return 'ACTIVATED';
  }
  return 'DEACTIVATED';
}
