import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class AuditTrailManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Audit trail API Communicator instance is required.');
    }
  }

  static validateAuditTrailJson(json) {
    if (json && json.auditTrailList) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.AUDIT_TRAIL_ADD_PAGE_INVALID);
  }

  searchAuditTrails(paging, sorting, filtering, customHeader, tenantId) {
    return this.communicator.searchAuditTrails(paging, sorting, filtering, customHeader, tenantId);
  }
}

export default AuditTrailManager;
