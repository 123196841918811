import { DecisionEngineActions } from 'middleware/action-types';

export const getDecisionEngineConfig = () => ({
  type: DecisionEngineActions.GET_DECISION_ENGINE_CONFIG,
});

export const getDecisionEngineSuccess = (payload) => ({
  type: DecisionEngineActions.GET_DECISION_ENGINE_CONFIG_SUCCESS,
  payload,
});

export const getDecisionEngineError = () => ({
  type: DecisionEngineActions.GET_DECISION_ENGINE_CONFIG_ERROR,
});

export const updateDecisionEngineConfig = (payload) => ({
  type: DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG,
  payload,
});

export const updateDecisionEngineSuccess = (payload) => ({
  type: DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG_SUCCESS,
  payload,
});

export const updateDecisionEngineError = () => ({
  type: DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG_ERROR,
});
