import CounterPartyVolumeConfigAPICommunicator from 'services/counter-party-volume-config-api-communicator';
import CounterPartyVolumeConfigManager from 'services/counter-party-volume-confg-manager';
import { showError } from 'middleware/actions/error';
import {
  deleteCounterPartyVolumeConfigSuccess,
  getCounterPartyVolumeConfigSuccess,
  updateCounterPartyVolumeConfigSuccess,
} from 'modules/configuration/rule-manager/counter-party-volume/middleware/actions/counter-party-volume-config';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { COUNTER_PARTY_VOLUME_ACTIONS } from 'modules/configuration/rule-manager/counter-party-volume/middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';

const communicator = new CounterPartyVolumeConfigAPICommunicator();
const manager = new CounterPartyVolumeConfigManager(communicator);

function* getCounterPartyVolumeConfigBegin(action) {
  yield put(startProcessing(COUNTER_PARTY_VOLUME_ACTIONS.fetch.data));
  try {
    const json = yield manager.getCounterPartyVolumeConfig(action.payload);
    yield put(getCounterPartyVolumeConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(COUNTER_PARTY_VOLUME_ACTIONS.fetch.data));
}

export function* getCounterPartyVolumeConfigWatcher() {
  yield takeLatest(COUNTER_PARTY_VOLUME_ACTIONS.fetch.data, getCounterPartyVolumeConfigBegin);
}

function* updateCounterPartyVolumeConfigBegin(action) {
  yield put(startProcessing(COUNTER_PARTY_VOLUME_ACTIONS.update.data));

  try {
    const json = yield manager.updateCounterPartyVolumeConfig(action.payload);
    yield put(updateCounterPartyVolumeConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(COUNTER_PARTY_VOLUME_ACTIONS.update.data));
}

export function* updateCounterPartyVolumeConfigWatcher() {
  yield takeLatest(COUNTER_PARTY_VOLUME_ACTIONS.update.data, updateCounterPartyVolumeConfigBegin);
}

function* deleteCounterPartyVolumeConfigBegin(action) {
  yield put(startProcessing(COUNTER_PARTY_VOLUME_ACTIONS.delete.send));

  try {
    const json = yield manager.deleteCounterPartyVolumeConfig(action.payload);
    yield put(deleteCounterPartyVolumeConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(COUNTER_PARTY_VOLUME_ACTIONS.delete.send));
}

export function* deleteCounterPartyVolumeConfigWatcher() {
  yield takeLatest(COUNTER_PARTY_VOLUME_ACTIONS.delete.send, deleteCounterPartyVolumeConfigBegin);
}
