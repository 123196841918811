import { CUSTOMER_CASE_ACTIONS } from 'modules/customer-cases/case-list/middleware/action-types';

const customerCaseListReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_CASE_ACTIONS.allCases.fetch.data: {
      if (!action?.payload?.paging?.scrollId) {
        return {
          ...state,
          cases: [],
        };
      }
      return state;
    }

    case CUSTOMER_CASE_ACTIONS.allCases.fetch.success: {
      const cases = [...state.cases, ...action.payload.data.cases];
      return {
        ...state,
        cases,
        totalCases: action.payload.data.totalHits,
        paging: {
          scrollId: action.payload.data.scrollId,
          lastPage: action.payload.data.cases.length === 0,
        },
      };
    }

    default:
      return state;
  }
};

export default customerCaseListReducer;
