import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class CaseListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('CaseList API Communicator instance is required.');
    }
  }

  static validateAllCasesJson(json) {
    const valid = json && json.paging && Array.isArray(json.cases);
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  queryAllCases(paging, sortingConfig, filterConfig, allMaskedDataShown) {
    return this.communicator
      .fetchAllCases(paging, sortingConfig, filterConfig, allMaskedDataShown)
      .then((json) => CaseListManager.validateAllCasesJson(json));
  }
}

export default CaseListManager;
