import { put, takeLatest } from 'redux-saga/effects';
import { VOLUME_RISK_V2_ACTIONS } from 'modules/configuration/rule-manager/volume-config-v2/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteVolumeRiskV2ConfigError,
  deleteVolumeRiskV2ConfigSuccess,
  getVolumeRiskV2ConfigError,
  getVolumeRiskV2ConfigSuccess,
  updateVolumeRiskV2ConfigError,
  updateVolumeRiskV2ConfigSuccess,
} from 'modules/configuration/rule-manager/volume-config-v2/middleware/actions/volume-risk-v2-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';

const communicator = new RuleConfigAPICommunicator('volume-risk-v2');
const manager = new RuleConfigManager(communicator);

function* getVolumeRiskV2ConfigBegin(action) {
  yield put(startProcessing(VOLUME_RISK_V2_ACTIONS.fetch.data));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getVolumeRiskV2ConfigSuccess(json));
  } catch (error) {
    yield put(getVolumeRiskV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(VOLUME_RISK_V2_ACTIONS.fetch.data));
}

export function* getVolumeRiskV2ConfigBeginWatcher() {
  yield takeLatest(VOLUME_RISK_V2_ACTIONS.fetch.data, getVolumeRiskV2ConfigBegin);
}

function* updateVolumeRiskV2ConfigBegin(action) {
  yield put(startProcessing(VOLUME_RISK_V2_ACTIONS.update.data));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateVolumeRiskV2ConfigSuccess(json));
  } catch (error) {
    yield put(updateVolumeRiskV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(VOLUME_RISK_V2_ACTIONS.update.data));
}

export function* updateVolumeRiskV2ConfigWatcher() {
  yield takeLatest(VOLUME_RISK_V2_ACTIONS.update.data, updateVolumeRiskV2ConfigBegin);
}

function* deleteVolumeRiskV2ConfigBegin(action) {
  yield put(startProcessing(VOLUME_RISK_V2_ACTIONS.delete.data));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteVolumeRiskV2ConfigSuccess(json));
  } catch (error) {
    yield put(deleteVolumeRiskV2ConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(VOLUME_RISK_V2_ACTIONS.delete.data));
}

export function* deleteVolumeRiskV2ConfigWatcher() {
  yield takeLatest(VOLUME_RISK_V2_ACTIONS.delete.data, deleteVolumeRiskV2ConfigBegin);
}
