import { Box, Chip } from '@mui/material';
import { Translation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { RootState } from 'middleware/store';
import { Filter } from 'components/filters/models/filter';
import { getCaseNotificationsConfig } from 'modules/configuration/workflows-and-users/components/case-notification/middleware/actions/case-notifications-config';

interface CaseNotificationRuleInstanceChipProps {
  form: Filter;
}

const CaseNotificationRuleInstanceChip = ({ form }: CaseNotificationRuleInstanceChipProps) => {
  const dispatch = useDispatch();
  const caseNotificationConfig: CaseNotificationConfig = useSelector(
    (state: RootState) => state.configContainer.caseNotificationsConfig as CaseNotificationConfig,
  );
  const [selectedInstances, setSelectedInstances] = useState<CaseNotificationInstance[]>([]);

  useEffect(() => {
    if (isEmpty(caseNotificationConfig)) {
      dispatch(getCaseNotificationsConfig());
      return;
    }

    if (caseNotificationConfig.instances) {
      setSelectedInstances(
        caseNotificationConfig.instances.filter((value) => form.values.includes(value.uniqueId)),
      );
    }
  }, [caseNotificationConfig, dispatch, form.values, setSelectedInstances]);

  return (
    <Translation>
      {(t) => (
        <>
          <Box fontSize="caption.fontSize" color="text.secondary">
            {t('mapping:filters.filtering.caseNotificationRuleInstanceIds.value.heading')}
          </Box>
          {selectedInstances?.map((item, index) => (
            <Chip key={index} variant="outlined" size="small" className="mr-1" label={item.title} />
          ))}
        </>
      )}
    </Translation>
  );
};

export default CaseNotificationRuleInstanceChip;
