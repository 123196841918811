import { CaseNotificationsConfigActions } from 'modules/configuration/workflows-and-users/components/case-notification/middleware/action-types';

const caseNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_GET_SUCCESS:
    case CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_UPDATE_SUCCESS:
    case CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_DELETE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default caseNotificationsReducer;
