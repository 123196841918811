import { call, put, takeLatest } from 'redux-saga/effects';
import ApiKeyConfigAPICommunicator from 'services/api-key-config-api-communicator';
import ApiKeyConfigManager from 'services/api-key-config-manager';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  ApiKeyConfigState,
  deleteApiKeysSuccess,
  getApiKeyConfigSuccess,
} from 'modules/configuration/api-key-config/middleware/reducers/api-key-config-reducer';
import { PayloadAction } from '@reduxjs/toolkit';

const communicator = new ApiKeyConfigAPICommunicator();
const manager = new ApiKeyConfigManager(communicator);

function* getApiKeyConfigBegin() {
  yield put(startProcessing('apiKeyConfig/getApiKeyConfig'));
  try {
    const apiKeyConfigState: ApiKeyConfigState = yield manager.getApiKeyConfig();
    yield put(getApiKeyConfigSuccess(apiKeyConfigState));
  } catch (error) {
    yield put(showError(error));
  }

  yield put(stopProcessing('apiKeyConfig/getApiKeyConfig'));
}

export function* getApiKeyConfigWatcher() {
  yield takeLatest('apiKeyConfig/getApiKeyConfig', getApiKeyConfigBegin);
}

function* sequentialDeleteBegin(action: PayloadAction<string[]>) {
  yield put(startProcessing('apiKeyConfig/deleteApiKeys'));
  let apiKeyConfigState: ApiKeyConfigState = { apiKeys: [] };
  // eslint-disable-next-line no-restricted-syntax
  for (const item of action.payload) {
    try {
      apiKeyConfigState = yield call(manager.deleteApiKey, item);
    } catch (error) {
      yield put(showError(error));
      yield put(stopProcessing('apiKeyConfig/deleteApiKeys'));
      return;
    }
  }
  yield put(deleteApiKeysSuccess(apiKeyConfigState));
  yield put(stopProcessing('apiKeyConfig/deleteApiKeys'));
}

export function* deleteApiKeyWatcher() {
  yield takeLatest('apiKeyConfig/deleteApiKeys', sequentialDeleteBegin);
}
