import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { BLACKLIST_CONFIG_ACTIONS } from 'middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import BlacklistApiCommunicator from 'services/blacklist-api-communicator';
import BlacklistConfigManager from 'services/blacklist-config-manager';
import {
  deleteBlacklistConfigError,
  deleteBlacklistConfigSuccess,
  getBlackListConfigError,
  getBlacklistConfigSuccess,
  updateBlacklistConfigError,
  updateBlacklistConfigSuccess,
} from 'middleware/actions/blacklist-config';

const communicator = new BlacklistApiCommunicator();
const manager = new BlacklistConfigManager(communicator);

function* getBlacklistConfigBegin(action) {
  yield put(startProcessing(BLACKLIST_CONFIG_ACTIONS.get.data));
  try {
    const json = yield manager.getBlacklistConfig(action.payload);
    yield put(getBlacklistConfigSuccess(json));
  } catch (error) {
    yield put(getBlackListConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(BLACKLIST_CONFIG_ACTIONS.get.data));
}

export function* getBlacklistConfigWatcher() {
  yield takeLatest(BLACKLIST_CONFIG_ACTIONS.get.data, getBlacklistConfigBegin);
}

function* updateBlacklistConfigBegin(action) {
  yield put(startProcessing(BLACKLIST_CONFIG_ACTIONS.update.data));

  try {
    const json = yield manager.updateBlacklistConfig(action.payload);
    yield put(updateBlacklistConfigSuccess(json));
  } catch (error) {
    yield put(updateBlacklistConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(BLACKLIST_CONFIG_ACTIONS.update.data));
}

export function* updateBlacklistConfigWatcher() {
  yield takeLatest(BLACKLIST_CONFIG_ACTIONS.update.data, updateBlacklistConfigBegin);
}

function* deleteBlacklistConfigBegin(action) {
  yield put(startProcessing(BLACKLIST_CONFIG_ACTIONS.delete.data));

  try {
    const json = yield manager.deleteBlacklistConfig(action.payload);
    yield put(deleteBlacklistConfigSuccess(json));
  } catch (error) {
    yield put(deleteBlacklistConfigError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(BLACKLIST_CONFIG_ACTIONS.delete.data));
}

export function* deleteBlacklistConfigWatcher() {
  yield takeLatest(BLACKLIST_CONFIG_ACTIONS.delete.data, deleteBlacklistConfigBegin);
}
