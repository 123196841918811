import { WIDTH_THRESHOLD } from 'modules/layout/main-view/constants/constants';
import { toggleVisibilityButton } from 'utils/helpers/utility';
import { useState, useEffect } from 'react';

export const useSidebarToggle = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isForcedOpen, setIsForcedOpen] = useState(false);
  const [isForcedClose, setIsForcedClose] = useState(false);
  const [contentWidth, setContentWidth] = useState();

  const toggleSidebar = () => {
    if (contentWidth >= WIDTH_THRESHOLD && isOpen) {
      setIsForcedClose(true);
      setIsForcedOpen(false);
    } else if (contentWidth < WIDTH_THRESHOLD && !isOpen) {
      setIsForcedClose(false);
      setIsForcedOpen(true);
    } else {
      setIsForcedClose(false);
      setIsForcedOpen(false);
    }
    setIsOpen(!isOpen);
  };

  const showHideSidebar = (width, height) => {
    if (width >= WIDTH_THRESHOLD) {
      setIsForcedOpen(false);

      if (!isForcedClose && !isOpen) {
        setIsOpen(true);
      }
    } else {
      setIsForcedClose(false);
      if (!isForcedOpen && isOpen) {
        setIsOpen(false);
      }
    }
    setContentWidth(width);
    setContentWidth(height);
  };

  useEffect(() => {
    toggleVisibilityButton(false);
  }, []);

  return { isOpen, showHideSidebar, toggleSidebar };
};
