import { Box, Divider, Grid, Chip } from '@mui/material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useForm, Form } from 'hooks/use-form';
import Controls from 'components/controls/controls';

const buildUserIdsValuesFromUrl = (filter) => ({
  field: i18next.t('mapping:filters.filtering.userId.key'),
  condition: i18next.t(`mapping:operator.text.${filter.condition}.key`),
  values: filter.values,
});

const UserIdForm = ({ editForm, onSubmit, fullWidth }) => {
  const { t } = useTranslation();

  const filterConditions = [
    t('mapping:operator.text.TEXT_EQUAL'),
    t('mapping:operator.text.TEXT_NOT_EQUAL'),
  ];

  const initialFValues = {
    userId: editForm?.values || [],
    condition: editForm?.condition
      ? t(`mapping:operator.text.${editForm.condition}`)
      : filterConditions[0],
  };

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define, consistent-return
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    if ('userId' in fieldValues) {
      temp.userId = fieldValues.userId?.length ? '' : t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        field: t('mapping:filters.filtering.userId.key'),
        condition: values.condition.key,
        values: values.userId,
      });
    }
  };
  return (
    <Grid container className={fullWidth ? 'w-250' : ''}>
      <Form className="w-100">
        <Grid container item className="px-3 py-3" spacing={2}>
          <Grid item xs={6}>
            <Controls.Select
              fullWidth
              name="condition"
              label={t('condition.heading')}
              value={values.condition}
              onChange={handleInputChange}
              options={filterConditions}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                name="userId"
                label={t('mapping:filters.filtering.userId.value.heading')}
                value={values.userId}
                onChange={handleInputChange}
                error={errors.userId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            dataTestIdPrefix="filter-by-user-id"
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};

const UserIdChip = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.userId.value.heading')}
      </Box>

      <Box fontSize="body2.fontSize">
        {form.condition !== t(`mapping:operator.text.TEXT_EQUAL.key`) && (
          <Box fontSize="caption.fontSize" component="span">
            {t(`mapping:operator.text.${form.condition}.value.heading`)}{' '}
          </Box>
        )}
        {form.values.map((item, index) => (
          <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
        ))}
      </Box>
    </>
  );
};

export { UserIdForm, UserIdChip, buildUserIdsValuesFromUrl };
