import { useState } from 'react';
import $ from 'jquery';
import { Tooltip } from '@mui/material';

const HoverComponent = ({
  activeClassName = 'rounded bg-warning text-light',
  id,
  tooltipTitle,
  hoverClass,
  noPadding,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const findAllElements = (callback) => {
    const allElements = $(`*[id=${$.escapeSelector(id)}]:visible`);
    if (allElements.length <= 1) {
      return;
    }
    allElements.each(function () {
      callback($(this));
    });
  };

  const handleMouseHover = () => {
    const activeClass = activeClassName + (hoverClass !== undefined ? ` ${hoverClass}` : '');
    findAllElements((el) => el.addClass(activeClass));

    if (!open) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    const activeClass = activeClassName + (hoverClass !== undefined ? ` ${hoverClass}` : '');
    findAllElements((el) => el.removeClass(activeClass));

    if (open) {
      setOpen(false);
    }
  };

  const componentClassName = noPadding
    ? 'hover-component'
    : 'hover-component hover-component-padding';

  const mainComponent = (
    <span
      id={id}
      className={`${componentClassName} wb-break-word`}
      onMouseOver={handleMouseHover}
      onMouseOut={handleMouseLeave}
    >
      {children}
    </span>
  );

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} open={open}>
        {mainComponent}
      </Tooltip>
    );
  }
  return mainComponent;
};

export default HoverComponent;
