import { CountryRiskConfigActions } from 'modules/configuration/rule-manager/country-config/middleware/action-types';

export const getCountryRiskConfig = () => ({
  type: CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET,
});

export const getCountryRiskConfigSuccess = (payload) => ({
  type: CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET_SUCCESS,
  payload,
});

export const getCountryRiskConfigError = () => ({
  type: CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET_ERROR,
});

export const updateCountryRiskConfig = (payload) => ({
  type: CountryRiskConfigActions.COUNTRY_RISK_CONFIG_UPDATE,
  payload,
});

export const updateCountryRiskConfigSuccess = (payload) => ({
  type: CountryRiskConfigActions.COUNTRY_RISK_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateCountryRiskConfigError = () => ({
  type: CountryRiskConfigActions.COUNTRY_RISK_CONFIG_UPDATE_ERROR,
});
