import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import { LoadingButton } from 'components/loaders/loading-button';
import { useTranslation } from 'react-i18next';
import { useCustomViews } from 'hooks/use-custom-views';
import { useLocation } from 'react-router-dom';
import { stripDefaultFiltering } from 'utils/helpers/url-utils/url-params-manager';

type SaveCustomViewDialogProps = {
  isDialogOpened: boolean;
  setDialogOpened: (value: boolean) => void;
};

export const SaveCustomViewDialog = (props: SaveCustomViewDialogProps) => {
  const { isDialogOpened, setDialogOpened } = props;
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const { createCustomView } = useCustomViews();
  const { search } = useLocation();

  const handleSave = (_: React.MouseEvent<HTMLButtonElement>) => {
    const url = stripDefaultFiltering(search);
    createCustomView({
      id: Date.now().toString(),
      name,
      value: url,
    });
    setDialogOpened(false);
  };

  return (
    <Dialog open={isDialogOpened} fullWidth>
      <DialogTitle className="pb-0">{t('case:caseList.customViews.saveView.heading')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems="center">
          <Grid container item alignItems="center" className="mt-3">
            <Grid item xs alignItems="center">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore Controls.Input not migrated to tsx yet */}
              <Controls.Input
                name="customViewName"
                label={t('name.heading')}
                value={name}
                onChange={(newValue: React.ChangeEvent<HTMLInputElement>) => {
                  setName(newValue.target.value);
                }}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpened(false)} color="default">
          {t('cancel.heading')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          title={t('confirm.heading')}
          onClick={handleSave}
        />
      </DialogActions>
    </Dialog>
  );
};
