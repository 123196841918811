import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Divider, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import { Filter, KeyValueHeading } from 'components/filters/models/filter';

export interface OptionFormProperties {
  filterKey: string;
  onSubmit: (filter: Filter) => void;
  editForm: Filter;
}

interface InitialFormValues {
  [key: string]: boolean;
}

const OptionForm = ({ onSubmit, filterKey, editForm }: OptionFormProperties) => {
  const { t } = useTranslation();
  const initialFValues: InitialFormValues = {};
  if (editForm) {
    editForm.values.forEach((item: string) => {
      initialFValues[`${filterKey}[${item}]`] = true;
    });
  }

  const options: KeyValueHeading[] = Object.values(t(`mapping:${filterKey}`));

  const { values, handleInputChange } = useForm(initialFValues);

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  const handleSelectChange = (event: UIEvent, checkedName: string) => {
    handleInputChange(event);

    const newCaseStatuses = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name: string) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];

    setCheckedValues(newCaseStatuses);
  };

  const handleSubmit = (event: UIEvent) => {
    event.preventDefault();

    onSubmit({
      condition: t('mapping:operator.text.TEXT_EQUAL.key'),
      field: filterKey,
      values: checkedValues,
    });
  };

  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
          {options.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Controls.Checkbox
                data-testid={`${filterKey}-${index}`}
                id={`${filterKey}-${index}`}
                name={`${filterKey}[${item.key}]`}
                label={item.value?.heading}
                value={values[`${filterKey}[${item.key}]`] ?? false}
                onChange={(event: UIEvent) => handleSelectChange(event, item.key)}
                size="small"
                fullWidth={false}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            type="submit"
            color="primary"
            size="medium"
            variant="text"
            onClick={undefined}
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};

export interface OptionChipProperties {
  filterKey: string;
  form: Filter;
}

const OptionChip = ({ filterKey, form }: OptionChipProperties) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t(`mapping:filters.filtering.${filterKey}.value.heading`)}
      </Box>
      {form.values.map((item: string, index: number) => (
        <Chip
          key={index}
          variant="outlined"
          size="small"
          className="mr-1"
          label={t(`mapping:${filterKey}.${item}.value.heading`)}
        />
      ))}
    </>
  );
};

export { OptionForm, OptionChip };
