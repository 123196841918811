import i18next from 'i18next';
import moment from 'moment';
import { CHARTS as CHART, API_TYPE, RISK_CATEGORY, ALERT_STATUS } from 'utils/constants/constant';

const endOfToday = () => {
  /**
   * WARNING: it gives the start of the next day
   */
  const now = moment(new Date()).add(1, 'days');
  now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return now.toDate();
};

const beginOfToday = () => {
  const now = moment(new Date());
  now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return now.toDate();
};

const createTimeFrameOption = (lookBackDuration, timeUnit, optionName) => {
  const from = moment(beginOfToday()).subtract(lookBackDuration, timeUnit).toDate();
  return { from, to: endOfToday(), name: optionName };
};

export const TimeFrameName = {
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  LAST_3_MONTH: 'LAST_3_MONTH',
  LAST_6_MONTH: 'LAST_6_MONTH',
  LAST_YEAR: 'LAST_YEAR',
};

export const TIME_FRAME_OPTIONS = {
  [TimeFrameName.LAST_WEEK]: {
    key: TimeFrameName.LAST_WEEK,
    value: {
      heading: i18next.t('dashboard:timeFrame.last7Days'),
      ...createTimeFrameOption(7, 'days', TimeFrameName.LAST_WEEK),
    },
  },
  [TimeFrameName.LAST_MONTH]: {
    key: TimeFrameName.LAST_MONTH,
    value: {
      heading: i18next.t('dashboard:timeFrame.last30Days'),
      ...createTimeFrameOption(1, 'months', TimeFrameName.LAST_MONTH),
    },
  },
  [[TimeFrameName.LAST_3_MONTH]]: {
    key: TimeFrameName.LAST_3_MONTH,
    value: {
      heading: i18next.t('dashboard:timeFrame.last3Months'),
      ...createTimeFrameOption(3, 'months', TimeFrameName.LAST_3_MONTH),
    },
  },
  [[TimeFrameName.LAST_6_MONTH]]: {
    key: TimeFrameName.LAST_6_MONTH,
    value: {
      heading: i18next.t('dashboard:timeFrame.last6Months'),
      ...createTimeFrameOption(6, 'months', TimeFrameName.LAST_6_MONTH),
    },
  },
  [[TimeFrameName.LAST_YEAR]]: {
    key: TimeFrameName.LAST_YEAR,
    value: {
      heading: i18next.t('dashboard:timeFrame.lastYear'),
      ...createTimeFrameOption(1, 'years', TimeFrameName.LAST_YEAR),
    },
  },
};

export const getTimeFrameOption = (timeFrame) => {
  const differenceInDays =
    (timeFrame.value.to.getTime() - timeFrame.value.from.getTime()) / (1000 * 3600 * 24);
  if (differenceInDays < 30) {
    return TIME_FRAME_OPTIONS.LAST_WEEK;
  }
  if (differenceInDays < 90) {
    return TIME_FRAME_OPTIONS.LAST_MONTH;
  }
  if (differenceInDays < 180) {
    return TIME_FRAME_OPTIONS.LAST_3_MONTH;
  }
  if (differenceInDays < 360) {
    return TIME_FRAME_OPTIONS.LAST_6_MONTH;
  }
  return TIME_FRAME_OPTIONS.LAST_YEAR;
};

export const SORTED_RISK_LEVELS = {
  [RISK_CATEGORY.low]: {
    label: i18next.t('mapping:riskLevel.low.value.heading'),
    color: CHART.riskSeriesColors[0],
  },
  [RISK_CATEGORY.medium]: {
    label: i18next.t('mapping:riskLevel.medium.value.heading'),
    color: CHART.riskSeriesColors[1],
  },
  [RISK_CATEGORY.high]: {
    label: i18next.t('mapping:riskLevel.high.value.heading'),
    color: CHART.riskSeriesColors[2],
  },
  [RISK_CATEGORY.prohibited]: {
    label: i18next.t('mapping:riskLevel.prohibited.value.heading'),
    color: CHART.riskSeriesColors[3],
  },
};

export const SORTED_API_TYPES = {
  [API_TYPE.CustomerChecks]: {
    label: i18next.t('mapping:apiTypes.customerChecks.value.heading'),
    color: CHART.seriesColors[1],
  },
  [API_TYPE.AMLChecks]: {
    label: i18next.t('mapping:apiTypes.transactionChecks.value.heading'),
    color: CHART.seriesColors[0],
  },
};

export const SORTED_ALERT_STATUSES = {
  [ALERT_STATUS.open]: {
    label: i18next.t('mapping:caseStatusV2.open.value.heading'),
    color: CHART.seriesColors[0],
  },
  [ALERT_STATUS.notOpened]: {
    label: i18next.t('mapping:caseStatusV2.not-opened.value.heading'),
    color: CHART.seriesColors[1],
  },
  [ALERT_STATUS.autoClosed]: {
    label: i18next.t('mapping:caseStatusV2.auto-closed.value.heading'),
    color: CHART.seriesColors[2],
  },
};
