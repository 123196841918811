import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class DecisionEngineConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Decision Engine Config API Communicator instance is required.');
    }
  }

  validateConfigJson(json) {
    if (
      json &&
      json.countryRiskActive !== undefined &&
      json.volumeRiskActive !== undefined &&
      json.pepSanctionActive !== undefined &&
      json.aiActive !== undefined &&
      json.highRiskCountryBlock !== undefined &&
      json.mediumRiskCountryBlock !== undefined
    ) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.DECISION_ENGINE_DATA_INVALID);
  }

  getDecisionEngineConfig() {
    return this.communicator
      .fetchDecisionEngineConfig()
      .then((json) => this.validateConfigJson(json));
  }

  getDecisionEngineConfigWithTenant(tenantId) {
    return this.communicator
      .fetchDecisionEngineConfigWithTenant(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  updateDecisionEngineConfig(params) {
    return this.communicator.updateDecisionEngineConfig(params);
  }
}

export default DecisionEngineConfigManager;
