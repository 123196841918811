import {
  FormControl,
  FormHelperText,
  TextField,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  renderOptionLabel,
  isOptionSelected,
  getOptionKey,
} from 'components/controls/helpers/multiple-input-utils';
import _ from 'lodash';
import { getGroupName } from 'utils/helpers/string-util';

const MultipleInput = ({
  name,
  dataTestIdPrefix,
  label,
  placeholder = null,
  value,
  tagsSeparatorText = null,
  size = 'small',
  error = null,
  shrink,
  freeSolo = true,
  options = [],
  filterSelectedOptions = false,
  onChange,
  disableCloseOnSelect = false,
  groupByEnabled = false,
  disableClearable = false,
  disabled = false,
  splitValueByCommaEnabled = true,
}) => {
  /**
   * not adding empty values
   * and setting to default form para
   * @param {*} name
   * @param {*} values
   * @returns
   */
  const customOnChange = (name, values) => {
    const eventValue = {
      target: {
        name,
        value: values,
      },
    };

    if (values?.length > value?.length) {
      if (values.at(-1)?.trim) {
        if (values.at(-1)?.trim() === '') {
          eventValue.target.value = value;
        }
      }
    }

    if (freeSolo && splitValueByCommaEnabled) {
      eventValue.target.value = eventValue.target.value
        .flatMap((val) => {
          if (val.includes(',')) {
            return val.split(',').map((s) => s.trim());
          }
          return val;
        })
        .filter((v) => v !== '');
    }

    return eventValue;
  };

  /**
   * add item on blur
   * @param {*} event
   */
  const onBlurField = (event) => {
    const currentInput = event.target.value.trim();

    if (currentInput !== '') {
      if (!value.includes(currentInput)) {
        onChange(customOnChange(name, [...value, currentInput]));
      }
    }
  };

  const getGroupBy = (option) => {
    const label = renderOptionLabel(option);
    return getGroupName(label);
  };

  const enableGroupBy = () => {
    const hasMultipleGroups = _.uniq(options.map(getGroupBy)).length > 1;
    return groupByEnabled && hasMultipleGroups;
  };

  const sortedOptions = _.sortBy(options, getGroupBy);

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      includeInputInList
      disableClearable={disableClearable}
      groupBy={enableGroupBy() ? getGroupBy : undefined}
      disableCloseOnSelect={disableCloseOnSelect}
      filterSelectedOptions={filterSelectedOptions}
      getOptionLabel={(option) => renderOptionLabel(option)}
      selectOnFocus
      isOptionEqualToValue={(option) => isOptionSelected(option, value)}
      value={value}
      onChange={(event, values) => onChange(customOnChange(name, values))}
      onBlur={(event) => onBlurField(event)}
      clearOnBlur
      options={sortedOptions}
      freeSolo={freeSolo}
      renderOption={(props, option) => (
        <ListItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          disablePadding
          dense
          selected={false}
          key={getOptionKey(option)}
        >
          <ListItemIcon>
            <Checkbox size="small" checked={isOptionSelected(option, value)} />
          </ListItemIcon>

          <ListItemText primary={renderOptionLabel(option)} />
        </ListItem>
      )}
      renderTags={(value, getTagProps) => {
        const chips = value.map((option, index) => (
          <Chip
            key={getOptionKey(option) + index}
            size="small"
            label={renderOptionLabel(option)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        ));
        const chipsCount = chips.length;
        if (chipsCount < 2 || !tagsSeparatorText) {
          return chips;
        }

        const tags = [chips[0]];
        for (let i = 1; i < chipsCount; i++) {
          tags.push(<Chip key={`tag_${i}`} size="small" label={tagsSeparatorText} disabled />);
          tags.push(chips[i]);
        }
        return tags;
      }}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FormControl fullWidth {...(error && { error: true })}>
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            data-testid={`${dataTestIdPrefix}-text-field`}
            size={size}
            placeholder={placeholder}
            error={Boolean(error)}
            label={label}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink,
            }}
            inputProps={{
              ...params.inputProps,
              'data-testid': `${dataTestIdPrefix}-text-field-value`,
            }}
          />
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default MultipleInput;
