import React, { FC } from 'react';
import { Chip } from '@mui/material';
import Spinner from 'components/loaders/spinner';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type LoadingChipButtonProps = {
  title: string;
  isSelected: boolean;
  isLoading: boolean;
  onClick: (event: React.MouseEvent<never>) => void;
};

export const LoadingChipButton: FC<LoadingChipButtonProps> = ({
  title,
  isSelected,
  isLoading,
  onClick,
}) => {
  const label = (
    <>
      {title}
      {isLoading ? (
        <Spinner
          size={16}
          thickness={4}
          color="inherit"
          className="mb-n1xs ml-2"
          isLoading={isLoading}
          actionType={undefined}
          multipleActions={undefined}
        />
      ) : (
        <ExpandMoreIcon fontSize="small" />
      )}
    </>
  );

  return (
    <Chip label={label} color={isSelected ? 'primary' : 'default'} onClick={onClick} clickable />
  );
};
