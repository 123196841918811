import { FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/filters-instantiable-list/middleware/action-types';

export const getFiltersInstantiableListConfig = (tenantId) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getFiltersInstantiableListConfigSuccess = (payload) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.success,
  payload,
});

export const getFiltersInstantiableListConfigError = () => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.error,
});
export const updateFiltersInstantiableListConfig = (payload) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.data,
  payload,
});

export const updateFiltersInstantiableListConfigSuccess = (payload) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.success,
  payload,
});

export const updateFiltersInstantiableListConfigError = () => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.error,
});
export const deleteFiltersInstantiableListConfig = (payload) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.data,
  payload,
});

export const deleteFiltersInstantiableListConfigSuccess = (payload) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.success,
  payload,
});

export const deleteFiltersInstantiableListConfigError = (payload) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.error,
  payload,
});

export const activateFiltersInstantiableListConfig = (version) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateFiltersInstantiableListConfigSuccess = (payload) => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.success,
  payload,
});

export const activateFiltersInstantiableListConfigError = () => ({
  type: FILTERS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.error,
});
