import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { formatNumberWithCommas } from 'utils/helpers/utility';

interface TotalResultProps {
  value: number;
}

const TotalResult: React.FC<TotalResultProps> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="caption" color="textSecondary">
      {`${formatNumberWithCommas(value)} ${
        value === 1 ? t('result.heading') : t('result.subheading')
      }`}
    </Typography>
  );
};

export default TotalResult;
