import i18next from 'i18next';
import { Filter } from 'components/filters/models/filter';

const buildCaseNotificationRuleInstancesFromUrl = (filter: Filter) => ({
  field: i18next.t('mapping:filters.filtering.caseNotificationRuleInstanceIds.key'),
  condition: i18next.t(`mapping:operator.text.${filter.condition}.key`),
  values: filter?.values,
});

export { buildCaseNotificationRuleInstancesFromUrl };
