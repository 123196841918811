import { Translation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';

/**
 * Base component for displaying HIDTA or HIFCA data.
 */
const HidtaHifcaDetails = ({ scoreData, maxPoints, popoverMode, headlineKey }) => {
  const scoreDataInternal = scoreData ?? {};

  const metaDataKeys = getMetaDataKeys();
  const originalData = scoreDataInternal[metaDataKeys.originalData];

  return (
    <Translation>
      {(t) => (
        <ScoreDetailsAccordion
          summaryComponent={
            <WheelPointsWithHeadline
              size={popoverMode ? 'small' : 'medium'}
              points={scoreDataInternal[metaDataKeys.calculatedPoints] ?? 0}
              maxPoints={maxPoints}
              headline={t(`case:userPopover.riskRating.${headlineKey}.heading`)}
            />
          }
          highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
          detailsComponent={
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {
                    // Data returned from API which is not in camel case per standard
                    originalData
                      ? `${originalData.zip} ${originalData.primary_city}, ${originalData.state}`
                      : t(`case:userPopover.riskRating.${headlineKey}.noMatch.heading`)
                  }
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {originalData?.Program}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )}
    </Translation>
  );
};

export { HidtaHifcaDetails };
