import { Typography } from '@mui/material';
import CountryName from 'components/country-name';
import { MASKED_ACTIONS } from 'middleware/action-types';
import { unmask, updateCaseDetailDataShown } from 'middleware/actions/mask';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';
import LocalStorageService from 'services/local-storage-service';
import { getLocaleDateString } from 'utils/helpers/date-time-util';
import { isCaseRoute } from 'utils/helpers/examination-util';
import { getAllCurrentCasesId } from 'utils/helpers/extraction-util';
import { removeListFromList } from 'utils/helpers/list-string-util';
import { stillNeedUnmask } from 'utils/helpers/mask-util';
import { randomHtmlId } from 'utils/helpers/math-util';

const localStorageService = new LocalStorageService();
const MAX_LENGTH = 30;

const UnmaskedText = ({ text, dataType, tooltipId, grow }) => {
  const unmaskedText = text ?? '';

  const truncateText = (text) => {
    if (!!text && text.length > MAX_LENGTH) {
      const first12 = text.substring(0, 12);
      const last12 = text.substring(text.length - 12, text.length);
      return `${first12} ... ${last12}`;
    }

    return text;
  };

  const getTextmask = (text, grow) => {
    switch (dataType) {
      case 'date':
        return <span>{getLocaleDateString(new Date(text))}</span>;

      case 'country':
        return <CountryName code={text} />;

      default:
        if (text.length > MAX_LENGTH) {
          return (
            <span id={tooltipId} className="clickable wb-break-word">
              {grow ? text : truncateText(text)}
              <UncontrolledTooltip placement="top" target={tooltipId}>
                {text}
              </UncontrolledTooltip>
            </span>
          );
        }
        return <span>{text}</span>;
    }
  };
  return <>{getTextmask(unmaskedText, grow)}</>;
};

const MaskedText = ({
  token,
  uuid,
  reloadCaseId,
  grow,
  customClass,
  dataType,
  disableUnmasking,
}) => {
  const urlParams = useParams();

  const maskeds = useSelector((state) => state.masks?.all || []);
  const unmaskedCaseIds = useSelector((state) => state.masks?.unmaskedCaseIds || []);
  const username = useSelector((state) => state.authentication?.user?.displayName);
  const processing = useSelector((state) => state.processing);
  const transactionHistory = useSelector((state) => state.case?.transactionHistory || {});
  const networkAnalysis = useSelector((state) => state.networkAnalysis || {});
  const caseDetailDataShown = useSelector((state) => state.masks?.caseDetailDataShown);
  const casesDataShown = useSelector((state) => state.masks?.casesDataShown);

  const dispatch = useDispatch();

  const [tooltipId] = useState(randomHtmlId());
  const [individualClicked, setIndividualClicked] = useState(false);

  const getData = () => maskeds?.find((item) => item.token === token) || {};

  const handleUnmask = (e) => {
    if (disableUnmasking) {
      return;
    }
    if (!caseDetailDataShown) {
      localStorageService.updateCaseDetailDataShown();
      dispatch(updateCaseDetailDataShown(true));

      return;
    }

    setIndividualClicked(true);

    e.stopPropagation();

    if (!stillNeedUnmask(maskeds)) {
      return;
    }

    const caseIds = getAllCurrentCasesId(
      uuid || urlParams.uuid,
      transactionHistory,
      networkAnalysis,
    );
    removeListFromList(unmaskedCaseIds, caseIds);
    dispatch(
      unmask({
        masterUuid: urlParams.uuid || uuid,
        displayUuid: uuid || urlParams.uuid,
        uuids: caseIds,
        userId: username,
        reloadCaseId,
      }),
    );
  };

  const truncateMasked = (masked) => {
    if (!!masked && masked.length > MAX_LENGTH) {
      const first4 = masked.substring(0, 4);
      const last4 = masked.substring(masked.length - 4, masked.length);
      return `${first4} **** **** ... **** **** ${last4}`;
    }

    return masked;
  };

  const shouldUnmask = (data) => {
    if (!!caseDetailDataShown || (!!casesDataShown && !isCaseRoute()) || !!individualClicked) {
      return data.unmasked && data.unmasked.length > 0;
    }

    return false;
  };

  const data = getData();

  if (shouldUnmask(data)) {
    return (
      <UnmaskedText dataType={dataType} text={data?.unmasked} tooltipId={tooltipId} grow={grow} />
    );
  }
  return (
    <Button
      color="link"
      disabled={processing?.[MASKED_ACTIONS.unmask.data]}
      onClick={handleUnmask}
      className={customClass}
    >
      {truncateMasked(data?.masked) ||
        (token ? (
          '***'
        ) : (
          <Typography variant="body2" color="textPrimary">
            -
          </Typography>
        ))}
    </Button>
  );
};

export default MaskedText;
