import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class SanctionListAPICommunicator extends APICommunicator {
  fetchReferenceNames(size, page, sortParams, filterColumn, filterValue) {
    const params = `size=${size}&page=${page}&sort-params=${sortParams}&filter-column=${filterColumn}&filter-value=${filterValue}`;
    const apiUrl = `${config.sanctionBaseUrl}/dow-jones/sanction-lists/?${params}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.SANCTION_BACKEND_NOT_AVAILABLE);
  }

  fetchSanctionListLastUpdate() {
    const apiUrl = `${config.sanctionBaseUrl}/dowjones/last-update`;
    return this.getJSONForTenant(apiUrl, ErrorCode.SANCTION_BACKEND_NOT_AVAILABLE);
  }

  fetchPepSanctionConfig() {
    const apiUrl = `${
      config.configurationUrl
    }/config/pepsanction/${TenantManagementService.getActiveTenantId()}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchInstanciablePepSanctionConfig() {
    const apiUrl = API_URLS.configService.instanciablePepSanction.get.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchPepSanctionRegions() {
    const apiUrl = `${config.sanctionBaseUrl}/dowjones/sanction-lists/types`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchRegionSanctionLists(size, page, sortField, sortDirection, filters) {
    let filterParams = '';
    filters.forEach((el) => {
      filterParams += `&filter-fields=${el.filterFields}&filter-values=${encodeURIComponent(
        el.filterValues,
      )}`;
    });

    const params = `size=${size}&page=${page}&sort-field=${sortField}&direction=${sortDirection}${filterParams}`;
    const apiUrl = `${config.sanctionBaseUrl}/dowjones/sanction-lists?${params}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  savePepSanctionConfig(params) {
    const apiUrl = API_URLS.configService.updatePepSanctionConfig;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  saveInstanciablePepSanctionConfig(params) {
    const apiUrl = API_URLS.configService.instanciablePepSanction.upsert.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.postJSONForTenant(
      apiUrl,
      {
        instances: [params],
      },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deletePepSanctionInstanciableConfig(id) {
    const apiUrl = API_URLS.configService.instanciablePepSanction.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.id, id);

    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updatePepSanctionInstanciableProvider(provider) {
    const apiUrl = API_URLS.configService.instanciablePepSanction.updateProvider
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.provider, provider);

    return this.postForCustomTenant(
      apiUrl,
      null,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default SanctionListAPICommunicator;
