export const VolumeIncreaseDecreaseConfigActions = {
  VOLUME_INCREASE_DECREASE_CONFIG_GET: 'VOLUME_INCREASE_DECREASE_CONFIG_GET',
  VOLUME_INCREASE_DECREASE_CONFIG_GET_SUCCESS: 'VOLUME_INCREASE_DECREASE_CONFIG_GET_SUCCESS',
  VOLUME_INCREASE_DECREASE_CONFIG_GET_ERROR: 'VOLUME_INCREASE_DECREASE_CONFIG_GET_ERROR',
  VOLUME_INCREASE_DECREASE_CONFIG_UPDATE: 'VOLUME_INCREASE_DECREASE_CONFIG_UPDATE',
  VOLUME_INCREASE_DECREASE_CONFIG_UPDATE_SUCCESS: 'VOLUME_INCREASE_DECREASE_CONFIG_UPDATE_SUCCESS',
  VOLUME_INCREASE_DECREASE_CONFIG_UPDATE_ERROR: 'VOLUME_INCREASE_DECREASE_CONFIG_UPDATE_ERROR',
  VOLUME_INCREASE_DECREASE_CONFIG_DELETE: 'VOLUME_INCREASE_DECREASE_CONFIG_DELETE',
  VOLUME_INCREASE_DECREASE_CONFIG_DELETE_SUCCESS: 'VOLUME_INCREASE_DECREASE_CONFIG_DELETE_SUCCESS',
  VOLUME_INCREASE_DECREASE_CONFIG_DELETE_ERROR: 'VOLUME_INCREASE_DECREASE_CONFIG_DELETE_ERROR',
};
