import React, { FC } from 'react';
import { MenuButton, Option } from 'components/menu-button/menu-button';
import { Filter, SortingRule } from 'models/api/search';
import { headers } from 'components/button-complex/file-download';
import { useDownloadFile } from 'hooks/use-download-file';
import { prepareFiltersForHttpRequest } from 'utils/csv-export/http-request-filters';
import { useTranslation } from 'react-i18next';
import { useStreamDownloader } from 'hooks/use-stream-downloader';
import TenantManagementService from 'services/tenant/tenant-management-service';
import SessionService from 'modules/authentication/login/services/session-service';

export type MenuOption = {
  label: string;
  url: string;
  limit?: number;
  message?: string;
  useStreamDownloader?: boolean;
};

type CasesFileExportMenuProps = {
  menuOptions: MenuOption[];
  sortingOptions: SortingRule[];
  filterOptions: Filter[];
  total?: number;
};

const auth = new SessionService();

export const CasesFileExportMenu: FC<CasesFileExportMenuProps> = ({
  menuOptions,
  sortingOptions,
  filterOptions,
  total,
}) => {
  const { t } = useTranslation();
  const { isDownloading: isDownloadingStream, downloadFile: downloadFileStream } =
    useStreamDownloader();
  const { mutate: downloadFile, isLoading } = useDownloadFile();
  const fileName = 'cases.csv';
  const caseLimitExceeded = (maxCaseAmount: number) =>
    typeof total !== 'number' || total > maxCaseAmount;
  const caseLimitDescription = (maxCaseAmount: number) =>
    caseLimitExceeded(maxCaseAmount) ? t('csv.download.casesLimit', { maxCaseAmount }) : undefined;

  const httpRequestFilterOptions: Filter[] = prepareFiltersForHttpRequest(filterOptions);

  const preparePayload = (sortingOptions: SortingRule[], filterOptions: Filter[]) => ({
    sorting: {
      orders: sortingOptions,
    },
    filtering: {
      filters: filterOptions,
      operator: 'AND',
    },
  });

  const payload = preparePayload(sortingOptions, httpRequestFilterOptions);

  const streamingHeaders = new Headers({
    'Content-Type': 'application/json',
    'Content-Disposition': `attachment; filename=${fileName}`,
    tenant_id: TenantManagementService.getActiveTenantId() ?? '',
    tenantId: TenantManagementService.getActiveTenantId() ?? '',
    correlationId: auth.getCorrelationId() ?? '',
    userId: auth.getUserId() ?? '',
  });

  const handleDownload = (url: string) => {
    downloadFile({ url, payload, config: headers(fileName, 'application/json'), fileName });
  };

  const options: Option[] = menuOptions.map((menuOption) => ({
    onClick: async () => {
      if (menuOption.useStreamDownloader) {
        return downloadFileStream(menuOption.url, fileName, streamingHeaders, payload);
      }

      return handleDownload(menuOption.url);
    },
    title: menuOption.label,
    disabled: menuOption.limit ? caseLimitExceeded(menuOption.limit) : undefined,
    description: menuOption.limit ? caseLimitDescription(menuOption.limit) : undefined,
    message: menuOption.message,
  }));

  return (
    <MenuButton
      title={t('csv.heading')}
      options={options}
      isLoading={isDownloadingStream || isLoading}
    />
  );
};
