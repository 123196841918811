import React, { FC, MouseEvent } from 'react';
import { clearCaseDetail } from 'middleware/actions/case';
import TrxCaseRow from 'modules/trx-cases/case-list/components/row/trx-case-row';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CaseDetail } from 'models/case/case-detail';
import { TenantConfig } from 'models/tenant/tenant';
import { ColumnConfig } from 'models/case-list/case-list';

interface TrxCaseRowContainerProps {
  canSeeCaseDetails: boolean;
  columnConfig: Array<ColumnConfig>;
  detailUrl: string;
  item: CaseDetail;
  tenantConfig: TenantConfig;
  testIdPrefix: string;
}

const TrxCaseRowContainer: FC<TrxCaseRowContainerProps> = ({
  item,
  detailUrl,
  canSeeCaseDetails,
  tenantConfig,
  testIdPrefix,
  columnConfig,
}) => {
  const { forwardingCasesCount, transactionCount } = { ...item };

  const txCount = forwardingCasesCount > 1 ? forwardingCasesCount : transactionCount;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();

    if (canSeeCaseDetails) {
      dispatch(clearCaseDetail());
      history.push(detailUrl);
    }
  };

  return (
    <TrxCaseRow
      columnConfig={columnConfig}
      onClick={handleClick}
      txCount={txCount}
      canSeeCaseDetails={canSeeCaseDetails}
      tenantConfig={tenantConfig}
      testIdPrefix={testIdPrefix}
      caseDetails={item}
      detailUrl={detailUrl}
    />
  );
};

export default TrxCaseRowContainer;
