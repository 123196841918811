import { Grid } from '@mui/material';
import { EmptyState } from 'components/empty-state';
import { useTranslation } from 'react-i18next';
import { ManualRiskRatingSummary } from 'components/popover/person-details/person-details-tab/risk-rating/manual-risk-rating-summary';
import { RiskRatingCategorySummary } from 'components/popover/person-details/person-details-tab/risk-rating/risk-rating-category-summary';
import { RiskRatingSummary } from 'components/popover/person-details/person-details-tab/risk-rating/risk-rating-summary';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { getMetaDataKeys } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import { DATE_TIME_FORMAT } from 'utils/constants/constant';
import moment from 'moment';

/**
 * Summary view of customer risk rating
 * @param {*} props
 */
const RiskRatingDataSummaryView = ({
  getScoreOnly,
  removeScoreLegend,
  popoverMode,
  manualRiskLevelChange = false,
  manualOverrides = {},
  summary,
  maxPoints,
  isIndividual,
  riskScoreCategory,
  riskScoreCategoryDerivedData,
  categoryMaxPoints,
  handleChange,
  isCustomerList = false,
}) => {
  const { t } = useTranslation();
  const metaDataKeys = getMetaDataKeys();

  const RiskRatingScoreOnly = () => (
    <RiskRatingSummary
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...summary}
      getScoreOnly
      removeScoreLegend={removeScoreLegend}
      popoverMode={popoverMode}
      maxPoints={maxPoints}
      isIndividual={isIndividual}
      manualRiskLevelChange={manualRiskLevelChange}
      isCustomerList={isCustomerList}
    />
  );

  if (getScoreOnly) {
    return <RiskRatingScoreOnly />;
  }

  function getActiveTill(manualOverrides) {
    if (manualOverrides?.validUntil?.durationType === 'UNTIL_DATE') {
      return moment(manualOverrides.validUntil.expirationDate).format(
        DATE_TIME_FORMAT.mediumMoment,
      );
    }
    if (manualOverrides?.validUntil?.durationType) {
      return t(
        `case:manualRiskLevelChange.validUntil.options.${manualOverrides.validUntil.durationType}.heading`,
      );
    }
    return '-';
  }

  if (manualRiskLevelChange) {
    return (
      <ManualRiskRatingSummary
        previousRiskLevel={manualOverrides?.previousRiskLevel || ''}
        manualRiskLevel={summary?.[metaDataKeys.finalRiskLevel] || ''}
        riskRatingScoreOnly={<RiskRatingScoreOnly />}
        activeTill={getActiveTill(manualOverrides)}
      />
    );
  }

  const categoryRows = [];

  riskScoreCategory.forEach((item, idx) => {
    const category = item.key;
    if (riskScoreCategoryDerivedData[category].catScoreItemsAvailable) {
      categoryRows.push(
        <Grid item key={idx} xs={12} className="border-bottom pb-3">
          <RiskRatingCategorySummary
            popoverMode={popoverMode}
            category={category}
            totalPoints={categoryMaxPoints[category]}
            subTotalPoints={riskScoreCategoryDerivedData[category].catTotalPoints}
            onClick={handleChange('detail', category)}
          />
        </Grid>,
      );
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mb-3">
        <RiskRatingSummary
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...summary}
          popoverMode={popoverMode}
          maxPoints={maxPoints}
          isIndividual={isIndividual}
        />
      </Grid>

      {categoryRows.length ? (
        categoryRows
      ) : (
        <EmptyState
          dense
          icon={<GppGoodIcon className="color-black-50 icon-75" />}
          heading={t('case:userPopover.riskRating.noFactors.heading')}
        />
      )}
    </Grid>
  );
};

export { RiskRatingDataSummaryView };
