import { SftpConfigActions } from 'modules/configuration/settings/sftp-config/middleware/action-types';

const sftpReducer = (state = {}, action) => {
  switch (action.type) {
    case SftpConfigActions.SFTP_GET_SUCCESS:
    case SftpConfigActions.SFTP_UPDATE_SUCCESS:
    case SftpConfigActions.SFTP_DELETE_SUCCESS:
    case SftpConfigActions.SFTP_GET_UNMASKED: {
      return {
        ...state,
        unmaskedId: null,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }
    case SftpConfigActions.SFTP_GET_UNMASKED_SUCCESS: {
      state.instances = state.instances?.map((obj) => {
        if (obj.uniqueId === action.payload.uniqueId) {
          return { ...obj, ...action.payload };
        }
        return obj;
      });
      return {
        ...state,
        unmaskedId: action.payload.uniqueId,
      };
    }

    default:
      return state;
  }
};

export default sftpReducer;
