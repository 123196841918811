import { CUSTOMER_RISK_RATING_CONFIG_ACTIONS } from 'middleware/action-types';

const customerRiskRatingConfigForOrganizationReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.data:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.error:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.delete.error: {
      return {
        ...state,
      };
    }

    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.success:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.success:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.success:
    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.deleteRiskFactor.success: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.delete.data: {
      return {};
    }

    default:
      return state;
  }
};

export default customerRiskRatingConfigForOrganizationReducer;
