import { BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/action-types';

const BlacklistInstantiableListConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.error:
    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch: {
      return state;
    }
    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.success:
    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.success:
    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send:
    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.error: {
      return state;
    }

    case BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default BlacklistInstantiableListConfigReducer;
