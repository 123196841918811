class BlacklistConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('BlackList Config API Communicator instance is required.');
    }
  }

  getBlacklistConfig() {
    return this.communicator.fetchConfig().then((json) => ({
      ...json,
      instances: json.instances.map((instance) => ({
        ...instance,
        isBlocking: instance.isBlocking === null ? false : instance.isBlocking,
        immediateDecision: instance.immediateDecision === null ? false : instance.immediateDecision,
      })),
    }));
  }

  updateBlacklistConfig(data) {
    return this.communicator.updateConfig(data);
  }

  deleteBlacklistConfig(id) {
    return this.communicator.deleteConfig(id);
  }
}

export default BlacklistConfigManager;
