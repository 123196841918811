import { Role } from 'modules/configuration/workflows-and-users/components/user-management/models/role-dto';
import { concatIfNonNull } from 'utils/helpers/string-util';

interface UserInfo {
  userId: string;
  displayName?: string;
  email: string;
}

interface UserInfoWithRoles {
  userInfo: UserInfo;
  roles: Role[];
  forFiltering?: {
    nameAndEmail: string;
    roleIds: string[];
  };
}

const populateDataForFiltering = (user: UserInfoWithRoles) => {
  user.forFiltering = {
    nameAndEmail: concatIfNonNull(user.userInfo.displayName, user.userInfo.email),
    roleIds: user.roles.map((role) => role.id),
  };
};

export { UserInfo, UserInfoWithRoles, populateDataForFiltering };
