import { useWhiteLabel } from 'hooks/use-white-label';
import React, { FC, Suspense } from 'react';
import { Loader } from 'components/loader';

interface AppLogoProps {
  className: string;
}

const AppLogo: FC<AppLogoProps> = ({ className }) => {
  const { applicationLogo } = useWhiteLabel();

  return (
    <Suspense fallback={<Loader />}>
      {applicationLogo ? (
        <img src={applicationLogo} alt="application logo" className={className} />
      ) : (
        ''
      )}
    </Suspense>
  );
};

export default AppLogo;
