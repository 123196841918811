import { MCC_LIST_ACTIONS } from 'middleware/action-types';

export const getMccFileList = (tenantId) => ({
  type: MCC_LIST_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getMccFileListSuccess = (payload) => ({
  type: MCC_LIST_ACTIONS.fileList.success,
  payload,
});

export const getMccFileListError = () => ({
  type: MCC_LIST_ACTIONS.fileList.error,
});

export const activateMccList = (version) => ({
  type: MCC_LIST_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateMccListSuccess = (payload) => ({
  type: MCC_LIST_ACTIONS.activateVersion.success,
  payload,
});

export const activateMccListError = () => ({
  type: MCC_LIST_ACTIONS.activateVersion.error,
});
