import { NETWORK_ANALYSIS_ACTION } from 'middleware/action-types';

export const getNetworkAnalysis = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.GET_NETWORK_ANALYSIS,
  payload,
});

export const getNetworkAnalysisSuccess = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.GET_NETWORK_ANALYSIS_SUCCESS,
  payload,
});

export const getNetworkAnalysisError = () => ({
  type: NETWORK_ANALYSIS_ACTION.GET_NETWORK_ANALYSIS_ERROR,
});

export const getPassthroughNetwork = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.GET_PASSTHROUGH_NETWORK,
  payload,
});

export const getPassthroughNetworkSuccess = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.GET_PASSTHROUGH_NETWORK_SUCCESS,
  payload,
});

export const getPassthroughNetworkError = () => ({
  type: NETWORK_ANALYSIS_ACTION.GET_PASSTHROUGH_NETWORK_ERROR,
});

export const getPassthroughV2Network = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.passthroughV2.get,
  payload,
});

export const getPassthroughV2NetworkSuccess = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.passthroughV2.success,
  payload,
});

export const getPassthroughV2NetworkError = () => ({
  type: NETWORK_ANALYSIS_ACTION.passthroughV2.error,
});
export const getPassthroughV3Network = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.passthroughV3.get,
  payload,
});

export const getPassthroughV3NetworkSuccess = (payload) => ({
  type: NETWORK_ANALYSIS_ACTION.passthroughV3.success,
  payload,
});

export const getPassthroughV3NetworkError = () => ({
  type: NETWORK_ANALYSIS_ACTION.passthroughV3.error,
});
