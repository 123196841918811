export const BLACKLIST_INSTANTIABLE_LIST_CONFIG_ACTIONS = {
  fileList: {
    fetch: 'GET_BLACKLIST_INSTANTIABLE_LIST_CONFIG',
    success: 'GET_BLACKLIST_INSTANTIABLE_LIST_CONFIG_SUCCESS',
    error: 'GET_BLACKLIST_INSTANTIABLE_LIST_CONFIG_ERROR',
  },
  update: {
    data: 'BLACKLIST_INSTANTIABLE_LIST_CONFIG_UPDATE',
    success: 'BLACKLIST_INSTANTIABLE_LIST_CONFIG_UPDATE_SUCCESS',
    error: 'BLACKLIST_INSTANTIABLE_LIST_CONFIG_UPDATE_ERROR',
  },
  delete: {
    data: 'DELETE_BLACKLIST_INSTANTIABLE_LIST_CONFIG',
    success: 'DELETE_BLACKLIST_INSTANTIABLE_LIST_CONFIG_SUCCESS',
    error: 'DELETE_BLACKLIST_INSTANTIABLE_LIST_CONFIG_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_BLACKLIST_INSTANTIABLE_LIST_CONFIG',
    success: 'ACTIVATE_BLACKLIST_INSTANTIABLE_LIST_CONFIG_SUCCESS',
    error: 'ACTIVATE_BLACKLIST_INSTANTIABLE_LIST_CONFIG_ERROR',
  },
};
