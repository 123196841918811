import { isNil, uniq } from 'lodash';
import { Operator } from 'components/filters/models/operator';

const reduceUsersToEmailUserIdMap = (
  map: Map<string, string[]>,
  user: Operator,
): Map<string, string[]> => {
  const userIdAsString = `${user.user_id}`;
  const mappedUser = map.get(user.email);
  if (mappedUser) {
    map.set(user.email, uniq([...mappedUser, userIdAsString]));
    return map;
  }
  map.set(user.email, [userIdAsString]);
  return map;
};

export const mapEmailsToUserIds = (usersList: Operator[], emails: string[]): string[] => {
  const emailUserIdsMap = usersList.reduce(
    (map, user) => reduceUsersToEmailUserIdMap(map, user),
    new Map<string, string[]>(),
  );

  return (
    emails
      .map((filterValue: string) => emailUserIdsMap.get(filterValue))
      ?.flatMap((userId) => userId ?? [])
      ?.filter((userId) => !isNil(userId)) ?? []
  );
};

const reduceUsersToUserIdEmailMap = (
  map: Map<string, string[]>,
  user: Operator,
): Map<string, string[]> => {
  const userIdAsString = `${user.user_id}`;
  const mappedUser = map.get(userIdAsString);
  if (mappedUser) {
    map.set(userIdAsString, [...mappedUser, user.email]);
    return map;
  }
  map.set(userIdAsString, [user.email]);
  return map;
};

export const mapUserIdsToEmails = (usersList: Operator[], userIds: string[]): string[] => {
  const userIdEmailMap = usersList.reduce(
    (map, user) => reduceUsersToUserIdEmailMap(map, user),
    new Map<string, string[]>(),
  );

  return uniq(
    userIds
      ?.map((filterValue: string) => userIdEmailMap.get(filterValue))
      ?.flatMap((email) => email ?? [])
      ?.filter((email) => !isNil(email)) ?? [],
  );
};
