export const CASE_STATISTICS_AND_HEALTH_ACTIONS = {
  caseStatisticAndHealth: {
    groupsCount: {
      data: 'DASHBOARD_GET_GROUPS_COUNT',
      success: 'DASHBOARD_GET_GROUPS_COUNT_SUCCESS',
    },
    groupsTypeCount: {
      data: 'DASHBOARD_GET_GROUPS_TYPE_COUNT',
      success: 'DASHBOARD_GET_GROUPS_TYPE_COUNT_SUCCESS',
    },
    groupsHealth: {
      data: 'DASHBOARD_GET_GROUPS_HEALTH',
      success: 'DASHBOARD_GET_GROUPS_HEALTH_SUCCESS',
    },
  },
};
