import ErrorCode from 'utils/constants/error-code';
import APICommunicator from 'services/api-communicator';
import SessionService from 'modules/authentication/login/services/session-service';
import { API_URLS, API_PARAMS } from 'utils/constants/api-config';
import { getCaseTenantId } from 'modules/trx-cases/case-detail/services/case/case-api-communicator';

const auth = new SessionService();

class TrxCaseDetailGroupingApi extends APICommunicator {
  postStopGrouping(caseId) {
    const apiUrl = API_URLS.caseService.groupCase.stopGrouping.replace(API_PARAMS.caseId, caseId);

    const caseTenantId = getCaseTenantId();
    return this.postJSONForCustomTenant(
      apiUrl,
      {},
      caseTenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default TrxCaseDetailGroupingApi;
