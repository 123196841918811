import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { EntityResolutionConfig } from 'modules/entity-resolution/models/entity-resolution';

class EntityResolutionAPI extends APICommunicator {
  getConfig() {
    const apiUrl = API_URLS.entityResolution.config.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId() as string,
    );

    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateConfig(params: EntityResolutionConfig) {
    const apiUrl = API_URLS.entityResolution.config.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId() as string,
    );

    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getEntityProfile(customerIdToken: string) {
    const apiUrl = API_URLS.entityResolution.entityProfile.fetch
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId() as string)
      .replace(API_PARAMS.customerIdToken, customerIdToken);

    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  deleteEntityProfile(entityId: string) {
    const apiUrl = API_URLS.entityResolution.entityProfile.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId() as string)
      .replace(API_PARAMS.entityId, entityId);

    return this.deleteJSONForTenant(apiUrl, null, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default EntityResolutionAPI;
