import { IconButton, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import { generatePath } from 'react-router';
import { APP_ROUTES } from 'utils/constants/routes';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import USER_PERMISSIONS from 'utils/constants/user-permissions';
import { useAuthorization } from 'hooks/use-authorization';
import { useTranslation } from 'react-i18next';
import { useCustomerProfileExists } from 'components/popover/person-details/hooks/use-customer-profile-exists';

interface CustomerProfileLinkProps {
  customerIdToken: string;
}

export const CustomerProfileLink = ({ customerIdToken }: CustomerProfileLinkProps) => {
  const { t } = useTranslation();
  const { detachmentConfig, userPermissions } = useAuthorization();
  const isCustomerViewEnabled = isFeatureAccessible(
    DETACHMENT_CONFIG.customerView,
    detachmentConfig,
    userPermissions,
    USER_PERMISSIONS.read.customerCaseList,
  );
  const { data, isLoading, isError, isSuccess } = useCustomerProfileExists(
    customerIdToken,
    isCustomerViewEnabled,
  );
  const customerProfileExists = data?.exists;
  const openInNew = () =>
    window.open(
      generatePath(APP_ROUTES.customerList.customerDetail.path, {
        tenantId: TenantManagementService.getActiveTenantId() ?? '',
        uuid: customerIdToken,
      }),
      '_blank',
    );
  if (!isCustomerViewEnabled) {
    return <></>;
  }
  return (
    <>
      {isSuccess && customerProfileExists && (
        <Tooltip title={t('customerProfileLink.heading')}>
          <IconButton data-testid="IconButton" size="small" onClick={() => openInNew()}>
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {(isLoading || isError || !customerProfileExists) && (
        <Tooltip title={t('customerProfileLink.notAvailable')}>
          <span>
            <IconButton data-testid="IconButton" size="small" disabled>
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};
