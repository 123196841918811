import { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Chip, Divider, FormHelperText, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import i18next from 'i18next';
import { BlockedChip } from 'components/chips/blocked-chip';
import { isStatusBlocked } from 'utils/helpers/cases/case-utility';

const buildTransactionStatusValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t('mapping:filters.filtering.transactionStatusesV2.key'),
  values: filter?.values,
});

const buildCustomerStatusValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t('mapping:filters.filtering.customerStatus.key'),
  values: filter?.values,
});

const TransactionStatusForm = ({ editForm, onSubmit, filterKey }) => {
  const { t } = useTranslation();

  const initialFValues = {};

  const transactionStatuses =
    filterKey === t('mapping:filters.filtering.customerStatus.key')
      ? Object.values(t('mapping:transactionStatusV2'))
      : Object.values(t('mapping:transactionStatus'));

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`transactionStatuses[${item}]`] = true;
    });
  }

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newStatuses = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newStatuses);
    // eslint-disable-next-line no-use-before-define
    validate(newStatuses);
  };

  const validate = (newCheckedStatuses = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    temp.transactionStatus = newCheckedStatuses.length ? '' : t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: filterKey,
        values: checkedValues,
      });
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
          {transactionStatuses.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Controls.Checkbox
                name={`transactionStatuses[${item.key}]`}
                label={isStatusBlocked(item.key) ? <BlockedChip /> : item.value.heading}
                value={values[`transactionStatuses[${item.key}]`] ?? false}
                onChange={(event) => handleSelectChange(event, item.key)}
                size="small"
              />
            </Grid>
          ))}
        </Grid>
        {errors.transactionStatus && (
          <FormHelperText className="pl-3" error>
            {errors.flow}
          </FormHelperText>
        )}
        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
        </Grid>
      </Form>
    </Grid>
  );
};

const TransactionStatusChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t(`mapping:filters.filtering.${form.field}.value.heading`)}
        </Box>
        {form.values.map((item, index) =>
          isStatusBlocked(item) ? (
            <BlockedChip key={index} />
          ) : (
            <Chip
              key={index}
              variant="outlined"
              size="small"
              className="mr-1"
              label={t([
                `mapping:transactionStatusV2.${item}.value.heading`,
                `mapping:transactionStatus.${item}.value.heading`,
              ])}
            />
          ),
        )}
      </>
    )}
  </Translation>
);

export {
  TransactionStatusForm,
  TransactionStatusChip,
  buildTransactionStatusValuesFromUrl,
  buildCustomerStatusValuesFromUrl,
};
