import { AccountBasedFrequencyConfigActions } from 'modules/configuration/rule-manager/account-based-frequency/middleware/action-types';

export const getAccountBasedFrequencyConfig = () => ({
  type: AccountBasedFrequencyConfigActions.GET,
});

export const getAccountBasedFrequencyConfigSuccess = (payload) => ({
  type: AccountBasedFrequencyConfigActions.GET_SUCCESS,
  payload,
});

export const getAccountBasedFrequencyConfigError = () => ({
  type: AccountBasedFrequencyConfigActions.GET_ERROR,
});

export const updateAccountBasedFrequencyConfig = (payload) => ({
  type: AccountBasedFrequencyConfigActions.UPDATE,
  payload,
});

export const updateAccountBasedFrequencyConfigSuccess = (payload) => ({
  type: AccountBasedFrequencyConfigActions.UPDATE_SUCCESS,
  payload,
});

export const updateAccountBasedFrequencyConfigError = () => ({
  type: AccountBasedFrequencyConfigActions.UPDATE_ERROR,
});

export const deleteAccountBasedFrequencyConfig = (payload) => ({
  type: AccountBasedFrequencyConfigActions.DELETE,
  payload,
});

export const deleteAccountBasedFrequencyConfigSuccess = (payload) => ({
  type: AccountBasedFrequencyConfigActions.DELETE_SUCCESS,
  payload,
});

export const deleteAccountBasedFrequencyConfigError = () => ({
  type: AccountBasedFrequencyConfigActions.DELETE_ERROR,
});
