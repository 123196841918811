import { LOCAL_STORAGE } from 'utils/constants/constant';
import { DEFAULT_LANGUAGE } from 'utils/constants/languages';
import { CustomView } from 'models/custom-view';

const DEFAULT_USE_BETA_VERSION = true;
const DEFAULT_USE_AUDIT_TRAIL_BETA_VERSION = false;

/* eslint class-methods-use-this: [0] */
class LocalStorageService {
  isMaskedDataShown() {
    return this.getCasesDataShownState() === 'true';
  }

  updateCaseDetailDataShown() {
    const currentState = this.getCaseDetailDataShownState();
    const newState = currentState !== 'true';
    localStorage.setItem(LOCAL_STORAGE.caseDetails.maskedDataShowState, String(newState));

    return newState;
  }

  getCaseDetailDataShownState() {
    return localStorage.getItem(LOCAL_STORAGE.caseDetails.maskedDataShowState);
  }

  setCaseDetailDataShownState(newState: boolean) {
    return localStorage.setItem(LOCAL_STORAGE.caseDetails.maskedDataShowState, String(newState));
  }

  updateCasesDataShown() {
    const currentState = this.getCasesDataShownState();
    const newState = currentState !== 'true';
    localStorage.setItem(LOCAL_STORAGE.caseList.companyDataShownState, String(newState));

    return newState;
  }

  getCasesDataShownState() {
    return localStorage.getItem(LOCAL_STORAGE.caseList.companyDataShownState);
  }

  resetAllMaskedDataShown() {
    localStorage.setItem(LOCAL_STORAGE.caseList.companyDataShownState, String(false));
    localStorage.setItem(LOCAL_STORAGE.caseDetails.maskedDataShowState, String(false));
  }

  getUseBetaGroupSearch(): boolean {
    return Boolean(
      localStorage.getItem(LOCAL_STORAGE.betaFeature.groupSearch) ?? DEFAULT_USE_BETA_VERSION,
    );
  }

  getUseBetaAuditTrail(): boolean {
    return Boolean(
      localStorage.getItem(LOCAL_STORAGE.betaFeature.auditTrail) ??
        DEFAULT_USE_AUDIT_TRAIL_BETA_VERSION,
    );
  }

  getUseBetaCustomerTransactionHistory(): boolean {
    return Boolean(
      localStorage.getItem(LOCAL_STORAGE.betaFeature.customerTransactionHistory) ??
        DEFAULT_USE_BETA_VERSION,
    );
  }

  setUseBetaGroupSearch(value: boolean) {
    localStorage.setItem(LOCAL_STORAGE.betaFeature.groupSearch, String(value));
  }

  setUseBetaCustomerTransactionHistory(value: boolean) {
    localStorage.setItem(LOCAL_STORAGE.betaFeature.customerTransactionHistory, String(value));
  }

  setUseBetaAuditTrail(value: boolean) {
    localStorage.setItem(LOCAL_STORAGE.betaFeature.auditTrail, String(value));
  }

  getPlatformVersion() {
    return {
      version: localStorage.getItem(LOCAL_STORAGE.platformVersion.current),
      timestamp: localStorage.getItem(LOCAL_STORAGE.platformVersion.timestamp),
    };
  }

  setPlatformVersion(version: string, timestamp: number) {
    localStorage.setItem(LOCAL_STORAGE.platformVersion.current, version);
    localStorage.setItem(LOCAL_STORAGE.platformVersion.timestamp, String(timestamp));
  }

  getLanguage(): { language: string } {
    return {
      language: localStorage.getItem(LOCAL_STORAGE.language.selectedLanguage) ?? DEFAULT_LANGUAGE,
    };
  }

  setLanguage(language: string) {
    localStorage.setItem(LOCAL_STORAGE.language.selectedLanguage, language);
  }

  static getTrxCustomViews(): CustomView[] {
    try {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE.customViews.trxCustomViews) ?? '');
    } catch (error) {
      return [];
    }
  }

  static addTrxCustomView(customView: CustomView) {
    const trxCustomViews = this.getTrxCustomViews();
    const newTrxCustomViews = [...trxCustomViews, customView];
    localStorage.setItem(
      LOCAL_STORAGE.customViews.trxCustomViews,
      JSON.stringify(newTrxCustomViews),
    );
    return customView;
  }

  static getTrxCaseListColumnConfig(): unknown {
    try {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE.trxCaseList.columnConfig) ?? '');
    } catch (error) {
      return undefined;
    }
  }

  static getCustomerCaseListColumnConfig(): unknown {
    try {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE.customerCaseList.columnConfig) ?? '');
    } catch (error) {
      return undefined;
    }
  }
}

export default LocalStorageService;
