import { useAuthentication } from 'modules/authentication/login/hooks/use-auth';
import { Loader } from 'components/loader';
import LoginViewV1Container from 'modules/authentication/login/view/login-view-v1-container';
import LoginViewV2Container from 'modules/authentication/login/view/login-view-v2-container';
import React, { Suspense, useEffect } from 'react';
import ResetPasswordView from 'modules/authentication/reset-password/views/reset-password-view';
import { useLocation } from 'react-router-dom';
import { lazyRetry } from 'utils/helpers/lazy-retry';
import { useWhiteLabel } from 'hooks/use-white-label';
import { setFavicon } from 'utils/helpers/utility';

const MainViewContainer = React.lazy(() =>
  lazyRetry(() => import('modules/layout/main-view/view/main-view-container'), 'MainViewContainer'),
);

const RouterSwitch = () => {
  const location = useLocation();
  const { isLoading, isAuthenticated } = useAuthentication();
  const { applicationFavicon } = useWhiteLabel();

  useEffect(() => {
    setFavicon(applicationFavicon as string);
  });

  if (location.pathname === '/caseManager/resetPassword') {
    return <ResetPasswordView />;
  }

  if (isLoading) return <Loader />;

  if (!isAuthenticated) {
    if (window.REACT_APP_LOGIN_VIEW === 'new') return <LoginViewV2Container />;
    return (
      <Suspense fallback={<Loader />}>
        <LoginViewV1Container />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <MainViewContainer />
    </Suspense>
  );
};

export default RouterSwitch;
