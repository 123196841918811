import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class RuleConfigAPICommunicator extends APICommunicator {
  constructor(requestMapping) {
    super();

    if (!requestMapping) {
      throw new Error('Param requestMapping is required.');
    }

    this.requestMapping = requestMapping;
  }

  baseUrl(tenantId) {
    return `${config.configurationUrl}/config/${this.requestMapping}/${
      tenantId ?? TenantManagementService.getActiveTenantId()
    }`;
  }

  fetchConfig(params) {
    const apiUrl = this.baseUrl(params?.tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateConfig(params) {
    const apiUrl = this.baseUrl();
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  deleteConfig(uniqueId) {
    const apiUrl = `${this.baseUrl()}?uniqueId=${uniqueId}`;
    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }
}

export default RuleConfigAPICommunicator;
