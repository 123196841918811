export const AccountBasedFrequencyConfigActions = {
  GET: 'ACCOUNT_BASED_FREQUENCY_CONFIG_GET',
  GET_SUCCESS: 'ACCOUNT_BASED_FREQUENCY_CONFIG_GET_SUCCESS',
  GET_ERROR: 'ACCOUNT_BASED_FREQUENCY_CONFIG_GET_ERROR',
  UPDATE: 'ACCOUNT_BASED_FREQUENCY_CONFIG_UPDATE',
  UPDATE_SUCCESS: 'ACCOUNT_BASED_FREQUENCY_CONFIG_UPDATE_SUCCESS',
  UPDATE_ERROR: 'ACCOUNT_BASED_FREQUENCY_CONFIG_UPDATE_ERROR',
  DELETE: 'ACCOUNT_BASED_FREQUENCY_CONFIG_DELETE',
  DELETE_SUCCESS: 'ACCOUNT_BASED_FREQUENCY_CONFIG_DELETE_SUCCESS',
  DELETE_ERROR: 'ACCOUNT_BASED_FREQUENCY_CONFIG_DELETE_ERROR',
};
