import { isEmpty } from 'lodash';
import { RootState } from 'middleware/store';
import { DetachmentConfig } from 'models/authorization/detachment';
import { UserPermission } from 'models/authorization/permissions';
import { shallowEqual, useSelector } from 'react-redux';
import { FEATURE_STATUS } from 'utils/constants/constant';

export const useAuthorization = () => {
  const userPermissions: Array<UserPermission> = useSelector(
    (state: RootState) => state.authentication?.userPermissions || [],
    shallowEqual,
  );

  const detachmentConfig: DetachmentConfig = useSelector(
    (state: RootState) => state.authentication?.detachmentConfig || {},
    shallowEqual,
  );

  const isFeatureAttached = (configItem: string) => {
    if (isEmpty(detachmentConfig)) return false;
    return detachmentConfig[configItem] === FEATURE_STATUS.attached;
  };

  return { userPermissions, detachmentConfig, isFeatureAttached };
};
