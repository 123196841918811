import { NotificationActions } from 'middleware/action-types';

export const showNotification = (notification) => ({
  type: NotificationActions.NOTIFICATIONS_SHOW,
  payload: notification,
});

export const hideNotification = (notification) => ({
  type: NotificationActions.NOTIFICATIONS_HIDE,
  payload: notification,
});

export const hideAllNotifications = () => ({
  type: NotificationActions.NOTIFICATIONS_HIDE_ALL,
});

export const updateNotificationShowTime = (notification) => ({
  type: NotificationActions.UPDATE_NOTIFICATION_SHOW_TIME,
  payload: notification,
});
