import { VolumeRiskConfigActions } from 'middleware/action-types';

export const getVolumeRiskConfig = () => ({
  type: VolumeRiskConfigActions.VOLUME_RISK_CONFIG_GET,
});

export const getVolumeRiskConfigSuccess = (payload) => ({
  type: VolumeRiskConfigActions.VOLUME_RISK_CONFIG_GET_SUCCESS,
  payload,
});

export const getVolumeRiskConfigError = () => ({
  type: VolumeRiskConfigActions.VOLUME_RISK_CONFIG_GET_ERROR,
});
