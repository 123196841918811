import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CrrCasesResponse,
  Customer,
  EsCrrCase,
  MoreCasesArgs,
  TrxCasesResponse,
  Case,
  GetTransactionsArgs,
  GetCustomerCasesArgs,
  CustomerNote,
} from 'modules/customer-data/types';
import { deepCopy } from 'utils/helpers/utility';

interface State {
  customer?: Customer;
  crrCases: {
    cases: EsCrrCase[];
    totalCases?: number;
    paging?: {
      scrollId: string;
      lastPage: boolean;
    };
  };
  trxCases: {
    cases: Case[];
    totalCases?: number;
    paging?: {
      scrollId: string;
      lastPage: boolean;
    };
  };
  customerNotes: CustomerNote[];
}

const initialState: State = {
  customer: undefined,
  crrCases: { cases: [], totalCases: undefined, paging: undefined },
  trxCases: { cases: [], totalCases: undefined, paging: undefined },
  customerNotes: [],
};

export const customerDetailSlice = createSlice({
  name: 'customerDetail',
  initialState,
  reducers: {
    getCustomerDetail: (state, _action: PayloadAction<string>) => ({
      ...state,
      customer: undefined,
    }),
    getCustomerDetailSuccess: (state, action: PayloadAction<Customer>) => ({
      ...state,
      customer: deepCopy(action.payload),
    }),

    resetSelectedCustomerDetail: () => ({ ...initialState }),

    getAllCustomerCases: (state, _action: PayloadAction<GetCustomerCasesArgs>) => ({
      ...state,
      crrCases: { cases: [] },
    }),
    getAllCustomerCasesSuccess: (state, action: PayloadAction<CrrCasesResponse>) => {
      const cases = [...state.crrCases.cases, ...action.payload.cases];
      return {
        ...state,
        crrCases: {
          cases,
          totalCases: action.payload.totalHits,
          paging: {
            scrollId: action.payload.scrollId,
            lastPage: action.payload.cases.length === 0,
          },
        },
      };
    },
    getMoreCustomerCases: (state, _action: PayloadAction<MoreCasesArgs>) => ({ ...state }),

    getAllTransactionCases: (state, _action: PayloadAction<GetTransactionsArgs>) => ({
      ...state,
      trxCases: { cases: [] },
    }),

    getAllTransactionCasesSuccess: (state, action: PayloadAction<TrxCasesResponse>) => {
      const cases = [...state.trxCases.cases, ...action.payload.cases];
      return {
        ...state,
        trxCases: {
          cases,
          totalCases: action.payload.totalHits,
          paging: {
            scrollId: action.payload.scrollId,
            lastPage: action.payload.cases.length === 0,
          },
        },
      };
    },
    getMoreTransactionCases: (state, _action: PayloadAction<MoreCasesArgs>) => ({ ...state }),
  },
});

export const {
  getCustomerDetail,
  getCustomerDetailSuccess,
  getAllCustomerCases,
  getAllCustomerCasesSuccess,
  resetSelectedCustomerDetail,
  getMoreCustomerCases,
  getAllTransactionCases,
  getAllTransactionCasesSuccess,
  getMoreTransactionCases,
} = customerDetailSlice.actions;

export default customerDetailSlice.reducer;
