import { Filter } from 'components/filters/models/filter';
import i18next from 'i18next';

export const buildAdditionalIdValuesFromUrl = (filter: Filter) => ({
  field: i18next.t('mapping:filters.filtering.additionalIds.key'),
  condition: i18next.t(`mapping:operator.text.${filter.condition}.key`),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  key: filter.key,
  values: filter.values,
});
