import { NotificationActions } from 'middleware/action-types';

const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case NotificationActions.NOTIFICATIONS_SHOW: {
      const notifications = state.notifications || [];
      const notification = action.payload || {};
      notification.hideAfterMilliseconds = 8;
      notification.createdDate = new Date();
      notification.id = Date.now();

      notifications.push(notification);

      if (notifications.length > 3) {
        const limit = notifications.length - 4;
        for (let i = notifications.length - 1; i >= 0; i--) {
          if (i <= limit && notifications[i].hideAfterMilliseconds > 2) {
            notifications[i].hideAfterMilliseconds = 2;
          }
        }
      }

      return {
        ...state,
        notifications: [...notifications],
      };
    }

    case NotificationActions.NOTIFICATIONS_HIDE: {
      const notifications = state.notifications || [];
      const idx = notifications.indexOf(action.payload);

      return {
        ...state,
        notifications: [...notifications.slice(0, idx), ...notifications.slice(idx + 1)],
      };
    }

    case NotificationActions.NOTIFICATIONS_HIDE_ALL: {
      return {
        ...state,
        notifications: [],
      };
    }

    case NotificationActions.UPDATE_NOTIFICATION_SHOW_TIME: {
      const notifications = state.notifications || [];
      const idx = notifications.indexOf(action.payload);
      notifications[idx].hideAfterMilliseconds -= 1;

      return {
        ...state,
        notifications: [...notifications],
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
