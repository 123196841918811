const VolumeConstants = {
  TEMP_ID_TOKEN: '_temp_',
  NULL_ID_TOKEN: '',
  TIME_FRAME_FIELD: 'timeFrame',
  TRANSACTION_TYPE_FIELD: 'transactionType',
  HIGH_RISK_THRESHOLD_FIELD: 'highRiskThreshold',
  MEDIUM_RISK_THRESHOLD_FIELD: 'mediumRiskThreshold',
  LOW_RISK_THRESHOLD_FIELD: 'lowRiskThreshold',
  DEFAULT_PAYMENT_CHANNEL: 'IBAN',
  COMMON_CONFIG_CRITERIA: 'commonConfigCriteria',
};

export default VolumeConstants;
