/* eslint-disable react/destructuring-assignment */
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BlockedChip } from 'components/chips/blocked-chip';
import { CustomerChip } from 'components/chips/customer-chip';
import { RiskChip } from 'components/chips/risk-chip';
import { UnblockedChip } from 'components/chips/unblocked-chip';
import FormattedDateTime from 'components/formatted-date-time';
import { ConditionalTooltip } from 'components/tooltip/tooltip';
import {
  getCaseTypeImage,
  getFlowImage,
  reasonImages,
} from 'modules/trx-cases/case-detail/utils/images';
import { useTranslation } from 'react-i18next';
import { META_DATA } from 'utils/constants/metadata.constants';
import TRANSACTION_STATUS from 'utils/constants/transaction-status';
import { getFirstTransactionFromCase } from 'utils/helpers/cases/case-utility';
import Strings from 'utils/helpers/locales/strings.en';
import { formatMoney } from 'utils/helpers/math-util';

/**
 * FILE DEPRECATED
 * Use case-table-cells for the new api in elastic search
 * old api use this file
 */

function buildItemImageCell(itemImage, item, idx, addedCss) {
  if (itemImage) {
    return (
      <ConditionalTooltip title={itemImage.tooltip}>
        <img key={idx} src={itemImage.image} alt={itemImage.tooltip} className={addedCss} />
      </ConditionalTooltip>
    );
  }
  return <span key={idx}>{item}</span>;
}

function buildItemImagesCell(items, itemImages, addedCss) {
  return items.map((item, idx) => {
    const itemImage = itemImages.find((itemImage) => itemImage.key === item);
    return buildItemImageCell(itemImage, item, idx, addedCss);
  });
}

const ReasonsCell = (props) => {
  const item = props.item || {};
  const { reasons } = item;
  const itemReasons = buildItemImagesCell(reasons, reasonImages);
  return <div>{itemReasons}</div>;
};

const PriorityCell = (props) => {
  const item = props.item || {};
  const priorityStyle = item.priority === 'High' ? 'high-priority-text' : 'normal-priority-text';
  return <div className={priorityStyle}>{item.priority}</div>;
};

const CaseStatusCell = (props) => {
  const item = props.item || {};

  return <span>{Strings.CaseStatus[item.caseStatus]}</span>;
};

const ProcessingStatusCell = ({ item = {} }) => {
  const { t } = useTranslation();

  return (
    <span className="color-on-surface">
      {t([
        `mapping:processingStatusV2.${item?.processingStatusV2?.toLowerCase()}.value.heading`,
        `mapping:processingStatus.${item?.processingStatus}.value.heading`,
        '',
      ])}
    </span>
  );
};

const InvestigationStatusCell = ({ caseDetail }) => {
  const { t } = useTranslation();
  let status = caseDetail?.investigationStatus?.name;
  // For old case, investigationStatus is empty / opening,
  // it should be set to processingStatusV2 (couple)
  if (!status || status === t(`mapping:investigationStatus.opening.key`)) {
    status = caseDetail?.processingStatusV2 ?? caseDetail?.processingStatus?.toLowerCase();
  }
  return (
    <span className="color-on-surface">
      {status ? t(`mapping:investigationStatus.${status}.value.heading`) : '-'}
    </span>
  );
};

const InvestigationOutcomeCell = ({ caseDetail }) => {
  const { t } = useTranslation();
  const investigationOutcome = caseDetail?.assessment?.fraudAssessment?.operatorAnswer;

  return (
    <span>
      {!investigationOutcome || investigationOutcome.toLowerCase() === 'none'
        ? '-'
        : t(`mapping:investigationOutcome.${investigationOutcome}.value.heading`)}
    </span>
  );
};

const TransactionStatusCell = ({ item }) => {
  switch (item?.transactionStatus) {
    case TRANSACTION_STATUS.blocked:
      return <BlockedChip />;
    case TRANSACTION_STATUS.unblocked:
      return <UnblockedChip />;
    default:
      return Strings.TransactionStatus[item?.transactionStatus];
  }
};

const TransactionRiskLevelCell = ({ item }) => {
  const { t } = useTranslation();
  const transaction = getFirstTransactionFromCase(item);
  const label = transaction.riskLevel ? t(`riskCategory.${transaction.riskLevel}.heading`) : '-';

  return (
    <span style={{ minWidth: 55 }}>
      <RiskChip size="small" risk={transaction.riskLevel} label={label} />
    </span>
  );
};

const TimestampCell = ({ item }) => {
  const updatedAt = item?.updatedAt;
  let transactionDate;
  if (updatedAt && updatedAt.length > 0) {
    transactionDate = new Date(updatedAt);
  }
  return (
    <span>
      <FormattedDateTime date={transactionDate} withTime />
    </span>
  );
};

const TimestampCreatedAtCell = ({ item }) => {
  const createdAt = item?.createdAt;
  let transactionDate;
  if (createdAt && createdAt.length > 0) {
    transactionDate = new Date(createdAt);
  }
  return (
    <span>
      <FormattedDateTime date={transactionDate} withTime />
    </span>
  );
};

const CustomerCell = ({ item }) => {
  const transaction = getFirstTransactionFromCase(item);
  const metadatas = item?.metadataComputedEvent?.metadatas ?? [];
  const customerRiskRatingData = metadatas.find(
    (mt) => mt.key === META_DATA.customerRiskRating.key,
  )?.values;
  const { tenantId, configVersion } = item;
  const caseConfig = { tenantId, configVersion };

  return (
    <>
      <CustomerChip
        uuid={item?.uuid}
        transaction={transaction}
        forwardingCaseIds={item.forwardingCaseIds}
        customerName={item.customer}
        customerRiskRatingData={customerRiskRatingData}
        customerCaseData={item}
        caseConfig={caseConfig}
      />
    </>
  );
};

const TransactionCountCell = (props) => {
  const txCount = props.txCount || 0;
  return <span>{txCount}</span>;
};

const TotalFlowAmountCell = ({ totalAmount }) => {
  const formattedTotalAmount = totalAmount != null ? formatMoney(totalAmount) : null;
  return <span>{formattedTotalAmount}</span>;
};

const TotalFlowCurrencyCell = ({ currency }) => {
  const currencyCode = currency && currency.length >= 3 ? currency.substring(0, 3) : currency;
  return <span>{currencyCode}</span>;
};

const TotalAmountCell = (props) => {
  const item = props.item || {};

  let totalAmount = 0;
  let currency = '';
  if (typeof item.totalAmount === 'object') {
    totalAmount = item.totalAmount.value;
    currency = item.totalAmount.currency;
  } else {
    totalAmount = item.totalAmount;
    currency = item.currency;
  }

  const formattedTotalAmount = totalAmount != null ? formatMoney(totalAmount) : null;

  const currencyCode = currency && currency.length >= 3 ? currency.substring(0, 3) : currency;
  return (
    <Typography variant="body2">
      <Typography component="span" variant="caption" color="textPrimary">
        {currencyCode}
      </Typography>{' '}
      {formattedTotalAmount}
    </Typography>
  );
};

const SettledFundsAmountCell = (props) => {
  const item = props.item || {};
  const transaction = getFirstTransactionFromCase(item);
  const settledFunds = transaction.settledFunds || {};
  return (
    <span>
      {settledFunds.currency} {formatMoney(settledFunds.value)}
    </span>
  );
};

const FlowCell = (props) => {
  const item = props.item || {};
  const flowImage = getFlowImage(item.flow);
  const itemFlows = buildItemImageCell(flowImage, '', 0, props.customClass);
  return <span style={{ minWidth: 55, position: 'relative' }}>{itemFlows}</span>;
};

const AssignToUserCell = (props) => {
  const item = props.item || {};
  const assignedToUser = item.assignedToUserDisplayName;
  return (
    <span>
      {assignedToUser !== undefined ? (
        assignedToUser
      ) : (
        <span className="font-italic color-on-surface-disabled">
          {Strings.CaseTable.Unassigned}
        </span>
      )}
    </span>
  );
};

/**
 * mapping image to cell
 * @param {*} types
 * @returns
 */
function buildTypeImagesCell(types, customClass, testIdPrefix) {
  return types.map((item, idx) => {
    const riskIcon = getCaseTypeImage(item.name);
    return (
      <Tooltip key={idx} title={item.description}>
        <img
          key={`_t${idx}`}
          src={riskIcon.image}
          data-testid={`${testIdPrefix}-icon-${item.name}`}
          alt=""
          className={customClass || 'type-icon'}
        />
      </Tooltip>
    );
  });
}

const TypeCell = (props) => {
  const item = props.item || {};
  const types = (item.types || []).map((t) => ({
    name: t.name,
    description: Strings.SanctionHitTypes[t.name],
  }));

  return (
    <span className="caseTypes">
      {types.length > 0 ? (
        buildTypeImagesCell(types, props.customClass, props.testIdPrefix)
      ) : (
        <span className="fas fa-minus" />
      )}
    </span>
  );
};

export {
  AssignToUserCell,
  CaseStatusCell,
  CustomerCell,
  FlowCell,
  InvestigationOutcomeCell,
  InvestigationStatusCell,
  PriorityCell,
  ProcessingStatusCell,
  ReasonsCell,
  SettledFundsAmountCell,
  TimestampCell,
  TimestampCreatedAtCell,
  TotalAmountCell,
  TotalFlowAmountCell,
  TotalFlowCurrencyCell,
  TransactionCountCell,
  TransactionRiskLevelCell,
  TransactionStatusCell,
  TypeCell,
};
