import { put, takeLatest } from 'redux-saga/effects';
import { CrossBorderVolumeConfigActions } from 'modules/configuration/rule-manager/cross-border-volume/middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  deleteCrossBorderVolumeConfigError,
  deleteCrossBorderVolumeConfigSuccess,
  getCrossBorderVolumeConfigError,
  getCrossBorderVolumeConfigSuccess,
  updateCrossBorderVolumeConfigError,
  updateCrossBorderVolumeConfigSuccess,
} from 'modules/configuration/rule-manager/cross-border-volume/middleware/actions/cross-border-volume-config';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';

const communicator = new RuleConfigAPICommunicator('cross-border-volume-increase');
const manager = new RuleConfigManager(communicator);

function* getCrossBorderVolumeConfigBegin(action) {
  yield put(startProcessing(CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getCrossBorderVolumeConfigSuccess(json));
  } catch (error) {
    yield put(getCrossBorderVolumeConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_GET));
}

export function* getCrossBorderVolumeConfigWatcher() {
  yield takeLatest(
    CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_GET,
    getCrossBorderVolumeConfigBegin,
  );
}

function* updateCrossBorderVolumeConfigBegin(action) {
  yield put(startProcessing(CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateCrossBorderVolumeConfigSuccess(json));
  } catch (error) {
    yield put(updateCrossBorderVolumeConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_UPDATE));
}

export function* updateCrossBorderVolumeConfigWatcher() {
  yield takeLatest(
    CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_UPDATE,
    updateCrossBorderVolumeConfigBegin,
  );
}

function* deleteCrossBorderVolumeConfigBegin(action) {
  yield put(startProcessing(CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteCrossBorderVolumeConfigSuccess(json));
  } catch (error) {
    yield put(deleteCrossBorderVolumeConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_DELETE));
}

export function* deleteCrossBorderVolumeConfigWatcher() {
  yield takeLatest(
    CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_DELETE,
    deleteCrossBorderVolumeConfigBegin,
  );
}
