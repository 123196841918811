import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ScreeningListSearchRequest } from 'modules/configuration/list-manager/data-provider-list/models/screening-list-search-request';
import { ReferenceListModel } from 'modules/configuration/list-manager/data-provider-list/models/reference-list-model';
import { ScreeningListAttributesRequest } from 'modules/configuration/list-manager/data-provider-list/models/screening-list-attributes-request';

interface DataProviderListPaging {
  searchTerm?: string;
  jurisdiction?: string;
  listType?: string;
  latestPage: number;
  hasMore: boolean;
}

interface DataProviderListConfigState {
  instances: Array<any>;
  updatedAt?: string;
  userId?: string;
  screeningLists: Array<ReferenceListModel>;
  jurisdictionCategories?: string[];
  listTypeCategories?: string[];
  paging?: DataProviderListPaging;
}

const initialState: DataProviderListConfigState = {
  instances: [],
  screeningLists: [],
};

export const dataProviderListConfigSlice = createSlice({
  name: 'dataProviderListConfig',
  initialState,
  reducers: {
    getDataProviderListConfig: (state) => state,
    getDataProviderListConfigSuccess: (
      state,
      action: PayloadAction<DataProviderListConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    saveDataProviderListConfig: (state, _action: PayloadAction<any>) => state,
    saveDataProviderListConfigSuccess: (
      state,
      action: PayloadAction<DataProviderListConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    deleteDataProviderListConfig: (state, _action: PayloadAction<string>) => state,
    deleteDataProviderListConfigSuccess: (
      state,
      action: PayloadAction<DataProviderListConfigState>,
    ) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    // This part below is handled in screening-list-saga.
    getScreeningList: (state, _action: PayloadAction<ScreeningListSearchRequest>) => state,
    getScreeningListSuccess: (state, action: PayloadAction<DataProviderListConfigState>) => {
      const latestPage = action.payload.paging?.latestPage || 1;
      const jurisdiction = action.payload.paging?.jurisdiction || undefined;
      const listType = action.payload.paging?.listType || undefined;
      const hasMore = action.payload.paging?.hasMore;

      // If page more-than-1 is fetched, we combine the current list with the newly fetched list.
      const updatedList =
        latestPage === 1
          ? action.payload.screeningLists
          : [...state.screeningLists, ...action.payload.screeningLists];
      const newState = {
        ...state,
        screeningLists: updatedList,
        paging: {
          latestPage,
          hasMore: hasMore !== undefined ? hasMore : true,
          searchTerm: action.payload.paging?.searchTerm || undefined,
          jurisdiction,
          listType,
        },
      };
      return newState;
    },
    getScreeningListAttributes: (state, _action: PayloadAction<ScreeningListAttributesRequest>) =>
      state,
    getScreeningListAttributesSuccess: (
      state,
      action: PayloadAction<DataProviderListConfigState>,
    ) => {
      const { listTypeCategories, jurisdictionCategories } = action.payload;
      listTypeCategories?.sort();
      jurisdictionCategories?.sort();
      const newState = {
        ...state,
        listTypeCategories,
        jurisdictionCategories,
      };
      return newState;
    },
  },
});

export const {
  getDataProviderListConfig,
  getDataProviderListConfigSuccess,
  saveDataProviderListConfig,
  saveDataProviderListConfigSuccess,
  deleteDataProviderListConfig,
  deleteDataProviderListConfigSuccess,

  getScreeningList,
  getScreeningListSuccess,
  getScreeningListAttributes,
  getScreeningListAttributesSuccess,
} = dataProviderListConfigSlice.actions;

export default dataProviderListConfigSlice.reducer;
