import { RULE_CATEGORY_ACTIONS } from 'middleware/action-types';

const ruleCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case RULE_CATEGORY_ACTIONS.fetch.success:
      return {
        ...state,
        attached: action.payload,
      };

    default:
      return state;
  }
};

export default ruleCategoryReducer;
