import { CrossBorderVolumeConfigActions } from 'modules/configuration/rule-manager/cross-border-volume/middleware/action-types';

export const getCrossBorderVolumeConfig = () => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_GET,
});

export const getCrossBorderVolumeConfigSuccess = (payload) => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_GET_SUCCESS,
  payload,
});

export const getCrossBorderVolumeConfigError = () => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_GET_ERROR,
});

export const updateCrossBorderVolumeConfig = (payload) => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_UPDATE,
  payload,
});

export const updateCrossBorderVolumeConfigSuccess = (payload) => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updateCrossBorderVolumeConfigError = () => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_UPDATE_ERROR,
});

export const deleteCrossBorderVolumeConfig = (payload) => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_DELETE,
  payload,
});

export const deleteCrossBorderVolumeConfigSuccess = (payload) => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_DELETE_SUCCESS,
  payload,
});

export const deleteCrossBorderVolumeConfigError = () => ({
  type: CrossBorderVolumeConfigActions.CROSS_BORDER_VOLUME_CONFIG_DELETE_ERROR,
});
