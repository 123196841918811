import { put, takeLatest } from 'redux-saga/effects';
import {
  getAllAuditTrailsError,
  getAllAuditTrailsSuccess,
} from 'modules/audit-trail/middleware/actions/audit-trail-actions';
import { AUDIT_TRAIL_ACTIONS } from 'modules/audit-trail/middleware/action-types';
import AuditTrailApiCommunicator from 'modules/audit-trail/services/audit-trail-api-communicator';
import AudiTrailManager from 'modules/audit-trail/services/audit-trail-manager';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { showError } from 'middleware/actions/error';

const auditTrailCommunicator = new AuditTrailApiCommunicator();
const auditTrailManager = new AudiTrailManager(auditTrailCommunicator);

function* getAllAuditTrailsBegin(action) {
  yield put(startProcessing(AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.data));
  try {
    const { paging, sorting, filtering, customHeader, tenantId } = action.payload;

    const json = yield auditTrailManager.searchAuditTrails(
      paging,
      sorting,
      filtering,
      customHeader,
      tenantId,
    );

    yield put(getAllAuditTrailsSuccess(json, paging.pageSize));
  } catch (error) {
    yield put(getAllAuditTrailsError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.data));
}

export function* getAllAuditTrailsWatcher() {
  yield takeLatest(AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.data, getAllAuditTrailsBegin);
}
