import { MASKED_ACTIONS } from 'middleware/action-types';

export const unmask = (payload) => ({
  type: MASKED_ACTIONS.unmask.data,
  payload,
});

export const unmaskSuccess = (unmasked) => ({
  type: MASKED_ACTIONS.unmask.success,
  payload: unmasked,
});

export const unmaskCasesSuccess = (uuids) => ({
  type: MASKED_ACTIONS.unmaskCases.success,
  payload: uuids,
});

export const unmaskError = () => ({
  type: MASKED_ACTIONS.unmask.error,
});

export const updateMasks = (payload) => ({
  type: MASKED_ACTIONS.unmask.update,
  payload,
});

export const unveilSanctionHitNames = (payload) => ({
  type: MASKED_ACTIONS.unveilSanctionHitName.data,
  payload,
});

export const unveilSanctionHitNamesSuccess = (payload) => ({
  type: MASKED_ACTIONS.unveilSanctionHitName.success,
  payload,
});

export const unveilSanctionHitNamesError = () => ({
  type: MASKED_ACTIONS.unveilSanctionHitName.error,
});

export const unveilCounterpartyData = (payload) => ({
  type: MASKED_ACTIONS.unveilCounterPartyData.data,
  payload,
});

export const unveilCounterpartyDataSuccess = (payload) => ({
  type: MASKED_ACTIONS.unveilCounterPartyData.success,
  payload,
});

export const unveilCounterpartyDataError = () => ({
  type: MASKED_ACTIONS.unveilCounterPartyData.error,
});

export const unveilMasterData = (uuid) => ({
  type: MASKED_ACTIONS.unveilMasterData.data,
  uuid,
});

export const unveilMasterDataSuccess = (payload) => ({
  type: MASKED_ACTIONS.unveilMasterData.success,
  payload,
});

export const unveilMasterDataError = () => ({
  type: MASKED_ACTIONS.unveilMasterData.error,
});

export const updateCasesDataShown = (payload) => ({
  type: MASKED_ACTIONS.dataShowState.cases,
  payload,
});

export const updateCaseDetailDataShown = (payload) => ({
  type: MASKED_ACTIONS.dataShowState.caseDetail,
  payload,
});
