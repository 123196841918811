import React from 'react';
import {
  Filter,
  FilterChipProps,
  FilterFormErrors,
  FilterFormProps,
  FormValues,
  NestedFilter,
} from 'components/filters/models/filter';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Divider, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import i18next from 'i18next';

interface TextFormProps extends FilterFormProps {
  fieldName: string;
  filterCreator: (value: string[]) => NestedFilter | Filter;
  filterToValueConverter: (value: NestedFilter | Filter) => string[];
  info?: React.ReactNode;
}

const TextForm = ({
  fieldName,
  editForm,
  onSubmit,
  filterCreator,
  filterToValueConverter,
  info,
}: TextFormProps) => {
  const { t } = useTranslation();

  const initialFValues: FormValues = {};
  initialFValues[`${fieldName}`] = filterToValueConverter(editForm);

  // eslint-disable-next-line no-use-before-define
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp: FilterFormErrors = { ...errors };
    if (`${fieldName}` in fieldValues) {
      temp.values = fieldValues[`${fieldName}`]?.length ? '' : i18next.t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({ ...temp });

    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event: UIEvent) => {
    event.preventDefault();
    if (validate()) {
      const storedValues = values[`${fieldName}`];
      const filter = filterCreator(storedValues);
      onSubmit(filter);
    }
  };

  return (
    <Grid container>
      <Form className="w-100">
        <Grid container item className="px-3 py-3" spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <Controls.MultipleInput
                name={fieldName}
                dataTestIdPrefix={`text-${fieldName}-input`}
                label={t('condition.equalOrContain.heading')}
                value={values[`${fieldName}`] || []}
                onChange={handleInputChange}
                splitValueByCommaEnabled={false}
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                error={(errors as any)[`${fieldName}`]}
                shrink={undefined}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        {info}
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            onClick={handleSubmit}
            type="submit"
            color="primary"
            text={t('filter.heading')}
          />
        </Grid>
      </Form>
    </Grid>
  );
};

interface TextChipProps extends FilterChipProps {
  fieldName: string;
  filterToValueConverter: (value: NestedFilter | Filter) => string[];
}

const TextChip = ({ fieldName, form, filterToValueConverter }: TextChipProps) => {
  const { t } = useTranslation();

  const values = filterToValueConverter(form);

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t(`mapping:filters.filtering.${fieldName}.value.heading`)}
      </Box>
      {values.map((item, index) => (
        <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
      ))}
    </>
  );
};

export { TextForm, TextChip };
