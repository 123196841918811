import { SETTINGS_ACTIONS } from 'middleware/action-types';

export const getSettings = (payload) => ({
  type: SETTINGS_ACTIONS.get.data,
  payload,
});

export const getSettingsSuccess = (payload) => ({
  type: SETTINGS_ACTIONS.get.success,
  payload,
});

export const getSettingsError = () => ({
  type: SETTINGS_ACTIONS.get.error,
});

export const updateSettings = (payload) => ({
  type: SETTINGS_ACTIONS.update.data,
  payload,
});

export const updateSettingsSuccess = (payload) => ({
  type: SETTINGS_ACTIONS.update.success,
  payload,
});

export const updateSettingsError = () => ({
  type: SETTINGS_ACTIONS.update.error,
});

export const updateSettingsCountryRisks = (payload) => ({
  type: SETTINGS_ACTIONS.countryRisks.update.data,
  payload,
});

export const updateSettingsCountryRisksSuccess = (payload) => ({
  type: SETTINGS_ACTIONS.countryRisks.update.success,
  payload,
});

export const updateSettingsCountryRisksError = () => ({
  type: SETTINGS_ACTIONS.countryRisks.update.error,
});

export const deleteSettingsCountryRisks = (payload) => ({
  type: SETTINGS_ACTIONS.countryRisks.delete.data,
  payload,
});

export const deleteSettingsCountryRisksSuccess = (payload) => ({
  type: SETTINGS_ACTIONS.countryRisks.delete.success,
  payload,
});

export const deleteSettingsCountryRisksError = (payload) => ({
  type: SETTINGS_ACTIONS.countryRisks.delete.error,
  payload,
});
