import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RISK_CATEGORY } from 'utils/constants/constant';
import { ConditionalTooltip } from 'components/tooltip/tooltip';

const getWheelProp = (size) => {
  switch (size) {
    case 'large':
      return { width: 72, thickness: 3.3 };
    case 'medium':
      return { width: 55, thickness: 3 };
    default:
      return { width: 40, thickness: 2 };
  }
};

const WheelPoints = ({
  points = 0,
  maxPoints = 1,
  size = 'small',
  tooltip,
  subheading,
  risk,
  suffix,
  notCalculated = false,
}) => {
  const { width, thickness } = getWheelProp(size);

  const halfWidth = width / 2;

  const progress = maxPoints === 0 ? 0 : (points * 100) / maxPoints;

  const theme = useTheme();
  const style = theme.custom?.wheelPointComponent[size];

  let riskCustom = risk?.toLowerCase();
  riskCustom = riskCustom?.replace(RISK_CATEGORY.default, '');
  const riskStyle = riskCustom
    ? theme.custom?.riskChipComponent[riskCustom]
    : theme.custom?.riskChipComponent.neutral;

  return (
    <ConditionalTooltip title={tooltip}>
      <Box position="relative" display="inline-flex" style={{ width }}>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          style={{ ...riskStyle, borderRadius: halfWidth }}
        />
        <CircularProgress
          variant="determinate"
          size={width}
          style={{ color: riskStyle?.color, strokeLinecap: 'round' }}
          value={progress}
          thickness={thickness}
        />
        <Box
          top={0}
          left={points < 100 && suffix ? 8 : 0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography component="span" style={{ ...style?.number, color: riskStyle?.color }}>
            {notCalculated === true ? '-' : points}
            {suffix && (
              <Typography component="span" variant="subtitle1">
                {suffix}
              </Typography>
            )}
          </Typography>
        </Box>
        <Box
          top={halfWidth + 2}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {subheading && (
            <Typography component="span" style={style?.text} color="textSecondary">
              {subheading}
            </Typography>
          )}
        </Box>
      </Box>
    </ConditionalTooltip>
  );
};

export { WheelPoints, getWheelProp };
