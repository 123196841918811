import { CASE_STATISTICS_AND_HEALTH_ACTIONS } from 'modules/dashboard/case-statistic/middleware/action-types';

const caseStatisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsCount.success: {
      return {
        ...state,
        caseCounts: action.payload.data,
      };
    }

    case CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsTypeCount.success: {
      return {
        ...state,
        caseTypeCounts: action.payload.data,
      };
    }

    case CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsHealth.success: {
      return {
        ...state,
        caseHealth: action.payload.data,
      };
    }

    default:
      return state;
  }
};

export default caseStatisticsReducer;
