import { AUTH_ACTIONS } from 'modules/authentication/login/middleware/action-types';
import SessionService from 'modules/authentication/login/services/session-service';
import { TENANT_CONFIG_ACTIONS } from 'modules/configuration/workflows-and-users/middleware/action-types';
import TenantManagementService from 'services/tenant/tenant-management-service';

const auth = new SessionService();

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.withSso.login: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case AUTH_ACTIONS.withSso.success: {
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        error: undefined,
      };
    }

    case AUTH_ACTIONS.withSso.error: {
      return {
        ...state,
        isAuthenticated: false,
        error: true,
      };
    }

    case AUTH_ACTIONS.withDatabase.login: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case AUTH_ACTIONS.withDatabase.success: {
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        error: undefined,
      };
    }

    case AUTH_ACTIONS.withDatabase.error: {
      return {
        ...state,
        isAuthenticated: false,
        error: {
          isError: action.payload.error,
          errorMessage: action.payload.message,
        },
      };
    }

    case AUTH_ACTIONS.withDatabase.logout:
    case AUTH_ACTIONS.withSso.logout: {
      return {
        isAuthenticated: false,
      };
    }

    case AUTH_ACTIONS.userInfos.set: {
      const payload = action.payload || {};
      const isAuthenticated = !payload.error && !!payload.accessToken;
      return {
        ...state,
        ...payload,
        isAuthenticated,
        error: undefined,
        user: {
          ...payload.user,
        },
      };
    }

    case AUTH_ACTIONS.platform.viewingConfig: {
      return {
        ...state,
        userPermissions: null,
        userPermissionRefreshAt: null,
        detachmentConfig: null,
        detachmentConfigRefreshAt: null,
      };
    }

    case AUTH_ACTIONS.permissions.success: {
      const payload = action.payload || {};

      return {
        ...state,
        userPermissions: payload.permissions,
        userPermissionRefreshAt: new Date().getTime(),
      };
    }

    case AUTH_ACTIONS.detachmentConfig.success: {
      const payload = action.payload || {};
      return {
        ...state,
        detachmentConfig: payload.data,
        detachmentConfigRefreshAt: new Date().getTime(),
      };
    }

    case AUTH_ACTIONS.operatorList.success: {
      const payload = action.payload || {};

      return {
        ...state,
        operatorList: payload.users,
      };
    }
    case TENANT_CONFIG_ACTIONS.REFRESH_CACHE_ERROR:
    case TENANT_CONFIG_ACTIONS.REFRESH_CACHE:
    case TENANT_CONFIG_ACTIONS.REFRESH_CACHE_SUCCESS: {
      const payload = action.payload || {};

      return {
        ...state,
        refreshCacheInfo: payload,
      };
    }

    default: {
      return {
        isLoading: false,
        isAuthenticated: auth.isAuthenticated(),
        user: {
          userId: auth.getUserId(),
          tenantId: TenantManagementService.getActiveTenantId(),
          tenantName: TenantManagementService.getTenantName(),
          username: auth.getUsername(),
          email: auth.getEmail(),
          tenants: TenantManagementService.getTenants(),
        },
        ...state,
      };
    }
  }
};

export default authReducer;
