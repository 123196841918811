import { Divider, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import { Form, useForm } from 'hooks/use-form';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AdditionalIdFormProps {
  onSubmit: CallableFunction;
  editForm: {
    key?: string;
    values?: string[];
    type?: string;
  };
  filterKey: string;
}

export const AdditionalIdForm: FC<AdditionalIdFormProps> = ({ editForm, filterKey, onSubmit }) => {
  const { t } = useTranslation();

  const initialFValues = {
    type: editForm?.key || '',
    values: editForm?.values || [],
  };

  type useFormReturnType = ReturnType<typeof useForm> & {
    errors: Record<string, null>;
  };
  const { values, errors, setErrors, handleInputChange }: useFormReturnType = useForm(
    initialFValues,
    false,
    undefined,
  );

  const validate = (fieldValues = values): boolean => {
    const temp: Record<string, string | null> = { ...errors };

    const isIdTypeSet = (fieldValues?.type?.length || 0) > 0;
    const isIdValuesSet = (fieldValues?.values?.length || 0) > 0;

    if (!isIdTypeSet && !isIdValuesSet) {
      temp.additionalId = t('required.subheading');
    } else {
      temp.additionalId = '';
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((it) => it === '');
  };

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.KEY_VALUES.key'),
        field: filterKey,
        key: values.type,
        values: values.values,
      });
    }
  };

  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="px-3 pt-3" spacing={2}>
          <Grid item xs={12}>
            <Controls.Input
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={Boolean(errors.additionalId)}
              name="type"
              id="type"
              label={t('mapping:filters.filtering.additionalIds.type.value.heading')}
              value={values.type}
              type="text"
              multiline={false}
              placeholder={undefined}
              endAdornment={null}
              startAdornment={undefined}
              shrink
              disabled={false}
              customValidation={false}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.MultipleInput
              dataTestIdPrefix="filter-by-additional-id-value"
              name="values"
              label={t('mapping:filters.filtering.additionalIds.values.value.heading')}
              value={values.values}
              onChange={handleInputChange}
              error={errors.additionalId}
              shrink
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className="pt-2">
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            type="submit"
            color="primary"
            text={t('filter.heading')}
            onClick={handleSubmit}
            dataTestIdPrefix="filter-by-additional-id"
          />
        </Grid>
      </Form>
    </Grid>
  );
};
