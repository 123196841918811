import { MccDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/mcc-details';
import { HidtaDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/hidta-details';
import { HifcaDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/hifca-details';
import { PepDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/pep-details';
import { AdverseMediaDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/adverse-media-details';
import { OrganizationDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/organization-details';
import { DurationInBusinessDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/duration-in-business-details';
import { getScoreNames } from 'modules/configuration/customer-risk-rating/customer-risk-rating-util';
import CountryOfRegistrationDetails from 'components/popover/person-details/person-details-tab/risk-rating/score-details/country-of-registration-details';
import CountryOfResidenceDetails from 'components/popover/person-details/person-details-tab/risk-rating/score-details/country-of-residence-details';
import { DurationCustomerAgeDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/duration-customer-age-details';
import { IncomeMonetaryValueRangeDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/income-monetary-value-range-details';
import { OccupationDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/occupation-details';
import { DurationCustomerSinceDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/duration-customer-since-details';
import { ContractValueDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/contract-value-details';
import { ProductTypeDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/product-type-details';
import { GenericDetails } from 'components/popover/person-details/person-details-tab/risk-rating/score-details/generic-details';

const RiskRatingCategoryDetails = ({
  scoreName,
  scoreData,
  maxPoints,
  popoverMode,
  caseConfig,
  isIndividual,
}) => {
  const scoreNames = getScoreNames();
  const detailsComponentMap = {
    [scoreNames.mccScore]: {
      component: MccDetails,
    },
    [scoreNames.hidtaScore]: {
      component: HidtaDetails,
    },
    [scoreNames.hifcaScore]: {
      component: HifcaDetails,
    },
    [scoreNames.pepScore]: {
      component: PepDetails,
    },
    [scoreNames.adverseMediaScore]: {
      component: AdverseMediaDetails,
    },
    [scoreNames.organizationTypeScore]: {
      component: OrganizationDetails,
    },
    [scoreNames.durationInBusinessScore]: {
      component: DurationInBusinessDetails,
    },
    [scoreNames.countryOfRegistration]: {
      component: CountryOfRegistrationDetails,
    },
    [scoreNames.countryOfResidence]: {
      component: CountryOfResidenceDetails,
    },
    [scoreNames.durationCustomerAgeScore]: {
      component: DurationCustomerAgeDetails,
    },
    [scoreNames.expectedIncomeScore]: {
      component: IncomeMonetaryValueRangeDetails,
    },
    [scoreNames.occupationScore]: {
      component: OccupationDetails,
    },
    [scoreNames.durationCustomerSinceScore]: {
      component: DurationCustomerSinceDetails,
    },
    [scoreNames.contractValueScore]: {
      component: ContractValueDetails,
    },
    [scoreNames.productTypeScore]: {
      component: ProductTypeDetails,
    },
    // TODO: add here if more risk factor
  };

  const detailsComponent =
    detailsComponentMap[scoreName] ||
    ((scoreData?.RISK_FACTOR_INSTANCE || scoreData?.TEMPLATE) && { component: GenericDetails });

  if (detailsComponent) {
    return (
      <detailsComponent.component
        scoreData={scoreData}
        maxPoints={maxPoints}
        popoverMode={popoverMode}
        caseConfig={caseConfig}
        isIndividual={isIndividual}
      />
    );
  }

  return null;
};

export { RiskRatingCategoryDetails };
