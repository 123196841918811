import { RISKY_WORDS_V2_LIST_ACTIONS } from 'middleware/action-types';
import {
  activateRiskyWordsV2ListError,
  activateRiskyWordsV2ListSuccess,
  getRiskyWordsV2FileListError,
  getRiskyWordsV2FileListSuccess,
} from 'middleware/actions/risky-words-v2-list';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import RiskyWordsV2ListApiCommunicator from 'services/risky-words-v2-list-api-communicator';
import RiskyWordsV2ListManager from 'services/risky-words-v2-list-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { showError } from '../actions/error';

const apiCommunicator = new RiskyWordsV2ListApiCommunicator();
const occupationListManager = new RiskyWordsV2ListManager(apiCommunicator);

/**
 * Fetch customer occupation files action
 */
export function* getRiskyWordsV2FileListBegin(action) {
  try {
    yield put(startProcessing(RISKY_WORDS_V2_LIST_ACTIONS.fileList.fetch));

    const json = yield occupationListManager.getRiskyWordsV2FileList(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getRiskyWordsV2FileListSuccess(json));

    yield put(stopProcessing(RISKY_WORDS_V2_LIST_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getRiskyWordsV2FileListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(RISKY_WORDS_V2_LIST_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch customer occupation files action watcher
 */
export function* getRiskyWordsV2FileListWatcher() {
  yield takeLatest(RISKY_WORDS_V2_LIST_ACTIONS.fileList.fetch, getRiskyWordsV2FileListBegin);
}

/**
 *  Update active risky words v2 file version action
 */
export function* updateActiveRiskyWordsV2ListVersionBegin(action) {
  try {
    yield put(startProcessing(RISKY_WORDS_V2_LIST_ACTIONS.activateVersion.send));

    const json = yield occupationListManager.updateActiveRiskyWordsV2ListVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateRiskyWordsV2ListSuccess(json));

    yield put(stopProcessing(RISKY_WORDS_V2_LIST_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateRiskyWordsV2ListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(RISKY_WORDS_V2_LIST_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active customer occupation file version action watcher
 */
export function* updateActiveRiskyWordsV2ListVersionWatcher() {
  yield takeLatest(
    RISKY_WORDS_V2_LIST_ACTIONS.activateVersion.send,
    updateActiveRiskyWordsV2ListVersionBegin,
  );
}
