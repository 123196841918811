/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatMaskedValue } from 'utils/helpers/cases/case-utility';
import { getTenantDefaultCurrency } from 'utils/helpers/config-utils/config-util';
import { TabPanel } from 'components/tabs/tab';
import { formatMoney, getNormalizeValue } from 'utils/helpers/math-util';
import { RiskChip } from 'components/chips/risk-chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  ListItemText,
  Typography,
} from '@mui/material';
import FormattedDateTime from 'components/formatted-date-time';
import { RISK_CATEGORY } from 'utils/constants/constant';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { BusinessPurposeChip } from 'components/chips/business-purpose-chip';
import { AccountData } from 'components/popover/person-details/person-details-tab/account-data';
import { CustomerRelationshipView } from 'components/popover/person-details/person-details-tab/customer-relationship-view';
import { SectionLink } from 'components/popover/person-details/person-details-tab/section-link';
import { RootState } from 'middleware/store';
import { useCustomerDataView } from 'components/popover/person-details/person-details-tab/use-customer-data-view';
import { isEmptyNestedChecks } from 'utils/helpers/object-util';
import { DbaData } from 'components/popover/person-details/person-details-tab/dba/dba-data';

interface CustomerDataProps {
  personDetails: any;
  tenantConfig: unknown;
  caseConfig: unknown;
  showUnmaskedData: boolean;
  isCustomerCase: boolean;
  isCustomer?: boolean;
}

/**
 * customer data for person popover
 */
const CustomerData: FC<CustomerDataProps> = ({
  personDetails,
  tenantConfig,
  caseConfig,
  showUnmaskedData,
  isCustomerCase,
  isCustomer,
}) => {
  const allMasks = useSelector((state: RootState) => state.masks?.all);
  const { currentView, setCurrentView, tabView } = useCustomerDataView();

  const formatMaskedValueInternal = (fieldObject: any) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);

  const handleChange = (viewKey: string) => () => {
    setCurrentView(tabView[viewKey]);
  };

  const handleAccountData = (accounts: any[] = []) => {
    const allAccounts: {
      accountLookupId: any;
      holderName: any;
      accountNumber: any;
      bankCountry: any;
      scheme: any;
      bankIdentifier: any;
      countryOfSettlement: any;
      contractualDetails: any;
      flags: string[];
      creditLimitDetails: any;
    }[] = [];

    accounts?.forEach((accountData) => {
      let tempAccount = accountData;
      if (accountData.account) {
        tempAccount = accountData.account;
      }
      allAccounts.push({
        accountLookupId: formatMaskedValueInternal(tempAccount.accountLookupId),
        holderName: formatMaskedValueInternal(tempAccount.holderName),
        accountNumber: formatMaskedValueInternal(tempAccount.accountNumber),
        bankCountry: tempAccount.bankCountry,
        scheme: tempAccount.scheme,
        bankIdentifier: tempAccount.bankIdentifier,
        countryOfSettlement: accountData.countryOfSettlement,
        contractualDetails: accountData.contractualDetails,
        flags: accountData.flags,
        creditLimitDetails: accountData.creditLimitDetails,
      });
    });

    return allAccounts;
  };

  const shouldShowRiskLevel = (riskLevel: string) =>
    !!riskLevel && riskLevel !== RISK_CATEGORY.low + RISK_CATEGORY.default;

  // todo get tenant config from state
  const tenantBaseCurrency = getTenantDefaultCurrency(tenantConfig);

  const decideCustomerSince = (it: any) => {
    if (it?.customerSince) {
      return new Date(it?.customerSince);
    }
    if (it?.characteristics?.customerSince) {
      return new Date(it?.characteristics?.customerSince);
    }
    return '';
  };

  const partyData = personDetails?.parties
    ? {
        ...personDetails?.parties[0],
        calculatedRiskLevel: personDetails?.calculatedRiskLevel,
        transaction: personDetails?.transaction,
        underwriterData: personDetails?.underwriterData,
      }
    : personDetails;

  const customerData = {
    customerId: formatMaskedValueInternal(partyData?.customerId),
    customerGroupId: formatMaskedValueInternal(partyData?.customerGroupId),
    customerSince: decideCustomerSince(partyData),
    customerStatus: partyData.customerStatus,
    customerType: partyData.customerType,
    signedProducts: partyData.signedProducts,
    flags: partyData.flags,
    riskLevel: partyData.riskLevel,
    taxId: formatMaskedValueInternal(partyData?.taxId),
    taxIdType: partyData?.taxIdType,
    underwriterData: {
      name: partyData?.underwriterData?.name,
      id: formatMaskedValueInternal(partyData?.underwriterData?.underwriterId),
    },
    customerReviewSchedule: {
      nextReviewDate: partyData.customerReviewSchedule?.nextReviewDate,
      nextScheduledReviewDate: partyData.customerReviewSchedule?.nextScheduledReviewDate,
    },
    characteristics: {
      depositDelay: partyData.characteristics?.depositDelay,
      discountFrequency: partyData.characteristics?.discountFrequency,
      businessPurposeDistribution: partyData.characteristics?.businessPurposeDistribution,
      expectedTicketSize: {
        max: partyData.characteristics?.expectedTicketSizeMax,
        average: partyData.characteristics?.expectedTicketSizeAverage,
      },
      expectedVolume: {
        daily: partyData.characteristics?.expectedVolumeDaily,
        monthly: partyData.characteristics?.expectedVolumeMonthly,
        volatility: partyData.characteristics?.expectedVolumeVolatility,
      },
      expectedIncome: {
        daily: partyData.characteristics?.expectedIncomeDaily,
        monthly: partyData.characteristics?.expectedIncomeMonthly,
        yearly: partyData.characteristics?.expectedIncomeYearly,
      },
      expectedTransactionCount: {
        daily: partyData.characteristics?.expectedTransactionCountDaily,
        monthly: partyData.characteristics?.expectedTransactionCountMonthly,
        yearly: partyData.characteristics?.expectedTransactionCountYearly,
      },
      majorCounterpartyAccounts: handleAccountData(
        partyData?.characteristics?.majorCounterpartyAccounts,
      ),
      employmentDetails: partyData.characteristics?.employmentDetails,
    },
    customerRelationshipDetails: {
      businessUnit: partyData.customerRelationshipDetails?.businessUnit,
      exemptions: partyData.customerRelationshipDetails?.exemptions,
      allAccounts: handleAccountData(partyData?.customerRelationshipDetails?.allAccounts),
      staff: partyData?.customerRelationshipDetails?.staff,
    },
    doingBusinessAs: partyData?.dba,
  };

  interface GeneralViewProps {
    customerData: any;
  }

  /**
   * general overview of customer data
   */
  const GeneralView: FC<GeneralViewProps> = ({ customerData }) => {
    const { riskLevel } = customerData;
    const personDetailsTabLinks = [
      'characteristics',
      'customerRelationshipDetails',
      'doingBusinessAs',
    ];

    return (
      <Translation>
        {(t) => (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.customerId.heading')}
                </Typography>
                <Typography variant="body2" data-testid="customer-id">
                  {customerData.customerId ? customerData.customerId : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.groupId.heading')}
                </Typography>
                <Typography variant="body2">
                  {customerData.customerGroupId ? customerData.customerGroupId : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.customerSince.heading')}
                </Typography>
                <Typography variant="body2">
                  {customerData.customerSince ? (
                    <FormattedDateTime
                      date={customerData.customerSince}
                      withTime={false}
                      shortLanguage={undefined}
                    />
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.status.heading')}
                </Typography>
                <Typography variant="body2">
                  {customerData.customerStatus ? customerData.customerStatus : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.type.heading')}
                </Typography>
                <Typography variant="body2">
                  {customerData.customerType ? customerData.customerType : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.riskLevel.heading')}
                </Typography>
                {shouldShowRiskLevel(riskLevel) ? (
                  <RiskChip
                    size="small"
                    risk={riskLevel}
                    label={t(`riskCategory.${riskLevel?.toLowerCase()}.heading`)}
                  />
                ) : (
                  '-'
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.products.heading')}
                </Typography>
                {customerData.signedProducts
                  ? customerData.signedProducts?.map((product: any, index: any) => (
                      <Chip label={product} size="small" key={index} className="ml-1" />
                    ))
                  : '-'}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.flags.heading')}
                </Typography>
                {customerData.flags
                  ? customerData.flags.map((flag: any, index: any) => (
                      <Chip label={flag} size="small" key={index} className="ml-1" />
                    ))
                  : '-'}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.underwriter.heading')}
                </Typography>

                <Typography variant="body2">
                  {customerData.underwriterData && customerData.underwriterData.name
                    ? `${customerData.underwriterData.id}. ${customerData.underwriterData.name}`
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.taxId.heading')}
                </Typography>
                <Typography variant="body2" data-testid="tax-id">
                  {customerData.taxId ? customerData.taxId : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.customer.taxIdType.heading')}
                </Typography>
                <Typography variant="body2">
                  {customerData.taxIdType ? customerData.taxIdType : '-'}
                </Typography>
              </Grid>

              {(isCustomerCase || isCustomer) &&
                !isEmptyNestedChecks(customerData.customerReviewSchedule) && (
                  <>
                    <Grid container item>
                      <Grid item>
                        <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                          {t('case:userPopover.customer.customerReviewSchedule.heading')}
                        </Box>
                      </Grid>
                    </Grid>
                    {customerData.customerReviewSchedule.nextScheduledReviewDate && (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          {t(
                            'case:userPopover.customer.customerReviewSchedule.nextScheduledReviewDate.heading',
                          )}
                        </Typography>
                        <Typography variant="body2">
                          <FormattedDateTime
                            date={customerData.customerReviewSchedule.nextScheduledReviewDate}
                            withTime={false}
                            shortLanguage={undefined}
                          />
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {t(
                          'case:userPopover.customer.customerReviewSchedule.nextReviewDate.heading',
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {customerData.customerReviewSchedule.nextReviewDate ? (
                          <FormattedDateTime
                            date={customerData.customerReviewSchedule.nextReviewDate}
                            withTime={false}
                            shortLanguage={undefined}
                          />
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}

              {personDetailsTabLinks.some((label) => !isEmptyNestedChecks(customerData[label])) && (
                <Grid item xs={12} className="border-bottom">
                  {personDetailsTabLinks.map(
                    (label, index) =>
                      !isEmptyNestedChecks(customerData[label]) && (
                        <SectionLink
                          key={index}
                          label={t(`case:userPopover.customer.${label}.heading`)}
                          changeTabViewOnClick={handleChange(tabView[label].key)}
                        />
                      ),
                  )}
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Translation>
    );
  };

  interface CharacteristicsViewProps {
    customerData: any;
  }

  /**
   * Characteristics data for person popover
   */
  const CharacteristicsView: FC<CharacteristicsViewProps> = ({ customerData }) => {
    const { t } = useTranslation();
    return (
      <Grid container spacing={1} className="pb-2">
        <Grid container item alignItems="center">
          <Grid item>
            <IconButtons.Back
              edge="start"
              onClick={handleChange(tabView.characteristics.back as string)}
            />
          </Grid>

          <Grid item>
            <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
              {t('case:userPopover.customer.characteristics.heading')}
            </Box>
          </Grid>
        </Grid>

        {customerData.characteristics.depositDelay && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.depositDelay.heading')}
            </Typography>
            <Typography variant="body2">{customerData.characteristics.depositDelay}</Typography>
          </Grid>
        )}

        {customerData.characteristics.discountFrequency && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.discountFrequency.heading')}
            </Typography>
            <Typography variant="body2">
              {customerData.characteristics.discountFrequency}
            </Typography>
          </Grid>
        )}

        {!!customerData.characteristics.businessPurposeDistribution?.length && (
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.businessPurposeDistribution.heading')}
            </Typography>
            <Typography variant="body2" component="div" className="mt-1">
              {customerData.characteristics.businessPurposeDistribution.map(
                (item: { labels: string[]; share: number }, index: number) => (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    key={index}
                  >
                    <Grid item container spacing={0.2}>
                      {item.labels.map((businessPurpose: string, index: number) => (
                        <Grid item key={index}>
                          <BusinessPurposeChip
                            providedBusinessPurpose={businessPurpose}
                            caseDetail={undefined}
                            isCustomer={undefined}
                            label={undefined}
                          />
                        </Grid>
                      ))}
                    </Grid>

                    <Grid item paddingRight={2}>
                      {getNormalizeValue(item.share, 1, 100)}%
                    </Grid>
                  </Grid>
                ),
              )}
            </Typography>
          </Grid>
        )}

        {!isEmptyNestedChecks(customerData.characteristics.employmentDetails) && (
          <>
            <Grid item xs={12} className="mt-1">
              <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                {t('case:userPopover.customer.characteristics.employmentDetails.heading')}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('case:userPopover.customer.characteristics.employmentDetails.status.heading')}
              </Typography>
              {customerData.characteristics.employmentDetails.status ? (
                <Typography variant="body2">
                  {customerData.characteristics.employmentDetails.status}
                </Typography>
              ) : (
                '-'
              )}
            </Grid>
          </>
        )}

        {!isEmptyNestedChecks(customerData.characteristics.expectedVolume) && (
          <Grid item xs={12} className="mt-1">
            <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
              {t('case:userPopover.customer.characteristics.expectedVolumes.heading')}
            </Box>
          </Grid>
        )}

        {customerData.characteristics.expectedVolume?.daily && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedVolumes.daily.heading')}
            </Typography>

            <Typography variant="body2">
              <Typography component="span" variant="caption" color="textPrimary">
                {tenantBaseCurrency}{' '}
              </Typography>
              {formatMoney(customerData.characteristics.expectedVolume.daily)}
            </Typography>
          </Grid>
        )}

        {customerData.characteristics.expectedVolume?.monthly && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedVolumes.monthly.heading')}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" variant="caption" color="textPrimary">
                {tenantBaseCurrency}{' '}
              </Typography>
              {formatMoney(customerData.characteristics.expectedVolume.monthly)}
            </Typography>
          </Grid>
        )}

        {customerData.characteristics.expectedVolume?.volatility && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedVolumes.volatility.heading')}
            </Typography>
            <Typography variant="body2">
              {customerData.characteristics.expectedVolume.volatility}
            </Typography>
          </Grid>
        )}

        {!isEmptyNestedChecks(customerData.characteristics.expectedIncome) && (
          <Grid item xs={12} className="mt-1">
            <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
              {t('case:userPopover.customer.characteristics.expectedIncomes.heading')}
            </Box>
          </Grid>
        )}

        {customerData.characteristics.expectedIncome?.daily && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedIncomes.daily.heading')}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" variant="caption" color="textPrimary">
                {tenantBaseCurrency}{' '}
              </Typography>
              {formatMoney(customerData.characteristics.expectedIncome.daily)}
            </Typography>
          </Grid>
        )}

        {customerData.characteristics.expectedIncome?.monthly && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedIncomes.monthly.heading')}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" variant="caption" color="textPrimary">
                {tenantBaseCurrency}{' '}
              </Typography>
              {formatMoney(customerData.characteristics.expectedIncome.monthly)}
            </Typography>
          </Grid>
        )}

        {customerData.characteristics.expectedIncome?.yearly && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedIncomes.yearly.heading')}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" variant="caption" color="textPrimary">
                {tenantBaseCurrency}{' '}
              </Typography>
              {formatMoney(customerData.characteristics.expectedIncome.yearly)}
            </Typography>
          </Grid>
        )}

        {!isEmptyNestedChecks(customerData.characteristics.expectedTransactionCount) && (
          <Grid item xs={12} className="mt-1">
            <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
              {t('case:userPopover.customer.characteristics.expectedTransactionCounts.heading')}
            </Box>
          </Grid>
        )}

        {customerData.characteristics.expectedTransactionCount?.daily && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t(
                'case:userPopover.customer.characteristics.expectedTransactionCounts.daily.heading',
              )}
            </Typography>
            <Typography variant="body2">
              {customerData.characteristics.expectedTransactionCount.daily}
            </Typography>
          </Grid>
        )}

        {customerData.characteristics.expectedTransactionCount?.monthly && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t(
                'case:userPopover.customer.characteristics.expectedTransactionCounts.monthly.heading',
              )}
            </Typography>
            <Typography variant="body2">
              {customerData.characteristics.expectedTransactionCount.monthly}
            </Typography>
          </Grid>
        )}

        {customerData.characteristics.expectedTransactionCount?.yearly && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t(
                'case:userPopover.customer.characteristics.expectedTransactionCounts.yearly.heading',
              )}
            </Typography>
            <Typography variant="body2">
              {customerData.characteristics.expectedTransactionCount.yearly}
            </Typography>
          </Grid>
        )}

        {!isEmptyNestedChecks(customerData.characteristics.expectedTicketSize) && (
          <Grid item xs={12} className="mt-1">
            <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
              {t('case:userPopover.customer.characteristics.expectedTicketSize.heading')}
            </Box>
          </Grid>
        )}

        {customerData.characteristics.expectedTicketSize?.average && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedTicketSize.average.heading')}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" variant="caption" color="textPrimary">
                {tenantBaseCurrency}{' '}
              </Typography>
              {formatMoney(customerData.characteristics.expectedTicketSize.average)}
            </Typography>
          </Grid>
        )}

        {customerData.characteristics.expectedTicketSize?.max && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.characteristics.expectedTicketSize.max.heading')}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" variant="caption" color="textPrimary">
                {tenantBaseCurrency}{' '}
              </Typography>
              {formatMoney(customerData.characteristics.expectedTicketSize.max)}
            </Typography>
          </Grid>
        )}

        {!!customerData.characteristics?.majorCounterpartyAccounts?.length && (
          <Grid item xs={12}>
            <Grid item xs={12} className="my-1 mb-2">
              <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                {t('case:userPopover.customer.characteristics.majorCounterpartyAccounts.heading')}
              </Box>
            </Grid>
            {customerData.characteristics?.majorCounterpartyAccounts?.map(
              (
                account: {
                  holderName: string;
                  accountNumber: string;
                },
                index: string,
              ) => (
                <Accordion variant="outlined" key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ListItemText
                      className="m-0"
                      primary={account?.holderName}
                      secondary={account?.accountNumber}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <AccountData account={account} caseConfig={caseConfig} />
                  </AccordionDetails>
                </Accordion>
              ),
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <TabPanel index={tabView.general.key} value={currentView?.key}>
        <GeneralView customerData={customerData} />
      </TabPanel>
      {!isEmptyNestedChecks(customerData.characteristics) && (
        <TabPanel index={tabView.characteristics.key} value={currentView?.key}>
          <CharacteristicsView customerData={customerData} />
        </TabPanel>
      )}
      {!isEmptyNestedChecks(customerData.customerRelationshipDetails) && (
        <TabPanel index={tabView.customerRelationshipDetails.key} value={currentView?.key}>
          <CustomerRelationshipView
            customerData={customerData}
            tenantConfig={tenantConfig}
            changeTabViewOnClick={handleChange(tabView.customerRelationshipDetails.back as string)}
            caseConfig={caseConfig}
          />
        </TabPanel>
      )}
      <TabPanel index={tabView.doingBusinessAs.key} value={currentView?.key}>
        <DbaData
          showUnmaskedData={showUnmaskedData}
          dbaContactDetails={customerData.doingBusinessAs}
          handleChange={handleChange(tabView.doingBusinessAs.back as string)}
        />
      </TabPanel>
    </>
  );
};

export { CustomerData };
