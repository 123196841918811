import { BLACKLIST_CONFIG_ACTIONS } from 'middleware/action-types';

export const getBlacklistConfig = () => ({
  type: BLACKLIST_CONFIG_ACTIONS.get.data,
});

export const getBlacklistConfigSuccess = (payload) => ({
  type: BLACKLIST_CONFIG_ACTIONS.get.success,
  payload,
});

export const getBlackListConfigError = () => ({
  type: BLACKLIST_CONFIG_ACTIONS.get.error,
});

export const deleteBlacklistConfig = (payload) => ({
  type: BLACKLIST_CONFIG_ACTIONS.delete.data,
  payload,
});

export const deleteBlacklistConfigSuccess = (payload) => ({
  type: BLACKLIST_CONFIG_ACTIONS.delete.success,
  payload,
});

export const deleteBlacklistConfigError = (payload) => ({
  type: BLACKLIST_CONFIG_ACTIONS.delete.error,
  payload,
});

export const updateBlacklistConfig = (payload) => ({
  type: BLACKLIST_CONFIG_ACTIONS.update.data,
  payload,
});

export const updateBlacklistConfigSuccess = (payload) => ({
  type: BLACKLIST_CONFIG_ACTIONS.update.success,
  payload,
});

export const updateBlacklistConfigError = (payload) => ({
  type: BLACKLIST_CONFIG_ACTIONS.update.error,
  payload,
});
