import APICommunicator from './api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class ProductTypeListApiCommunicator extends APICommunicator {
  fetchProductTypeFilesListIndividual(tenantId) {
    const baseUrl = API_URLS.productTypeService.getMetadata.individual;

    const apiUrl = baseUrl.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.PRODUCT_TYPE_LIST_DATA_NOT_FOUND, tenantId);
  }

  fetchProductTypeFilesListOrganization(tenantId) {
    const baseUrl = API_URLS.productTypeService.getMetadata.organization;

    const apiUrl = baseUrl.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.PRODUCT_TYPE_LIST_DATA_NOT_FOUND, tenantId);
  }

  updateActiveProductTypeListVersionIndividual(tenantId, version) {
    const apiUrl = API_URLS.productTypeService.updateActiveVersion.individual
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }

  updateActiveProductTypeListVersionOrganization(tenantId, version) {
    const apiUrl = API_URLS.productTypeService.updateActiveVersion.organization
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default ProductTypeListApiCommunicator;
