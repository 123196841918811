import { DetachmentUtilActions } from 'middleware/action-types';

export const putDetachmentConfig = (payload) => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE,
  payload,
});

export const putDetachmentConfigSuccess = (payload) => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE_SUCCESS,
  payload,
});

export const putDetachmentConfigError = () => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_UPDATE_ERROR,
});

export const postDetachmentConfig = (payload) => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT,
  payload,
});

export const postDetachmentConfigSuccess = (payload) => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT_SUCCESS,
  payload,
});

export const postDetachmentConfigError = () => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_INSERT_ERROR,
});

export const getDetachmentConfig = () => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET,
});

export const getDetachmentConfigSuccess = (payload) => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET_SUCCESS,
  payload,
});

export const getDetachmentConfigError = () => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_GET_ERROR,
});

export const deleteDetachmentConfig = () => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET,
});

export const deleteDetachmentConfigSuccess = (payload) => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET_SUCCESS,
  payload,
});

export const deleteDetachmentConfigError = () => ({
  type: DetachmentUtilActions.DETACHMENT_CONFIG_UTIL_RESET_ERROR,
});
