import { Tooltip } from '@mui/material';

/**
 * renders tooltip if available
 * @param {*} param0
 * @returns
 */
const ConditionalTooltip = ({ title, children }) => (
  <>{title ? <Tooltip title={title}>{children}</Tooltip> : children}</>
);

export { ConditionalTooltip };
