import i18next from 'i18next';
import { CUSTOMER_RISK_RATING_CONFIG_ACTIONS } from 'middleware/action-types';
import {
  createRiskFactorError,
  createRiskFactorForIndividualError,
  createRiskFactorForIndividualSuccess,
  createRiskFactorSuccess,
  deleteCustomerRiskRatingConfig,
  deleteCustomerRiskRatingConfigForIndividual,
  deleteCustomerRiskRatingError,
  deleteCustomerRiskRatingForIndividualError,
  deleteRiskRatingFactorForIndividualError,
  deleteRiskRatingFactorForIndividualSuccess,
  deleteRiskRatingFactorForOrganizationError,
  deleteRiskRatingFactorForOrganizationSuccess,
  getCustomerRiskRatingConfigError,
  getCustomerRiskRatingConfigForIndividualError,
  getCustomerRiskRatingConfigForIndividualSuccess,
  getCustomerRiskRatingConfigSuccess,
  updateCustomerRiskRatingConfigForIndividualSuccess,
  updateCustomerRiskRatingConfigSuccess,
  updateCustomerRiskRatingError,
  updateCustomerRiskRatingForIndividualError,
} from 'middleware/actions/customer-risk-rating-config';
import { showError } from 'middleware/actions/error';
import { showNotification } from 'middleware/actions/notification';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import CustomerRiskRatingConfigApiCommunicator from 'services/customer-risk-rating-config-api-communicator';
import CustomerRiskRatingConfigManager from 'services/customer-risk-rating-config-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';

const apiCommunicator = new CustomerRiskRatingConfigApiCommunicator();
const manager = new CustomerRiskRatingConfigManager(apiCommunicator);

export function* getCustomerRiskRatingConfigBegin(action) {
  try {
    yield put(startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.data));

    const json = yield manager.getCustomerRiskRatingConfig(
      action.payload?.tenantId ?? TenantManagementService.getActiveTenantId(),
      action.payload?.configVersion,
    );
    yield put(getCustomerRiskRatingConfigSuccess(json));

    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.data));
  } catch (error) {
    yield put(getCustomerRiskRatingConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.data));
  }
}

export function* deleteCustomerRiskRatingConfigBegin(action) {
  try {
    yield put(startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.delete.data));

    yield put(deleteCustomerRiskRatingConfig());

    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.delete.data));
  } catch (error) {
    yield put(deleteCustomerRiskRatingError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.delete.data));
  }
}

export function* deleteCustomerRiskRatingConfigForIndividualBegin(action) {
  try {
    yield put(startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.delete.data));

    yield put(deleteCustomerRiskRatingConfigForIndividual());

    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.delete.data));
  } catch (error) {
    yield put(deleteCustomerRiskRatingForIndividualError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.delete.data));
  }
}

export function* cleanUpConfigBegin() {
  yield put(deleteCustomerRiskRatingConfig());
  yield put(deleteCustomerRiskRatingConfigForIndividual());
}

export function* getCustomerRiskRatingConfigForIndividualBegin(action) {
  try {
    yield put(startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.data));

    const json = yield manager.getCustomerRiskRatingConfigForIndividual(
      action.payload?.tenantId ?? TenantManagementService.getActiveTenantId(),
      action.payload?.configVersion,
    );
    yield put(getCustomerRiskRatingConfigForIndividualSuccess(json));

    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.data));
  } catch (error) {
    yield put(getCustomerRiskRatingConfigForIndividualError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.data));
  }
}

export function* getCustomerRiskRatingConfigWatcher() {
  yield takeLatest(
    CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.get.data,
    getCustomerRiskRatingConfigBegin,
  );
}

export function* getCustomerRiskRatingConfigForIndividualWatcher() {
  yield takeLatest(
    CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.get.data,
    getCustomerRiskRatingConfigForIndividualBegin,
  );
}

export function* updateCustomerRiskRatingConfig(action) {
  try {
    yield put(startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.data));

    const json = yield manager.updateCustomerRiskRatingConfig(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(updateCustomerRiskRatingConfigSuccess(json));

    yield put(
      showNotification({
        message: i18next.t('configuration:customerRiskRating.notification.success.heading'),
      }),
    );

    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.data));
  } catch (error) {
    yield put(updateCustomerRiskRatingError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.data));
  }
}

export function* updateCustomerRiskRatingConfigForIndividual(action) {
  try {
    yield put(startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.data));

    const json = yield manager.updateCustomerRiskRatingConfigForIndividual(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(updateCustomerRiskRatingConfigForIndividualSuccess(json));

    yield put(
      showNotification({
        message: i18next.t('configuration:customerRiskRating.notification.success.heading'),
      }),
    );
    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.data));
  } catch (error) {
    yield put(updateCustomerRiskRatingForIndividualError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.data));
  }
}

export function* updateCustomerRiskRatingConfigWatcher() {
  yield takeLatest(
    CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.update.data,
    updateCustomerRiskRatingConfig,
  );
}

export function* updateCustomerRiskRatingConfigForIndividualWatcher() {
  yield takeLatest(
    CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.update.data,
    updateCustomerRiskRatingConfigForIndividual,
  );
}

/**
 * queue organization and individual
 */
export function* createRiskFactor(action) {
  if (action?.payload?.organization) {
    try {
      yield put(
        startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.data),
      );

      const json = yield manager.createRiskFactor(
        TenantManagementService.getActiveTenantId(),
        action.payload.organization,
      );
      yield put(createRiskFactorSuccess(json));

      yield put(
        showNotification({
          message: i18next.t('configuration:customerRiskRating.notification.success.heading'),
        }),
      );

      yield put(
        stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.data),
      );
    } catch (error) {
      yield put(createRiskFactorError());
      yield put(showError({ ...error, action }));
      yield put(
        stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.data),
      );
    }
  }
  if (action?.payload?.individual) {
    try {
      yield put(
        startProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.createRiskFactor.data),
      );

      const json = yield manager.createRiskFactorForIndividual(
        TenantManagementService.getActiveTenantId(),
        action.payload.individual,
      );
      yield put(createRiskFactorForIndividualSuccess(json));

      yield put(
        showNotification({
          message: i18next.t('configuration:customerRiskRating.notification.success.heading'),
        }),
      );
      yield put(
        stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.createRiskFactor.data),
      );
    } catch (error) {
      yield put(createRiskFactorForIndividualError());
      yield put(showError({ ...error, action }));
      yield put(
        stopProcessing(CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.createRiskFactor.data),
      );
    }
  }
}

export function* createRiskFactorWatcher() {
  yield takeLatest(
    CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.createRiskFactor.data,
    createRiskFactor,
  );
}

export function* deleteRiskFactor(action) {
  const isIndividual =
    action.type === CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.deleteRiskFactor.data;

  try {
    yield put(startProcessing(action.type));

    const json = yield manager.deleteRiskFactor(TenantManagementService.getActiveTenantId(), {
      isIndividual,
      ...action.payload,
    });

    const successAction = isIndividual
      ? deleteRiskRatingFactorForIndividualSuccess
      : deleteRiskRatingFactorForOrganizationSuccess;
    yield put(successAction(json));

    // suppressing the green box so that we don't get two when risk thresholds are adjusted
    // yield put(
    //   showNotification({
    //     message: i18next.t('configuration:customerRiskRating.notification.success.heading'),
    //   }),
    // );
  } catch (error) {
    const errorAction = isIndividual
      ? deleteRiskRatingFactorForIndividualError
      : deleteRiskRatingFactorForOrganizationError;
    yield put(errorAction());
  }
  yield put(stopProcessing(action.type));
}

export function* deleteRiskFactorWatcher() {
  yield takeLatest(
    [
      CUSTOMER_RISK_RATING_CONFIG_ACTIONS.organization.deleteRiskFactor.data,
      CUSTOMER_RISK_RATING_CONFIG_ACTIONS.individual.deleteRiskFactor.data,
    ],
    deleteRiskFactor,
  );
}
