import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const Close = ({ onClick, edge, size = 'medium', fontSize, color }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('close.heading')}>
      <IconButton onClick={onClick} size={size} edge={edge} color={color}>
        <CloseIcon fontSize={fontSize} />
      </IconButton>
    </Tooltip>
  );
};

export default Close;
