import { AuditTrackActions } from 'middleware/action-types';

const auditTrackReducer = (state = {}, action) => {
  switch (action.type) {
    case AuditTrackActions.AUDIT_TRACK_GET: {
      if (action.page === 0) {
        return {
          ...state,
          auditTracks: [],
        };
      }
      return state;
    }

    case AuditTrackActions.AUDIT_TRACK_GET_SUCCESS: {
      const auditTracks = [...state.auditTracks, ...action.payload.content];

      return {
        ...state,
        auditTracks,
        currentPage: action.payload.pageable.pageNumber,
        lastPage: action.payload.last,
      };
    }

    case AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID_SUCCESS: {
      const newState = { ...state };
      const userIds = [];

      action.payload.stringValues.forEach((u) => {
        if (u != null && u.length > 0) {
          userIds.push(u);
        }
      });

      userIds.sort((a, b) => a.localeCompare(b));

      newState.userIds = userIds;
      return newState;
    }

    default:
      return state;
  }
};

export default auditTrackReducer;
