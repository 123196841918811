import LocalStorageService from 'services/local-storage-service';
import { isColumnCanSort } from 'utils/helpers/cases/case-utility';
import { canSeeAssignedUser } from 'utils/helpers/permissions-util';
import { validateColumnLocalStorage } from 'utils/helpers/table/columns';
import { UserPermission } from 'models/authorization/permissions';
import { TFunction } from 'react-i18next';

interface TableCell {
  id?: string;
  align: 'left' | 'right' | 'center';
  disablePadding: boolean;
  isVisible: boolean;
  label: string;
  isRequired?: boolean;
  sortConfig: {
    isEnabled: boolean;
    name: string;
    inverseSortIcon?: boolean;
  };
}

export const buildTableHeadCells = (
  caseStatusFilter: { key?: string }, // todo add real type here
  userPermissions: UserPermission[],
  t: TFunction,
  isRuleCategoryHeaderVisible = false,
  isInvestigationStatusAttached = false,
  alertPrioritization = {},
  isClosedCasesTable = false,
  isAutoCloseRelabelAttached = false,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isAnomalyScoreEnabled, isAutoCloseScoreEnabled } = alertPrioritization;

  const processingStatusCell: TableCell = {
    id: 'processingStatus',
    align: 'left',
    disablePadding: false,
    isVisible: !isInvestigationStatusAttached,
    label: t('mapping:filters.sorting.processingStatus.value.heading'),
    sortConfig: {
      isEnabled: isColumnCanSort(caseStatusFilter),
      name: t('mapping:filters.sorting.processingStatus.key'),
    },
  };

  const anomalyScoreCell: TableCell = {
    id: 'anomalyScore',
    align: 'left',
    disablePadding: false,
    label: t(`mapping:filters.sorting.anomalyScoreMax.value.heading`),
    isRequired: false,
    isVisible: false,
    sortConfig: {
      isEnabled: true,
      name: t('mapping:filters.sorting.anomalyScoreMax.key'),
      inverseSortIcon: true,
    },
  };

  const customerCell: TableCell = {
    id: 'customer',
    align: 'left',
    disablePadding: false,
    label: t('mapping:filters.sorting.customer.value.heading'),
    isRequired: true,
    isVisible: true,
    sortConfig: {
      isEnabled: true,
      name: t('mapping:filters.sorting.customer.key'),
    },
  };

  const autoCloseScoreCell: TableCell = {
    id: 'autoCloseScore',
    align: 'left',
    disablePadding: false,
    label: isAutoCloseRelabelAttached
      ? t(`mapping:filters.sorting.falsePositiveReductionScore.value.heading`)
      : t(`mapping:filters.sorting.autoCloseScoreMin.value.heading`),
    isRequired: false,
    isVisible: false,
    sortConfig: {
      isEnabled: true,
      name: t('mapping:filters.sorting.autoCloseScoreMin.key'),
      inverseSortIcon: true,
    },
  };

  const caseIdsCell: TableCell = {
    id: 'caseIds',
    align: 'left',
    disablePadding: true,
    isVisible: false,
    label: t('mapping:filters.sorting.caseId.value.heading'),
    sortConfig: {
      isEnabled: false,
      name: t('mapping:filters.sorting.caseId.key'),
    },
  };

  const additionalIdsCell: TableCell = {
    id: 'additionalIds',
    align: 'left',
    disablePadding: true,
    isVisible: false,
    label: t('mapping:filters.sorting.additionalIds.value.heading'),
    sortConfig: {
      isEnabled: false,
      name: t('mapping:filters.sorting.additionalIds.key'),
    },
  };

  const originatorsBankCountriesCell: TableCell = {
    id: 'originatorsBankCountries',
    align: 'left',
    disablePadding: true,
    isVisible: false,
    label: t('mapping:filters.sorting.originatorsBankCountries.value.heading'),
    sortConfig: {
      isEnabled: false,
      name: t('mapping:filters.sorting.originatorsBankCountries.key'),
    },
  };

  const beneficiariesBankCountriesCell: TableCell = {
    id: 'beneficiariesBankCountries',
    align: 'left',
    disablePadding: true,
    isVisible: false,
    label: t('mapping:filters.sorting.beneficiariesBankCountries.value.heading'),
    sortConfig: {
      isEnabled: false,
      name: t('mapping:filters.sorting.beneficiariesBankCountries.key'),
    },
  };

  const headCells: TableCell[] = [
    {
      id: 'cases-tx-type',
      align: 'left',
      disablePadding: false,
      isVisible: true,
      label: t('mapping:filters.sorting.typesString.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.typesString.key'),
      },
    },
    caseIdsCell,
    additionalIdsCell,
    {
      id: 'caseStatus',
      align: 'left',
      disablePadding: false,
      isVisible: true,
      label: t('mapping:filters.sorting.caseStatus.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.caseStatus.key'),
      },
    },
    processingStatusCell,
    {
      id: 'transactionStatus',
      align: 'left',
      disablePadding: false,
      isVisible: true,
      label: t('mapping:filters.sorting.transactionStatus.value.heading'),
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.transactionStatus.key'),
      },
    },
    {
      id: 'risk',
      align: 'left',
      disablePadding: false,
      label: t(`mapping:filters.sorting.risk.value.heading`),
      isRequired: true,
      isVisible: true,
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.risk.key'),
        inverseSortIcon: true,
      },
    },
    customerCell,
    {
      id: 'customerID',
      align: 'left',
      disablePadding: false,
      label: t('mapping:filters.sorting.customerID.value.heading'),
      isVisible: false,
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.customerID.key'),
      },
    },
    {
      id: 'customerGroupID',
      align: 'left',
      disablePadding: false,
      label: t('mapping:filters.sorting.customerGroupID.value.heading'),
      isVisible: false,
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.customerGroupID.key'),
      },
    },
    {
      id: 'orderIds',
      align: 'left',
      disablePadding: false,
      label: t('mapping:filters.sorting.orderIds.value.heading'),
      isVisible: false,
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.orderIds.key'),
      },
    },
    {
      id: 'executionScopes',
      align: 'left',
      disablePadding: false,
      label: t('mapping:filters.sorting.executionScopes.value.heading'),
      isVisible: false,
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.executionScopes.key'),
      },
    },
    {
      id: 'accountNumbers',
      align: 'left',
      disablePadding: false,
      label: t('mapping:filters.sorting.accountNumbers.value.heading'),
      isVisible: false,
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.accountNumbers.key'),
      },
    },
    {
      id: 'transactionCount',
      align: 'right',
      disablePadding: false,
      isVisible: true,
      label: t('mapping:filters.sorting.transactionCount.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.transactionCount.key'),
      },
    },
    {
      id: 'totalAmount',
      align: 'right',
      disablePadding: false,
      isVisible: true,
      label: t('mapping:filters.sorting.totalAmount.value.heading'),
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.totalAmount.key'),
      },
    },
    {
      align: 'left',
      disablePadding: false,
      isVisible: false,
      label: t('mapping:filters.sorting.paymentMeans.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.paymentMeans.key'),
      },
    },
    {
      id: 'flow',
      align: 'center',
      disablePadding: false,
      isVisible: true,
      label: t('mapping:filters.sorting.flow.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.flow.key'),
      },
    },
    {
      align: 'left',
      disablePadding: false,
      isVisible: false,
      label: t('mapping:filters.sorting.createdAt.value.heading'),
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.createdAt.key'),
        inverseSortIcon: true,
      },
    },
    {
      align: 'left',
      disablePadding: false,
      isVisible: false,
      label: t('mapping:filters.sorting.lastProcessedAt.value.heading'),
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.lastProcessedAt.key'),
        inverseSortIcon: true,
      },
    },
    {
      id: 'updatedAt',
      align: 'left',
      disablePadding: false,
      isVisible: true,
      label: t('mapping:filters.sorting.updatedAt.value.heading'),
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.updatedAt.key'),
        inverseSortIcon: true,
      },
    },
    originatorsBankCountriesCell,
    beneficiariesBankCountriesCell,
  ];

  if (isClosedCasesTable) {
    headCells.push({
      id: 'investigationOutcome',
      align: 'left',
      disablePadding: false,
      isVisible: true,
      isRequired: false,
      label: t('mapping:filters.sorting.investigationOutcome.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.investigationOutcome.key'),
      },
    });
  }

  headCells.push({
    id: 'intermediaryName',
    align: 'left',
    disablePadding: false,
    isVisible: false,
    label: t('mapping:filters.sorting.intermediaryName.value.heading'),
    sortConfig: {
      isEnabled: false,
      name: t('mapping:filters.sorting.intermediaryName.key'),
    },
  });

  if (
    canSeeAssignedUser(userPermissions) ||
    caseStatusFilter.key === t('mapping:caseStatusFilters.OPEN.key') ||
    caseStatusFilter.key === t('mapping:caseStatusFilters.CLOSED.key')
  ) {
    headCells.push({
      id: 'assignedToUserId',
      align: 'left',
      disablePadding: false,
      isRequired: true,
      isVisible: true,
      label: t('mapping:filters.sorting.assignedToUserId.value.heading'),
      sortConfig: {
        isEnabled: true,
        name: t('mapping:filters.sorting.assignedToUserId.key'),
      },
    });
  }

  if (isRuleCategoryHeaderVisible) {
    const categoryCell: TableCell = {
      id: 'cases-rule-category',
      align: 'left',
      disablePadding: false,
      isRequired: false,
      isVisible: true,
      label: t('mapping:filters.sorting.ruleCategories.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.ruleCategories.key'),
      },
    };
    headCells.splice(0, 0, categoryCell);
  }

  if (isInvestigationStatusAttached) {
    const investigationStatusCell: TableCell = {
      id: 'cases-investigation-status',
      align: 'left',
      disablePadding: false,
      isRequired: true,
      isVisible: true,
      label: t('mapping:filters.sorting.investigationStatus.value.heading'),
      sortConfig: {
        isEnabled: false,
        name: t('mapping:filters.sorting.investigationStatus.key'),
      },
    };
    const processingStatusIdx = headCells.indexOf(processingStatusCell);
    headCells.splice(processingStatusIdx, 0, investigationStatusCell);
  }
  if (isAnomalyScoreEnabled) {
    const anomalyScoreCellsIdx = headCells.indexOf(customerCell);
    headCells.splice(anomalyScoreCellsIdx, 0, anomalyScoreCell);
  }
  if (isAutoCloseScoreEnabled) {
    const autoCloseScoreCellIdx = headCells.indexOf(customerCell);
    headCells.splice(autoCloseScoreCellIdx, 0, autoCloseScoreCell);
  }

  const tenantNameCell: TableCell = {
    id: 'tenantName',
    align: 'left',
    disablePadding: false,
    isVisible: false,
    label: t('mapping:filters.sorting.tenantId.value.heading'),
    sortConfig: {
      isEnabled: false,
      name: t('mapping:filters.sorting.tenantId.key'),
    },
  };
  // Put tenant name cell config as first column
  headCells.splice(0, 0, tenantNameCell);

  return validateColumnLocalStorage(headCells, LocalStorageService.getTrxCaseListColumnConfig());
};
