import { useTenantConfig } from 'hooks/use-tenant-config';
import { showError } from 'middleware/actions/error';
import ExperimentationApi from 'modules/configuration/experimentation/service/experimentation-api';
import { useQuery } from 'react-query/';
import { useDispatch } from 'react-redux';
import TenantManagementService from 'services/tenant/tenant-management-service';

export const useExecutionStatus = () => {
  const dispatch = useDispatch();
  const experimentationApi = new ExperimentationApi();
  const {
    tenantConfig: { experimentationTenant, tenantId: configTenantId },
  } = useTenantConfig();
  const headerTenantId = TenantManagementService.getActiveTenantId();

  const isTenantConsideredForExecution = () =>
    experimentationTenant && configTenantId === headerTenantId;

  return useQuery({
    queryKey: ['latestExecutionStatus', configTenantId],
    queryFn: () =>
      isTenantConsideredForExecution() ? experimentationApi.executionStatus() : undefined,
    refetchOnWindowFocus: true,
    onError: () => {
      dispatch(
        showError({
          message: 'Failed to fetch latest execution status for experimentation',
        }),
      );
    },
  });
};
