import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { formatMaskedValue } from 'utils/helpers/cases/case-utility';
import { useSelector } from 'react-redux';
import { RootState } from 'middleware/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmpty } from 'lodash';
import {
  IdentityDocument,
  UnmaskedIdentityDocument,
} from 'components/popover/person-details/person-details-tab/documents/identity-document';
import { DocumentDataHeader } from 'components/popover/person-details/person-details-tab/documents/documents-data-header';
import { DocumentDataBody } from 'components/popover/person-details/person-details-tab/documents/documents-data-body';
import { getCountryRiskLevel, getSettingsFromState } from 'utils/helpers/config-utils/config-util';
import { getCountryName } from 'utils/constants/country-list';

interface DocumentsDataProps {
  handleChange?: (backTabLink: string) => void;
  backTabLink: string;
  documents: UnmaskedIdentityDocument[];
}

const DocumentsData: FC<DocumentsDataProps> = ({ handleChange, backTabLink, documents }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid container item alignItems="center">
        {handleChange && (
          <Grid item>
            <IconButtons.Back edge="start" onClick={() => handleChange(backTabLink)} />
          </Grid>
        )}

        <Grid item>
          <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
            {t('case:userPopover.personal.identityDocument.heading')}
          </Box>
        </Grid>
      </Grid>

      <Grid container item flexDirection="column" style={{ paddingBottom: 30 }}>
        {isEmpty(documents) ? (
          <Typography variant="body2" component="div">
            {t('case:userPopover.personal.identityDocument.empty.heading')}
          </Typography>
        ) : (
          documents.map((document, index) => (
            <Accordion
              variant="outlined"
              key={index}
              defaultExpanded={index === 0}
              style={{ maxWidth: '100%' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <DocumentDataHeader document={document} />
              </AccordionSummary>
              <AccordionDetails>
                <DocumentDataBody document={document} />
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Grid>
    </Grid>
  );
};

interface DocumentsDataViewProps {
  accountHoldingPartyData: {
    personalDetails: {
      identityDocumentLinks: IdentityDocument[];
    };
  };
  handleChange: (backTabLink: string) => void;
  backTabLink: string;
  showUnmaskedData: boolean;
  caseConfig: any;
}

/**
 * Documents data for person popover
 * @param {*} props
 */
const DocumentsDataView: FC<DocumentsDataViewProps> = ({
  accountHoldingPartyData,
  handleChange,
  backTabLink,
  showUnmaskedData,
  caseConfig,
}) => {
  const allMasks = useSelector((state: RootState) => state.masks?.all);

  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);
    return settings?.countryRisk;
  });

  const { identityDocumentLinks } = accountHoldingPartyData.personalDetails;
  if (isEmpty(identityDocumentLinks)) {
    return <DocumentsData handleChange={handleChange} backTabLink={backTabLink} documents={[]} />;
  }

  const formatMaskedValueInternal = (fieldObject: unknown) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);

  const documents = identityDocumentLinks?.map((document) => {
    const issuingCountry = getCountryName(document?.issuingCountry as string);
    const issuingCountryRiskLevel = getCountryRiskLevel(issuingCountry, countryRisk);

    return {
      ...document,
      id: formatMaskedValueInternal(document.id),
      link: formatMaskedValueInternal(document.link),
      issuingCountryProperties: {
        value: document?.issuingCountry,
        name: issuingCountry,
        riskLevel: issuingCountryRiskLevel,
      },
    };
  }) as UnmaskedIdentityDocument[];

  return (
    <DocumentsData handleChange={handleChange} backTabLink={backTabLink} documents={documents} />
  );
};

export { DocumentsDataView, DocumentsData };
