import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuditTrail } from 'models/audit-trail/audit-trail';

interface DataProviderAuditTrailState {
  auditTrails: AuditTrail[];
}

interface AuditTrailRequest {
  paging: any;
  sorting: any;
  filtering: any;
  customHeader: any;
  tenantId: string | null;
}

const initialState: DataProviderAuditTrailState = {
  auditTrails: [],
};

export const dataProviderAuditTrailSlide = createSlice({
  name: 'dataProviderAuditTrail',
  initialState,
  reducers: {
    getDataProviderAuditTrail: (state, _action: PayloadAction<AuditTrailRequest>) => state,
    getDataProviderAuditTrailSuccess: (
      state,
      action: PayloadAction<DataProviderAuditTrailState>,
    ) => {
      const auditTrails = action.payload?.auditTrails;
      auditTrails.sort(
        (a1, a2) => new Date(a2.createdAt).getTime() - new Date(a1.createdAt).getTime(),
      );
      const newState = {
        ...state,
        auditTrails,
      };
      return newState;
    },
  },
});

export const { getDataProviderAuditTrail, getDataProviderAuditTrailSuccess } =
  dataProviderAuditTrailSlide.actions;

export default dataProviderAuditTrailSlide.reducer;
