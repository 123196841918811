import { REFERENCE_ACCOUNT_V2_ACTIONS } from 'modules/configuration/rule-manager/reference-account-v2/middleware/action-types';

const referenceAccountV2Reducer = (state = {}, action) => {
  switch (action.type) {
    case REFERENCE_ACCOUNT_V2_ACTIONS.fetch.data:
    case REFERENCE_ACCOUNT_V2_ACTIONS.fetch.error: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case REFERENCE_ACCOUNT_V2_ACTIONS.fetch.success:
    case REFERENCE_ACCOUNT_V2_ACTIONS.update.success:
    case REFERENCE_ACCOUNT_V2_ACTIONS.delete.success: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default referenceAccountV2Reducer;
