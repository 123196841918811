import SingleError from 'components/notifications/errors/single-error';
import useInterval from 'hooks/use-interval';
import { hideError } from 'middleware/actions/error';
import { getErrorsToBeShown } from 'modules/layout/main-view/utils/helpers/error';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TenantManagementService from 'services/tenant/tenant-management-service';
import config from 'utils/helpers/config-utils/config';

// In milliseconds
const DURATION_TO_SHOW_ERROR = 30000;
const DURATION_TO_RETRY = 5000;

const Errors = () => {
  const errors = useSelector((state) => state.error.errors);
  const [delay, setDelay] = useState(1000);
  const dispatch = useDispatch();

  const handleIntervalTasks = () => {
    const errorsInternal = errors || [];
    const now = new Date().getTime();
    errorsInternal
      .filter((error) => error.hide !== true)
      .forEach((error) => {
        // Retry a task
        const retriedDate = new Date(error.retriedDate || error.createdDate);
        let duration = now - retriedDate.getTime();
        if (duration >= DURATION_TO_RETRY) {
          // TODO: It causes repeatly calling API
          // temporary rem this until we find a way to fix
          // this.props.retryError(error);
        }

        // Hide error if exceed a duration
        const createdDate = new Date(error.createdDate);
        duration = now - createdDate.getTime();
        if (duration >= DURATION_TO_SHOW_ERROR) {
          dispatch(hideError(error));
        }
      });
  };

  useInterval(handleIntervalTasks, delay);

  useEffect(() => () => setDelay(undefined), []);

  const errorsToBeShown = getErrorsToBeShown(
    errors,
    TenantManagementService.getActiveTenantId(),
    config.name,
  );

  return errorsToBeShown.map((error, idx) => <SingleError key={idx} error={error} />);
};

export default Errors;
