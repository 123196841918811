import { MCC_LIST_ACTIONS } from 'middleware/action-types';
import {
  activateMccListError,
  activateMccListSuccess,
  getMccFileList,
  getMccFileListError,
  getMccFileListSuccess,
} from 'middleware/actions/mcc-list';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import MccListApiCommunicator from 'services/mcc-list-api-communicator';
import MccListManager from 'services/mcc-list-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { showError } from '../actions/error';

const apiCommunicator = new MccListApiCommunicator();
const mccListManager = new MccListManager(apiCommunicator);

/**
 * Fetch Business purpose files action
 */
export function* getMccFileListBegin(action) {
  try {
    yield put(startProcessing(MCC_LIST_ACTIONS.fileList.fetch));

    const json = yield mccListManager.getMccFileList(TenantManagementService.getActiveTenantId());
    yield put(getMccFileListSuccess(json));

    yield put(stopProcessing(MCC_LIST_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getMccFileListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(MCC_LIST_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch Business purpose files action watcher
 */
export function* getMccFileListWatcher() {
  yield takeLatest(MCC_LIST_ACTIONS.fileList.fetch, getMccFileListBegin);
}

/**
 * Activate fetch Business purpose files watcher
 */
export function* activateMccFileListWatcher() {
  yield takeLatest(MCC_LIST_ACTIONS.fileList.fetch, activateMccFileListWatcher);
}

/**
 *  Update active Business purpose file version action
 */
export function* updateActiveMccListVersionBegin(action) {
  try {
    yield put(startProcessing(MCC_LIST_ACTIONS.activateVersion.send));

    const json = yield mccListManager.updateActiveMccListVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateMccListSuccess(json));
    yield put(getMccFileList(TenantManagementService.getActiveTenantId()));

    yield put(stopProcessing(MCC_LIST_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateMccListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(MCC_LIST_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active Business purpose file version action watcher
 */
export function* updateActiveMccListVersionWatcher() {
  yield takeLatest(MCC_LIST_ACTIONS.activateVersion.send, updateActiveMccListVersionBegin);
}

/**
 *  Activate update active Business purpose file version watcher
 */
export function* activateUpdateActiveMccListVersionWatcher() {
  yield takeLatest(MCC_LIST_ACTIONS.activateVersion.send, activateMccFileListWatcher);
}
