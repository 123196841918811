import { Grid, Typography } from '@mui/material';

const EmptyState = ({ icon, heading, subheading, dense }) => (
  <Grid container className={`${dense ? '' : 'py-5'} px-2`}>
    <Grid container item xs={12} justifyContent="center">
      {icon}
    </Grid>
    {heading && (
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="body2" color="textSecondary">
          {heading}
        </Typography>
      </Grid>
    )}
    {subheading && (
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="caption" className="color-on-surface-disabled">
          {subheading}
        </Typography>
      </Grid>
    )}
  </Grid>
);

export { EmptyState };
