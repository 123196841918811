import { Translation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemText,
  Chip,
} from '@mui/material';
import { AccountData } from 'components/popover/person-details/person-details-tab/account-data';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { PersonChip } from 'components/chips/person-chip';
import { makeSnakeCaseReadable } from 'utils/helpers/string-util';

/**
 * customer relationship data for person popover
 * @param {*} props
 */
const CustomerRelationshipView = ({
  customerData,
  tenantConfig,
  changeTabViewOnClick,
  caseConfig,
}) => {
  const handleStaffData = (staffs = []) => {
    const allStaffs = [];
    staffs?.forEach((staff) => {
      allStaffs.push({
        data: {
          personalDetails: staff.personalDetails,
          contactDetails: staff.contactDetails,
          role: staff.role,
        },
      });
    });
    return allStaffs;
  };
  const staff = handleStaffData(customerData.customerRelationshipDetails.staff);

  return (
    <Translation>
      {(t) => (
        <Grid container spacing={1} className="pb-2">
          <Grid container item alignItems="center">
            <Grid item>
              <IconButtons.Back edge="start" onClick={changeTabViewOnClick} />
            </Grid>

            <Grid item>
              <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                {t('case:userPopover.customer.customerRelationshipDetails.heading')}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              {t('case:userPopover.customer.customerRelationshipDetails.businessUnit.heading')}
            </Typography>
            <Typography variant="body2">
              {customerData.customerRelationshipDetails.businessUnit
                ? customerData.customerRelationshipDetails.businessUnit
                : '-'}
            </Typography>
          </Grid>

          {customerData.customerRelationshipDetails.exemptions && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('case:userPopover.customer.customerRelationshipDetails.exemptions.heading')}
              </Typography>
              <Typography variant="body2">
                {customerData.customerRelationshipDetails.exemptions.length > 0
                  ? customerData.customerRelationshipDetails.exemptions.map((exemption) => (
                      <Chip size="small" label={exemption} />
                    ))
                  : '-'}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid item xs={12} className="my-1 mb-2">
              <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                {t('case:userPopover.customer.customerRelationshipDetails.allAccounts.heading')}
              </Box>
            </Grid>
            {!customerData.customerRelationshipDetails?.allAccounts?.length && (
              <Typography variant="body2">-</Typography>
            )}
            {customerData.customerRelationshipDetails.allAccounts.map((account, index) => (
              <Accordion variant="outlined" key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <ListItemText
                    className="m-0"
                    primary={account?.holderName}
                    secondary={account?.accountNumber}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <AccountData account={account} caseConfig={caseConfig} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs={12} className="my-1 mb-2">
              <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                {t('case:userPopover.customer.customerRelationshipDetails.staff.heading')}
              </Box>
            </Grid>
            <Grid item xs container direction="column" justifyContent="space-between">
              {!customerData.customerRelationshipDetails?.staff?.length && (
                <Typography variant="body2">-</Typography>
              )}
              {staff.map((personDetails, index) => (
                <Grid
                  key={index}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="pb-2"
                >
                  <Grid item xs={6}>
                    <span className="mr-2">
                      <PersonChip
                        chipSize="small"
                        personDetails={personDetails}
                        customerType={customerData.customerType}
                        tenantConfig={tenantConfig}
                        caseConfig={caseConfig}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="caption"
                      component="p"
                      color="textSecondary"
                      className="text-right"
                    >
                      {personDetails?.data?.role
                        ? makeSnakeCaseReadable(personDetails.data.role)
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Translation>
  );
};

export { CustomerRelationshipView };
