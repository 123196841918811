import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import APICommunicator from 'services/api-communicator';

class CustomerDetailsApiCommunicator extends APICommunicator {
  getCustomerDetails(caseId) {
    const apiUrl = API_URLS.customerDetails.get.replaceAll(API_PARAMS.caseId, caseId);
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default CustomerDetailsApiCommunicator;
