import { useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  List,
  ListSubheader,
  ListItem,
} from '@mui/material';
import i18next from 'i18next';
import { Translation, useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import { useSelector } from 'react-redux';

const buildRiskFactorsValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t('mapping:filters.filtering.riskFactors.key'),
  values: filter?.values,
});

const getAllGenericRiskFactorsFromState = (state) => {
  const customerRiskRating = state?.configContainer?.customerRiskRating;
  const individualRiskFactors = customerRiskRating?.individual?.riskFactorsConfig?.instances || [];
  const organizationRiskFactors =
    customerRiskRating?.organization?.riskFactorsConfig?.instances || [];
  return [...individualRiskFactors, ...organizationRiskFactors];
};

const RiskFactorsForm = ({ editForm, onSubmit }) => {
  const { t } = useTranslation();
  const genericRiskFactors = useSelector(getAllGenericRiskFactorsFromState);

  const sections = _.sortBy(
    Object.values(t('mapping:customerRiskRating.sections')),
    (item) => item.value.heading,
  );
  const initialFValues = {};

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`riskFactors[${item}]`] = true;
    });
  }

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newTypes = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newTypes);
    // eslint-disable-next-line no-use-before-define
    validate(newTypes);
  };

  /**
   * validating field form
   * @param newCheckedTypes
   * @returns
   */
  const validate = (newCheckedTypes = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };

    temp.riskFactors = newCheckedTypes.length ? '' : t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: t('mapping:filters.filtering.riskFactors.key'),
        values: checkedValues,
      });
    }
  };

  const getRiskFactorsForSection = (section) => {
    const sectionStaticRiskFactors = Object.values(section.value.editors).map((rf) => ({
      key: rf.scoreName.key,
      label: t(`mapping:customerRiskRating.factors.${rf.scoreName.key}.value.heading`),
      value: values[`riskFactors[${rf.scoreName.key}]`] ?? false,
    }));
    let sectionGenericRiskFactors = genericRiskFactors
      .filter((rf) => rf.section === section.key)
      .map((rf) => ({
        key: rf.title,
        label: rf.title,
        value: values[`riskFactors[${rf.title}]`] ?? false,
      }));
    sectionGenericRiskFactors = _.uniqBy(sectionGenericRiskFactors, 'label');
    return [...sectionStaticRiskFactors, ...sectionGenericRiskFactors];
  };

  return (
    <Grid container item>
      <Form onSubmit={handleSubmit} className="w-100">
        <Grid container item>
          <List dense disablePadding className="max-h-vh-33 overflow-auto w-100" subheader={<li />}>
            {sections.map((section, index) => {
              const riskFactorsForSection = getRiskFactorsForSection(section);
              return (
                <li key={`section-${index}`}>
                  <ul className="pl-3">
                    <ListSubheader disableGutters>{section.value.heading}</ListSubheader>
                    {riskFactorsForSection.map((rf, index) => (
                      <ListItem disablePadding key={`item-${index}`}>
                        <Controls.Checkbox
                          name={`riskFactors[${rf.key}]`}
                          label={rf.label}
                          value={values[`riskFactors[${rf.key}]`] ?? false}
                          onChange={(event) => handleSelectChange(event, rf.key)}
                          size="small"
                        />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              );
            })}
          </List>
        </Grid>
        {errors.riskFactors && (
          <FormHelperText className="pl-3" error>
            {errors.riskFactors}
          </FormHelperText>
        )}
        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
        </Grid>
      </Form>
    </Grid>
  );
};

const RiskFactorsChip = ({ form }) => {
  const genericRiskFactors = useSelector(getAllGenericRiskFactorsFromState);

  return (
    <Translation>
      {(t) => (
        <>
          <Box fontSize="caption.fontSize" color="text.secondary">
            {t('mapping:filters.filtering.riskFactors.value.heading')}
          </Box>
          {form.values.map((item, index) => {
            const label = genericRiskFactors.find((rf) => rf.title === item)
              ? genericRiskFactors.find((rf) => rf.title === item).title
              : t(`mapping:customerRiskRating.factors.${item}.value.heading`);
            return (
              <Chip key={index} variant="outlined" size="small" className="mr-1" label={label} />
            );
          })}
        </>
      )}
    </Translation>
  );
};

export { RiskFactorsForm, RiskFactorsChip, buildRiskFactorsValuesFromUrl };
