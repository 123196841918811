import { findMask } from 'utils/helpers/cases/case-utility';
import { Mask } from 'models/mask';

export const getFieldValue = (
  field: string | Mask,
  caseDetailDataShown: boolean,
  allMasks?: Mask[],
) => {
  let fieldValue;

  if (typeof field === 'string') {
    fieldValue = { masked: field, unmasked: field };
  } else {
    fieldValue = findMask(allMasks, field);
  }
  return caseDetailDataShown ? fieldValue.unmasked : fieldValue.masked;
};
