import { PASSTHROUGH_V2_ACTIONS } from 'modules/configuration/rule-manager/passthrough-v2/middleware/action-types';

export const getPassthroughV2Config = () => ({
  type: PASSTHROUGH_V2_ACTIONS.fetch.data,
});

export const getPassthroughV2ConfigSuccess = (payload) => ({
  type: PASSTHROUGH_V2_ACTIONS.fetch.success,
  payload,
});

export const updatePassthroughV2Config = (payload) => ({
  type: PASSTHROUGH_V2_ACTIONS.update.data,
  payload,
});

export const updatePassthroughV2ConfigSuccess = (payload) => ({
  type: PASSTHROUGH_V2_ACTIONS.update.success,
  payload,
});

export const deletePassthroughV2Config = (payload) => ({
  type: PASSTHROUGH_V2_ACTIONS.delete.send,
  payload,
});

export const deletePassthroughV2ConfigSuccess = (payload) => ({
  type: PASSTHROUGH_V2_ACTIONS.delete.success,
  payload,
});
