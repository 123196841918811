import { ConfluenceActions } from 'middleware/action-types';

export const getConfluenceArticle = (articleId) => ({
  type: ConfluenceActions.CONFLUENCE_ARTICLE_GET,
  articleId,
});

export const getConfluenceArticleSuccess = (payload) => ({
  type: ConfluenceActions.CONFLUENCE_ARTICLE_GET_SUCCESS,
  payload,
});

export const getConfluenceArticleError = () => ({
  type: ConfluenceActions.CONFLUENCE_ARTICLE_GET_ERROR,
});
