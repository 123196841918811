import { SETTINGS_ACTIONS } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import {
  deleteSettingsCountryRisksError,
  deleteSettingsCountryRisksSuccess,
  getSettingsError,
  getSettingsSuccess,
  updateSettingsCountryRisksError,
  updateSettingsCountryRisksSuccess,
  updateSettingsError,
  updateSettingsSuccess,
} from 'middleware/actions/settings';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import SettingsApiCommunicator from 'services/settings-api-communicator';
import SettingsManager from 'services/settings-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { FEATURE_STATUS } from 'utils/constants/constant';
import { getSettingsFromState } from 'utils/helpers/config-utils/config-util';
import { isEmptyObject } from 'utils/helpers/examination-util';
import { getPepSanctionConfig } from 'middleware/actions/sanction-list';

const apiCommunicator = new SettingsApiCommunicator();
const settingsManager = new SettingsManager(apiCommunicator);

/**
 * Fetch Settings
 */
export function* getSettingsBegin(action) {
  try {
    yield put(startProcessing(SETTINGS_ACTIONS.get.data));
    const payload = action?.payload;
    const tenantId = payload?.tenantId ?? TenantManagementService.getActiveTenantId();
    const configVersion = payload?.configVersion;
    const configVersionKey = configVersion ?? FEATURE_STATUS.config.active;
    let json = yield select((state) => getSettingsFromState(state, tenantId, configVersionKey));
    if (!json || isEmptyObject(json)) {
      json = yield settingsManager.getSettings(tenantId, configVersion);
    }
    yield put(getSettingsSuccess({ tenantId, configVersionKey, json }));
  } catch (error) {
    yield put(getSettingsError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SETTINGS_ACTIONS.get.data));
}

export function* getSettingsWatcher() {
  yield takeEvery(SETTINGS_ACTIONS.get.data, getSettingsBegin);
}

export function* updateSettingsBegin(action) {
  try {
    yield put(startProcessing(SETTINGS_ACTIONS.update.data));
    const tenantId = TenantManagementService.getActiveTenantId();
    const json = yield settingsManager.updateSettings(tenantId, action.payload);
    const configVersionKey = FEATURE_STATUS.config.active;
    yield put(getPepSanctionConfig());
    yield put(yield put(updateSettingsSuccess({ tenantId, configVersionKey, json })));
  } catch (error) {
    yield put(updateSettingsError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SETTINGS_ACTIONS.update.data));
}

export function* updateSettingsWatcher() {
  yield takeLatest(SETTINGS_ACTIONS.update.data, updateSettingsBegin);
}

export function* updateSettingsCountryRisksBegin(action) {
  try {
    yield put(startProcessing(SETTINGS_ACTIONS.countryRisks.update.data));
    const tenantId = TenantManagementService.getActiveTenantId();
    const json = yield settingsManager.updateSettingsContryRisks(tenantId, action.payload);
    const configVersionKey = FEATURE_STATUS.config.active;
    yield put(updateSettingsCountryRisksSuccess({ tenantId, configVersionKey, json }));
  } catch (error) {
    yield put(updateSettingsCountryRisksError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(SETTINGS_ACTIONS.countryRisks.update.data));
}

export function* updateSettingsCountryRisksWatcher() {
  yield takeLatest(SETTINGS_ACTIONS.countryRisks.update.data, updateSettingsCountryRisksBegin);
}

export function* deleteSettingsCountryRisksBegin(action) {
  try {
    yield put(startProcessing(SETTINGS_ACTIONS.countryRisks.delete.data));
    const tenantId = TenantManagementService.getActiveTenantId();
    const json = yield settingsManager.deleteSettingsContryRisks(tenantId, action.payload);
    const configVersionKey = FEATURE_STATUS.config.active;
    yield put(deleteSettingsCountryRisksSuccess({ tenantId, configVersionKey, json }));
  } catch (error) {
    yield put(deleteSettingsCountryRisksError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(SETTINGS_ACTIONS.countryRisks.delete.data));
}

export function* deleteSettingsCountryRisksWatcher() {
  yield takeLatest(SETTINGS_ACTIONS.countryRisks.delete.data, deleteSettingsCountryRisksBegin);
}
