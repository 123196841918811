import {
  SanctionListActions,
  PepSanctionConfigActions,
  PEP_SANCTION_CONFIG_ACTIONS,
} from 'middleware/action-types';

import { CUSTOM_LIST_CODE } from 'utils/constants/country-list';

const sanctionListReducer = (state = { instances: [] }, action) => {
  switch (action.type) {
    case SanctionListActions.GET_SANCTION_LIST: {
      if (action.page === 0) {
        return {
          ...state,
          referenceNames: [],
        };
      }
      return state;
    }

    case SanctionListActions.GET_SANCTION_LIST_SUCCESS: {
      const referenceNames = [...state.referenceNames, ...action.payload.content];
      return {
        ...state,
        referenceNames,
        currentPage: action.payload.pageable.pageNumber,
        lastPage: action.payload.last,
        timestamp: new Date().getTime(),
      };
    }

    case SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE_SUCCESS: {
      const lastUpdate = action.payload;

      // prefer provider in pep-sanction config
      const provider = state.provider ? state.provider : lastUpdate?.provider;

      return {
        ...state,
        lastUpdate,
        provider,
        timestamp: new Date().getTime(),
      };
    }

    case PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG: {
      return {
        ...state,
        blockTransactionOnSanctionHit: false,
        onlyUseCurrentListsAndIgnoreSuspended: false,
        immediateDecision: false,
        sanctionedEntityTypes: [],
        lists: [],
        preparationSteps: [],
        matchingScoreThreshold: undefined,
        namesToCheckPepSanction: [],
        userId: undefined,
        updatedAt: undefined,
      };
    }

    case PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG_SUCCESS:
    case PEP_SANCTION_CONFIG_ACTIONS.update.success: {
      const { payload } = action;
      const customList = payload?.customList ?? {};
      customList.type = CUSTOM_LIST_CODE;

      // prefer provider in pep-sanction config
      const provider = payload?.provider ? payload?.provider : state.provider;
      return {
        ...state,
        provider,
        blockTransactionOnSanctionHit: payload?.blockTransactionOnSanctionHit,
        onlyUseCurrentListsAndIgnoreSuspended: payload?.onlyUseCurrentListsAndIgnoreSuspended,
        immediateDecision: payload?.immediateDecision,
        sanctionedEntityTypes: payload?.sanctionedEntityTypes,
        selectedLists: payload?.lists,
        customList,
        preparationSteps: payload?.preparationSteps,
        regionCurrencies: payload?.regionCurrencies,
        matchingScoreThreshold: payload?.matchingScoreThreshold,
        namesToCheckPepSanction: payload?.namesToCheckPepSanction,
        timestamp: new Date().getTime(),
        userId: payload?.userId,
        updatedAt: payload?.updatedAt,
      };
    }

    case PEP_SANCTION_CONFIG_ACTIONS.updateInstanciableProvider.success:
    case PEP_SANCTION_CONFIG_ACTIONS.instanciable.success: {
      const { payload } = action;

      return {
        ...state,
        lastUpdateInstances: {
          date: payload?.updatedAt,
          userId: payload?.userId,
        },
        instances: payload?.instances,
      };
    }

    case PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.success:
    case PEP_SANCTION_CONFIG_ACTIONS.deleteInstanciable.success: {
      const { payload } = action;

      return {
        ...state,
        lastUpdateInstances: { date: payload?.updatedAt, userId: payload?.userId },
        instances: payload?.instances,
      };
    }

    case PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS: {
      return {
        ...state,
        lists: [],
      };
    }

    case PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS_SUCCESS: {
      return {
        ...state,
        lists: action.payload,
        timestamp: new Date().getTime(),
      };
    }

    case PepSanctionConfigActions.GET_REGION_SANCTION_LISTS: {
      return {
        ...state,
        selectedRegion: action.region,
      };
    }

    case PepSanctionConfigActions.GET_REGION_SANCTION_LISTS_SUCCESS: {
      const selectedRegionList = state[state.selectedRegion] || [];
      const newRegionList = selectedRegionList.concat(action.payload.content || []);
      const sanctionList = state.sanctionList || {};
      const lastSanctionLists = sanctionList[`${state.selectedRegion}_last_update`] || [];

      newRegionList.forEach((item) => {
        const existed =
          lastSanctionLists.find((i) => i.code === item.code || i.name === item.name) !== undefined;
        if (!existed) {
          lastSanctionLists.push(item);
        }
      });

      return {
        ...state,
        sanctionListsTimestamp: new Date().getTime(),
        regionSanctionLists: action.payload.content,
        currentPage: action.payload.pageable.pageNumber,
        lastPage: action.payload.last,
        totalElements: action.payload.totalElements,
        [state.selectedRegion]: lastSanctionLists,
        [`${state.selectedRegion}_last_update`]: lastSanctionLists,
      };
    }

    default:
      return state;
  }
};

export default sanctionListReducer;
