import { CountryRiskConfigActions } from 'modules/configuration/rule-manager/country-config/middleware/action-types';

const countryRiskConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET:
    case CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CountryRiskConfigActions.COUNTRY_RISK_CONFIG_GET_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default countryRiskConfigReducer;
