import SessionService from 'modules/authentication/login/services/session-service';
import { logMessageInBackend } from 'services/api/logger/logger';

const auth = SessionService.getInstance();

const retries = 2;

// retry on 408 (timeout), 429 (too many requests)
const retryOnClientErrorHttpStatus = [408, 429];

const shouldRetryOnHttpStatus = (status) =>
  Math.floor(status / 100) !== 4 || retryOnClientErrorHttpStatus.includes(status);

const retryOn = (attempt, error, response) => {
  // do not retry on almost 4xx client error
  if (response?.status && !shouldRetryOnHttpStatus(response?.status)) {
    return false;
  }

  let message;
  if (error) {
    message = `${error.toString()}, retrying ${attempt + 1}`;
  } else if (!response) {
    message = `No response, retrying ${attempt + 1}`;
  } else if (!response.status) {
    message = `No response status for ${response.url}, retrying ${attempt + 1}`;
  } else if (response.status >= 300) {
    message = `Response status ${response.status}(${response.statusText}), retrying ${attempt + 1}`;
  } else if (response.headers.get('Content-Length') === 0) {
    message = `No response content, status ${response.status}, retrying ${attempt + 1}`;
  }
  if (message && !!response) {
    if (response.headers) {
      message += `. Headers: ${JSON.stringify(response.headers)}`;
    }
    if (response.url) {
      message += `. Url: ${response.url}`;
    }
    message += `. User: ${auth.getUsername()}`;
    logMessageInBackend(message, 'warn');
    return attempt < retries;
  }
  return false;
};

export { retryOn };
