import { useState } from 'react';

const useForm = (initialFValues, validateOnChange = false, validate) => {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  const clearForm = () => {
    setValues({});
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    clearForm,
    validate,
  };
};

// eslint-disable-next-line react/jsx-props-no-spreading
const Form = ({ children, ...other }) => <form {...other}>{children}</form>;

export { useForm, Form };
