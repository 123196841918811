import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type CurrentRuleReducerState = {
  editingRuleKey: string;
};

const initialState: CurrentRuleReducerState = {
  editingRuleKey: '',
};

const currentRuleReducer = createSlice({
  name: 'currentRule',
  initialState,
  reducers: {
    setEditingRuleKey: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        editingRuleKey: action.payload,
      };
      return newState;
    },
    clearEditingRuleKey: (state) => {
      const newState = {
        ...state,
        editingRuleKey: '',
      };
      return newState;
    },
  },
});

export const { setEditingRuleKey, clearEditingRuleKey } = currentRuleReducer.actions;

export default currentRuleReducer.reducer;
