import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class FiltersListApi extends APICommunicator {
  fetchFiltersInstantiableListConfig(tenantId) {
    const apiUrl = API_URLS.listManager.filters.fetch.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.FILTERS_LIST_DATA_NOT_FOUND, tenantId)
      .then((json) => Promise.resolve(json))
      .catch(() => raiseErrorByCode(ErrorCode.FILTERS_LIST_DATA_INVALID));
  }

  updateFiltersInstantiableListConfig(data) {
    const apiUrl = API_URLS.listManager.filters.upsert.replace(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.postJSONForTenant(
      apiUrl,
      { instances: data },
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  deleteFiltersInstantiableListConfig(id) {
    const apiUrl = API_URLS.listManager.filters.delete
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId())
      .replace(API_PARAMS.id, id);

    return this.deleteForCustomTenant(
      apiUrl,
      TenantManagementService.getActiveTenantId(),
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateActiveFiltersInstantiableListConfigVersion(tenantId, version) {
    const apiUrl = API_URLS.listManager.filters.updateActiveVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default FiltersListApi;
