import { Tooltip, Grid, Box } from '@mui/material';
import { Translation } from 'react-i18next';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { getRiskClassName } from 'utils/helpers/cases/case-utility';
import { AccountCircle, Help, Work } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ReplayIcon from '@mui/icons-material/Replay';
/**
 * chip to open person details
 * @param {*} props
 */
const InitiatorIcon = ({ color }) => (
  <Translation>
    {(t) => (
      <>
        <Tooltip title={t('case:caseDetail.transaction.initiator.heading')}>
          <TouchAppIcon color={color || 'action'} />
        </Tooltip>
      </>
    )}
  </Translation>
);

const RescreeningIcon = ({ color, fontSize, className }) => (
  <Translation>
    {(t) => (
      <>
        <Tooltip title={t(`rescreening.tooltip.heading`)}>
          <ReplayIcon color={color || 'action'} className={className} fontSize={fontSize} />
        </Tooltip>
      </>
    )}
  </Translation>
);

const KeyboardArrowIcon = ({ color = 'action' }) => (
  <Translation>
    {(t) => (
      <Grid container item justifyContent="center" alignItems="center" direction="column">
        <Grid item className="mb-n2">
          <KeyboardArrowUpIcon color={color} className="icon-10 border" />
        </Grid>
        <Grid item>
          <KeyboardArrowLeftIcon color={color} className="icon-10 border" />
          <KeyboardArrowDownIcon color={color} className="icon-10 border" />
          <KeyboardArrowRightIcon color={color} className="icon-10 border" />
        </Grid>
        <Grid
          item
          className={`${color === 'white' ? 'color-white' : 'color-on-surface-secondary'}`}
        >
          <Box fontSize={12}>{t('keyboard.heading')}</Box>
        </Grid>
      </Grid>
    )}
  </Translation>
);

const getAccountIcon = (isCustomer, isCompany, riskLevel, size) => {
  if (isCustomer) {
    if (isCompany) {
      return <Work fontSize={size} className={`${getRiskClassName(riskLevel)}`} />;
    }

    return <AccountCircle fontSize={size} className={`${getRiskClassName(riskLevel)}`} />;
  }
  return <Help fontSize={size} className={`${getRiskClassName(riskLevel)}`} />;
};

export { InitiatorIcon, getAccountIcon, KeyboardArrowIcon, RescreeningIcon };
