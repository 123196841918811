import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, Tab, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { buildRoute } from 'utils/helpers/cases/case-route-utility';

import { myCasesRoute } from 'utils/constants/routes';
import { getCustomViewsPath } from 'modules/trx-cases/case-list/utils/helpers/custom-views-path';

/**
 * Showing tabs for popover
 * @param {*} props
 */
const CaseNavigator = ({ caseTypes = [], search, children }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedTabKey, setSelectedTab] = useState(location.pathname);

  const handleChange = (event, newRoute) => {
    if (newRoute === getCustomViewsPath()) {
      return;
    }
    setSelectedTab(newRoute);
    history.push({
      pathname: newRoute,
      search: search ?? location.search,
    });
  };

  return (
    <Tabs
      className="pt-2 pb-3 min-h-0"
      value={selectedTabKey}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        className: 'd-none',
      }}
    >
      {caseTypes?.map((caseType, idx) => {
        const shortName = t([
          `routeNavigation.${caseType.key}.subheading`,
          `routeNavigation.${caseType.key}.heading`,
        ]);
        return (
          <Tab
            key={idx}
            disableRipple
            value={buildRoute(caseType.path)}
            className={`px-2 py-0 min-w-0 min-h-0 ${
              myCasesRoute.key === caseType.key ? 'border-right' : ''
            }`}
            label={
              <Chip
                label={shortName}
                color={buildRoute(caseType.path) === selectedTabKey ? 'primary' : 'default'}
                clickable
              />
            }
          />
        );
      })}
      {children}
    </Tabs>
  );
};

export default CaseNavigator;
