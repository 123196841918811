import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { showError } from 'middleware/actions/error';
import { getListForRiskFactorSuccess } from 'modules/configuration/customer-risk-rating/middleware/action-types';
import { put, takeEvery } from 'redux-saga/effects';
import RiskLevelMapApi from 'services/risk-level-map/risk-level-map-api';

const communicator = new RiskLevelMapApi();

function* getListForRiskFactorBegin(action) {
  yield put(startProcessing('riskFactorRiskLevelMapList/getListForRiskFactor'));
  try {
    const json = yield communicator.fetchMetadata(action.payload);
    yield put(
      getListForRiskFactorSuccess({
        riskFactorInstanceId: action.payload.riskFactorInstanceId,
        metadata: json,
      }),
    );
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('riskFactorRiskLevelMapList/getListForRiskFactor'));
}

export function* getListForRiskFactorWatcher() {
  yield takeEvery('riskFactorRiskLevelMapList/getListForRiskFactor', getListForRiskFactorBegin);
}
