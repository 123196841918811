export const FlaggingConfigActions = {
  FLAGGING_CONFIG_GET: 'FLAGGING_CONFIG_GET',
  FLAGGING_CONFIG_GET_SUCCESS: 'FLAGGING_CONFIG_GET_SUCCESS',
  FLAGGING_CONFIG_GET_ERROR: 'FLAGGING_CONFIG_GET_ERROR',
  FLAGGING_CONFIG_UPDATE: 'FLAGGING_CONFIG_UPDATE',
  FLAGGING_CONFIG_UPDATE_SUCCESS: 'FLAGGING_CONFIG_UPDATE_SUCCESS',
  FLAGGING_CONFIG_UPDATE_ERROR: 'FLAGGING_CONFIG_UPDATE_ERROR',
  FLAGGING_CONFIG_DELETE: 'FLAGGING_CONFIG_DELETE',
  FLAGGING_CONFIG_DELETE_SUCCESS: 'FLAGGING_CONFIG_DELETE_SUCCESS',
  FLAGGING_CONFIG_DELETE_ERROR: 'FLAGGING_CONFIG_DELETE_ERROR',
};
