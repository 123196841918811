import { useState } from 'react';
import { Box, Chip, Divider, FormHelperText, Grid } from '@mui/material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';

const buildInvestigationStatusValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
  field: i18next.t('mapping:filters.filtering.investigationStatuses.key'),
  values: filter?.values,
});

const InvestigationStatusForm = ({ investigationStatuses, editForm, onSubmit }) => {
  const { t } = useTranslation();

  const initialFValues = {};

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`investigationStatuses[${item}]`] = true;
    });
  }

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newTypes = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newTypes);
    // eslint-disable-next-line no-use-before-define
    validate(newTypes);
  };

  /**
   * validating field form
   * @param newCheckedTypes
   * @returns
   */
  const validate = (newCheckedTypes = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };

    temp.caseTypes = newCheckedTypes.length ? '' : t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.collection.COLLECTION_ANY_OF.key'),
        field: t('mapping:filters.filtering.investigationStatuses.key'),
        values: checkedValues,
      });
    }
  };

  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
          {investigationStatuses.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Controls.Checkbox
                name={`investigationStatuses[${item.key}]`}
                label={
                  <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>{item.value.heading}</Grid>
                  </Grid>
                }
                value={values[`investigationStatuses[${item.key}]`] ?? false}
                onChange={(event) => handleSelectChange(event, item.key)}
                size="small"
              />
            </Grid>
          ))}
        </Grid>
        {errors.investigationStatuses && (
          <FormHelperText className="pl-3" error>
            {errors.investigationStatuses}
          </FormHelperText>
        )}
        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
        </Grid>
      </Form>
    </Grid>
  );
};

const InvestigationStatusFilterChip = ({ form }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t('mapping:filters.filtering.investigationStatuses.value.heading')}
      </Box>
      {form.values.map((item, index) => (
        <Chip
          key={index}
          variant="outlined"
          size="small"
          className="mr-1"
          label={t(`mapping:investigationStatus.${item}.value.heading`)}
        />
      ))}
    </>
  );
};

export {
  InvestigationStatusForm,
  InvestigationStatusFilterChip,
  buildInvestigationStatusValuesFromUrl,
};
