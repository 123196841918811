const SORT_DIRECTION = {
  desc: 'DESC',
  asc: 'ASC',
};

const DIRECTION = {
  desc: 'desc' as const,
  asc: 'asc' as const,
};

export { SORT_DIRECTION, DIRECTION };
