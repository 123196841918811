import { TENANT_CONFIG_ACTIONS } from 'modules/configuration/workflows-and-users/middleware/action-types';
import {
  getCaseSearchDateForTenantId,
  getGroupSearchDateForTenantId,
} from 'utils/helpers/config-utils/config';
import { USER_MANAGEMENT_ACTIONS } from 'modules/configuration/workflows-and-users/components/user-management/middleware/action-types';

const tenantConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET_SUCCESS:
    case TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT_SUCCESS:
    case TENANT_CONFIG_ACTIONS.callbackConfig.success: {
      return {
        ...state,
        ...action.payload,
        groupSearchDate: getGroupSearchDateForTenantId(action.payload.tenantId),
        caseSearchDate: getCaseSearchDateForTenantId(action.payload.tenantId),
        timestamp: new Date().getTime(),
      };
    }

    case USER_MANAGEMENT_ACTIONS.userManagementConfig.success:
    case TENANT_CONFIG_ACTIONS.scorechainConfig.success: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case TENANT_CONFIG_ACTIONS.callbackConfigSecret.success: {
      return {
        ...state,
        callbackConfig: {
          ...(state.callbackConfig || {}),
          secret: {
            ...(state.callbackConfig?.secret || {}),
            decrypted: action.payload.secret,
            isNull: !action.payload.secret,
          },
        },
      };
    }

    case TENANT_CONFIG_ACTIONS.scorechainConfigSecret.success: {
      return {
        ...state,
        scorechainConfig: {
          ...(state.scorechainConfig || {}),
          secret: {
            ...(state.scorechainConfig?.secret || {}),
            decrypted: action.payload.secret,
          },
        },
      };
    }

    case TENANT_CONFIG_ACTIONS.createMasterTenant.success:
    case TENANT_CONFIG_ACTIONS.TENANT_ADD_SUCCESS: {
      return {
        ...state,
        newTenant: action.payload,
      };
    }

    case TENANT_CONFIG_ACTIONS.TENANT_REMOVE_LAST: {
      return {
        ...state,
        newTenant: {},
      };
    }

    case TENANT_CONFIG_ACTIONS.USERS_GET_SUCCESS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case TENANT_CONFIG_ACTIONS.ALL_TENANTS_GET_SUCCESS: {
      return {
        ...state,
        allTenants: action.payload,
      };
    }

    case TENANT_CONFIG_ACTIONS.USERS_NOT_IN_TENANT_SUCCESS: {
      return {
        ...state,
        otherUsers: action.payload,
      };
    }

    case TENANT_CONFIG_ACTIONS.masterTenants.success: {
      return {
        ...state,
        masterTenants: action.payload,
      };
    }

    case TENANT_CONFIG_ACTIONS.tenantStructure.success:
    case TENANT_CONFIG_ACTIONS.tenantStructure.error: {
      return {
        ...state,
        tenantStructureUpdateResponse: action.payload,
      };
    }

    case TENANT_CONFIG_ACTIONS.tenantStructure.clear:
      return {
        ...state,
        tenantStructureUpdateResponse: {},
      };

    default:
      return state;
  }
};

export default tenantConfigReducer;
