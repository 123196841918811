/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  decryptWebLinks,
  formatMaskedArray,
  formatMaskedValue,
  hasAddress,
} from 'utils/helpers/cases/case-utility';
import { Grid, Typography } from '@mui/material';
import { getCountryName } from 'utils/constants/country-list';
import { RiskChip } from 'components/chips/risk-chip';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { getCountryRiskLevel, getSettingsFromState } from 'utils/helpers/config-utils/config-util';
import { RootState } from 'middleware/store';
import { WebLink } from 'modules/trx-cases/case-detail/components/weblinks/model/weblink';
import { WebLinkList } from 'modules/trx-cases/case-detail/components/weblinks/weblink-list';

interface ContactDataProps {
  handleChange?: (backTabLink: string) => void;
  backTabLink?: string;
  contactData: any;
}

const ContactData: FC<ContactDataProps> = ({ handleChange, backTabLink = '', contactData }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={1}>
        <Grid container item alignItems="center">
          {handleChange && (
            <Grid item>
              <IconButtons.Back edge="start" onClick={() => handleChange(backTabLink)} />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('name.heading')}
          </Typography>
          <Typography variant="body2" component="span">
            {contactData.name || contactData.extendedName ? (
              <>
                <Typography variant="body2">{contactData.name}</Typography>
                <Typography variant="body2">{contactData.extendedName}</Typography>
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('email.heading')}
          </Typography>

          {contactData.emailAddress?.length
            ? contactData.emailAddress?.map((product: any, index: number) => (
                <Typography key={index} variant="body2">
                  {' '}
                  {product}
                </Typography>
              ))
            : '-'}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('phoneNumber.heading')}
          </Typography>
          {contactData.phoneNumber?.length
            ? contactData.phoneNumber?.map((product: any, index: number) => (
                <Typography key={index} variant="body2">
                  {' '}
                  {product}
                </Typography>
              ))
            : '-'}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('address.heading')}
          </Typography>

          {hasAddress(contactData.address) ? (
            <>
              <Typography variant="body2">
                {contactData.address?.street ? `${contactData.address?.street} ` : ''}
                {contactData.address?.streetExtended
                  ? `${contactData.address?.streetExtended} `
                  : ''}
                {contactData.address?.unstructured ? `${contactData.address?.unstructured} ` : ''}
              </Typography>

              <Typography variant="body2" component="span">
                {contactData.address?.zip ? `${contactData.address?.zip} ` : ''}
                {contactData.address?.town ? `${contactData.address?.town} ` : ''}
                {contactData.address?.region ? `${contactData.address?.region} ` : ''}
                {contactData.address?.country?.value ? (
                  <RiskChip
                    size="small"
                    risk={contactData.address.country.riskLevel}
                    label={contactData.address.country.name}
                  />
                ) : (
                  ''
                )}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Grid>
        <Grid item xs={12}>
          {contactData.webLinksV2.length ? (
            <WebLinkList webLinks={contactData.webLinksV2} emptyView="-" />
          ) : (
            <WebLinkList webLinks={contactData.webLinks} emptyView="-" />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {t('case:userPopover.personal.contactInfo.relatedCustomerId.heading')}
          </Typography>
          {contactData.relatedCustomerId?.length
            ? contactData.relatedCustomerId?.map((product: any, index: number) => (
                <Typography key={index} variant="body2">
                  {' '}
                  {product}
                </Typography>
              ))
            : '-'}
        </Grid>
      </Grid>
    </>
  );
};

interface ContactInfoViewProps {
  personDetails: any;
  handleChange?: (backTabLink: string) => void;
  backTabLink?: string;
  caseConfig: { tenantId: string; configVersion: number };
  showUnmaskedData: boolean;
}

/**
 * contact info for person popover
 * @param {*} props
 */
const ContactInfoView: FC<ContactInfoViewProps> = ({
  personDetails,
  handleChange,
  backTabLink = '',
  caseConfig,
  showUnmaskedData,
}) => {
  const allMasks = useSelector((state: RootState) => state.masks?.all);
  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);
    return settings?.countryRisk;
  });
  const formatMaskedValueInternal = (fieldObject: any) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);
  const formatMaskedArrayInternal = (dataArray: any[]) =>
    formatMaskedArray(showUnmaskedData, allMasks, dataArray);
  const decryptWebLinksInternal = (webLinks: any[]) =>
    decryptWebLinks(showUnmaskedData, allMasks, webLinks);

  const convertWebLinks = (webLinks: string[]) => {
    const convertedWebLinks: WebLink[] = [];
    webLinks?.forEach((webLink) => {
      convertedWebLinks.push({
        title: formatMaskedValueInternal(webLink),
        url: formatMaskedValueInternal(webLink),
      });
    });
    return convertedWebLinks;
  };

  const partyData = personDetails?.parties
    ? {
        ...personDetails?.parties[0],
        calculatedRiskLevel: personDetails?.calculatedRiskLevel,
        transaction: personDetails?.transaction,
        underwriterData: personDetails?.underwriterData,
      }
    : personDetails;

  const countryValue = formatMaskedValueInternal(partyData.contactDetails?.country);

  const contactData = {
    name: formatMaskedValueInternal(partyData.contactDetails?.contactName),
    extendedName: formatMaskedValueInternal(partyData.contactDetails?.contactNameExtended),
    contactId: formatMaskedValueInternal(partyData.contactDetails?.contactId),
    emailAddress: formatMaskedArrayInternal(partyData.contactDetails?.emailAddresses),
    phoneNumber: formatMaskedArrayInternal(partyData.contactDetails?.phoneNumbers),
    webLinks: convertWebLinks(formatMaskedArrayInternal(partyData.contactDetails?.webLinks)), // deprecated
    webLinksV2: decryptWebLinksInternal(partyData.contactDetails?.webLinksV2) as WebLink[],
    addressValidSince: partyData.contactDetails?.addressValidSince,
    relatedCustomerId: formatMaskedArrayInternal(partyData.contactDetails?.allAssignedCustomerIds),
    address: {
      street: formatMaskedValueInternal(partyData.contactDetails?.street),
      streetExtended: formatMaskedValueInternal(partyData.contactDetails?.streetExtended),
      town: formatMaskedValueInternal(partyData.contactDetails?.town),
      region: formatMaskedValueInternal(partyData.contactDetails?.region),
      zip: formatMaskedValueInternal(partyData.contactDetails?.zip),
      country: {
        value: countryValue,
        name: getCountryName(countryValue),
        riskLevel: getCountryRiskLevel(countryValue, countryRisk),
      },
      unstructured: formatMaskedValueInternal(partyData.contactDetails?.unstructuredAddress),
    },
  };

  return (
    <ContactData handleChange={handleChange} backTabLink={backTabLink} contactData={contactData} />
  );
};

export { ContactData, ContactInfoView };
