import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

interface MultiTextFilterChipProps {
  form: {
    values: string[];
  };
  filterName: string;
}

export const MultiTextFilterChip: FC<MultiTextFilterChipProps> = ({ form, filterName }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box fontSize="caption.fontSize" color="text.secondary">
        {t(`mapping:filters.filtering.${filterName}.value.heading`)}
      </Box>
      {form.values.map((item, index) => (
        <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
      ))}
    </>
  );
};
