import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class CustomerListApiCommunicator extends APICommunicator {
  fetchAll({ paging, sorting, filtering, allMaskedDataShown }) {
    const apiUrl = API_URLS.customerList.fetchAll;

    const payload = {
      paging,
      sorting,
      filtering,
      allMaskedDataShown,
    };
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default CustomerListApiCommunicator;
