import { GOAML_ACTIONS } from 'middleware/action-types';

const goAMLReducer = (state = {}, action) => {
  switch (action.type) {
    case GOAML_ACTIONS.getXML.data:
      return {
        ...state,
        getXML: {
          xml: '',
          errorMessage: '',
        },
      };

    case GOAML_ACTIONS.getXML.success:
      return {
        ...state,
        getXML: {
          xml: action.payload?.data,
          errorMessage: action.payload?.errorMessage,
        },
      };

    case GOAML_ACTIONS.saveXML.data:
      return {
        ...state,
        saveXML: {
          successMessage: '',
          errorMessage: '',
        },
      };

    case GOAML_ACTIONS.saveXML.success:
      return {
        ...state,
        saveXML: {
          successMessage: action.payload?.successMessage,
          errorMessage: action.payload?.errorMessage,
        },
      };

    case GOAML_ACTIONS.validateXML.data:
      return {
        ...state,
        validateXML: {
          errorMessage: '',
        },
      };

    case GOAML_ACTIONS.validateXML.success:
      return {
        ...state,
        validateXML: {
          errorMessage: action.payload?.errorMessage,
        },
      };

    case GOAML_ACTIONS.clearCache.data:
      return {
        ...state,
        getXML: {
          xml: '',
          errorMessage: '',
        },
      };

    default:
      return state;
  }
};

export default goAMLReducer;
