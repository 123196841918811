import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';

export const assessPepSanction = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.pepSanction.assess.data,
  payload,
});

export const assessPepSanctionSuccess = (payload) => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.pepSanction.assess.success,
  payload,
});

export const assessPepSanctionError = () => ({
  type: CUSTOMER_CASE_DETAIL_ACTIONS.pepSanction.assess.error,
});
