import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class CustomerListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Customer List API Communicator instance is required.');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  validateAllCasesJson(json) {
    const valid = json && Array.isArray(json.customers);
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  queryAll(params) {
    params.filtering.filters.forEach((filter) => {
      if (filter.field === 'customerName') {
        filter.field = 'customer';
      }
    });
    const payload = {
      paging: { ...params.paging },
      filtering: {
        filters: params.filtering.filters,
        operator: 'AND',
      },
      sorting: {
        orders: [...params.sorting],
      },
    };
    return this.communicator.fetchAll(payload).then((json) => this.validateAllCasesJson(json));
  }
}

export default CustomerListManager;
