import { put, takeEvery } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';
import PepSanctionApiCommunicator from 'modules/customer-cases/case-detail/services/pep-sanction/pep-sanction-api-communicator';
import PepSanctionManager from 'modules/customer-cases/case-detail/services/pep-sanction/pep-sanction-manager';
import {
  assessPepSanctionError,
  assessPepSanctionSuccess,
} from 'modules/customer-cases/case-detail/middleware/actions/pep-sanction';

const communicator = new PepSanctionApiCommunicator();
const manager = new PepSanctionManager(communicator);

function* assessPepSanctionBegin(action) {
  yield put(startProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.pepSanction.assess.data));

  try {
    const params = action.payload;
    const json = yield manager.assessPepSanction(params);
    yield put(assessPepSanctionSuccess({ type: action.type, data: json }));
  } catch (error) {
    yield put(assessPepSanctionError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.pepSanction.assess.data));
}

function* assessPepSanctionWatcher() {
  yield takeEvery(CUSTOMER_CASE_DETAIL_ACTIONS.pepSanction.assess.data, assessPepSanctionBegin);
}

export { assessPepSanctionWatcher };
