const DEFAULT_COUNTRY_FIELDS = {
  default: true,
  accountHoldingParty: {
    companyDetails: [],
    contactDetails: [
      {
        key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.key',

        value:
          'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.value.heading',
      },
    ],
    personalDetails: [],
    referenceAccount: [],
  },
  fundsBeneficiary: {
    transaction: [
      {
        key: 'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.key',

        value:
          'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.value.heading',
      },
    ],
  },
  counterParty: {
    companyDetails: [],
    contactDetails: [
      {
        key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.key',

        value:
          'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.value.heading',
      },
    ],
    personalDetails: [],
    referenceAccount: [],
  },
  fundsOriginator: {
    transaction: [
      {
        key: 'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.key',

        value:
          'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.value.heading',
      },
    ],
  },
};

export default class CountryRiskDtoMapper {
  static mapHoldingParty(acc, fieldParts) {
    let temp = acc;
    if (!Object.prototype.hasOwnProperty.call(temp, fieldParts[0])) {
      temp = {
        ...temp,
        [fieldParts[0]]: {},
      };
    }

    if (!Object.prototype.hasOwnProperty.call(temp[fieldParts[0]], fieldParts[1])) {
      temp = {
        ...temp,
        [fieldParts[0]]: {
          ...temp[fieldParts[0]],
          [fieldParts[1]]: [],
        },
      };
    }

    return {
      ...temp,
      [fieldParts[0]]: {
        ...temp[fieldParts[0]],
        [fieldParts[1]]: [
          ...temp[fieldParts[0]][fieldParts[1]],
          CountryRiskDtoMapper.getModelValueForHoldingParty(fieldParts[1], fieldParts[2]),
        ],
      },
    };
  }

  static mapTransactionParty(acc, fieldParts) {
    let temp = acc;
    if (!Object.prototype.hasOwnProperty.call(temp, fieldParts[0])) {
      temp = {
        ...temp,
        [fieldParts[0]]: {
          transaction: [],
        },
      };
    }

    return {
      ...temp,
      [fieldParts[0]]: {
        ...temp[fieldParts[0]],
        transaction: [
          ...temp[fieldParts[0]].transaction,
          CountryRiskDtoMapper.getModelValueForTransactionParty(fieldParts[1]),
        ],
      },
    };
  }

  static mapFromDto(dto) {
    return {
      ...dto,
      instances: [
        ...dto.instances.map((instance) => {
          let countryFields;
          if (instance.countryFields === null) {
            countryFields = DEFAULT_COUNTRY_FIELDS;
          } else {
            countryFields = {
              ...instance.countryFields.reduce((acc, current) => {
                const fieldParts = current.split('.');

                if (fieldParts[0] === 'accountHoldingParty' || fieldParts[0] === 'counterParty') {
                  return CountryRiskDtoMapper.mapHoldingParty(acc, fieldParts);
                }

                return CountryRiskDtoMapper.mapTransactionParty(acc, fieldParts);
              }, {}),
              default: false,
            };
          }
          return { ...instance, countryFields };
        }),
      ],
    };
  }

  static mapOneInstanceToDto(instance) {
    let countryFields;

    if (instance?.countryFields?.default) {
      countryFields = null;
    } else {
      const accountHoldingPartyFields = Object.keys(
        instance?.countryFields?.accountHoldingParty ?? {},
      ).map((key) =>
        CountryRiskDtoMapper.mapHoldingPartyValuesToDto(
          instance?.countryFields?.accountHoldingParty[key],
          key,
          'accountHoldingParty',
        ),
      );

      const counterPartyFields = Object.keys(instance?.countryFields?.counterParty ?? {}).map(
        (key) =>
          CountryRiskDtoMapper.mapHoldingPartyValuesToDto(
            instance?.countryFields?.counterParty[key],
            key,
            'counterParty',
          ),
      );

      const fundsOriginatorFields = Object.keys(instance?.countryFields?.fundsOriginator ?? {}).map(
        (key) =>
          CountryRiskDtoMapper.mapTransactionPartyValuesToDto(
            instance?.countryFields?.fundsOriginator,
            key,
            'fundsOriginator',
          ),
      );
      const fundsBeneficiaryFields = Object.keys(
        instance?.countryFields?.fundsBeneficiary ?? {},
      ).map((key) =>
        CountryRiskDtoMapper.mapTransactionPartyValuesToDto(
          instance?.countryFields?.fundsBeneficiary,
          key,
          'fundsBeneficiary',
        ),
      );

      countryFields = [
        ...accountHoldingPartyFields,
        ...counterPartyFields,
        ...fundsOriginatorFields,
        ...fundsBeneficiaryFields,
      ].flatMap((value) => value);
    }

    return {
      ...instance,
      countryFields,
    };
  }

  static mapToDto(model) {
    return {
      ...model,
      instances: [
        ...model.instances.map((instance) => CountryRiskDtoMapper.mapOneInstanceToDto(instance)),
      ],
    };
  }

  static getModelValueForHoldingParty(group, key) {
    switch (group) {
      case 'personalDetails': {
        if (key === 'nationality') {
          return {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.nationality.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.nationality.value.heading',
          };
        }
        if (key === 'countryOfBirth') {
          return {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfBirth.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfBirth.value.heading',
          };
        }
        if (key === 'countryOfResidence') {
          return {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfResidence.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfResidence.value.heading',
          };
        }
        break;
      }
      case 'referenceAccount': {
        if (key === 'bankCountry') {
          return {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.referenceAccount.bankCountry.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.referenceAccount.bankCountry.value.heading',
          };
        }
        break;
      }
      case 'companyDetails': {
        if (key === 'countryOfRegistration') {
          return {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.companyDetails.countryOfRegistration.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.companyDetails.countryOfRegistration.value.heading',
          };
        }
        break;
      }
      case 'contactDetails': {
        if (key === 'country') {
          return {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.value.heading',
          };
        }
        break;
      }
      default: {
        break;
      }
    }
    return '';
  }

  static getModelValueForTransactionParty(key) {
    if (key === 'bankCountry') {
      return {
        key: 'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.key',

        value:
          'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.value.heading',
      };
    }

    return '';
  }

  static getHoldingPartyKeys(group) {
    switch (group) {
      case 'personalDetails': {
        return [
          {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.nationality.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.nationality.value.heading',
          },
          {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfBirth.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfBirth.value.heading',
          },
          {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfResidence.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfResidence.value.heading',
          },
        ];
      }
      case 'referenceAccount': {
        return [
          {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.referenceAccount.bankCountry.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.referenceAccount.bankCountry.value.heading',
          },
        ];
      }
      case 'companyDetails': {
        return [
          {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.companyDetails.countryOfRegistration.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.companyDetails.countryOfRegistration.value.heading',
          },
        ];
      }
      case 'contactDetails': {
        return [
          {
            key: 'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.key',

            value:
              'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.value.heading',
          },
        ];
      }
      default: {
        return [];
      }
    }
  }

  static mapHoldingPartyValuesToDto(values, key, group) {
    switch (key) {
      case 'personalDetails': {
        return values.map((value) => {
          if (
            value.key ===
            'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.nationality.key'
          ) {
            return `${group}.personalDetails.nationality`;
          }
          if (
            value.key ===
            'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfBirth.key'
          ) {
            return `${group}.personalDetails.countryOfBirth`;
          }
          if (
            value.key ===
            'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.personalDetails.countryOfResidence.key'
          ) {
            return `${group}.personalDetails.countryOfResidence`;
          }
          return '';
        });
      }
      case 'referenceAccount': {
        return values.map((value) => {
          if (
            value.key ===
            'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.referenceAccount.bankCountry.key'
          ) {
            return `${group}.referenceAccount.bankCountry`;
          }
          return '';
        });
      }
      case 'companyDetails': {
        return values.map((value) => {
          if (
            value.key ===
            'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.companyDetails.countryOfRegistration.key'
          ) {
            return `${group}.companyDetails.countryOfRegistration`;
          }
          return '';
        });
      }
      case 'contactDetails': {
        return values.map((value) => {
          if (
            value.key ===
            'configuration:ruleManager.countryRiskV2.countryFields.holdingParty.contactDetails.country.key'
          ) {
            return `${group}.contactDetails.country`;
          }
          return '';
        });
      }
      default: {
        break;
      }
    }
    return '';
  }

  static getTransactionPartyKeys() {
    return [
      {
        key: 'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.key',

        value:
          'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.value.heading',
      },
    ];
  }

  static mapTransactionPartyValuesToDto(values, key, group) {
    return values.transaction.map((value) => {
      if (
        value.key ===
        'configuration:ruleManager.countryRiskV2.countryFields.transactionParty.bankCountry.key'
      ) {
        return `${group}.bankCountry`;
      }
      return '';
    });
  }
}
