import { CASE_STATISTICS_AND_HEALTH_ACTIONS } from 'modules/dashboard/case-statistic/middleware/action-types';

export const getDashboardMetricSuccess = (payload, type) => ({
  type: `${type}_SUCCESS`,
  payload,
});

export const getGroupsCountMetric = (payload) => ({
  type: CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsCount.data,
  payload,
});

export const getGroupsTypeCountMetric = (payload) => ({
  type: CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsTypeCount.data,
  payload,
});

export const getGroupsHealthMetric = (payload) => ({
  type: CASE_STATISTICS_AND_HEALTH_ACTIONS.caseStatisticAndHealth.groupsHealth.data,
  payload,
});
