import { IconButton, Tooltip } from '@mui/material';
import { Translation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const AttachFile = ({
  handleChange,
  size = 'medium',
  multiple,
  fileExtensions,
  disabled,
  fontSize = 'small',
  edge,
  name,
}) => {
  const convertToDefEventPara = (event) => ({
    ...event,
    target: {
      name: event.target.name,
      value: event.target.files,
    },
  });

  return (
    <Translation>
      {(t) => (
        <Tooltip title={t('attach.heading')}>
          <span>
            <IconButton size={size} edge={edge} disabled={disabled} component="label">
              <AttachFileIcon fontSize={fontSize} />
              <input
                name={name}
                accept={fileExtensions}
                multiple={multiple}
                type="file"
                hidden
                onChange={(event) => handleChange(convertToDefEventPara(event))}
                onClick={(e) => (e.target.value = null)}
              />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Translation>
  );
};

export default AttachFile;
