import { Box, Chip } from '@mui/material';
import { tenantIdsToTenants } from 'components/filters/fields/tenant-name/tenant-name-filter-helper';
import { Tenant } from 'models/tenant/tenant';
import React from 'react';
import { Translation } from 'react-i18next';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { getTenantsFromString } from 'utils/helpers/utility';

interface TenantIdsChipProps {
  form: {
    values: string[];
  };
}

export const TenantNameChip = ({ form }: TenantIdsChipProps) => {
  const allTenants = TenantManagementService.getTenants();
  const userTenants = getTenantsFromString(allTenants) as Tenant[];
  const tenants = tenantIdsToTenants(form.values, userTenants);

  return (
    <Translation>
      {(t) => (
        <>
          <Box fontSize="caption.fontSize" color="text.secondary">
            {t('mapping:filters.filtering.tenantId.value.heading')}
          </Box>
          {tenants.map((item, index) => (
            <Chip key={index} variant="outlined" size="small" className="mr-1" label={item?.name} />
          ))}
        </>
      )}
    </Translation>
  );
};
