import { AUDIT_TRAIL_ACTIONS } from 'modules/audit-trail/middleware/action-types';

const initialState = {
  auditTrails: [],
};

const auditTrailReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.data: {
      if (!action?.payload?.paging?.scrollId) {
        return {
          ...state,
          ...initialState,
        };
      }
      return state;
    }
    case AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.success: {
      const auditTrails = [...state.auditTrails, ...action.payload.auditTrails];
      return {
        ...state,
        auditTrails,
        total: action.payload.total,
        paging: {
          scrollId: action.payload.paging.scrollId,
          lastPage:
            action.payload.auditTrails.length === 0 ||
            action.payload.total < action.payload.pageSize,
        },
      };
    }
    default:
      return state;
  }
};

export default auditTrailReducer;
