import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class AssessmentEngineConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Assessment Engine Config API Communicator instance is required.');
    }
  }

  validateConfigJson(json) {
    if (json) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  getAssessmentEngineConfig() {
    return this.communicator
      .fetchAssessmentEngineConfig()
      .then((json) => this.validateConfigJson(json));
  }

  updateAssessmentEngineConfig(params) {
    return this.communicator.updateAssessmentEngineConfig(params);
  }
}

export default AssessmentEngineConfigManager;
