import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSettings } from 'middleware/actions/settings';
import { hideError, showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  removeForbiddenAlertForPageId,
  addReadForbiddenAlert,
} from 'middleware/reducers/forbidden-alert-reducer';
import { updateMasks } from 'middleware/actions/mask';
import { getMaskedsFromCase, getMaskedsFromCases } from 'utils/helpers/cases/case-utility';
import CaseAPICommunicator from 'modules/trx-cases/case-detail/services/case/case-api-communicator';
import CaseManager from 'modules/trx-cases/case-detail/services/case/case-manager';
import { CaseActions } from 'middleware/action-types';
import { getUserPermissions } from 'modules/authentication/login/middleware/actions/auth-actions';
import {
  getCaseDetailConfig,
  getCaseDetailError,
  getCaseDetailSuccess,
  getCasesByForwardingToCaseIdError,
  getCasesByForwardingToCaseIdSuccess,
  getCaseStateChanges,
  getForwardingCaseDetailError,
  getForwardingCaseDetailSuccess,
} from 'middleware/actions/case';
import { uniqBy } from 'lodash';
import ErrorCode from 'utils/constants/error-code';
import { PAGE_ID } from 'components/alerts/page-id';

const caseCommunicator = new CaseAPICommunicator();
const caseManager = new CaseManager(caseCommunicator);

function* getCaseDetailBegin(action) {
  yield put(startProcessing(CaseActions.CASE_DETAIL_GET));
  yield put(removeForbiddenAlertForPageId(PAGE_ID.caseDetails));
  try {
    yield put(getUserPermissions());
    const detailCase = yield caseManager.queryCaseDetail(action.uuid, action.caseCreatedAt);
    yield put(getCaseDetailSuccess(detailCase));
    const configVersion =
      detailCase?.configVersion || detailCase?.decisionTakenEvent?.configVersion;
    const tenantId = detailCase?.tenantId;
    if (action.firstLoad && action.shouldGetCaseStateChanges) {
      yield put(getCaseStateChanges(action.uuid, tenantId));
    }

    yield put(
      getCaseDetailConfig({
        tenantId,
        configVersion,
      }),
    );
    yield put(
      getSettings({
        tenantId,
        configVersion,
      }),
    );
    yield put(updateMasks(getMaskedsFromCase(detailCase)));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    const errorCode = error.code;
    if (errorCode === ErrorCode.MISSING_PERMISSIONS) {
      yield put(addReadForbiddenAlert(PAGE_ID.caseDetails));
    } else {
      yield put(getCaseDetailError());
      yield put(showError({ ...error, action }));
    }
  }
  yield put(stopProcessing(CaseActions.CASE_DETAIL_GET));
}

function* getCaseDetailWatcher() {
  yield takeLatest(CaseActions.CASE_DETAIL_GET, getCaseDetailBegin);
}

function* getForwardingCaseDetailBegin(action) {
  try {
    yield put(startProcessing(CaseActions.FORWARDING_CASE_DETAIL_GET));

    const json = yield caseManager.queryCaseDetail(action.uuid, action.caseCreatedAt);
    yield put(getForwardingCaseDetailSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(CaseActions.FORWARDING_CASE_DETAIL_GET));
  } catch (error) {
    yield put(getForwardingCaseDetailError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CaseActions.FORWARDING_CASE_DETAIL_GET));
  }
}

function* getForwardingCaseDetailWatcher() {
  yield takeLatest(CaseActions.FORWARDING_CASE_DETAIL_GET, getForwardingCaseDetailBegin);
}

function* fetchSettings(detailCase, loadedSettings) {
  const configVersion = detailCase?.configVersion || detailCase?.decisionTakenEvent?.configVersion;
  const tenantId = detailCase?.tenantId;

  if (!loadedSettings[tenantId] || !loadedSettings[tenantId][configVersion]) {
    yield put(
      getSettings({
        tenantId,
        configVersion,
      }),
    );
  }
}

function* getCasesByForwardingToCaseIdBegin(action) {
  yield put(startProcessing(CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET));
  try {
    const forwardingCases = yield caseManager.queryCasesByForwardingToCaseId(
      action.forwardingToCaseId,
      action.tenantId,
      action.page,
      action.pageSize,
    );

    yield put(getCasesByForwardingToCaseIdSuccess(forwardingCases));
    yield put(updateMasks(getMaskedsFromCases(forwardingCases)));

    const uniqTenantIdConfigVersion = uniqBy(forwardingCases, (detailCase) =>
      [
        detailCase?.tenantId,
        detailCase?.configVersion || detailCase?.decisionTakenEvent?.configVersion,
      ].join(),
    );

    yield all(
      uniqTenantIdConfigVersion.map((detailCase) =>
        call(fetchSettings, detailCase, action.loadedSettings),
      ),
    );

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    yield put(getCasesByForwardingToCaseIdError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET));
}

function* getCasesByForwardingToCaseIdWatcher() {
  yield takeLatest(
    CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET,
    getCasesByForwardingToCaseIdBegin,
  );
}

export {
  getCaseDetailWatcher,
  getForwardingCaseDetailWatcher,
  getCasesByForwardingToCaseIdWatcher,
};
