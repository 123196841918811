import { Box, Chip } from '@mui/material';
import { getOperatorList } from 'modules/authentication/login/middleware/actions/auth-actions';
import { Translation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { mapUserIdsToEmails } from 'components/filters/filter-utils';
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { RootState } from 'middleware/store';
import { Filter } from 'components/filters/models/filter';
import { Operator } from 'components/filters/models/operator';

interface AssignedToChipProps {
  form: Filter;
}

const AssignedToChip = ({ form }: AssignedToChipProps) => {
  const dispatch = useDispatch();
  const usersList: Operator[] = useSelector(
    (state: RootState) => state.authentication?.operatorList ?? [],
  );
  useEffect(() => {
    if (isEmpty(usersList)) {
      dispatch(getOperatorList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Translation>
      {(t) => (
        <>
          <Box fontSize="caption.fontSize" color="text.secondary">
            {t('mapping:filters.filtering.assignedToUserId.value.heading')}
          </Box>
          {mapUserIdsToEmails(usersList, form.values).map((item, index) => (
            <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
          ))}
        </>
      )}
    </Translation>
  );
};

export default AssignedToChip;
