import { Grid, Typography } from '@mui/material';
import { RiskChip } from 'components/chips/risk-chip';
import FormattedDateTime from 'components/formatted-date-time';
import { SectionLink } from 'components/popover/person-details/person-details-tab/section-link';
import { TabPanel } from 'components/tabs/tab';
import { useState } from 'react';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCountryName } from 'utils/constants/country-list';
import { formatMaskedValue } from 'utils/helpers/cases/case-utility';

import { getCountryRiskLevel, getSettingsFromState } from 'utils/helpers/config-utils/config-util';
import { DocumentsDataView } from 'components/popover/person-details/person-details-tab/documents/documents-data';
import { isEmpty } from 'lodash';

/**
 * personal data for person popover
 * @param {*} props
 */
const PersonalData = ({ personDetails, caseConfig, showUnmaskedData }) => {
  const allMasks = useSelector((state) => state.masks?.all);
  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);
    return settings?.countryRisk;
  });

  const tabView = {
    general: {
      key: 'general',
    },

    documents: {
      key: 'documents',
      back: 'general',
    },
  };
  const [currentView, setSelectedView] = useState(tabView.general);

  const handleChange = (viewKey) => {
    setSelectedView(tabView[viewKey]);
  };

  const formatMaskedValueInternal = (fieldObject) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);

  const partyData = personDetails?.parties
    ? {
        ...personDetails?.parties[0],
        calculatedRiskLevel: personDetails?.calculatedRiskLevel,
        transaction: personDetails?.transaction,
        underwriterData: personDetails?.underwriterData,
      }
    : personDetails;

  const personalData = {
    fullName: formatMaskedValueInternal(partyData?.personalDetails?.personName?.fullName),
    nameAtBirth: formatMaskedValueInternal(partyData?.personalDetails?.personName?.nameAtBirth),
    gender: partyData?.personalDetails?.gender?.toLowerCase(),
    occupation: partyData?.personalDetails?.occupation,
    dateOfBirth: formatMaskedValueInternal(partyData.personalDetails?.dateOfBirth),
    placeOfBirth: formatMaskedValueInternal(partyData.personalDetails?.placeOfBirth),
    countryOfBirth: {
      value: formatMaskedValueInternal(partyData.personalDetails?.countryOfBirth),
    },
    nationality: {
      value: formatMaskedValueInternal(partyData.personalDetails?.nationality),
    },
    socialSecurityNo: formatMaskedValueInternal(partyData.personalDetails?.socialSecurityNumber),
    taxId: formatMaskedValueInternal(partyData.taxId),
    taxIdType: partyData?.taxIdType,
    role: partyData?.role,
  };

  personalData.countryOfBirth.name = getCountryName(personalData.countryOfBirth.value);
  personalData.countryOfBirth.riskLevel = getCountryRiskLevel(
    personalData.countryOfBirth.value,
    countryRisk,
  );

  personalData.nationality.name = getCountryName(personalData.nationality.value);
  personalData.nationality.riskLevel = getCountryRiskLevel(
    personalData.nationality.value,
    countryRisk,
  );

  /**
   * general overview of customer data
   * @param {*} props
   */
  const GeneralView = ({ personalData }) => (
    <Translation>
      {(t) => (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t('name.heading')}
              </Typography>
              <Typography variant="body2">
                {personalData.fullName ? personalData.fullName : '-'}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t('nameAtBirth.heading')}
              </Typography>
              <Typography variant="body2">
                {personalData.nameAtBirth ? personalData.nameAtBirth : '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('gender.heading')}
              </Typography>
              <Typography variant="body2" className="text-capitalize">
                {personalData.gender ? personalData.gender : '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('dateOfBirth.heading')}
              </Typography>
              <Typography variant="body2">
                {personalData.dateOfBirth ? (
                  <FormattedDateTime date={personalData.dateOfBirth} />
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('placeOfBirth.heading')}
              </Typography>
              <Typography variant="body2" component="span">
                {!personalData.placeOfBirth && !personalData.countryOfBirth.value ? '-' : ''}

                {personalData.placeOfBirth ? (
                  <span className="mr-1">{personalData.placeOfBirth}</span>
                ) : (
                  ''
                )}
                {personalData.countryOfBirth.value ? (
                  <RiskChip
                    size="small"
                    risk={personalData.countryOfBirth.riskLevel}
                    label={personalData.countryOfBirth.name}
                  />
                ) : (
                  ''
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('nationality.heading')}
              </Typography>
              {personalData.nationality.value ? (
                <RiskChip
                  size="small"
                  risk={personalData.nationality.riskLevel}
                  label={personalData.nationality.name}
                />
              ) : (
                '-'
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('case:userPopover.personal.socialSecurityNo.heading')}
              </Typography>
              <Typography variant="body2">
                {personalData.socialSecurityNo ? personalData.socialSecurityNo : '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('case:userPopover.personal.taxId.heading')}
              </Typography>
              <Typography variant="body2">{personalData.taxId ?? '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('case:userPopover.personal.taxIdType.heading')}
              </Typography>
              <Typography variant="body2">{personalData.taxIdType ?? '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('case:userPopover.personal.occupation.heading')}
              </Typography>
              <Typography variant="body2" className="text-capitalize">
                {personalData.occupation ? personalData.occupation : '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary">
                {t('case:userPopover.personal.role.heading')}
              </Typography>
              <Typography variant="body2" className="text-capitalize wb-break-all">
                {personalData.role ?? '-'}
              </Typography>
            </Grid>

            {!isEmpty(partyData.personalDetails.identityDocumentLinks) && (
              <Grid item xs={12} className="border-bottom">
                <SectionLink
                  label={t('case:userPopover.personal.identityDocument.heading')}
                  changeTabViewOnClick={() => handleChange(tabView.documents.key)}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Translation>
  );

  return (
    <>
      <TabPanel index={tabView.general.key} value={currentView.key}>
        <GeneralView personalData={personalData} />
      </TabPanel>

      <TabPanel index={tabView.documents.key} value={currentView.key}>
        <DocumentsDataView
          showUnmaskedData={showUnmaskedData}
          // variable named here personalDetails is ahpd in fact... this error is propagated overall component
          accountHoldingPartyData={partyData}
          handleChange={handleChange}
          backTabLink={tabView.general.key}
          caseConfig={caseConfig}
        />
      </TabPanel>
    </>
  );
};

export { PersonalData };
