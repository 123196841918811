import CaseStatusFilters from 'utils/constants/case-status-filters';
import AllCases from 'modules/trx-cases/case-list/components/all-cases';
import React, { FC } from 'react';
import { CaseListProps } from 'modules/trx-cases/case-list/models/case-list';

const CustomViewCases: FC<CaseListProps> = (props) => {
  const { location } = props;

  const newProps = {
    ...props,
    caseStatusFilter: CaseStatusFilters.ANY_CASES,
    key: location.key,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <AllCases {...newProps} />;
};

export default CustomViewCases;
