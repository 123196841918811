import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RISK_CATEGORY } from 'utils/constants/constant';
import { ConditionalTooltip } from 'components/tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import { getWheelProp } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points';

const ManualWheel = ({ size = 'small', risk }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const style = theme.custom?.wheelPointComponent[size];

  const height = getWheelProp(size)?.width;
  const halfHeight = height / 2;
  const width = height * 1.2;
  const xAdjustment = `${height * 0.1}px`;

  let riskCustom = risk?.toLowerCase();
  riskCustom = riskCustom?.replace(RISK_CATEGORY.default, '');
  const riskStyle = riskCustom
    ? theme.custom?.riskChipComponent[riskCustom]
    : theme.custom?.riskChipComponent.neutral;

  const tooltip = (
    <Typography variant="overline" component="p" className="color-white lh-1">
      {t('configuration:customerRiskRating.riskFactor.points.manual.text', {
        riskLevel: t(`mapping:riskLevel.${risk?.toLowerCase()}.value.heading`).toLowerCase(),
      })}
    </Typography>
  );

  return (
    <ConditionalTooltip title={tooltip}>
      <Box position="relative" display="inline-flex" style={{ width }} data-testid="manual-wheel">
        <Box display="inline-block" style={{ height }} />
        <Box
          top={0}
          bottom={0}
          left={xAdjustment}
          right={xAdjustment}
          position="absolute"
          style={{
            backgroundColor: riskStyle?.color,
            borderRadius: halfHeight,
            width: height,
            height: 2 * halfHeight,
          }}
        />
        <Box
          top="25%"
          bottom="25%"
          left={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            ...riskStyle,
            borderRadius: halfHeight,
            borderColor: riskStyle?.color,
            borderWidth: '2px',
            borderStyle: 'solid',
            width,
          }}
        >
          <Typography
            component="span"
            color={riskStyle?.color}
            fontSize={style?.text?.fontSize}
            fontWeight={700}
          >
            {t('configuration:customerRiskRating.riskFactor.points.manual.heading')}
          </Typography>
        </Box>
      </Box>
    </ConditionalTooltip>
  );
};

export { ManualWheel };
