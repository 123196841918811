import { CustomerDataApi } from 'modules/customer-data/services/customer-data-api';
import { useQuery } from 'react-query/';

export const useCustomerProfileExists = (
  customerIdToken: string,
  isCustomerViewEnabled: boolean,
) => {
  const customerDataApi = new CustomerDataApi();

  return useQuery({
    enabled: customerIdToken !== undefined && isCustomerViewEnabled,
    queryKey: ['customer-profile-exists', customerIdToken],
    queryFn: () => customerDataApi.checkIfCustomerProfileExists(customerIdToken),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });
};
