import { Box, Chip, Divider, FormHelperText, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import { useDetachment } from 'hooks/use-detachment';
import { Form, useForm } from 'hooks/use-form';
import i18next from 'i18next';
import { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';

const buildCaseStatus = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t(`mapping:filters.filtering.caseStatus.key`),
  values: filter?.values,
});

const buildProcessingStatus = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t(`mapping:filters.filtering.processingStatus.key`),
  values: filter?.values,
});

const buildCaseStatusesValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t(`mapping:filters.filtering.${filter.field}.key`),
  values: filter?.values,
});

const CaseStatusForm = ({ editForm, onSubmit, filterKey, mappingPath }) => {
  const { t } = useTranslation();
  const initialFValues = {};

  // Escalated status filtered out to avoid confusion as it not a status on backend according to CaseStatusesV2, so results will be always empty.
  // Dunno why it belongs to caseStatusesV2 mapping.

  const { autoCloseRelabel: isAutoCloseRelabelAttached } = useDetachment('autoCloseRelabel');

  const caseStatuses = Object.values(t(`mapping:${mappingPath}`)).filter(
    (value) =>
      value.key !== 'escalated' &&
      !(isAutoCloseRelabelAttached && ['AUTO_CLOSED', 'auto-closed'].includes(value.key)),
  );

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`${mappingPath}[${item}]`] = true;
    });
  }

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newCaseStatuses = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];

    setCheckedValues(newCaseStatuses);
    // eslint-disable-next-line no-use-before-define
    validate(newCaseStatuses);
  };

  const validate = (newChecked = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    temp.caseStatus = newChecked.length ? '' : t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: filterKey,
        values: checkedValues,
      });
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  return (
    <Translation>
      {(t) => (
        <Grid container item>
          <Form onSubmit={handleSubmit}>
            <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
              {caseStatuses.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Controls.Checkbox
                    name={`${mappingPath}[${item.key}]`}
                    label={item.value.heading}
                    value={values[`${mappingPath}[${item.key}]`] ?? false}
                    onChange={(event) => handleSelectChange(event, item.key)}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            {errors.caseStatus && (
              <FormHelperText className="pl-3" error>
                {errors.caseStatus}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

/**
 * @param form - form data
 * @param upperCaseValue - if true form values will be upperCased
 */
const CaseStatusChip = ({ form, mappingPath, filterName }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t(`mapping:filters.filtering.${filterName}.value.heading`)}
        </Box>
        {form.values.map((item, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            className="mr-1"
            label={t(`mapping:${mappingPath}.${item}.value.heading`)}
          />
        ))}
      </>
    )}
  </Translation>
);

export {
  CaseStatusForm,
  CaseStatusChip,
  buildCaseStatus,
  buildProcessingStatus,
  buildCaseStatusesValuesFromUrl,
};
