import { Component } from 'react';
import { connect } from 'react-redux';
import { hideNotification } from 'middleware/actions/notification';
import $ from 'jquery';
import DOMPurify from 'dompurify';
import { Alert } from '@mui/material';

const INTERVAL_TIME = 350;

class ToastMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0.8,
    };

    this.handleIntervalTasks = this.handleIntervalTasks.bind(this);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.handleIntervalTasks, INTERVAL_TIME);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleIntervalTasks() {
    const { data: notification = {}, hideNotification } = this.props;
    const messageId = `toast${notification.id}`;
    notification.hideAfterMilliseconds -= 1;
    let { opacity } = this.state;

    if (notification.hideAfterMilliseconds < 4) {
      opacity -= 0.005;
      this.setState({
        opacity,
      });
      const currentToast = $(`#${messageId}`);
      currentToast.fadeTo(INTERVAL_TIME, opacity);
    }

    if (notification.hideAfterMilliseconds === 0) {
      hideNotification(notification);
    } else if (notification.hideAfterMilliseconds < 0) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    const { data: notification = {}, index, hideNotification } = this.props;

    const messageId = `toast${notification.id}`;

    return (
      <div id={messageId} data-sort={index} className="mb-2">
        <Alert severity="success" variant="filled" onClose={() => hideNotification(notification)}>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(notification.message),
            }}
          />
        </Alert>
      </div>
    );
  }
}

const mapDispatchToProps = {
  hideNotification,
};

export default connect(null, mapDispatchToProps)(ToastMessage);
