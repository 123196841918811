import { IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Translation } from 'react-i18next';

const Settings = ({ onClick, edge }) => (
  <Translation>
    {(t) => (
      <Tooltip title={t('settings.heading')}>
        <IconButton edge={edge} onClick={onClick} size="large">
          <SettingsIcon />
        </IconButton>
      </Tooltip>
    )}
  </Translation>
);

export default Settings;
