import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EntityResolutionConfig } from 'modules/entity-resolution/models/entity-resolution';

const initialState: EntityResolutionConfig = {
  active: false,
};

const entityResolutionConfigSlice = createSlice({
  name: 'entityResolutionConfig',
  initialState,
  reducers: {
    getEntityResolutionConfig: (state) => state,
    getEntityResolutionConfigSuccess: (state, action: PayloadAction<EntityResolutionConfig>) => {
      const newState = {
        ...state,
        ...action?.payload,
      };
      return newState;
    },
    saveEntityResolutionConfig: (state, _action: PayloadAction<EntityResolutionConfig>) => state,
    saveEntityResolutionConfigSuccess: (state, action: PayloadAction<EntityResolutionConfig>) => {
      const newState = {
        ...state,
        ...action?.payload,
      };
      return newState;
    },
  },
});

export const {
  getEntityResolutionConfig,
  getEntityResolutionConfigSuccess,
  saveEntityResolutionConfig,
  saveEntityResolutionConfigSuccess,
} = entityResolutionConfigSlice.actions;

export default entityResolutionConfigSlice.reducer;
