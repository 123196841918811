import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatMaskedValue } from 'utils/helpers/cases/case-utility';
import { TabPanel } from 'components/tabs/tab';
import { getCountryName } from 'utils/constants/country-list';
import { RiskChip } from 'components/chips/risk-chip';
import { Typography, Grid, Box } from '@mui/material';
import FormattedDateTime from 'components/formatted-date-time';
import { BusinessPurposeChip } from 'components/chips/business-purpose-chip';
import HoverMaskedText from 'components/hover-masked-text';
import { SectionLink } from 'components/popover/person-details/person-details-tab/section-link';
import { getSettingsFromState, getCountryRiskLevel } from 'utils/helpers/config-utils/config-util';
import { ShareholdersDataView } from 'components/popover/person-details/person-details-tab/shareholders-data';
import { isEmptyNestedChecks } from 'utils/helpers/object-util';

/**
 * company data for person popover
 * @param {*} props
 */
const CompanyData = (props) => {
  const { personDetails, caseConfig, showUnmaskedData } = props;
  const allMasks = useSelector((state) => state.masks?.all);
  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);
    return settings?.countryRisk;
  });

  const tabView = {
    general: {
      key: 'general',
    },

    shareholdersInfo: {
      key: 'shareholdersInfo',
      back: 'general',
    },
  };
  const [currentView, setSelectedView] = useState(tabView.general);

  const formatMaskedValueInternal = (fieldObject) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);

  const handleChange = (viewKey) => {
    setSelectedView(tabView[viewKey]);
  };

  const partyData = personDetails?.parties
    ? {
        ...personDetails?.parties[0],
        calculatedRiskLevel: personDetails?.calculatedRiskLevel,
        transaction: personDetails?.transaction,
        underwriterData: personDetails?.underwriterData,
      }
    : personDetails;

  const companyData = {
    companyName: formatMaskedValueInternal(partyData?.companyDetails?.companyName),
    trademark: partyData?.companyDetails?.trademark,
    businessDescription: partyData?.companyDetails?.businessDescription,
    businessPurpose: partyData?.companyDetails?.businessPurposes,
    inBusinessSince: partyData.companyDetails?.inBusinessSince,
    registrationCountry: {
      value: formatMaskedValueInternal(partyData.companyDetails?.countryOfRegistration),
    },
    organizationType: partyData.companyDetails?.organizationType,
    legalForm: partyData.companyDetails?.legalForm,
    taxId: formatMaskedValueInternal(partyData.taxId),
    complexOwnership: partyData?.companyDetails?.shareholdersStructure?.complexOwnership,
  };

  companyData.inBusinessSince = companyData.inBusinessSince
    ? new Date(companyData.inBusinessSince)
    : '';
  companyData.registrationCountry.name = getCountryName(companyData.registrationCountry?.value);
  companyData.registrationCountry.riskLevel = getCountryRiskLevel(
    companyData.registrationCountry?.value,
    countryRisk,
  );

  /**
   * general overview of customer data
   * @param {*} props
   */
  const GeneralView = (props) => {
    const { companyData } = props;

    return (
      <Translation>
        {(t) => (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.name.heading')}
                </Typography>
                <Typography variant="body2">
                  <HoverMaskedText
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...partyData?.companyDetails?.companyName}
                  />
                </Typography>
                {companyData.trademark && (
                  <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                    {companyData.trademark}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.description.heading')}
                </Typography>
                <Typography variant="body2">
                  {companyData.businessDescription ? `${companyData.businessDescription}` : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.businessPurpose.heading')}
                </Typography>
                {companyData.businessPurpose ? (
                  <BusinessPurposeChip providedBusinessPurpose={companyData.businessPurpose} />
                ) : (
                  '-'
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.businessSince.heading')}
                </Typography>
                <Typography variant="body2">
                  {companyData.inBusinessSince ? (
                    <FormattedDateTime date={companyData.inBusinessSince} />
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.registrationCountry.heading')}
                </Typography>
                {companyData.registrationCountry.value ? (
                  <RiskChip
                    size="small"
                    risk={companyData.registrationCountry.riskLevel}
                    label={companyData.registrationCountry.name}
                  />
                ) : (
                  '-'
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.organizationType.heading')}
                </Typography>
                <Typography variant="body2">
                  {companyData.organizationType ? companyData.organizationType : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.legalForm.heading')}
                </Typography>
                <Typography variant="body2">
                  {companyData.legalForm ? companyData.legalForm : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.personal.taxId.heading')}
                </Typography>
                <Typography variant="body2">
                  {companyData.taxId ? companyData.taxId : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} className="mt-1">
                <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
                  {t('case:userPopover.company.shareholdersStructure.heading')}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('case:userPopover.company.complexOwnership.heading')}
                </Typography>
                <Typography variant="body2">
                  {companyData.complexOwnership ? companyData.complexOwnership : '-'}
                </Typography>
              </Grid>
              {!isEmptyNestedChecks(partyData?.companyDetails?.shareholders) && (
                <Grid item xs={12} className="border-bottom">
                  <SectionLink
                    label={t('case:userPopover.personal.shareholdersInfo.heading')}
                    changeTabViewOnClick={() => handleChange(tabView.shareholdersInfo.key)}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Translation>
    );
  };

  return (
    <>
      <TabPanel index={tabView.general.key} value={currentView.key}>
        <GeneralView companyData={companyData} />
      </TabPanel>
      {!isEmptyNestedChecks(partyData?.companyDetails?.shareholders) && (
        <TabPanel index={tabView.shareholdersInfo.key} value={currentView.key}>
          <ShareholdersDataView
            showUnmaskedData={showUnmaskedData}
            personDetails={partyData}
            handleChange={handleChange}
            backTabLink={tabView.general.key}
            caseConfig={caseConfig}
          />
        </TabPanel>
      )}
    </>
  );
};

export { CompanyData };
