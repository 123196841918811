import { AUDIT_TRAIL_ACTIONS } from 'modules/audit-trail/middleware/action-types';

export const getAllAuditTrails = ({
  pageSize,
  scrollId,
  sortingConfig,
  filterConfig,
  customHeader,
  tenantId,
}) => ({
  type: AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.data,
  payload: {
    paging: {
      pageSize,
      scrollId,
    },
    sorting: {
      orders: sortingConfig,
    },
    filtering: {
      filters: filterConfig,
      operator: 'AND',
    },
    customHeader,
    tenantId,
  },
});

export const getAllAuditTrailsSuccess = (payload, pageSize) => ({
  type: AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.success,
  payload: {
    ...payload,
    pageSize,
  },
});

export const getAllAuditTrailsError = () => ({
  type: AUDIT_TRAIL_ACTIONS.allAuditTrails.fetch.error,
});
