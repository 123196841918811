/**
 *
 * @deprecated please do not create new actions
 * use action creators from redux
 * visit: https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#writing-slices
 *
 * */

export const CaseActions = {
  CASES_BY_FORWARDING_TO_CASEID_GET: 'CASES_BY_FORWARDING_TO_CASEID_GET',
  CASES_BY_FORWARDING_TO_CASEID_GET_SUCCESS: 'CASES_BY_FORWARDING_TO_CASEID_GET_SUCCESS',
  CASES_BY_FORWARDING_TO_CASEID_GET_ERROR: 'CASES_BY_FORWARDING_TO_CASEID_GET_ERROR',
  CASE_DETAIL_SELECT: 'CASE_DETAIL_SELECT',
  CASE_DETAIL_GET: 'CASE_DETAIL_GET',
  CASE_DETAIL_GET_SUCCESS: 'CASE_DETAIL_GET_SUCCESS',
  CASE_DETAIL_GET_ERROR: 'CASE_DETAIL_GET_ERROR',
  CASE_DETAIL_CLEAR: 'CASE_DETAIL_CLEAR',
  FORWARDING_CASE_DETAIL_GET: 'FORWARDING_CASE_DETAIL_GET',
  FORWARDING_CASE_DETAIL_GET_SUCCESS: 'FORWARDING_CASE_DETAIL_GET_SUCCESS',
  FORWARDING_CASE_DETAIL_GET_ERROR: 'FORWARDING_CASE_DETAIL_GET_ERROR',
  CASE_STATE_CHANGES_GET: 'CASE_STATE_CHANGES_GET',
  CASE_STATE_CHANGES_GET_SUCCESS: 'CASE_STATE_CHANGES_GET_SUCCESS',
  CASE_STATE_CHANGES_GET_ERROR: 'CASE_STATE_CHANGES_GET_ERROR',
  CASE_STATUS_FORCEFUL_UPDATE: 'CASE_STATUS_FORCEFUL_UPDATE',
  CASE_STATUS_FORCEFUL_UPDATE_SUCCESS: 'CASE_STATUS_FORCEFUL_UPDATE_SUCCESS',
  CASE_STATUS_FORCEFUL_UPDATE_ERROR: 'CASE_STATUS_FORCEFUL_UPDATE_ERROR',

  CURRENCY_GET: 'CURRENCY_GET',
  CURRENCY_GET_SUCCESS: 'CURRENCY_GET_SUCCESS',
  CURRENCY_GET_ERROR: 'CURRENCY_GET_ERROR',

  CASE_STATE_UPDATE: 'CASE_STATE_UPDATE',
  CASE_STATE_UPDATE_SUCCESS: 'CASE_STATE_UPDATE_SUCCESS',
  CASE_STATE_UPDATE_ERROR: 'CASE_STATE_UPDATE_ERROR',
  SANCTION_HIT_VALID_MATCHES_UPDATE: 'SANCTION_HIT_VALID_MATCHES_UPDATE',
  SANCTION_HIT_VALID_MATCHES_UPDATE_SUCCESS: 'SANCTION_HIT_VALID_MATCHES_UPDATE_SUCCESS',
  SANCTION_HIT_VALID_MATCHES_UPDATE_ERROR: 'SANCTION_HIT_VALID_MATCHES_UPDATE_ERROR',

  ASSESS_VOLUME_RISK: 'ASSESS_VOLUME_RISK',
  ASSESS_VOLUME_RISK_SUCCESS: 'ASSESS_VOLUME_RISK_SUCCESS',
  ASSESS_VOLUME_RISK_ERROR: 'ASSESS_VOLUME_RISK_ERROR',

  ASSESS_COUNTRY_RISK: 'ASSESS_COUNTRY_RISK',
  ASSESS_COUNTRY_RISK_SUCCESS: 'ASSESS_COUNTRY_RISK_SUCCESS',
  ASSESS_COUNTRY_RISK_ERROR: 'ASSESS_COUNTRY_RISK_ERROR',

  ASSESS_LABEL: 'ASSESS_LABEL',
  ASSESS_LABEL_SUCCESS: 'ASSESS_LABEL_SUCCESS',
  ASSESS_LABEL_ERROR: 'ASSESS_LABEL_ERROR',

  ASSESS: 'ASSESS',
  ASSESS_SUCCESS: 'ASSESS_SUCCESS',
  ASSESS_ERROR: 'ASSESS_ERROR',

  TRANSACTIONS_GET: 'TRANSACTIONS_GET',
  TRANSACTIONS_GET_SUCCESS: 'TRANSACTIONS_GET_SUCCESS',
  TRANSACTIONS_GET_ERROR: 'TRANSACTIONS_GET_ERROR',

  ASSIGN_CASE_TO_OPERATOR: 'ASSIGN_CASE_TO_OPERATOR',
  ASSIGN_CASE_TO_OPERATOR_SUCCESS: 'ASSIGN_CASE_TO_OPERATOR_SUCCESS',
  ASSIGN_CASE_TO_OPERATOR_ERROR: 'ASSIGN_CASE_TO_OPERATOR_ERROR',

  GET_CUSTOMER_PREVIOUS_ALERTS: 'GET_CUSTOMER_PREVIOUS_ALERTS',
  GET_CUSTOMER_PREVIOUS_ALERTS_SUCCESS: 'GET_CUSTOMER_PREVIOUS_ALERTS_SUCCESS',
  GET_CUSTOMER_PREVIOUS_ALERTS_ERROR: 'GET_CUSTOMER_PREVIOUS_ALERTS_ERROR',

  GET_CUSTOMER_PROFILE_BALANCES: 'GET_CUSTOMER_PROFILE_BALANCES',
  GET_CUSTOMER_PROFILE_BALANCES_SUCCESS: 'GET_CUSTOMER_PROFILE_BALANCES_SUCCESS',
  GET_CUSTOMER_PROFILE_BALANCES_ERROR: 'GET_CUSTOMER_PROFILE_BALANCES_ERROR',

  GET_CASE_LAST_4_MONTHS_TX_METADATA: 'GET_CASE_LAST_4_MONTHS_TX_METADATA',
  GET_CASE_LAST_4_MONTHS_TX_METADATA_SUCCESS: 'GET_CASE_LAST_4_MONTHS_TX_METADATA_SUCCESS',
  GET_CASE_LAST_4_MONTHS_TX_METADATA_ERROR: 'GET_CASE_LAST_4_MONTHS_TX_METADATA_ERROR',

  GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE: 'GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE',
  GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE_SUCCESS:
    'GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE_SUCCESS',
  GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE_ERROR: 'GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE_ERROR',

  BREAK_OUT_CASE_FROM_GROUP: 'BREAK_OUT_CASE_FROM_GROUP',
  BREAK_OUT_CASE_FROM_GROUP_SUCCESS: 'BREAK_OUT_CASE_FROM_GROUP_SUCCESS',
  BREAK_OUT_CASE_FROM_GROUP_ERROR: 'BREAK_OUT_CASE_FROM_GROUP_ERROR',

  ADD_CASE_TO_CASE_GROUP: 'ADD_CASE_TO_CASE_GROUP',
  ADD_CASE_TO_CASE_GROUP_SUCCESS: 'ADD_CASE_TO_CASE_GROUP_SUCCESS',
  ADD_CASE_TO_CASE_GROUP_ERROR: 'ADD_CASE_TO_CASE_GROUP_ERROR',

  TRANSACTIONS_V2: {
    get: 'TRANSACTIONS_GET_V2',
    resetScroll: 'TRANSACTION_RESET_SCROLL',
  },
};
export const CASE_ACTIONS = {
  allCases: {
    fetch: {
      data: 'ALL_CASES_GET',
      success: 'ALL_CASES_GET_SUCCESS',
      error: 'ALL_CASES_GET_ERROR',
    },
    list: {
      data: 'GET_CASE_LIST_BY_IDS',
      success: 'GET_CASE_LIST_BY_IDS_SUCCESS',
      error: 'GET_CASE_LIST_BY_IDS_ERROR',
    },
  },
  config: {
    fetch: {
      data: 'GET_CASE_DETAIL_CONFIG',
      success: 'GET_CASE_DETAIL_CONFIG_SUCCESS',
      error: 'GET_CASE_DETAIL_CONFIG_ERROR',
    },
  },
  transactionHistory: {
    reset: 'RESET_TRANSACTION_HISTORY',
  },
};

export const SanctionListActions = {
  GET_SANCTION_LIST: 'GET_SANCTION_LIST',
  GET_SANCTION_LIST_SUCCESS: 'GET_SANCTION_LIST_SUCCESS',
  GET_SANCTION_LIST_ERROR: 'GET_SANCTION_LIST_ERROR',

  GET_SANCTION_LIST_LAST_UPDATE: 'GET_SANCTION_LIST_LAST_UPDATE',
  GET_SANCTION_LIST_LAST_UPDATE_SUCCESS: 'GET_SANCTION_LIST_LAST_UPDATE_SUCCESS',
  GET_SANCTION_LIST_LAST_UPDATE_ERROR: 'GET_SANCTION_LIST_LAST_UPDATE_ERROR',
};

export const ErrorActions = {
  ERRORS_SHOW: 'ERRORS_SHOW',
  ERRORS_RETRY: 'ERRORS_RETRY',
  ERRORS_RETRY_BEGAN: 'ERRORS_RETRY_BEGAN',
  ERRORS_HIDE: 'ERRORS_HIDE',
};

export const NotificationActions = {
  NOTIFICATIONS_SHOW: 'NOTIFICATIONS_SHOW',
  NOTIFICATIONS_HIDE: 'NOTIFICATIONS_HIDE',
  NOTIFICATIONS_HIDE_ALL: 'NOTIFICATIONS_HIDE_ALL',
  UPDATE_NOTIFICATION_SHOW_TIME: 'UPDATE_NOTIFICATION_SHOW_TIME',
};

// TODO: change to new structure
export const AuthActions = {
  LOGIN: 'LOGIN',

  LOGIN_WITH_SSO_START: 'LOGIN_WITH_SSO_SUCCESS_START',
  LOGIN_WITH_SSO_SUCCESS: 'LOGIN_WITH_SSO_SUCCESS',
  LOGIN_WITH_SSO_ERROR: 'LOGIN_WITH_SSO_ERROR',
  LOGOUT_WITH_SSO_SUCCESS: 'LOGOUT_WITH_SSO_SUCCESS',

  LOGIN_WITH_DATABASE_START: 'LOGIN_WITH_DATABASE_START',
  LOGIN_WITH_DATABASE_SUCCESS: 'LOGIN_WITH_DATABASE_SUCCESS',
  LOGIN_WITH_DATABASE_ERROR: 'LOGIN_WITH_DATABASE_ERROR',
  LOGOUT_WITH_DATABASE_SUCCESS: 'LOGOUT_WITH_DATABASE_SUCCESS',

  REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
  REQUEST_RESET_PASSWORD_SUCCESS: 'REQUEST_RESET_PASSWORD_SUCCESS',
  REQUEST_RESET_PASSWORD_ERROR: 'REQUEST_RESET_PASSWORD_ERROR',

  SET_USER_INFOS: 'SET_USER_INFOS',
  SET_GLOBAL_STATE: 'SET_GLOBAL_STATE',
  FETCH_USER_CONFIG: 'FETCH_USER_CONFIG',
};

export const MASKED_ACTIONS = {
  unmask: {
    data: 'UNMASK',
    success: 'UNMASK_SUCCESS',
    error: 'UNMASK_ERROR',
    update: 'UPDATE_MASKS',
  },
  unmaskCases: {
    success: 'UNMASK_CASES_SUCCESS',
  },
  unveilSanctionHitName: {
    data: 'UNVEIL_SANCTION_HIT_NAMES',
    success: 'UNVEIL_SANCTION_HIT_NAMES_SUCCESS',
    error: 'UNVEIL_SANCTION_HIT_NAMES_ERROR',
  },
  unveilCounterPartyData: {
    data: 'UNVEIL_COUNTERPARTY_DATA',
    success: 'UNVEIL_COUNTERPARTY_DATA_SUCCESS',
    error: 'UNVEIL_COUNTERPARTY_DATA_ERROR',
  },
  unveilMasterData: {
    data: 'UNVEIL_MASTER_DATA',
    success: 'UNVEIL_MASTER_DATA_SUCCESS',
    error: 'UNVEIL_MASTER_DATA_ERROR',
  },
  dataShowState: {
    cases: 'UPDATE_CASES_DATA_SHOW_STATE',
    caseDetail: 'UPDATE_CASE_DETAIL_DATA_SHOW_STATE',
  },
};

export const AuditTrackActions = {
  REQUEST_ADD_AUDIT_TRACK: 'REQUEST_ADD_AUDIT_TRACK',
  ADD_AUDIT_TRACK_SUCCESS: 'ADD_AUDIT_TRACK_SUCCESS',
  ADD_AUDIT_TRACK_ERROR: 'ADD_AUDIT_TRACK_ERROR',

  AUDIT_TRACK_GET: 'AUDIT_TRACK_GET',
  AUDIT_TRACK_GET_SUCCESS: 'AUDIT_TRACK_GET_SUCCESS',
  AUDIT_TRACK_GET_ERROR: 'AUDIT_TRACK_GET_ERROR',

  GET_AUDIT_TRACK_UNIQUE_USER_ID: 'GET_AUDIT_TRACK_UNIQUE_USER_ID',
  GET_AUDIT_TRACK_UNIQUE_USER_ID_SUCCESS: 'GET_AUDIT_TRACK_UNIQUE_USER_ID_SUCCESS',
  GET_AUDIT_TRACK_UNIQUE_USER_ID_ERROR: 'GET_AUDIT_TRACK_UNIQUE_USER_ID_ERROR',

  ADD_STAND_ALONE_AUDIT_TRACK: 'ADD_STAND_ALONE_AUDIT_TRACK',
  ADD_STAND_ALONE_AUDIT_TRACK_SUCCESS: 'ADD_STAND_ALONE_AUDIT_TRACK_SUCCESS',
  ADD_STAND_ALONE_AUDIT_TRACK_ERROR: 'ADD_STAND_ALONE_AUDIT_TRACK_ERROR',
};

export const PepSanctionConfigActions = {
  GET_PEP_SANCTION_CONFIG: 'GET_PEP_SANCTION_CONFIG',
  GET_PEP_SANCTION_CONFIG_SUCCESS: 'GET_PEP_SANCTION_CONFIG_SUCCESS',
  GET_PEP_SANCTION_CONFIG_ERROR: 'GET_PEP_SANCTION_CONFIG_ERROR',

  GET_PEP_SANCTION_REGIONS: 'GET_PEP_SANCTION_REGIONS',
  GET_PEP_SANCTION_REGIONS_SUCCESS: 'GET_PEP_SANCTION_REGIONS_SUCCESS',
  GET_PEP_SANCTION_REGIONS_ERROR: 'GET_PEP_SANCTION_REGIONS_ERROR',

  GET_REGION_SANCTION_LISTS: 'GET_REGION_SANCTION_LISTS',
  GET_REGION_SANCTION_LISTS_SUCCESS: 'GET_REGION_SANCTION_LISTS_SUCCESS',
  GET_REGION_SANCTION_LISTS_ERROR: 'GET_REGION_SANCTION_LISTS_ERROR',
};

export const BLACKLIST_CONFIG_ACTIONS = {
  update: {
    data: 'SAVE_BLACKLIST_CONFIG',
    success: 'SAVE_BLACKLIST_CONFIG_SUCCESS',
    error: 'SAVE_BLACKLIST_CONFIG_ERROR',
  },
  get: {
    data: 'GET_BLACKLIST_CONFIG',
    success: 'GET_BLACKLIST_CONFIG_SUCCESS',
    error: 'GET_BLACKLIST_CONFIG_ERROR',
  },
  delete: {
    data: 'DELETE_BLACKLIST_CONFIG',
    success: 'DELETE_BLACKLIST_CONFIG_SUCCESS',
    error: 'DELETE_BLACKLIST_CONFIG_ERROR',
  },
};

export const AssessmentEngineConfigActions = {
  ASSESSMENT_ENGINE_CONFIG_GET: 'ASSESSMENT_ENGINE_CONFIG_GET',
  ASSESSMENT_ENGINE_CONFIG_GET_SUCCESS: 'ASSESSMENT_ENGINE_CONFIG_GET_SUCCESS',
  ASSESSMENT_ENGINE_CONFIG_GET_ERROR: 'ASSESSMENT_ENGINE_CONFIG_GET_ERROR',
  ASSESSMENT_ENGINE_CONFIG_UPDATE: 'ASSESSMENT_ENGINE_CONFIG_UPDATE',
  ASSESSMENT_ENGINE_CONFIG_UPDATE_SUCCESS: 'ASSESSMENT_ENGINE_CONFIG_UPDATE_SUCCESS',
  ASSESSMENT_ENGINE_CONFIG_UPDATE_ERROR: 'ASSESSMENT_ENGINE_CONFIG_UPDATE_ERROR',
};

export const ImageActions = {
  SHOW_BENEFICIARY_IMAGE: 'SHOW_BENEFICIARY_IMAGE',
  SHOW_BENEFICIARY_IMAGE_SUCCESS: 'SHOW_BENEFICIARY_IMAGE_SUCCESS',
  SHOW_BENEFICIARY_IMAGE_ERROR: 'SHOW_BENEFICIARY_IMAGE_ERROR',

  SHOW_ORIGINATOR_IMAGE: 'SHOW_ORIGINATOR_IMAGE',
  SHOW_ORIGINATOR_IMAGE_SUCCESS: 'SHOW_ORIGINATOR_IMAGE_SUCCESS',
  SHOW_ORIGINATOR_IMAGE_ERROR: 'SHOW_ORIGINATOR_IMAGE_ERROR',
};

export const VolumeRiskConfigActions = {
  VOLUME_RISK_CONFIG_GET: 'VOLUME_RISK_CONFIG_GET',
  VOLUME_RISK_CONFIG_GET_SUCCESS: 'VOLUME_RISK_CONFIG_GET_SUCCESS',
  VOLUME_RISK_CONFIG_GET_ERROR: 'VOLUME_RISK_CONFIG_GET_ERROR',
  VOLUME_RISK_CONFIG_UPDATE: 'VOLUME_RISK_CONFIG_UPDATE',
  VOLUME_RISK_CONFIG_UPDATE_SUCCESS: 'VOLUME_RISK_CONFIG_UPDATE_SUCCESS',
  VOLUME_RISK_CONFIG_UPDATE_ERROR: 'VOLUME_RISK_CONFIG_UPDATE_ERROR',
};

export const ConfigurationActions = {
  GET_CONFIGURATION_LAST_UPDATE_INFO: 'GET_CONFIGURATION_LAST_UPDATE_INFO',
  GET_CONFIGURATION_LAST_UPDATE_INFO_SUCCESS: 'GET_CONFIGURATION_LAST_UPDATE_INFO_SUCCESS',
  GET_CONFIGURATION_LAST_UPDATE_INFO_ERROR: 'GET_CONFIGURATION_LAST_UPDATE_INFO_ERROR',
};

export const DecisionEngineActions = {
  GET_DECISION_ENGINE_CONFIG: 'GET_DECISION_ENGINE_CONFIG',
  GET_DECISION_ENGINE_CONFIG_SUCCESS: 'GET_DECISION_ENGINE_CONFIG_SUCCESS',
  GET_DECISION_ENGINE_CONFIG_ERROR: 'GET_DECISION_ENGINE_CONFIG_ERROR',

  UPDATE_DECISION_ENGINE_CONFIG: 'UPDATE_DECISION_ENGINE_CONFIG',
  UPDATE_DECISION_ENGINE_CONFIG_SUCCESS: 'UPDATE_DECISION_ENGINE_CONFIG_SUCCESS',
  UPDATE_DECISION_ENGINE_CONFIG_ERROR: 'UPDATE_DECISION_ENGINE_CONFIG_ERROR',
};

export const SuspiciousWordsActions = {
  GET_SUSPICIOUS_WORD_LIST: 'GET_SUSPICIOUS_WORD_LIST',
  GET_SUSPICIOUS_WORD_LIST_SUCCESS: 'GET_SUSPICIOUS_WORD_LIST_SUCCESS',
  GET_SUSPICIOUS_WORD_LIST_ERROR: 'GET_SUSPICIOUS_WORD_LIST_ERROR',

  ACTIVATE_SUSPICIOUS_WORD_LIST: 'ACTIVATE_SUSPICIOUS_WORD_LIST',
  ACTIVATE_SUSPICIOUS_WORD_LIST_SUCCESS: 'ACTIVATE_SUSPICIOUS_WORD_LIST_SUCCESS',
  ACTIVATE_SUSPICIOUS_WORD_LIST_ERROR: 'ACTIVATE_SUSPICIOUS_WORD_LIST_ERROR',
};

export const NETWORK_ANALYSIS_ACTION = {
  GET_NETWORK_ANALYSIS: 'GET_NETWORK_ANALYSIS',
  GET_NETWORK_ANALYSIS_SUCCESS: 'GET_NETWORK_ANALYSIS_SUCCESS',
  GET_NETWORK_ANALYSIS_ERROR: 'GET_NETWORK_ANALYSIS_ERROR',

  GET_PASSTHROUGH_NETWORK: 'GET_PASSTHROUGH_NETWORK',
  GET_PASSTHROUGH_NETWORK_SUCCESS: 'GET_PASSTHROUGH_NETWORK_SUCCESS',
  GET_PASSTHROUGH_NETWORK_ERROR: 'GET_PASSTHROUGH_NETWORK_ERROR',

  passthroughV2: {
    get: 'GET_PASSTHROUGH_V2_NETWORK',
    success: 'GET_PASSTHROUGH_V2_NETWORK_SUCCESS',
    error: 'GET_PASSTHROUGH_V2_NETWORK_ERROR',
  },
  passthroughV3: {
    get: 'GET_PASSTHROUGH_V3_NETWORK',
    success: 'GET_PASSTHROUGH_V3_NETWORK_SUCCESS',
    error: 'GET_PASSTHROUGH_V3_NETWORK_ERROR',
  },
};

export const DetachmentUtilActions = {
  DETACHMENT_CONFIG_UTIL_GET: 'DETACHMENT_CONFIG_UTIL_GET',
  DETACHMENT_CONFIG_UTIL_GET_SUCCESS: 'DETACHMENT_CONFIG_UTIL_GET_SUCCESS',
  DETACHMENT_CONFIG_UTIL_GET_ERROR: 'DETACHMENT_CONFIG_UTIL_GET_ERROR',

  DETACHMENT_CONFIG_UTIL_INSERT: 'DETACHMENT_CONFIG_UTIL_INSERT',
  DETACHMENT_CONFIG_UTIL_INSERT_SUCCESS: 'DETACHMENT_CONFIG_UTIL_INSERT_SUCCESS',
  DETACHMENT_CONFIG_UTIL_INSERT_ERROR: 'DETACHMENT_CONFIG_UTIL_INSERT_ERROR',

  DETACHMENT_CONFIG_UTIL_UPDATE: 'DETACHMENT_CONFIG_UTIL_UPDATE',
  DETACHMENT_CONFIG_UTIL_UPDATE_SUCCESS: 'DETACHMENT_CONFIG_UTIL_UPDATE_SUCCESS',
  DETACHMENT_CONFIG_UTIL_UPDATE_ERROR: 'DETACHMENT_CONFIG_UTIL_UPDATE_ERROR',

  DETACHMENT_CONFIG_UTIL_RESET: 'DETACHMENT_CONFIG_UTIL_RESET',
  DETACHMENT_CONFIG_UTIL_RESET_SUCCESS: 'DETACHMENT_CONFIG_UTIL_RESET_SUCCESS',
  DETACHMENT_CONFIG_UTIL_RESET_ERROR: 'DETACHMENT_CONFIG_UTIL_RESET_ERROR',
};

export const LoginActions = {
  START_LOGIN_WITH_SSO: 'START_LOGIN_SSO',
  LOGIN_WITH_SSO_SUCCESS: 'LOGIN_WITH_SSO_SUCCESS',
  LOGIN_WITH_SSO_ERROR: 'LOGIN_WITH_SSO_ERROR',

  START_LOGIN_WITH_DATABASE: 'START_LOGIN_WITH_DATABASE',
  LOGIN_WITH_DATABASE_SUCCESS: 'LOGIN_WITH_DATABASE_SUCCESS',
  LOGIN_WITH_DATABASE_ERROR: 'LOGIN_WITH_DATABASE_ERROR',
};

export const ToolingActions = {
  REPROCESS_CASE_POST: 'REPROCESS_CASE_POST',
  REPROCESS_CASE_POST_SUCCESS: 'REPROCESS_CASE_POST_SUCCESS',
  REPROCESS_CASE_POST_ERROR: 'REPROCESS_CASE_POST_ERROR',
};

export const ConfluenceActions = {
  CONFLUENCE_ARTICLE_GET: 'CONFLUENCE_ARTICLE_GET',
  CONFLUENCE_ARTICLE_GET_SUCCESS: 'CONFLUENCE_ARTICLE_GET_SUCCESS',
  CONFLUENCE_ARTICLE_GET_ERROR: 'CONFLUENCE_ARTICLE_GET_ERROR',
};

export const MCC_LIST_ACTIONS = {
  fileList: {
    fetch: 'GET_MCC_FILE_LIST',
    success: 'GET_MCC_FILE_LIST_SUCCESS',
    error: 'GET_MCC_FILE_LIST_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_MCC_LIST',
    success: 'ACTIVATE_MCC_LIST_SUCCESS',
    error: 'ACTIVATE_MCC_LIST_ERROR',
  },
};

export const HIDTA_HIFCA_LIST_ACTIONS = {
  fileList: {
    get: 'GET_HIDTA_HIFCA_FILE_LIST',
    success: 'GET_HIDTA_HIFCA_FILE_LIST_SUCCESS',
    error: 'GET_HIDTA_HIFCA_FILE_LIST_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_HIDTA_HIFCA_LIST',
    success: 'ACTIVATE_HIDTA_HIFCA_LIST_SUCCESS',
    error: 'ACTIVATE_HIDTA_HIFCA_LIST_ERROR',
  },
};

export const OCCUPATION_LIST_ACTIONS = {
  fileList: {
    fetch: 'GET_OCCUPATION_FILE_LIST',
    success: 'GET_OCCUPATION_FILE_LIST_SUCCESS',
    error: 'GET_OCCUPATION_FILE_LIST_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_OCCUPATION_LIST',
    success: 'ACTIVATE_OCCUPATION_LIST_SUCCESS',
    error: 'ACTIVATE_OCCUPATION_LIST_ERROR',
  },
};

export const PRODUCT_TYPE_LIST_ACTIONS = {
  fileList: {
    fetchIndividual: 'GET_PRODUCT_TYPE_FILE_LIST_INDIVIDUAL',
    fetchOrganization: 'GET_PRODUCT_TYPE_FILE_LIST_ORGANIZATION',
    individualSuccess: 'GET_PRODUCT_TYPE_FILE_LIST_INDIVIDUAL_SUCCESS',
    organizationSuccess: 'GET_PRODUCT_TYPE_FILE_LIST_ORGANIZATION_SUCCESS',
    individualError: 'GET_PRODUCT_TYPE_FILE_LIST_INDIVIDUAL_ERROR',
    organizationError: 'GET_PRODUCT_TYPE_FILE_LIST_ORGANIZATION_ERROR',
  },
  activateVersion: {
    sendIndividual: 'ACTIVATE_PRODUCT_TYPE_LIST_INDIVIDUAL',
    sendOrganization: 'ACTIVATE_PRODUCT_TYPE_LIST_ORGANIZATION',
    individualSuccess: 'ACTIVATE_PRODUCT_TYPE_LIST_INDIVIDUAL_SUCCESS',
    organizationSuccess: 'ACTIVATE_PRODUCT_TYPE_LIST_ORGANIZATION_SUCCESS',
    individualError: 'ACTIVATE_PRODUCT_TYPE_LIST_INDIVIDUAL_ERROR',
    organizationError: 'ACTIVATE_PRODUCT_TYPE_LIST_ORGANIZATION_ERROR',
  },
};

export const PEP_SANCTION_CONFIG_ACTIONS = {
  update: {
    data: 'SAVE_PEP_SANCTION_CONFIG',
    success: 'SAVE_PEP_SANCTION_CONFIG_SUCCESS',
    error: 'SAVE_PEP_SANCTION_CONFIG_ERROR',
  },
  updateInstanciable: {
    data: 'SAVE_INSTANCIABLE_PEP_SANCTION_CONFIG',
    success: 'SAVE_INSTANCIABLE_PEP_SANCTION_CONFIG_SUCCESS',
    error: 'SAVE_INSTANCIABLE_PEP_SANCTION_CONFIG_ERROR',
  },
  instanciable: {
    get: 'GET_INSTANCIABLE_PEP_SANCTION_CONFIG',
    success: 'GET_INSTANCIABLE_PEP_SANCTION_CONFIG_SUCCESS',
    error: 'GET_INSTANCIABLE_PEP_SANCTION_CONFIG_ERROR',
  },
  deleteInstanciable: {
    send: 'DELETE_INSTANCIABLE_PEP_SANCTION_CONFIG',
    success: 'DELETE_INSTANCIABLE_PEP_SANCTION_CONFIG_SUCCESS',
    error: 'DELETE_INSTANCIABLE_PEP_SANCTION_CONFIG_ERROR',
  },
  updateInstanciableProvider: {
    data: 'SAVE_INSTANCIABLE_PEP_SANCTION_PROVIDER',
    success: 'SAVE_INSTANCIABLE_PEP_SANCTION_PROVIDER_SUCCESS',
    error: 'SAVE_INSTANCIABLE_PEP_SANCTION_PROVIDER_ERROR',
  },
};

export const TOOLING_ACTIONS = {
  query: {
    send: 'REPROCESS_QUERY',
    success: 'REPROCESS_QUERY_SUCCESS',
    error: 'REPROCESS_QUERY_ERROR',
  },
  reprocess: {
    send: 'REPROCESS_EXECUTE',
    success: 'REPROCESS_EXECUTE_SUCCESS',
    error: 'REPROCESS_EXECUTE_ERROR',
  },
};

export const GOAML_ACTIONS = {
  getXML: {
    data: 'GET_GOAML_XML',
    success: 'GET_GOAML_XML_SUCCESS',
    error: 'GET_GOAML_XML_ERROR',
  },
  saveXML: {
    data: 'SAVE_GOAML_XML',
    success: 'SAVE_GOAML_XML_SUCCESS',
    error: 'SAVE_GOAML_XML_ERROR',
  },
  validateXML: {
    data: 'VALIDATE_GOAML_XML',
    success: 'VALIDATE_GOAML_XML_SUCCESS',
    error: 'VALIDATE_GOAML_XML_ERROR',
  },
  clearCache: {
    data: 'CLEAR_GOAML_XML_CACHE',
  },
};

export const CUSTOMER_DETAILS_ACTIONS = {
  get: {
    data: 'GET_CUSTOMER_DETAILS_DATA',
    success: 'GET_CUSTOMER_DETAILS_DATA_SUCCESS',
    error: 'GET_CUSTOMER_DETAILS_DATA_ERROR',
  },
};

export const CUSTOMER_RISK_RATING_CONFIG_ACTIONS = {
  organization: {
    get: {
      data: 'GET_CUSTOMER_RISK_RATING_CONFIG_DATA',
      success: 'GET_CUSTOMER_RISK_RATING_CONFIG_SUCCESS',
      error: 'GET_CUSTOMER_RISK_RATING_CONFIG_ERROR',
    },
    update: {
      data: 'UPDATE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_DATA',
      success: 'UPDATE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_SUCCESS',
      error: 'UPDATE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_ERROR',
    },
    requestForUpdate: {
      data: 'REQUEST_FOR_UPDATE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_DATA',
      success: 'REQUEST_FOR_UPDATE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_SUCCESS',
      error: 'REQUEST_FOR_UPDATE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_ERROR',
    },
    delete: {
      data: 'DELETE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_DATA',
      error: 'DELETE_CUSTOMER_RISK_RATING_CONFIG_ACTIONS_ERROR',
    },
    createRiskFactor: {
      data: 'CREATE_RISK_FACTOR_DATA',
      success: 'CREATE_RISK_FACTOR_SUCCESS',
      error: 'CREATE_RISK_FACTOR_ERROR',
    },
    deleteRiskFactor: {
      data: 'DELETE_RISK_FACTOR_DATA',
      success: 'DELETE_RISK_FACTOR_SUCCESS',
      error: 'DELETE_RISK_FACTOR_ERROR',
    },
  },
  individual: {
    get: {
      data: 'GET_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_DATA',
      success: 'GET_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_SUCCESS',
      error: 'GET_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_ERROR',
    },
    update: {
      data: 'UPDATE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_DATA',
      success: 'UPDATE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_SUCCESS',
      error: 'UPDATE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_ERROR',
    },
    requestForUpdate: {
      data: 'REQUEST_FOR_UPDATE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_DATA',
      success: 'REQUEST_FOR_UPDATE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_SUCCESS',
      error: 'REQUEST_FOR_UPDATE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_ERROR',
    },
    createRiskFactor: {
      data: 'CREATE_RISK_FACTOR_FOR_INDIVIDUAL_DATA',
      success: 'CREATE_RISK_FACTOR_FOR_INDIVIDUAL_SUCCESS',
      error: 'CREATE_RISK_FACTOR_FOR_INDIVIDUAL_ERROR',
    },
    deleteRiskFactor: {
      data: 'DELETE_RISK_FACTOR_FOR_INDIVIDUAL_DATA',
      success: 'DELETE_RISK_FACTOR_FOR_INDIVIDUAL_SUCCESS',
      error: 'DELETE_RISK_FACTOR_FOR_INDIVIDUAL_ERROR',
    },
    delete: {
      data: 'DELETE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_DATA',
      error: 'DELETE_CUSTOMER_RISK_RATING_CONFIG_FOR_INDIVIDUAL_ERROR',
    },
  },
};

export const CUSTOMER_DATA_DETAILS_ACTIONS = {
  actions: {
    doManualCustomerCheck: {
      data: 'CUSTOMER_DATA_ACTION_DO_MANUAL_CUSTOMER_CHECK',
    },
    createManualCustomerCase: {
      data: 'CUSTOMER_DATA_ACTION_CREATE_MANUAL_CUSTOMER_CASE',
    },
  },
};

export const SETTINGS_ACTIONS = {
  get: {
    data: 'GET_SETTINGS_DATA',
    success: 'GET_SETTINGS_DATA_SUCCESS',
    error: 'GET_SETTINGS_DATA_ERROR',
  },
  update: {
    data: 'UPDATE_SETTINGS_DATA',
    success: 'UPDATE_SETTINGS_DATA_SUCCESS',
    error: 'UPDATE_SETTINGS_DATA_ERROR',
  },
  countryRisks: {
    update: {
      data: 'UPDATE_SETTINGS_COUNTRY_RISKS',
      success: 'UPDATE_SETTINGS_COUNTRY_RISKS_SUCCESS',
      error: 'UPDATE_SETTINGS_COUNTRY_RISKS_ERROR',
    },
    delete: {
      data: 'DELETE_SETTINGS_COUNTRY_RISKS',
      success: 'DELETE_SETTINGS_COUNTRY_RISKS_SUCCESS',
      error: 'DELETE_SETTINGS_COUNTRY_RISKS_ERROR',
    },
  },
};

export const RISKY_WORDS_V2_LIST_ACTIONS = {
  fileList: {
    fetch: 'GET_RISKY_WORDS_V2_FILE_LIST',
    success: 'GET_RISKY_WORDS_V2_FILE_LIST_SUCCESS',
    error: 'GET_RISKY_WORDS_V2_FILE_LIST_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_RISKY_WORDS_V2_LIST',
    success: 'ACTIVATE_RISKY_WORDS_V2_LIST_SUCCESS',
    error: 'ACTIVATE_RISKY_WORDS_V2_LIST_ERROR',
  },
};
export const FILTERS_LIST_ACTIONS = {
  fileList: {
    fetch: 'GET_FILTERS_FILE_LIST',
    success: 'GET_FILTERS_FILE_LIST_SUCCESS',
    error: 'GET_FILTERS_FILE_LIST_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_FILTERS_LIST',
    success: 'ACTIVATE_FILTERS_LIST_SUCCESS',
    error: 'ACTIVATE_FILTERS_LIST_ERROR',
  },
};
export const BLACKLIST_LIST_ACTIONS = {
  fileList: {
    fetch: 'GET_BLACKLIST_FILE_LIST',
    success: 'GET_BLACKLIST_FILE_LIST_SUCCESS',
    error: 'GET_BLACKLIST_FILE_LIST_ERROR',
  },
  activateVersion: {
    send: 'ACTIVATE_BLACKLIST_LIST',
    success: 'ACTIVATE_BLACKLIST_LIST_SUCCESS',
    error: 'ACTIVATE_BLACKLIST_LIST_ERROR',
  },
};

export const COUNTRY_RISK_V2_ACTIONS = {
  fetch: {
    data: 'COUNTRY_RISK_V2_CONFIG_GET',
    success: 'COUNTRY_RISK_V2_CONFIG_GET_SUCCESS',
    error: 'COUNTRY_RISK_V2_CONFIG_GET_ERROR',
  },
  update: {
    data: 'COUNTRY_RISK_V2_CONFIG_UPDATE',
    success: 'COUNTRY_RISK_V2_CONFIG_UPDATE_SUCCESS',
    error: 'COUNTRY_RISK_V2_CONFIG_UPDATE_ERROR',
  },
  delete: {
    data: 'COUNTRY_RISK_V2_CONFIG_DELETE',
    success: 'COUNTRY_RISK_V2_CONFIG_DELETE_SUCCESS',
    error: 'COUNTRY_RISK_V2_CONFIG_DELETE_ERROR',
  },
};

export const RULE_CATEGORY_ACTIONS = {
  fetch: {
    data: 'RULE_CATEGORY_GET',
    success: 'RULE_CATEGORY_GET_SUCCESS',
    error: 'RULE_CATEGORY_GET_ERROR',
  },
};

export const TRANSACTION_FIELDS_ACTIONS = {
  fetch: {
    data: 'FETCH_TRANSACTION_FIELDS_GET',
    success: 'FETCH_TRANSACTION_FIELDS_SUCCESS',
    error: 'FETCH_TRANSACTION_FIELDS_ERROR',
  },
};

export const PLAUSIBILITY_ACTIONS = {
  fetch: {
    data: 'PLAUSIBILITY_CONFIG_GET',
    success: 'PLAUSIBILITY_CONFIG_GET_SUCCESS',
    error: 'PLAUSIBILITY_CONFIG_GET_ERROR',
  },
  update: {
    data: 'PLAUSIBILITY_CONFIG_UPDATE',
    success: 'PLAUSIBILITY_CONFIG_UPDATE_SUCCESS',
    error: 'PLAUSIBILITY_CONFIG_UPDATE_ERROR',
  },
  delete: {
    data: 'PLAUSIBILITY_CONFIG_DELETE',
    success: 'PLAUSIBILITY_CONFIG_DELETE_SUCCESS',
    error: 'PLAUSIBILITY_CONFIG_DELETE_ERROR',
  },
};

export const EXPECTED_CUSTOMER_VALUE_ACTIONS = {
  fetch: {
    data: 'EXPECTED_CUSTOMER_VALUE_CONFIG_GET',
    success: 'EXPECTED_CUSTOMER_VALUE_CONFIG_GET_SUCCESS',
    error: 'EXPECTED_CUSTOMER_VALUE_CONFIG_GET_ERROR',
  },
  update: {
    send: 'EXPECTED_CUSTOMER_VALUE_CONFIG_UPDATE',
    success: 'EXPECTED_CUSTOMER_VALUE_CONFIG_UPDATE_SUCCESS',
    error: 'EXPECTED_CUSTOMER_VALUE_CONFIG_UPDATE_ERROR',
  },
  delete: {
    send: 'EXPECTED_CUSTOMER_VALUE_CONFIG_DELETE',
    success: 'EXPECTED_CUSTOMER_VALUE_CONFIG_DELETE_SUCCESS',
    error: 'EXPECTED_CUSTOMER_VALUE_CONFIG_DELETE_ERROR',
  },
};

/**
 *
 * @deprecated please do not create new actions
 * use action creators from redux
 * visit: https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#writing-slices
 *
 * */
