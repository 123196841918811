import {
  AuditTrackActions,
  CASE_ACTIONS,
  CaseActions,
  ImageActions,
} from 'middleware/action-types';

export const getCasesByForwardingToCaseId = (
  forwardingToCaseId,
  tenantId,
  page,
  pageSize,
  loadedSettings,
) => ({
  type: CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET,
  forwardingToCaseId,
  tenantId,
  page,
  pageSize,
  loadedSettings,
});

export const getCasesByForwardingToCaseIdSuccess = (payload) => ({
  type: CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET_SUCCESS,
  payload,
});

export const getCasesByForwardingToCaseIdError = () => ({
  type: CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET_ERROR,
});

export const updateCaseStatusForcefully = (payload) => ({
  type: CaseActions.CASE_STATUS_FORCEFUL_UPDATE,
  payload,
});

export const updateCaseStatusForcefullySuccess = (payload) => ({
  type: CaseActions.CASE_STATUS_FORCEFUL_UPDATE_SUCCESS,
  payload,
});

export const updateCaseStatusForcefullyError = () => ({
  type: CaseActions.CASE_STATUS_FORCEFUL_UPDATE_ERROR,
});

export const getAuditTracks = (
  size,
  page,
  sortParams,
  filterColumn,
  filterValue,
  filterOperator,
  filterStartDate,
  filterEndDate,
  excludeSystemUser,
) => ({
  type: AuditTrackActions.AUDIT_TRACK_GET,
  size,
  page,
  sortParams,
  filterColumn,
  filterValue,
  filterOperator,
  filterStartDate,
  filterEndDate,
  excludeSystemUser,
});

export const getAuditTracksSuccess = (payload) => ({
  type: AuditTrackActions.AUDIT_TRACK_GET_SUCCESS,
  payload,
});

export const getAuditTracksError = () => ({
  type: AuditTrackActions.AUDIT_TRACK_GET_ERROR,
});

export const getDistinctCurrencyValuesByCaseStatus = (caseStatus) => ({
  type: CaseActions.CURRENCY_GET,
  caseStatus,
});

export const getDistinctCurrencyValuesByCaseStatusSuccess = (payload) => ({
  type: CaseActions.CURRENCY_GET_SUCCESS,
  payload,
});

export const getDistinctCurrencyValuesByCaseStatusError = () => ({
  type: CaseActions.CURRENCY_GET_ERROR,
});

export const selectCaseDetail = (caseDetail) => ({
  type: CaseActions.CASE_DETAIL_SELECT,
  caseDetail,
});

export const getCaseDetail = (
  uuid,
  firstLoad = false,
  caseCreatedAt,
  shouldGetCaseStateChanges = true,
) => ({
  type: CaseActions.CASE_DETAIL_GET,
  uuid,
  firstLoad,
  caseCreatedAt,
  shouldGetCaseStateChanges,
});

export const clearCaseDetail = () => ({
  type: CaseActions.CASE_DETAIL_CLEAR,
});

export const getCaseDetailSuccess = (payload) => ({
  type: CaseActions.CASE_DETAIL_GET_SUCCESS,
  payload,
});

export const getCaseDetailError = () => ({
  type: CaseActions.CASE_DETAIL_GET_ERROR,
});

export const getForwardingCaseDetail = (uuid, caseCreatedAt) => ({
  type: CaseActions.FORWARDING_CASE_DETAIL_GET,
  uuid,
  caseCreatedAt,
});

export const getForwardingCaseDetailSuccess = (payload) => ({
  type: CaseActions.FORWARDING_CASE_DETAIL_GET_SUCCESS,
  payload,
});

export const getForwardingCaseDetailError = () => ({
  type: CaseActions.FORWARDING_CASE_DETAIL_GET_ERROR,
});

export const getCaseStateChanges = (uuid, caseTenantId) => ({
  type: CaseActions.CASE_STATE_CHANGES_GET,
  payload: {
    uuid,
    caseTenantId,
  },
});

export const getCaseStateChangesSuccess = (payload) => ({
  type: CaseActions.CASE_STATE_CHANGES_GET_SUCCESS,
  payload,
});

export const getCaseStateChangesError = () => ({
  type: CaseActions.CASE_STATE_CHANGES_GET_ERROR,
});

export const updateCaseState = (
  uuid,
  createdAt,
  caseStateChange,
  userId,
  note,
  feedback,
  caseStateChangeNote,
  caseTenantId,
  assessment,
) => ({
  type: CaseActions.CASE_STATE_UPDATE,
  payload: {
    uuid,
    createdAt,
    caseStateChange,
    userId,
    note,
    feedback,
    caseStateChangeNote,
    caseTenantId,
    assessment,
  },
});

export const updateCaseStateSuccess = (payload) => ({
  type: CaseActions.CASE_STATE_UPDATE_SUCCESS,
  payload,
});

export const updateCaseStateError = () => ({
  type: CaseActions.CASE_STATE_UPDATE_ERROR,
});

export const updateSanctionHitValidMatches = (payload) => ({
  type: CaseActions.SANCTION_HIT_VALID_MATCHES_UPDATE,
  payload,
});

export const updateSanctionHitValidMatchesSuccess = (payload, requestId) => ({
  type: CaseActions.SANCTION_HIT_VALID_MATCHES_UPDATE_SUCCESS,
  payload,
  requestId,
});

export const updateSanctionHitValidMatchesError = () => ({
  type: CaseActions.SANCTION_HIT_VALID_MATCHES_UPDATE_ERROR,
});

export const getDistinctUserIdsFromAuditTracks = () => ({
  type: AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID,
});

export const getDistinctUserIdsFromAuditTracksSuccess = (payload) => ({
  type: AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID_SUCCESS,
  payload,
});

export const getDistinctUserIdsFromAuditTracksError = () => ({
  type: AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID_ERROR,
});

export const addAuditTrack = (
  masterUuid,
  uuid,
  userId,
  message,
  event,
  caseStateChange,
  reloadCaseId,
) => ({
  type: AuditTrackActions.REQUEST_ADD_AUDIT_TRACK,
  payload: {
    masterUuid,
    uuid,
    userId,
    message,
    event,
    caseStateChange,
    reloadCaseId,
  },
});

export const addAuditTrackSuccess = (payload) => ({
  type: AuditTrackActions.ADD_AUDIT_TRACK_SUCCESS,
  payload,
});

export const addAuditTrackError = () => ({
  type: AuditTrackActions.ADD_AUDIT_TRACK_ERROR,
});

export const showOriginatorImage = (payload) => ({
  type: ImageActions.SHOW_ORIGINATOR_IMAGE,
  payload,
});

export const showOriginatorImageSuccess = () => ({
  type: ImageActions.SHOW_ORIGINATOR_IMAGE_SUCCESS,
});

export const showOriginatorImageError = () => ({
  type: ImageActions.SHOW_ORIGINATOR_IMAGE_ERROR,
});

export const showBeneficiaryImage = (payload) => ({
  type: ImageActions.SHOW_BENEFICIARY_IMAGE,
  payload,
});

export const showBeneficiaryImageSuccess = () => ({
  type: ImageActions.SHOW_BENEFICIARY_IMAGE_SUCCESS,
});

export const showBeneficiaryImageError = () => ({
  type: ImageActions.SHOW_BENEFICIARY_IMAGE_ERROR,
});

export const assessVolumeRisk = (payload) => ({
  type: CaseActions.ASSESS_VOLUME_RISK,
  payload,
});

export const assessVolumeRiskSuccess = (payload) => ({
  type: CaseActions.ASSESS_VOLUME_RISK_SUCCESS,
  payload,
});

export const assessVolumeRiskError = () => ({
  type: CaseActions.ASSESS_VOLUME_RISK_ERROR,
});

export const assessCountryRisk = (payload) => ({
  type: CaseActions.ASSESS_COUNTRY_RISK,
  payload,
});

export const assessCountryRiskSuccess = (payload) => ({
  type: CaseActions.ASSESS_COUNTRY_RISK_SUCCESS,
  payload,
});

export const assessCountryRiskError = () => ({
  type: CaseActions.ASSESS_COUNTRY_RISK_ERROR,
});

export const assessLabel = (payload) => ({
  type: CaseActions.ASSESS_LABEL,
  payload,
});

export const assessLabelSuccess = (payload) => ({
  type: CaseActions.ASSESS_LABEL_SUCCESS,
  payload,
});

export const assessLabelError = () => ({
  type: CaseActions.ASSESS_LABEL_ERROR,
});

export const assess = (payload) => ({
  type: CaseActions.ASSESS,
  payload,
});

export const assessSuccess = (payload) => ({
  type: CaseActions.ASSESS_SUCCESS,
  payload,
});

export const assessError = () => ({
  type: CaseActions.ASSESS_ERROR,
});

export const getTransactions = (payload) => ({
  type: CaseActions.TRANSACTIONS_GET,
  payload,
});

export const getTransactionsV2 = (payload) => ({
  type: CaseActions.TRANSACTIONS_V2.get,
  payload,
});

export const getTransactionsSuccess = (payload) => ({
  type: CaseActions.TRANSACTIONS_GET_SUCCESS,
  payload,
});

export const getTransactionsError = () => ({
  type: CaseActions.TRANSACTIONS_GET_ERROR,
});

export const addStandaloneAuditTrack = (userId, message, event, sectionId) => ({
  type: AuditTrackActions.ADD_STAND_ALONE_AUDIT_TRACK,
  payload: {
    userId,
    message,
    event,
    sectionId,
  },
});

export const addStandaloneAuditTrackSuccess = (payload) => ({
  type: AuditTrackActions.ADD_STAND_ALONE_AUDIT_TRACK_SUCCESS,
  payload,
});

export const addStandaloneAuditTrackError = () => ({
  type: AuditTrackActions.ADD_STAND_ALONE_AUDIT_TRACK_ERROR,
});

export const assignCaseToOperator = (payload) => ({
  type: CaseActions.ASSIGN_CASE_TO_OPERATOR,
  payload,
});

export const assignCaseToOperatorSuccess = (payload) => ({
  type: CaseActions.ASSIGN_CASE_TO_OPERATOR_SUCCESS,
  payload,
});

export const assignCaseToOperatorError = () => ({
  type: CaseActions.ASSIGN_CASE_TO_OPERATOR_ERROR,
});

export const getCustomerPreviousAlerts = (payload) => ({
  type: CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS,
  payload,
});

export const getCustomerPreviousAlertsSuccess = (payload) => ({
  type: CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS_SUCCESS,
  payload,
});

export const getCustomerPreviousAlertsError = () => ({
  type: CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS_ERROR,
});

export const getCustomerProfileBalances = (payload) => ({
  type: CaseActions.GET_CUSTOMER_PROFILE_BALANCES,
  payload,
});

export const getCustomerProfileBalancesSuccess = (payload) => ({
  type: CaseActions.GET_CUSTOMER_PROFILE_BALANCES_SUCCESS,
  payload,
});

export const getCustomerProfileBalancesError = () => ({
  type: CaseActions.GET_CUSTOMER_PROFILE_BALANCES_ERROR,
});

export const getCaseLast4MonthsTxMetadata = (payload) => ({
  type: CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA,
  payload,
});

export const getCaseLast4MonthsTxMetadataSuccess = (payload) => ({
  type: CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA_SUCCESS,
  payload,
});

export const getCaseLast4MonthsTxMetadataError = () => ({
  type: CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA_ERROR,
});

export const getCaseLast4MonthsTxHeatmapImage = (payload) => ({
  type: CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE,
  payload,
});

export const getCaseLast4MonthsTxHeatmapImageSuccess = (payload) => ({
  type: CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE_SUCCESS,
  payload,
});

export const getCaseLast4MonthsTxHeatmapImageError = () => ({
  type: CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE_ERROR,
});

export const breakOutCaseFromGroup = (payload) => ({
  type: CaseActions.BREAK_OUT_CASE_FROM_GROUP,
  payload,
});

export const breakOutCaseFromGroupSuccess = (payload) => ({
  type: CaseActions.BREAK_OUT_CASE_FROM_GROUP_SUCCESS,
  payload,
});

export const breakOutCaseFromGroupError = () => ({
  type: CaseActions.BREAK_OUT_CASE_FROM_GROUP_ERROR,
});

export const addCaseToCaseGroup = (payload) => ({
  type: CaseActions.ADD_CASE_TO_CASE_GROUP,
  payload,
});

export const addCaseToCaseGroupSuccess = (payload) => ({
  type: CaseActions.ADD_CASE_TO_CASE_GROUP_SUCCESS,
  payload,
});

export const addCaseToCaseGroupError = () => ({
  type: CaseActions.ADD_CASE_TO_CASE_GROUP_ERROR,
});

export const getCaseDetailConfig = (payload) => ({
  type: CASE_ACTIONS.config.fetch.data,
  payload,
});

export const getCaseDetailConfigSuccess = (payload) => ({
  type: CASE_ACTIONS.config.fetch.success,
  payload,
});

export const getCaseDetailConfigError = () => ({
  type: CASE_ACTIONS.config.fetch.error,
});

export const resetTransactionHistory = () => ({
  type: CASE_ACTIONS.transactionHistory.reset,
});

export const getCaseListByIds = (payload) => ({
  type: CASE_ACTIONS.allCases.list.data,
  payload,
});

export const getCaseListByIdsSuccess = (payload) => ({
  type: CASE_ACTIONS.allCases.list.success,
  payload,
});

export const getCaseListByIdsError = () => ({
  type: CASE_ACTIONS.allCases.list.error,
});
