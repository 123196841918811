import { Box, Divider, Grid, Chip, Typography } from '@mui/material';
import i18next from 'i18next';
import { Translation, Trans } from 'react-i18next';
import { useForm, Form } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import NoSubtenantSupportAlert from 'components/filters/alerts/noSubtenantSupportAlert';

const buildCustomersValuesFromUrl = (filter) => ({
  field: i18next.t('mapping:filters.filtering.customer.key'),
  condition: i18next.t(`mapping:operator.text.${filter.condition}.key`),
  values: filter.values,
});

const CustomersForm = ({ editForm, onSubmit, isCustomerCasesList }) => {
  const initialFValues = {
    customers: editForm?.values || [],
  };

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    if ('customers' in fieldValues) {
      temp.customers = fieldValues.customers?.length ? '' : i18next.t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    return null;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
        field: i18next.t('mapping:filters.filtering.customer.key'),
        values: values.customers,
      });
    }
  };
  return (
    <Translation>
      {(t) => (
        <Grid container>
          <Form className="w-100">
            <Grid container item className="px-3 py-3" spacing={1}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <Controls.MultipleInput
                    name="customers"
                    label={t('condition.equalOrContain.heading')}
                    value={values.customers}
                    onChange={handleInputChange}
                    splitValueByCommaEnabled={false}
                    error={errors.customers}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
            <Grid container item xs={12} className="p-2">
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="caption" component="p">
                  {t('case:caseList.header.customerSearchHint.heading')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="caption">
                  <Trans i18nKey="case:caseList.header.customerSearchHint.subheading" />
                </Typography>
              </Grid>
            </Grid>
            {isCustomerCasesList && (
              <Grid container item xs className="p-2">
                <NoSubtenantSupportAlert />
              </Grid>
            )}
            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button
                onClick={handleSubmit}
                type="submit"
                color="primary"
                text={t('filter.heading')}
              />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const CustomersChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.customer.value.heading')}
        </Box>
        {form.values.map((item, index) => (
          <Chip key={index} variant="outlined" size="small" className="mr-1" label={item} />
        ))}
      </>
    )}
  </Translation>
);

export { CustomersForm, CustomersChip, buildCustomersValuesFromUrl };
