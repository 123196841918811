import React, { FC } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Translation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

const Back: FC<IconButtonProps> = ({ onClick, edge, size = 'medium' }) => {
  const history = useHistory();
  const location = useLocation();

  const defaultGoBack = () => {
    const parentArray = location.pathname?.split('/');
    parentArray.pop();
    const parentUrl = parentArray.join('/');

    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(parentUrl);
    }
  };

  return (
    <Translation>
      {(t) => (
        <Tooltip title={t('back.heading')}>
          <IconButton edge={edge} onClick={onClick ?? defaultGoBack} size={size}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
    </Translation>
  );
};

export default Back;
