import { Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LoginButtonProps {
  onClick: CallableFunction;
}

const LoginButton: FC<LoginButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      data-testid="login-button"
      size="large"
      color="primary"
      variant="contained"
      fullWidth
      onClick={() => onClick()}
    >
      <Typography variant="subtitle1" padding={0.5}>
        {t('login.continue.heading')}
      </Typography>
    </Button>
  );
};

export default LoginButton;
