import { TENANT_CONFIG_ACTIONS } from 'modules/configuration/workflows-and-users/middleware/action-types';

export const getTenantConfig = () => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET,
});

export const putTenantConfig = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT,
  payload,
});

export const getTenantConfigSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET_SUCCESS,
  payload,
});

export const putTenantConfigSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT_SUCCESS,
  payload,
});

export const getTenantConfigError = () => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET_ERROR,
});

export const putTenantConfigError = () => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT_ERROR,
});

export const addTenant = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_ADD,
  payload,
});

export const addTenantSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_ADD_SUCCESS,
  payload,
});

export const addTenantError = () => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_ADD_ERROR,
});

export const removeLastCreatedTenant = () => ({
  type: TENANT_CONFIG_ACTIONS.TENANT_REMOVE_LAST,
});

export const addTenantToUsers = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.ADD_TENANT_TO_USERS,
  payload,
});

export const addTenantToUsersSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.ADD_TENANT_TO_USERS_SUCCESS,
  payload,
});

export const addTenantToUsersError = () => ({
  type: TENANT_CONFIG_ACTIONS.ADD_TENANT_TO_USERS_ERROR,
});

export const getUsers = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.USERS_GET,
  payload,
});

export const getUsersSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.USERS_GET_SUCCESS,
  payload,
});

export const getUsersError = () => ({
  type: TENANT_CONFIG_ACTIONS.USERS_GET_ERROR,
});

export const getAllTenants = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.ALL_TENANTS_GET,
  payload,
});

export const getAllTenantsSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.ALL_TENANTS_GET_SUCCESS,
  payload,
});

export const getAllTenantsError = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.ALL_TENANTS_GET_ERROR,
  payload,
});

export const getUsersNotInTenant = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.USERS_NOT_IN_TENANT_GET,
  payload,
});

export const getUsersNotInTenantSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.USERS_NOT_IN_TENANT_SUCCESS,
  payload,
});

export const getUsersNotInTenantError = () => ({
  type: TENANT_CONFIG_ACTIONS.USERS_NOT_IN_TENANT_ERROR,
});

export const updateUser = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.USER_UPDATE,
  payload,
});

export const updateUserSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.USER_UPDATE_SUCCESS,
  payload,
});

export const updateUserError = () => ({
  type: TENANT_CONFIG_ACTIONS.USER_UPDATE_ERROR,
});

export const clearTenantStructureStatus = () => ({
  type: TENANT_CONFIG_ACTIONS.tenantStructure.clear,
});

/** action to read master tenant ids */
export const getMasterTenants = () => ({
  type: TENANT_CONFIG_ACTIONS.masterTenants.get,
});
/** action when successfully reading master tenant ids */
export const getMasterTenantsSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.masterTenants.success,
  payload,
});
/** action if error occurs during reading master tenant ids */
export const getMasterTenantsError = () => ({
  type: TENANT_CONFIG_ACTIONS.masterTenants.error,
});

/** action to update tenant structure */
export const updateTenantStructure = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.tenantStructure.update,
  payload,
});
/** action when successfully updating tenant structure */
export const updateTenantStructureSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.tenantStructure.success,
  payload,
});
/** action if error occurs during updating tenant structure */
export const updateTenantStructureError = () => ({
  type: TENANT_CONFIG_ACTIONS.tenantStructure.error,
});

/**
 * Action to update tenant callback config
 */
export const putTenantCallbackConfig = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.callbackConfig.put,
  payload,
});

/**
 * Action when successfully updated tenant callback config
 */
export const putTenantCallbackConfigSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.callbackConfig.success,
  payload,
});

/**
 * Action if errors occurred during update tenant callback config
 */
export const putTenantCallbackConfigError = () => ({
  type: TENANT_CONFIG_ACTIONS.callbackConfig.error,
});

export const getTenantCallbackConfigSecret = () => ({
  type: TENANT_CONFIG_ACTIONS.callbackConfigSecret.get,
});

export const getTenantCallbackConfigSecretSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.callbackConfigSecret.success,
  payload,
});

export const getTenantCallbackConfigSecretError = () => ({
  type: TENANT_CONFIG_ACTIONS.callbackConfigSecret.error,
});
export const putTenantScorechainConfig = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.scorechainConfig.put,
  payload,
});

/**
 * Action when successfully updated tenant callback config
 */
export const putTenantScorechainConfigSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.scorechainConfig.success,
  payload,
});
export const putTenantScorechainConfigError = () => ({
  type: TENANT_CONFIG_ACTIONS.scorechainConfig.error,
});

export const getTenantScorechainConfigSecret = () => ({
  type: TENANT_CONFIG_ACTIONS.scorechainConfigSecret.get,
});

export const getTenantScorechainConfigSecretSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.scorechainConfigSecret.success,
  payload,
});

export const getTenantScorechainConfigSecretError = () => ({
  type: TENANT_CONFIG_ACTIONS.scorechainConfigSecret.error,
});

export const addMasterTenant = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.createMasterTenant.add,
  payload,
});

export const getMasterTenantCreationStatus = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.createMasterTenant.status,
  payload,
});

export const addMasterTenantSuccess = (payload) => ({
  type: TENANT_CONFIG_ACTIONS.createMasterTenant.success,
  payload,
});

export const addMasterTenantError = () => ({
  type: TENANT_CONFIG_ACTIONS.createMasterTenant.error,
});
