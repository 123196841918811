import { CallMerge, Replay } from '@mui/icons-material';
import { Avatar, AvatarGroup, Chip, Grid, Tooltip, Typography } from '@mui/material';
import { BlockedChip } from 'components/chips/blocked-chip';
import { ClickableRiskChip } from 'components/chips/clickable-risk-chip';
import { CustomerChipNameOnly } from 'components/chips/customer-chip-name-only';
import { RiskChip } from 'components/chips/risk-chip';
import FormattedDateTime from 'components/formatted-date-time';
import { ConditionalTooltip } from 'components/tooltip/tooltip';
import i18next from 'i18next';
import { isArray } from 'lodash';
import {
  getCaseTypeImage,
  getFlowImage,
  reasonImages,
} from 'modules/trx-cases/case-detail/utils/images';
import { Translation, useTranslation } from 'react-i18next';
import {
  getFirstTransactionFromCase,
  isSameLoggedUser,
  isStatusBlocked,
} from 'utils/helpers/cases/case-utility';
import { getLocaleDateString } from 'utils/helpers/date-time-util';
import { formatMoney } from 'utils/helpers/math-util';
import { MaskedChip } from 'components/chips/masked-chip';
import { WheelPoints } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points';

function buildItemImageCell(itemImage, item, idx, addedCss) {
  if (itemImage) {
    return (
      <ConditionalTooltip title={itemImage.tooltip}>
        <img key={idx} src={itemImage.image} alt={itemImage.tooltip} className={addedCss} />
      </ConditionalTooltip>
    );
  }
  return <span key={idx}>{item}</span>;
}

function buildItemImagesCell(items, itemImages, addedCss) {
  return items.map((item, idx) => {
    const itemImage = itemImages.find((image) => image.key === item);
    return buildItemImageCell(itemImage, item, idx, addedCss);
  });
}

const ReasonsCell = ({ item: { reasons } }) => {
  const itemReasons = buildItemImagesCell(reasons, reasonImages);
  return <div>{itemReasons}</div>;
};

const PriorityCell = ({ item = {} }) => {
  const priorityStyle = item.priority === 'High' ? 'high-priority-text' : 'normal-priority-text';
  return <div className={priorityStyle}>{item.priority}</div>;
};

const CaseStatusCell = ({ item }) => {
  const label = item?.caseStatus
    ? i18next.t([
        `mapping:caseStatusV2.${item.caseStatus}.value.heading`,
        `mapping:caseStatus.${item.caseStatus}.value.heading`,
      ])
    : '-';
  return (
    <Translation>
      {(t) => (
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>{label}</Grid>
          {item?.rescreening && (
            <Grid item>
              <ConditionalTooltip title={t(`rescreening.tooltip.heading`)}>
                <Replay color="disabled" />
              </ConditionalTooltip>
            </Grid>
          )}
          {item?.escalatedAt && (
            <Grid item>
              <ConditionalTooltip
                title={t(`escalatedAt.tooltip.heading`, {
                  escalationDate: getLocaleDateString(new Date(item?.escalatedAt)),
                })}
              >
                <CallMerge color="disabled" />
              </ConditionalTooltip>
            </Grid>
          )}
        </Grid>
      )}
    </Translation>
  );
};

/**
 * maxNumber min value is 2. Limit comes from AvatarGroup component.
 */
const PlainTextAsChipGroupCell = ({ maxNumber, item, fieldName }) => {
  let itemsArray = item[fieldName];
  if (!itemsArray) {
    itemsArray = [];
  } else if (!isArray(itemsArray)) {
    itemsArray = [itemsArray];
  }

  return (
    <div className="d-flex" style={{ margin: 'auto' }} role="button">
      {itemsArray.length <= 2 ? (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
          {itemsArray.map((item, index) => (
            <Chip key={index} size="small" label={item} />
          ))}
        </div>
      ) : (
        <AvatarGroup max={maxNumber} className="chips d-flex">
          {itemsArray.map((item, index) => (
            <Chip key={index} size="small" label={item} />
          ))}
        </AvatarGroup>
      )}
    </div>
  );
};

const MaskedCell = ({ item, maxNumber = 2, fieldName }) => {
  let fieldValues = item[fieldName];
  if (!fieldValues) {
    return null;
  }
  const id = item.groupId;
  if (!isArray(fieldValues)) {
    fieldValues = [fieldValues];
  }
  return (
    <div className="d-flex" style={{ margin: 'auto' }}>
      {fieldValues.length <= 2 ? (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
          {fieldValues.map((item, index) => (
            <MaskedChip
              key={index}
              maskedValue={item.masked}
              token={item.token}
              uuid={id}
              size="small"
            />
          ))}
        </div>
      ) : (
        <AvatarGroup max={maxNumber} className="chips d-flex">
          {fieldValues.map((item, index) => (
            <MaskedChip
              key={index}
              maskedValue={item.masked}
              token={item.token}
              uuid={id}
              size="small"
            />
          ))}
        </AvatarGroup>
      )}
    </div>
  );
};

const ProcessingStatusCell = ({ item }) => {
  const labelKey = item?.processingStatusV2 || item?.processingStatus;
  return (
    <Translation>
      {(t) => (
        <span className="color-on-surface">
          {t([
            `mapping:processingStatusV2.${labelKey}.value.heading`,
            `mapping:processingStatus.${labelKey}.value.heading`,
            '-',
          ])}
        </span>
      )}
    </Translation>
  );
};

const TransactionStatusCell = ({ item }) => {
  const transactionStatus = item?.customerStatus || item?.transactionStatusV2;

  if (!transactionStatus || transactionStatus.toLowerCase() === 'none') {
    return '-';
  }

  return (
    <Translation>
      {(t) => {
        const isTransactionStatusBlocked = isStatusBlocked(transactionStatus);
        const nonBlockedTransactionStatusHeading = transactionStatus
          ? t([
              `mapping:transactionStatusV2.${transactionStatus}.value.heading`,
              `mapping:transactionStatus.${transactionStatus}.value.heading`,
            ])
          : '-';
        return isTransactionStatusBlocked ? <BlockedChip /> : nonBlockedTransactionStatusHeading;
      }}
    </Translation>
  );
};

const TransactionRiskLevelCell = ({ item, riskKey, clickable, dataTestIdPrefix }) => {
  let label;
  if (riskKey) {
    label = i18next.t(`mapping:riskLevel.${riskKey.toLowerCase()}.value.heading`);
  } else {
    label = item?.riskLevel
      ? i18next.t(`mapping:riskLevel.${item?.riskLevel?.toLowerCase()}.value.heading`)
      : '-';
  }

  return clickable ? (
    <ClickableRiskChip
      item={item}
      risk={riskKey || item?.riskLevel}
      size="small"
      label={label}
      dataTestIdPrefix={dataTestIdPrefix}
    />
  ) : (
    <RiskChip
      size="small"
      risk={riskKey || item?.riskLevel}
      label={label}
      dataTestIdPrefix={dataTestIdPrefix}
    />
  );
};

const AnomalyScoreCell = ({ item, anomalyThreshold }) => {
  const anomalyScoreMax = item?.aggregatedScores?.anomalyScoreMax;
  const normalizedScore = (Math.min(anomalyScoreMax, 0.99) * 100).toFixed(0);
  return anomalyScoreMax === undefined ? (
    <WheelPoints notCalculated />
  ) : (
    <WheelPoints
      points={normalizedScore}
      maxPoints={100}
      size="small"
      risk={anomalyThreshold <= anomalyScoreMax ? 'high' : 'medium'}
    />
  );
};

const AutoCloseScoreCell = ({ item, machineCertaintyProbabilityThreshold }) => {
  const autoCloseScore = item?.aggregatedScores?.autoCloseScoreMin;
  return autoCloseScore === undefined ? (
    <WheelPoints notCalculated />
  ) : (
    <WheelPoints
      points={Math.round(autoCloseScore * 100)}
      maxPoints={100}
      size="small"
      risk={machineCertaintyProbabilityThreshold <= autoCloseScore ? 'low' : null}
    />
  );
};

const TimestampCell = ({ item, timestamp }) => {
  const updatedAt = timestamp ?? item?.updatedAt;
  let transactionDate;
  if (updatedAt && updatedAt.length > 0) {
    transactionDate = new Date(updatedAt);
  }
  return (
    <span className="text-nowrap">
      <FormattedDateTime date={transactionDate} withTime />
    </span>
  );
};

const TimestampCreatedAtCell = ({ item }) => {
  const createdAt = item?.createdAt;
  let transactionDate;
  if (createdAt && createdAt.length > 0) {
    transactionDate = new Date(createdAt);
  }
  return (
    <span>
      <FormattedDateTime date={transactionDate} withTime />
    </span>
  );
};

const CustomerCell = ({ item, dataTestIdPrefix, tenantConfig }) => (
  // const transaction = getFirstTransactionFromCase(item);
  // FIXME: temporary show only name without popover and allow unmasking
  // will be fixed once we provide all data from BE
  <CustomerChipNameOnly
    item={item}
    dataTestIdPrefix={dataTestIdPrefix}
    tenantConfig={tenantConfig}
  />
);
const TransactionCountCell = ({ txCount }) => <span>{txCount ?? 0}</span>;

const TotalFlowAmountCell = ({ totalAmount }) => {
  const formattedTotalAmount = totalAmount != null ? formatMoney(totalAmount) : null;
  return <span>{formattedTotalAmount}</span>;
};

const TotalFlowCurrencyCell = ({ currency }) => {
  const currencyCode = currency && currency.length >= 3 ? currency.substring(0, 3) : currency;
  return <span>{currencyCode}</span>;
};

const TotalAmountCell = ({ item }) => {
  const totalAmount = item.totalAmount?.value;
  const formattedTotalAmount = totalAmount != null ? formatMoney(totalAmount) : null;
  const currency = item.totalAmount?.currency;
  const currencyCode = currency && currency.length >= 3 ? currency.substring(0, 3) : currency;

  return (
    <Typography variant="body2" component="span" className="text-nowrap">
      <Typography component="span" variant="caption" color="textPrimary">
        {currencyCode}
      </Typography>{' '}
      {formattedTotalAmount}
    </Typography>
  );
};

const SettledFundsAmountCell = ({ item = {} }) => {
  const transaction = getFirstTransactionFromCase(item);
  const settledFunds = transaction.settledFunds || {};
  return (
    <span>
      {settledFunds.currency} {formatMoney(settledFunds.value)}
    </span>
  );
};

const FlowCell = ({ item = {}, flowKey, customClass }) => {
  const flowImage = getFlowImage(flowKey || item.flows);
  const itemFlows = buildItemImageCell(flowImage, '', 0, customClass);
  return <span>{itemFlows}</span>;
};

const AssignToUserCell = ({ item = {}, userDetails = {}, showEmpty }) => {
  const { t } = useTranslation();

  const assignedToUser =
    item.assignedToUserDetails?.name ||
    item.assignedToUserDetails?.email ||
    item?.assignedToUserDisplayName ||
    item?.assignedToUserId ||
    userDetails?.name ||
    userDetails?.displayName ||
    userDetails?.email ||
    userDetails?.userId;

  const isLoggedUser =
    isSameLoggedUser(item.assignedToUserDetails?.userId) ||
    isSameLoggedUser(userDetails?.userId) ||
    isSameLoggedUser(item.assignedToUserId) ||
    isSameLoggedUser(item.assignedToUserDisplayName);

  if (assignedToUser === t(`mapping:auditTrail.user.SYSTEM.key`)) {
    return (
      <Typography color="textSecondary" variant="body2" component="span">
        {t(`mapping:auditTrail.user.SYSTEM.value.heading`)}
      </Typography>
    );
  }

  return (
    <span>
      {assignedToUser ? (
        <ConditionalTooltip
          title={
            assignedToUser === item.assignedToUserDetails?.email ||
            assignedToUser === userDetails?.email
              ? ''
              : item.assignedToUserDetails?.email || userDetails?.email
          }
        >
          <Chip
            variant="outlined"
            color={isLoggedUser ? 'primary' : 'default'}
            avatar={<Avatar>{assignedToUser.charAt(0).toUpperCase()}</Avatar>}
            label={assignedToUser}
          />
        </ConditionalTooltip>
      ) : (
        <span className="font-italic color-on-surface-disabled">
          {showEmpty ? '' : t('accountNames.unassigned.heading')}
        </span>
      )}
    </span>
  );
};

/**
 * mapping image to cell
 * @param {*} types
 * @returns
 */
function buildTypeImagesCell(types, customClass, testIdPrefix) {
  return types.map((item, idx) => {
    const riskIcon = getCaseTypeImage(item);
    return (
      <Tooltip key={idx} title={i18next.t([`mapping:patternType.${item}.value.heading`])}>
        <img
          key={`_t${idx}`}
          src={riskIcon.image}
          alt=""
          data-testid={`${testIdPrefix}-icon-${item}`}
          className={customClass || 'type-icon'}
        />
      </Tooltip>
    );
  });
}

const TypeCell = ({ item = {}, singleTypeKey, customClass, testIdPrefix }) => {
  const types = item.types || item.caseTypes || [];

  return (
    <span className="caseTypes">
      {types.length || singleTypeKey ? (
        buildTypeImagesCell(singleTypeKey ? [singleTypeKey] : types, customClass, testIdPrefix)
      ) : (
        <span className="fas fa-minus" />
      )}
    </span>
  );
};

export {
  ReasonsCell,
  PriorityCell,
  CaseStatusCell,
  ProcessingStatusCell,
  CustomerCell,
  TransactionCountCell,
  TotalAmountCell,
  FlowCell,
  AnomalyScoreCell,
  AutoCloseScoreCell,
  TransactionStatusCell,
  TimestampCell,
  TimestampCreatedAtCell,
  TypeCell,
  AssignToUserCell,
  SettledFundsAmountCell,
  TransactionRiskLevelCell,
  TotalFlowCurrencyCell,
  TotalFlowAmountCell,
  MaskedCell,
  PlainTextAsChipGroupCell,
};
