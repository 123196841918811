import platform from 'platform';
import { logMessageInBackend } from 'services/api/logger/logger';

const prepareMessageHttpError = (error, httpUrl, httpMethod, httpBody, functionName) => {
  let message = `\nREDBOX_LOG: Request to ${httpMethod} ${httpUrl}`;

  if (error.status === 205) {
    message = `\nORANGEBOX_LOG: Request to ${httpMethod} ${httpUrl}`;
  }

  if (error instanceof TypeError && error?.message === 'Failed to fetch') {
    message = `\nFAILED_TO_FETCH_BOX_LOG: Request to ${httpMethod} ${httpUrl}`;
  }

  message += `\nDetected in function: ${functionName}`;

  return message;
};

const buildMessageHttpError = ({
  error,
  httpUrl,
  httpBody,
  httpMethod,
  functionName,
  httpHeaders,
}) => {
  let message = prepareMessageHttpError(error, httpUrl, httpMethod, httpBody, functionName);
  message += `\nRequest headers: ${httpHeaders}`;

  if (error.response) {
    // Axios
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    message += `\nError type: Axios library`;
    message += `\nStatus: ${error.response.status ?? '-'}`;
    message += `\nBody: ${error.response.data ?? '-'}`;
    message += `\nHeaders: ${JSON.stringify(error.response.headers) ?? '-'}`;
  } else if (error.request) {
    // Axios
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    message += `\nError type: Axios library`;
    message += `\nCode: ${error.code ?? '-'}`;
    message += `\nRequest: ${error.request ?? '-'}`;
  } else if (error.status) {
    // Fetch
    message += '\nError type: Fetch library';
    message += `\nStatus: ${error.status ?? '-'}`;
    message += `\nStatus text: ${error.statusText ?? '-'}`;
    message += `\nType: ${error.type ?? '-'}`;
    message += `\nHeaders: ${JSON.stringify(error.headers) ?? '-'}`;
  } else {
    // Something happened in setting up the request that triggered an Error
    // an exception thrown by Fetch API https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
    message += '\nError type: General error';
    message += `\nMessage: ${error.message ?? '-'}`;
    message += `\nError stack: ${error.stack ?? '-'}`;
  }

  return message;
};

const errorLog = (errorReportObj) => {
  let message;
  if (errorReportObj.httpUrl) {
    message = buildMessageHttpError(errorReportObj);
  }

  message += `\nBrowser: ${platform.name}`;
  message += `\nVersion: ${platform.version}`;
  message += `\nOS: ${platform.os}`;
  message += `\nDescription: ${platform.description}`;

  // eslint-disable-next-line consistent-return
  return logMessageInBackend(message, 'warn');
};

export { errorLog, buildMessageHttpError };
