import { HIDTA_HIFCA_LIST_ACTIONS } from 'middleware/action-types';

export const getHidtaHifcaFileList = (tenantId) => ({
  type: HIDTA_HIFCA_LIST_ACTIONS.fileList.get,
  payload: { tenantId },
});

export const getHidtaHifcaFileListSuccess = (payload) => ({
  type: HIDTA_HIFCA_LIST_ACTIONS.fileList.success,
  payload,
});

export const getHidtaHifcaFileListError = () => ({
  type: HIDTA_HIFCA_LIST_ACTIONS.fileList.error,
});

export const activateHidtaHifca = (version) => ({
  type: HIDTA_HIFCA_LIST_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateHidtaHifcaSuccess = (payload) => ({
  type: HIDTA_HIFCA_LIST_ACTIONS.activateVersion.success,
  payload,
});

export const activateHidtaHifcaError = () => ({
  type: HIDTA_HIFCA_LIST_ACTIONS.activateVersion.error,
});
