import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class RiskyWordsV2ListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('RiskyWords Instantiable List API Communicator instance is required.');
    }
  }

  /**
   * Checking if incoming JSON is correct
   */
  // eslint-disable-next-line class-methods-use-this
  validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.RISKY_WORDS_LIST_DATA_INVALID);
  }

  /**
   * Fetch RiskyWordsV2 file list
   */
  getRiskyWordsInstantiableListConfig(tenantId) {
    return this.communicator
      .fetchRiskyWordsInstantiableListConfig(tenantId)
      .then((json) => this.validateConfigJson(json));
  }

  updateRiskyWordsInstantiableListConfig(payload) {
    return this.communicator.updateRiskyWordsInstantiableListConfig(payload);
  }

  deleteRiskyWordsInstantiableListConfig(id) {
    return this.communicator.deleteRiskyWordsInstantiableListConfig(id);
  }

  /**
   * Update active RiskyWordsV2 file version
   */
  updateActiveRiskyWordsInstantiableListConfigVersion(tenantId, payload) {
    return this.communicator.updateActiveRiskyWordsInstantiableListConfigVersion(
      tenantId,
      payload.version,
    );
  }
}

export default RiskyWordsV2ListManager;
