import { COUNTER_PARTY_VOLUME_ACTIONS } from 'modules/configuration/rule-manager/counter-party-volume/middleware/action-types';

const counterPartyVolumeConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTER_PARTY_VOLUME_ACTIONS.fetch.success:
    case COUNTER_PARTY_VOLUME_ACTIONS.update.success:
    case COUNTER_PARTY_VOLUME_ACTIONS.delete.success:
      return {
        ...state,
        ruleInstances: action.payload.instances,
        updateDate: action.payload.updatedAt,
        userId: action.payload.userId,
      };
    default:
      return state;
  }
};

export default counterPartyVolumeConfigReducer;
