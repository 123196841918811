import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighestPointsApplied from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/highest-points-applied';

const ScoreDetailsAccordion = ({
  summaryComponent,
  detailsComponent,
  highestPointsApplied = false,
}) => (
  <Accordion elevation={0}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="px-0 pt-0">
      {summaryComponent}
    </AccordionSummary>
    <AccordionDetails className="px-0 pt-0">
      {highestPointsApplied ? <HighestPointsApplied /> : detailsComponent}
    </AccordionDetails>
  </Accordion>
);

export { ScoreDetailsAccordion };
