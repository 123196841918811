import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class AuditTrailApiCommunicator extends APICommunicator {
  // eslint-disable-next-line class-methods-use-this
  searchAuditTrails(paging, sorting, filtering, customHeader, tenantId) {
    const apiUrl = API_URLS.auditTrailService.search;
    const payload = {
      paging,
      sorting,
      filtering,
    };

    if (tenantId) {
      return this.postJSONForCustomTenant(
        apiUrl,
        payload,
        tenantId,
        ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
        {},
        customHeader,
      );
    }

    return this.postJSONForTenant(
      apiUrl,
      payload,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      customHeader,
    );
  }
}

export default AuditTrailApiCommunicator;
