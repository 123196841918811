import i18next from 'i18next';
import { showNotification } from 'middleware/actions/notification';
import { SftpConfigActions } from 'modules/configuration/settings/sftp-config/middleware/action-types';
import {
  deleteSftpConfigError,
  deleteSftpConfigSuccess,
  getSftpConfigError,
  getSftpConfigSuccess,
  getSftpConfigurationUnmaskedError,
  getSftpConfigurationUnmaskedSuccess,
  updateSftpConfigError,
  updateSftpConfigSuccess,
} from 'modules/configuration/settings/sftp-config/middleware/actions/sftp-config';
import { SftpApiCommunicator } from 'modules/configuration/settings/sftp-config/service/sftp-api-communicator';
import SftpManager from 'modules/configuration/settings/sftp-config/service/sftp-manager';

import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

const communicator = new SftpApiCommunicator();
const manager = new SftpManager(communicator);

function* getSftpConfigBegin(action) {
  yield put(startProcessing(SftpConfigActions.SFTP_GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getSftpConfigSuccess(json));
  } catch (error) {
    yield put(getSftpConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SftpConfigActions.SFTP_GET));
}

export function* getSftpConfigWatcher() {
  yield takeLatest(SftpConfigActions.SFTP_GET, getSftpConfigBegin);
}

function* updateSftpConfigBegin(action) {
  yield put(startProcessing(SftpConfigActions.SFTP_UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateSftpConfigSuccess(json));
  } catch (error) {
    yield put(updateSftpConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SftpConfigActions.SFTP_UPDATE));
}

export function* updateSftpConfigWatcher() {
  yield takeLatest(SftpConfigActions.SFTP_UPDATE, updateSftpConfigBegin);
}

function* deleteSftpConfigBegin(action) {
  yield put(startProcessing(SftpConfigActions.SFTP_DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteSftpConfigSuccess(json));
  } catch (error) {
    yield put(deleteSftpConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SftpConfigActions.SFTP_DELETE));
}

export function* deleteSftpConfigWatcher() {
  yield takeLatest(SftpConfigActions.SFTP_DELETE, deleteSftpConfigBegin);
}

function* getSftpConfigUnmaskedBegin(action) {
  yield put(startProcessing(SftpConfigActions.SFTP_GET_UNMASKED));
  try {
    const json = yield manager.detokenize(action.payload.uniqueId);
    yield put(getSftpConfigurationUnmaskedSuccess(json));

    const noteString = i18next.t('configuration:settings.sftpConfig.unmask.heading');
    yield put(showNotification({ message: noteString }));
  } catch (error) {
    yield put(getSftpConfigurationUnmaskedError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(SftpConfigActions.SFTP_GET_UNMASKED));
}

export function* getSftpConfigUnmaskedWatcher() {
  yield takeLatest(SftpConfigActions.SFTP_GET_UNMASKED, getSftpConfigUnmaskedBegin);
}
