import TenantManagementService from 'services/tenant/tenant-management-service';
import routes, { APP_ROUTES, getMatchRoute, settingsRoute } from 'utils/constants/routes';
import { buildRoute } from 'utils/helpers/cases/case-route-utility';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { getAccessibleRoutes } from 'utils/helpers/route-util';

const getDefaultUrl = (canSeeEscalatedCases) =>
  canSeeEscalatedCases
    ? buildRoute(APP_ROUTES.transaction.escalatedCases.path)
    : buildRoute(APP_ROUTES.transaction.myCases.path);

class RedirectUrl {
  // eslint-disable-next-line class-methods-use-this
  save(url) {
    return sessionStorage.setItem('redirect_url', url);
  }

  // eslint-disable-next-line class-methods-use-this
  get() {
    return sessionStorage.getItem('redirect_url');
  }

  // eslint-disable-next-line class-methods-use-this
  findRoute(accessibleRoutes, routeName) {
    return accessibleRoutes.find((r) => r.path.indexOf(routeName) >= 0);
  }

  // eslint-disable-next-line class-methods-use-this
  canSeeOpeningNotOpenedOrCaseBeingProcessed(redirectUrl, userPermissions) {
    return (
      (redirectUrl.indexOf('notOpenedCases') >= 0 &&
        isFeatureAccessible(APP_ROUTES.transaction.notOpenedCases.permission, userPermissions)) ||
      (redirectUrl.indexOf('beingProcessedCases') >= 0 &&
        isFeatureAccessible(APP_ROUTES.transaction.beingProcessedCases.permission, userPermissions))
    );
  }

  getRedirectUrl(isAuthenticated = false, settingsAndAccessContext = {}) {
    if (!isAuthenticated) return '/';

    let redirectUrl = this.get();

    const { userPermissions } = settingsAndAccessContext;

    const canSeeEscalatedCases = isFeatureAccessible(
      APP_ROUTES.transaction.escalatedCases.permission,
      userPermissions,
    );

    const canSeeAnyCases = isFeatureAccessible(
      APP_ROUTES.transaction.anyCases.permission,
      userPermissions,
    );

    if (!redirectUrl) {
      redirectUrl = getDefaultUrl(canSeeEscalatedCases);
    }

    const accessibleRoutes = getAccessibleRoutes(isAuthenticated, settingsAndAccessContext, routes);
    const matchingRoute = getMatchRoute(redirectUrl, true, accessibleRoutes);
    const myCaseRoute = this.findRoute(accessibleRoutes, 'myCases');
    const firstAccessibleRoute = accessibleRoutes[0] || {};
    const firstAccessiblePath = firstAccessibleRoute.path || '';

    const canOnlySeeBasicCases = !this.canSeeOpeningNotOpenedOrCaseBeingProcessed(
      redirectUrl,
      userPermissions,
    );

    if (!matchingRoute) {
      if (canSeeEscalatedCases) {
        return buildRoute(APP_ROUTES.transaction.escalatedCases.path);
      }

      if (canOnlySeeBasicCases) {
        return buildRoute(APP_ROUTES.transaction.myCases.path);
      }

      if (myCaseRoute) {
        return myCaseRoute.path.replace(':tenantId?', TenantManagementService.getActiveTenantId());
      }

      if (!canSeeAnyCases) {
        return buildRoute(settingsRoute);
      }

      return firstAccessiblePath.replace(':tenantId?', TenantManagementService.getActiveTenantId());
    }

    const hasCorrectPathFormat =
      matchingRoute.path.indexOf('/:tenantId?') >= 0 && matchingRoute.path.indexOf('/:uuid') < 0;

    if (hasCorrectPathFormat) {
      return matchingRoute.path.replace(':tenantId?', TenantManagementService.getActiveTenantId());
    }

    return redirectUrl;
  }
}

export default new RedirectUrl();
