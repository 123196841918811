import { Chip } from '@mui/material';
import { getCustomerDetails } from 'middleware/actions/customer-details';
import {
  getCustomerRiskRatingConfig,
  getCustomerRiskRatingConfigForIndividual,
} from 'middleware/actions/customer-risk-rating-config';
import { unmask } from 'middleware/actions/mask';
import { getSettings } from 'middleware/actions/settings';
import {
  getVariousCustomerName,
  getCustomerCategory,
  isCompany,
} from 'utils/helpers/cases/case-utility';
import { getDefaultCustomerCategoryFromState } from 'utils/helpers/config-utils/config-util';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountIcon } from 'components/icons/icons';
import { PersonPopover } from 'components/popover/person-popover';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';

const MAX_LENGTH = 20;

/**
 * check if a data already unmasked
 * @param {*} allMasks
 * @param {*} customer
 */
const isDataUnmasked = (allMasks, customer) => {
  if (!allMasks || !Array.isArray(allMasks)) {
    return false;
  }

  const unmaskedData = allMasks.find(
    (t) =>
      t.masked === customer?.masked?.name && customer?.tokens?.includes(t.token) && !!t.unmasked,
  );
  return unmaskedData !== undefined;
};

/**
 * check if data should unmask
 * @param {*} individualShown
 * @param {*} visibleState
 * @param {*} isCompanyTransaction
 */
const shouldUnmaskData = (individualShown, visibleState, isCompanyTransaction) => {
  if (!visibleState) {
    return individualShown;
  }

  return isCompanyTransaction;
};

/**
 * truncate text at the middle if it's too long
 * @param {*} text
 */
const truncateTextMiddle = (text) => {
  if (!!text && text.length > MAX_LENGTH) {
    const first8 = text.substring(0, 8);
    const last8 = text.substring(text.length - 8, text.length);
    return `${first8} ... ${last8}`;
  }

  return text;
};

/**
 * truncate text at the last if it's too long
 * @param {*} text
 */
const truncateTextLast = (text) => {
  if (!!text && text.length > MAX_LENGTH) {
    const first8 = text.substring(0, MAX_LENGTH);
    return `${first8} ... `;
  }

  return text;
};

const shouldShowCustomerNameUnmasked = (masks, customer, isCompanyTransaction) =>
  isDataUnmasked(masks?.all, customer) &&
  masks?.unmaskedTokens?.find(
    (m) => m.masked === customer?.masked?.name && customer?.tokens?.includes(m.token),
  ) &&
  !isCompanyTransaction;

/**
 * chip to display customer details
 */
const CustomerChipNameOnly = ({
  item = {},
  dataTestIdPrefix,
  tenantConfig,
  forceRiskLevel,
  customHandleClick,
}) => {
  const uuid = item?.groupId;

  const masks = useSelector((state) => state.masks);
  const username = useSelector((state) => state.authentication.user?.email);
  const userPermissions = useSelector((state) => state.authentication?.userPermissions || []);
  const detachmentConfig = useSelector((state) => state.authentication?.detachmentConfig || {});
  const customerDetails = useSelector(
    (state) => state?.customerDetails[uuid] ?? (item.customerDetails || {}),
  );

  const accountHoldingPartyData = customerDetails?.accountHoldingPartyData ?? {};
  const companyDetails = accountHoldingPartyData?.companyDetails;
  const personalDetails = accountHoldingPartyData?.personalDetails;
  const contactDetails = accountHoldingPartyData?.contactDetails;

  const underwriterData = customerDetails?.underwriterData;
  const customerRiskRatingData = customerDetails?.riskRatingData;

  const [anchorEl, setAnchorEl] = useState(null);
  const [individualShown, setIndividualShown] = useState(false);
  const [isUnmasked, setIsUnmasked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();

  const customer = {
    masked: item?.customers?.[0] ?? {},
    tokens: item?.customerNameTokens?.map((t) => t?.nameToken),
  };

  const { tenantId, configVersion } = item;
  const caseConfig = { tenantId, configVersion };
  const defaultCustomerCategory = useSelector((state) =>
    getDefaultCustomerCategoryFromState({ state, tenantId, configVersion }),
  );
  const customerCategory = getCustomerCategory(item);
  const isCompanyTransaction = isCompany(customerCategory, defaultCustomerCategory);
  useEffect(() => {
    if (!!masks?.casesDataShown && isCompanyTransaction) {
      setIndividualShown(false);
    } else if (shouldShowCustomerNameUnmasked(masks, customer, isCompanyTransaction)) {
      setIndividualShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    masks.casesDataShown,
    isCompanyTransaction,
    masks.all,
    customer?.masked?.name,
    masks.unmaskedTokens,
  ]);

  const shouldUnmask = shouldUnmaskData(
    individualShown,
    masks?.casesDataShown,
    isCompanyTransaction,
  );

  const customerData = {
    masked: customer?.masked?.name ?? '-',
    tokens: customer?.tokens,
    riskLevel: accountHoldingPartyData?.riskLevel,
    calculatedRiskLevel: item?.riskLevel?.toLowerCase(),
    customerId: accountHoldingPartyData?.customerId,
    customerGroupId: accountHoldingPartyData?.customerGroupId,
    customerStatus: accountHoldingPartyData?.customerStatus,
    customerSince: accountHoldingPartyData?.customerSince,
    customerType: accountHoldingPartyData?.customerType,
    signedProducts: accountHoldingPartyData?.signedProducts,
    underwriterData,
    referenceAccount: accountHoldingPartyData?.referenceAccount,
    characteristics: accountHoldingPartyData?.characteristics,
    personalDetails,
    companyDetails,
    contactDetails,
    flags: accountHoldingPartyData?.flags,
  };

  const openDetails = Boolean(anchorEl);

  const handleClick = (event) => {
    if (typeof customHandleClick === 'function') {
      customHandleClick(event);
      return;
    }
    const anchorResult = anchorEl ? null : event.currentTarget;
    event.stopPropagation();
    setAnchorEl(anchorResult);
    setIndividualShown(true);

    if (!!anchorResult && !isUnmasked) {
      dispatch(getCustomerDetails(uuid));
      dispatch(
        unmask({
          masterUuid: uuid,
          displayUuid: uuid,
          uuids: [uuid],
          userId: username,
        }),
      );
      dispatch(getSettings({ tenantId, configVersion }));

      setIsUnmasked(true);
      setIsClicked(true);
    }
  };

  useEffect(
    () => {
      if (isClicked) {
        if (isCompanyTransaction) {
          if (
            isFeatureAccessible(
              DETACHMENT_CONFIG.customerRiskRating.organization,
              detachmentConfig,
              userPermissions,
            )
          ) {
            dispatch(getCustomerRiskRatingConfig({ tenantId, configVersion }));
          }
        } else if (
          isFeatureAccessible(
            DETACHMENT_CONFIG.customerRiskRating.individual,
            detachmentConfig,
            userPermissions,
          )
        ) {
          dispatch(getCustomerRiskRatingConfigForIndividual({ tenantId, configVersion }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCompanyTransaction, item?.tenantId, isClicked, configVersion],
  );

  const customerDisplayName =
    masks?.all?.find((mask) => customerData?.tokens?.includes(mask.token))?.unmasked ||
    customerData?.masked;

  const maskedLabel =
    customerDisplayName === getVariousCustomerName()
      ? customerDisplayName
      : truncateTextMiddle(customerData.masked);
  return (
    <>
      <Chip
        className="none max-w-100"
        onClick={handleClick}
        size="small"
        label={
          individualShown || shouldUnmask ? truncateTextLast(customerDisplayName) : maskedLabel
        }
        icon={getAccountIcon(true, isCompanyTransaction, forceRiskLevel)}
        data-testid={`${dataTestIdPrefix}-customer-chip`}
      />

      <PersonPopover
        anchorEl={anchorEl}
        openDetails={openDetails}
        handleClick={handleClick}
        personName={customerDisplayName}
        personDetails={customerData}
        isCustomer
        customerRiskRatingData={customerRiskRatingData}
        tenantId={item?.tenantId}
        item={item}
        isCompanyTransaction={isCompanyTransaction}
        caseConfig={caseConfig}
        tenantConfig={tenantConfig}
      />
    </>
  );
};

export { CustomerChipNameOnly };
