import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener, Grid, IconButton, Paper, Popper } from '@mui/material';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { AccountIdForm } from 'components/filters/fields/account-id';
import { AccountNumberForm } from 'components/filters/fields/account-number';
import AssignedToForm from 'components/filters/fields/assigned-to/assigned-to';
import { CaseIdForm } from 'components/filters/fields/case-id';
import { CaseScreeningTypeStringForm } from 'components/filters/fields/case-screening-type-string';
import { CaseStatusForm } from 'components/filters/fields/case-status';
import { CaseTypeForm } from 'components/filters/fields/case-type';
import { ChannelsForm } from 'components/filters/fields/channels';
import { CreatedAtForm } from 'components/filters/fields/created-at';
import { CustomersForm } from 'components/filters/fields/customer';
import { CustomerCategoryForm } from 'components/filters/fields/customer-category/customer-category-form';
import { CustomerGroupIdsForm } from 'components/filters/fields/customer-group-id';
import { CustomerIdsForm } from 'components/filters/fields/customer-id';
import { EventForm } from 'components/filters/fields/event';
import { FlowForm } from 'components/filters/fields/flow';
import { FundsTotalForm } from 'components/filters/fields/funds-total';
import { IntermediaryNameForm } from 'components/filters/fields/intermediary-name';
import { CustomerNameForm } from 'components/filters/fields/customer-name';
import { InvestigationStatusForm } from 'components/filters/fields/investigation-status';
import { IsEscalatedForm } from 'components/filters/fields/is-escalated';
import { LastUpdatedForm } from 'components/filters/fields/last-updated';
import { PaymentMeansForm } from 'components/filters/fields/payment-means';
import { ProcessedAtForm } from 'components/filters/fields/processed-at';
import { ProcessingStatusForm } from 'components/filters/fields/processing-status';
import { RiskFactorsForm } from 'components/filters/fields/risk-factors';
import { RiskLevelForm } from 'components/filters/fields/risk-level';
import { RiskPointsForm } from 'components/filters/fields/risk-points';
import { RuleCategoryForm } from 'components/filters/fields/rule-category';
import { RuleInstancesForm } from 'components/filters/fields/rule-instances/rule-instances-form';
import { ScreeningTypeForm } from 'components/filters/fields/screening-type';
import { TenantNameForm } from 'components/filters/fields/tenant-name';
import { TenantTransactionIdForm } from 'components/filters/fields/tenant-transaction-id';
import { TransactionStatusForm } from 'components/filters/fields/transaction-status';
import { UnassignedForm } from 'components/filters/fields/unassigned';
import { UserIdForm } from 'components/filters/fields/user-id';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AnomalyScoreForm } from 'components/filters/fields/anomaly-score';
import { AutoCloseScoreForm } from 'components/filters/fields/auto-close-score';
import { AdditionalIdForm } from 'components/filters/fields/addtional-id/additional-id-form';
import { ScreeningListTypeForm } from 'components/filters/fields/screening-list-type';
import { ScreeningListJurisdictionForm } from 'components/filters/fields/screening-list-jurisdiction';
import { InvestigationOutcomeForm } from 'components/filters/fields/investigation-outcome';
import { ExecutionScopesForm } from 'components/filters/fields/execution-scopes';
import { OrderIdsForm } from 'components/filters/fields/order-ids';
import { MultiTextFilterForm } from 'components/filters/fields/multitext/multitext-filter-form';
import { RoleForm } from 'components/filters/fields/role';
import CaseNotificationRuleInstance from 'components/filters/fields/case-notification-rule-instance/case-notification-rule-instance';
import {
  Filter,
  FilterConfig,
  KeyValueHeading,
  KeyValueHeadingMap,
} from 'components/filters/models/filter';
import { useAuthorization } from 'hooks/use-authorization';
import { filterInvestigationStatusesByPermissions } from 'components/filters/utils/investigation-statuses-by-permissions';
import { filterProcessingStatusesByPermissions } from 'components/filters/utils/processing-statuses-by-permissions';
import { updateProcessingStatusesByAutoClosedRelabeling } from 'components/filters/utils/processing-statuses-by-autoclosed-relabeling';
import { OptionForm } from 'components/filters/fields/option';
import { TextForm } from 'components/filters/fields/text';
import {
  counterpartyAccountFilterCreator,
  counterpartyAccountFilterToValueConverter,
  counterpartyNameFilterCreator,
  counterpartyNameFilterToValueConverter,
  tenantTransactionIdFilterCreator,
  tenantTransactionIdFilterToValueConverter,
  usageFilterCreator,
  usageFilterToValueConverter,
} from 'components/filters/fields/text.fields';
import { useDetachment } from 'hooks/use-detachment';

interface FilterPopperProps {
  anchorEl: HTMLElement;
  filter: FilterConfig;
  selectedFilter: FilterConfig;
  isCustomerCasesList: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClose: (event: any) => void;
  handleSubmitFilter: (filter: Filter) => void;
}

const FilterPopper: React.FC<FilterPopperProps> = ({
  anchorEl,
  filter,
  selectedFilter,
  isCustomerCasesList,
  handleClose,
  handleSubmitFilter,
}) => {
  const { t } = useTranslation();
  const { autoCloseRelabel: isAutoCloseRelabelAttached } = useDetachment('autoCloseRelabel');

  const { userPermissions } = useAuthorization();
  const userPermissionStrings = userPermissions?.map((p) => p.permission_name);

  const allInvestigationStatuses = _.sortBy(
    Object.values(t('mapping:investigationStatus')) as unknown as Array<KeyValueHeading>,
    (item) => item.value?.heading,
  );
  const filteredInvestigationStatuses = filterInvestigationStatusesByPermissions(
    allInvestigationStatuses,
    userPermissionStrings,
  );

  const processingStatusList: KeyValueHeadingMap =
    filter.key === t('mapping:filters.filtering.processingStatus.key')
      ? t('mapping:processingStatusV2')
      : t('mapping:processingStatus');
  updateProcessingStatusesByAutoClosedRelabeling(processingStatusList, isAutoCloseRelabelAttached);
  const allProcessingStatuses = _.sortBy(
    Object.values(processingStatusList) as unknown as Array<KeyValueHeading>,
    (item) => item.value?.heading,
  );
  const filteredProcessingStatuses = filterProcessingStatusesByPermissions(
    allProcessingStatuses,
    userPermissionStrings,
  );

  return (
    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
      <Popper
        open={Boolean(anchorEl && selectedFilter)}
        anchorEl={anchorEl}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -35],
              },
            },
          ],
        }}
        placement="bottom-start"
        className="z-1300"
      >
        <Paper elevation={1} className="max-w-250">
          <Grid container>
            <Grid item container>
              <Grid
                container
                item
                xs={12}
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
                className="bg-primary pl-3 pr-2 py-0"
              >
                <Grid item>
                  <Typography variant="body2" className="color-on-primary">
                    {filter.value?.heading}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton edge={false} onClick={handleClose} size="large">
                    <CloseIcon fontSize="small" className="color-on-primary" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {filter.key === t('mapping:filters.filtering.totalAmount.key') && (
                <FundsTotalForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.anomalyScoreMax.key') && (
                <AnomalyScoreForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.autoCloseScoreMin.key') && (
                <AutoCloseScoreForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.falsePositiveReductionScore.key') && (
                <AutoCloseScoreForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.tenantTransactionIds.key') && (
                <TenantTransactionIdForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.updatedAt.key') && (
                <LastUpdatedForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.unassigned.key') && (
                <UnassignedForm onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.assignedToUserId.key') && (
                <AssignedToForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key ===
                t('mapping:filters.filtering.caseNotificationRuleInstanceIds.key') && (
                <CaseNotificationRuleInstance
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.tenantId.key') && (
                <TenantNameForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.ruleInstances.key') && (
                <RuleInstancesForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.types.key') && (
                <CaseTypeForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.escalatedAt.key') && (
                <IsEscalatedForm onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.flows.key') && (
                <FlowForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {(filter.key === t('mapping:filters.filtering.transactionStatusesV2.key') ||
                filter.key === t('mapping:filters.filtering.customerStatus.key')) && (
                <TransactionStatusForm
                  filterKey={filter.key}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {(filter.key === t('mapping:filters.filtering.processingStatusesV2.key') ||
                filter.key === t('mapping:filters.filtering.processingStatus.key')) && (
                <ProcessingStatusForm
                  filterKey={filter.key}
                  processingStatuses={filteredProcessingStatuses}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {(filter.key === t('mapping:filters.filtering.caseStatuses.key') ||
                filter.key === t('mapping:filters.filtering.status.key')) && (
                <CaseStatusForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                  filterKey={filter.key}
                  mappingPath="caseStatus"
                />
              )}
              {filter.key === t('mapping:filters.filtering.caseStatus.key') && (
                <CaseStatusForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                  filterKey={filter.key}
                  mappingPath="caseStatusV2"
                />
              )}
              {filter.key === t('mapping:filters.filtering.riskLevels.key') && (
                <RiskLevelForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.processedAts.key') && (
                <ProcessedAtForm
                  fieldName="processedAts"
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}

              {filter.key === t('mapping:filters.filtering.processedAt.key') && (
                <ProcessedAtForm
                  fieldName="processedAt"
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}

              {filter.key === t('mapping:filters.filtering.caseIds.key') && (
                <CaseIdForm
                  filterKey={filter.key}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.intermediaryName.key') && (
                <IntermediaryNameForm
                  filterKey={filter.key}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.customerName.key') && (
                <CustomerNameForm
                  filterKey={filter.key}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}

              {filter.key === t('mapping:filters.filtering.counterpartyName.key') && (
                <TextForm
                  fieldName="counterpartyName"
                  filterCreator={counterpartyNameFilterCreator}
                  filterToValueConverter={counterpartyNameFilterToValueConverter}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                  info={
                    <Grid container item xs={12} className="p-2">
                      <Grid item xs={12}>
                        <Typography color="textSecondary" variant="caption" component="p">
                          {t('case:caseList.header.customerSearchHint.heading')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="textSecondary" variant="caption">
                          <Trans i18nKey="case:caseList.header.customerSearchHint.subheading" />
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              )}

              {filter.key === t('mapping:filters.filtering.counterpartyAccount.key') && (
                <TextForm
                  fieldName="counterpartyAccount"
                  filterCreator={counterpartyAccountFilterCreator}
                  filterToValueConverter={counterpartyAccountFilterToValueConverter}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}

              {filter.key === t('mapping:filters.filtering.usage.key') && (
                <TextForm
                  fieldName="usage"
                  filterCreator={usageFilterCreator}
                  filterToValueConverter={usageFilterToValueConverter}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}

              {filter.key === t('mapping:filters.filtering.tenantTransactionId.key') && (
                <TextForm
                  fieldName="tenantTransactionId"
                  filterCreator={tenantTransactionIdFilterCreator}
                  filterToValueConverter={tenantTransactionIdFilterToValueConverter}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}

              {filter.key === t('mapping:filters.filtering.accountIds.key') && (
                <AccountIdForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.accountNumbers.key') && (
                <AccountNumberForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.customer.key') && (
                <CustomersForm
                  isCustomerCasesList={isCustomerCasesList}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.customerId.key') && (
                <CustomerIdsForm
                  isCustomerCasesList={isCustomerCasesList}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.customerGroupIds.key') && (
                <CustomerGroupIdsForm
                  isCustomerCasesList={isCustomerCasesList}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.riskFactors.key') && (
                <RiskFactorsForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.totalPoints.key') && (
                <RiskPointsForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.rescreening.key') && (
                <ScreeningTypeForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.typesString.key') && (
                <CaseScreeningTypeStringForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.createdAt.key') && (
                <CreatedAtForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.event.key') && (
                <EventForm fullWidth editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.userId.key') && (
                <UserIdForm
                  fullWidth
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.paymentMeans.key') && (
                <PaymentMeansForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.ruleCategories.key') && (
                <RuleCategoryForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.investigationStatuses.key') && (
                <InvestigationStatusForm
                  investigationStatuses={filteredInvestigationStatuses}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.customerCategory.key') && (
                <CustomerCategoryForm
                  onSubmit={handleSubmitFilter}
                  editForm={selectedFilter.form}
                  filterKey={filter.key}
                />
              )}
              {filter.key === t('mapping:filters.filtering.additionalIds.key') && (
                <AdditionalIdForm
                  filterKey={filter.key}
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.screeningListType.key') && (
                <ScreeningListTypeForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.screeningListJurisdiction.key') && (
                <ScreeningListJurisdictionForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.channels.key') && (
                <ChannelsForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.investigationOutcome.key') && (
                <InvestigationOutcomeForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                />
              )}
              {filter.key === t('mapping:filters.filtering.orderIds.key') && (
                <OrderIdsForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.executionScopes.key') && (
                <ExecutionScopesForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.originatorsBankCountries.key') && (
                <MultiTextFilterForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                  filterKey={filter.key}
                  mappingPath="originatorsBankCountries"
                />
              )}
              {filter.key === t('mapping:filters.filtering.beneficiariesBankCountries.key') && (
                <MultiTextFilterForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                  filterKey={filter.key}
                  mappingPath="beneficiariesBankCountries"
                />
              )}
              {filter.key === t('mapping:filters.filtering.roles.key') && (
                <RoleForm editForm={selectedFilter.form} onSubmit={handleSubmitFilter} />
              )}
              {filter.key === t('mapping:filters.filtering.reasonTypes.key') && (
                <OptionForm
                  editForm={selectedFilter.form}
                  onSubmit={handleSubmitFilter}
                  filterKey="reasonTypes"
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default FilterPopper;
