import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { AccountIdChip, buildAccountIdsValuesFromUrl } from 'components/filters/fields/account-id';
import {
  AccountNumberChip,
  buildAccountNumbersValuesFromUrl,
} from 'components/filters/fields/account-number';
import { buildCaseIdsValuesFromUrl, CaseIdChip } from 'components/filters/fields/case-id';
import {
  buildCaseScreeningTypeStringValuesFromUrl,
  CaseScreeningTypesStringChip,
} from 'components/filters/fields/case-screening-type-string';
import {
  buildCaseStatusesValuesFromUrl,
  CaseStatusChip,
} from 'components/filters/fields/case-status';
import { buildCaseTypeValuesFromUrl, CaseTypeChip } from 'components/filters/fields/case-type';
import { buildChannelValuesFromUrl, ChannelsChip } from 'components/filters/fields/channels';
import { buildCreatedAtValuesFromUrl, CreatedAtChip } from 'components/filters/fields/created-at';
import { buildCustomersValuesFromUrl, CustomersChip } from 'components/filters/fields/customer';
import { buildCustomerCategoryValuesFromUrl } from 'components/filters/fields/customer-category/build-customer-category-values-from-url';
import { CustomerCategoryChip } from 'components/filters/fields/customer-category/customer-category-chip';
import {
  buildCustomerGroupIdsValuesFromUrl,
  CustomerGroupIdsChip,
} from 'components/filters/fields/customer-group-id';
import {
  buildCustomerIdsValuesFromUrl,
  CUSTOMER_ID_FILTER_FIELD,
  CustomerIdsChip,
} from 'components/filters/fields/customer-id';
import { buildEventValuesFromUrl, EventChip } from 'components/filters/fields/event';
import { buildFlowValuesFromUrl, FlowChip } from 'components/filters/fields/flow';
import {
  buildFundsTotalValuesFromUrl,
  FundsTotalChip,
} from 'components/filters/fields/funds-total';
import {
  buildIntermediaryNameValuesFromUrl,
  IntermediaryNameChip,
} from 'components/filters/fields/intermediary-name';
import {
  buildCustomerNameValuesFromUrl,
  CustomerNameChip,
} from 'components/filters/fields/customer-name';
import {
  buildInvestigationStatusValuesFromUrl,
  InvestigationStatusFilterChip,
} from 'components/filters/fields/investigation-status';
import {
  buildIsEscalatedValuesFromUrl,
  IsEscalatedChip,
} from 'components/filters/fields/is-escalated';
import {
  buildLastUpdatedValuesFromUrl,
  LastUpdatedChip,
} from 'components/filters/fields/last-updated';
import {
  buildPaymentMeansValuesFromUrl,
  PaymentMeansChip,
} from 'components/filters/fields/payment-means';
import {
  buildProcessedAtValuesFromUrl,
  ProcessedAtChip,
} from 'components/filters/fields/processed-at';
import {
  buildProcessingStatusV2ValuesFromUrl,
  buildProcessingStatusValuesFromUrl,
  ProcessingStatusChip,
} from 'components/filters/fields/processing-status';
import {
  buildRiskFactorsValuesFromUrl,
  RiskFactorsChip,
} from 'components/filters/fields/risk-factors';
import { buildRiskLevelValuesFromUrl, RiskLevelChip } from 'components/filters/fields/risk-level';
import {
  buildRiskPointsValuesFromUrl,
  RiskPointsChip,
} from 'components/filters/fields/risk-points';
import {
  buildRuleCategoryValuesFromUrl,
  RuleCategoryFilterChip,
} from 'components/filters/fields/rule-category';
import {
  buildRuleInstancesValuesFromUrl,
  RuleInstancesChip,
} from 'components/filters/fields/rule-instances';
import {
  buildScreeningTypeValuesFromUrl,
  ScreeningTypeChip,
} from 'components/filters/fields/screening-type';
import {
  buildTenantNameValuesFromUrl,
  TenantNameChip,
} from 'components/filters/fields/tenant-name';
import {
  buildTenantTransactionIdsValuesFromUrl,
  TenantTransactionIdChip,
} from 'components/filters/fields/tenant-transaction-id';
import {
  buildCustomerStatusValuesFromUrl,
  buildTransactionStatusValuesFromUrl,
  TransactionStatusChip,
} from 'components/filters/fields/transaction-status';
import { buildUnassignedValuesFromUrl, UnassignedChip } from 'components/filters/fields/unassigned';
import { buildUserIdsValuesFromUrl, UserIdChip } from 'components/filters/fields/user-id';
import {
  AnomalyScoreChip,
  buildAnomalyScoreValuesFromUrl,
} from 'components/filters/fields/anomaly-score';
import {
  AutoCloseScoreChip,
  buildAutoCloseScoreValuesFromUrl,
} from 'components/filters/fields/auto-close-score';
import { buildAdditionalIdValuesFromUrl } from 'components/filters/fields/addtional-id/build-additional-id-values-from-url';
import { AdditionalIdChip } from 'components/filters/fields/addtional-id/additional-id-chip';
import { ScreeningListTypeChip } from 'components/filters/fields/screening-list-type';
import { ScreeningListJurisdictionChip } from 'components/filters/fields/screening-list-jurisdiction';
import {
  buildInvestigationOutcomeValuesFromUrl,
  InvestigationOutcomeFilterChip,
} from 'components/filters/fields/investigation-outcome';
import {
  buildExecutionScopesValuesFromUrl,
  ExecutionScopesChip,
} from 'components/filters/fields/execution-scopes';
import { buildOrderIdsValuesFromUrl, OrderIdsChip } from 'components/filters/fields/order-ids';
import { buildMultiTextFilterValuesFromUrl } from 'components/filters/fields/multitext/build-multitext-filter-values-from-url';
import { MultiTextFilterChip } from 'components/filters/fields/multitext/multitext-filter-chip';
import { buildRoleValuesFromUrl, RoleChip } from 'components/filters/fields/role';
import { Filter } from 'components/filters/models/filter';
import { KeyValueMapping } from 'modules/configuration/rule-manager/common/filters/models/key-value-mapping';
import AssignedToChip from 'components/filters/fields/assigned-to/assigned-to-chip';
import { buildAssignedToValuesFromUrl } from 'components/filters/fields/assigned-to/build-assigned-to-values-from-url';
import CaseNotificationRuleInstanceChip from 'components/filters/fields/case-notification-rule-instance/case-notification-rule-instance-chip';
import { buildCaseNotificationRuleInstancesFromUrl } from 'components/filters/fields/case-notification-rule-instance/case-notification-rule-instance-from-url';
import { TextChip } from 'components/filters/fields/text';
import {
  counterpartyAccountFilterToValueConverter,
  counterpartyNameFilterToValueConverter,
  tenantTransactionIdFilterToValueConverter,
  usageFilterToValueConverter,
} from 'components/filters/fields/text.fields';
import { OptionChip } from 'components/filters/fields/option';

interface FormFieldToValue {
  [key: string]: CallableFunction;
}

const buildFilter = (filter: Filter, condition: string) => ({
  condition: i18next.t(`mapping:operator.${condition}.key`),
  field: i18next.t(`mapping:filters.filtering.${filter.field}.key`),
  values: filter?.values,
});

const formFieldToValueFromUrlMapping: FormFieldToValue = {
  updatedAt: buildLastUpdatedValuesFromUrl,
  tenantTransactionIds: buildTenantTransactionIdsValuesFromUrl,
  escalatedAt: buildIsEscalatedValuesFromUrl,
  totalAmount: buildFundsTotalValuesFromUrl,
  anomalyScoreMax: buildAnomalyScoreValuesFromUrl,
  autoCloseScoreMin: buildAutoCloseScoreValuesFromUrl,
  tenantId: buildTenantNameValuesFromUrl,
  assignedToUserId: buildAssignedToValuesFromUrl,
  caseNotificationRuleInstanceIds: buildCaseNotificationRuleInstancesFromUrl,
  unassigned: buildUnassignedValuesFromUrl,
  ruleInstances: buildRuleInstancesValuesFromUrl,
  types: buildCaseTypeValuesFromUrl,
  flows: buildFlowValuesFromUrl,
  transactionStatusesV2: buildTransactionStatusValuesFromUrl,
  customerStatus: buildCustomerStatusValuesFromUrl,
  processingStatusesV2: buildProcessingStatusV2ValuesFromUrl,
  processingStatus: buildProcessingStatusValuesFromUrl,
  // Similar names intended. Backend has filter field names: caseStatuses for trx list and caseStatus for customer list
  caseStatuses: buildCaseStatusesValuesFromUrl,
  caseStatus: buildCaseStatusesValuesFromUrl,
  totalPoints: buildRiskPointsValuesFromUrl,
  riskLevels: buildRiskLevelValuesFromUrl,
  processedAts: (filter: Filter) => buildProcessedAtValuesFromUrl('processedAts', filter),
  caseIds: buildCaseIdsValuesFromUrl,
  intermediaryName: buildIntermediaryNameValuesFromUrl,
  customerName: buildCustomerNameValuesFromUrl,
  accountIds: buildAccountIdsValuesFromUrl,
  accountNumbers: buildAccountNumbersValuesFromUrl,
  customer: buildCustomersValuesFromUrl,
  [CUSTOMER_ID_FILTER_FIELD]: buildCustomerIdsValuesFromUrl,
  paymentMeans: buildPaymentMeansValuesFromUrl,
  customerGroupIds: buildCustomerGroupIdsValuesFromUrl,
  riskFactors: buildRiskFactorsValuesFromUrl,
  rescreening: buildScreeningTypeValuesFromUrl,
  typesString: buildCaseScreeningTypeStringValuesFromUrl,
  createdAt: buildCreatedAtValuesFromUrl,
  event: buildEventValuesFromUrl,
  userId: buildUserIdsValuesFromUrl,
  ruleCategories: buildRuleCategoryValuesFromUrl,
  investigationStatuses: buildInvestigationStatusValuesFromUrl,
  customerCategory: buildCustomerCategoryValuesFromUrl,
  additionalIds: buildAdditionalIdValuesFromUrl,
  channels: buildChannelValuesFromUrl,
  investigationOutcome: buildInvestigationOutcomeValuesFromUrl,
  orderIds: buildOrderIdsValuesFromUrl,
  executionScopes: buildExecutionScopesValuesFromUrl,
  originatorsBankCountries: buildMultiTextFilterValuesFromUrl,
  beneficiariesBankCountries: buildMultiTextFilterValuesFromUrl,
  role: buildRoleValuesFromUrl,
  reasonTypes: (it: Filter) => buildFilter(it, 'text.TEXT_EQUAL'),
};

const filterToKeyValueMapping = (filter: Filter): KeyValueMapping =>
  i18next.t(`mapping:filters.filtering.${filter.field}`);

const mapUrlToFormFilter = (urlFilterConfig: Filter[]) =>
  urlFilterConfig?.map((filter) => {
    if (
      filter.field === 'assignedToUserId' &&
      filter.condition === i18next.t(`mapping:operator.text.EMPTY.key`)
    ) {
      return {
        form: formFieldToValueFromUrlMapping.unassigned(filter),
        key: 'unassigned',
      };
    }
    return {
      ...filterToKeyValueMapping(filter),
      form: formFieldToValueFromUrlMapping[filter.field](filter),
    };
  });

interface FilterChipLabelOption {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

interface FilterChipLabelProps {
  option: FilterChipLabelOption;
}

const FilterChipLabel: React.FC<FilterChipLabelProps> = ({ option }) => {
  const { t } = useTranslation();

  const optionKey = option.key;

  if (optionKey === t('mapping:filters.filtering.typesString.key')) {
    return <CaseScreeningTypesStringChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.totalAmount.key')) {
    return <FundsTotalChip form={option.form} />;
  }

  if (optionKey === t('mapping:filters.filtering.anomalyScoreMax.key')) {
    return <AnomalyScoreChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.autoCloseScoreMin.key')) {
    return <AutoCloseScoreChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.falsePositiveReductionScore.key')) {
    return <AutoCloseScoreChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.tenantTransactionIds.key')) {
    return <TenantTransactionIdChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.updatedAt.key')) {
    return <LastUpdatedChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.unassigned.key')) {
    return <UnassignedChip />;
  }
  if (optionKey === t('mapping:filters.filtering.tenantId.key')) {
    return <TenantNameChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.assignedToUserId.key')) {
    return <AssignedToChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.caseNotificationRuleInstanceIds.key')) {
    return <CaseNotificationRuleInstanceChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.ruleInstances.key')) {
    return <RuleInstancesChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.types.key')) {
    return <CaseTypeChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.escalatedAt.key')) {
    return <IsEscalatedChip />;
  }
  if (optionKey === t('mapping:filters.filtering.flows.key')) {
    return <FlowChip form={option.form} />;
  }
  if (
    optionKey === t('mapping:filters.filtering.transactionStatusesV2.key') ||
    optionKey === t('mapping:filters.filtering.customerStatus.key')
  ) {
    return <TransactionStatusChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.caseStatuses.key')) {
    return <CaseStatusChip form={option.form} mappingPath="caseStatus" filterName={optionKey} />;
  }
  if (optionKey === t('mapping:filters.filtering.status.key')) {
    return <CaseStatusChip form={option.form} mappingPath="caseStatus" filterName={optionKey} />;
  }
  if (optionKey === t('mapping:filters.filtering.caseStatus.key')) {
    return <CaseStatusChip form={option.form} mappingPath="caseStatusV2" filterName={optionKey} />;
  }
  if (
    optionKey === t('mapping:filters.filtering.processingStatusesV2.key') ||
    optionKey === t('mapping:filters.filtering.processingStatus.key')
  ) {
    return <ProcessingStatusChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.riskLevels.key')) {
    return <RiskLevelChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.processedAts.key')) {
    return <ProcessedAtChip fieldName="processedAts" form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.processedAt.key')) {
    return <ProcessedAtChip fieldName="processedAt" form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.caseIds.key')) {
    return <CaseIdChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.intermediaryName.key')) {
    return <IntermediaryNameChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.customerName.key')) {
    return <CustomerNameChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.accountIds.key')) {
    return <AccountIdChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.accountNumbers.key')) {
    return <AccountNumberChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.customer.key')) {
    return <CustomersChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.counterpartyName.key')) {
    return (
      <TextChip
        fieldName="counterpartyName"
        filterToValueConverter={counterpartyNameFilterToValueConverter}
        form={option.form}
      />
    );
  }
  if (optionKey === t('mapping:filters.filtering.usage.key')) {
    return (
      <TextChip
        fieldName="usage"
        filterToValueConverter={usageFilterToValueConverter}
        form={option.form}
      />
    );
  }
  if (optionKey === t('mapping:filters.filtering.tenantTransactionId.key')) {
    return (
      <TextChip
        fieldName="usage"
        filterToValueConverter={tenantTransactionIdFilterToValueConverter}
        form={option.form}
      />
    );
  }
  if (optionKey === t('mapping:filters.filtering.counterpartyAccount.key')) {
    return (
      <TextChip
        fieldName="counterpartyAccount"
        filterToValueConverter={counterpartyAccountFilterToValueConverter}
        form={option.form}
      />
    );
  }
  if (optionKey === t('mapping:filters.filtering.customerId.key')) {
    return <CustomerIdsChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.customerGroupIds.key')) {
    return <CustomerGroupIdsChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.riskFactors.key')) {
    return <RiskFactorsChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.totalPoints.key')) {
    return <RiskPointsChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.rescreening.key')) {
    return <ScreeningTypeChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.createdAt.key')) {
    return <CreatedAtChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.event.key')) {
    return <EventChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.userId.key')) {
    return <UserIdChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.paymentMeans.key')) {
    return <PaymentMeansChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.ruleCategories.key')) {
    return <RuleCategoryFilterChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.investigationStatuses.key')) {
    return <InvestigationStatusFilterChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.customerCategory.key')) {
    return <CustomerCategoryChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.additionalIds.key')) {
    return <AdditionalIdChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.screeningListType.key')) {
    return <ScreeningListTypeChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.screeningListJurisdiction.key')) {
    return <ScreeningListJurisdictionChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.channels.key')) {
    return <ChannelsChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.investigationOutcome.key')) {
    return <InvestigationOutcomeFilterChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.orderIds.key')) {
    return <OrderIdsChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.executionScopes.key')) {
    return <ExecutionScopesChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.originatorsBankCountries.key')) {
    return <MultiTextFilterChip form={option.form} filterName={optionKey} />;
  }
  if (optionKey === t('mapping:filters.filtering.beneficiariesBankCountries.key')) {
    return <MultiTextFilterChip form={option.form} filterName={optionKey} />;
  }
  if (optionKey === t('mapping:filters.filtering.roles.key')) {
    return <RoleChip form={option.form} />;
  }
  if (optionKey === t('mapping:filters.filtering.reasonTypes.key')) {
    return <OptionChip form={option.form} filterKey="reasonTypes" />;
  }

  return null;
};

export default FilterChipLabel;

export { mapUrlToFormFilter };
