import { BLACKLIST_LIST_ACTIONS } from 'middleware/action-types';

/**
 * OCCUPATION_LIST_ACTIONS.fileList - to fetch list of previously uploaded files
 * OCCUPATION_LIST_ACTIONS.activateVersion - to update selected file version and set it as active
 */
const BlacklistListReducer = (state = {}, action) => {
  switch (action.type) {
    case BLACKLIST_LIST_ACTIONS.fileList.error:
    case BLACKLIST_LIST_ACTIONS.fileList.fetch: {
      return state;
    }

    case BLACKLIST_LIST_ACTIONS.fileList.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    case BLACKLIST_LIST_ACTIONS.activateVersion.send:
    case BLACKLIST_LIST_ACTIONS.activateVersion.error: {
      return state;
    }

    case BLACKLIST_LIST_ACTIONS.activateVersion.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default BlacklistListReducer;
