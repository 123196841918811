import { put, takeLatest } from 'redux-saga/effects';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { showError } from 'middleware/actions/error';
import {
  getRuleBuilderConfigSuccess,
  saveRuleBuilderConfigSuccess,
} from 'modules/configuration/rule-manager/common/rule-builder/middleware/reducers/rule-builder-reducer';
import { RuleBuilderConfigApi } from 'modules/configuration/rule-manager/common/rule-builder/services/rule-builder-config-api-communicator';

const communicator = new RuleBuilderConfigApi();

function* getRuleBuilderConfigBegin(action) {
  yield put(startProcessing('ruleBuilder/getRuleBuilderConfig'));
  try {
    const json = yield communicator.fetchConfig();
    yield put(getRuleBuilderConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('ruleBuilder/getRuleBuilderConfig'));
}

export function* getRuleBuilderConfigWatcher() {
  yield takeLatest('ruleBuilder/getRuleBuilderConfig', getRuleBuilderConfigBegin);
}

function* saveRuleBuilderConfigBegin(action) {
  yield put(startProcessing('ruleBuilder/saveRuleBuilderConfig'));

  try {
    const json = yield communicator.updateConfig(action.payload);
    yield put(saveRuleBuilderConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing('ruleBuilder/saveRuleBuilderConfig'));
}

export function* saveRuleBuilderConfigWatcher() {
  yield takeLatest('ruleBuilder/saveRuleBuilderConfig', saveRuleBuilderConfigBegin);
}

function* deleteRuleBuilderConfigBegin(action) {
  yield put(startProcessing('ruleBuilder/deleteRuleBuilderConfig'));
  try {
    const json = yield communicator.deleteConfig(action.payload);
    yield put(saveRuleBuilderConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('ruleBuilder/deleteRuleBuilderConfig'));
}

export function* deleteRuleBuilderConfigWatcher() {
  yield takeLatest('ruleBuilder/deleteRuleBuilderConfig', deleteRuleBuilderConfigBegin);
}
