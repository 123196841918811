import { useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, TextField, Divider, Dialog, DialogContent, Typography } from '@mui/material';
import { Translation } from 'react-i18next';
import { LoadingButton } from 'components/loaders/loading-button';
import Alert from '@mui/material/Alert';
import { AuthActions } from 'middleware/action-types';
import { requestResetPassword } from 'modules/authentication/reset-password/middleware/actions/reset-password-actions';
import AppLogo from 'components/app-logo';
import { NavLink } from 'react-router-dom';

const ResetPasswordView = () => {
  const dispatch = useDispatch();
  const resetPasswordState = useSelector((state) => state.resetPassword || {});
  const processing = useSelector((state) => state.processing || {});
  const actionType = useSelector((state) => state.actionType || '');

  const [emailAddress, setEmailAddress] = useState('');

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (emailAddress?.length) {
      dispatch(requestResetPassword(emailAddress));
    }
  };

  const handleEmailChanges = (e) => {
    setEmailAddress(e.target.value);
  };

  const { error } = resetPasswordState;
  let { message } = resetPasswordState;
  if (message !== undefined && message.length > 0) {
    message = message.replace('.', '');
  }

  const isProcessing = actionType ? processing[actionType] : processing?.isProcessing;

  return (
    <Translation>
      {(t) => (
        <Dialog
          open
          fullWidth
          maxWidth="xs"
          className="backdrop-none"
          PaperProps={{ elevation: 1 }}
        >
          <DialogContent>
            <Form>
              <FormGroup>
                <Grid container spacing={1}>
                  <Grid container item xs={12} justifyContent="center">
                    <AppLogo className="app-logo mb-2" />

                    <Divider className="w-100" />
                  </Grid>

                  <Grid container item xs={12} justifyContent="center" className="mt-2 mb-2">
                    <Typography color="textSecondary" variant="body2">
                      {t('forgotPassword.heading')}
                    </Typography>
                  </Grid>
                  {Boolean(message !== undefined && message.length && !isProcessing) && (
                    <Grid item xs={12} id="reset-message">
                      <Alert severity={error ? 'error' : 'success'}>
                        {`${message} ${t('from.heading')} `}
                        <Typography variant="body2" component="span" color="textSecondary">
                          {t('forgotPassword.text')}
                        </Typography>
                      </Alert>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography variant="body2" color="textPrimary">
                      {t('forgotPassword.subheading')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className="mt-2">
                    <TextField
                      fullWidth
                      id="email"
                      type="email"
                      label={t('login.username.heading')}
                      size="small"
                      autoFocus
                      value={emailAddress}
                      onChange={handleEmailChanges}
                    />
                  </Grid>

                  <Grid item xs={12} className="mt-3">
                    <LoadingButton
                      type="submit"
                      id="loginButton"
                      fullWidth
                      disableElevation
                      title={t('forgotPassword.button.heading')}
                      color="primary"
                      variant="contained"
                      onClick={handleResetPassword}
                      multipleActions={[AuthActions.REQUEST_RESET_PASSWORD]}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <NavLink to="/" className="text-decoration-none">
                      <Button color="default" fullWidth>
                        {t('forgotPassword.button.subheading')}
                      </Button>
                    </NavLink>
                  </Grid>
                </Grid>
              </FormGroup>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </Translation>
  );
};

export default ResetPasswordView;
