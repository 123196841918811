import { isEmpty } from 'lodash';
import { Tenant, TenantId, TenantIdSchema, TenantSchema } from 'models/tenant/tenant';
import { SESSION_STORAGE_KEYS } from 'services/tenant/constants/session-storage-keys';
import { LOCAL_STORAGE } from 'utils/constants/constant';
import { getTenantsFromString } from 'utils/helpers/utility';
import { z } from 'zod';

class TenantManagementService {
  tenantsMap: Map<TenantId, Tenant>;

  constructor() {
    this.tenantsMap = new Map();
  }

  static setActiveTenantId(tenantId: TenantId) {
    if (tenantId) {
      localStorage.setItem(SESSION_STORAGE_KEYS.activeTenantId, tenantId);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.activeTenantId, tenantId);
    }
  }

  static getActiveTenantId() {
    let tenantId = sessionStorage.getItem(SESSION_STORAGE_KEYS.activeTenantId);
    if (isEmpty(tenantId)) {
      tenantId = localStorage.getItem(SESSION_STORAGE_KEYS.activeTenantId);
    }
    TenantIdSchema.safeParse(tenantId);
    return tenantId;
  }

  static setTenantName(tenantName?: string) {
    if (tenantName) {
      localStorage.setItem(SESSION_STORAGE_KEYS.tenantName, tenantName);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.tenantName, tenantName);
    }
  }

  static getTenantName() {
    let tenantName = sessionStorage.getItem(SESSION_STORAGE_KEYS.tenantName);
    if (isEmpty(tenantName)) {
      tenantName = localStorage.getItem(SESSION_STORAGE_KEYS.tenantName);
    }
    return tenantName;
  }

  static setLastTenantId(tenantId: TenantId) {
    if (tenantId) {
      TenantIdSchema.safeParse(tenantId);
      localStorage.setItem(SESSION_STORAGE_KEYS.lastTenantId, tenantId);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.lastTenantId, tenantId);
    }
  }

  static getLastTenantId() {
    let lastTenantId = sessionStorage.getItem(SESSION_STORAGE_KEYS.lastTenantId);
    if (isEmpty(lastTenantId)) {
      lastTenantId = localStorage.getItem(SESSION_STORAGE_KEYS.lastTenantId);
    }
    return lastTenantId;
  }

  static setLastTenantName(tenantName?: string) {
    if (tenantName) {
      localStorage.setItem(SESSION_STORAGE_KEYS.lastTenantName, tenantName);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.lastTenantName, tenantName);
    }
  }

  static getLastTenantName() {
    let lastTenantName = sessionStorage.getItem(SESSION_STORAGE_KEYS.lastTenantName);
    if (isEmpty(lastTenantName)) {
      lastTenantName = localStorage.getItem(SESSION_STORAGE_KEYS.lastTenantName);
    }
    return lastTenantName;
  }

  static setActiveTenant(tenant: Tenant) {
    TenantManagementService.setActiveTenantId(tenant?.id);
    TenantManagementService.setTenantName(tenant?.name);
  }

  static getActiveTenant() {
    return {
      id: TenantManagementService.getActiveTenantId(),
      name: TenantManagementService.getTenantName(),
    };
  }

  static setLastActiveTenant(tenant: Tenant) {
    TenantManagementService.setLastTenantId(tenant?.id);
    TenantManagementService.setTenantName(tenant?.name);
  }

  static getLastActiveTenant(): Tenant {
    const tenantId = TenantManagementService.getLastTenantId();
    const tenantName = TenantManagementService.getLastTenantName();
    const tenant = { id: `${tenantId}`, name: `${tenantName}` };
    TenantSchema.safeParse(tenant);
    return tenant;
  }

  static setTenantIdOfCurrentCase(tenantId: TenantId) {
    if (tenantId) {
      TenantIdSchema.parse(tenantId);
      localStorage.setItem(LOCAL_STORAGE.currentCase.tenantId, tenantId);
      sessionStorage.setItem(LOCAL_STORAGE.currentCase.tenantId, tenantId);
    }
  }

  static getTenantIdOfCurrentCase() {
    let tenantId = sessionStorage.getItem(LOCAL_STORAGE.currentCase.tenantId);
    if (isEmpty(tenantId)) {
      tenantId = localStorage.getItem(LOCAL_STORAGE.currentCase.tenantId);
    }
    TenantIdSchema.safeParse(tenantId);
    return tenantId;
  }

  static removeTenantIdOfCurrentCase() {
    localStorage.removeItem(LOCAL_STORAGE.currentCase.tenantId);
    sessionStorage.removeItem(LOCAL_STORAGE.currentCase.tenantId);
  }

  static getTenants() {
    let tenants = sessionStorage.getItem(SESSION_STORAGE_KEYS.tenants);
    if (isEmpty(tenants)) {
      tenants = localStorage.getItem(SESSION_STORAGE_KEYS.tenants);
    }
    return tenants;
  }

  static setTenants(tenants: Array<Tenant>) {
    z.array(TenantSchema).nonempty().safeParse(tenants);
    localStorage.setItem(SESSION_STORAGE_KEYS.tenants, JSON.stringify(tenants));
    sessionStorage.setItem(SESSION_STORAGE_KEYS.tenants, JSON.stringify(tenants));
  }

  static getTenantMap(): Map<TenantId, Tenant> {
    const tenants = getTenantsFromString(TenantManagementService.getTenants()) as Tenant[];
    return tenants.reduce((accumulator, currentValue) => {
      accumulator.set(currentValue.id, currentValue);
      return accumulator;
    }, new Map<TenantId, Tenant>());
  }
}

export default TenantManagementService;
