/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CaseDetail } from 'models/case/case-detail';
import { RiskLevel } from 'models/customer-cases/common';
import { CASE_CONTEXT } from 'utils/constants/case.constants';
import { META_DATA } from 'utils/constants/metadata.constants';

export type Metadata = { key: string; values: { DETAIL: any; SUMMARY: any } };

interface CustomerRiskRatingData {
  summary: {
    TOTAL_POINTS: number;
    FINAL_RISK_LEVEL: RiskLevel;
  };
  detail: { [riskFactorName: string]: any };
  manualRiskLevelChange?: boolean;
  manualOverrides?: unknown;
}

const customerRiskRatingDataFromMappedCase = (
  riskScorePoints: number,
  riskLevel: RiskLevel,
  metadatas: Metadata[],
  manualOverrides?: any,
  manualRiskLevelChange?: boolean,
): CustomerRiskRatingData => {
  const riskRatingDataFromCase: CustomerRiskRatingData = {
    summary: {
      TOTAL_POINTS:
        riskScorePoints ||
        // fallback for old cases prior to Jan 2022
        metadatas?.find((mt) => mt.key === META_DATA.customerRiskRating.key)?.values?.SUMMARY
          ?.TOTAL_POINTS,
      FINAL_RISK_LEVEL:
        riskLevel ||
        // fallback for old cases prior to Jan 2022
        metadatas?.find((mt) => mt.key === META_DATA.customerRiskRating.key)?.values?.SUMMARY
          ?.FINAL_RISK_LEVEL,
    },
    detail: {},
    manualRiskLevelChange,
    manualOverrides,
  };

  const allDetails: any[] = [];
  metadatas
    ?.filter((mt) => mt.key === META_DATA.customerRiskRating.key)
    .forEach((mt) => allDetails.push(mt.values?.DETAIL));

  allDetails.forEach((detail) => {
    Object.keys(detail).forEach((detailKey) => {
      if (!Object.keys(riskRatingDataFromCase?.detail)?.includes(detailKey)) {
        riskRatingDataFromCase.detail[detailKey] = detail[detailKey];
      }
    });
  });

  return riskRatingDataFromCase;
};

const sumOfTotalPoints = (metadatas: Metadata[]): number => {
  let sum = 0;
  metadatas.forEach((mt) => {
    sum += mt.values?.SUMMARY?.TOTAL_POINTS;
  });
  return sum;
};

const prepareCustomerRiskRatingDataForTrx = (
  caseDetail: CaseDetail | any,
  riskLevel?: RiskLevel,
): CustomerRiskRatingData | undefined => {
  const metadatas = caseDetail?.metadataComputedEvent?.metadatas?.filter(
    (mt: { key: string }) => mt.key === META_DATA.customerRiskRating.key,
  );
  if (!riskLevel || !metadatas?.length) return;
  const riskScorePoints = sumOfTotalPoints(metadatas);
  // eslint-disable-next-line consistent-return
  return customerRiskRatingDataFromMappedCase(riskScorePoints, riskLevel, metadatas);
};

const prepareCustomerRiskRatingDataForCrr = (
  caseDetail: CaseDetail | any,
): CustomerRiskRatingData => {
  const {
    riskScorePoints,
    riskLevel,
    metadatas,
    manualOverrides,
    manualRiskLevelChange = false,
  } = caseDetail;
  return customerRiskRatingDataFromMappedCase(
    riskScorePoints,
    riskLevel,
    metadatas,
    manualOverrides,
    manualRiskLevelChange,
  );
};

export const prepareCustomerRiskRatingData = (
  caseDetail: CaseDetail | any,
  riskLevel?: RiskLevel,
): CustomerRiskRatingData | undefined => {
  if (!caseDetail) return;
  if (caseDetail?.metadataComputedEvent?.caseContext === CASE_CONTEXT.transaction) {
    return prepareCustomerRiskRatingDataForTrx(caseDetail, riskLevel);
  }
  return prepareCustomerRiskRatingDataForCrr(caseDetail);
};
