export class TransactionFieldsManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('TransactionFields API Communicator instance is required.');
    }
  }

  getTransactionFields() {
    return this.communicator.fetchComparableFields().then((json) => json);
  }
}
