const PageSize = {
  CASES: 60,
  notes: {
    initial: 75,
    requestMore: 20,
  },
  auditTrail: {
    initial: 50,
  },
  SANCTION_LISTS: 20,
  REGION_SANCTION_LISTS: 2000,
  CUSTOMER_DUE_DILIGENCE: 30,
};

export default PageSize;
