import { useCallback, useState } from 'react';
import jsFileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { showError } from 'middleware/actions/error';
import { getLines, trimLastLine } from 'utils/helpers/stream-util';
import { useTranslation } from 'react-i18next';

type CsvExportStreamResponseReport = {
  Status: string;
  Message: string;
  Success: boolean;
};

export const useStreamDownloader = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const extractResponseReport = useCallback((lines: string[]) => {
    const lastLine = lines[lines.length - 1];
    return JSON.parse(lastLine) as CsvExportStreamResponseReport;
  }, []);

  const handleErrors = useCallback(
    (responseReport: CsvExportStreamResponseReport) => {
      if (!responseReport.Success) {
        dispatch(showError({ message: responseReport.Message }));
      }
    },
    [dispatch],
  );

  const processChunk = useCallback(
    async (reader: ReadableStreamDefaultReader, result: Uint8Array[], fileName: string) => {
      const { done, value } = await reader.read();

      if (done) {
        const previousChunk = result.pop();
        const lines = getLines(previousChunk);
        const responseReport = extractResponseReport(lines);

        handleErrors(responseReport);

        const cleanedUpChunk = trimLastLine(lines);
        result.push(new TextEncoder().encode(cleanedUpChunk));
        const blob = new Blob(result, { type: 'application/octet-stream' });
        jsFileDownload(blob, fileName);
        reader.releaseLock();
        setIsDownloading(false);
        return;
      }
      result.push(value);
      await processChunk(reader, result, fileName);
    },
    [extractResponseReport, handleErrors],
  );

  const downloadFile = useCallback(
    async (url: string, fileName: string, headers: Headers, payload: object) => {
      setIsDownloading(true);

      try {
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          dispatch(showError({ message: t('file.download.error.downloadError.heading') }));
          setIsDownloading(false);
          return;
        }

        if (!response.body) {
          dispatch(showError({ message: t('file.download.error.downloadError.noData') }));
          setIsDownloading(false);
          return;
        }

        const reader = response.body.getReader();
        const result: Uint8Array[] = [];

        await processChunk(reader, result, fileName);
      } catch (error) {
        setIsDownloading(false);
        dispatch(showError({ message: t('file.download.error.downloadError.heading') }));
        throw error;
      }
    },
    [t, dispatch, processChunk],
  );

  return {
    isDownloading,
    downloadFile,
  };
};
