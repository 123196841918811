import { NETWORK_ANALYSIS_ACTION } from 'middleware/action-types';

const networkAnalysisReducer = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_ANALYSIS_ACTION.GET_NETWORK_ANALYSIS_SUCCESS:
    case NETWORK_ANALYSIS_ACTION.GET_PASSTHROUGH_NETWORK_SUCCESS:
    case NETWORK_ANALYSIS_ACTION.passthroughV2.success: {
      const payload = action.payload || {};
      return {
        ...state,
        [payload.type]: payload.middleManList,
      };
    }
    case NETWORK_ANALYSIS_ACTION.passthroughV3.success: {
      const payload = action.payload || {};
      return {
        ...state,
        [payload.type]: payload.middleManList,
      };
    }
    default:
      return state;
  }
};

export default networkAnalysisReducer;
