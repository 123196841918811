const highlightColor = '#fb8175';
const defaultHighlightColor = '#2532e5';
const defaultHorverColor = '#F5F5F5';
const disabledColor = '#ccc';
const whiteColor = '#fff';
const darkColor = '#5d5b5d';
const grayBackgroundColor = '#E2E2E2';

const getDefaultSelectComponentBackgroundColor = (isDisabled, isSelected, isFocused) => {
  if (isDisabled) return null;
  if (isSelected) return defaultHighlightColor;
  if (isFocused) return defaultHorverColor;
  return null;
};
const getDefaultSelectComponentColor = (isDisabled, isSelected, isFocused) => {
  if (isDisabled) return disabledColor;
  if (isSelected) return whiteColor;
  if (isFocused) return darkColor;
  return null;
};
const getSelectComponentBackgroundColor = (isDisabled, isSelected, isFocused) => {
  if (isDisabled) return null;
  if (isSelected) return highlightColor;
  if (isFocused) return highlightColor;
  return null;
};
const getSelectWhiteColor = (isDisabled, isSelected, isFocused) => {
  if (isDisabled) return disabledColor;
  if (isSelected) return whiteColor;
  if (isFocused) return whiteColor;
  return darkColor;
};
const CustomStyles = {
  SelectComponent: {
    control: (base) => ({
      ...base,
      borderColor: 'hsl(0, 0%, 80%)',
      boxShadow: 0,
      '&:hover': {
        borderColor: highlightColor,
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: getSelectComponentBackgroundColor(isDisabled, isSelected, isFocused),
      color: getSelectWhiteColor(isDisabled, isSelected, isFocused),
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? highlightColor : darkColor),
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  },

  defaultSelectComponent: {
    control: (base) => ({
      ...base,
      borderColor: 'hsl(0, 0%, 80%)',
      boxShadow: 0,
      '&:hover': {
        borderColor: defaultHighlightColor,
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: getDefaultSelectComponentBackgroundColor(isDisabled, isSelected, isFocused),
      color: getDefaultSelectComponentColor(isDisabled, isSelected, isFocused),
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        color: darkColor,
        backgroundColor: !isDisabled && defaultHorverColor,
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  },
  GrayBigSelectComponent: {
    control: (base) => ({
      ...base,
      backgroundColor: grayBackgroundColor,
      borderStyle: 'none',
      boxShadow: 0,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: getSelectWhiteColor(isDisabled, isSelected, isFocused),
      color: getSelectWhiteColor(isDisabled, isSelected, isFocused),
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? highlightColor : darkColor),
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  },
};

export default CustomStyles;
