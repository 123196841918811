import { SftpConfigActions } from 'modules/configuration/settings/sftp-config/middleware/action-types';

export const getSftpConfig = () => ({
  type: SftpConfigActions.SFTP_GET,
});

export const getSftpConfigSuccess = (payload) => ({
  type: SftpConfigActions.SFTP_GET_SUCCESS,
  payload,
});

export const getSftpConfigError = () => ({
  type: SftpConfigActions.SFTP_GET_ERROR,
});

export const updateSftpConfig = (payload) => ({
  type: SftpConfigActions.SFTP_UPDATE,
  payload,
});

export const updateSftpConfigSuccess = (payload) => ({
  type: SftpConfigActions.SFTP_UPDATE_SUCCESS,
  payload,
});

export const updateSftpConfigError = () => ({
  type: SftpConfigActions.SFTP_UPDATE_ERROR,
});

export const deleteSftpConfig = (payload) => ({
  type: SftpConfigActions.SFTP_DELETE,
  payload,
});

export const deleteSftpConfigSuccess = (payload) => ({
  type: SftpConfigActions.SFTP_DELETE_SUCCESS,
  payload,
});

export const deleteSftpConfigError = () => ({
  type: SftpConfigActions.SFTP_DELETE_ERROR,
});

export const getSftpConfigurationUnmasked = (payload) => ({
  type: SftpConfigActions.SFTP_GET_UNMASKED,
  payload,
});

export const getSftpConfigurationUnmaskedSuccess = (payload) => ({
  type: SftpConfigActions.SFTP_GET_UNMASKED_SUCCESS,
  payload,
});

export const getSftpConfigurationUnmaskedError = () => ({
  type: SftpConfigActions.SFTP_GET_UNMASKED_ERROR,
});
