const validateColumnLocalStorage = (defaultCells, localStorageColumnConfig) => {
  if (localStorageColumnConfig) {
    defaultCells.forEach((column) => {
      const result = localStorageColumnConfig.find(
        (obj) => obj.sortConfig.name === column.sortConfig.name,
      );

      if (result) {
        column.isVisible = result.isVisible;
      }
    });
  }

  return defaultCells;
};

export { validateColumnLocalStorage };
