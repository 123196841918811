import React, { FC } from 'react';
import { WebLink as WebLinkModel } from 'modules/trx-cases/case-detail/components/weblinks/model/weblink';
import { Box, Typography } from '@mui/material';

type WeblinkProps = {
  weblink: WebLinkModel;
  hidden?: boolean;
};

export const WebLink: FC<WeblinkProps> = ({ weblink, hidden = false }) => (
  <Box overflow="hidden" textOverflow="ellipsis">
    {!hidden ? (
      <a href={weblink.url} target="_blank" rel="noopener noreferrer">
        {weblink.title}
      </a>
    ) : (
      <Typography variant="body2">{weblink.url}</Typography>
    )}
  </Box>
);
