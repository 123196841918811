import TENANT_IDS from 'utils/constants/tenant-ids';
import tameedLogo from 'assets/tenants/tameed/images/logo.png';
import csiLogo from 'assets/tenants/csi/images/logo.png';
import framlLogo from 'assets/tenants/framl/images/logo.png';
import sixLogo from 'assets/tenants/six/images/logo.png';
import metaMapLogo from 'assets/tenants/metamap/images/logo.png';

export interface WhiteLabelContext {
  name: string;
  tenantIds: Array<string>;
  logo: string;
  favicon: string;
}

interface WhiteLabelConfig {
  [key: string]: WhiteLabelContext;
}

const WHITE_LABEL_CONFIG: WhiteLabelConfig = {
  metamap: {
    name: 'metamap',
    tenantIds: [
      TENANT_IDS.qa.metamap.playground,
      TENANT_IDS.qa.metamap.demo,
      TENANT_IDS.prod.metamap.root,
      TENANT_IDS.prod.metamap.stagnig,
      TENANT_IDS.prod.metamap.xendit,
    ],
    logo: metaMapLogo,
    favicon: '',
  },
  tameed: {
    name: 'tameed',
    tenantIds: [TENANT_IDS.prod.tameed.root, TENANT_IDS.prod.tameed.integration],
    logo: tameedLogo,
    favicon: '',
  },
  csi: {
    name: 'csi',
    tenantIds: [TENANT_IDS.qa.csi.demo],
    logo: csiLogo,
    favicon: '',
  },
  six: { name: 'six', tenantIds: [TENANT_IDS.multipleEnviroments.six], logo: sixLogo, favicon: '' },
  framl: { name: 'framl', tenantIds: [TENANT_IDS.dev.framl], logo: framlLogo, favicon: '' },
};

const getWhiteLabelContext = (key: string | null) => {
  if (key === null) return null;

  const whiteLabelList = Object.values(WHITE_LABEL_CONFIG);

  return whiteLabelList.find((whiteLabelObj) => whiteLabelObj.tenantIds.includes(key));
};

export { getWhiteLabelContext };
