import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class VolumeRiskConfigAPICommunicator extends APICommunicator {
  fetchVolumeRiskConfig() {
    const apiUrl = `${
      config.configurationUrl
    }/config/volumerisk/${TenantManagementService.getActiveTenantId()}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default VolumeRiskConfigAPICommunicator;
