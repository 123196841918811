export const SmurfingConfigActions = {
  SMURFING_V2_CONFIG_GET: 'SMURFING_V2_CONFIG_GET',
  SMURFING_V2_CONFIG_GET_SUCCESS: 'SMURFING_V2_CONFIG_GET_SUCCESS',
  SMURFING_V2_CONFIG_GET_ERROR: 'SMURFING_V2_CONFIG_GET_ERROR',
  SMURFING_V2_CONFIG_UPDATE: 'SMURFING_V2_CONFIG_UPDATE',
  SMURFING_V2_CONFIG_UPDATE_SUCCESS: 'SMURFING_V2_CONFIG_UPDATE_SUCCESS',
  SMURFING_V2_CONFIG_UPDATE_ERROR: 'SMURFING_V2_CONFIG_UPDATE_ERROR',
  SMURFING_V2_CONFIG_DELETE: 'SMURFING_V2_CONFIG_DELETE',
  SMURFING_V2_CONFIG_DELETE_SUCCESS: 'SMURFING_V2_CONFIG_DELETE_SUCCESS',
  SMURFING_V2_CONFIG_DELETE_ERROR: 'SMURFING_V2_CONFIG_DELETE_ERROR',
};
