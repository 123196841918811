export const RoundAmountConfigActions = {
  ROUND_AMOUNT_CONFIG_GET: 'ROUND_AMOUNT_CONFIG_GET',
  ROUND_AMOUNT_CONFIG_GET_SUCCESS: 'ROUND_AMOUNT_CONFIG_GET_SUCCESS',
  ROUND_AMOUNT_CONFIG_GET_ERROR: 'ROUND_AMOUNT_CONFIG_GET_ERROR',
  ROUND_AMOUNT_CONFIG_UPDATE: 'ROUND_AMOUNT_CONFIG_UPDATE',
  ROUND_AMOUNT_CONFIG_UPDATE_SUCCESS: 'ROUND_AMOUNT_CONFIG_UPDATE_SUCCESS',
  ROUND_AMOUNT_CONFIG_UPDATE_ERROR: 'ROUND_AMOUNT_CONFIG_UPDATE_ERROR',
  ROUND_AMOUNT_CONFIG_DELETE: 'ROUND_AMOUNT_CONFIG_DELETE',
  ROUND_AMOUNT_CONFIG_DELETE_SUCCESS: 'ROUND_AMOUNT_CONFIG_DELETE_SUCCESS',
  ROUND_AMOUNT_CONFIG_DELETE_ERROR: 'ROUND_AMOUNT_CONFIG_DELETE_ERROR',
};
