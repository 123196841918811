import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import jsFileDownload from 'js-file-download';
import { showError } from 'middleware/actions/error';
import { useDispatch } from 'react-redux';

type DownloadFileRequestConfig = {
  headers: object;
  withCredentials: boolean;
};

type DownloadFileParams = {
  url: string;
  payload: object;
  config: DownloadFileRequestConfig;
  fileName: string;
};

export const useDownloadFile = () => {
  const dispatch = useDispatch();

  return useMutation<void, AxiosError, DownloadFileParams>(
    ({ url, payload, config, fileName }) =>
      axios.post(url, payload, config).then((response) => {
        jsFileDownload(response.data, fileName);
      }),
    {
      onError: (error) => {
        dispatch(showError({ message: error.message }));
      },
    },
  );
};
