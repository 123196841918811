import { RULE_CATEGORY_ACTIONS } from 'middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { getRuleCategoriesError, getRuleCategoriesSuccess } from 'middleware/actions/rule-category';
import RuleCategoryAPICommunicator from 'services/rule-category-api-communicator';
import RuleCategoryManager from 'services/rule-category-manager';

const apiCommunicator = new RuleCategoryAPICommunicator();
const manager = new RuleCategoryManager(apiCommunicator);

function* getRuleCategoriesBegin(action) {
  yield put(startProcessing(RULE_CATEGORY_ACTIONS.fetch.data));
  try {
    const json = yield manager.getRuleCategories();
    yield put(getRuleCategoriesSuccess(json));
  } catch (error) {
    yield put(getRuleCategoriesError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(RULE_CATEGORY_ACTIONS.fetch.data));
}

export function* getRuleCategoriesWatcher() {
  yield takeLatest(RULE_CATEGORY_ACTIONS.fetch.data, getRuleCategoriesBegin);
}
