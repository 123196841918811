import { Divider, FormHelperText, Grid } from '@mui/material';
import Controls from 'components/controls/controls';
import { Form, useForm } from 'hooks/use-form';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getAccountIcon } from 'components/icons/icons';

interface CustomerCategoryFormProps {
  onSubmit: CallableFunction;
  editForm: { values?: string[] };
  filterKey: string;
}

interface CustomerType {
  key: string;
  value: {
    heading: string;
  };
}

export const CustomerCategoryForm: FC<CustomerCategoryFormProps> = ({
  editForm,
  filterKey,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const companyTypeKey = t('mapping:customerType.company.key');
  const customerCategories = Object.values(t(`mapping:customerType`) as CustomerType[]).filter(
    (value) => value.key !== 'any',
  );

  type useFormReturnType = ReturnType<typeof useForm> & {
    errors: Record<string, null>;
  };
  const { values, errors, setErrors, handleInputChange }: useFormReturnType = useForm(
    editForm?.values?.reduce((tmp, it) => ({ ...tmp, [it]: true }), {}) ?? {},
    false,
    // eslint-disable-next-line no-use-before-define
    validate,
  );

  function validate(fieldValues = values): boolean {
    // eslint-disable-next-line no-use-before-define
    const temp: Record<string, string | null> = { ...errors };

    temp.customerCategory = Object.entries(fieldValues).filter(([_k, v]) => v).length
      ? ''
      : t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((it) => it === '');
  }

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: t('mapping:operator.text.TEXT_EQUAL.key'),
        field: filterKey,
        values: Object.entries<boolean>(values)
          .filter(([_key, val]) => val)
          .map(([key, _val]) => key),
      });
    }
  };

  return (
    <Grid container item>
      <Form onSubmit={handleSubmit} data-testid="filters-customer-category-form">
        <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
          {customerCategories.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Controls.Checkbox
                name={item.key}
                label={
                  <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>{getAccountIcon(true, companyTypeKey === item.key)}</Grid>
                    <Grid item>{item.value.heading}</Grid>
                  </Grid>
                }
                onChange={handleInputChange}
                value={values[item.key] ?? false}
                size="small"
                fullWidth={false}
                id={`customer-category-${index}`}
              />
            </Grid>
          ))}
        </Grid>
        {errors.customerCategory && (
          <FormHelperText className="pl-3" error>
            {errors.customerCategory}
          </FormHelperText>
        )}
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button
            type="submit"
            color="primary"
            text={t('filter.heading')}
            onClick={handleSubmit}
            dataTestIdPrefix="filter-by-customer-category"
          />
        </Grid>
      </Form>
    </Grid>
  );
};
