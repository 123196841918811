import React, { ReactNode } from 'react';
import { AvatarGroup, Chip, Grid, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { customComponents } from 'utils/helpers/theme/material.theme';

interface ChipGroupProps {
  values?: ReactNode[];
  maxNumber?: number;
}

const MultiValueChip = ({ maxNumber = 2, values = [] }: ChipGroupProps) => {
  if (isEmpty(values)) {
    return null;
  }

  return (
    <div className="d-flex">
      <Tooltip
        componentsProps={{ tooltip: { className: 'content' } }}
        title={
          values.length > maxNumber ? (
            <Grid
              container
              spacing={1}
              data-testid="more-chips-body"
              style={{ padding: '12px', width: '300px', minHeight: '100px' }}
            >
              {values.map((singleItem, index) => (
                <Grid item key={index}>
                  <Chip size="small" label={singleItem} />
                </Grid>
              ))}
            </Grid>
          ) : null
        }
      >
        <AvatarGroup max={maxNumber} className="chips d-flex" data-testid="multi-value-chip">
          {values.map((singleItem, index) => (
            <Chip
              key={index}
              className={`${
                index !== 0 ? 'ml-n2 border-right-2 border-left-2' : 'border-right-2'
              } border-white`}
              size="small"
              style={customComponents.riskChipComponent.default}
              label={singleItem}
            />
          ))}
        </AvatarGroup>
      </Tooltip>
    </div>
  );
};

export default MultiValueChip;
