import APICommunicator from 'services/api-communicator';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

class BlacklistListApiCommunicator extends APICommunicator {
  fetchBlacklistFilesList(tenantId) {
    const apiUrl = API_URLS.config.blacklist.getMetadata.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }

  updateActiveBlacklistListVersion(tenantId, version) {
    const apiUrl = API_URLS.config.blacklist.updateActiveVersion
      .replaceAll(API_PARAMS.tenantId, tenantId)
      .replaceAll(API_PARAMS.version, version || '');
    return this.postJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, tenantId);
  }
}

export default BlacklistListApiCommunicator;
