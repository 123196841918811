import { put, takeLatest } from 'redux-saga/effects';
import { DecisionEngineActions } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getDecisionEngineError,
  getDecisionEngineSuccess,
  updateDecisionEngineError,
  updateDecisionEngineSuccess,
} from 'middleware/actions/decision-engine-config';
import DecisionEngineConfigAPICommunicator from 'services/decision-engine-config-api-communicator';
import DecisionEngineConfigManager from 'services/decision-engine-config-manager';

const decisionEngineConfigAPICommunicator = new DecisionEngineConfigAPICommunicator();
const decisionEngineConfigManager = new DecisionEngineConfigManager(
  decisionEngineConfigAPICommunicator,
);

function* getDecisionEngineConfigBegin(action) {
  try {
    yield put(startProcessing(DecisionEngineActions.GET_DECISION_ENGINE_CONFIG));

    const json = yield decisionEngineConfigManager.getDecisionEngineConfig();
    yield put(getDecisionEngineSuccess(json));

    yield put(stopProcessing(DecisionEngineActions.GET_DECISION_ENGINE_CONFIG));
  } catch (error) {
    yield put(getDecisionEngineError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(DecisionEngineActions.GET_DECISION_ENGINE_CONFIG));
  }
}

export function* getDecisionEngineConfigWatcher() {
  yield takeLatest(DecisionEngineActions.GET_DECISION_ENGINE_CONFIG, getDecisionEngineConfigBegin);
}

function* updateDecisionEngineConfigBegin(action) {
  try {
    yield put(startProcessing(DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG));

    const json = yield decisionEngineConfigManager.updateDecisionEngineConfig(action.payload);
    yield put(updateDecisionEngineSuccess(json));

    yield put(stopProcessing(DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG));
  } catch (error) {
    yield put(updateDecisionEngineError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG));
  }
}

export function* updateDecisionEngineConfigWatcher() {
  yield takeLatest(
    DecisionEngineActions.UPDATE_DECISION_ENGINE_CONFIG,
    updateDecisionEngineConfigBegin,
  );
}
