import { put, takeEvery } from 'redux-saga/effects';
import { MASKED_ACTIONS } from 'middleware/action-types';
import {
  unmaskCasesSuccess,
  unmaskError,
  unmaskSuccess,
  unveilCounterpartyDataError,
  unveilCounterpartyDataSuccess,
  unveilMasterDataError,
  unveilMasterDataSuccess,
  unveilSanctionHitNamesError,
  unveilSanctionHitNamesSuccess,
} from 'middleware/actions/mask';
import { getCaseDetail, getForwardingCaseDetail } from 'middleware/actions/case';
import { hideError, showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { showNotification } from 'middleware/actions/notification';
import CaseAPICommunicator from 'modules/trx-cases/case-detail/services/case/case-api-communicator';
import CaseManager from 'modules/trx-cases/case-detail/services/case/case-manager';
import i18next from 'i18next';

const caseManager = new CaseManager(new CaseAPICommunicator());

function* unmaskBegin(action) {
  yield put(startProcessing(MASKED_ACTIONS.unmask.data));

  const { payload = {} } = action;
  const isCaseIncluded = payload.uuids.includes(payload.displayUuid);
  const numberOfCases = payload.uuids?.length;

  try {
    const json = yield caseManager.unmaskManyCases(payload);

    json.token = payload.token;
    yield put(unmaskSuccess(json));
    yield put(unmaskCasesSuccess(payload.uuids));

    if (payload.reloadCaseId) {
      if (payload.reloadCaseId === payload.masterUuid) {
        yield put(getCaseDetail(payload.reloadCaseId, false));
      } else {
        yield put(getForwardingCaseDetail(payload.reloadCaseId));
      }
    }

    let noteString = i18next.t('case:dataVisibility.maskedData.unmaskCase.all.heading');

    if (numberOfCases === 1 && isCaseIncluded) {
      noteString = i18next.t('case:dataVisibility.maskedData.unmaskCase.current.heading');
    } else if (numberOfCases > 1 && !isCaseIncluded) {
      noteString = i18next.t('case:dataVisibility.maskedData.unmaskCase.related.heading');
    }

    yield put(showNotification({ message: noteString }));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    yield put(unmaskError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(MASKED_ACTIONS.unmask.data));
}

export function* unmaskWatcher() {
  yield takeEvery(MASKED_ACTIONS.unmask.data, unmaskBegin);
}

function* unveilSanctionHitNamesBegin(action) {
  yield put(startProcessing(MASKED_ACTIONS.unveilSanctionHitName.data));
  const { payload } = action;
  const { displayUuid, masterUuid, displayCase, unveilOriginator, unveilBeneficiary } = payload;

  try {
    const json = yield caseManager.unveilSanctionHitNamesForCase(
      displayCase,
      unveilOriginator,
      unveilBeneficiary,
    );
    yield put(unveilSanctionHitNamesSuccess(json));
    yield put(unmaskSuccess(json));

    if (displayUuid === masterUuid) {
      yield put(getCaseDetail(displayUuid, false));
    } else {
      yield put(getForwardingCaseDetail(displayUuid));
    }
  } catch (error) {
    yield put(unveilSanctionHitNamesError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(MASKED_ACTIONS.unveilSanctionHitName.data));
}

export function* unveilSanctionHitNamesWatcher() {
  yield takeEvery(MASKED_ACTIONS.unveilSanctionHitName.data, unveilSanctionHitNamesBegin);
}

function* unveilCounterpartyDataBegin(action) {
  const { payload } = action;
  const counterpartyData = payload.counterpartyData || {};

  yield put(startProcessing(MASKED_ACTIONS.unveilCounterPartyData.data));
  try {
    const json = yield caseManager.unveilCounterpartyData(payload.uuid, counterpartyData);
    yield put(unveilCounterpartyDataSuccess(json));
    yield put(unmaskSuccess(json));
  } catch (error) {
    yield put(unveilCounterpartyDataError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(MASKED_ACTIONS.unveilCounterPartyData.data));
}

export function* unveilCounterpartyDataWatcher() {
  yield takeEvery(MASKED_ACTIONS.unveilCounterPartyData.data, unveilCounterpartyDataBegin);
}

function* unveilMasterDataBegin(action) {
  const { uuid } = action;

  yield put(startProcessing(MASKED_ACTIONS.unveilMasterData.data));
  try {
    const json = yield caseManager.unmaskMasterData(uuid);
    yield put(unveilMasterDataSuccess(json));
    yield put(unmaskSuccess(json));
  } catch (error) {
    yield put(unveilMasterDataError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(MASKED_ACTIONS.unveilMasterData.data));
}

export function* unveilMasterDataWatcher() {
  yield takeEvery(MASKED_ACTIONS.unveilMasterData.data, unveilMasterDataBegin);
}
