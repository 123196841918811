import axios from 'axios';
import SessionService from 'modules/authentication/login/services/session-service';
import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

const auth = new SessionService();

export class WhitelabelApiCommunicator extends APICommunicator {
  getLogo(tenantId: string) {
    const apiUrl = API_URLS.config.whitelabel.logo.replace(API_PARAMS.tenantId, tenantId);

    return this.getImage(apiUrl, tenantId, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getFavicon(tenantId: string) {
    const apiUrl = API_URLS.config.whitelabel.favicon.replace(API_PARAMS.tenantId, tenantId);

    return this.getImage(apiUrl, tenantId, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  // eslint-disable-next-line class-methods-use-this
  setBrandingImage(type: string, file: File) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const getWhitelabelLogoUploadUrl = (
      type === 'logo' ? API_URLS.config.whitelabel.logo : API_URLS.config.whitelabel.favicon
    ).replace(
      API_PARAMS.tenantId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tenantId,
    );
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post(getWhitelabelLogoUploadUrl, formData, {
        withCredentials: true,
        headers: {
          tenantId,
          correlationId: auth.getCorrelationId(),
          userId: auth.getUsername(),
        },
      })
      .then(() => Promise.resolve(file))
      .catch(() => raiseErrorByCode(ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE));
  }
}
