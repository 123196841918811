import ApiKeyConfigAPICommunicator from 'services/api-key-config-api-communicator';
import { ApiKeyConfigState } from 'modules/configuration/api-key-config/middleware/reducers/api-key-config-reducer';

class ApiKeyConfigManager {
  communicator: ApiKeyConfigAPICommunicator;

  constructor(communicator: ApiKeyConfigAPICommunicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Expected ApiKeyConfigAPICommunicator instance.');
    }
  }

  getApiKeyConfig = (): Promise<ApiKeyConfigState> => this.communicator.fetchConfig();

  deleteApiKey = (keyName?: string): Promise<ApiKeyConfigState> =>
    this.communicator.deleteApiKey(keyName);
}

export default ApiKeyConfigManager;
