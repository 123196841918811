export enum CaseStatuses {
  OPENING = 'OPENING',
  NOT_OPENED = 'NOT_OPENED',
  COMPUTING = 'COMPUTING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  AUTO_CLOSED = 'AUTO_CLOSED',
  MY_CASES = 'MY_CASES',
  ESCALATED = 'ESCALATED',
  ANY = 'ANY',
  FRAUD = 'FRAUD',
  AUTO_CLOSED_REVIEW = 'AUTO_CLOSED_REVIEW',
}

type CaseStatus = `${CaseStatuses}`;

export default CaseStatus;
