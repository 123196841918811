import { ACCOUNT_BASED_FREQUENCY_ACTIONS } from 'modules/configuration/rule-manager/account-based-frequency-increase-decrease/middleware/action-types';

export const getAccountBasedFrequencyIncreaseDecreaseConfig = () => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.fetch.data,
});

export const getAccountBasedFrequencyIncreaseDecreaseConfigSuccess = (payload) => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.fetch.success,
  payload,
});

export const getAccountBasedFrequencyIncreaseDecreaseConfigError = () => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.fetch.error,
});

export const updateAccountBasedFrequencyIncreaseDecreaseConfig = (payload) => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.update.data,
  payload,
});

export const updateAccountBasedFrequencyIncreaseDecreaseConfigSuccess = (payload) => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.update.success,
  payload,
});

export const updateAccountBasedFrequencyIncreaseDecreaseConfigError = () => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.update.error,
});

export const deleteAccountBasedFrequencyIncreaseDecreaseConfig = (payload) => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.delete.data,
  payload,
});

export const deleteAccountBasedFrequencyIncreaseDecreaseConfigSuccess = (payload) => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.delete.success,
  payload,
});

export const deleteAccountBasedFrequencyIncreaseDecreaseConfigError = () => ({
  type: ACCOUNT_BASED_FREQUENCY_ACTIONS.increaseDecrease.delete.error,
});
