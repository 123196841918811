import { TOOLING_ACTIONS, ToolingActions } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import {
  postReprocessCaseError,
  postReprocessCaseSuccess,
  queryReprocessCasesError,
  queryReprocessCasesSuccess,
  reprocessCasesError,
  reprocessCasesSuccess,
} from 'middleware/actions/tooling';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import TenantManagementService from 'services/tenant/tenant-management-service';
import ToolingApiCommunicator from 'services/tooling-api-communicator';
import ToolingManager from 'services/tooling-manager';

const apiCommunicator = new ToolingApiCommunicator();
const manager = new ToolingManager(apiCommunicator);

function* postReprocessCaseBegin(action) {
  yield put(startProcessing(ToolingActions.REPROCESS_CASE_POST));
  try {
    const tenantId = TenantManagementService.getActiveTenantId();
    const json = yield manager.reprocessCases(tenantId, action.payload);
    if (json != null && json.data != null) {
      yield put(postReprocessCaseSuccess(json));
    } else {
      yield put(postReprocessCaseError(json));
    }
  } catch (error) {
    console.log(error);
    yield put(postReprocessCaseError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(ToolingActions.REPROCESS_CASE_POST));
}

export function* postReprocessCaseWatcher() {
  yield takeLatest(ToolingActions.REPROCESS_CASE_POST, postReprocessCaseBegin);
}

function* queryReprocessCasesBegin(action) {
  yield put(startProcessing(TOOLING_ACTIONS.query.send));
  try {
    const json = yield manager.query(action.payload);
    yield put(queryReprocessCasesSuccess(json));
  } catch (error) {
    console.log(error);
    yield put(queryReprocessCasesError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TOOLING_ACTIONS.query.send));
}

export function* queryReprocessCasesWatcher() {
  yield takeLatest(TOOLING_ACTIONS.query.send, queryReprocessCasesBegin);
}

function* reprocessCasesBegin(action) {
  yield put(startProcessing(TOOLING_ACTIONS.reprocess.send));
  try {
    const json = yield manager.reprocess(action.payload);
    yield put(reprocessCasesSuccess(json));
  } catch (error) {
    console.log(error);
    yield put(reprocessCasesError(error));
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(TOOLING_ACTIONS.reprocess.send));
}

export function* executeReprocessCasesWatcher() {
  yield takeLatest(TOOLING_ACTIONS.reprocess.send, reprocessCasesBegin);
}
