import USER_PERMISSIONS from 'utils/constants/user-permissions';

export function hasPermission(userPermissions = [], permissionToCheck) {
  if (userPermissions === undefined || !Array.isArray(userPermissions)) {
    return false;
  }
  /**
   * if checking several permissions to co-exist together
   */
  if (Array.isArray(permissionToCheck)) {
    for (let i = 0; i < permissionToCheck.length; i++) {
      const temp =
        userPermissions.find((p) => p.permission_name === permissionToCheck[i]) !== undefined;
      if (!temp) {
        return false;
      }
    }
    return true;
  }

  return userPermissions.find((p) => p.permission_name === permissionToCheck) !== undefined;
}

export function isHawkAIUser(email) {
  const emailLC = email ? email.toLowerCase() : '';
  return emailLC.endsWith('@hawkai.tech') || emailLC.endsWith('hawk.ai');
}

export function canCreateMasterTenant(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.write.masterTenant);
}

export function canCreateSubTenant(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.write.createSubtenant);
}

export function canSeeCaseDetails(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.read.caseDetails);
}

export function canSeeAssignedUser(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.read.assignedUser);
}

export function canReadAuditTrack(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.read.auditTrack);
}

export function canReprocessCases(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.write.tooling);
}

export function canUpdateDetachmentConfig(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.write.detachmentConfig);
}

export function canUpdateSettings(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.write.settings);
}

export function canAddNewRiskFactor(userPermissions = []) {
  return hasPermission(userPermissions, USER_PERMISSIONS.write.newRiskFactor);
}

/**
 * The same permissions as for adding new risk factors
 */
export function canDeleteRiskFactor(userPermissions = []) {
  return canAddNewRiskFactor(userPermissions);
}

export const checkPermissions = (userPermissions = []) => ({
  canCreateMasterTenant: hasPermission(userPermissions, USER_PERMISSIONS.write.masterTenant),
  canCreateSubTenant: hasPermission(userPermissions, USER_PERMISSIONS.write.createSubtenant),
  canSeeCaseDetails: hasPermission(userPermissions, USER_PERMISSIONS.read.caseDetails),
  canUpdateCaseDetails: hasPermission(userPermissions, USER_PERMISSIONS.write.caseDetails),
  canReprocessCases: hasPermission(userPermissions, USER_PERMISSIONS.write.tooling),
  canUpdateDetachmentConfig: hasPermission(
    userPermissions,
    USER_PERMISSIONS.write.detachmentConfig,
  ),
  canUpdateSettings: hasPermission(userPermissions, USER_PERMISSIONS.write.settings),
  canUpdateWhitelabelSettings: hasPermission(userPermissions, USER_PERMISSIONS.write.whitelabel),
  canSeeAssignedUser: hasPermission(userPermissions, USER_PERMISSIONS.read.assignedUser),
  canUpdateCustomerCaseDetails: hasPermission(
    userPermissions,
    USER_PERMISSIONS.write.customerCaseList,
  ),
  canEditSanctionProvider: hasPermission(userPermissions, USER_PERMISSIONS.write.sanctionProvider),
  canEditCategoryConfig: hasPermission(userPermissions, USER_PERMISSIONS.write.categoryConfig),
  canWriteSftpConfiguration: hasPermission(
    userPermissions,
    USER_PERMISSIONS.write.sftpConfiguration,
  ),
});
