import { put, takeLatest } from 'redux-saga/effects';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { showError } from 'middleware/actions/error';

import { BehavioralAnalysisConfigApi } from 'modules/configuration/machine-intelligence/behavioral-analysis/services/behavioral-analysis-config-api-communicator';
import {
  getBehavioralAnalysisConfigSuccess,
  saveBehavioralAnalysisConfigSuccess,
} from 'modules/configuration/machine-intelligence/behavioral-analysis/middleware/reducers/behavioral-analysis-reducer';

const communicator = new BehavioralAnalysisConfigApi();

function* getBehavioralAnalysisConfigBegin(action) {
  yield put(startProcessing('behavioralAnalysis/getBehavioralAnalysisConfig'));
  try {
    const json = yield communicator.fetchConfig();
    yield put(getBehavioralAnalysisConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('behavioralAnalysis/getBehavioralAnalysisConfig'));
}

export function* getBehavioralAnalysisConfigWatcher() {
  yield takeLatest(
    'behavioralAnalysis/getBehavioralAnalysisConfig',
    getBehavioralAnalysisConfigBegin,
  );
}

function* saveBehavioralAnalysisConfigBegin(action) {
  yield put(startProcessing('behavioralAnalysis/saveBehavioralAnalysisConfig'));

  try {
    const json = yield communicator.updateConfig(action.payload);
    yield put(saveBehavioralAnalysisConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing('behavioralAnalysis/saveBehavioralAnalysisConfig'));
}

export function* saveBehavioralAnalysisConfigWatcher() {
  yield takeLatest(
    'behavioralAnalysis/saveBehavioralAnalysisConfig',
    saveBehavioralAnalysisConfigBegin,
  );
}

function* deleteBehavioralAnalysisConfigBegin(action) {
  yield put(startProcessing('behavioralAnalysis/deleteBehavioralAnalysisConfig'));
  try {
    const json = yield communicator.deleteConfig(action.payload);
    yield put(saveBehavioralAnalysisConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('behavioralAnalysis/deleteBehavioralAnalysisConfig'));
}

export function* deleteBehavioralAnalysisConfigWatcher() {
  yield takeLatest(
    'behavioralAnalysis/deleteBehavioralAnalysisConfig',
    deleteBehavioralAnalysisConfigBegin,
  );
}
