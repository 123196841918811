import { EFFICIENCY_ACTIONS } from 'modules/dashboard/efficiency/middleware/action-types';

export interface KeyData {
  key: string;
  data: {
    key: string;
    data: number;
  };
}

export interface RiskyWordsData {
  key: string;
  truePositive: number;
  falsePositive: number;
  autoClosedFalsePositive: number;
  count: number;
}

export interface Rate {
  key: string;
  data: {
    falsePositiveCasesCount: number;
    totalCasesCount: number;
  };
  startDate: string;
  endDate: string;
  periodDays: number;
  tenantId: string;
  zoneId: string;
}

export interface TruePositivePatternData {
  tenantId: string;
  startDate: string;
  endDate: string;
  zoneId: string;
  totalClosedCases: number;
  totalTruePositiveClosedCases: number;
  closedCaseCount: KeyData[];
  truePositiveCount: KeyData[];
}

export interface CasesPerformanceData {
  tenantId: string;
  startDate: string;
  endDate: string;
  zoneId: string;
  totalClosedCases: number;
  totalTruePositiveClosedCases: number;
  closedCaseCount: KeyData[];
  truePositiveCount: KeyData[];
  falsePositiveCount: KeyData[];
  autoClosedFalsePositiveCount: KeyData[];
}

export interface FalsePositivePatternDataPayload {
  data: KeyData[];
  endDate: string;
  startDate: string;
  tenantId: string;
  zoneId: string;
}

export interface RiskyWordsDataPayload {
  data: { byWordAttribute: RiskyWordsData[] };
  endDate: string;
  startDate: string;
  tenantId: string;
  zoneId: string;
}

export interface TruePositivePatternDataPayload {
  caseCountByRule: TruePositivePatternData;
  caseCountByInstance?: TruePositivePatternData;
}

export interface CasePerformanceDataPayload {
  caseCountByRule: CasesPerformanceData;
}

export interface EfficiencyState {
  casesPerformanceData?: CasesPerformanceData;
  currentPeriodFalsePositiveData?: {
    falsePositiveCasesCount: number;
    totalCasesCount: number;
  };
  currentPeriodFalsePositiveDataDays?: number;
  falsePositivePatternData?: KeyData[];
  previousPeriodFalsePositiveData?: {
    falsePositiveCasesCount: number;
    totalCasesCount: number;
  };
  previousPeriodFalsePositiveDataDays?: number;
  riskyWordsData: { byWordAttribute: RiskyWordsData[] };
  riskyWordsEndDate: string;
  riskyWordsStartDate: string;
  truePositivePatternData?: TruePositivePatternData;
  truePositivePatternDataByInstance?: TruePositivePatternData;
}

const EfficiencyStateDefaults: EfficiencyState = {
  riskyWordsStartDate: '',
  riskyWordsEndDate: '',
  riskyWordsData: { byWordAttribute: [] },
};

const efficiencyReducer = (
  state: EfficiencyState = EfficiencyStateDefaults,
  action: {
    type: string;
    payload:
      | Rate[]
      | FalsePositivePatternDataPayload
      | TruePositivePatternDataPayload
      | RiskyWordsDataPayload;
  },
): EfficiencyState => {
  switch (action.type) {
    case EFFICIENCY_ACTIONS.efficiency.falsePositiveRate.success: {
      const rates = (action.payload as Rate[]) || [];
      const updates = rates.reduce((acc, rate) => {
        const keyUpdate = { [rate.key]: rate.data };
        const periodDaysKeyUpdate = { [`${rate.key}Days`]: rate.periodDays };
        return { ...acc, ...keyUpdate, ...periodDaysKeyUpdate };
      }, {});
      return {
        ...state,
        ...updates,
      };
    }

    case EFFICIENCY_ACTIONS.efficiency.falsePositivePattern.success: {
      return {
        ...state,
        falsePositivePatternData: (action.payload as FalsePositivePatternDataPayload).data,
      };
    }
    case EFFICIENCY_ACTIONS.efficiency.truePositivePattern.success: {
      return {
        ...state,
        truePositivePatternData: (action.payload as TruePositivePatternDataPayload).caseCountByRule,
        truePositivePatternDataByInstance: (action.payload as TruePositivePatternDataPayload)
          .caseCountByInstance,
      };
    }
    case EFFICIENCY_ACTIONS.efficiency.casesPerformance.success: {
      return {
        ...state,
        casesPerformanceData: (action.payload as CasePerformanceDataPayload).caseCountByRule,
      };
    }
    case EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.success: {
      return {
        ...state,
        riskyWordsData: (action.payload as RiskyWordsDataPayload).data,
        riskyWordsStartDate: (action.payload as RiskyWordsDataPayload).startDate,
        riskyWordsEndDate: (action.payload as RiskyWordsDataPayload).endDate,
      };
    }

    default:
      return state;
  }
};

export default efficiencyReducer;
