export const CaseNotificationsConfigActions = {
  CASE_NOTIFICATIONS_CONFIG_GET: 'CASE_NOTIFICATIONS_CONFIG_GET',
  CASE_NOTIFICATIONS_CONFIG_GET_SUCCESS: 'CASE_NOTIFICATIONS_CONFIG_GET_SUCCESS',
  CASE_NOTIFICATIONS_CONFIG_GET_ERROR: 'CASE_NOTIFICATIONS_CONFIG_GET_ERROR',
  CASE_NOTIFICATIONS_CONFIG_UPDATE: 'CASE_NOTIFICATIONS_CONFIG_UPDATE',
  CASE_NOTIFICATIONS_CONFIG_UPDATE_SUCCESS: 'CASE_NOTIFICATIONS_CONFIG_UPDATE_SUCCESS',
  CASE_NOTIFICATIONS_CONFIG_UPDATE_ERROR: 'CASE_NOTIFICATIONS_CONFIG_UPDATE_ERROR',
  CASE_NOTIFICATIONS_CONFIG_DELETE: 'CASE_NOTIFICATIONS_CONFIG_DELETE',
  CASE_NOTIFICATIONS_CONFIG_DELETE_SUCCESS: 'CASE_NOTIFICATIONS_CONFIG_DELETE_SUCCESS',
  CASE_NOTIFICATIONS_CONFIG_DELETE_ERROR: 'CASE_NOTIFICATIONS_CONFIG_DELETE_ERROR',
};
