import { FILTERS_LIST_ACTIONS } from 'middleware/action-types';

export const getFiltersFileList = (tenantId) => ({
  type: FILTERS_LIST_ACTIONS.fileList.fetch,
  payload: { tenantId },
});

export const getFiltersFileListSuccess = (payload) => ({
  type: FILTERS_LIST_ACTIONS.fileList.success,
  payload,
});

export const getFiltersFileListError = () => ({
  type: FILTERS_LIST_ACTIONS.fileList.error,
});

export const activateFiltersList = (version) => ({
  type: FILTERS_LIST_ACTIONS.activateVersion.send,
  payload: { version },
});

export const activateFiltersListSuccess = (payload) => ({
  type: FILTERS_LIST_ACTIONS.activateVersion.success,
  payload,
});

export const activateFiltersListError = () => ({
  type: FILTERS_LIST_ACTIONS.activateVersion.error,
});
