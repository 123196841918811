// the idea was taken from the article: https://www.codemzy.com/blog/fix-chunkloaderror-react
export const lazyRetry = <T = unknown>(
  componentImport: () => Promise<{ default: T }>,
  name: string,
): Promise<{ default: T }> =>
  new Promise((resolve, reject) => {
    const sessionStorageKey = `retry-${name || 'generic'}-refreshed`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionStorageKey) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(sessionStorageKey, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(sessionStorageKey, 'true');
          window.location.reload();
          return;
        }
        reject(error);
      });
  });
