import { REFERENCE_ACCOUNT_V2_ACTIONS } from 'modules/configuration/rule-manager/reference-account-v2/middleware/action-types';

export const getReferenceAccountV2Config = () => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.fetch.data,
});

export const getReferenceAccountV2ConfigSuccess = (payload) => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.fetch.success,
  payload,
});

export const getReferenceAccountV2ConfigError = () => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.fetch.error,
});

export const updateReferenceAccountV2Config = (payload) => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.update.data,
  payload,
});

export const updateReferenceAccountV2ConfigSuccess = (payload) => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.update.success,
  payload,
});

export const updateReferenceAccountV2ConfigError = (payload) => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.update.error,
  payload,
});

export const deleteReferenceAccountV2Config = (payload) => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.delete.data,
  payload,
});

export const deleteReferenceAccountV2ConfigSuccess = (payload) => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.delete.success,
  payload,
});

export const deleteReferenceAccountV2ConfigError = (payload) => ({
  type: REFERENCE_ACCOUNT_V2_ACTIONS.delete.error,
  payload,
});
