import CaseType from 'modules/trx-cases/case-detail/utils/constants/case-type';

import lawIcon from 'assets/images/ic-law.png';
import nonEUIcon from 'assets/images/ic-noneu.png';
import turnOverIcon from 'assets/images/ic-acceleration.png';
import highTicketIcon from 'assets/images/ic-highticket.png';
import cryptoIcon from 'assets/images/ic-crypto.png';
import outgoingIcon from 'assets/images/ic-outgoing.png';
import incomingIcon from 'assets/images/ic-incoming.png';
import incomingOutgoingIcon from 'assets/images/ic-incomingoutgoing.png';
import volumeIcon from 'assets/images/icon_volume.png';
import pepIcon from 'assets/images/pep-icon.png';
import rcaIcon from 'assets/images/rca-icon.png';
import sipIcon from 'assets/images/sip-icon.png';
import soeIcon from 'assets/images/icon-soe.png';
import aeIcon from 'assets/images/icon-associated-entity.png';
import sanctionIcon from 'assets/images/sanction-icon.png';
import sieIcon from 'assets/images/sie-icon.png';
import crhIcon from 'assets/images/highRiskCountries.png';
import usageRiskIcon from 'assets/images/icon-risky-words.png';
import accountBlacklistedIcon from 'assets/images/account_blacklisted.png';
import refAccountIcon from 'assets/images/ref_account.png';
import moneyMuleIcon from 'assets/images/icon-money-mule.png';
import phishingIcon from 'assets/images/icon-phishing.png';
import fanInIcon from 'assets/images/icon-fan-in.png';
import cashTransactionIcon from 'assets/images/cash-transaction.png';
import defaultRiskIcon from 'assets/images/icon-default.png';
import passthroughIcon from 'assets/images/ic-passthrough.png';
import smurfingIcon from 'assets/images/ic-smurfing.png';
import roundAmountIcon from 'assets/images/icon-roundamount.png';
import unusualFrequencyIcon from 'assets/images/icon-counterparty-frequency.png';
import dormantAccountActivationIcon from 'assets/images/icon-dormant-account-activation.png';
import crossBorderVolumeIcon from 'assets/images/icon-cross-border-volume.png';
import volumeIncreaseDecreaseIcon from 'assets/images/icon-volume-increase-decrease.png';
import suspiciousAvgTicketSizeIcon from 'assets/images/icon-suspicious-avg-ticket-size.png';
import articleIcon from 'assets/images/icon-article.png';
import adverseMediaIcon from 'assets/images/icon-adverse-media.png';
import plausibilityIcon from 'assets/images/icon-plausibility.png';
import expectedValueIcon from 'assets/images/icon-expected-value.png';
import percentageRuleIcon from 'assets/images/percentage-rule-icon.png';
import frequenctChangesIcon from 'assets/images/frequent-changes.png';
import groupingRuleIcon from 'assets/images/grouping-rule.svg';

const crmIcon = crhIcon;

const reasonImages = [
  { key: 'Sanction List', image: lawIcon, tooltip: 'Sanction list' },
  { key: 'Non-EU', image: nonEUIcon, tooltip: 'Non-EU' },
  {
    key: 'Increased Turn-Over',
    image: turnOverIcon,
    tooltip: 'Increased turn-over',
  },
  { key: 'High Ticket', image: highTicketIcon, tooltip: 'High ticket' },
  { key: 'Crypto', image: cryptoIcon, tooltip: 'Crypto' },
];

const flowImages = [
  { key: 'outbound', image: outgoingIcon, tooltip: 'Outgoing funds' },
  { key: 'inbound', image: incomingIcon, tooltip: 'Incoming funds' },
  {
    key: 'inboundoutbound',
    image: incomingOutgoingIcon,
    tooltip: 'Incoming/Outgoing funds',
  },
  {
    key: 'outboundinbound',
    image: incomingOutgoingIcon,
    tooltip: 'Incoming/Outgoing funds',
  },
];

const getFlowImage = (flow) => {
  const flows = Array.isArray(flow) ? flow.join('') : flow;
  const flowImage = flowImages.find((i) => i.key === flows);
  return flowImage !== undefined ? flowImage : { key: flows, image: incomingIcon };
};

// WARNING: svg icons will break PDF export. Please, use png instead
const caseTypeImages = [
  { type: CaseType.BP, image: accountBlacklistedIcon },
  { type: CaseType.PEP, image: pepIcon },
  { type: CaseType.RCA, image: rcaIcon },
  { type: CaseType.SIP, image: sipIcon },
  { type: CaseType.SE, image: sanctionIcon },
  { type: CaseType.SP, image: sanctionIcon },
  { type: CaseType.SIE, image: sieIcon },
  { type: CaseType.SOE, image: soeIcon },
  { type: CaseType.AE, image: aeIcon },
  { type: CaseType.AP, image: aeIcon },
  { type: CaseType.CRH, image: crhIcon },
  { type: CaseType.CRM, image: crmIcon },
  { type: CaseType.COUNTRY_RISK_V2, image: crhIcon },
  { type: CaseType.VOLUME_RISK, image: volumeIcon },
  { type: CaseType.VOLUME_RISK_V2, image: volumeIcon },
  { type: CaseType.USAGE_RISK, image: usageRiskIcon },
  { type: CaseType.ACCOUNT_BLACKLISTED, image: accountBlacklistedIcon },
  { type: CaseType.REFERENCE_ACCOUNT, image: refAccountIcon },
  {
    type: CaseType.REFERENCE_ACCOUNT_V2,
    image: refAccountIcon,
  },

  { type: CaseType.MONEY_MULE, image: moneyMuleIcon },
  { type: CaseType.PHISHING, image: phishingIcon },
  { type: CaseType.FAN_IN, image: fanInIcon },
  { type: CaseType.CASH_TRANSACTION, image: cashTransactionIcon },
  { type: CaseType.PASSTHROUGH, image: passthroughIcon },
  { type: CaseType.PASSTHROUGH_V2, image: passthroughIcon },
  { type: CaseType.PASSTHROUGH_V3, image: passthroughIcon },
  { type: CaseType.RISKY_WORDS_V2, image: usageRiskIcon },
  { type: CaseType.SMURFING, image: smurfingIcon },
  { type: CaseType.ROUND_AMOUNT, image: roundAmountIcon },
  {
    type: CaseType.UNUSUAL_COUNTER_PARTY_FREQUENCY,
    image: unusualFrequencyIcon,
  },
  { type: CaseType.ACCOUNT_BASED_FREQUENCY, image: unusualFrequencyIcon },
  {
    type: CaseType.DORMANT_ACCOUNT_ACTIVATION,
    image: dormantAccountActivationIcon,
  },
  { type: CaseType.CROSS_BORDER_VOLUME, image: crossBorderVolumeIcon },
  {
    type: CaseType.VOLUME_INCREASE_DECREASE,
    image: volumeIncreaseDecreaseIcon,
  },
  {
    type: CaseType.SUSPICIOUS_AVG_TICKET_SIZE,
    image: suspiciousAvgTicketSizeIcon,
  },
  {
    type: CaseType.ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE,
    image: unusualFrequencyIcon,
  },
  {
    type: CaseType.ROUND_AMOUNT_RATIO_INCREASE_DECREASE,
    image: roundAmountIcon,
  },
  { type: CaseType.COUNTER_PARTY_VOLUME, image: volumeIcon },
  { type: CaseType.REG_314A, image: articleIcon },
  { type: CaseType.AMP, image: adverseMediaIcon },
  { type: CaseType.AME, image: adverseMediaIcon },
  { type: CaseType.PLAUSIBILITY, image: plausibilityIcon },
  { type: CaseType.BLACKLIST, image: accountBlacklistedIcon },
  { type: CaseType.EXPECTED_CUSTOMER_VALUE, image: expectedValueIcon },
  { type: CaseType.WALLET_SCREENING, image: cryptoIcon },
  { type: CaseType.RULE_BUILDER, image: percentageRuleIcon },
  { type: CaseType.PERCENTAGE_RULE, image: percentageRuleIcon },
  { type: CaseType.FREQUENT_CHANGES_RULE, image: frequenctChangesIcon },
  { type: CaseType.GROUPING_RULE, image: groupingRuleIcon },
  { type: CaseType.AML_RISK_SCREENING, image: pepIcon },
  { type: CaseType.VOLUME_RISK_V3, image: volumeIcon },
];

const getCaseTypeImage = (caseType) => {
  const caseTypeImage = caseTypeImages.find((i) => i.type === caseType);
  return caseTypeImage !== undefined ? caseTypeImage : { type: caseType, image: defaultRiskIcon };
};

export { reasonImages, flowImages, getFlowImage, caseTypeImages, getCaseTypeImage };
