import { RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS } from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/action-types';

const RiskyWordsInstantiableListConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.error:
    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.fetch: {
      return state;
    }
    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.update.success:
    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.delete.success:
    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.fileList.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.send:
    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.error: {
      return state;
    }

    case RISKY_WORDS_INSTANTIABLE_LIST_CONFIG_ACTIONS.activateVersion.success: {
      return {
        ...state,
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default RiskyWordsInstantiableListConfigReducer;
