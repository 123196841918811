import { useState } from 'react';
import { Translation } from 'react-i18next';
import { Box, Divider, FormHelperText, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import i18next from 'i18next';
import { TransactionRiskLevelCell } from 'components/table/table-cell/case-table-cell';

const buildRiskLevelValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t('mapping:filters.filtering.riskLevels.key'),
  values: filter?.values,
});

const RiskLevelForm = ({ editForm, onSubmit }) => {
  const initialFValues = {};
  const riskLevels = Object.values(i18next.t('mapping:riskLevel'));
  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  if (editForm) {
    editForm.values.forEach((item) => {
      initialFValues[`riskLevel[${item}]`] = true;
    });
  }

  const handleSelectChange = (event, checkedName) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const newRiskLevels = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newRiskLevels);
    // eslint-disable-next-line no-use-before-define
    validate(newRiskLevels);
  };

  const validate = (newChecked = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    temp.riskLevel = newChecked.length ? '' : i18next.t('required.heading');
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
        field: i18next.t('mapping:filters.filtering.riskLevels.key'),
        values: checkedValues,
      });
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  return (
    <Translation>
      {(t) => (
        <Grid container item>
          <Form onSubmit={handleSubmit}>
            <Grid container item className="pl-3 max-h-vh-33 overflow-auto">
              {riskLevels.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Controls.Checkbox
                    name={`riskLevel[${item.key}]`}
                    label={<TransactionRiskLevelCell riskKey={item.key} />}
                    value={values[`riskLevel[${item.key}]`] ?? false}
                    onChange={(event) => handleSelectChange(event, item.key)}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            {errors.riskLevel && (
              <FormHelperText className="pl-3" error>
                {errors.riskLevel}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const RiskLevelChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.riskLevels.value.heading')}
        </Box>
        {form.values.map((item, index) => (
          <span className="mr-1" key={index}>
            <TransactionRiskLevelCell key={index} riskKey={item} />
          </span>
        ))}
      </>
    )}
  </Translation>
);

export { RiskLevelForm, RiskLevelChip, buildRiskLevelValuesFromUrl };
