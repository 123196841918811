class ManualRiskLevelChangeManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Manual-risk-level-change communicator instance is required.');
    }
  }

  requestManualRiskLevelChange(payload) {
    return this.communicator.requestManualRiskLevelChange(payload);
  }
}

export default ManualRiskLevelChangeManager;
