/* eslint-disable react/jsx-boolean-value */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getPersonName } from 'utils/helpers/cases/case-utility';
import { getPersonData } from 'utils/helpers/cases/case-party-utils';
import { PersonChip } from 'components/chips/person-chip';
import AccountTab from 'modules/trx-cases/case-detail/components/intermediary/popover/account-tab';
import { RootState } from 'middleware/store';

interface PartyAccountTabProps {
  personDetails: any;
  tenantConfig: unknown;
  caseConfig: unknown;
  customerRiskRatingData: any;
  showUnmaskedData?: boolean;
  isIndividual: boolean;
  isCustomer: boolean;
}

const extractPartyDetails = (personDetails: any, isCustomer: boolean) => {
  const partyDetails: any = [];

  if (personDetails?.parties) {
    partyDetails.push(
      ...personDetails.parties.map((partyData: any) => ({
        data: {
          ...partyData,
          parties: [partyData],
          calculatedRiskLevel: personDetails.calculatedRiskLevel,
          transaction: personDetails.transaction,
          underwriterData: personDetails.underwriterData,
        },
        isCustomer,
      })),
    );
  }

  return partyDetails;
};

const AccountHolders: FC<PartyAccountTabProps> = ({
  personDetails,
  isCustomer,
  tenantConfig,
  isIndividual,
  caseConfig,
  customerRiskRatingData,
}) => {
  const partyDetails = extractPartyDetails(personDetails, isCustomer);

  return partyDetails.map((details: any) => (
    <Grid item>
      <PersonChip
        chipSize="small"
        personDetails={details}
        tenantConfig={tenantConfig}
        isCompany={!isIndividual}
        caseConfig={caseConfig}
        customerRiskRatingDataParam={customerRiskRatingData}
        skipHolderName={true}
      />
    </Grid>
  ));
};

/**
 * data for person popover
 * @param {*} props
 */
const PartyAccountTab: FC<PartyAccountTabProps> = ({
  personDetails,
  showUnmaskedData,
  isCustomer,
  caseConfig,
  tenantConfig,
  customerRiskRatingData,
  isIndividual,
}) => {
  const { t } = useTranslation();
  const allMasks = useSelector((state: RootState) => state.masks?.all);

  const personData = getPersonData(allMasks, personDetails, showUnmaskedData);
  const personName = getPersonName(personData);

  return (
    <>
      <AccountTab
        account={{
          accountNumber: personDetails?.transaction?.accountNumber,
          holderName: personName,
        }}
      />
      <Grid container spacing={0.5}>
        <Grid item xs={12} className="mt-1">
          <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
            {t('case:userPopover.partyAccount.accountHolders.heading')}
          </Box>
        </Grid>

        <Grid container item xs={12} className="mt-1" spacing={0.5}>
          {personDetails?.parties?.length > 1 ? (
            <AccountHolders
              personDetails={personDetails}
              isCustomer={isCustomer}
              tenantConfig={tenantConfig}
              isIndividual={isIndividual}
              caseConfig={caseConfig}
              customerRiskRatingData={customerRiskRatingData}
            />
          ) : (
            <Grid item>
              <PersonChip
                chipSize="small"
                readOnly={true}
                personDetails={{ data: personDetails }}
                tenantConfig={tenantConfig}
                isCompany={!isIndividual}
                caseConfig={caseConfig}
                customerRiskRatingDataParam={customerRiskRatingData}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { PartyAccountTab };
