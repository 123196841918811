import { GOAML_ACTIONS } from 'middleware/action-types';

export const getGoAMLXML = (payload) => ({
  type: GOAML_ACTIONS.getXML.data,
  payload,
});

export const getGoAMLXMLSuccess = (payload) => ({
  type: GOAML_ACTIONS.getXML.success,
  payload,
});

export const getGoAMLXMLError = () => ({
  type: GOAML_ACTIONS.getXML.error,
});

export const saveGoAMLXML = (payload) => ({
  type: GOAML_ACTIONS.saveXML.data,
  payload,
});

export const saveGoAMLXMLSuccess = (payload) => ({
  type: GOAML_ACTIONS.saveXML.success,
  payload,
});

export const saveGoAMLXMLError = () => ({
  type: GOAML_ACTIONS.saveXML.error,
});

export const validateGoAMLXML = (payload) => ({
  type: GOAML_ACTIONS.validateXML.data,
  payload,
});

export const validateGoAMLXMLSuccess = (payload) => ({
  type: GOAML_ACTIONS.validateXML.success,
  payload,
});

export const validateGoAMLXMLError = () => ({
  type: GOAML_ACTIONS.validateXML.error,
});

export const clearGoAMLXMLCache = () => ({
  type: GOAML_ACTIONS.clearCache.data,
});
