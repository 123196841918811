import APICommunicator from 'services/api-communicator';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import config from 'utils/helpers/config-utils/config';

class TenantConfigAPICommunicator extends APICommunicator {
  fetchTenantConfig() {
    const apiUrl = API_URLS.tenantService.getConfig.replaceAll(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  fetchTenantConfigWithTenant(tenantId) {
    const apiUrl = API_URLS.tenantService.getConfig.replaceAll(API_PARAMS.tenantId, tenantId);
    return this.getJSONForCustomTenant(
      apiUrl,
      tenantId,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  saveTenantConfig(params) {
    const apiUrl = `${
      config.configurationUrl
    }/config/tenant/${TenantManagementService.getActiveTenantId()}`;
    const body = params;
    return this.putJSONForTenant(apiUrl, body, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  addTenant(params) {
    const apiUrl = API_URLS.tenantService.addTenant;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  addMasterTenant(params) {
    const apiUrl = API_URLS.tenantManagementService.masterTenant.create;
    return this.postJSONForTenant(
      apiUrl,
      params,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
      null,
    );
  }

  getMasterTenantCreationStatus(requestId) {
    const apiUrl = API_URLS.tenantManagementService.masterTenant.getCreationStatus.replaceAll(
      API_PARAMS.requestId,
      requestId,
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, null);
  }

  finalizeMasterTenantCreation(requestId) {
    const apiUrl = API_URLS.tenantManagementService.masterTenant.finalize.replaceAll(
      API_PARAMS.requestId,
      requestId,
    );
    return this.deleteJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE, null);
  }

  addTenantToUsers({ userId, newTenant }) {
    const apiUrl = userId
      ? `${config.userUrl}/add-user-tenant/${encodeURIComponent(userId)}`
      : `${config.userUrl}/add-user-tenant/`;
    return this.postJSONForTenant(apiUrl, newTenant, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getAllTenants(params) {
    const apiUrl = API_URLS.tenantService.getAllTenants;
    return this.getJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getUsers(tenantId) {
    const apiUrl = `${config.userUrl}/users-in-tenant/${tenantId}`;
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateTenantName(params, tenantId) {
    const apiUrl = `${config.userUrl}/tenants/${tenantId}`;
    return this.putJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getUsersNotInTenant(tenantId, otherTenantIds) {
    const apiUrl = `${config.userUrl}/users-in-tenants/${tenantId}`;
    return this.postJSONForTenant(
      apiUrl,
      otherTenantIds,
      ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE,
    );
  }

  updateUserInfo(params) {
    const apiUrl = `${config.userUrl}/update`;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getMasterTenants() {
    const apiUrl = API_URLS.masterTenantService.getMasterTenants;
    return this.getJSONForUserId(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  updateTenantStructure(params) {
    const apiUrl = API_URLS.tenantService.submitTenantHierarchy;
    return this.postJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  /**
   * Call API to update tenant callback config
   */
  putTenantCallbackConfig(params) {
    const apiUrl = API_URLS.tenantService.callback.getConfig.replaceAll(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.putJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getTenantCallbackConfigSecret() {
    const apiUrl = API_URLS.tenantService.callback.detokenizeSecret.replaceAll(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  /**
   * Call API to update scorechain api config
   */
  putTenantScorechainConfig(params) {
    const apiUrl = API_URLS.tenantService.scorechain.getConfig.replaceAll(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.putJSONForTenant(apiUrl, params, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getTenantScorechainConfigSecret() {
    const apiUrl = API_URLS.tenantService.scorechain.detokenizeSecret.replaceAll(
      API_PARAMS.tenantId,
      TenantManagementService.getActiveTenantId(),
    );
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default TenantConfigAPICommunicator;
