/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type JSONValue = string | number | boolean | { [key: string]: JSONValue } | Array<JSONValue>;

interface RuleBuilderConfigState {
  instances: Array<JSONValue>;
  updatedAt: string;
  userId: string;
}

const initialState: RuleBuilderConfigState = {
  instances: [],
  updatedAt: '',
  userId: '',
};

export const ruleBuilderConfigReadingSlice = createSlice({
  name: 'ruleBuilder',
  initialState,
  reducers: {
    getRuleBuilderConfig: (state) => state,
    getRuleBuilderConfigSuccess: (state, action: PayloadAction<RuleBuilderConfigState>) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    saveRuleBuilderConfig: (state, _action: PayloadAction<any>) => state,
    saveRuleBuilderConfigSuccess: (state, action: PayloadAction<RuleBuilderConfigState>) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
    deleteRuleBuilderConfig: (state, _action: PayloadAction<any>) => state,
    deleteRuleBuilderConfigSuccess: (state, action: PayloadAction<RuleBuilderConfigState>) => {
      const newState = {
        ...state,
        instances: action.payload?.instances,
        updatedAt: action.payload?.updatedAt,
        userId: action.payload?.userId,
      };
      return newState;
    },
  },
});

export const {
  getRuleBuilderConfig,
  getRuleBuilderConfigSuccess,
  saveRuleBuilderConfig,
  saveRuleBuilderConfigSuccess,
  deleteRuleBuilderConfig,
} = ruleBuilderConfigReadingSlice.actions;

export default ruleBuilderConfigReadingSlice.reducer;
