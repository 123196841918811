import fetchRetry from 'fetch-retry';

import originalFetch from 'isomorphic-fetch';
import SessionService from 'modules/authentication/login/services/session-service';
import { errorLog } from 'services/api/error-handling/error-reporting';
import { retryOn } from 'services/api/error-handling/retry';
import { logMessageInBackend } from 'services/api/logger/logger';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { HTTP_METHODS } from 'utils/constants/constant';
import ErrorCode from 'utils/constants/error-code';
import {
  raiseErrorByCode,
  raiseErrorByCodeForFCReport,
  maskUserId,
} from 'utils/helpers/error-util';

const auth = new SessionService();

export class APICommunicator {
  static createAdditionalInfo(apiUrl, correlationId) {
    return {
      type: 'backend',
      apiUrl,
      correlationId,
    };
  }

  static readFilenameFromResponse(res) {
    const contentDisposition = res.headers.get('Content-Disposition');
    return /filename\*?=([^']*'')?([^;]*)/.exec(contentDisposition)[2];
  }

  async status(res) {
    if (res.status === 205) {
      // handle 205 RESET_CONTENT as an error
      return Promise.reject(res);
    }
    if (res.status >= 200 && res.status < 300) {
      return Promise.resolve(res);
    }
    if (res instanceof Promise) {
      const error = await this.getErrorContent(res);
      return Promise.reject(error);
    }
    return Promise.reject(res);
  }

  // eslint-disable-next-line class-methods-use-this
  json(res) {
    return res
      .json()
      .then((data) => Promise.resolve(data))
      .catch((err) => {
        logMessageInBackend(`${err.toString()}, url: ${res.url}`, 'warn');
        return Promise.reject(err);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  str(res) {
    return res.text().catch((err) => {
      logMessageInBackend(`${err.toString()}, url: ${res.url}`, 'warn');
      return Promise.reject(err);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  errorCodeOverwrite(error, errorCodeIfError) {
    switch (error?.status) {
      case 205:
        return ErrorCode.RESET_CONTENT;
      case 403:
        return ErrorCode.MISSING_PERMISSIONS;
      default:
        return errorCodeIfError;
    }
  }

  getBodyJsonForTenant(apiUrl, data, errorCodeIfError) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      tenant_id: tenantId,
      tenantId,
      userId: auth.getEmail(),
      correlationId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      headers,
      params: { test: 'ok' },
      retryOn,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        if (
          errorCodeIfError !== ErrorCode.CASE_DETAIL_DATA_NOT_FOUND &&
          errorCodeIfError !== ErrorCode.MCC_LIST_DATA_NOT_FOUND
        ) {
          const errorReportObj = {
            error,
            httpUrl: apiUrl,
            httpMethod: HTTP_METHODS.get,
            functionName: 'getBodyJsonForTenant',
            httpHeaders: JSON.stringify(maskUserId(headers)),
          };
          errorLog(errorReportObj);
        }
        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  getJSONForTenant(apiUrl, errorCodeIfError, moreData, customResponseHandler = this.json) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      tenant_id: tenantId,
      tenantId,
      userId: auth.getEmail(),
      correlationId,
      caseTenantId: moreData?.caseTenantId,
      caseCreatedAt: moreData?.caseCreatedAt,
      windowLocation: window.location.href,
    };

    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      headers,
      retryOn,
    })
      .then(this.status)
      .then(customResponseHandler)
      .catch((error) => {
        if (
          errorCodeIfError !== ErrorCode.CASE_DETAIL_DATA_NOT_FOUND &&
          errorCodeIfError !== ErrorCode.MCC_LIST_DATA_NOT_FOUND
        ) {
          const errorReportObj = {
            error,
            httpUrl: apiUrl,
            httpMethod: HTTP_METHODS.get,
            functionName: 'getJSONForTenant',
            httpHeaders: JSON.stringify(maskUserId(headers)),
          };
          errorLog(errorReportObj);
        }
        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          moreData,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  getXMLForTenant(apiUrl, errorCodeIfError, moreData) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/xml',
      tenant_id: tenantId,
      tenantId,
      userId: auth.getUsername(),
      correlationId,
      caseTenantId: moreData?.caseTenantId,
      windowLocation: window.location.href,
      createdAt: moreData?.createdAt,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      headers,
      retryOn,
    })
      .then(this.status)
      .then(this.str)
      .catch((error) =>
        raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          moreData,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        ),
      );
  }

  /**
   * GET method with Auth0 userId included
   */
  getJSONForUserId(apiUrl, errorCodeIfError, moreData) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      tenantId,
      userId: auth.getUserId(),
      correlationId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      headers,
      retryOn,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        if (errorCodeIfError !== ErrorCode.CASE_DETAIL_DATA_NOT_FOUND) {
          const errorReportObj = {
            error,
            httpUrl: apiUrl,
            httpMethod: HTTP_METHODS.get,
            functionName: 'getJSONForUserId',
            httpHeaders: JSON.stringify(maskUserId(headers)),
          };
          errorLog(errorReportObj);
        }
        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          moreData,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  // TODO: replace correlationId with a sessionId
  postJSONForTenant(
    apiUrl,
    obj,
    errorCodeIfError,
    customHeaders,
    customResponseHandler = this.json,
  ) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      tenantId,
      tenant_id: tenantId,
      userId: auth.getUsername(),
      correlationId,
      windowLocation: window.location.href,
      ...customHeaders,
    };
    const body = JSON.stringify(obj);
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn,
      body,
    })
      .then(this.status)
      .then(customResponseHandler)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: body,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postJSONForTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        const statusCode = error.status;
        const errorCode = this.extractErrorCode(statusCode, errorCodeIfError);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCode),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  // TODO: replace correlationId with a sessionId
  deleteJSONForTenant(apiUrl, obj, errorCodeIfError, customHeaders) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      tenantId,
      tenant_id: tenantId,
      userId: auth.getUsername(),
      correlationId,
      windowLocation: window.location.href,
      ...customHeaders,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'DELETE',
      headers,
      retryOn,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpMethod: HTTP_METHODS.delete,
          functionName: 'deleteJSONForTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  postXMLForTenant(apiUrl, xmlString, errorCodeIfError, moreData) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/xml',
      tenantId,
      tenant_id: tenantId,
      userId: auth.getUsername(),
      correlationId,
      createdAt: moreData?.createdAt,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn,
      body: xmlString,
    })
      .then(this.status)
      .then(this.str)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: xmlString,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postXMLForTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  putXMLForTenant(apiUrl, xmlString, errorCodeIfError, moreData) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/xml',
      tenantId,
      tenant_id: tenantId,
      userId: auth.getUsername(),
      correlationId: auth.getCorrelationId(),
      windowLocation: window.location.href,
      createdAt: moreData?.createdAt,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'PUT',
      headers,
      retryOn,
      body: xmlString,
    })
      .then(this.status)
      .then(this.str)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: xmlString,
          httpMethod: HTTP_METHODS.put,
          functionName: 'putXMLForTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  putJSONForTenant(apiUrl, obj, errorCodeIfError) {
    const tenantId = TenantManagementService.getActiveTenantId();
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      tenantId,
      tenant_id: tenantId,
      userId: auth.getUsername(),
      correlationId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'PUT',
      headers,
      retryOn,
      body: JSON.stringify(obj),
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: obj,
          httpMethod: HTTP_METHODS.put,
          functionName: 'putJSONForTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  getJSONForCustomTenant(apiUrl, tenantId, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      tenantId,
      userId: auth.getUsername(),
      correlationId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      headers,
      retryOn,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpMethod: HTTP_METHODS.get,
          functionName: 'getJSONForCustomTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  postForCustomTenant(apiUrl, obj, tenantId, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      tenantId,
      userId: auth.getUsername(),
      correlationId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn,
      body: obj,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: obj,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postForCustomTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  postJSONForCustomTenant(apiUrl, obj, tenantId, errorCodeIfError, moreData, customHeaders) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      tenantId,
      userId: auth.getUsername(),
      correlationId,
      caseTenantId: moreData?.caseTenantId,
      windowLocation: window.location.href,
      ...customHeaders,
    };
    const body = JSON.stringify(obj);
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn,
      body,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: obj,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postJSONForCustomTenant',
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  postJSONForListTenants(apiUrl, obj, tenantId, tenantIds, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      tenantId,
      tenantIds,
      userId: auth.getUsername(),
      correlationId,
      windowLocation: window.location.href,
    };
    const body = JSON.stringify(obj);
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn,
      body,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: body,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postJSONForListTenants',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  putJSONForCustomTenant(apiUrl, obj, tenantId, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      tenantId,
      userId: auth.getUsername(),
      correlationId,
      windowLocation: window.location.href,
    };
    const body = JSON.stringify(obj);
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'PUT',
      headers,
      retryOn,
      body,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: body,
          httpMethod: HTTP_METHODS.put,
          functionName: 'putJSONForCustomTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  postJSON(apiUrl, obj, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      correlationId,
      windowLocation: window.location.href,
    };
    const body = JSON.stringify(obj);
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn,
      body,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: body,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postJSON',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  // Special case: after login with sso provider, there is no Cookie yet
  // So Authorization header must be sent to backend so that it is verified
  postJSONWithAuthorizationHeader(apiUrl, accessToken, obj, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      correlationId,
      windowLocation: window.location.href,
      Authorization: `Bearer ${accessToken}`,
    };
    const body = JSON.stringify(obj);
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn,
      body,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpBody: body,
          httpMethod: HTTP_METHODS.post,
          functionName: 'postJSONWithAuthorizationHeader',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  deleteForCustomTenant(apiUrl, tenantId, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const userId = auth.getEmail();
    const headers = {
      'Content-Type': 'application/json',
      tenantId,
      correlationId,
      userId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'DELETE',
      headers,
      retryOn,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpMethod: HTTP_METHODS.delete,
          functionName: 'deleteForCustomTenant',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  getJSONFromCaseFiles(apiUrl, tenantId, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      correlationId,
      tenant_id: tenantId,
      tenantId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      headers,
      retryOn,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpMethod: HTTP_METHODS.get,
          functionName: 'getJSONFromCaseFiles',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  getByteArrayFromCaseFiles(apiUrl, tenantId, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      correlationId,
      tenant_id: tenantId,
      tenantId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      headers,
      retryOn,
    })
      .then(this.status)
      .then((res) => res.arrayBuffer())
      .then((arrayBuffer) => arrayBuffer)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpMethod: HTTP_METHODS.get,
          functionName: 'getByteArrayFromCaseFiles',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
        );
      });
  }

  getImage(apiUrl, tenantId, errorCodeIfError) {
    const correlationId = auth.getCorrelationId();
    const headers = {
      'Content-Type': 'application/json',
      correlationId,
      tenantId,
      windowLocation: window.location.href,
    };
    return fetchRetry(originalFetch)(apiUrl, {
      method: 'GET',
      headers,
      retryOn,
    })
      .then(this.status)
      .then((res) => res.arrayBuffer())
      .then((arrayBuffer) => {
        if (arrayBuffer.byteLength > 0) {
          return URL.createObjectURL(new Blob([arrayBuffer]));
        }
        return undefined;
      })
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpMethod: HTTP_METHODS.get,
          functionName: 'getImage',
          httpHeaders: JSON.stringify(maskUserId(headers)),
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl, null),
        );
      });
  }

  getJsonFromExternal(apiUrl, errorCodeIfError) {
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'GET',
      retryOn,
    })
      .then(this.status)
      .then(this.json)
      .catch((error) => {
        const errorReportObj = {
          error,
          httpUrl: apiUrl,
          httpMethod: HTTP_METHODS.get,
          functionName: 'getJsonFromExternal',
        };
        errorLog(errorReportObj);

        return raiseErrorByCode(
          this.errorCodeOverwrite(error, errorCodeIfError),
          undefined,
          APICommunicator.createAdditionalInfo(apiUrl),
        );
      });
  }

  // eslint-disable-next-line class-methods-use-this
  postFCReportDTOForTenant(apiUrl, fcReportDto, response200Handler) {
    const correlationId = auth.getCorrelationId();
    const tenantId = TenantManagementService.getActiveTenantId();
    let errorCode = ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE;
    const headers = {
      Accept: 'application/xml',
      'Content-Type': 'application/json',
      tenantId,
      correlationId,
      userId: auth.getUsername(),
      windowLocation: window.location.href,
    };
    const body = JSON.stringify(fcReportDto);
    return fetchRetry(originalFetch)(apiUrl, {
      credentials: 'include',
      method: 'POST',
      headers,
      retryOn: false,
      body,
    })
      .then((res) => {
        if (res.status === 200) {
          return response200Handler(res);
        }
        if (res.status === 204) {
          return {};
        }
        return res
          .json()
          .then((validationResult) => {
            errorCode = ErrorCode.FINCEN_SAR_DATA_INVALID;
            return Promise.reject(validationResult);
          })
          .catch((err) => {
            logMessageInBackend(`${err.toString()}, url: ${res.url}`, 'warn');
            return Promise.reject(err);
          })
          .then((it) => ({ data: it }));
      })
      .catch((error) => {
        if (!errorCode === ErrorCode.FINCEN_SAR_DATA_INVALID) {
          const errorReportObj = {
            error,
            httpUrl: apiUrl,
            httpBody: body,
            httpMethod: HTTP_METHODS.post,
            functionName: 'postFCReportDTOForTenant',
            httpHeaders: JSON.stringify(maskUserId(headers)),
          };
          errorLog(errorReportObj);
        }
        return raiseErrorByCodeForFCReport(
          errorCode,
          APICommunicator.createAdditionalInfo(apiUrl, correlationId),
          error,
        );
      });
  }

  // eslint-disable-next-line class-methods-use-this
  async getErrorContent(err) {
    return err.then((r) => r);
  }

  // eslint-disable-next-line class-methods-use-this
  extractErrorCode(statusCode, errorCode = '') {
    if (typeof errorCode !== 'string' && errorCode[statusCode]) {
      return errorCode[statusCode];
    }

    return errorCode.default || errorCode;
  }
}

export default APICommunicator;
