import { Box, Divider, Grid } from '@mui/material';
import i18next from 'i18next';
import { Translation, useTranslation } from 'react-i18next';
import { useForm, Form } from 'hooks/use-form';
import Controls from 'components/controls/controls';
import { useDetachment } from 'hooks/use-detachment';

const buildAutoCloseScoreValuesFromUrl = (filter) => ({
  field: i18next.t('mapping:filters.filtering.autoCloseScoreMin.key'),
  condition: i18next.t(`mapping:operator.number.${filter.condition}.key`),
  value: filter?.value,
});

const AutoCloseScoreForm = ({ editForm, onSubmit }) => {
  const { t } = useTranslation();

  const filterConditions = [
    t('mapping:operator.number.NUMERIC_EQUAL'),
    t('mapping:operator.number.NUMERIC_LOWER_THAN'),
    t('mapping:operator.number.NUMERIC_GREATER_THAN'),
    t('mapping:operator.number.NUMERIC_LOWER_THAN_OR_EQUAL'),
    t('mapping:operator.number.NUMERIC_GREATER_THAN_OR_EQUAL'),
  ];

  const initialFValues = {
    amount: editForm?.value || '',
    condition: editForm?.condition
      ? t(`mapping:operator.number.${editForm.condition}`)
      : filterConditions[0],
  };

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define
  const validate = (fieldValues = values) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };
    if ('amount' in fieldValues) {
      // eslint-disable-next-line no-use-before-define
      temp.amount = fieldValues.amount ? '' : t('required.heading');
    }
    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });
    // eslint-disable-next-line no-use-before-define
    if (fieldValues === values) return Object.values(temp).every((amount) => amount === '');
    return null;
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        condition: values.condition.key,
        field: t('mapping:filters.filtering.autoCloseScoreMin.key'),
        value: values.amount,
      });
    }
  };
  return (
    <Grid container item>
      <Form onSubmit={handleSubmit}>
        <Grid container item className="px-3 py-3" spacing={2}>
          <Grid item xs={4}>
            <Controls.Select
              fullWidth
              name="condition"
              label={t('condition.heading')}
              value={values.condition}
              onChange={handleInputChange}
              options={filterConditions}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Controls.Input
                name="amount"
                label={t('case:caseList.filter.amount.heading')}
                value={values.amount}
                onChange={handleInputChange}
                error={errors.amount}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider light />
        </Grid>

        <Grid container item xs={12} justifyContent="flex-end" className="p-2">
          <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
        </Grid>
      </Form>
    </Grid>
  );
};

const AutoCloseScoreChip = ({ form }) => {
  const { autoCloseRelabel: isAutoCloseRelabelAttached } = useDetachment('autoCloseRelabel');
  return (
    <Translation>
      {(t) => (
        <>
          <Box fontSize="caption.fontSize" color="text.secondary">
            {isAutoCloseRelabelAttached
              ? t('mapping:filters.filtering.falsePositiveReductionScore.value.heading')
              : t('mapping:filters.filtering.autoCloseScoreMin.value.heading')}
          </Box>

          <Box fontSize="body2.fontSize">{form.value}</Box>
        </>
      )}
    </Translation>
  );
};

export { AutoCloseScoreForm, AutoCloseScoreChip, buildAutoCloseScoreValuesFromUrl };
