class PassthroughV3ConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Passthrough V3 Config API Communicator instance is requrired.');
    }
  }

  getPassthroughV3Config() {
    return this.communicator.fetchConfig();
  }

  updatePassthroughV3Config(data) {
    return this.communicator.updateConfig(data);
  }

  deletePassthroughV3Config(id) {
    return this.communicator.deleteConfig(id);
  }
}

export default PassthroughV3ConfigManager;
