import { Box, Divider, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { validateDateRange } from 'components/filters/utils/date-range';
import moment from 'moment/moment';
import { startOfMinuteAsString } from 'utils/helpers/date-time-util';
import Controls from 'components/controls/controls';
import FormattedDateTime from 'components/formatted-date-time';
import { Form, useForm } from 'hooks/use-form';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import { TIME_FRAME_OPTIONS } from 'utils/constants/dashboard-constant';

const buildProcessedAtValuesFromUrl = (fieldName, filter) => ({
  field: i18next.t(`mapping:filters.filtering.${fieldName}.key`),
  condition: 'INCLUSIVE_DATE_RANGE',
  rangeStart: filter.rangeStart,
  rangeEnd: filter.rangeEnd,
});

const buildProcessedAtSubmitValues = (fieldName, from, to) => ({
  field: i18next.t(`mapping:filters.filtering.${fieldName}.key`),
  condition: 'INCLUSIVE_DATE_RANGE',
  rangeStart: from,
  rangeEnd: startOfMinuteAsString(to),
});

const ProcessedAtForm = ({ fieldName, editForm, onSubmit }) => {
  const initialFValues = {
    from: moment(editForm?.rangeStart).toDate() || TIME_FRAME_OPTIONS.LAST_3_MONTH.value.from,
    to: moment(editForm?.rangeEnd).toDate() || TIME_FRAME_OPTIONS.LAST_3_MONTH.value.to,
  };

  const { values, errors, setErrors, handleInputChange, validate } = useForm(
    initialFValues,
    true,
    (fieldValues = values) => {
      const errors = validateDateRange(fieldValues, values);
      setErrors(errors);
      if (fieldValues === values) {
        return Object.values(errors).every((x) => x === '');
      }
      return null;
    },
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit(buildProcessedAtSubmitValues(fieldName, values.from, values.to));
    }
  };

  return (
    <Translation>
      {(t) => (
        <Grid container item>
          <Form onSubmit={handleSubmit}>
            <Grid container item className="px-3 py-3" spacing={2}>
              <Grid item xs={12}>
                <Controls.DatePicker
                  name="from"
                  label={t('from.subheading')}
                  value={values.from}
                  onChange={handleInputChange}
                  hasError={!!errors.from}
                  helperText={errors.from}
                />
              </Grid>

              <Grid item xs={12}>
                <Controls.DatePicker
                  name="to"
                  label={t('to.heading')}
                  value={values.to}
                  onChange={handleInputChange}
                  hasError={!!errors.to}
                  helperText={errors.to}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button type="submit" color="primary" text={t('filter.heading')} />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const ProcessedAtChip = ({ fieldName, form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t(`mapping:filters.filtering.${fieldName}.value.heading`)}
        </Box>

        <Box fontSize="body2.fontSize">
          <FormattedDateTime date={new Date(form.rangeStart)} withTime />
          <ArrowForwardIcon fontSize="small" color="action" className="mx-1" />
          <FormattedDateTime date={new Date(form.rangeEnd)} withTime />
        </Box>
      </>
    )}
  </Translation>
);

export { ProcessedAtForm, ProcessedAtChip, buildProcessedAtValuesFromUrl };
