import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';

import TenantManagementService from 'services/tenant/tenant-management-service';
import config from 'utils/helpers/config-utils/config';
import { API_PARAMS, API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';

export class SftpApiCommunicator extends RuleConfigAPICommunicator {
  constructor() {
    super('sftp');
  }

  baseUrl(tenantId: string) {
    return `${`${config.caseManagerServer}/configuration/v1`}/${this.requestMapping}/${
      tenantId ?? TenantManagementService.getActiveTenantId()
    }`;
  }

  detokenizeConfig(instanceUniqueId: string) {
    const apiUrl = API_URLS.config.sftpConfiguration.detokenize
      .replace(API_PARAMS.tenantId, TenantManagementService.getActiveTenantId() || '')
      .replace(API_PARAMS.id, instanceUniqueId);
    return this.getJSONForTenant(apiUrl, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}
