import InitialRedirect from 'modules/authentication/login/components/initial-redirect';
import { useAuthentication } from 'modules/authentication/login/hooks/use-auth';
import { loginWithDatabaseStart } from 'modules/authentication/login/middleware/actions/auth-actions';
import LoginViewV1 from 'modules/authentication/login/view/login-view-v1';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TenantManagementService from 'services/tenant/tenant-management-service';

const AUTH_0 = {
  noTenantMessage: 'Invalid tenant id!',
};

const LoginViewV1Container = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect } = useAuthentication();

  const errorMessageState = useSelector((state) => state.authentication?.error?.errorMessage);
  const hasError = useSelector(
    (state) => state.authentication?.error?.isError ?? (state.authentication?.error || false),
  );

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    if (username.length > 0 && password.length > 0) {
      dispatch(
        loginWithDatabaseStart(username, password, TenantManagementService.getLastTenantId()),
      );
    }
  };

  const handleSsoLogin = (e) => {
    e.preventDefault();
    loginWithRedirect().then();
  };

  const handleEmailChanges = (e) => {
    setUsername(e.target.value);
  };

  const handlePwdChanges = (e) => {
    setPassword(e.target.value);
  };

  const handleShowPasswordChanges = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    if (hasError) {
      const emailComp = document.getElementById('email');

      if (hasError && emailComp) {
        emailComp.focus();
      }
      if (errorMessageState === AUTH_0.noTenantMessage) {
        setErrorMessage(t('login.text'));
      } else {
        setErrorMessage(t('login.subheading'));
      }
    }
  }, [hasError, t, errorMessageState]);

  if (isAuthenticated) return <InitialRedirect />;

  return (
    <LoginViewV1
      username={username}
      password={password}
      isPwdVisible={isPasswordVisible}
      handleLogin={handleLogin}
      handleSsoLogin={handleSsoLogin}
      handleEmailChanges={handleEmailChanges}
      handlePwdChanges={handlePwdChanges}
      handleShowPasswordChanges={handleShowPasswordChanges}
      hasError={hasError}
      errorMessage={errorMessage}
    />
  );
};

export default LoginViewV1Container;
