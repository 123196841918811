import PassthroughV3ConfigAPICommunicator from 'services/passthrough-v3-config-api-communicator';
import PassthroughV3ConfigManager from 'services/passthrough-v3-config-manager';
import { showError } from 'middleware/actions/error';
import {
  deletePassthroughV3ConfigSuccess,
  getPassthroughV3ConfigSuccess,
  updatePassthroughV3ConfigSuccess,
} from 'modules/configuration/rule-manager/passthrough-v3/middleware/actions/passthrough-v3-config';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { PASSTHROUGH_V3_ACTIONS } from 'modules/configuration/rule-manager/passthrough-v3/middleware/action-types';
import { put, takeLatest } from 'redux-saga/effects';

const communicator = new PassthroughV3ConfigAPICommunicator();
const manager = new PassthroughV3ConfigManager(communicator);

function* getPassthroughV3ConfigBegin(action) {
  yield put(startProcessing(PASSTHROUGH_V3_ACTIONS.fetch.data));
  try {
    const json = yield manager.getPassthroughV3Config(action.payload);
    yield put(getPassthroughV3ConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PASSTHROUGH_V3_ACTIONS.fetch.data));
}

export function* getPassthroughV3ConfigWatcher() {
  yield takeLatest(PASSTHROUGH_V3_ACTIONS.fetch.data, getPassthroughV3ConfigBegin);
}

function* updatePassthroughV3ConfigBegin(action) {
  yield put(startProcessing(PASSTHROUGH_V3_ACTIONS.update.data));

  try {
    const json = yield manager.updatePassthroughV3Config(action.payload);
    yield put(updatePassthroughV3ConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PASSTHROUGH_V3_ACTIONS.update.data));
}

export function* updatePassthroughV3ConfigWatcher() {
  yield takeLatest(PASSTHROUGH_V3_ACTIONS.update.data, updatePassthroughV3ConfigBegin);
}

function* deletePassthroughV3ConfigBegin(action) {
  yield put(startProcessing(PASSTHROUGH_V3_ACTIONS.delete.send));

  try {
    const json = yield manager.deletePassthroughV3Config(action.payload);
    yield put(deletePassthroughV3ConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PASSTHROUGH_V3_ACTIONS.delete.send));
}

export function* deletePassthroughV3ConfigWatcher() {
  yield takeLatest(PASSTHROUGH_V3_ACTIONS.delete.send, deletePassthroughV3ConfigBegin);
}
