export const TRX_CASE_DETAIL_ACTIONS = {
  caseNotes: {
    add: {
      data: 'REQUEST_ADD_NOTE',
      success: 'ADD_NOTE_SUCCESS',
      error: 'ADD_NOTE_ERROR',
    },
    downloadAttachment: {
      data: 'DOWNLOAD_ATTACHMENT_NOTE_TRX',
      success: 'DOWNLOAD_ATTACHMENT_NOTE_SUCCESS_TRX',
      error: 'DOWNLOAD_ATTACHMENT_NOTE_ERROR_TRX',
    },
    getUniqueUserId: {
      data: 'GET_UNIQUE_USER_ID',
      success: 'GET_UNIQUE_USER_ID_SUCCESS',
      error: 'GET_UNIQUE_USER_ID_ERROR',
    },
  },
  grouping: {
    stopGrouping: {
      data: 'STOP_GROUPING',
      success: 'STOP_GROUPING_SUCCESS',
      error: 'STOP_GROUPING_ERROR',
    },
  },
};
