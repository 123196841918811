import { CASE_ACTIONS } from 'middleware/action-types';
import { getCaseDetailConfigError, getCaseDetailConfigSuccess } from 'middleware/actions/case';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import TenantConfigAPICommunicator from 'modules/configuration/workflows-and-users/services/tenant-config-api-communicator';
import TenantConfigManager from 'modules/configuration/workflows-and-users/services/tenant-config-manager';
import CaseAPICommunicator from 'modules/trx-cases/case-detail/services/case/case-api-communicator';
import CaseManager from 'modules/trx-cases/case-detail/services/case/case-manager';
import { put, takeLatest } from 'redux-saga/effects';
import APICommunicator from 'services/api-communicator';
import CountryRiskConfigAPICommunicator from 'services/country-risk-config-api-communicator';
import CountryRiskConfigManager from 'services/country-risk-config-manager';

import DecisionEngineConfigAPICommunicator from 'services/decision-engine-config-api-communicator';
import DecisionEngineConfigManager from 'services/decision-engine-config-manager';
import DetachmentConfigManager from 'services/detachment-config-manager';

const decisionEngineConfigManager = new DecisionEngineConfigManager(
  new DecisionEngineConfigAPICommunicator(),
);
const countryRiskConfigManager = new CountryRiskConfigManager(
  new CountryRiskConfigAPICommunicator(),
);
const detachmentConfigManager = new DetachmentConfigManager(new APICommunicator());
const tenantConfigManager = new TenantConfigManager(new TenantConfigAPICommunicator());

const caseCommunicator = new CaseAPICommunicator();
const caseManager = new CaseManager(caseCommunicator);

function* getCaseDetailConfigBegin(action) {
  try {
    yield put(startProcessing(CASE_ACTIONS.config.fetch.data));

    const configVersion = action.payload?.configVersion;
    const tenantId = action.payload?.tenantId;
    let json;
    if (configVersion) {
      json = yield caseManager.getCaseDetailConfig(tenantId, configVersion);
      json.latestTenantConfig = yield tenantConfigManager.fetchTenantConfigWithTenant(tenantId);
    } else {
      // fallback strategy, get the latest config of the case tenantId when config version is not available
      const decisionEngineConfig =
        yield decisionEngineConfigManager.getDecisionEngineConfigWithTenant(tenantId);
      const countryRiskConfig = yield countryRiskConfigManager.getCountryRiskConfigWithTenant(
        tenantId,
      );
      const detachmentConfig = yield detachmentConfigManager.getDetachmentConfig(tenantId);
      const tenantConfig = yield tenantConfigManager.fetchTenantConfigWithTenant(tenantId);

      json = {
        decisionEngineConfig,
        countryRiskConfig,
        detachmentConfig: detachmentConfig.data,
        tenantConfig,
      };
    }
    yield put(getCaseDetailConfigSuccess(json));

    yield put(stopProcessing(CASE_ACTIONS.config.fetch.data));
  } catch (error) {
    yield put(getCaseDetailConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CASE_ACTIONS.config.fetch.data));
  }
}

function* getCaseDetailConfigWatcher() {
  yield takeLatest(CASE_ACTIONS.config.fetch.data, getCaseDetailConfigBegin);
}

export { getCaseDetailConfigWatcher };
