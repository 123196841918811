import { Chip } from '@mui/material';
import { getCustomerDetails } from 'middleware/actions/customer-details';
import {
  getCustomerRiskRatingConfig,
  getCustomerRiskRatingConfigForIndividual,
} from 'middleware/actions/customer-risk-rating-config';
import { unmask } from 'middleware/actions/mask';
import {
  getAccountHolderPartyFromTransaction,
  getCustomerFromTransaction,
  getPersonData,
  getPersonName,
  getVariousCustomerName,
  isCompany,
} from 'utils/helpers/cases/case-utility';
import { getDefaultCustomerCategoryFromState } from 'utils/helpers/config-utils/config-util';
import { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountIcon } from 'components/icons/icons';
import { TAB_KEYS } from 'components/popover/person-details/person-details-tab/person-details-tab';
import { PersonPopover } from 'components/popover/person-popover';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';

const MAX_LENGTH = 20;

/**
 * check if a data already unmasked
 * @param {*} allMasks
 * @param {*} token
 */
const isDataUnmasked = (allMasks, token) => {
  if (!allMasks || !Array.isArray(allMasks)) {
    return false;
  }

  const unmaskedData = allMasks.find((t) => t.token === token && !!t.unmasked);
  return unmaskedData !== undefined;
};

/**
 * check if data should unmask
 * @param {*} individualShown
 * @param {*} visibleState
 * @param {*} isCompanyTransaction
 */
const shouldUnmaskData = (individualShown, visibleState, isCompanyTransaction) => {
  if (!visibleState) {
    return individualShown;
  }

  return isCompanyTransaction;
};

/**
 * truncate text at the middle if it's too long
 * @param {*} text
 */
const truncateTextMiddle = (text) => {
  if (!!text && text.length > MAX_LENGTH) {
    const first8 = text.substring(0, 8);
    const last8 = text.substring(text.length - 8, text.length);
    return `${first8} ... ${last8}`;
  }

  return text;
};

/**
 * truncate text at the last if it's too long
 * @param {*} text
 */
const truncateTextLast = (text) => {
  if (!!text && text.length > MAX_LENGTH) {
    const first8 = text.substring(0, MAX_LENGTH);
    return `${first8} ... `;
  }

  return text;
};

/**
 * get customer display name
 * @param {*} customerName
 * @param {*} personData
 */
const getCustomerDisplayName = (customerName, personData) => {
  if (customerName === getVariousCustomerName()) {
    return customerName;
  }

  return getPersonName(personData);
};

/**
 * chip to display customer details
 * @param {*} uuid
 * @param {*} transaction
 * @param {*} forwardingCaseIds
 * @param customerCaseData
 * @param customerName
 * @param size
 * @param isPageContent
 * @param isCustomerCase
 * @param caseConfig
 * @param tenantConfig
 * @param showCustomerDetailsLink
 */
const CustomerChip = ({
  uuid,
  transaction,
  forwardingCaseIds,
  customerCaseData,
  customerName,
  size = 'small',
  isPageContent,
  isCustomerCase = false,
  caseConfig,
  tenantConfig,
  showCustomerDetailsLink = true,
}) => {
  const riskLevel = transaction?.riskLevel ?? customerCaseData?.riskLevel;
  const masterData = transaction?.masterData ?? customerCaseData?.masterData;
  const caseTenantId = transaction?.tenantId ?? customerCaseData?.tenantId;

  const userPermissions = useSelector((state) => state.authentication?.userPermissions || []);
  const detachmentConfig = useSelector((state) => state.authentication?.detachmentConfig || {});

  const accountHoldingPartyData = masterData?.accountHoldingPartyData;
  const { companyDetails, personalDetails } = {
    ...accountHoldingPartyData,
  };
  const customer = getCustomerFromTransaction(transaction);

  const masks = useSelector((state) => state.masks);
  const username = useSelector((state) => state.authentication.user?.email);

  const [individualShown, setIndividualShown] = useState(false);
  const [isUnmasked, setIsUnmasked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const customerRiskRatingData = useSelector(
    (state) => state?.customerDetails?.[uuid]?.riskRatingData,
  );

  const dispatch = useDispatch();

  const { tenantId, configVersion } = caseConfig;
  const defaultCustomerCategory = useSelector((state) =>
    getDefaultCustomerCategoryFromState({ state, tenantId, configVersion }),
  );
  const isCompanyTransaction = isCompany(
    transaction?.customerCategory || customerCaseData?.customerCategory,
    defaultCustomerCategory,
  );
  useEffect(() => {
    if (!!masks?.casesDataShown && isCompanyTransaction) {
      setIndividualShown(false);
    } else if (
      isDataUnmasked(masks?.all, customer.token) &&
      masks?.unmaskedTokens.includes(customer.token) &&
      !isCompanyTransaction
    ) {
      setIndividualShown(true);
    }
  }, [masks.casesDataShown, isCompanyTransaction, masks.all, customer.token, masks.unmaskedTokens]);

  const shouldUnmask = shouldUnmaskData(
    individualShown,
    masks?.casesDataShown,
    isCompanyTransaction,
  );

  const openPopover = (event) => {
    const anchorResult = anchorEl && anchorEl === event.currentTarget ? null : event.currentTarget;
    event.stopPropagation();
    setAnchorEl(anchorResult);
  };

  const closePopover = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    openPopover(event);
    setIndividualShown(true);

    if (!isUnmasked) {
      dispatch(getCustomerDetails(uuid));
      dispatch(
        unmask({
          masterUuid: uuid,
          displayUuid: uuid,
          uuids: forwardingCaseIds?.length ? forwardingCaseIds : [uuid],
          userId: username,
          caseTenantId,
          isCustomerCase,
        }),
      );

      if (
        isFeatureAccessible(
          DETACHMENT_CONFIG.customerRiskRating.organization,
          detachmentConfig,
          userPermissions,
        ) &&
        isCompanyTransaction
      ) {
        dispatch(getCustomerRiskRatingConfig({ tenantId, configVersion }));
      }
      if (
        isFeatureAccessible(
          DETACHMENT_CONFIG.customerRiskRating.individual,
          detachmentConfig,
          userPermissions,
        ) &&
        !isCompanyTransaction
      ) {
        dispatch(getCustomerRiskRatingConfigForIndividual({ tenantId, configVersion }));
      }

      setIsUnmasked(true);
    }
  };

  const accountHolderParty = getAccountHolderPartyFromTransaction(transaction);
  const personData = getPersonData(
    masks?.all,
    personalDetails,
    companyDetails,
    accountHolderParty,
    masks?.casesDataShown || masks?.caseDetailDataShown || individualShown,
    isCompanyTransaction,
  );

  const customerDisplayName = getCustomerDisplayName(customerName, personData);

  const fallbackCustomerHeading = (
    <Translation>
      {(t) => (
        <span className="font-italic color-on-surface-secondary">{t('customer.heading')}</span>
      )}
    </Translation>
  );

  let label;
  if (customer.masked || customerDisplayName) {
    if (individualShown || shouldUnmask) {
      label = truncateTextLast(customerDisplayName);
    } else if (customerDisplayName === getVariousCustomerName()) {
      label = customerDisplayName;
    } else {
      label = masks?.caseDetailDataShown
        ? customerDisplayName
        : truncateTextMiddle(customer.masked || customerDisplayName);
    }
  } else {
    label = fallbackCustomerHeading;
  }

  if (isPageContent) {
    return (
      <PersonPopover
        personName={label}
        item={customerCaseData}
        transaction={transaction}
        isCustomer
        isCompanyTransaction={isCompanyTransaction}
        isPageContent
        caseConfig={caseConfig}
        tenantConfig={tenantConfig}
        showCustomerDetailsLink={showCustomerDetailsLink}
      />
    );
  }

  return (
    <>
      <Chip
        className={`${riskLevel ?? 'none'} max-w-100 clickable`}
        onClick={handleClick}
        size={size}
        label={label}
        icon={getAccountIcon(true, isCompanyTransaction, riskLevel)}
      />
      <PersonPopover
        anchorEl={anchorEl}
        openDetails={!!anchorEl}
        handleClick={closePopover}
        isCustomer
        defaultTab={TAB_KEYS.customer}
        item={customerCaseData}
        transaction={transaction}
        isPageContent={isPageContent}
        isCompanyTransaction={isCompanyTransaction}
        customerRiskRatingData={customerRiskRatingData}
        caseConfig={caseConfig}
        tenantConfig={tenantConfig}
        isCustomerCase
        showCustomerDetailsLink={showCustomerDetailsLink}
      />
    </>
  );
};

export { CustomerChip, getCustomerDisplayName };
