import { Grid } from '@mui/material';
import { getCountryName } from 'utils/constants/country-list';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RiskChip } from 'components/chips/risk-chip';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { getSettingsFromState, getCountryRiskLevel } from 'utils/helpers/config-utils/config-util';

const CountryOfRegistrationDetails = ({ popoverMode, scoreData, maxPoints, caseConfig }) => {
  const countryRisk = useSelector((state) => {
    const settings = getSettingsFromState(state, caseConfig?.tenantId, caseConfig?.configVersion);
    return settings?.countryRisk;
  });

  return (
    <Translation>
      {(t) => (
        <ScoreDetailsAccordion
          summaryComponent={
            <WheelPointsWithHeadline
              size={popoverMode ? 'small' : 'medium'}
              points={scoreData.CALCULATED_POINTS ?? 0}
              maxPoints={maxPoints}
              headline={t('case:userPopover.riskRating.countryOfRegistration.heading')}
            />
          }
          highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
          detailsComponent={
            <Grid container justifyContent="space-between" className="pb-1">
              <Grid item>
                <RiskChip
                  size="small"
                  risk={getCountryRiskLevel(scoreData.ORIGINAL_DATA, countryRisk)}
                  label={getCountryName(scoreData.ORIGINAL_DATA)}
                />
              </Grid>
            </Grid>
          }
        />
      )}
    </Translation>
  );
};

export default CountryOfRegistrationDetails;
