import RedirectUrl from 'utils/helpers/redirect-url';
import { useAuthentication } from 'modules/authentication/login/hooks/use-auth';
import LoginViewV2 from 'modules/authentication/login/view/login-view-v2';
import InitialRedirect from 'modules/authentication/login/components/initial-redirect';
import SessionService from 'modules/authentication/login/services/session-service';
import { useWhiteLabel } from 'hooks/use-white-label';
import React from 'react';

const LoginViewV2Container = () => {
  const {
    isLoading,
    isAuthenticated: isAuth0Authenticated,
    loginWithRedirect,
  } = useAuthentication();

  const authService = new SessionService();
  const isAuthenticatedDatabase = authService.isAuthenticated();
  const isAuthenticated = isAuth0Authenticated || isAuthenticatedDatabase;

  const { applicationLogo } = useWhiteLabel();

  const login = () => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect().then();
    }
  };

  if (location.pathname !== '/') {
    RedirectUrl.save(location.pathname);
  }

  if (isAuthenticated) return <InitialRedirect />;

  return <LoginViewV2 applicationLogo={applicationLogo} onClick={() => login()} />;
};

export default LoginViewV2Container;
