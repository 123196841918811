import { ROUND_AMOUNT_ACTIONS } from 'modules/configuration/rule-manager/round-amount-ratio-increase-decrease/middleware/action-types';
import { convertMultiplierToReadablePercentage2 } from 'modules/configuration/rule-manager/common/helpers/util';

const RoundAmountRatioIncreaseDecreaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.fetch.success:
    case ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.update.success:
    case ROUND_AMOUNT_ACTIONS.ratioIncreaseDecrease.delete.success: {
      return {
        ...state,
        instances: convertMultiplierToReadablePercentage2(action),
        ...action.payload,
        timestamp: new Date().getTime(),
      };
    }

    default:
      return state;
  }
};

export default RoundAmountRatioIncreaseDecreaseReducer;
