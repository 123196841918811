import ErrorCode from 'utils/constants/error-code';
import { raiseErrorByCode } from 'utils/helpers/error-util';

class CaseDetailsManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Case API Communicator instance is required.');
    }
  }

  static validateCaseDetailJson(json) {
    const valid = json && json.crrCase;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  queryCaseDetail(uuid, tenantId) {
    return this.communicator
      .fetchCaseDetail(uuid, tenantId)
      .then((json) => CaseDetailsManager.validateCaseDetailJson(json));
  }

  updateCaseState(uuid, tenantId, body) {
    return this.communicator.updateCaseState(uuid, tenantId, body).then((json) => json);
  }
}

export default CaseDetailsManager;
