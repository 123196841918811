class CounterPartyVolumeConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Counter-Party Volume Config API Communicator instance is requrired.');
    }
  }

  getCounterPartyVolumeConfig() {
    return this.communicator.fetchConfig();
  }

  updateCounterPartyVolumeConfig(data) {
    return this.communicator.updateConfig(data);
  }

  deleteCounterPartyVolumeConfig(id) {
    return this.communicator.deleteConfig(id);
  }
}

export default CounterPartyVolumeConfigManager;
