import { PassthroughConfigActions } from 'modules/configuration/rule-manager/passthrough/middleware/action-types';

export const getPassthroughConfig = () => ({
  type: PassthroughConfigActions.PASSTHROUGH_CONFIG_GET,
});

export const getPassthroughConfigSuccess = (payload) => ({
  type: PassthroughConfigActions.PASSTHROUGH_CONFIG_GET_SUCCESS,
  payload,
});

export const getPassthroughConfigError = () => ({
  type: PassthroughConfigActions.PASSTHROUGH_CONFIG_GET_ERROR,
});

export const updatePassthroughConfig = (payload) => ({
  type: PassthroughConfigActions.PASSTHROUGH_CONFIG_UPDATE,
  payload,
});

export const updatePassthroughConfigSuccess = (payload) => ({
  type: PassthroughConfigActions.PASSTHROUGH_CONFIG_UPDATE_SUCCESS,
  payload,
});

export const updatePassthroughConfigError = () => ({
  type: PassthroughConfigActions.PASSTHROUGH_CONFIG_UPDATE_ERROR,
});
