import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class VolumeRiskConfigManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Volume Risk Config API Communicator instance is required.');
    }
  }

  validateConfigJson(json) {
    if (json && json.lowRisk && json.mediumRisk && json.highRisk) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.CASES_DATA_INVALID);
  }

  getVolumeRiskConfig() {
    return this.communicator.fetchVolumeRiskConfig().then((json) => this.validateConfigJson(json));
  }
}

export default VolumeRiskConfigManager;
