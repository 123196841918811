import { put, takeLatest } from 'redux-saga/effects';
import {
  updateCaseStateError,
  updateCaseStateSuccess,
} from 'modules/customer-cases/case-detail/middleware/actions/case-state';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import CaseDetailApiCommunicator from 'modules/customer-cases/case-detail/services/case-detail-api-communicator';
import CaseDetailManager from 'modules/customer-cases/case-detail/services/case-detail-manager';
import { buildCustomerCaseStateChangeNote } from 'modules/trx-cases/case-detail/components/case-actions/utils/case-state-change-util';
import { showNotification } from 'middleware/actions/notification';
import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';

const caseDetailCommunicator = new CaseDetailApiCommunicator();
const caseDetailManager = new CaseDetailManager(caseDetailCommunicator);

function* updateCaseState(action, updateCaseState) {
  yield put(startProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.stateUpdate.data));
  try {
    const json = yield updateCaseState(action);
    const noteString = buildCustomerCaseStateChangeNote({ caseStateChange: action.payload.body });

    yield put(
      updateCaseStateSuccess({
        type: action.type,
        data: json,
      }),
    );
    yield put(showNotification({ message: noteString }));
  } catch (error) {
    yield put(updateCaseStateError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.stateUpdate.data));
}

function* updateCaseStateBegin(action) {
  yield updateCaseState(action, ({ payload }) => {
    const { uuid, tenantId, body } = payload;
    return caseDetailManager.updateCaseState(uuid, tenantId, body);
  });
}

export function* updateCaseStateWatcher() {
  yield takeLatest(CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.stateUpdate.data, updateCaseStateBegin);
}
