import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { RISK_CATEGORY } from 'utils/constants/constant';
import { ChipWithPersonPopover } from 'components/chips/chip-with-person-popover';

/**
 * representing chips with risk
 * @param {*} param
 */
const ClickableRiskChip = (props) => {
  const { item, risk, size, label, tooltip, dataTestIdPrefix } = props;

  const theme = useTheme();
  const riskLC = risk?.toLowerCase()?.replace(RISK_CATEGORY.default, '');
  const style = theme.custom.riskChipComponent[riskLC];

  const chipProps = {
    style,
    size,
    label,
  };

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <ChipWithPersonPopover
            item={item}
            defaultTab="riskRating"
            dataTestIdPrefix={dataTestIdPrefix}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...chipProps}
          />
        </Tooltip>
      ) : (
        <ChipWithPersonPopover
          item={item}
          defaultTab="riskRating"
          dataTestIdPrefix={dataTestIdPrefix}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...chipProps}
        />
      )}
    </>
  );
};

export { ClickableRiskChip };
