import { OCCUPATION_LIST_ACTIONS } from 'middleware/action-types';
import {
  activateOccupationListError,
  activateOccupationListSuccess,
  getOccupationFileListError,
  getOccupationFileListSuccess,
} from 'middleware/actions/occupation-list';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import OccupationListApiCommunicator from 'services/occupation-list-api-communicator';
import OccupationListManager from 'services/occupation-list-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { showError } from '../actions/error';

const apiCommunicator = new OccupationListApiCommunicator();
const occupationListManager = new OccupationListManager(apiCommunicator);

/**
 * Fetch customer occupation files action
 */
export function* getOccupationFileListBegin(action) {
  try {
    yield put(startProcessing(OCCUPATION_LIST_ACTIONS.fileList.fetch));

    const json = yield occupationListManager.getOccupationFileList(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getOccupationFileListSuccess(json));

    yield put(stopProcessing(OCCUPATION_LIST_ACTIONS.fileList.fetch));
  } catch (error) {
    yield put(getOccupationFileListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(OCCUPATION_LIST_ACTIONS.fileList.fetch));
  }
}

/**
 * Fetch customer occupation files action watcher
 */
export function* getOccupationFileListWatcher() {
  yield takeLatest(OCCUPATION_LIST_ACTIONS.fileList.fetch, getOccupationFileListBegin);
}

/**
 * Activate fetch customer occupation files watcher
 */
export function* activateOccupationFileListWatcher() {
  yield takeLatest(OCCUPATION_LIST_ACTIONS.fileList.fetch, activateOccupationFileListWatcher);
}

/**
 *  Update active customer occupation file version action
 */
export function* updateActiveOccupationListVersionBegin(action) {
  try {
    yield put(startProcessing(OCCUPATION_LIST_ACTIONS.activateVersion.send));

    const json = yield occupationListManager.updateActiveOccupationListVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateOccupationListSuccess(json));

    yield put(stopProcessing(OCCUPATION_LIST_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateOccupationListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(OCCUPATION_LIST_ACTIONS.activateVersion.send));
  }
}

/**
 *  Update active customer occupation file version action watcher
 */
export function* updateActiveOccupationListVersionWatcher() {
  yield takeLatest(
    OCCUPATION_LIST_ACTIONS.activateVersion.send,
    updateActiveOccupationListVersionBegin,
  );
}

/**
 *  Activate update active customer occupation file version watcher
 */
export function* activateUpdateActiveOccupationListVersionWatcher() {
  yield takeLatest(OCCUPATION_LIST_ACTIONS.activateVersion.send, activateOccupationFileListWatcher);
}
