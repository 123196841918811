import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { EFFICIENCY_ACTIONS } from 'modules/dashboard/efficiency/middleware/action-types';
import {
  getGroupsCasesPerformanceError,
  getGroupsCasesPerformanceSuccess,
  getGroupsFalsePositivePatternError,
  getGroupsFalsePositivePatternSuccess,
  getGroupsFalsePositiveRateError,
  getGroupsFalsePositiveRateSuccess,
  getGroupsTruePositivePatternError,
  getGroupsTruePositivePatternSuccess,
  getRiskyWordsPatternError,
  getRiskyWordsPatternSuccess,
} from 'modules/dashboard/efficiency/middleware/actions/efficiency';
import EfficiencyAPICommunicator from 'modules/dashboard/efficiency/middleware/services/efficiency-communicator';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import DashboardManager from 'services/dashboard-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { isItemExpired } from 'utils/helpers/date-time-util';
import { cleanUpLocalStorage } from 'utils/helpers/utility';

const efficiencyAPICommunicator = new EfficiencyAPICommunicator();
const efficiencyManager = new DashboardManager(efficiencyAPICommunicator);

// 1 min expired, since report is fast enough
const EXPIRE_TIME_IN_SECONDS = 60;

function* getGroupsFalsePositiveRateBegin(action) {
  yield put(startProcessing(EFFICIENCY_ACTIONS.efficiency.falsePositiveRate.data));
  try {
    const tenantId = TenantManagementService.getActiveTenantId();

    const storeKey = `${action.type}_${tenantId}_${action.payload.periodDays}`;
    const storeDateKey = `${storeKey}_datetime`;
    const response = localStorage.getItem(storeKey);
    const responseDateString = localStorage.getItem(storeDateKey);

    let json;
    if (response && !isItemExpired(responseDateString, EXPIRE_TIME_IN_SECONDS)) {
      json = JSON.parse(response);
    } else {
      cleanUpLocalStorage(storeKey);
      json = yield efficiencyManager.getGroupsFalsePositiveRate(action.payload);
      localStorage.setItem(storeKey, JSON.stringify(json));
      localStorage.setItem(storeDateKey, new Date().toString());
    }
    yield put(getGroupsFalsePositiveRateSuccess(json));
  } catch (error) {
    yield put(getGroupsFalsePositiveRateError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(EFFICIENCY_ACTIONS.efficiency.falsePositiveRate.data));
}

export function* getGroupsFalsePositiveRateWatcher() {
  yield takeEvery(
    EFFICIENCY_ACTIONS.efficiency.falsePositiveRate.data,
    getGroupsFalsePositiveRateBegin,
  );
}

function* getGroupsFalsePositivePatternBegin(action) {
  yield put(startProcessing(EFFICIENCY_ACTIONS.efficiency.falsePositivePattern.data));
  try {
    const tenantId = TenantManagementService.getActiveTenantId();

    const { startDate, endDate } = action.payload;
    const storeKey = `${action.type}_${tenantId}_${startDate.valueOf()}_${endDate.valueOf()}`;
    const storeDateKey = `${storeKey}_datetime`;
    const response = localStorage.getItem(storeKey);
    const responseDateString = localStorage.getItem(storeDateKey);

    let json;
    if (response && !isItemExpired(responseDateString, EXPIRE_TIME_IN_SECONDS)) {
      json = JSON.parse(response);
    } else {
      cleanUpLocalStorage(storeKey);
      json = yield efficiencyManager.getGroupsFalsePositivePattern(action.payload);
      localStorage.setItem(storeKey, JSON.stringify(json));
      localStorage.setItem(storeDateKey, new Date().toString());
    }
    yield put(getGroupsFalsePositivePatternSuccess(json));
  } catch (error) {
    yield put(getGroupsFalsePositivePatternError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(EFFICIENCY_ACTIONS.efficiency.falsePositivePattern.data));
}

export function* getGroupsFalsePositivePatternWatcher() {
  yield takeEvery(
    EFFICIENCY_ACTIONS.efficiency.falsePositivePattern.data,
    getGroupsFalsePositivePatternBegin,
  );
}

function* getGroupsTruePositivePatternBegin(action) {
  yield put(startProcessing(EFFICIENCY_ACTIONS.efficiency.truePositivePattern.data));
  try {
    const tenantId = TenantManagementService.getActiveTenantId();

    const { startDate, endDate } = action.payload;
    const storeKey = `${action.type}_${tenantId}_${startDate.valueOf()}_${endDate.valueOf()}`;
    const response = localStorage.getItem(storeKey);

    const storeKeyByInstance = `${
      action.type
    }_BY_INSTANCE_${tenantId}_${startDate.valueOf()}_${endDate.valueOf()}`;
    const responseByInstance = localStorage.getItem(storeKeyByInstance);

    const storeDateKey = `${storeKey}_datetime`;
    const responseDateString = localStorage.getItem(storeDateKey);

    let json;
    let json1;
    if (
      response &&
      responseByInstance &&
      !isItemExpired(responseDateString, EXPIRE_TIME_IN_SECONDS)
    ) {
      json = JSON.parse(response);
      json1 = JSON.parse(responseByInstance);
    } else {
      cleanUpLocalStorage(storeKey);
      json = yield efficiencyManager.getGroupsTruePositivePattern(action.payload);
      json1 = yield efficiencyManager.getGroupsTruePositivePatternByInstance(action.payload);
      localStorage.setItem(storeKey, JSON.stringify(json));
      localStorage.setItem(storeKeyByInstance, JSON.stringify(json1));
      localStorage.setItem(storeDateKey, new Date().toString());
    }
    yield put(
      getGroupsTruePositivePatternSuccess({
        caseCountByRule: json,
        caseCountByInstance: json1,
      }),
    );
  } catch (error) {
    yield put(getGroupsTruePositivePatternError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(EFFICIENCY_ACTIONS.efficiency.truePositivePattern.data));
}

export function* getGroupsTruePositivePatternWatcher() {
  yield takeEvery(
    EFFICIENCY_ACTIONS.efficiency.truePositivePattern.data,
    getGroupsTruePositivePatternBegin,
  );
}

function* getGroupsCasesPerformanceBegin(action) {
  yield put(startProcessing(EFFICIENCY_ACTIONS.efficiency.casesPerformance.data));
  try {
    const tenantId = TenantManagementService.getActiveTenantId();

    const { startDate, endDate } = action.payload;
    const storeKey = `${action.type}_${tenantId}_${startDate.valueOf()}_${endDate.valueOf()}`;
    const response = localStorage.getItem(storeKey);

    const storeDateKey = `${storeKey}_datetime`;
    const responseDateString = localStorage.getItem(storeDateKey);

    let json;
    if (response && !isItemExpired(responseDateString, EXPIRE_TIME_IN_SECONDS)) {
      json = JSON.parse(response);
    } else {
      cleanUpLocalStorage(storeKey);
      json = yield efficiencyManager.getGroupsCasesPerformance(action.payload);
      localStorage.setItem(storeKey, JSON.stringify(json));
      localStorage.setItem(storeDateKey, new Date().toString());
    }
    yield put(
      getGroupsCasesPerformanceSuccess({
        caseCountByRule: json,
      }),
    );
  } catch (error) {
    yield put(getGroupsCasesPerformanceError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(EFFICIENCY_ACTIONS.efficiency.casesPerformance.data));
}

export function* getGroupsCasesPerformanceWatcher() {
  yield takeEvery(
    EFFICIENCY_ACTIONS.efficiency.casesPerformance.data,
    getGroupsCasesPerformanceBegin,
  );
}
function* getRiskyWordsPatternBegin(action) {
  yield put(startProcessing(EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.data));
  try {
    const tenantId = TenantManagementService.getActiveTenantId();

    const { startDate, endDate } = action.payload;
    const storeKey = `${action.type}_${tenantId}_${startDate.valueOf()}_${endDate.valueOf()}`;
    const response = localStorage.getItem(storeKey);

    const storeDateKey = `${storeKey}_datetime`;
    const responseDateString = localStorage.getItem(storeDateKey);

    let json;
    if (response && !isItemExpired(responseDateString, EXPIRE_TIME_IN_SECONDS)) {
      json = JSON.parse(response);
    } else {
      cleanUpLocalStorage(storeKey);
      json = yield efficiencyManager.getRiskyWordsPattern(action.payload);
      localStorage.setItem(storeKey, JSON.stringify(json));
      localStorage.setItem(storeDateKey, new Date().toString());
    }
    yield put(getRiskyWordsPatternSuccess(json));
  } catch (error) {
    yield put(getRiskyWordsPatternError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.data));
  }
  yield put(stopProcessing(EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.data));
}

export function* getRiskyWordsPatternWatcher() {
  yield takeLatest(EFFICIENCY_ACTIONS.efficiency.riskyWordsPattern.data, getRiskyWordsPatternBegin);
}
