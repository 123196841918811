import { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Chip, Divider, Grid } from '@mui/material';
import { Form, useForm } from 'hooks/use-form';
import { useDetachment } from 'hooks/use-detachment';
import Controls from 'components/controls/controls';
import i18next from 'i18next';
import _ from 'lodash';

const buildEventValuesFromUrl = (filter) => ({
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  field: i18next.t('mapping:filters.filtering.event.key'),
  values: filter?.values,
});

const removeAutoClosedEvent = (eventList, isAutoCloseRelabelAttached) => {
  const autocloseIndex = eventList.findIndex((event) => event.key === 'SCREENING_AUTOCLOSE');
  const fprIndex = eventList.findIndex((event) => event.key === 'SCREENING_FPR');

  if (autocloseIndex !== -1 && fprIndex !== -1) {
    if (isAutoCloseRelabelAttached) {
      eventList.splice(autocloseIndex, 1);
    } else {
      eventList.splice(fprIndex, 1);
    }
  }

  return eventList;
};

const EventForm = ({ editForm, onSubmit, fullWidth }) => {
  const { t } = useTranslation();
  const { autoCloseRelabel: isAutoCloseRelabelAttached } = useDetachment('autoCloseRelabel');

  const initialFValues = { event: [] };

  if (editForm) {
    const generateFullObject = [];
    editForm.values.forEach((item) => {
      generateFullObject.push(t(`mapping:auditTrail.event.${item}`));
    });
    initialFValues.event = generateFullObject;
  }

  let eventList = Object.values(t('mapping:auditTrail.event'));

  eventList = eventList.filter((event) => event.key !== t('mapping:auditTrail.event.ANY.key'));

  eventList = removeAutoClosedEvent(eventList, isAutoCloseRelabelAttached);

  const event = _.sortBy(eventList, (item) => item.value.heading);

  const [checkedValues, setCheckedValues] = useState(editForm?.values ?? []);

  const handleEventChange = (event) => {
    // eslint-disable-next-line no-use-before-define
    handleInputChange(event);
    const keyArray = event.target.value.map((o) => o.key);
    setCheckedValues(keyArray);
    // eslint-disable-next-line no-use-before-define
    validate(keyArray);
  };

  /**
   * validating field form
   * @param fieldValues
   * @returns
   */
  // eslint-disable-next-line no-use-before-define, consistent-return
  const validate = (fieldValues = checkedValues) => {
    // eslint-disable-next-line no-use-before-define
    const temp = { ...errors };

    temp.event = fieldValues.length ? '' : i18next.t('required.heading');

    // eslint-disable-next-line no-use-before-define
    setErrors({
      ...temp,
    });

    // eslint-disable-next-line no-use-before-define
    return Object.values(temp).every((field) => field === '');
  };

  const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onSubmit({
        field: i18next.t('mapping:filters.filtering.event.key'),
        condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
        values: checkedValues,
      });
    }
  };

  return (
    <Translation>
      {(t) => (
        <Grid container className={fullWidth ? 'w-250' : ''}>
          <Form className="w-100">
            <Grid container item className="px-3 py-3" spacing={1}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Controls.MultipleInput
                    name="event"
                    label={t('condition.equal.heading')}
                    value={values.event}
                    onChange={(event) => handleEventChange(event)}
                    error={errors.event}
                    options={event}
                    filterSelectedOptions={false}
                    freeSolo={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end" className="p-2">
              <Controls.Button
                dataTestIdPrefix="filter-by-event"
                onClick={handleSubmit}
                type="submit"
                color="primary"
                text={t('filter.heading')}
              />
            </Grid>
          </Form>
        </Grid>
      )}
    </Translation>
  );
};

const EventChip = ({ form }) => (
  <Translation>
    {(t) => (
      <>
        <Box fontSize="caption.fontSize" color="text.secondary">
          {t('mapping:filters.filtering.event.value.heading')}
        </Box>
        {form.values.map((item, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            className="mr-1"
            label={t(`mapping:auditTrail.event.${item}.value.heading`)}
          />
        ))}
      </>
    )}
  </Translation>
);

export { EventForm, EventChip, buildEventValuesFromUrl };
