import { PASSTHROUGH_V3_ACTIONS } from 'modules/configuration/rule-manager/passthrough-v3/middleware/action-types';

const passthroughV3ConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSTHROUGH_V3_ACTIONS.fetch.success:
    case PASSTHROUGH_V3_ACTIONS.update.success:
    case PASSTHROUGH_V3_ACTIONS.delete.success:
      return {
        ...state,
        ruleInstances: action.payload.instances,
        updateDate: action.payload.updatedAt,
        userId: action.payload.userId,
      };
    default:
      return state;
  }
};

export default passthroughV3ConfigReducer;
