/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { ScoreDetailsAccordion } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/score-details-accordion';
import { WheelPointsWithHeadline } from 'components/popover/person-details/person-details-tab/risk-rating/shared-components/wheel-points-with-headline';
import { useTranslation } from 'react-i18next';
import { CRR_METADATA_KEYS } from 'utils/constants/crr.constants';
import { Grid, Typography } from '@mui/material';
import { RiskChip } from 'components/chips/risk-chip';

interface ListUploadDetailsProps {
  scoreData: any;
  maxPoints: number;
  popoverMode: boolean;
  title: string;
}

const ListUploadDetails: FC<ListUploadDetailsProps> = ({
  scoreData,
  maxPoints,
  popoverMode,
  title,
}) => {
  const metaDataKeys = CRR_METADATA_KEYS;
  const { t } = useTranslation();

  return (
    <ScoreDetailsAccordion
      summaryComponent={
        <WheelPointsWithHeadline
          size={popoverMode ? 'small' : 'medium'}
          points={scoreData?.[metaDataKeys.calculatedPoints] ?? 0}
          maxPoints={maxPoints}
          headline={title}
          subHead={undefined}
        />
      }
      highestPointsApplied={scoreData?.HIGHEST_POINTS_APPLIED}
      detailsComponent={
        <Grid container justifyContent="space-between" className="pb-1">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {scoreData?.[metaDataKeys.originalData]}
            </Typography>
          </Grid>
          <Grid item>
            <RiskChip
              risk={scoreData?.[metaDataKeys.calculatedListUploadRiskLevel]}
              size="small"
              label={t(
                `riskCategory.${scoreData?.[
                  metaDataKeys.calculatedListUploadRiskLevel
                ].toLowerCase()}.heading`,
              )}
              tooltip={undefined}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default ListUploadDetails;
