import { ContactDetails } from 'modules/trx-cases/case-detail/model/contact-details';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { useSelector } from 'react-redux';
import { RootState } from 'middleware/store';
import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EncryptedAndHashed } from 'modules/trx-cases/case-detail/model/encrypted';
import { getCountryName } from 'utils/constants/country-list';
import { ContactData } from 'components/popover/person-details/person-details-tab/contact-data';
import {
  decryptWebLinks,
  formatMaskedArray,
  formatMaskedValue,
} from 'utils/helpers/cases/case-utility';

interface DbaDataProps {
  dbaContactDetails: ContactDetails[];
  handleChange?: () => void;
  showUnmaskedData: boolean;
}

/**
 * Doing business as data of customer
 * @param {*} props
 */
const DbaData: FC<DbaDataProps> = ({ dbaContactDetails, handleChange, showUnmaskedData }) => {
  const { t } = useTranslation();
  const allMasks = useSelector((state: RootState) => state.masks?.all);

  const formatMaskedValueInternal = (fieldObject: EncryptedAndHashed | undefined) =>
    formatMaskedValue(showUnmaskedData, allMasks, fieldObject);
  const formatMaskedArrayInternal = (dataArray: EncryptedAndHashed[]) =>
    formatMaskedArray(showUnmaskedData, allMasks, dataArray);
  const decryptWebLinksInternal = (webLinks: any[]) =>
    decryptWebLinks(showUnmaskedData, allMasks, webLinks);

  const dbaContactsData = dbaContactDetails.map((contactDetails: ContactDetails) => {
    const countryValue = formatMaskedValueInternal(contactDetails?.country);

    const address = {
      street: formatMaskedValueInternal(contactDetails?.street),
      streetExtended: formatMaskedValueInternal(contactDetails?.streetExtended),
      town: formatMaskedValueInternal(contactDetails?.town),
      region: formatMaskedValueInternal(contactDetails?.region),
      zip: formatMaskedValueInternal(contactDetails?.zip),
      country: {
        value: countryValue,
        name: getCountryName(countryValue),
      },
      unstructured: formatMaskedValueInternal(contactDetails?.unstructuredAddress),
    };

    const contactData = {
      name: contactDetails?.contactName && formatMaskedValueInternal(contactDetails?.contactName),
      extendedName: formatMaskedValueInternal(contactDetails?.contactNameExtended),
      contactId: formatMaskedValueInternal(contactDetails?.contactId),
      emailAddress: formatMaskedArrayInternal(contactDetails?.emailAddresses || []),
      phoneNumber: formatMaskedArrayInternal(contactDetails?.phoneNumbers || []),
      webLinksV2: decryptWebLinksInternal(contactDetails?.webLinksV2 || []),
      addressValidSince: contactDetails?.addressValidSince,
      relatedCustomerId: formatMaskedArrayInternal(contactDetails?.allAssignedCustomerIds || []),
      address,
    };
    return contactData;
  });
  return (
    <Grid container spacing={1}>
      <Grid container item alignItems="center">
        {handleChange && (
          <Grid item>
            <IconButtons.Back edge="start" onClick={handleChange} />
          </Grid>
        )}

        <Grid item>
          <Box fontSize="body2.fontSize" fontWeight="fontWeightMedium">
            {t('case:userPopover.customer.doingBusinessAs.heading')}
          </Box>
        </Grid>
      </Grid>

      <Grid container item flexDirection="column" style={{ paddingBottom: 30 }}>
        {isEmpty(dbaContactDetails) ? (
          <Typography variant="body2" component="div">
            {t('case:userPopover.customer.doingBusinessAs.empty.heading')}
          </Typography>
        ) : (
          dbaContactsData?.map((contactData, index) => (
            <Accordion
              variant="outlined"
              key={index}
              defaultExpanded={index === 0}
              style={{ maxWidth: '100%' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container>
                  <Grid item>
                    <Typography variant="body2" component="div">
                      {contactData?.name ?? '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <ContactData contactData={contactData} />
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export { DbaData };
