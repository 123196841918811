import React, { useEffect, useRef, useState } from 'react';
import Spinner from 'components/loaders/spinner';
import { useSelector } from 'react-redux';
import { getFirstPropertyInArray } from 'utils/helpers/utility';
import { RootState } from 'middleware/store';
import { Button, IconButton, Tooltip } from '@mui/material';

interface LoadingButtonProps {
  title?: string;
  size?: 'small' | 'medium' | 'large';
  disableElevation?: boolean;
  iconButtonMode?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
  parentClick?: React.MutableRefObject<(event: React.MouseEvent<HTMLButtonElement>) => void>;
  isLoading?: boolean;
  conditionalLoading?: boolean;
  globalActionListener?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  multipleActions?: string[] | string;
  className?: string;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  spinnerModeOnly?: boolean;
  isDisabled?: boolean;
  'data-testid'?: string;
}

/**
 * Loading button
 */
export const LoadingButton = ({
  title,
  size,
  disableElevation = true,
  iconButtonMode,
  variant,
  color,
  style,
  startIcon,
  parentClick,
  isLoading,
  conditionalLoading,
  globalActionListener,
  onClick,
  multipleActions,
  className,
  id,
  type,
  fullWidth,
  spinnerModeOnly,
  isDisabled,
  'data-testid': dataTestId = '',
}: LoadingButtonProps) => {
  const processing = useSelector((state: RootState) => state.processing);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const buttonRef = useRef<HTMLAnchorElement>(null);
  const [minWidth, setMinWidth] = useState(0);

  /**
   * Enforces button with same action values doesn't get triggered
   * since its depending on redux state isLoading
   */

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsClicked(true);
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    setMinWidth((prevMinWidth) => buttonRef?.current?.clientWidth ?? prevMinWidth);
  }, []);

  useEffect(() => {
    if (parentClick) {
      parentClick.current = handleClick;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempActions = multipleActions;
    if (!Array.isArray(multipleActions) && multipleActions) {
      tempActions = [multipleActions];
    }

    let tempProcessing = getFirstPropertyInArray(tempActions, processing);
    tempProcessing = Boolean(isLoading || tempProcessing) && (isClicked || globalActionListener);
    if (!tempProcessing) {
      setIsClicked(false);
    }
    setIsProcessing(tempProcessing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processing, isDisabled, isLoading, conditionalLoading, isClicked]);

  if (spinnerModeOnly) {
    return (
      <Spinner
        size={15}
        thickness={4}
        color="inherit"
        className={`mb-n1xs ${className}`}
        isLoading={isProcessing}
        actionType={undefined}
        multipleActions={undefined}
      />
    );
  }

  if (iconButtonMode) {
    const disabled = isProcessing || isDisabled;
    const iconButton = (
      <IconButton
        data-testid={dataTestId}
        disabled={disabled}
        onClick={handleClick}
        ref={buttonRef}
        color={color}
        component={undefined as any}
      >
        {isProcessing ? (
          <Spinner
            size={24}
            thickness={4}
            color="inherit"
            isLoading={isProcessing}
            actionType={undefined}
            multipleActions={undefined}
            className={undefined}
          />
        ) : (
          startIcon
        )}
      </IconButton>
    );
    if (disabled) {
      return iconButton;
    }
    return <Tooltip title={title}>{iconButton}</Tooltip>;
  }

  return (
    <Button
      ref={buttonRef}
      id={id}
      data-testid={dataTestId}
      fullWidth={fullWidth}
      type={type}
      color={color}
      size={size}
      variant={variant}
      onClick={handleClick}
      disableElevation={disableElevation}
      style={{ ...style, minWidth }}
      disabled={isProcessing || isDisabled}
      className={className}
      startIcon={isProcessing ? null : startIcon}
      component={undefined as any}
    >
      <div>
        <Spinner
          size={16}
          thickness={4}
          color="inherit"
          className={`mb-n1xs ${startIcon ? 'mr-2 ' : ''}`}
          isLoading={isProcessing}
          actionType={undefined}
          multipleActions={undefined}
        />
        {isProcessing && !startIcon ? '' : title}
      </div>
    </Button>
  );
};
