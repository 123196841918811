import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import React, { FC } from 'react';

const Delete: FC<IconButtonProps> = ({ onClick, disabled, edge = false, size = 'medium' }) => {
  const { t } = useTranslation();
  const iconButton = (
    <IconButton onClick={onClick} disabled={disabled} edge={edge} size={size}>
      <DeleteIcon fontSize={size} />
    </IconButton>
  );
  return disabled ? iconButton : <Tooltip title={t('delete.heading')}>{iconButton}</Tooltip>;
};

export default Delete;
