import { createTheme, Theme } from '@mui/material/styles';
import { Nested } from 'models/common';
import { ExecutionStatus } from 'modules/configuration/experimentation/service/experimentation-api';
import { TaskStatus } from 'modules/workspace/tasks/models/tasks';
import {
  components,
  customComponents,
  enUS,
  palette,
  typography,
} from 'utils/helpers/theme/material.theme';

declare module '@mui/material/Button' {
  export interface ButtonClasses {
    textTransform: string;
  }

  export interface ButtonPropsColorOverrides {
    default: true;
  }
}

declare module '@mui/material/styles' {
  interface ThemeOptions {
    custom: Nested;
  }

  interface Theme {
    custom: Nested;
  }

  export function createTheme(options?: ThemeOptions): Theme;
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {
    custom: Nested & {
      taskStatusChipComponent: {
        [key in TaskStatus]: React.CSSProperties;
      };
      executionStatusChipComponent: {
        [key in ExecutionStatus]: React.CSSProperties;
      };
      riskChipComponent: {
        [key in keyof typeof customComponents.riskChipComponent]: React.CSSProperties;
      };
    };
  }
}

export const hawkaiLightTheme = createTheme(
  {
    typography,
    palette,
    components,
    custom: customComponents,
  },
  enUS,
);
