import { raiseErrorByCode } from 'utils/helpers/error-util';
import ErrorCode from 'utils/constants/error-code';

class HidtaHifcaListManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Hidta Hifca API Communicator instance is required.');
    }
  }

  static validateConfigJson(json) {
    const valid = json;
    if (valid) {
      return Promise.resolve(json);
    }
    return raiseErrorByCode(ErrorCode.hidtaHifca.dataInvalid);
  }

  getHidtaHifcaFileList(tenantId) {
    return this.communicator
      .fetchHidtaHifcaFilesList(tenantId)
      .then((json) => HidtaHifcaListManager.validateConfigJson(json));
  }

  updateActiveHidtaHifcaListVersion(tenantId, payload) {
    return this.communicator.updateActiveHidtaHifcaListVersion(tenantId, payload.version);
  }
}

export default HidtaHifcaListManager;
