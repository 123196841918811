import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { hideError, showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import CaseAPICommunicator from 'modules/trx-cases/case-detail/services/case/case-api-communicator';
import CaseManager from 'modules/trx-cases/case-detail/services/case/case-manager';
import { CaseActions } from 'middleware/action-types';
import {
  assignCaseToOperatorError,
  assignCaseToOperatorSuccess,
  getCaseDetail,
  getCaseStateChanges,
  getCaseStateChangesError,
  getCaseStateChangesSuccess,
  updateCaseStateError,
  updateCaseStateSuccess,
} from 'middleware/actions/case';
import { showNotification } from 'middleware/actions/notification';

const caseCommunicator = new CaseAPICommunicator();
const caseManager = new CaseManager(caseCommunicator);

function* getCaseStateChangesBegin(action) {
  const payload = action.payload || {};
  const { uuid, caseTenantId } = payload;

  try {
    yield put(startProcessing(CaseActions.CASE_STATE_CHANGES_GET));

    const json = yield caseManager.queryCaseStateChanges(uuid, caseTenantId);
    yield put(getCaseStateChangesSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(CaseActions.CASE_STATE_CHANGES_GET));
  } catch (error) {
    yield put(getCaseStateChangesError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CaseActions.CASE_STATE_CHANGES_GET));
  }
}

function* getCaseStateChangesWatcher() {
  yield takeLatest(CaseActions.CASE_STATE_CHANGES_GET, getCaseStateChangesBegin);
}

function* updateCaseStateBegin(action) {
  const {
    uuid,
    createdAt,
    caseTenantId,
    caseStateChange,
    note: operatorNote,
    feedback: operatorAnswer,
    caseStateChangeNote: message,
    assessment,
  } = action.payload;
  try {
    const SUBMIT_SAR = 'SUBMIT_SAR';
    const isSubmitSar = caseStateChange.caseAction.name === SUBMIT_SAR ? 'yes' : operatorAnswer;

    yield put(startProcessing(CaseActions.CASE_STATE_UPDATE));

    const json = yield caseManager.updateCaseState({
      uuid,
      caseStateChange,
      message,
      operatorAnswer: isSubmitSar,
      operatorNote,
      caseTenantId,
      assessment,
    });
    yield put(updateCaseStateSuccess(json));

    yield put(showNotification({ message }));

    /**
     * fetch case updates in single & groups
     */
    if (operatorNote?.length) {
      yield put(getCaseDetail(uuid, true, createdAt, false));
    } else {
      yield put(getCaseDetail(uuid, false, createdAt, false));
    }

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(CaseActions.CASE_STATE_UPDATE));
  } catch (error) {
    yield put(updateCaseStateError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CaseActions.CASE_STATE_UPDATE));
  }
}

function* updateCaseStateWatcher() {
  yield takeLatest(CaseActions.CASE_STATE_UPDATE, updateCaseStateBegin);
}

function* assignCaseToOperatorBegin(action) {
  yield put(startProcessing(CaseActions.ASSIGN_CASE_TO_OPERATOR));
  try {
    const { caseId, caseCreatedAt } = action.payload;
    const detailCase = yield caseManager.assignCaseToAnotherOperator(action.payload);
    yield put(assignCaseToOperatorSuccess(detailCase));
    yield put(getCaseStateChanges(caseId, detailCase.tenantId));
    yield put(getCaseDetail(caseId, false, caseCreatedAt));
  } catch (error) {
    yield put(assignCaseToOperatorError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.ASSIGN_CASE_TO_OPERATOR));
}

function* assignCaseToOperatorWatcher() {
  yield takeEvery(CaseActions.ASSIGN_CASE_TO_OPERATOR, assignCaseToOperatorBegin);
}

export { getCaseStateChangesWatcher, updateCaseStateWatcher, assignCaseToOperatorWatcher };
