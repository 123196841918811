import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import store from 'middleware/store';
import App from 'modules/app';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { hawkaiLightTheme } from 'utils/helpers/theme/hawkai.theme';
import './services/language/i18n.ts';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={hawkaiLightTheme}>
        <ConfirmProvider>
          <Suspense fallback={<div />}>
            <App />
          </Suspense>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
);
