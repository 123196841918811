import { EXPECTED_CUSTOMER_VALUE_ACTIONS } from 'modules/configuration/rule-manager/expected-customer-value/middleware/action-types';

const expectedCustomerValueConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPECTED_CUSTOMER_VALUE_ACTIONS.fetch.success:
    case EXPECTED_CUSTOMER_VALUE_ACTIONS.update.success:
    case EXPECTED_CUSTOMER_VALUE_ACTIONS.delete.success:
      return {
        ...state,
        ruleInstances: action.payload.instances,
        updateDate: action.payload.updatedAt,
        userId: action.payload.userId,
      };
    default:
      return state;
  }
};

export default expectedCustomerValueConfigReducer;
