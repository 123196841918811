import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import APICommunicator from 'services/api-communicator';

class ResetPasswordService extends APICommunicator {
  requestResetPassword(emailAddress) {
    const apiUrl = API_URLS.authService.resetPassword.post;
    return this.postJSON(apiUrl, { emailAddress }, ErrorCode.AUTH0_API_NOT_AVAILABLE);
  }
}

export default ResetPasswordService;
