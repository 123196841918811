import React from 'react';
import { AvatarGroup, Chip, Grid, Tooltip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { customComponents } from 'utils/helpers/theme/material.theme';

type AdditionalId = { type: string; value: string };

interface ChipGroupProps {
  values?: AdditionalId[];
  maxNumber?: number;
}

const MultiValueChipWithTitle = ({ maxNumber = 2, values = [] }: ChipGroupProps) => {
  if (isEmpty(values)) {
    return null;
  }

  const formattedLabel = ({ type, value }: AdditionalId) => (
    <>
      <Typography variant="caption" color="textSecondary">
        {type}
      </Typography>
      <Typography component="p">{value}</Typography>
    </>
  );

  return (
    <div className="d-flex">
      <Tooltip
        componentsProps={{ tooltip: { className: 'content' } }}
        title={
          <Grid
            container
            spacing={1}
            data-testid="more-chips-body"
            style={{ padding: '12px', width: '300px', minHeight: '100px' }}
          >
            {values.map((singleItem, index) => (
              <Grid item key={index}>
                <Chip
                  size="small"
                  className="p-1"
                  sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                    },
                  }}
                  label={formattedLabel(singleItem)}
                />
              </Grid>
            ))}
          </Grid>
        }
      >
        <AvatarGroup
          max={maxNumber}
          className="chips d-flex"
          data-testid="multi-value-chip-with-title"
        >
          {values.map((singleItem, index) => (
            <Chip
              key={index}
              className={`${
                index !== 0 ? 'ml-n2 border-right-2 border-left-2' : 'border-right-2'
              } border-white`}
              size="small"
              style={customComponents.riskChipComponent.default}
              label={singleItem.value}
            />
          ))}
        </AvatarGroup>
      </Tooltip>
    </div>
  );
};

export default MultiValueChipWithTitle;
