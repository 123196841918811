import { EntityResolutionRiskTemplates } from 'components/popover/person-details/person-details-tab/risk-rating/entity-resolution-risk-details/entity-resolution-risk-details';

export const CRR_TYPES = {
  assessment: 'ASSESSMENT',
  duration: 'DURATION',
  hidtaHifca: 'HIDTA_HIFCA',
  income: 'INCOME',
};

export const CRR_CUSTOM_ACTIVATION_FLAGS = {
  pep: 'ASSESSMENT_PEP',
  adverseMedia: 'ASSESSMENT_ADVERSE_MEDIA',
  mcc: 'MCC',
  occupation: 'OCCUPATION',
  productType: 'PRODUCT_TYPE',
};

export const CRR_GENERIC_TEMPLATE_VALUES = {
  COUNTRY: 'COUNTRY',
  RISK_PATTERN: 'RISK_PATTERN',
  BEHAVIOUR_EXPECTED_VALUE_DEVIATION: 'BEHAVIOUR_EXPECTED_VALUE_DEVIATION',
  RANGE: 'RANGE',
  SLIDER: 'SLIDER',
  TIME_RANGE: 'TIME_RANGE',
  TXN_VOLUME_AND_COUNT: 'TXN_VOLUME_AND_COUNT',
  FREE_TEXT: 'FREE_TEXT',
  LIST_UPLOAD: 'LIST_UPLOAD',

  /** @deprecated please use BEHAVIOUR_EXPECTED_VALUE_DEVIATION instead */
  PERCENT_DEVIATION: 'PERCENT_DEVIATION',
};

export const CRR_GENERIC_GROUP_VALUES = {
  ASSESSMENT: 'ASSESSMENT',
  RISK_PATTERN: 'RISK_PATTERN',
  BEHAVIOUR_EXPECTED_VALUE_DEVIATION: 'BEHAVIOUR_EXPECTED_VALUE_DEVIATION',
  CASE_STATUS: 'CASE_STATUS',
  COUNTRY_RISK_LEVEL: 'COUNTRY_RISK_LEVEL',
  DEVIATION: 'DEVIATION',
  MONETARY_VALUE_RANGE: 'MONETARY_VALUE_RANGE',
  TIME_RANGE: 'TIME_RANGE',
  TRANSACTION_COUNT: 'TRANSACTION_COUNT',
  TXN_VOLUME_AND_COUNT: 'TXN_VOLUME_AND_COUNT',
  FREE_TEXT: 'FREE_TEXT',
  LIST_RISK_LEVEL: 'LIST_RISK_LEVEL',
};

export const CRR_GENERIC_GROUP_TO_TEMPLATE_MAP = {
  [CRR_GENERIC_GROUP_VALUES.ASSESSMENT]: CRR_GENERIC_TEMPLATE_VALUES.SLIDER,
  [CRR_GENERIC_GROUP_VALUES.CASE_STATUS]: CRR_GENERIC_TEMPLATE_VALUES.RANGE,
  [CRR_GENERIC_GROUP_VALUES.COUNTRY_RISK_LEVEL]: CRR_GENERIC_TEMPLATE_VALUES.COUNTRY,
  [CRR_GENERIC_GROUP_VALUES.RISK_PATTERN]: CRR_GENERIC_TEMPLATE_VALUES.RISK_PATTERN,
  [CRR_GENERIC_GROUP_VALUES.BEHAVIOUR_EXPECTED_VALUE_DEVIATION]:
    CRR_GENERIC_TEMPLATE_VALUES.BEHAVIOUR_EXPECTED_VALUE_DEVIATION,
  [CRR_GENERIC_GROUP_VALUES.DEVIATION]: [
    CRR_GENERIC_TEMPLATE_VALUES.BEHAVIOUR_EXPECTED_VALUE_DEVIATION,
    CRR_GENERIC_TEMPLATE_VALUES.PERCENT_DEVIATION,
  ],
  [CRR_GENERIC_GROUP_VALUES.MONETARY_VALUE_RANGE]: CRR_GENERIC_TEMPLATE_VALUES.RANGE,
  [CRR_GENERIC_GROUP_VALUES.TIME_RANGE]: CRR_GENERIC_TEMPLATE_VALUES.TIME_RANGE,
  [CRR_GENERIC_GROUP_VALUES.TRANSACTION_COUNT]: CRR_GENERIC_TEMPLATE_VALUES.RANGE,
  [CRR_GENERIC_GROUP_VALUES.TXN_VOLUME_AND_COUNT]: CRR_GENERIC_TEMPLATE_VALUES.TXN_VOLUME_AND_COUNT,
  [CRR_GENERIC_GROUP_VALUES.FREE_TEXT]: CRR_GENERIC_TEMPLATE_VALUES.FREE_TEXT,
  [CRR_GENERIC_GROUP_VALUES.LIST_RISK_LEVEL]: CRR_GENERIC_TEMPLATE_VALUES.LIST_UPLOAD,
  [EntityResolutionRiskTemplates.multipleAttribute]:
    EntityResolutionRiskTemplates.multipleAttribute,
};

export enum CrrCustomerTypes {
  organization = 'organization',
  individual = 'individual',
}

export type CrrCustomerType = `${CrrCustomerTypes}`;

export const CRR_METADATA_KEYS = {
  summary: 'SUMMARY',
  detail: 'DETAIL',
  originalData: 'ORIGINAL_DATA',
  calculatedPoints: 'CALCULATED_POINTS',
  totalPoints: 'TOTAL_POINTS',
  finalRiskLevel: 'FINAL_RISK_LEVEL',
  calculatedDurationInBusiness: 'CALCULATED_DURATION_IN_BUSINESS',
  calculatedDurationCustomerAge: 'CALCULATED_DURATION_CUSTOMER_AGE',
  calculatedDurationCustomerSince: 'CALCULATED_DURATION_CUSTOMER_SINCE',
  calculatedListUploadRiskLevel: 'CALCULATED_LIST_UPLOAD_RISK_LEVEL',
  selectedTypeContractValue: 'SELECTED_TYPE_CONTRACT_VALUE',
  selectedTypeExpectedIncome: 'SELECTED_TYPE_EXPECTED_INCOME',
  riskFactorInstance: 'RISK_FACTOR_INSTANCE',
};

export enum CrrSections {
  geography = 'GEOGRAPHY',
  customer = 'CUSTOMER',
  industry = 'INDUSTRY',
  behaviour = 'BEHAVIOUR',
}

export type CrrSection = `${CrrSections}`;
