import { CaseNotificationsConfigActions } from 'modules/configuration/workflows-and-users/components/case-notification/middleware/action-types';
import {
  deleteCaseNotificationsConfigError,
  deleteCaseNotificationsConfigSuccess,
  getCaseNotificationsConfigError,
  getCaseNotificationsConfigSuccess,
  updateCaseNotificationsConfigError,
  updateCaseNotificationsConfigSuccess,
} from 'modules/configuration/workflows-and-users/components/case-notification/middleware/actions/case-notifications-config';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import RuleConfigAPICommunicator from 'services/rule-config-api-communicator';
import RuleConfigManager from 'services/rule-config-manager';

const communicator = new RuleConfigAPICommunicator('case-notifications');
const manager = new RuleConfigManager(communicator);

function* getCaseNotificationsConfigBegin(action) {
  yield put(startProcessing(CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_GET));
  try {
    const json = yield manager.getConfig(action.payload);
    yield put(getCaseNotificationsConfigSuccess(json));
  } catch (error) {
    yield put(getCaseNotificationsConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_GET));
}

export function* getCaseNotificationsConfigWatcher() {
  yield takeLatest(
    CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_GET,
    getCaseNotificationsConfigBegin,
  );
}

function* updateCaseNotificationsConfigBegin(action) {
  yield put(startProcessing(CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_UPDATE));
  try {
    const json = yield manager.updateConfig(action.payload);
    yield put(updateCaseNotificationsConfigSuccess(json));
  } catch (error) {
    yield put(updateCaseNotificationsConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_UPDATE));
}

export function* updateCaseNotificationsConfigWatcher() {
  yield takeLatest(
    CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_UPDATE,
    updateCaseNotificationsConfigBegin,
  );
}

function* deleteCaseNotificationsConfigBegin(action) {
  yield put(startProcessing(CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_DELETE));
  try {
    const json = yield manager.deleteConfig(action.payload.uniqueId);
    yield put(deleteCaseNotificationsConfigSuccess(json));
  } catch (error) {
    yield put(deleteCaseNotificationsConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_DELETE));
}

export function* deleteCaseNotificationsConfigWatcher() {
  yield takeLatest(
    CaseNotificationsConfigActions.CASE_NOTIFICATIONS_CONFIG_DELETE,
    deleteCaseNotificationsConfigBegin,
  );
}
