import { HIDTA_HIFCA_LIST_ACTIONS } from 'middleware/action-types';
import { showError } from 'middleware/actions/error';
import {
  activateHidtaHifcaError,
  activateHidtaHifcaSuccess,
  getHidtaHifcaFileList,
  getHidtaHifcaFileListError,
  getHidtaHifcaFileListSuccess,
} from 'middleware/actions/hidta-hifca-list';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import HidtaHifcaListApiCommunicator from 'services/hidta-hifca-list-api-communicator';
import HidtaHifcaListManager from 'services/hidta-hifca-list-manager';
import TenantManagementService from 'services/tenant/tenant-management-service';

const apiCommunicator = new HidtaHifcaListApiCommunicator();
const hidtaHifcaListManager = new HidtaHifcaListManager(apiCommunicator);

export function* getHidtaHifcaFileListBegin(action) {
  try {
    yield put(startProcessing(HIDTA_HIFCA_LIST_ACTIONS.fileList.get));

    const json = yield hidtaHifcaListManager.getHidtaHifcaFileList(
      TenantManagementService.getActiveTenantId(),
    );
    yield put(getHidtaHifcaFileListSuccess(json));

    yield put(stopProcessing(HIDTA_HIFCA_LIST_ACTIONS.fileList.get));
  } catch (error) {
    yield put(getHidtaHifcaFileListError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(HIDTA_HIFCA_LIST_ACTIONS.fileList.get));
  }
}

export function* getHidtaHifcaFileListWatcher() {
  yield takeLatest(HIDTA_HIFCA_LIST_ACTIONS.fileList.get, getHidtaHifcaFileListBegin);
}

export function* updateActiveHidtaHifcaListVersionBegin(action) {
  try {
    yield put(startProcessing(HIDTA_HIFCA_LIST_ACTIONS.activateVersion.send));

    const json = yield hidtaHifcaListManager.updateActiveHidtaHifcaListVersion(
      TenantManagementService.getActiveTenantId(),
      action.payload,
    );
    yield put(activateHidtaHifcaSuccess(json));
    yield put(getHidtaHifcaFileList(TenantManagementService.getActiveTenantId()));

    yield put(stopProcessing(HIDTA_HIFCA_LIST_ACTIONS.activateVersion.send));
  } catch (error) {
    yield put(activateHidtaHifcaError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(HIDTA_HIFCA_LIST_ACTIONS.activateVersion.send));
  }
}

export function* updateActiveHidtaHifcaListVersionWatcher() {
  yield takeLatest(
    HIDTA_HIFCA_LIST_ACTIONS.activateVersion.send,
    updateActiveHidtaHifcaListVersionBegin,
  );
}
