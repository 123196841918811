import { CUSTOMER_DETAILS_ACTIONS } from 'middleware/action-types';

export const getCustomerDetails = (caseId) => ({
  type: CUSTOMER_DETAILS_ACTIONS.get.data,
  payload: { caseId },
});

export const getCustomerDetailsSuccess = (payload) => ({
  type: CUSTOMER_DETAILS_ACTIONS.get.success,
  payload,
});

export const getCustomerDetailsError = () => ({
  type: CUSTOMER_DETAILS_ACTIONS.get.error,
});
